import React from "react";
import {standartColors} from "../../assets/colors/Colors";

export default function TabIndicator(props: {
    name: string,
    isActive: boolean,
    onClick: () => void;
}){

    return (
        < div key={props.name} onClick={props.onClick} style={styles.header.tab} >
            <div style={styles.header.text}>{props.name}</div>
            <div style={{ ...styles.stepIndicator, backgroundColor: props.isActive ? standartColors.primaryYellow : "transparent" }} />
        </div >
    )

}

const styles = {
    header: {
        container: { height: 100, width: '100%', display: 'flex', alignItems: 'center', position: 'relative', top: 0, } as React.CSSProperties,
        tab: { cursor: "pointer", height: 55, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', } as React.CSSProperties,
        text: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80%', width: '100%', fontWeight: 'bold', marginLeft: 10, marginRight: 10 } as React.CSSProperties,
    },
    stepIndicator: { borderRadius: 2, height: 5, width: '100%', marginLeft: 10, marginRight: 10 }
}