import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import {BACKEND_URL} from "../../assets/AppConstants";
import {useCallback, useEffect, useState} from "react";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {useLoginState} from "../../States/UserState";
import LanguageExporter from "../../Helpers/LanguageExporter";

const videoRepository = new VideoRepository();

export function BoxSlider(props: {contentData: any}){
    const language = useLanguageState(state => state.language)
    const [active, setActive] = useState(0);
    const [contents, setContents] = useState([]);
    const authState = useLoginState();
    useEffect(() => {

        const interval = setInterval(() => {
            if(props.contentData.length - 1 === active){
                setActive(0);
            }else{
                setActive(active + 1);
            }
        }, 5000);

        return () => {
            clearInterval(interval);
        }

    }, [active, props.contentData]);


    const getContents = useCallback( async (contentId = null) => {
        const contentGet = await videoRepository.getUserContent();

        const data = contentGet.filter((i) => {
            let isExists = true;

            i.videos_companies.forEach((a) => {
                if(!authState.company.includes(a.company_id)){
                    isExists = false;
                }
            });

            return isExists;
        });

        setContents(data.filter(vid => vid.seen_videos.length < 1));
    }, [authState]);

    useEffect(() => {
        getContents().then(undefined);
    }, [getContents]);

    return (
        <div style={{position:"relative"}}>
            <div style={{left: 20, top: 20, position: "absolute", color: "#fff", fontWeight: "bold"}}>{I18N("sliderHeaderText", language)}</div>
            <div style={{height: 210, borderRadius: 10, width: '100%'}}>
            {contents.map((content, index) => {
               return (
                   <div onClick={() => {
                       window.location.href = '/Lernvideos#video=' + content.id
                   }} style={{cursor: "pointer", borderRadius: 10, display: active === index ? "block" : "none", height: '100%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundImage: `url('${BACKEND_URL}${content.image}')`}}>
                       <div style={{width: '100%', height: 210, backgroundColor: "#00000050", borderRadius: 10,}}>
                           <p style={{position: "absolute", bottom: 20, left: 20, fontSize: 28, color: "#fff"}}>{LanguageExporter(content, language, 'video_titles', 'title', 'title')}</p>
                       </div>
                   </div>
               )
            })}
            </div>
            <div style={{position: "absolute", bottom: 20, right: 20, flexDirection: "row", display: "flex", justifyContent: "center"}}>
                {contents.map((contentForDot, index) => {
                    return (
                        <div style={{background: active === index ? "#FFE16A" : "#FFE16A50", width: 10, height: 10, borderRadius: '50%', marginRight: 5, cursor: "pointer"}} onClick={() => setActive(index)} />
                    )
                })}
            </div>
        </div>
    )


}
