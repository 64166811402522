import {AbstractRepository} from "./abstract-repository";
import axios from "axios";

export class VideoUploadRepository{

    getContent(pagination?: {page:number, limit: number}): Promise<any[]>{
        return new Promise((resolve, reject) => {

           axios.get(`/videos/content/uploadzip${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}` : ''}`).then((response) => {

               return resolve(response.data);

           }).catch((err) => {
               return reject(err);
           })

        });
    }


    getContentForRemote(token: string, endpoint: string): Promise<any[]>{
        return new Promise((resolve, reject) => {

            axios.get(`${endpoint}/videos/content/uploadzip`, {
                headers: {
                    Authorization: token.includes('Bearer') ? token : `Bearer ${token}`
                }
            }).then((response) => {

                return resolve(response.data);

            }).catch((err) => {
                return reject(err);
            })

        });
    }

    getContentList(): Promise<any[]>{

        return new Promise((resolve, reject) => {

           axios.get('/videos/content/uploadzip/company').then((resonse) => {
               return resolve(resonse.data);
           }).catch((err) => {
               return reject(err);
           })

        });

    }

    update(name: string, companies: number[], image: string, id: number): Promise<any[]>{
        return new Promise((resolve, reject) => {

            axios.patch('/videos/content/uploadzip/'+ id, {name: name, companies: companies, image: image}).then((response) => {

                return resolve(response.data);

            }).catch((err) => {
                return reject(err);
            })

        });
    }

    delete(id: number){
        return new Promise((resolve, reject) => {

            axios.delete('/videos/content/uploadzip/' +id).then((response) => {

                return resolve(response.data);

            }).catch((err) => {
                return reject(err);
            })

        });
    }


    uploadPicture(file: File, uploadProgressCallback?: (name: File, progress: number, loaded: number, finished?: boolean) => void): Promise<string>{
        return new Promise((resolve, reject) => {

            const fileHolder = new FormData();
            fileHolder.append('file', file);

            axios.post('/videos/uploadzip', fileHolder, {
                onUploadProgress: (progressEvent) => {
                    if(uploadProgressCallback !== undefined){
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        console.log(progressEvent);
                        if (totalLength !== null) {
                            uploadProgressCallback(file, Math.round( (progressEvent.loaded * 100) / totalLength), progressEvent.loaded);
                        }
                    }
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if(uploadProgressCallback !== undefined){
                    uploadProgressCallback(file, 100, 0, true);
                }
                return resolve(response.data.zip);
            }).catch((error) => {
                return reject(error);
            });

        });
    }

}
