import React, { useCallback, useEffect, useState } from "react";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import { CategoriesRepository } from "../Repositories/CategoriesRepository";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import TableMui, {IColumn} from "../ViewComponents/TableMui";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import {PaginationTypes} from "../Types/PaginationTypes";
import {CategoryType} from "../Types/CategoryType";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import {useSnackbar} from "notistack";


const categoriesRepository = new CategoriesRepository();

export default function CategoryList(props: { editMode: (item) => void }) {


    //Setting states
    const [categories, setCategories] = useState<PaginationTypes<CategoryType>>(undefined);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderType, setOrderType] = useState(null);
    const [filter, setFilter] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const {enqueueSnackbar} = useSnackbar();


    //Call categories function
    const getCategories = useCallback(async () => {
        if(!waitingForPage){
            setLoading(true);
            const categoryList = await categoriesRepository.getAll({page: page, limit: limit, filter, orderType});
            setCategories(categoryList as PaginationTypes<CategoryType>);
            setLoading(false);
        }
    }, [page, limit, filter, orderType,waitingForPage]);

    //Deleting categories
    const deleteCategory = useCallback(async (category: any) => {

        const del = await categoriesRepository.delete(category.id, category.unlink !== undefined ? category.unlink :  false);

        if (del instanceof Error) {
            enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            return;
        } else {

            if(del.status !== undefined && del.status === -1){
                return del;
            }
            await getCategories().then(undefined)
            enqueueSnackbar(I18N('deleteSuccess', language, [{ key: 'name', value: I18N('category', language) }]), {variant: "success"});

        }

    }, [categories, language, getCategories]);

    //setting categories table columns
    const tableColumnsMui = useCallback(() : IColumn[]  => {
        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'name',
                name: I18N('name', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return value;
                }
            },
            {
                field: 'learnContent',
                name: I18N('learnContent', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    return row._count.video_categories;
                }
            },
            {
                field: 'actions',
                width: 100,
                disableReorder: true,
                disableColumnMenu: true,
                filterable: false,
                sortable: false,
                name: I18N('actions', language),
                renderCell: (params: any) => {
                    return <DeleteIcon row={params.row} callBack={deleteCategory} unlinkRequired={true} ask={true} />;
                }
            },
        ];

    }, [language, deleteCategory]);

    //Loading categories when page loads
    useEffect(() => {
        getCategories().then(undefined);
    }, [getCategories]);


    //Sorting functions/
    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);
    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);
    //Sorting functions


    return (
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    setWaitingForPage={setWaitingForPage}
                    page={page}
                    tableName="categoryListTable"
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={categories?.data !== undefined ? categories.data : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT
                        if(event.target.dataset.field !== "actions"){
                            props.editMode(data.row);
                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={categories?.total}
                />
            </div>
    )

}
