import {TextField} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import FormButton from "../../../ViewComponents/FormButton";

const saveKey = "departmentFachData";

function DepartmentStep(props: {title: string}, ref){

    const language = useLanguageState(state => state.language);
    const [formState, setFormState] = useState([""]);

    const clearData = useCallback(() => {

        setFormState(['']);
        localStorage.removeItem(saveKey);

    }, []);

    const checkLocalStorage = useCallback(() => {

        const departmentsFachData = localStorage.getItem(saveKey);
        if(departmentsFachData){
            setFormState(JSON.parse(departmentsFachData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formState));

    }, [formState]);

    return (
        <div style={{padding: 10, maxHeight: 380, overflowY: "scroll"}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>

            {formState.map((i, key) => {
                return (
                    <TextField
                        fullWidth
                        label={I18N('name', language)}
                        style={{ marginTop: 20,color: "#fff" }}
                        value={formState[key]}
                        onChange={(text) => {
                            formState[key] = text.target.value;
                            setFormState([...formState]);
                        }}
                    />
                )
            })}

            <div style={{marginTop: 20}}>
                <FormButton text={I18N('new', language)} color="dark" icon="plus" onClick={() => {
                    formState.push("");
                    setFormState([...formState]);
                }} isLoading={false} />
            </div>



            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )
}

const Department = React.forwardRef(DepartmentStep);
export default Department;