import {Drawer, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useLanguageState} from "../States/LanguageState";
import {I18N} from "../i18n/i18n";
import FormButton from "../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../ViewComponents/VideoEditComponents/Drawer";
import ViewBox from "../ViewComponents/ViewBox";
import {UsersRepository} from "../Repositories/UsersRepository";
import {DrawerConfigSx} from "../ViewComponents/DrawerConfigSx";

const userRepository = new UsersRepository();

const PasswordQualityEdit = (props: {selectedGroup: any; isOpen: boolean, setIsOpen: (status: boolean) => void, content: any, setContent: (data) => void;}) => {
    const language = useLanguageState();
    const [isLoading, setIsLoading] = useState(false);

    const [formState, setFormState] = useState({
        min_lenght: '0',
        digits: 1,
        specialChars: 1,
        capitalLetters: 1

    });


    useEffect(() => {

        if(props.content){

            setFormState({
                min_lenght: props.content.min_lenght.toString(),
                digits: props.content.digits ? 1 : 0,
                specialChars: props.content.specialChars ? 1 : 0,
                capitalLetters: props.content.capitalLetters ? 1 : 0
            })

        }else{
         setFormState({
             min_lenght: '0',
             digits: 1,
             specialChars: 1,
             capitalLetters: 1

         })
        }

    }, [props.content]);




    const saveChanges = useCallback(async () => {

        const data = await userRepository.savePasswordQuality(formState, props.selectedGroup.id);
        props.setContent(data);
        props.setIsOpen(false);

    }, [formState, props.selectedGroup]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeSettings', language.language)}</p>
                        <FormButton icon="save" withLoadingInFullScreen={true} color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={isLoading} />
                    </div>
                    <div className="box"  style={{marginTop: 5}}>

                        <ViewBox>
                            <p className="viewBoxTitle">{I18N("group", language.language)}</p>
                            <TextField
                                fullWidth
                                disabled={true}
                                label={I18N('group', language.language)}
                                style={{ marginTop: 20}}
                                value={props.selectedGroup?.name}
                            />


                        </ViewBox>

                        <ViewBox>

                            <p className="viewBoxTitle">{I18N("settings", language.language)}</p>
                            <TextField
                                fullWidth
                                label={I18N('minPassword', language.language)}
                                style={{ marginTop: 20}}
                                value={formState.min_lenght}
                                onChange={(text) => {
                                    setFormState({...formState, min_lenght: text.target.value})
                                }}
                            />

                            <FormControl fullWidth style={{ marginTop: 20}}>
                                <InputLabel id="digits-selector-id">{I18N('digits', language.language)}</InputLabel>
                                <Select
                                    labelId="digits-selector-id"
                                    id="digits-selector"
                                    value={formState.digits}
                                    label={I18N('digits', language.language)}
                                    onChange={(text) => {
                                        setFormState({...formState, digits: Number(text.target.value)})
                                    }}
                                >
                                    <MenuItem value={1}>{I18N('yes', language.language)}</MenuItem>
                                    <MenuItem value={0}>{I18N('no', language.language)}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginTop: 20}}>
                                <InputLabel id="specialChars-selector">{I18N('specialChars', language.language)}</InputLabel>
                                <Select
                                    labelId="specialChars-selector"
                                    id="specialCharts-id"
                                    value={formState.specialChars}
                                    label={I18N('specialChars', language.language)}
                                    onChange={(text) => {
                                        setFormState({...formState, specialChars: Number(text.target.value)})
                                    }}
                                >
                                    <MenuItem value={1}>{I18N('yes', language.language)}</MenuItem>
                                    <MenuItem value={0}>{I18N('no', language.language)}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth style={{ marginTop: 20}}>
                                <InputLabel id="capitalLetters-selector-id">{I18N('capitalLetters', language.language)}</InputLabel>
                                <Select
                                    labelId="capitalLetters-selector-id"
                                    id="capitalLetters-selector"
                                    value={formState.capitalLetters}
                                    label={I18N('capitalLetters', language.language)}
                                    onChange={(text) => {
                                        setFormState({...formState, capitalLetters: Number(text.target.value)})
                                    }}
                                >
                                    <MenuItem value={1}>{I18N('yes', language.language)}</MenuItem>
                                    <MenuItem value={0}>{I18N('no', language.language)}</MenuItem>
                                </Select>
                            </FormControl>


                        </ViewBox>

                    </div>
                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default PasswordQualityEdit;
