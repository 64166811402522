import React, {useCallback, useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, TextField, useTheme} from '@mui/material';
import { standartColors } from '../../assets/colors/Colors';
import { Searchbar } from '../../Searchbar/Searchbar';
import { PWNEDRepository } from '../../Repositories/PWNED/pwned.repository';
import {I18N} from "../../i18n/i18n";
import emailSuccess from '../../assets/emailSuccess.svg';
import emailDanger from '../../assets/emailDanger.svg';
import {useLanguageState} from "../../States/LanguageState";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ViewBox from "../../ViewComponents/ViewBox";
import moment from "moment/moment";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import BreadCrumb from "../../ViewComponents/BreadCrumb";
import {useHistory} from "react-router-dom";
import proofIcon from "../../assets/passwordProof.svg";
const sweetAlert = withReactContent(Swal)
type Props = {
    style: any
    isMobile: () => boolean
}

const pwnedRepo = new PWNEDRepository()
export function EmailPruefung(props: Props) {

    //Creating states
    const history = useHistory();
    const [email, setEmail] = useState('Test');
    const [breachedAccounts, setBreachedAccounts] = useState<{Name: string, BreachDate: string, DataClasses: string[]}[]>([]);
    const language = useLanguageState(state => state.language);
    const breadCrumb = useBreadCrumb();
    const [proofStatus, setProofStatus] = useState<null | boolean>(null);
    const theme = useTheme();
    const [isLoading, setIsLoading] = useState(false);

    //Moving page to top when page opens
    useEffect(() => {

        window.scrollTo({
            behavior: "smooth",
            top: 0
        });

    }, []);

    // Sending request function which sends request to our endpoint then retrieves data
    const checkEmail = useCallback(async() => {
        setIsLoading(true);
        await pwnedRepo.checkForEmail(email).then((response) => {
                const responseData  = response as unknown as any;
                setProofStatus(responseData.data.length <= 0);
                setBreachedAccounts(responseData.data);
                setIsLoading(false);
        }).catch(async (err) => {
            await sweetAlert.fire({
                showCloseButton: true,
                title: I18N('error', language),
                html: I18N('unexpectedError', language),
                icon: "error"
            });
            setIsLoading(false);
            return  {status: false};
        })



    }, [email, language]);

    useEffect(() => {

        breadCrumb.setTitle(I18N("emailCheck", language));
        breadCrumb.setCrumb([
            {
                title: I18N("home", language),
                onClick: () => history.push('/Dashboard'),
            },
            {
                title: I18N("emailCheck", language),
                onClick: undefined,
            }
        ]);
        breadCrumb.setChildren(null);
    }, [language]);

        return (
            <div style={props.style}>
                <Searchbar />
                <BreadCrumb />
                <div style={styles.container}>
                    <Backdrop
                        open={isLoading}
                        style={{ color: standartColors.primaryYellow, zIndex:999 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div style={{...styles.header.container, color: theme.palette.mode === "dark" && "#fff"}}>

                        {props.isMobile() &&
                            <div style={{ fontWeight: 'normal', fontSize: 13, marginTop: 10 }}>{I18N('pleaseEnterEmail', language)}</div>
                        }
                        <TextField
                            label={props.isMobile() ? '' : I18N('pleaseEnterEmail', language)}
                            style={{ width: '100%' }}
                            onKeyDown={(key) => {
                                if(key.key === "Enter"){
                                    checkEmail();
                                }
                            }}
                            onChange={(text) => setEmail(text.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <Button startIcon={(<img src={proofIcon} />)} style={styles.button} fullWidth variant="contained"
                                        onClick={checkEmail}
                                    >
                                        {I18N('check', language)}
                                    </Button>
                                )
                            }}
                        />
                    </div>


                        {proofStatus !== null && (
                            <React.Fragment>
                                {proofStatus ? (
                                    <ViewBox>
                                    <div className="emailContent" style={{height: 'calc(100vh - 500px)', }}>
                                        <div className="leftSide">
                                            <p className="title">
                                                {I18N('emailSuccessTitle', language)}
                                            </p>
                                            <p className={"content"}>{I18N('emailSuccessText', language)}</p>
                                        </div>
                                        <img src={emailSuccess} />
                                    </div>
                                    </ViewBox>
                                ): (
                                    <ViewBox style={{height: 'calc(100vh - 460px)', overflowY: "scroll", backgroundImage: `url(${emailDanger})`, backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'right 250px bottom 26px', backgroundAttachment: "fixed"}}>
                                    <div className="emailContent">
                                        <div className="leftSide">
                                            <p className="title">
                                                {I18N('emailDangerTitle', language)}
                                            </p>
                                            <p className={"content"}>{I18N('emailDangerText', language)}</p>
                                            <p className="accounts">
                                                {breachedAccounts.map((account) => (
                                                    <div key={account.Name} style={{ marginTop: 10, borderBottom: '2px solid #F2F2F2', paddingBottom: 20, marginBottom: 20}}>
                                                        <table className="tableForEmail">
                                                            <tr>
                                                                    <td style={{width: 250, fontWeight: 250}}>{I18N('service', language)}:</td>
                                                                    <td><b>{account.Name}</b></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width: 250, fontWeight: 250}}>{I18N('compromisedData', language)}:</td>
                                                                <td>{account.DataClasses.join(', ')}<br/> </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width: 250, fontWeight: 250}}>
                                                                    {I18N('dateOfCompromise', language)}:
                                                                </td>
                                                                <td>
                                                                    {moment(account.BreachDate).format('DD.MM.YYYY')}
                                                                </td>

                                                            </tr>
                                                        </table>
                                                    </div>
                                                ))}
                                            </p>
                                        </div>
                                        {/*<img style={{position: "absolute", top: '20%', right: 30, opacity: .5}} src={emailDanger} />*/}
                                    </div>
                                    </ViewBox>
                                    )}
                            </React.Fragment>
                        )}

                </div>
            </div>
        )
}

const styles = {
    container: { width: '93%', marginTop: 70, display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
    header: {
        container: { fontWeight: 600, height: 120, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: standartColors.black } as React.CSSProperties,
        buttom: {}
    },
    pswContent: {
        container: { width: '100%', height: '80%', marginTop: 12, color: '212B36', borderRadius: 10, boxShadow: ' 0 0 12px 4px rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)' } as React.CSSProperties,
        header: { height: '100%', width: '96%', marginLeft: '2%', marginTop: 12, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' } as React.CSSProperties,
    },
    resultBox: { fontWeight: 600, height: '100%', width: 300, borderRadius: 10, backgroundColor: '#ED8787', display: 'flex', justifyContent: 'center', alignItems: 'center' } as React.CSSProperties,
    button: {
        backgroundColor: standartColors.primaryYellow, color: standartColors.black, borderRadius: 10, height: '80%', width: 220, display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
}
