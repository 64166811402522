import React, {useCallback, useEffect, useState} from 'react';
import ViewBox from "../ViewComponents/ViewBox";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {TextField, useTheme} from "@mui/material";
import Select from "react-select";
import {DepartmentsRepository} from "../Repositories/DepartmentsRepository";
import {VideoRepository} from "../Repositories/VideoRepository";
import FormButton from "../ViewComponents/FormButton";
import {LearnVideosType} from "../Types/LearnVideosTypes";
import {DepartmentType} from "../Types/DepartmentTypes";
import {colorForSelect} from "../ComplinessTheme";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {useBreadCrumb} from "../States/BreadCrumbState";
import EditIcon from "../ViewComponents/EditIcon";
import GeneralInfoBar from "./Bars/GeneralInfoBar";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem, timelineItemClasses,
    timelineOppositeContentClasses,
    TimelineSeparator
} from '@mui/lab';
import plusLearningPlan from '../assets/plusLearning.svg';
import StepBar from "./Bars/StepBar";
import {LearningPlanRepository} from "../Repositories/LearningPlanRepository";
import {useSnackbar} from "notistack";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import LanguageExporter from "../Helpers/LanguageExporter";
import VideoTypeExporter from "../Helpers/VideoTypeExporter";

const departmentsRepository = new DepartmentsRepository();
const videoRepository = new VideoRepository();
const companyRepository = new CompanyRepository();
const learnPlanRepository = new LearningPlanRepository();
export interface learningPlanFormState {

    description: string;
    department: {label: string, value: number}[] | undefined;
    status: {label: string, value: number} | undefined;
    trigger: {label: string, value: string} | undefined;
    companies: {label: string, value: number}[] | undefined;

}

interface Props {
    onFormChange: (form: learningPlanFormState) => void;
    onStepsChange: (steps) => void;
    editMode?: any;
    onReturnBackToView: () => void;
    onFinish: () => void;
}





export default function LearningPlanEdit(props: Props){
    const [companies, setCompanies] = useState([]);
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [isStepOpen, setIsStepOpen] = useState(false);
    const [clickedStep, setClickedStep] = useState(null);
    const [generalInfoOpen, setGeneralInfoOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();


    const executionTimeSelections: {label: string, value: string}[] = [
        {label: I18N('immediately', language), value: 'immediately'},
        {label: I18N('1_day_delay', language), value: '1_day'},
        {label: I18N('2_day_delay', language), value: '2_day'},
        {label: I18N('3_day_delay', language), value: '3_day'},
        {label: I18N('4_day_delay', language), value: '4_day'},
        {label: I18N('5_day_delay', language), value: '5_day'},
        {label: I18N('6_day_delay', language), value: '6_day'},
        {label: I18N('1_week_delay', language), value: '1_week'},
        {label: I18N('2_week_delay', language), value: '2_week'},
        {label: I18N('3_week_delay', language), value: '3_week'},
        {label: I18N('1_month_delay', language), value: '1_month'},
        {label: I18N('2_month_delay', language), value: '2_month'},
        {label: I18N('3_month_delay', language), value: '3_month'}
    ]


    const deadlineLimits: {label: string, value: string}[] = [
        {label:  I18N("noDeadline", language), value: 'NO'},
        {label:  I18N('1_day_deadline', language), value: '1_day'},
        {label:  I18N('2_day_deadline', language), value: '2_day'},
        {label:  I18N('3_day_deadline', language), value: '3_day'},
        {label:  I18N('4_day_deadline', language), value: '4_day'},
        {label:  I18N('5_day_deadline', language), value: '5_day'},
        {label:  I18N('6_day_deadline', language), value: '6_day'},
        {label:  I18N('1_week_deadline', language), value: '1_week'},
        {label:  I18N('2_week_deadline', language), value: '2_week'},
        {label:  I18N('3_week_deadline', language), value: '3_week'},
        {label:  I18N('1_month_deadline', language), value: '1_month'},
        {label:  I18N('2_month_deadline', language), value: '2_month'},
        {label:  I18N('3_month_deadline', language), value: '3_month'}
    ]

    const startEventSelections = [
        {label: I18N('after_event', language), value: 'after'},
        {label: I18N('with_event', language), value: 'with'},
    ]


    const [planState, setPlanState] = useState(props.editMode);

    const [loaded, setLoaded] = useState(false);

    const [steps, setSteps] = useState([
        {
            disabled: true,
            startEvent: {label: I18N('with_event', language), value: 'with'},
            execution: {label:  I18N('immediately', language), value: 'immediately'},
            videos: [],
            deadline: {label: I18N("noDeadline", language), value: 'NO'}
        },
    ]);


    const [departments, setDepartments] = useState<DepartmentType[]>([]);

    const [videos, setVideos] = useState([]);

    const breadCrumb = useBreadCrumb();

    const loadDepartments = useCallback(async () => {

        const depList = await departmentsRepository.getAll();
        setDepartments(depList as DepartmentType[]);

    }, []);

    const loadVideos = useCallback(async () => {
        const videoList = await videoRepository.getAll();
        setVideos(videoList as LearnVideosType[]);
    }, []);


    const loadCompanies = useCallback( async () => {

        const subList = await companyRepository.getSubCompanies();
        setCompanies(subList);
    }, []);

    useEffect(() => {
        loadDepartments().then(undefined);
        loadVideos().then(undefined);
        loadCompanies().then(undefined)
    }, []);

    const doCalculation = useCallback((planData) => {

        const stepList = [];

        planData.learning_plan_steps.forEach((step, key) => {

            const startEventData = startEventSelections.find((item) => item.value === step.startEvent);
            const executionDate =executionTimeSelections.find((item) => item.value === step.execution);
            const deadline  =deadlineLimits.find((item) => item.value === step.deadline);
            stepList.push({
                disabled: key === 0,
                startEvent: startEventData,
                execution: executionDate,
                deadline: deadline,
                videos: step.learning_plan_step_videos.map((video) => {
                    const findVideo = videos.find((vid) => vid.id === video.video_id)
                    if(findVideo !== undefined){
                        setLoaded(true);
                        return {
                            label: LanguageExporter(findVideo, language, 'video_titles', 'title', 'title') + " ("+VideoTypeExporter(findVideo.type)+")",
                            value: video.video_id
                        }
                    }

                })
            })

        });


        setSteps([...stepList]);


    }, [deadlineLimits, executionTimeSelections, startEventSelections, videos]);

    useEffect(() => {


        if(planState === null)
            return;

        if(loaded){
            return;
        }

       doCalculation(planState);

    }, [doCalculation, planState, videos, departments, loaded]);




    //updateLearningPlan
    useEffect(() => {

        breadCrumb.setBackButton(props.onReturnBackToView);
        breadCrumb.setTitle(props.editMode ? props.editMode.description : I18N('createLearningPlan', language));
        breadCrumb.setAdditionalCrumb({title: props.editMode ? props.editMode.description : I18N('createLearningPlan', language), onClick: undefined});
        breadCrumb.setChildren([
            {
                color: 'transparent',
                key: 'learningPlanSaver',
                title: '',
                onClick: undefined
            }
        ])

    }, [language, props.onReturnBackToView, props.editMode, props.onFinish]);


    const removeLearningPlanStepSaver = useCallback(async () => {

        //  @IsString()
        //   description: string;
        //   @IsBoolean()
        //   status: boolean;
        //   @IsArray()
        //   department: number[];
        //   @IsArray()
        //   steps: {
        //     startEvent: string;
        //     execution: string;
        //     videos: { label: string; value: number }[];
        //     deadline: string;
        //   }[];
        //   @IsString()
        //   trigger: string;
        //   @IsOptional()
        //   @IsArray()
        //   companies: number[]

        const changedPlanState = {...planState};

        changedPlanState.status = !!planState.status;
        changedPlanState.department = JSON.parse(planState.department)
        changedPlanState.companies = planState.learningplans_companies.map((i) => i.company_id);
        let changedSteps:any = [...steps];
        changedSteps = changedSteps.map((i) => {
            return {
                ...i,
                execution: i.execution.value,
                startEvent: i.startEvent.value,
                deadline: i.deadline.value
            }
        })

        await learnPlanRepository.update({...changedPlanState, steps: changedSteps}, props.editMode.id).then((response) => {
            enqueueSnackbar(I18N('stepDeleted', language), {variant: "success"})

        }).catch((e) => {
            enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})

        });




    }, [steps, planState]);

    const deleteStep = useCallback((stepID) => {

        steps.splice(stepID, 1)
        setSteps([...steps]);
        removeLearningPlanStepSaver();
    }, [steps, removeLearningPlanStepSaver])

    return (
        <React.Fragment>

            <GeneralInfoBar isOpen={generalInfoOpen} setIsOpen={() => setGeneralInfoOpen(false)} plan={props.editMode} setContent={setPlanState} />
            <StepBar isOpen={isStepOpen} setIsOpen={() => setIsStepOpen(false)} step={clickedStep} mainID={props.editMode.id} setContent={(planData) => doCalculation({...planData})} newStep={clickedStep === null ? (steps.length + 1) : 0} />
            <ViewBox title={I18N('generalInformation', language)} rightComponent={(<EditIcon onClick={() => setGeneralInfoOpen(true)} />)}>
                <table style={{borderSpacing: 8}}>
                    <th>
                        <td style={{width: 200}}></td>
                        <td></td>
                    </th>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('title', language)}
                        </td>
                        <td style={{fontSize: 14, fontWeight: "bold"}}>
                            {planState.description}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('trigger', language)}
                        </td>
                        <td>
                            <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{I18N('trigger_'+planState.trigger, language)}</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('companies', language)}
                        </td>
                        <td style={{fontSize: 14,}}>

                            {planState.learningplans_companies?.map((i)=> <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{i.company.name}</div>)}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('departments', language)}
                        </td>
                        <td style={{fontSize: 14,}}>

                            {planState.depList?.map((i)=> <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{i.name}</div>)}
                        </td>
                    </tr>

                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('status', language)}
                        </td>
                        <td style={{fontSize: 14,}}>
                            <div style={{background: planState.status ? '#25AF60' : "#c91313", paddingRight: 10, color: "#fff", paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{planState.status ? I18N('active', language) : I18N('inactive', language)}</div>
                        </td>
                    </tr>
                </table>
            </ViewBox>


            <ViewBox rightComponent={(<div style={{cursor: "pointer"}} onClick={() => {
                setClickedStep(null);
                setIsStepOpen(true);
            }}><img src={plusLearningPlan} /></div>)} title={I18N('sequence', language)}>

                <Timeline    sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}>
                    {steps.map((step,key) => {
                        console.log(step);
                        return (
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{
                                        width: 20,
                                        height:20
                                    }} color={"info"} />
                                    {steps.length !== key + 1 && <TimelineConnector />}
                                </TimelineSeparator>
                                <TimelineContent>

                                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                        <div>
                                            <p style={{fontSize: 14, margin:0, padding:0, fontWeight: "bold"}}>{step.videos.map((i) => i?.label).join(', ')}</p>
                                            <p style={{fontSize: 12, margin:0, padding:0,}}>{step.startEvent?.label}, {step.execution?.label}, {step.deadline?.label}</p>
                                        </div>
                                        <div style={{marginLeft: 20, display: "flex", justifyContent: "center"}}>
                                            <EditIcon onClick={() => {
                                                setClickedStep({
                                                    step: step,
                                                    key: key,
                                                    stepNumber: key + 1
                                                });
                                                setIsStepOpen(true);
                                            }} />
                                            {key !== 0 && (
                                                <DeleteIcon size={12} ask={true} row={"noRow"} callBack={async (row) => {
                                                    deleteStep(key);
                                                }
                                                } />
                                            )}

                                        </div>

                                    </div>

                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}

                </Timeline>

            </ViewBox>

        </React.Fragment>

    )

}
