import React, {useCallback, useState} from "react";
import {TextField} from "@mui/material";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {useTheme} from "@mui/material";
import ColorModeContext from "../ColorMode";

interface Props {
    title?: string
    searchBar?: (text) => void;
    children: any;
    width?: number;
    customClass?: string;
    rightComponent?: any;
    padding?: number;
    style?: any
}


export default function ViewBox(props: Props){
    const theme = useTheme<any>();
    const colorMode = React.useContext(ColorModeContext);
    const language = useLanguageState(state => state.language);
    const [searchText, setSearchText] = useState('');

    const onChangeSearch = useCallback((event) => {

        setSearchText(event.target.value);
        props.searchBar(event.target.value);

    }, [props]);

    return (

            <div className={props.customClass !== undefined ? props.customClass : `viewBox ${props.padding !== undefined ? 'viewBoxX2' : ''}`} style={{ borderColor: theme.palette.borderColor , background:theme.palette.mode ==='dark' ? '#000811' : theme.palette.background.default, color: theme.palette.color, width: props.width !== undefined ? props.width + '%' : undefined, padding: props.padding !== undefined ? props.padding : undefined, ...props.style}}>
                <div className="boxHeader">
                    {props.title !== undefined && (
                        <div style={{display: "flex", justifyContent: "space-between", width: '100%'}}>
                            <div className="viewBoxTitle">{props.title}</div>
                            {props.rightComponent && (
                                props.rightComponent
                            )}
                        </div>

                    )}
                    {props.searchBar !== undefined && (
                        <TextField value={searchText} placeholder={I18N('search', language)} onChange={(event) => onChangeSearch(event)} />
                    )}
                </div>
                {props.children}
            </div>


    )

}
