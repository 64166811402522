import axios from "axios";
import {BACKEND_URL} from "../assets/AppConstants";

export default function EGRCSender(token){

    return axios.get(BACKEND_URL + '/videos/egrcData', {
        params: {
            token: token
        }
    }).then((response) => {
        return response.data;
    }).catch((err) => console.log(err));

}

export function EGRCRequestFile(contentKey: string, token){
    return axios.post(BACKEND_URL + '/videos/egrcData/requestFile', {key: contentKey}, {
        params: {
            token: token
        }
    }).then((response) => {
        return response.data
    }).catch((err) => {
        return {
            status: false
        }
    });
}