import React, { useCallback, useEffect, useState } from 'react';
import UploadList from "./UploadList";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import { UploadContentOperation } from "./UploadContentOperation";
import { VideoUploadRepository } from "../Repositories/VideoUploadRepository";
import { UploadContentEdit } from "./UploadContentEdit";
import { useUploadingState } from "../States/CurrentlyUploading";
import {useSnackbar} from "notistack";
const uploadContentRepository = new VideoUploadRepository();



type Props = {
    style: any;
    isMobile: () => boolean
}



export function UploadContentTab(props: Props) {
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editContent, setEditContent] = useState<null | any>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);
    const setUploadingPage = useUploadingState(state => state.setInUploadingPage);
    const {enqueueSnackbar} = useSnackbar();
    const [formState, setFormState] = useState({
        name: '',
        companies: [],
        image: ''
    });


    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const backToContentTable = () => {
        setCreateOpen(false);
        setUploadingPage(false);
        setEditContent(null);
        scrollTop();
    }


    const saveContent = useCallback(async () => {
        if (formState.name === '' || formState.companies.length < 1 || formState.image === '') {
            enqueueSnackbar(I18N('pleaseFill', language, [{ key: 'name', value: I18N('content', language) }]), {variant: "success"})
            return;
        }
        setFormLoading(true);

        await uploadContentRepository.update(formState.name, formState.companies, formState.image, Number(editContent.id)).then(async (response) => {

            setFormLoading(false);

            enqueueSnackbar(I18N('successfullyUpdatedReturning', language, [{ key: 'name', value: I18N('content', language) }]), {variant: "success"})

            backToContentTable();
        }).catch(async (err) => {

            setFormLoading(false);
            enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})
        })
        setFormLoading(false);

    }, [formState, editContent, language]);


    useEffect(() => {
        return () => {
            setUploadingPage(false);
        }
    }, []);

    return (
        <div style={props.style}>
            <div className="flex-row-button">
                {createOpen && editContent !== null && (<FormButton text={I18N('updateContent', language)} onClick={saveContent} isLoading={formLoading} />)}
                <FormButton text={(createOpen ? I18N("back", language) : I18N('new', language))} onClick={() => {
                    if (createOpen) {
                        setEditContent(null);
                    }
                    setUploadingPage(!createOpen);
                    setCreateOpen(!createOpen);
                    scrollTop();
                }} isLoading={false} />
            </div>

            {!createOpen ?
                <UploadList editMode={(item) => {
                    setEditContent(item);
                    setCreateOpen(true);
                    scrollTop();
                }} />
                : (
                    <React.Fragment>
                        {editContent !== null ? (
                            <UploadContentEdit setFormChange={setFormState} editMode={editContent} backCallback={backToContentTable} />
                        ) : (
                            <UploadContentOperation backCallback={backToContentTable} />
                        )}
                    </React.Fragment>

                )}
        </div>
    )

}
