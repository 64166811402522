import {DocumentsRepository} from "../Repositories/DocumentsRepository";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ViewBox from "../ViewComponents/ViewBox";
import DocBookSVG from '../assets/docBook.svg';
import {useLanguageState} from "../States/LanguageState";
import {I18N} from "../i18n/i18n";
import {UsersRepository} from "../Repositories/UsersRepository";
import {UserType} from "../Types/UserType";
import {Searchbar} from "../Searchbar/Searchbar";
import {BACKEND_URL} from "../assets/AppConstants";
import {standartColors} from "../assets/colors/Colors";
import FormButton from "../ViewComponents/FormButton";
import EditDocument from "./EditDocument";
import {ClassificationDecider, StatusDecider} from "./StatusDecider";
import DownloadButton from '../assets/downloadButton.svg';
import FullScreenOpen from '../assets/fullscreenOpen.svg';
import CSVG from '../assets/chapter2.svg';
import {tableOfContentCalculator} from "../Helpers/TableOfContentsCalculator";
import slugify from "../Helpers/Slugify";
import {useTheme} from "@mui/material";

const usersRepository = new UsersRepository();
const documentsRepository = new DocumentsRepository();

export default function Documents(props: any){

    const theme = useTheme();
    const [document, setDocument] = useState(null);
    const [history, setHistory] = useState([]);
    const [users, setUsers] = useState<UserType[]>([]);
    const [responsibleUser, setResponsibleUser] = useState<null | UserType>(null);
    const [implementationUsers, setImplementationUsers] = useState<UserType[] | null>(null)
    const contentRef = useRef<null | HTMLDivElement>(null);
    const language = useLanguageState(state => state.language);
    const [editMode, setEditMode] = useState(false);
    const [exportHistory, setExportHistory] = useState([]);
    const itemRef = useRef<null | HTMLDivElement>(null);
    const [inWork, setInwork] = useState(null);
    const [inWorkHistory, setInWorkHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [documentID, setDocumentID] = useState(null);
    const [availableDocuments, setAvailableDocuments] = useState([]);
    const [inWorkID, setInWorkID] = useState(null);
    const [availableInWorks, setAvailableInWorks] = useState([]);
    const [availableExportHistory, setAvailableExportHistory] = useState([]);
    const [availableInWorkHistory, setAvailableInWorkHistory] = useState([]);
    const [availableHistory, setAvailableHistory] = useState([]);
    const [showContents, setShowContents] = useState(false);



    useEffect(() => {

        if(availableHistory.length > 0 && document){
            setHistory(availableHistory.filter((items) => items.oID === document._id));
        }else{
            setHistory([]);
        }

    }, [availableHistory, document]);

    useEffect(() => {

        if(availableInWorkHistory.length > 0 && inWork){
            setInWorkHistory(availableInWorkHistory.filter(item => item.oID === inWork._id));
        }else{
            setInWorkHistory([]);
        }

    }, [availableInWorkHistory, inWork]);


    useEffect(() => {

        if(availableExportHistory.length > 0 && document){
            setExportHistory(availableExportHistory.filter((item) => item.document_id === document._id));
        }else{
            setExportHistory([]);
        }

    }, [availableExportHistory, document]);

    useEffect(() => {

        if(availableInWorks.length > 0 && document){
            setInWorkID(availableInWorks.find((inWorkItem) => inWorkItem.oID === document._id)?._id);
        }

    }, [availableInWorks, document]);

    useEffect(() => {
        if(availableInWorks.length > 0 && inWorkID){
            console.log("ava", inWorkID, availableInWorks)
            setInwork(availableInWorks.find((inWorkData) => inWorkData._id === inWorkID));
        }
    }, [availableInWorks, inWorkID]);


    useEffect(() => {
        if(availableDocuments.length > 0){
            setDocumentID(availableDocuments[availableDocuments.length - 1]._id);
        }
    }, [availableDocuments]);

    useEffect(() => {

        //console.log(documentID);
        if(documentID === "create"){
            setDocument(null);
        } if(documentID === null){
            setDocument(null);
        }else{
            setDocument(availableDocuments.find((doc) => doc._id === documentID));
        }
    }, [availableDocuments, documentID]);


    const tableOfContentsData = useCallback(() => {
        return tableOfContentCalculator(contentRef);
    }, [contentRef]);

    useEffect(() => {
        tableOfContentsData();
    }, [tableOfContentsData]);



    const setUsersData = useCallback(() => {

        if(document == null){
            return false
        }
        if(users.length < 1){
            return false;
        }

        if(document.responsibleUsers.length < 1 || document.implementationUsers.length < 1){
            return false;
        }
        console.log(users, document.implementationUsers)
        setImplementationUsers(users.filter((item) => {
            return document.implementationUsers.includes(item.id);
        }))
        setResponsibleUser(users.find((item) => item.id === document.responsibleUsers[0]));

    }, [users, document]);

    useEffect(() => {

        setUsersData();

    }, [setUsersData])


    useEffect(() => {
        loadDocuments().then(undefined);
        loadUsers().then(undefined);
    }, []);

    const loadUsers = useCallback(async () => {
        setLoading(true);
        const userData = await usersRepository.getAll();
        setUsers(userData as UserType[]);
        setLoading(false);
    }, []);



    const loadDocuments = useCallback(async () => {
        setLoading(true);
        const docs = await documentsRepository.getAll();
        //setDocument(docs.availableDocuments[0]);
        setAvailableDocuments(docs.availableDocuments);
        //setInwork(docs.inWork);
        setAvailableInWorks(docs.inWork)
        const reverseHistory = docs.history.reverse();
        //setHistory(reverseHistory);
        setAvailableHistory(reverseHistory);
        const reverseExportHistory = docs.exportHistory.reverse();
        //setExportHistory(reverseExportHistory);
        setAvailableExportHistory(reverseExportHistory);
        const reverseInWork = docs.inWorkHistory.reverse();
        //setInWorkHistory(reverseInWork);
        setAvailableInWorkHistory(reverseInWork);
        //setLoading(false);
    }, []);


    const exportContent = useCallback(async () => {
        const dataFil = JSON.parse(JSON.stringify(responsibleUser));
        const dataImp = JSON.parse(JSON.stringify(implementationUsers));
        if(dataFil.innerData.photo === ''){
            dataFil.innerData.photo = BACKEND_URL + '/images/no-user-image.gif'
        }else{
            dataFil.innerData.photo = BACKEND_URL + '/users' + dataFil.innerData.photo;
        }

        dataImp.map((data) => {

            if(data.innerData.photo === ''){
                data.innerData.photo = BACKEND_URL + '/images/no-user-image.gif';
            }else{
                data.innerData.photo = BACKEND_URL + '/users' + data.innerData.photo;
            }

            return data;
        })

        setLoading(true);
        await documentsRepository.exportPDF({oID: document._id, document, implementationUsers: dataImp, responsibleUser: dataFil, status: StatusDecider(document.status, language), classification: ClassificationDecider(document.classification, language)}).catch(() => setLoading(false))
        setLoading(false);
        await loadDocuments();
        setLoading(false);
    }, [responsibleUser, implementationUsers, document, language, loadDocuments]);

    const fullscreenToggle = useCallback(async () => {

        if(itemRef.current !== null){
            //@ts-ignore
            await window.document.querySelector('#documentHolderItem').webkitRequestFullScreen();
        }


    }, [itemRef]);


    const createOnOpen = useCallback(async () => {
        setLoading(true);
        const createRequest = await documentsRepository.openInCrate().catch((err) => alert(err));
        if(createRequest){
            const docs = await loadDocuments();
            setDocumentID(createRequest.docID);
            setEditMode(true);

        }
        setLoading(false);

    }, [loadDocuments]);


    const regexReplaceDocument = useCallback(() => {

        const regex = /<h([1-6]).*?>(.*?)<\/h[1-6]>/gi;

        if(document){
            return document.document.replace(regex, (match, capture, capture2) => {
                console.log(match, capture, capture2);
                return `<section id="heading-${capture}-${slugify(capture2)}"><h${capture}>${capture2}</h${capture}></section>`;
            });
        }else{
            return "";
        }

    }, [document]);

    useEffect(() => {
        console.log(regexReplaceDocument());
    }, [regexReplaceDocument]);

    return (
        <div style={props.style}>
            <Searchbar />


            <div style={{marginLeft: 25, width: '93%'}}>
                <div style={{fontWeight: 600, height: 18, fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', color: theme.palette.mode === 'dark' ? '#fff' : standartColors.black}}>
                    {I18N("documents", language)}
                    <ul className="breadCrumb">
                        <li onClick={() => window.location.href = '/Dashboard'}>{I18N('dashboard', language)}</li>
                        <li className="divider" />
                        <li>{I18N('documents', language)}</li>
                    </ul>
                </div>
                {!editMode && (
                    <div className="flex-row-button">
                        <FormButton text={documentID === 'create' ? I18N('create', language) : I18N('documentEditMode', language)} onClick={async () => {
                            if(documentID === 'create'){
                                await createOnOpen()
                            }else{
                                setEditMode(true)
                            }
                        }} isLoading={loading} withLoadingInFullScreen={true} />
                    </div>
                )}

                {editMode ? (
                    <EditDocument tableOfContents={tableOfContentsData()} exportHistory={exportHistory} onUpdate={() => loadDocuments().then(() => setLoading(false))} history={history} inWorkHistory={inWorkHistory} setEditMode={setEditMode} document={document} inWork={inWork} users={users} />
                ): (
                    <div id="documentHolderItem" ref={itemRef}>
                        {document && (
                            <ViewBox>
                                <div style={{padding: 15, position: "relative"}}>
                                    <div style={{height: 300, padding: 20, position: "relative", background: 'linear-gradient(90.09deg, rgba(47, 54, 64, 0.5) 0.08%, rgba(47, 54, 64, 0.25) 99.93%)', borderRadius: '10px 10px 0 0', border: '1px solid #E0E0E0'}}>

                                        <img src={DownloadButton} style={{position: "absolute", right: 10, top: 10, cursor: "pointer"}} onClick={exportContent} />
                                        <img src={FullScreenOpen} style={{position: "absolute", right: 40, top: 10, cursor: "pointer"}} onClick={fullscreenToggle} />

                                        <img src={DocBookSVG} style={{position: "absolute", opacity: 0.5, right: 0}} />

                                        <p style={{fontSize: 48, margin:0, paddingTop: 20, color: "#fff"}}>{document?.title}</p>

                                        <div style={{position: "absolute", bottom: 20, width: '98%'}}>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: '100%'}}>
                                                <div style={{justifyContent: "flex-start", display: "flex"}}>
                                                    <div>
                                                        <p style={{fontWeight: "bold", fontSize: 14, color: "#fff"}}>{I18N('author', language)}</p>
                                                        {responsibleUser !== null && (
                                                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                                <img src={responsibleUser?.innerData?.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' : BACKEND_URL + '/users' +responsibleUser?.innerData?.photo} style={{height: 40, width: 40, borderRadius: 50, cursor: "pointer" }}  alt={"userImage"}/>
                                                                <p style={{fontSize: 12, fontWeight: 400, color: "#fff", marginLeft: 10}}>{responsibleUser?.firstName} {responsibleUser?.lastName}</p>
                                                            </div>
                                                        )}
                                                        <p style={{fontSize: 10, color: "#fff"}}>{I18N('responsibleUser', language)}</p>
                                                    </div>

                                                    <div style={{marginLeft: 60}}>
                                                        <div style={{marginTop: 44}} />
                                                        {implementationUsers !== null && (
                                                            <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                                                {implementationUsers.map((user) => (
                                                                    <img src={user.innerData?.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' : BACKEND_URL + '/users' +user.innerData?.photo} style={{height: 40, width: 40, borderRadius: 50, cursor: "pointer" }}  alt={"userImage"}/>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <p style={{fontSize: 10, color: "#fff"}}>{I18N('implementationUser', language)}</p>

                                                    </div>

                                                </div>
                                                <div style={{position: "absolute", bottom:-20, fontSize: 12, fontWeight: "bold", color: "#fff", left: '38%', alignItems: "center",  display: "flex", justifyContent: "center", flexDirection: "row", }}>
                                                    <p style={{display: "flex"}}>{I18N('version', language)}: <p style={{margin:0, fontWeight: "normal", paddingTop: 2, fontSize: 10,}}> {document?.__v + 1} | 20.05.00</p></p>
                                                    <p style={{display: "flex", marginLeft: 40}}>{I18N('classification', language)}: <p style={{margin:0, paddingTop: 2, fontSize: 10, fontWeight: "normal"}}> {ClassificationDecider(document?.classification, language)}</p></p>
                                                    <p  style={{display: "flex", marginLeft: 40}}>{I18N('status', language)}: <p style={{margin: 0, paddingTop: 2, fontSize: 10, fontWeight: "normal"}}> {StatusDecider(document?.status, language)}</p></p>
                                                </div>

                                                <div style={{textAlign: "center", bottom: 0, position: "absolute", right: 0,}}>
                                                    <p style={{fontSize: 14, color: "#fff", fontWeight: "bold"}}>
                                                        {I18N("validUntil", language)}
                                                    </p>
                                                    <p style={{fontSize: 12, color: "#fff"}}>
                                                        {document?.deadline}
                                                    </p>
                                                </div>


                                            </div>

                                        </div>

                                        <div onMouseEnter={() => setShowContents(true)} onMouseLeave={() => setShowContents(false)} style={{zIndex:9999, background: '#FFE16A', borderRadius: 10, position: "fixed", right: 220, bottom: 20, width: 40, height: 40, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                            <img src={CSVG} />

                                            {showContents && (
                                                <ol style={{zIndex:9999, background: "#fff", width: 150, borderRadius: 10, position: "absolute", right: 10, bottom: 22, boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)'}}>
                                                    <p style={{fontSize: 14, fontWeight: "bold", marginBottom: 5, padding: 0, marginLeft: -20}}>{I18N('tableOfContents', language)}</p>
                                                    {tableOfContentsData().map((item) => (
                                                        <li style={{paddingRight: 10, paddingLeft: 10, fontSize: 16, marginBottom:8, padding: 0}}>
                                                            <a href={`#heading-${item.tag}-${slugify(item.itemTitle)}`} style={{color: "#000", textDecoration: "none"}}>{item.itemTitle}</a>
                                                            {item.child.length > 0 && (
                                                                <ol type="a" style={{padding: 0, paddingLeft: 10, marginBottom: 5}}>
                                                                    {item.child.map((inner) => (
                                                                        <li style={{fontSize: 16}}>
                                                                           <a href={`#heading-${inner.tag}-${slugify(inner.itemTitle)}`} style={{color: "#000", textDecoration: "none"}}>{inner.itemTitle}</a>
                                                                        </li>
                                                                    ))}
                                                                </ol>
                                                            )}
                                                        </li>
                                                    ))}
                                                </ol>
                                            )}
                                        </div>

                                    </div>


                                </div>


                                <div style={{padding: 15, position: "relative"}} >
                                    <div ref={contentRef} dangerouslySetInnerHTML={{__html: regexReplaceDocument()}}>

                                    </div>


                                </div>
                            </ViewBox>

                        )}
                    </div>
                )}


            </div>



        </div>


    );
}
