import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import { EditorState, convertToRaw, ContentState, Modifier} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer, SidebarDrawerBig} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import ViewBox from "../../../../ViewComponents/ViewBox";
import {Replacements} from "../../../Templates/EmailOperation";
import {BACKEND_URL} from "../../../../assets/AppConstants";
import {VideoRepository} from "../../../../Repositories/VideoRepository";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";

const videoRepository = new VideoRepository();
const phishingRepository = new PhishingRepository();

const DataContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void;}) => {
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [htmlState, setHtmlState] = useState('');


    const saveChanges = useCallback(() => {

        setLoadingData(true);
        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))


        phishingRepository.updateEmailData({data: props.content.data_type === 'html' ? htmlState : editorChanger}, props.content.id).then((response) => {
            props.setContent(response);
            props.setIsOpen(false);
            setLoadingData(false);
        }).catch((er) => {
            setLoadingData(false);
            console.log(er);
        })


    }, [props.content, editorState, htmlState]);

    useEffect(() => {
        if(props.content.data_type === 'html'){
            setHtmlState(props.content.data);
        }else{
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.content.data).contentBlocks)));
        }
    }, [props.content]);


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawerBig>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

                                <div style={{width: '68%'}}>
                                    {props.content.data_type === 'html' ? (
                                        <div><textarea style={{background: theme.palette.mode === 'dark' && theme.palette.background.default, color: theme.palette.mode === 'dark' && '#fff', width: '100%'}} onChange={(e) => setHtmlState(e.target.value)} rows={20}  value={htmlState}/></div>
                                    ): (
                                        <Editor
                                            toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                                            editorState={editorState}
                                            toolbar={{
                                                image: {
                                                    uploadEnabled: true,
                                                    uploadCallback: async (e) => {
                                                        return await videoRepository.uploadPicture(e, true).then((response) => {
                                                            return { data: { link: BACKEND_URL + response } }
                                                        })
                                                    },
                                                    previewImage: true,
                                                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                    alt: { present: false, mandatory: false },
                                                    defaultSize: {
                                                        height: 'auto',
                                                        width: 'auto',
                                                    },
                                                },
                                            }}
                                            toolbarCustomButtons={[<Replacements language={language} />]}
                                            wrapperClassName="classEditorEmail"
                                            editorClassName="classEditorInner"

                                            onEditorStateChange={(data) => {
                                                setEditorState(data);
                                            }}
                                        />
                                    )}

                                </div>

                                <ViewBox width={30} title={I18N('placeholderList', language)} style={{height: 240, marginTop: 0}}>
                                    <table style={{borderSpacing: 15}}>
                                        <tr>
                                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12}}>{`{{contact.FIRSTNAME}}`}</td>
                                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}>{I18N('usersFirstname',language)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{`{{contact.LASTNAME}}`}</td>
                                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{I18N('usersLastname', language)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{`{{contact.LINK}}`}</td>
                                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{I18N('connectingToUserListLink', language)}</td>
                                        </tr>
                                    </table>
                                </ViewBox>

                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawerBig>
            </Drawer>
        </SidebarContainer>
    );
};

export default DataContentBar;