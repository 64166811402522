import {AbstractRepository} from "./abstract-repository";
import axios from "axios";

export class LanguageRepository implements AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return Promise.resolve(undefined);
    }

    getAll(): Promise<any[]> {

        return new Promise((resolve, reject) => {

            axios.get('/languages').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return Promise.resolve(undefined);
    }

    update(object: any): Promise<any> {
        return Promise.resolve(undefined);
    }



}
