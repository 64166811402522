import {I18N} from "../i18n/i18n";
import videoIcon from '../assets/Video.svg'
import workIcon from '../assets/Work.svg'
import messageIcon from '../assets/Message.svg'


export default function NotificationDecider(notification, language){

    if(notification.pdf_id !== null || notification.video_id !== null){
        const notSplit = notification.content.split('|');

        let link = '';
        let icon = '';
        if(notification.pdf_id !== null){
            link = `/LearnPdf/${notification.pdf_id}`
            icon = videoIcon;
        }else {
            link = `/Lernvideos/${notification.video_id}`
            icon = workIcon
        }

        return {text: I18N(notSplit[0], language, [{key: 'name', value: notSplit[1]}]), link: link, icon: icon}
    }else{
        return {text: notification.content, link: notification.url, icon: messageIcon, target: '_blank'}
    }


}
