import {formatSizeUnits} from "../Helpers/ByteToUnit";
import React from "react";
import {useUploadingState} from "../States/CurrentlyUploading";
import ReactTooltip from "react-tooltip";
import DeleteIcon from '../assets/times.svg'

export default function UploadingListView(props: {
    sideBar?: boolean
}){

    const uploadingState = useUploadingState();

    return (
        <ul className="uploadingContent">
            <div className="uploadHeader">Content Upload</div>
            <ReactTooltip />
            {uploadingState.uploading !== null && uploadingState.uploading.map((uploading, index) => {
                return (<li>
                    <div className="liInside">
                        {props.sideBar ? (
                            <p data-tip={uploading.name}>{ uploading.name.substr(0, 20) + '...'}</p>
                        ): (
                            <p>{uploading.name}</p>
                        )}

                        <p>{(uploading.loaded === undefined ? 0 + 'MB' : formatSizeUnits(uploading.loaded) + ' / ' + formatSizeUnits(uploading.size))} {uploading.progress === 100 && <img onClick={() => uploadingState.removeFromList(index)} style={{marginLeft: 10}} src={DeleteIcon} width={13} height={13} />}</p>
                    </div>
                    <p style={{paddingTop: 0, marginTop:0}}>{uploading.progress === 100 && uploading.finished !== undefined ? ('Der Lerninhalt wurde vollständig hochgeladen') : 'Progressing File...'}</p>
                    <div className="uploadBar">
                        <div className="innerBar" style={{width: uploading.progress === undefined ? 1 + '%' : uploading.progress + '%'}}>
                            {uploading.progress === undefined ? 1 : uploading.progress}%
                        </div>
                    </div>
                </li>)
            })}
        </ul>
    )

}
