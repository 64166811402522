import ViewBox from "../../ViewComponents/ViewBox";
import EnvChart from "./Charts/EnvChart";
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {useCallback, useEffect, useState} from "react";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import NumberOfUsers from "./Charts/NumberOfUsers";
import Licences from "./Charts/Licences";
import NumberOfVideos from "./Charts/NumberOfVideos";

const companyRepository = new CompanyRepository();

export default function Dashboard(){

    const language = useLanguageState(state => state.language)
    const [companies, setCompanies] = useState([]);

    const loadData = useCallback(async () => {
        let companyList = await companyRepository.getAllAdmin();
        companyList = companyList.filter((company) => {
            return !(company.ID === 1 || company.ID === 2)
        })

        setCompanies(companyList);
    }, []);

    useEffect(() => {
        loadData().then(undefined)
    }, [loadData]);


    return (
        <div>
            <div className="flex-row">
                <ViewBox title={I18N('clientsWithEnv', language)}>
                    <EnvChart companies={companies} />
                </ViewBox>
                <ViewBox title={I18N('numberOfActiveUsers', language)}>
                    <NumberOfUsers companies={companies} />
                </ViewBox>
            </div>

            <div className="flex-row">
                <ViewBox title={I18N('licencesChart', language)}>
                    <Licences companies={companies} />
                </ViewBox>
                <ViewBox title={I18N('numberOfVideos', language)}>
                    <NumberOfVideos companies={companies} />
                </ViewBox>
            </div>

        </div>
    )

}