import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import {useTheme} from "@mui/material";
import FormButton from "../FormButton";
import {SidebarContainer, SidebarDrawer} from "./Drawer";
import {TrophiesRepository} from "../../Repositories/TrophiesRepository";
import {CategoriesRepository} from "../../Repositories/CategoriesRepository";
import {QuizRepository} from "../../Repositories/QuizRepository";
import {TrophiesTypes} from "../../Types/TrophiesTypes";
import {CategoryType} from "../../Types/CategoryType";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import Select from "react-select/creatable";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {OptionTypeBase} from "react-select/src/types";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import {DrawerConfigSx} from "../DrawerConfigSx";
import ViewBox from "../ViewBox";
import {useLoginState} from "../../States/UserState";
import SelectAutocomplete from "../SelectAutoComplete";

const videoRepository = new VideoRepository();
const companyRepository = new CompanyRepository();


const CompaniesBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any;setContent: (data) => void;}) => {
    const language = useLanguageState();
    const theme = useTheme();
    const [companies, setCompanies] = useState([]);
    const authService = useLoginState();
    const [loadingData, setLoadingData] = useState(false);

    const [formState, setFormState] = useState<OptionsType<any>>(undefined);

    const loadCompanies = useCallback( async () => {

        let subList = await companyRepository.getSubCompanies();
        subList = subList.filter((a) => authService.company.includes(a.id));
        setCompanies(subList.map((i) => {
            return {label: i.name, value: i.id}
        }));
        return subList;
    }, [authService]);


    const updateStateWithResponse = useCallback((companies: any[]) => {

        const selectedFromContent = props.content.videos_companies.map((i) => i.company_id);

        const findSelectedCompanyList = companies.filter((i) => selectedFromContent.includes(i.id)).map((i) => {
            return {label: i.name, value: i.id};
        });

        setFormState(findSelectedCompanyList);

    }, [props.content]);

    useEffect(() => {
        setLoadingData(true)
        Promise.all([loadCompanies()]).then((response) => {
            updateStateWithResponse(response[0]);
            setLoadingData(false)
        }).catch((error) => {
            console.log("error");
            setLoadingData(false)
        })

    }, [updateStateWithResponse]);



    const saveChanges = useCallback(async () => {
        setLoadingData(true);

        await videoRepository.updateCompanies({
            companies: formState.map((i) => i.value)
        }, props.content.id).then((response) => {
            console.log("success");
            props.setIsOpen(false)
            props.setContent(response);
            setLoadingData(false);
        }).catch((err) => {
            console.log('err');
            setLoadingData(false);
        })

        setLoadingData(false);
    }, [formState, props.content]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeCompanies', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true}  icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <div className="box"  style={{marginTop: 5}}>


                        <ViewBox>
                            <div className="marginTop20" style={{width: '100%'}}>
                                <div className="viewBoxTitle">{I18N('company', language.language)}</div>
                                <SelectAutocomplete
                                    multiple={true}
                                    maxTags={3}
                                    options={companies.map((comp) => { return {value: comp.value, label: comp.label}})}
                                    value={formState}
                                    onChange={(item) => setFormState(item)}
                                />
                                {/*<NoCreateSelect*/}
                                {/*    styles={{valueContainer: (styles) => {*/}
                                {/*            return {*/}
                                {/*                ...styles,*/}
                                {/*                height: 50,*/}
                                {/*                scrollbarWidth: "none",*/}

                                {/*                msOverflowStyle: "none",*/}
                                {/*                overflowX: "hidden",*/}
                                {/*                overflowY: "scroll"*/}
                                {/*            };*/}
                                {/*        },container: (styles) => {*/}
                                {/*            return {*/}
                                {/*                ...styles,*/}
                                {/*                width: '100%',*/}
                                {/*            };*/}
                                {/*        }}}*/}
                                {/*    theme={(template) => {*/}
                                {/*        return {*/}
                                {/*            ...template,*/}
                                {/*            colors: {*/}
                                {/*                ...template.colors,*/}
                                {/*                ...colorForSelect(theme)*/}
                                {/*            }*/}
                                {/*        }*/}
                                {/*    }} value={formState} isMulti={true} options={companies} onChange={(item) => setFormState(item)} />*/}

                            </div>

                        </ViewBox>

                    </div>
                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default CompaniesBar;
