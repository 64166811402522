import React, {useCallback, useEffect, useState} from 'react';
import { standartColors } from '../assets/colors/Colors';
import { Searchbar } from '../Searchbar/Searchbar';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";
import TrophyCircles from "../ViewComponents/Trophy/TrophyCircles";
import UserStatsCircle from "../ViewComponents/UserStatsCircle";
import UserRankingList from "../ViewComponents/Rankings/UserRankingList";
import DepartmentRankingList from "../ViewComponents/Rankings/DepartmentRankingList";
import {UsersRepository} from "../Repositories/UsersRepository";
import {DepartmentsRepository} from "../Repositories/DepartmentsRepository";
import {useLoginState} from "../States/UserState";
import {getToken} from "../Repositories/Cookies";
import {WelcomeBox} from "../ViewComponents/WelcomeBox";
import {UserChart} from "../ViewComponents/UserChart";
import {useTheme} from "@mui/material";
import HeaderMenu from "../ViewComponents/NewHeaderMenu";
import BreadCrumb from "../ViewComponents/BreadCrumb";
import {useBreadCrumb} from "../States/BreadCrumbState";

const videoRepository = new VideoRepository();
const usersRepository = new UsersRepository();
const departmentsRepository = new DepartmentsRepository();

type Props = {
    style: any;
    isMobile: () => boolean
}


export function DashboardView(props: Props) {
    const language = useLanguageState(state => state.language);

    const [contentData, setContentData] = useState([]);
    const [topUserRanking, setTopUserRanking] = useState([]);
    const [top10Departments, setTop10Departments] = useState([]);
    const loginState = useLoginState();
    const clientName = getToken();
    const theme = useTheme();
    const breadCrumb = useBreadCrumb();
    const getUserContentData = useCallback(async () => {

        const content = await videoRepository.getUserContent();
        const top10 = await usersRepository.getTop10();
        const top10dep = await departmentsRepository.getTop10();

        setContentData(content);
        setTopUserRanking(top10);
        setTop10Departments(top10dep);

    }, []);

    useEffect(() => {
        getUserContentData().then(undefined);
    }, [getUserContentData]);

    useEffect(() => {

        window.scrollTo({
            behavior: "smooth",
            top: 0
        });

    }, []);

    // useEffect(() => {
    //     breadCrumb.setTitle(I18N('dashboard', language));
    //     breadCrumb.setCrumb([
    //         {title: I18N('dashboard', language), onClick: undefined},
    //     ]);
    //     breadCrumb.setBackButton(null);
    //     breadCrumb.setChildren([]);
    //
    // }, []);

        return (
            <div style={{...props.style}}>
                <Searchbar />

                <div style={{...styles.container, color: theme.palette.mode === 'dark' ? "#fff" : standartColors.black}}>
                    <div className="flex-row" style={{marginTop: 50}}>
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewWelcomeContent') && (
                        <WelcomeBox type={1} contentData={contentData} />
                            )}
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewOpenLearningContent') && (
                        <WelcomeBox type={2} contentData={contentData} />
                            )}
                    </div>

                    <div className="flex-row">
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewLookOverPersonalVideos') && (
                        <UserStatsCircle contentData={contentData} />
                            )}
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewDashboardTrophies') && (
                        <TrophyCircles contentData={contentData} />
                            )}
                    </div>


                    <div className="flex-row">
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewUserRanking') && (
                            <UserRankingList contentData={topUserRanking}/>
                        )}
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewDepartmentRanking') && (
                            <DepartmentRankingList contentData={top10Departments} />
                        )}
                    </div>

                    <div className="flex-row">
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewUserChartDashboard') && (
                            <UserChart />
                        )}
                    </div>
                </div>
            </div >
        )


}



const styles = {
    container: { height: '100%', width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
    header: {
        container: { fontWeight: 600,  width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'} as React.CSSProperties,
    },
    headerMobile: {
        container: { fontWeight: 600, height: 50, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', } as React.CSSProperties,
    },
    cards: {
        container: { display: 'flex', flexDirection: 'row', height: '50%', justifyContent: 'space-around' } as React.CSSProperties,
        footer: { borderTop: '1px solid #E0E0E0', height: 80, display: 'flex', alignItems: 'center' } as React.CSSProperties,
    },
    cardsMobile: {
        container: { display: 'flex', flexDirection: 'column', height: '50%', width: '100%', marginTop: 20 } as React.CSSProperties,
        footer: { borderTop: '1px solid #E0E0E0', height: 80, display: 'flex', alignItems: 'center' } as React.CSSProperties,
    },
    boxContainer: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', borderRadius: 10, boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)', border: '1px solid #E0E0E0' } as React.CSSProperties,
    boxContent: { marginTop: 10, width: '90%', height: '100%' } as React.CSSProperties,
    title: { fontSize: 16, fontWeight: 'bold' } as React.CSSProperties,
    shortCuts: {
        container: { height: '20%', position: 'relative', left: 0, width: 'calc(100% - 20px)', display: 'flex', alignItems: 'center', flexDirection: 'row', overflow: 'scroll', color: standartColors.black } as React.CSSProperties,
        shortcut: { backgroundColor: standartColors.primaryYellow, height: 120, borderRadius: 10, fontSize: 18, fontWeight: 'bold', display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginLeft: 20, textDecoration: 'none', color: standartColors.black, } as React.CSSProperties,
        title: { marginLeft: 20, marginRight: 20 },
        icon: { height: 40, width: 40, marginLeft: 20, marginRight: 20 }
    },
    shortCutsMobile: {
        container: { height: '10%', position: 'relative', left: 0, width: 'calc(100% - 20px)', display: 'flex', alignItems: 'center', flexDirection: 'row', overflow: 'scroll', color: standartColors.black } as React.CSSProperties,
        shortcut: { backgroundColor: standartColors.primaryYellow, height: '90%', borderRadius: 10, fontSize: 12, fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40%', marginLeft: 12, textDecoration: 'none', color: standartColors.black, flexDirection: 'column' } as React.CSSProperties,
        title: { textAlign: 'center', width: '100%', wordWrap: 'break-word', whiteSpace: 'pre-wrap' },
        icon: { height: 20, width: 20, marginLeft: 20, marginRight: 20 }
    },

    rewards: {
        container: { cursor: "pointer", minWidth: 150, marginRight: 20, width: 150, textAlign: "center", flexGrow: 1, } as React.CSSProperties,
        text: { fontWeight: 'bold', fontSize: 22, color: standartColors.fontColorH1, textAlign: 'center' } as React.CSSProperties,
        textGray: { fontWeight: 'bold', fontSize: 22, color: standartColors.fontColorGrayH1 } as React.CSSProperties,
    }
}
