import React, {useCallback, useEffect, useState} from "react";
import {UserType} from "../../Types/UserType";
import {UsersRepository} from "../../Repositories/UsersRepository";
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import DeleteIcon from "../../ViewComponents/DeleteIcon";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import TableMui, {IColumn} from "../../ViewComponents/TableMui";
import {PaginationTypes} from "../../Types/PaginationTypes";
import tableHeightCalculator from "../../Helpers/TableHeightCalculator";
import {useSnackbar} from "notistack";
import unArchiveSvg from '../../assets/unArchiveBox.svg';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment/moment";
const sweetAlert = withReactContent(Swal)


const usersRepository = new UsersRepository();

export default function UserList(props: {editMode: (item) => void; showArchive: boolean}) {

    // Defining States
    const [users, setUsers] = useState<PaginationTypes<UserType>>(undefined);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const {enqueueSnackbar} = useSnackbar();

    // Getting user list from backed set it to state -- func
    const getUserList = useCallback(async () => {
        if(!waitingForPage){
            setLoading(true);
            const userList = await usersRepository.getAll({page: page, limit: limit, filter: filter, orderType: orderType}, props.showArchive);
            console.log(userList);
            setUsers(userList as PaginationTypes<UserType>);
            setLoading(false);
        }
    }, [page, limit, filter, orderType, waitingForPage, props.showArchive]);

    // Delete function which sends requests to backend and delete the user.
    const deleteUser = useCallback(async (user: UserType) => {

      await usersRepository.delete(user.id, false).then(async (response) => {
          enqueueSnackbar(I18N(props.showArchive ? "deleteSuccess" : 'archiveSuccess', language, [{value: I18N('user', language), key: 'name'}]), {variant: "success"});
          await getUserList();
        }).catch(async (err) => {
            if(err.code === 422){
                enqueueSnackbar(I18N(err.data.errorMessage, language), {variant: "error"});
            }else{
                enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            }
        })



    }, [getUserList, language]);

    const unArchive = useCallback(async (user: UserType) => {


        await sweetAlert.fire({
            showCloseButton: true,
            title: I18N('unArchiveHeader', language),
            html: I18N('unArchiveItem', language),
            icon: "question",
            reverseButtons: true,
            confirmButtonColor: "#8CEEB6",
            cancelButtonColor: "#F2F2F2",
            cancelButtonText: I18N('noCancel', language),
            confirmButtonText: I18N('yesUnArchive', language),
            customClass: {
                cancelButton: "cancelCustom",
                confirmButton: "confirmCustom"
            },
            showCancelButton: true,
        }).then(async (response) => {
            if(response.isConfirmed){
                await usersRepository.delete(user.id, true).then(async (response) => {
                    enqueueSnackbar(I18N("userUnArchived", language, [{value: I18N('user', language), key: 'name'}]), {variant: "success"});
                    await getUserList();
                }).catch(async (err) => {
                    if(err.code === 422){
                        enqueueSnackbar(I18N(err.data.errorMessage, language), {variant: "error"});
                    }else{
                        enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
                    }
                })
            }
        })


    }, [getUserList, language]);


    // Calling get function when page loads.
    useEffect(() => {
        getUserList().then(undefined);
    }, [getUserList]);

    // Creating columns for table

    const tableColumnsMui = (): IColumn[] => {

        const colList:IColumn[] = [
            {
                field: 'id',
                name: 'ID',
                width: 300,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'username',
                name: I18N('username', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    if(moment(params.row.innerData.archived_at).isBefore(moment(new Date()).subtract(1, "year").add("day", 1))){
                        return '-'
                    }else{
                        return value;
                    }
                }
            },
            {
                field: 'firstName',
                name: I18N('firstName', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    if(moment(params.row.innerData.archived_at).isBefore(moment(new Date()).subtract(1, "year").add("day", 1))){
                        return '-'
                    }else{
                        return value;
                    }
                }
            },
            {
                field: 'lastName',
                name: I18N('lastName', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    if(moment(params.row.innerData.archived_at).isBefore(moment(new Date()).subtract(1, "year").add("day", 1))){
                        return '-'
                    }else{
                        return value;
                    }
                }
            },
        ];

        if(props.showArchive){
            colList.push({
                field: 'archived_at',
                name: I18N('archived_at', language),
                renderCell: (params: any) => {
                    return moment(params.row.innerData.archived_at).format('DD.MM.YYYY HH:mm');
                }
            });
        }

        colList.push({
            field: 'actions',
            name: I18N('actions', language),
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params: any) => {
                return (
                    <div style={{display: "flex", justifyContent: "center", alignContent: "center", flexDirection: "row"}}>
                        {props.showArchive && (<div onClick={() => unArchive(params.row)} className="trueMark" style={{cursor: "pointer", display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", alignItems: "center"}}><img style={{width: 20, height: 20,padding: 0}} src={unArchiveSvg} /></div>)}
                        <DeleteIcon isArchive={!props.showArchive} row={params.row} callBack={deleteUser} ask={true} />
                    </div>

                );
            }
        })

        //archived_at
        return colList
    }


    //Filtering functions
    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);
    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);
    //Filtering functions

    return (
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    setWaitingForPage={setWaitingForPage}
                    tableName="table_users"
                    page={page}
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={users?.data !== undefined ? users.data.filter((i) => {
                        if(i.groups.findIndex((e => e.id === "ab940592-2df5-4ff1-9cb5-2c290687ecda")) !== -1){
                            return false;
                        }else{
                            return true;
                        }
                    }).map((u) => {
                        return {
                            username: u.username,
                            firstName: u.firstName,
                            lastName: u.lastName,
                            id: u.id,
                            innerData: u.innerData,
                            email: u.email
                        }
                    }) : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT

                        if(props.showArchive){
                            return;
                        }

                        if(event.target.dataset.field !== "actions" && event.target.dataset.field !== undefined){
                            console.log(data.row);
                            props.editMode(data.row);
                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={users?.total}
                />
            </div>
    )

}
