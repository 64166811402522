import {useCallback, useRef, useState} from "react";
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import CompanyStep from "../FachWizard/Components/CompanyStep";
import DepartmentStep from "../FachWizard/Components/DepartmentStep";
import UsersStep from "../FachWizard/Components/UsersStep";
import WizardBox from "../WizardBox";
import FinishMulti from "./Components/FinishMulti";
import CategoriesStep from "../TechWizard/Components/CategoriesStep";
import TrophiesStep from "../TechWizard/Components/TrophiesStep";
import LearningContentStep from "../TechWizard/Components/LearningContentStep";
import LearningPlanStep from "../TechWizard/Components/LearningPlantStep";

export default function MultiWizardMain(props: {isOpen: boolean;  setIsOpen: (status: boolean) => void;}){

    const language = useLanguageState(state => state.language);
    const [activeStep, setActiveStep] = useState(0);
    const categoriesRef = useRef(null);
    const learningContentRef = useRef(null);
    const trophiesRef = useRef(null);
    const learningPlanRef = useRef(null);
    const companyRef = useRef(null);
    const usersRef = useRef(null);
    const departmentRef = useRef(null);
    const onClearData = useCallback(() => {


        switch (activeStep){
            case 1: {
                if(companyRef.current !== null){
                    companyRef.current.click();
                }
                return;
            }
            case 2: {
                if(departmentRef.current !== null){
                    departmentRef.current.click();
                }
                return;
            }
            case 3: {
                if(usersRef.current !== null){
                    usersRef.current.click();
                }
                return;
            }
            case 4: {
                if(categoriesRef.current !== null){
                    categoriesRef.current.click();
                }
                return;
            }
            case 5: {
                if(trophiesRef.current !== null){
                    trophiesRef.current.click();
                }
                return;
            }
            case 6: {
                if(learningContentRef.current !== null){
                    learningContentRef.current.click();
                }
                return;
            }
            case 7: {
                if(learningPlanRef.current !== null){
                    learningPlanRef.current.click();
                }
                return;
            }
            default: {
                return '';
            }
        }


    }, [activeStep]);

    const steps = [
        {welcomeStep: true, stepDescription: I18N("welcomeMultiDescription", language)},
        {title: I18N('companyStep', language), stepDescription: I18N('companyStepDescription', language), stepInnerComponent: <CompanyStep ref={companyRef} title={I18N('companyStep', language)} />},
        {title: I18N('departmentStep', language), stepDescription: I18N('departmentStepDescription', language), stepInnerComponent: <DepartmentStep ref={departmentRef} title={I18N('departmentStep', language)} />},
        {title: I18N('usersStep', language), stepDescription: I18N('userStepDescription', language), stepInnerComponent: <UsersStep ref={usersRef} title={I18N('usersStep', language)} />},
        {title: I18N('categoriesStep', language), stepDescription: I18N('categoriesStepDescription', language), stepInnerComponent: <CategoriesStep ref={categoriesRef} title={I18N('categoriesStep', language)} />},
        {title: I18N('trophiesStep', language), stepDescription: I18N('trophiesStepDescription', language), stepInnerComponent: <TrophiesStep ref={trophiesRef} title={I18N('trophiesStep', language)} />},
        {title: I18N('learningContentStep', language), stepDescription: I18N('learningContentStepDescription', language), stepInnerComponent: <LearningContentStep ref={learningContentRef} title={I18N('learningContentStep', language)} />},
        {title: I18N('learningPlanStep', language), stepDescription: I18N('learningPlanStepDescription', language), stepInnerComponent: <LearningPlanStep ref={learningPlanRef} title={I18N('learningPlanStep', language)} />},
        {finishStep: true, stepDescription: I18N('finishMultiDescription', language), stepInnerComponent: <FinishMulti />}
    ]

    return (
        <WizardBox sendActiveStep={setActiveStep} onClearData={onClearData} steps={steps} open={props.isOpen} setOpen={props.setIsOpen} />
    )

}