import {Box, Modal} from "@mui/material";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import FormButton from "./FormButton";
import {useState} from "react";

export default function DeleteModal(props: {isOpen: boolean, onClose: () => void; data: any, unlinkFunction: () => void;}){

    const language = useLanguageState(state => state.language);
    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{...style, width: 850}}>

                <div>
                    <p style={{textAlign: "center", fontSize: 25, color: "#000"}}>{I18N("connectedData", language)}</p>
                </div>
                <div>
                    {props.data !== null && (
                        <>
                            {Object.keys(props.data.data).map((data) => {
                                return (
                                    <>
                                        <p style={{fontSize: 16, fontWeight: "bold"}}>{data}</p>
                                        <ol>
                                            {props.data.data[data].map((i) => {
                                                return <li>{(i.title !== undefined || i.name !== undefined) ? (i.title ?? i.name) : i.id}</li>
                                            })}
                                        </ol>
                                    </>
                                )
                            })}
                        </>
                    )}
                </div>

                <div className="flex-row-button">
                    <FormButton color="dark" text={I18N("cancel", language)} onClick={() => {
                        props.onClose();
                    }} isLoading={false} />
                    <FormButton color="lightGreen" text={I18N("unlink", language)} onClick={() => {
                        props.unlinkFunction();
                    }} isLoading={false} />
                </div>

            </Box>
        </Modal>
            )

}


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    overflow: "hidden",
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    border: '1px solid gray',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};