import {Drawer, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {colorForSelect} from "../../ComplinessTheme";
import Select from "react-select/creatable";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from '../../ViewComponents/VideoEditComponents/Drawer';
import {useLanguageState} from "../../States/LanguageState";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {LearningPlanRepository} from "../../Repositories/LearningPlanRepository";
import {LearnVideosType} from "../../Types/LearnVideosTypes";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";
import SelectAutocomplete from "../../ViewComponents/SelectAutoComplete";
import LanguageExporter from "../../Helpers/LanguageExporter";
import VideoTypeExporter from "../../Helpers/VideoTypeExporter";
const learningPlanRepository = new LearningPlanRepository();
const sweetAlert = withReactContent(Swal)
const videoRepository = new VideoRepository();

const StepBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, step: any; setContent: (data) => void; newStep: number; mainID: number}) => {

    const language = useLanguageState();
    const [loadingData, setLoadingData] = useState(false);
    const theme = useTheme();
    const [videos, setVideos] = useState([]);
    const [hasError, setHasError] = useState(false);


    const [step, setStep] = useState({
        disabled: false,
        startEvent: undefined,
        execution: undefined,
        videos: undefined,
        deadline: undefined,
    },)

    useEffect(() => {
        const stepData = props.step?.step;
        if(props.step !== null && props.step !== undefined){
            setStep({
                disabled: stepData.disabled,
                startEvent: stepData.startEvent,
                execution: stepData.execution,
                videos: stepData.videos,
                deadline: stepData.deadline,
            });
        }else{
            setStep({
                disabled: false,
                startEvent: undefined,
                execution: undefined,
                videos: undefined,
                deadline: undefined,
            })
        }
    }, [props.step]);


    const loadVideos = useCallback(async () => {
        const videoList = await videoRepository.getAll();
        setVideos(videoList as LearnVideosType[]);
    }, []);

    const doThisWhenLoaded = useCallback(async (videodata) => {

    }, []);

    useEffect(() => {
        Promise.all([loadVideos()]).then((response) => {

            doThisWhenLoaded(response[0]);


        }).catch((err) => {
            console.log(err);
        })

    }, []);

    const saveChanges = useCallback(async () => {

        if(step.deadline === undefined || step.deadline === null || step.videos === null || step.videos === undefined || step.videos.length < 1 || step.execution === null || step.execution === undefined || step.startEvent === null || step.startEvent === undefined){
            setHasError(true);
            return;
        }

        setLoadingData(true);
        let stepID = -1;
        if(props.newStep){
            stepID = 0;
        }else{
            stepID = props.step.stepNumber;
        }


        await learningPlanRepository.updateStep({
            stepID: stepID,
            newStep: props.newStep,
            ...step
        }, props.mainID).then((response) => {
            setLoadingData(false);
            console.log(response);
            props.setContent(response);
            props.setIsOpen(false);
            setStep({
                disabled: false,
                startEvent: undefined,
                execution: undefined,
                videos: undefined,
                deadline: undefined,
            })
        }).catch((err) => {
            setLoadingData(false);
            console.log(err);
        })


    }, [props.newStep, props.mainID, props.step, step]);


    const executionTimeSelections: {label: string, value: string}[] = [
        {label: I18N('immediately', language.language), value: 'immediately'},
        {label: I18N('1_day_delay', language.language), value: '1_day'},
        {label: I18N('2_day_delay', language.language), value: '2_day'},
        {label: I18N('3_day_delay', language.language), value: '3_day'},
        {label: I18N('4_day_delay', language.language), value: '4_day'},
        {label: I18N('5_day_delay', language.language), value: '5_day'},
        {label: I18N('6_day_delay', language.language), value: '6_day'},
        {label: I18N('1_week_delay', language.language), value: '1_week'},
        {label: I18N('2_week_delay', language.language), value: '2_week'},
        {label: I18N('3_week_delay', language.language), value: '3_week'},
        {label: I18N('1_month_delay', language.language), value: '1_month'},
        {label: I18N('2_month_delay', language.language), value: '2_month'},
        {label: I18N('3_month_delay', language.language), value: '3_month'}
    ]


    const deadlineLimits: {label: string, value: string}[] = [
        {label:  I18N("noDeadline", language.language), value: 'NO'},
        {label:  I18N('1_day_deadline', language.language), value: '1_day'},
        {label:  I18N('2_day_deadline', language.language), value: '2_day'},
        {label:  I18N('3_day_deadline', language.language), value: '3_day'},
        {label:  I18N('4_day_deadline', language.language), value: '4_day'},
        {label:  I18N('5_day_deadline', language.language), value: '5_day'},
        {label:  I18N('6_day_deadline', language.language), value: '6_day'},
        {label:  I18N('1_week_deadline', language.language), value: '1_week'},
        {label:  I18N('2_week_deadline', language.language), value: '2_week'},
        {label:  I18N('3_week_deadline', language.language), value: '3_week'},
        {label:  I18N('1_month_deadline', language.language), value: '1_month'},
        {label:  I18N('2_month_deadline', language.language), value: '2_month'},
        {label:  I18N('3_month_deadline', language.language), value: '3_month'}
    ]

    const startEventSelections = [
        {label: I18N('after_event', language.language), value: 'after'},
        {label: I18N('with_event', language.language), value: 'with'},
    ]


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('step', language.language)} {props.step === null ? props.newStep : props.step?.stepNumber}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {/*START EVENT*/}
                            <div className="marginTop20">
                                <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('startEvent', language.language)}</div>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect" styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55,
                                        borderColor: (hasError && (step.startEvent === null || step.startEvent === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",
                                    })}}
                                        placeholder={I18N('startEvent', language.language)}
                                        isDisabled={step.disabled}
                                        isMulti={false}
                                        options={startEventSelections}
                                        value={step.startEvent}
                                        onChange={(change) => setStep({...step, startEvent: change})}
                                />

                            </div>

                            {/*execution*/}
                            <div className="marginTop20">
                                <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('delivery', language.language)}</div>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect" styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55,
                                        borderColor: (hasError && (step.execution === null || step.execution === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",
                                    })}}
                                        placeholder={I18N('delivery', language.language)}
                                        isDisabled={step.disabled}
                                        isMulti={false}
                                        options={executionTimeSelections}
                                        value={step.execution}
                                        onChange={(change) => setStep({...step, execution: change})}
                                />

                            </div>

                            {/**deadline*/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle" style={{fontSize: 13}}>{I18N("deadline", language.language)}</p>
                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect" styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55,
                                        borderColor: (hasError && (step.deadline === null || step.deadline === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",
                                    })}}
                                        placeholder={I18N('deadline', language.language)}
                                        isDisabled={props.newStep ? false : step.disabled}
                                        isMulti={false}
                                        options={deadlineLimits}
                                        value={step.deadline}
                                        onChange={(change) => setStep({...step, deadline: change})}
                                />

                            </div>

                            {/**content*/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle" style={{fontSize: 13}}>{I18N("content", language.language)}</p>

                                <SelectAutocomplete
                                    multiple={true}
                                    maxTags={3}
                                    options={videos.map((video) => {
                                        return {label: LanguageExporter(video, language.language, 'video_titles', 'title', 'title') + " ("+VideoTypeExporter(video.type)+")", value: video.id}
                                    })}
                                    value={step.videos}
                                    onChange={(change) => {
                                        setStep({...step, videos: change})
                                    }}
                                />

                                {/*<Select theme={(template) => {*/}
                                {/*    return {*/}
                                {/*        ...template,*/}
                                {/*        colors: {*/}
                                {/*            ...template.colors,*/}
                                {/*            ...colorForSelect(theme)*/}
                                {/*        }*/}
                                {/*    }*/}
                                {/*}} className="fullWidthSelect" styles={{*/}
                                {/*    valueContainer: (styles) => {*/}
                                {/*        return {*/}
                                {/*            ...styles,*/}
                                {/*            height: 50,*/}
                                {/*            scrollbarWidth: "none",*/}
                                {/*            msOverflowStyle: "none",*/}
                                {/*            overflowX: "hidden",*/}
                                {/*            overflowY: "scroll"*/}
                                {/*        };*/}
                                {/*    },*/}
                                {/*    control: base => ({*/}
                                {/*        ...base,*/}
                                {/*        minHeight: 55,*/}
                                {/*        height: 55,*/}
                                {/*        borderColor: (hasError && (step.videos === null || step.videos === undefined || step.videos.length < 1)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",*/}
                                {/*    })}} isMulti={true} options={videos.map((video) => {*/}
                                {/*    return {label: video.title + (video.type === 1 ? ' (Video)' : ' (Pdf)'), value: video.id}*/}
                                {/*})}*/}
                                {/*        placeholder={I18N('content', language.language)}*/}
                                {/*        value={step.videos} onChange={(change) => {*/}
                                {/*    setStep({...step, videos: change})*/}
                                {/*}} />*/}
                            </div>


                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default StepBar;
