import NotificationDecider from "../utils/NotificationDecider";
import LightBulb from "../assets/lightbulb.svg";
import React from "react";
import {useLanguageState} from "../States/LanguageState";
import {useHistory} from "react-router-dom";
import {NotificationsRepository} from "../Repositories/NotificationsRepository";
import {useTheme} from "@mui/material";
const notificationsRepository = new NotificationsRepository();

interface Props {
    notification: any;
    index: number;
    onAfterClick: (notification:any, index: number) => void;
    fullView?: boolean
}

export default function SingleNotification(props: Props){

    const languageState = useLanguageState();
    const history = useHistory();
    const theme = useTheme();


    const notificationData = NotificationDecider(props.notification, languageState.language);

    return (
            <li className={`notification ${!props.notification.isClicked && (theme.palette.mode === 'dark' ? "notClickedDark" : 'notClicked')}`} onClick={() => {

            if(!props.notification.isClicked){
                notificationsRepository.markAsClicked(props.notification.id).then(undefined);
                props.onAfterClick(props.notification, props.index);
            }
            if(notificationData.target !== undefined){
                window.open(notificationData.link, '_blank');
            }else{
                if(notificationData.link.includes("Lernvideos")){
                    const splitLink = notificationData.link.split("Lernvideos/");
                    history.push("Lernvideos#video="+splitLink[1]);
                }else{
                    history.push(notificationData.link)
                }

            }
        }
        }>
            <div className="notificationIcon"><img src={LightBulb}  /></div>
            <div className="inner">
                <div className="content" style={{color: theme.palette.mode === 'dark' && "#fff"}}>
                    {notificationData.text}
                </div>
                <div style={{color: theme.palette.mode === 'dark' && "#fff"}} className={`time ${props.fullView ? 'leftTime' : ''}`}>
                    {new Date(props.notification.created_at).toLocaleString()}
                </div>
            </div>
        </li>
    )
}
