import {Card, CardContent, CardHeader} from '@mui/material';
import { styled } from '@mui/material/styles';
// @ts-ignore
import {
    DataGrid as MuiDataGrid,
    GridCallbackDetails,
    GridColDef,
    GridFeatureMode, GridFilterModel,
    GridSortModel,
    deDE,
    enUS
} from "@mui/x-data-grid";

import React, {useEffect, useState} from "react";
import {useLanguageState} from "../States/LanguageState";
import {useLoginState} from "../States/UserState";
import {getToken} from "../Repositories/Cookies";
import {I18N} from "../i18n/i18n";


const pageSizeOptions = [1,5,10,15,20];
const defaultFeatureMode: GridFeatureMode = "server";

interface IDataTable {
    data: any[];
    columns: IColumn[];
    isLoading?: boolean;
    onClick?: any;
    paginationMode?: GridFeatureMode
    onPageChange?: (page: number, details: GridCallbackDetails) => void;
    onPageSizeChange?: (pageSize: number, details: GridCallbackDetails) => void;
    pageSizeOptions?: number[];
    pageSize?: number;
    page?: number;
    rowCount?: number;
    sortingMode?: GridFeatureMode;
    onSortModelChange?: (mode: GridSortModel, details: GridCallbackDetails) => void;
    onFilterModeChange?: (mode: GridFilterModel, details: GridCallbackDetails) => void;
    filterMode?: GridFeatureMode;
    getRowId?: (row) => void;
    title?: string;
    tableName?: string;
    setWaitingForPage?: (status: boolean) => void;
}
export interface IColumn {
    field: string;
    name: string;
    renderCell?: any;
    width?: number;
    disableExport?: boolean;
    disableColumnMenu?: boolean
    hidden?: boolean;
    disableReorder?: boolean;
    sortable?: boolean;
    description?: string;
    filterable?: boolean;
    sortComparator?: any;
    type?: string;
    valueGetter?: any;

}

const DataGrid = styled(MuiDataGrid)((theme) => {
    const darkMode = false;
    return {
        border: 'none',
        borderBottom: 'none',
        '& .MuiDataGrid-columnHeader': {
            // TODO: theme attribute for background
            backgroundColor:  theme.theme.palette.mode === 'dark' ? theme.theme.palette.background.default : `#F2F2F2`,
            fontSize: '12px',
            //@ts-ignore
            color:  theme.theme.palette.mode === 'dark' ? theme.theme.palette.color :'#828282',
            border: 'none',
            '&:first-of-type': {
                borderTopLeftRadius: '10px',
                borderBottomLeftRadius: '10px'
            },
            '&:last-of-type': {
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px'
            }
        },
        '& .MuiDataGrid-columnHeaders': {
            borderBottom: 'none'
        },
        '& .MuiDataGrid-cell': {
            border: 'none'
        }
    };
});

const TableMui = (props: IDataTable) => {

    const { data, columns, isLoading, onClick } = props;
    const [cols, setCols] = useState<GridColDef[]>([]);
    const language = useLanguageState(state => state.language);
    const loginState = useLoginState(state => state);
    const clientName = getToken();
    const [fRequest, setFRequest] = useState(true);

    useEffect(() => {

       const pageSizeFromLocalStorage = localStorage.getItem("pageSizeTable");

       if(pageSizeFromLocalStorage !== null && props.onPageSizeChange !== undefined){
           props.onPageSizeChange(Number(pageSizeFromLocalStorage), {});
       }else{
           if(props.pageSize !== undefined){
               localStorage.setItem('pageSizeTable', props.pageSize.toString());

           }
       }
    }, []);

    useEffect(() => {
        if(props.pageSize !== undefined) {
            localStorage.setItem('pageSizeTable', props.pageSize.toString());
        }
    }, [props.pageSize]);

    useEffect(() => {
        if(props.tableName){
            const currentSavedPage = localStorage.getItem(`pageForTable-${props.tableName}`);
            if(!currentSavedPage){
                localStorage.setItem(`pageForTable-${props.tableName}`, props.page.toString());
            }else{
                if(!fRequest){
                    localStorage.setItem(`pageForTable-${props.tableName}`, props.page.toString());

                }else{
                    setFRequest(false);
                }

            }
        }

    }, [props.page, props.tableName, fRequest]);

    useEffect(() => {

        if(props.tableName){

            const currentSavedPage = localStorage.getItem(`pageForTable-${props.tableName}`);

            if(currentSavedPage && props.onPageChange){
                props.onPageChange(Number(Number(currentSavedPage) - 1), {});
            }
        }
        if(props.setWaitingForPage){
            props.setWaitingForPage(false);
        }

    }, [props.tableName]);



    useEffect(() => {
        console.log(loginState.token.resource_access[clientName].roles);
        setCols(columns.map(column => {
            return {
                type: column.type ?? undefined,
                width: column.width,
                field: column.field,
                valueGetter: column.valueGetter ?? undefined,
                headerName: column.name,
                flex: column.width ? 0 : 1,
                renderCell: column.renderCell,
                disableReorder: column.disableReorder,
                disableExport: column.disableExport,
                disableColumnMenu: column.disableColumnMenu,
                sortable: column.sortable === undefined ? true : column.sortable,
                description: column.description,
                filterable: column.filterable === undefined ? true : column.filterable,
                hide: (loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('manage-realm') && column.name === 'ID') ? ((column.hidden === undefined ? false : column.hidden)) : ((column.name === 'ID') ? (column.hidden === undefined  ? true : column.hidden) : (column.hidden === undefined ? false : column.hidden))
            };
        }))
    }, [clientName, columns, loginState.token]);


    return (
        <div style={{ height: '100%', width: '100%', boxSizing: 'border-box', marginTop: 20 }}>
            <Card sx={{ height: '100%' }}>
                {props.title && (
                    <CardHeader titleTypographyProps={{variant: "h6"}} title={props.title} />
                )}
                <CardContent sx={{ height: '100%' }}>
                    <DataGrid
                        localeText={language === 'de' ? deDE.components.MuiDataGrid.defaultProps.localeText : enUS.components.MuiDataGrid.defaultProps.localeText}
                        onFilterModelChange={props.onFilterModeChange}
                        filterMode={props.filterMode === undefined ? defaultFeatureMode : props.filterMode}
                        sortingMode={props.sortingMode === undefined ? defaultFeatureMode : props.sortingMode}
                        onSortModelChange={props.onSortModelChange}
                        pageSize={props.pageSize}
                        page={props.page ? props.page - 1 : undefined}
                        getRowId={(row) => {
                            if(props.getRowId !== undefined){
                                return props.getRowId(row);
                            }
                            if(row.ID !== undefined){
                                return row.ID;
                            }else{
                                return row.id;
                            }
                        }}
                        rowCount={props.rowCount}
                        rowsPerPageOptions={props.pageSizeOptions === undefined ? pageSizeOptions : props.pageSizeOptions}
                        paginationMode={props.paginationMode === undefined ? defaultFeatureMode : props.paginationMode}
                        onPageChange={props.onPageChange}
                        onPageSizeChange={props.onPageSizeChange}
                        loading={isLoading}
                        rows={data}
                        columns={cols}
                        showColumnRightBorder={false}
                        showCellRightBorder={false}
                        disableSelectionOnClick={false}
                        onRowClick={onClick}

                    />
                </CardContent>
            </Card>
        </div>
    );
};

export default TableMui;
