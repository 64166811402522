import {Drawer, InputAdornment, Slider, styled, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {useLanguageState} from "../../../States/LanguageState";
import {I18N} from "../../../i18n/i18n";
import {SidebarContainer, SidebarDrawer} from "../../../ViewComponents/VideoEditComponents/Drawer";
import FormButton from "../../../ViewComponents/FormButton";
import {ProfileRepository} from "../../../Repositories/ProfileRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Select from "react-select/creatable";
import {colorForSelect} from "../../../ComplinessTheme";
import {useSnackbar} from "notistack";
import {DrawerConfigSx} from "../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../ViewComponents/ViewBox";
const sweetAlert = withReactContent(Swal)
const profileRepository = new ProfileRepository();


const IOSSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#CD6516' : '#CD6516',
    height: 2,
    padding: '15px 0',
    marginTop: 20,
}));

const ChangeAutoLogout = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, userInfo: any; onUpdate: () => void;}) => {
    const language = useLanguageState();

    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const [formState, setFormState] = useState<{isActive: 1 | 0, timer: number}>({
        isActive: 1,
        timer: 10
    });

    useEffect(() => {

        if(props.userInfo){

            setFormState({
                isActive: props.userInfo.autoLogout,
                timer: props.userInfo.autoLogoutTimer
            })

        }else{
            setFormState({
                isActive: 1,
                timer: 10
            })
        }



    }, [props.userInfo]);

    const saveChanges = useCallback(async () => {

        setLoadingData(true);

        await profileRepository.updateTimer({...formState}).then(async (response) => {
            console.log(response);
            if(response.status){
                props.setIsOpen(false);
                props.onUpdate();
                enqueueSnackbar(I18N('successfullyUpdated', language.language), {variant: "success"})
                window.location.reload();
                return true;
            }else{

            }
        }).catch(async (err) => {
            console.log(err);
            enqueueSnackbar(I18N('unexpectedError', language.language), {variant: "error"})
            return false;
        })


        setLoadingData(false);


    }, [formState]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('autoLogout', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <div className="box"  style={{marginTop: 5}}>
                        <ViewBox>
                            <div className="marginTop20" style={{width: '100%'}}>
                                <p className="viewBoxTitle">{I18N('status', language.language)}</p>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop10" styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} key="statusSelect"
                                        isMulti={false}
                                        placeholder={I18N("passPercentage", language.language)}
                                        options={[{value: 1, label: I18N('active', language.language)}, {value: 0, label: I18N("inActive", language.language)}]}
                                        value={formState.isActive ? {value: 1, label: I18N('active', language.language)} : {value: 0, label: I18N("inActive", language.language)}} onChange={(change) => {
                                    setFormState({...formState, isActive: change.value as  1 | 0})
                                }} />

                            </div>
                        </ViewBox>

                        <ViewBox>
                            <div className="marginTop20" style={{width: '100%'}}>
                                <TextField
                                    fullWidth
                                    InputProps={{
                                        inputProps: {
                                            style: { textAlign: "right" },
                                        },
                                        endAdornment: (<InputAdornment position="end">{I18N('minutes', language.language)}</InputAdornment>)
                                    }
                                    }
                                    style={{ marginTop: 20}}
                                    value={formState.timer}
                                    disabled={true}
                                />

                                <IOSSlider
                                    aria-label="Temperature"
                                    color="primary"
                                    value={formState.timer}
                                    onChange={(e, v) => {
                                        setFormState({...formState, timer: v as number})
                                    }
                                    }
                                    getAriaValueText={(value) => value.toString()}

                                    valueLabelDisplay="auto"
                                    step={5}
                                    marks={[{value: 5, label: 5},{value: 20, label: 20},{value: 40, label: 40}, {value: 60, label: 60},  {value: 80, label: 80},  {value: 100, label: 100},  {value: 120, label: 120}, ]}
                                    min={5}
                                    max={120}
                                />
                            </div>
                        </ViewBox>



                    </div>
                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default ChangeAutoLogout;


