import React, { useCallback, useEffect, useState } from "react";
import ViewBox from "../ViewComponents/ViewBox";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import { TextField, useTheme } from "@mui/material";
import Select from "react-select";
import { DepartmentsRepository } from "../Repositories/DepartmentsRepository";
import { VideoRepository } from "../Repositories/VideoRepository";
import FormButton from "../ViewComponents/FormButton";
import { LearnVideosType } from "../Types/LearnVideosTypes";
import { DepartmentType } from "../Types/DepartmentTypes";
import { colorForSelect } from "../ComplinessTheme";
import { CompanyRepository } from "../Repositories/CompanyRepository";
import { useBreadCrumb } from "../States/BreadCrumbState";
import { useLoginState } from "../States/UserState";
import SelectAutocomplete from "../ViewComponents/SelectAutoComplete";
import LanguageExporter from "../Helpers/LanguageExporter";
import VideoTypeExporter from "../Helpers/VideoTypeExporter";

const departmentsRepository = new DepartmentsRepository();
const videoRepository = new VideoRepository();
const companyRepository = new CompanyRepository();
export interface learningPlanFormState {
  description: string;
  department: { label: string; value: number }[] | undefined;
  status: { label: string; value: number } | undefined;
  trigger: { label: string; value: string } | undefined;
  companies: { label: string; value: number }[] | undefined;
}

interface Props {
  onFormChange: (form: learningPlanFormState) => void;
  onStepsChange: (steps) => void;
  editMode?: any;
  onReturnBackToView: () => void;
  onFinish: () => void;

  formHasError: boolean;
}

export default function LearningPlanOperation(props: Props) {
  const [companies, setCompanies] = useState([]);
  const language = useLanguageState((state) => state.language);
  const theme = useTheme();
  const authService = useLoginState();

  const executionTimeSelections: { label: string; value: string }[] = [
    { label: I18N("immediately", language), value: "immediately" },
    { label: I18N("1_day_delay", language), value: "1_day" },
    { label: I18N("2_day_delay", language), value: "2_day" },
    { label: I18N("3_day_delay", language), value: "3_day" },
    { label: I18N("4_day_delay", language), value: "4_day" },
    { label: I18N("5_day_delay", language), value: "5_day" },
    { label: I18N("6_day_delay", language), value: "6_day" },
    { label: I18N("1_week_delay", language), value: "1_week" },
    { label: I18N("2_week_delay", language), value: "2_week" },
    { label: I18N("3_week_delay", language), value: "3_week" },
    { label: I18N("1_month_delay", language), value: "1_month" },
    { label: I18N("2_month_delay", language), value: "2_month" },
    { label: I18N("3_month_delay", language), value: "3_month" },
  ];

  const deadlineLimits: { label: string; value: string }[] = [
    { label: I18N("noDeadline", language), value: "NO" },
    { label: I18N("1_day_deadline", language), value: "1_day" },
    { label: I18N("2_day_deadline", language), value: "2_day" },
    { label: I18N("3_day_deadline", language), value: "3_day" },
    { label: I18N("4_day_deadline", language), value: "4_day" },
    { label: I18N("5_day_deadline", language), value: "5_day" },
    { label: I18N("6_day_deadline", language), value: "6_day" },
    { label: I18N("1_week_deadline", language), value: "1_week" },
    { label: I18N("2_week_deadline", language), value: "2_week" },
    { label: I18N("3_week_deadline", language), value: "3_week" },
    { label: I18N("1_month_deadline", language), value: "1_month" },
    { label: I18N("2_month_deadline", language), value: "2_month" },
    { label: I18N("3_month_deadline", language), value: "3_month" },
  ];

  const startEventSelections = [
    { label: I18N("after_event", language), value: "after" },
    { label: I18N("with_event", language), value: "with" },
  ];

  const globalTriggers = [
    { label: I18N("trigger_userCreated", language), value: "userCreated" },
    { label: I18N("trigger_userMoved", language), value: "userMoved" },
    { label: I18N("trigger_userFinished", language), value: "userFinished" },
  ];

  const [formState, setFormState] = useState<learningPlanFormState>({
    description: "",
    department: undefined,
    status: { label: I18N("active", language), value: 1 },
    trigger: undefined,
    companies: undefined,
  });
  const [loaded, setLoaded] = useState(false);

  const [steps, setSteps] = useState([
    {
      disabled: false,
      startEvent: { label: I18N("with_event", language), value: "with" },
      execution: { label: I18N("immediately", language), value: "immediately" },
      videos: [],
      deadline: { label: I18N("noDeadline", language), value: "NO" },
    },
  ]);

  const [departments, setDepartments] = useState<DepartmentType[]>([]);

  const [videos, setVideos] = useState([]);

  const breadCrumb = useBreadCrumb();

  const loadDepartments = useCallback(async () => {
    const depList = await departmentsRepository.getAll();
    setDepartments(depList as DepartmentType[]);
  }, []);

  const loadVideos = useCallback(async () => {
    const videoList = await videoRepository.getAll();
    setVideos(videoList as LearnVideosType[]);
  }, []);

  const loadCompanies = useCallback(async () => {
    const subList = await companyRepository.getSubCompanies();
    const userComp = subList;
    setCompanies(userComp);
  }, [authService]);

  useEffect(() => {
    loadDepartments().then(undefined);
    loadVideos().then(undefined);
    loadCompanies().then(undefined);
  }, []);

  useEffect(() => {
    if (props.editMode === null) return;

    if (loaded) {
      return;
    }

    console.log(props.editMode.depList);

    setFormState({
      department: props.editMode.depList.map((item) => {
        return { label: item.name, value: item.ID };
      }),
      description: props.editMode.description,
      status: {
        label: I18N(props.editMode.status ? "active" : "inActive", language),
        value: props.editMode.status,
      },
      trigger: {
        label: globalTriggers.find(
          (gTrigger) => gTrigger.value === props.editMode.trigger,
        ).label,
        value: props.editMode.trigger,
      },
      companies: props.editMode.learningplans_companies.map((i) => {
        return {
          label: i.company.name,
          value: i.company.id,
        };
      }),
    });

    const stepList = [];

    props.editMode.learning_plan_steps.forEach((step, key) => {
      const startEventData = startEventSelections.find(
        (item) => item.value === step.startEvent,
      );
      const executionDate = executionTimeSelections.find(
        (item) => item.value === step.execution,
      );
      const deadline = deadlineLimits.find(
        (item) => item.value === step.deadline,
      );
      stepList.push({
        disabled: false,
        startEvent: startEventData,
        execution: executionDate,
        deadline: deadline,
        videos: step.learning_plan_step_videos.map((video) => {
          const findVideo = videos.find((vid) => vid.id === video.video_id);
          if (findVideo !== undefined) {
            setLoaded(true);
            return {
              label:
                LanguageExporter(
                  findVideo,
                  language,
                  "video_titles",
                  "title",
                  "title",
                ) +
                " (" +
                VideoTypeExporter(findVideo.type) +
                ")",
              value: video.video_id,
            };
          }
        }),
      });
    });

    setSteps(stepList);
  }, [props.editMode, videos, departments]);

  const setStepData = useCallback(
    (
      type: "video" | "execution" | "startEvent" | "deadline",
      index: number,
      data: any,
    ) => {
      const stepData = steps;
      const selectedStep = steps[index];

      switch (type) {
        case "video": {
          selectedStep.videos = data;
          break;
        }
        case "execution": {
          selectedStep.execution = data;
          break;
        }
        case "startEvent": {
          selectedStep.startEvent = data;
          break;
        }
        case "deadline": {
          selectedStep.deadline = data;
          break;
        }
      }

      stepData[index] = selectedStep;
      setSteps([...stepData]);
    },
    [steps],
  );

  const addNewStep = useCallback(() => {
    const stepInner = {
      startEvent: undefined,
      execution: undefined,
      videos: [],
      disabled: false,
      deadline: undefined,
    };

    setSteps([...steps, stepInner]);
  }, [steps]);

  useEffect(() => {
    props.onFormChange(formState);
    props.onStepsChange(steps);
  }, [steps, formState, props.onStepsChange, props.onFormChange]);

  const deleteRow = useCallback(
    (index: number) => {
      steps.splice(index, 1);
      setSteps([...steps]);
    },
    [steps],
  );

  //updateLearningPlan
  useEffect(() => {
    breadCrumb.setBackButton(props.onReturnBackToView);
    breadCrumb.setTitle(
      props.editMode
        ? props.editMode.description
        : I18N("createLearningPlan", language),
    );
    breadCrumb.setAdditionalCrumb({
      title: props.editMode
        ? props.editMode.description
        : I18N("createLearningPlan", language),
      onClick: undefined,
    });
    breadCrumb.setChildren([
      {
        color: "lightGreen",
        key: "learningPlanSaver",
        title: props.editMode
          ? I18N("updateLearningPlan", language)
          : I18N("createLearningPlan", language),
        onClick: props.onFinish,
        icon: "save",
      },
    ]);
  }, [language, props.onReturnBackToView, props.editMode, props.onFinish]);

  return (
    <React.Fragment>
      <ViewBox title={I18N("generalInformation", language)}>
        <div className="flex-row-input">
          <TextField
            fullWidth
            label={I18N("description", language)}
            className="marginTop20 marginRight10"
            value={formState.description}
            error={props.formHasError && formState.description === "" && true}
            onChange={(text) =>
              setFormState({ ...formState, description: text.target.value })
            }
          />

          {/*<Select theme={(template) => {*/}
          {/*    return {*/}
          {/*        ...template,*/}
          {/*        colors: {*/}
          {/*            ...template.colors,*/}
          {/*            ...colorForSelect(theme)*/}
          {/*        }*/}
          {/*    }*/}
          {/*}}  className="fullWidthSelect marginTop10" styles={{*/}
          {/*    valueContainer: (styles) => {*/}
          {/*        return {*/}
          {/*            ...styles,*/}
          {/*            height: 50,*/}
          {/*            scrollbarWidth: "none",*/}

          {/*            msOverflowStyle: "none",*/}
          {/*            overflowX: "hidden",*/}
          {/*            overflowY: "scroll"*/}
          {/*        };*/}
          {/*    },*/}
          {/*    control: base => ({*/}
          {/*        ...base,*/}
          {/*        borderColor: (props.formHasError && (formState.department === null || formState.department === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",*/}
          {/*        height: 55,*/}
          {/*        minHeight: 55*/}
          {/*    })}} isMulti={true} placeholder={I18N('departmentSelection', language)} options={departments.map((department) => { return {value: department.ID, label: department.name}})} value={formState.department} onChange={(change) => {*/}
          {/*        //@ts-ignore*/}
          {/*        setFormState({...formState, department: change})*/}
          {/*}} />*/}

          <div className="" style={{ width: "100%" }}>
            <SelectAutocomplete
              size={"55px"}
              label={I18N("departmentSelection", language)}
              multiple={true}
              maxTags={1}
              options={departments.map((department) => {
                return { value: department.ID, label: department.name };
              })}
              value={formState.department}
              onChange={(data) =>
                setFormState({ ...formState, department: data })
              }
            />
          </div>

          <Select
            theme={(template) => {
              return {
                ...template,
                colors: {
                  ...template.colors,
                  ...colorForSelect(theme),
                },
              };
            }}
            className="fullWidthSelect marginTop10 marginLeft10"
            styles={{
              control: (base) => ({
                ...base,
                height: 55,
                minHeight: 55,
                borderColor:
                  props.formHasError &&
                  (formState.trigger === null ||
                    formState.trigger === undefined)
                    ? "#FF6262"
                    : "rgba(0, 0, 0, 0.23)",
              }),
            }}
            placeholder={I18N("trigger", language)}
            isMulti={false}
            options={globalTriggers}
            value={formState.trigger}
            onChange={(change) =>
              setFormState({ ...formState, trigger: change })
            }
          />

          <div className="marginLeft10" style={{ width: "100%" }}>
            <SelectAutocomplete
              size={"55px"}
              label={I18N("companies", language)}
              multiple={true}
              maxTags={1}
              options={companies.map((comp) => {
                return { value: comp.id, label: comp.name };
              })}
              value={formState.companies}
              onChange={(data) =>
                setFormState({ ...formState, companies: data as any })
              }
            />
          </div>

          {/*<Select theme={(template) => {*/}
          {/*    return {*/}
          {/*        ...template,*/}
          {/*        colors: {*/}
          {/*            ...template.colors,*/}
          {/*            ...colorForSelect(theme)*/}
          {/*        }*/}
          {/*    }*/}
          {/*}} styles={{ valueContainer: (styles) => {*/}
          {/*        return {*/}
          {/*            ...styles,*/}
          {/*            height: 50,*/}
          {/*            scrollbarWidth: "none",*/}

          {/*            msOverflowStyle: "none",*/}
          {/*            overflowX: "hidden",*/}
          {/*            overflowY: "scroll"*/}
          {/*        };*/}
          {/*    },control: base => ({*/}
          {/*        ...base,*/}
          {/*        height: 55,*/}
          {/*        minHeight: 55,*/}
          {/*        borderColor: (props.formHasError && (formState.companies === null || formState.companies === undefined)) ? "#FF6262" : "rgba(0, 0, 0, 0.23)",*/}
          {/*    })}} placeholder={I18N('companies', language)} className="fullWidthSelect marginTop10 marginLeft10" isMulti={true} key="subCompanySelect" options={companies.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.companies} onChange={(data, ) => setFormState({...formState, companies: data as any})} />*/}

          <Select
            theme={(template) => {
              return {
                ...template,
                colors: {
                  ...template.colors,
                  ...colorForSelect(theme),
                },
              };
            }}
            className="fullWidthSelect marginTop10 marginLeft10"
            styles={{
              control: (base) => ({
                ...base,
                height: 55,
                minHeight: 55,
                borderColor:
                  props.formHasError &&
                  (formState.status === null || formState.status === undefined)
                    ? "#FF6262"
                    : "rgba(0, 0, 0, 0.23)",
              }),
            }}
            isMulti={false}
            options={[
              { label: I18N("active", language), value: 1 },
              { label: I18N("inActive", language), value: 0 },
            ]}
            value={formState.status}
            onChange={(change) => {
              setFormState({ ...formState, status: change });
            }}
          />
        </div>
      </ViewBox>

      <ViewBox title={I18N("sequence", language)}>
        <FormButton
          text={I18N("addMore", language)}
          onClick={addNewStep}
          isLoading={false}
        />
        {steps.map((step, key) => (
          <div className="step">
            <div className="stepName">
              <span
                style={{
                  background:
                    theme.palette.mode === "dark"
                      ? theme.palette.background.default
                      : "#fff",
                }}
              >
                {" "}
                {key + 1}. {I18N("step", language)}
              </span>
            </div>
            <div className="stepInner">
              <div className="startEvent">
                <Select
                  theme={(template) => {
                    return {
                      ...template,
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme),
                      },
                    };
                  }}
                  className="fullWidthSelect"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 55,
                      minHeight: 55,
                      borderColor:
                        props.formHasError &&
                        (step.startEvent === null ||
                          step.startEvent === undefined)
                          ? "#FF6262"
                          : "rgba(0, 0, 0, 0.23)",
                    }),
                  }}
                  placeholder={I18N("startEvent", language)}
                  isDisabled={step.disabled}
                  isMulti={false}
                  options={startEventSelections}
                  value={step.startEvent}
                  onChange={(change) => setStepData("startEvent", key, change)}
                />
              </div>
              <div className="execution">
                <Select
                  theme={(template) => {
                    return {
                      ...template,
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme),
                      },
                    };
                  }}
                  className="fullWidthSelect"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 55,
                      minHeight: 55,
                      borderColor:
                        props.formHasError &&
                        (step.execution === null ||
                          step.execution === undefined)
                          ? "#FF6262"
                          : "rgba(0, 0, 0, 0.23)",
                    }),
                  }}
                  placeholder={I18N("execution", language)}
                  isDisabled={step.disabled}
                  isMulti={false}
                  options={executionTimeSelections}
                  value={step.execution}
                  onChange={(change) => setStepData("execution", key, change)}
                />
              </div>
              <div className="deadline">
                <Select
                  theme={(template) => {
                    return {
                      ...template,
                      colors: {
                        ...template.colors,
                        ...colorForSelect(theme),
                      },
                    };
                  }}
                  className="fullWidthSelect"
                  styles={{
                    control: (base) => ({
                      ...base,
                      height: 55,
                      minHeight: 55,
                      borderColor:
                        props.formHasError &&
                        (step.deadline === null || step.deadline === undefined)
                          ? "#FF6262"
                          : "rgba(0, 0, 0, 0.23)",
                    }),
                  }}
                  placeholder={I18N("deadline", language)}
                  isDisabled={key === 0 ? false : step.disabled}
                  isMulti={false}
                  options={deadlineLimits}
                  value={step.deadline}
                  onChange={(change) => setStepData("deadline", key, change)}
                />
              </div>
              <div className="videoBox">
                <SelectAutocomplete
                  margin={0}
                  size={"55px"}
                  multiple={true}
                  maxTags={1}
                  options={videos.map((video) => {
                    return {
                      label:
                        LanguageExporter(
                          video,
                          language,
                          "video_titles",
                          "title",
                          "title",
                        ) +
                        " (" +
                        VideoTypeExporter(video.type) +
                        ")",
                      value: video.id,
                    };
                  })}
                  value={step.videos}
                  onChange={(change) => {
                    setStepData("video", key, change);
                  }}
                />

                {/*<Select theme={(template) => {*/}
                {/*    return {*/}
                {/*        ...template,*/}
                {/*        colors: {*/}
                {/*            ...template.colors,*/}
                {/*            ...colorForSelect(theme)*/}
                {/*        }*/}
                {/*    }*/}
                {/*}} className="fullWidthSelect" styles={{*/}
                {/*    valueContainer: (styles) => {*/}
                {/*        return {*/}
                {/*            ...styles,*/}
                {/*            height: 50,*/}
                {/*            scrollbarWidth: "none",*/}
                {/*            msOverflowStyle: "none",*/}
                {/*            overflowX: "hidden",*/}
                {/*            overflowY: "scroll"*/}
                {/*        };*/}
                {/*    },*/}
                {/*    control: base => ({*/}
                {/*        ...base,*/}
                {/*        minHeight: 55,*/}
                {/*        height: 55,*/}
                {/*        borderColor: (props.formHasError && (step.videos === null || step.videos === undefined || step.videos.length < 1)) ? "#FF6262" : "rgba(0, 0, 0, 0.23)",*/}
                {/*    })}} isMulti={true} options={videos.map((video) => {*/}
                {/*    return {label: video.title + (video.type === 1 ? ' (Video)' : ' (Pdf)'), value: video.id}*/}
                {/*})}*/}
                {/*        placeholder={I18N('content', language)}*/}
                {/*        value={step.videos} onChange={(change) => {*/}
                {/*    setStepData('video', key, change);*/}
                {/*}} />*/}
              </div>
              {key !== 0 && (
                <div className="deleteBox">
                  <FormButton
                    text={"-"}
                    smallButton={true}
                    onClick={() => deleteRow(key)}
                    isLoading={false}
                    deleteButton={true}
                  />
                </div>
              )}
              <div className="clearMe" />
            </div>
          </div>
        ))}
      </ViewBox>
    </React.Fragment>
  );
}
