import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../../../i18n/i18n";
import {useTheme} from "@mui/material";
import {useLanguageState} from "../../../States/LanguageState";
import {CompanyRepository} from "../../../Repositories/CompanyRepository";
import ReactApexChart from "react-apexcharts";


export default function NumberOfVideos(props: {
    companies: any;
}){

    const theme = useTheme();
    const language = useLanguageState(state => state.language);
    const [companySeries, setCompanySeries] = useState([]);
    const [labels, setLabels] = useState([]);


    const calculateData = useCallback((companyData: any) => {

        const calculations = [];
        const labels = [];

        companyData.forEach((company) => {
            calculations.push(company.content.length);
            labels.push(company.name);
        });

        setCompanySeries(calculations);
        setLabels(labels)

    }, []);

    useEffect(() => {

        calculateData(props.companies);

    }, [calculateData, props.companies]);


    const settings = useCallback(() => {
        return {

            stroke: {
                colors: [theme.palette.mode === 'dark' ? '#FFE16A' : '#fff'],
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            xaxis: {
                categories: labels,
            },
            legend: { show: false },
            dataLabels: {
                enabled: false
            }
        }

    }, [language, theme, labels]);




    return (
        <>
            {labels.length === 0 ? (
                'Loading'
            ): (
                <ReactApexChart
                    type="bar"
                    options={settings()} series={[{
                        name: 'Videos',
                    data: companySeries
                }]}
                    height={280}
                />
            )}
        </>

    )

}