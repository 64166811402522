import React, {Component, useCallback, useEffect, useState} from 'react';
import { standartColors } from '../../../assets/colors/Colors';
import previewPic from '../../../assets/previewPic.png';
import {Table} from "../../../UiLibrary/table/Table";
import ViewBox from "../../../ViewComponents/ViewBox";
import {I18N} from "../../../i18n/i18n";
import {useLanguageState} from "../../../States/LanguageState";
import {BACKEND_URL} from "../../../assets/AppConstants";
import {VideoRepository} from "../../../Repositories/VideoRepository";
import {Backdrop, CircularProgress} from "@mui/material";


const videoRepository = new VideoRepository();

export function MyVideos() {

    const language = useLanguageState(state => state.language)
    const [contents, setContents] = useState([]);
    const [loading, setLoading] = useState(false);

    const getContents = useCallback( async (contentId = null) => {

        setLoading(true);
        const contentGet = await videoRepository.getUserContent();

        if(contentId !== null){
            const found = contentGet.find((content) =>  content.id === contentId);
            if(found){
                //setSelectedVideo(found);
            }
        }

        setContents(contentGet.sort((a, b) => a.title.localeCompare(b.title)));

        setLoading(false);
    }, []);


    //Calling receive function when page opens
    useEffect(() => {
        getContents().then(undefined);
    }, [getContents]);


    const openVideo = useCallback((video) => {

    }, []);

    const NoContentHolder =  useCallback((type: 'toView' | 'extraContent' | 'allContent' | 'viewed') => {
        return (
            <div className="alert alert-info">
                <p>
                    {I18N('noContent', language, [{key: 'contentName', value: I18N(type, language)}])}
                </p>

            </div>
        )
    }, [language]);

    const ContentHolder = useCallback((content: any) => {
        let status = "open";
        if(content.user_content_data.length > 0){
            status = 'inProgress';
        }else if(content.seen_videos.length > 0){
            status = 'done';
        }else{
            status = 'open';
        }
        return (
            <div onClick={() => openVideo(content)} key={content.id} className="contentInner" style={{position: "relative"}}>
                <div style={{background: status === 'inProgress' ? '#FFEEAB' : status === 'open' ? '#F2F2F2' : '#25AF60', color: status === 'open' ? '#fff' : '#000', borderRadius: '0 10px 10px 0', width: 94, textAlign: "center", position: "absolute", fontSize: 12, top: 20, left:0}}>
                    {I18N(status, language)}
                </div>
                <p className="content-text">{content.title} {content.seen_videos.length > 0 && <div className="finished" />}</p>
                <img  className="content-picture" src={BACKEND_URL + content.image} alt={content.title} />
            </div>
        )
    }, []);

    return (
            <ViewBox>
                <Backdrop
                    open={loading}
                    style={{ color: standartColors.primaryYellow, zIndex:999 }}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="flex-content">
                    {(loading || contents.length > 0) ? (
                        <React.Fragment>
                            {contents.map((content) => ContentHolder(content))}
                        </React.Fragment>
                    ): (NoContentHolder('allContent'))}
                </div>
            </ViewBox>
        )
}

