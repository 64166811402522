import {Backdrop, CircularProgress} from "@mui/material";
import {standartColors} from "../assets/colors/Colors";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Back10 from "../assets/back10.png";
import PauseSVG from "../assets/pause.svg";
import PlaySVG from "../assets/play.svg";
import {secondsToHms} from "../Helpers/SecondsToPrettyTime";
import ReactPlayer from "react-player";
import {useLanguageState} from "../States/LanguageState";
import {I18N} from "../i18n/i18n";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {EGRCRequestFile} from "./EGRCSender";
const sweetAlert = withReactContent(Swal)

export default function EGRCContentPlayer(props: {content: any, token: string}){

    const [loading, setLoading] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const language = useLanguageState(state => state.language);
    const [totalTime, setTotalTime] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const playerRef = useRef<ReactPlayer>(null);
    const [playerLoaded, setPlayerLoaded] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const [fullLoading, setFullLoading] = useState(false);
    const loadVideo = useCallback(async () => {
        setFullLoading(true);
        const getVideoURL = await EGRCRequestFile(props.content.key, props.token);
        setFullLoading(false);
        if(getVideoURL.status){
            setVideoUrl(getVideoURL.url)
        }else{
            await sweetAlert.fire({
                title: I18N('error', language),
                html: I18N('errorWhileLoadingContent', language),
                icon: "error"
            });
        }


    }, [props.content, props.token]);


    useEffect(() => {
        loadVideo().then(undefined);
    }, [loadVideo]);

    const back10secs = useCallback(() => {

        if(playerRef.current !== null){
            const currentTime = playerRef.current.getCurrentTime();
            if(currentTime - 10 >= 0){
                playerRef.current.seekTo(currentTime - 10, 'seconds');
            }else{
                playerRef.current.seekTo(0);
            }
        }

    }, [playerRef]);

    return (
        <React.Fragment>
            <Backdrop
                open={fullLoading}
                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {videoUrl !== null && (
                <div onClick={() => setIsPlaying(!isPlaying)} style={{position: "relative", marginTop: 70, height: '60vh'}} className={"onlyHoverVideo"}>
                    <div className={`playPause ${isPlaying ? 'onlyHover' : ''}`}>{isPlaying ? (<React.Fragment>
                            <img style={{filter: 'brightness(0) invert(1)', marginRight: 20}} onClick={back10secs} src={Back10} width={50} height={50} />
                            <img src={PauseSVG} width={50} height={50} />
                        </React.Fragment>
                    ) : <img width={50} height={50} src={PlaySVG} />}</div>
                    <div className="currentTime">
                        {secondsToHms(currentTime)} / {secondsToHms(totalTime)}
                    </div>

                    <ReactPlayer ref={playerRef}
                                 onReady={() => {
                                     setPlayerLoaded(true);
                                 }}
                                 onSeek={() => {
                                     if(playerLoaded){
                                         setIsPlaying(true);
                                     }
                                 }}
                                 onPause={() => setIsPlaying(false)}
                                 onStart={() => setIsPlaying(true)}
                                 onDuration={(duration) => setTotalTime(duration)}
                                 playing={isPlaying}
                                 loop={false}
                                 style={{pointerEvents: "none"}}
                                 onProgress={(progress) => {
                                     setCurrentTime(progress.playedSeconds);
                                 }}
                                 width={'100%'}
                                 height={'100%'}
                                 controls={false}
                                 url={videoUrl} />
                </div>
            )}
        </React.Fragment>
    )

}