import imageWizard from "../assets/wizard.svg";

export default function WelcomeStep(){

    return (
        <div>
            <img src={imageWizard} width={500} style={{position: "absolute", bottom: 50}} />
        </div>
    )

}