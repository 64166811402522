import {TextField} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import FormButton from "../../../ViewComponents/FormButton";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";

const saveKey = "usersFachData";

function UsersStep(props: {title: string}, ref){
    const language = useLanguageState(state => state.language);
    const [formState, setFormState] = useState([{
        name: '',
        surname: '',
        emailAddress: ''
    }]);

    const clearData = useCallback(() => {

        setFormState([{
            name: '',
            surname: '',
            emailAddress: ''
        }]);
        localStorage.removeItem(saveKey);

    }, []);

    const checkLocalStorage = useCallback(() => {

        const usersFachData = localStorage.getItem(saveKey);
        if(usersFachData){
            setFormState(JSON.parse(usersFachData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formState));

    }, [formState]);

    return (
        <div style={{padding: 10, maxHeight: 380, overflowY: "scroll"}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>

            {formState.map((i, key) => {
                return (
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <TextField
                            fullWidth
                            label={I18N('firstName', language)}
                            style={{ marginTop: 20,color: "#fff", marginRight: 5 }}
                            value={formState[key].name}
                            onChange={(text) => {
                                formState[key].name = text.target.value;
                                setFormState([...formState]);
                            }}
                        />
                        <TextField
                            fullWidth
                            label={I18N('lastName', language)}
                            style={{ marginTop: 20,color: "#fff", marginRight: 5 }}
                            value={formState[key].surname}
                            onChange={(text) => {
                                formState[key].surname = text.target.value;
                                setFormState([...formState]);
                            }}
                        />
                        <TextField
                            fullWidth
                            label={I18N('email', language)}
                            style={{ marginTop: 20,color: "#fff" }}
                            value={formState[key].emailAddress}
                            onChange={(text) => {
                                formState[key].emailAddress = text.target.value;
                                setFormState([...formState]);
                            }}
                        />
                    </div>
                )
            })}

            <div style={{marginTop: 20}}>
                <FormButton text={I18N('new', language)} color="dark" icon="plus" onClick={() => {
                    formState.push({
                        name: '',
                        surname: '',
                        emailAddress: ''
                    });
                    setFormState([...formState]);
                }} isLoading={false} />
            </div>



            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )
}

const Users = React.forwardRef(UsersStep);

export default Users;