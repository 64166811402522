import axios from "axios";

export class VideoUserUploadRepository{
    uploadPicture(file: File, uploadProgressCallback?: (name: File, progress: number, loaded: number) => void): Promise<string>{
        return new Promise((resolve, reject) => {
            const fileHolder = new FormData();
            fileHolder.append('file', file);
            axios.post('/videos/uploadUser', fileHolder, {
                onUploadProgress: (progressEvent) => {
                    if(uploadProgressCallback !== undefined){
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        console.log(progressEvent);
                        if (totalLength !== null) {
                            uploadProgressCallback(file, Math.round( (progressEvent.loaded * 100) / totalLength), progressEvent.loaded);
                        }
                    }
                },
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return resolve(response.data.zip);
            }).catch((error) => {
                return reject(error);
            });

        });
    }
}
