import React, { useCallback, useEffect, useState } from 'react';
import { VideoInfo } from './VideoInfo';
import VideoList from './VideoList';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import { VideoRepository } from '../Repositories/VideoRepository';
import { VideoUploadRepository } from '../Repositories/VideoUploadRepository';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { ContentEdit } from './ContentEdit';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { DepartmentType } from '../Types/DepartmentTypes';

const videoRepository = new VideoRepository();
const videoUploadRepository = new VideoUploadRepository();

type Props = {
  style: any;
  isMobile: () => boolean;
  hideSideBar: (status: boolean) => void;
};

export type ContentTypeState = {
  title: string;
  url: string;
  departments: number[];
  trophies: number[];
  trophy: { value: number; label: string } | undefined;
  imageURL: string;
  categories: { value: number; label: string } | undefined;
  visible: boolean;
  visibleForAll: boolean;
  requiredForAll: boolean;
  type: 1 | 2 | 3 | 4;
  content: { value: number; label: string } | undefined;
  quiz: undefined | { value: number; label: string };
  standalone: undefined | { value: number; label: string };
  companies: undefined | { value: number; label: string }[];
  mandatoryDepartments: number[];
  accountable?: { value: string; label: string } | undefined;
  execution_responsible?: { value: string; label: string } | undefined;
  execution_responsibles?: { value: string; label: string }[] | [];
};

export function VideoPage(props: Props) {
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editVideo, setEditVideo] = useState<null | any>(null);
  const language = useLanguageState((state) => state.language);
  const [formLoading, setFormLoading] = useState(false);
  const [contentList, setContentList] = useState([]);
  const breadCrumb = useBreadCrumb();
  const { enqueueSnackbar } = useSnackbar();

  const [titleFields, setTitleFields] = useState(null);
  const [formState, setFormState] = useState<ContentTypeState>({
    title: '',
    url: '',
    departments: [],
    trophies: [],
    trophy: undefined,
    imageURL: '',
    categories: undefined,
    visible: true,
    visibleForAll: false,
    type: 1,
    requiredForAll: false,
    content: undefined,
    quiz: undefined,
    standalone: undefined,
    companies: undefined,
    mandatoryDepartments: []
  });

  const location = useLocation();
  const [mountKey, setMountKey] = useState(undefined);

  useEffect(() => {
    if (location.key !== undefined) {
      if (location.key !== mountKey) {
        onReturnBackToView();
      }
    }
    setMountKey(location.key);
  }, [location, mountKey]);

  const scrollTop = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, []);

  const backToVideoTable = useCallback(() => {
    setCreateOpen(false);
    setEditVideo(null);
    scrollTop();
  }, []);

  const getContentList = useCallback(async () => {
    const getList = await videoUploadRepository.getContentList();

    setContentList(getList);
  }, []);

  const saveVideo = useCallback(async () => {
    //Checking if fields are filled
    console.log(formState);

    if (formState.url === undefined && formState.content === undefined) {
      if (formState.standalone !== undefined && formState.standalone !== null) {
        formState.url = String(formState.standalone.value);
        formState.type = 4;
      } else {
        enqueueSnackbar(
          I18N('pleaseFill', language, [
            { key: 'name', value: I18N('content', language) }
          ]),
          { variant: 'error' }
        );
        return;
      }
    }

    if (
      formState.url === '' &&
      formState.standalone !== null &&
      formState.standalone !== undefined
    ) {
      formState.url = String(formState.standalone.value);
      formState.type = 4;
    }

    let key = '';

    if (formState.content !== undefined) {
      const findSelectedContent = contentList.find(
        (content) => content.id === formState.content.value
      );

      if (findSelectedContent === undefined) {
        enqueueSnackbar(
          I18N('pleaseFill', language, [
            { key: 'name', value: I18N('content', language) }
          ]),
          { variant: 'error' }
        );
        return;
      } else {
        formState.url = findSelectedContent.url;
        key = findSelectedContent.key;
      }
    } else {
      if (formState.url === '') {
        enqueueSnackbar(
          I18N('pleaseFill', language, [
            { key: 'name', value: I18N('content', language) }
          ]),
          { variant: 'error' }
        );
        return;
      } else {
        if (isNaN(Number(formState.url))) {
          key = formState.url.split('/')[formState.url.split('/').length - 1];
        }
      }
    }

    //(!formState.requiredForAll && formState.trophies.length < 1)
    if (
      titleFields === null ||
      formState.url === '' ||
      formState.trophy === undefined ||
      (!formState.visibleForAll && formState.departments.length < 1) ||
      // formState.imageURL === '' ||
      formState.categories === undefined
    ) {
      enqueueSnackbar(
        I18N('pleaseFill', language, [
          { key: 'name', value: I18N('content', language) }
        ]),
        { variant: 'error' }
      );

      return;
    }

    setFormLoading(true);

    let copyFormState = JSON.parse(JSON.stringify(formState));
    copyFormState.trophy = formState.trophy.value;
    copyFormState.categories = [formState.categories.value];
    copyFormState.quiz =
      formState.quiz !== null && formState.quiz !== undefined
        ? formState.quiz.value
        : undefined;
    copyFormState.accountable =
      formState.accountable !== null && formState.accountable !== undefined
        ? formState.accountable.value
        : undefined;
    copyFormState.execution_responsibles =
      formState.execution_responsibles !== null &&
      formState.execution_responsibles !== undefined
        ? formState.execution_responsibles
        : [];
    copyFormState.standalone =
      formState.standalone !== null && formState.standalone !== undefined
        ? formState.standalone.value
        : undefined;
    copyFormState.key = key;
    copyFormState.companies =
      formState.companies === undefined || formState.companies === null
        ? []
        : formState.companies.map((vals) => vals.value);

    if (editVideo !== null) {
      if (editVideo.type === 1) {
        copyFormState.key = editVideo.key;
      }

      await videoRepository
        .update(
          { ...copyFormState, languages: titleFields },
          Number(editVideo.id)
        )
        .then(async () => {
          setFormLoading(false);

          enqueueSnackbar(
            I18N('successfullyUpdatedReturning', language, [
              { key: 'name', value: I18N('content', language) }
            ]),
            { variant: 'success' }
          );

          onReturnBackToView();
        })
        .catch(async () => {
          setFormLoading(false);
          enqueueSnackbar(I18N('unexpectedError', language), {
            variant: 'error'
          });
        });
      setFormLoading(false);
    } else {
      await videoRepository
        .post({ ...copyFormState, languages: titleFields })
        .then(async () => {
          setFormLoading(false);

          enqueueSnackbar(
            I18N('successfullyCreatedReturning', language, [
              { key: 'name', value: I18N('content', language) }
            ]),
            { variant: 'success' }
          );

          onReturnBackToView();
        })
        .catch(async () => {
          setFormLoading(false);
          enqueueSnackbar(I18N('unexpectedError', language), {
            variant: 'error'
          });
        });
    }
    setFormLoading(false);
  }, [formState, contentList, titleFields]);

  const enrollVideo = useCallback(
    async (depsSelected: DepartmentType[], sendNotification: boolean) => {
      setFormLoading(true);
      await videoRepository.enrollVideo(
        editVideo.id,
        depsSelected.map((i) => i.ID),
        sendNotification
      );

      enqueueSnackbar(
        I18N('successfullyEnrolled', language, [
          { key: 'name', value: I18N('content', language) }
        ]),
        { variant: 'success' }
      );

      setFormLoading(false);
      onReturnBackToView();
      return true;
    },
    [editVideo, language]
  );

  useEffect(() => {
    getContentList().then(undefined);
  }, []);

  const onReturnBackToView = useCallback(() => {
    breadCrumb.setTitle(I18N('videos', language));
    breadCrumb.setCrumb([
      { title: I18N('configuration', language), onClick: undefined },
      {
        title: I18N('videos', language),
        onClick: onReturnBackToView
      }
    ]);
    breadCrumb.setBackButton(null);
    breadCrumb.setChildren([
      {
        key: 'videoCreateNewKey',
        title: I18N('new', language),
        onClick: () => {
          setCreateOpen(!createOpen);
          scrollTop();
        },
        icon: 'plus',
        color: 'dark'
      }
    ]);
    backToVideoTable();
  }, [language]);

  useEffect(() => {
    onReturnBackToView();
  }, []);

  return (
    <div style={props.style}>
      {!createOpen ? (
        <VideoList
          editMode={(item) => {
            setEditVideo(item);
            setCreateOpen(true);
            scrollTop();
          }}
        />
      ) : (
        <>
          {editVideo ? (
            <ContentEdit
              onEnroll={enrollVideo}
              onFormStateChange={setFormState}
              onFinish={saveVideo}
              backCallback={onReturnBackToView}
              editMode={editVideo}
              isMobile={props.isMobile}
              canEdit={editVideo?.canEdit}
              canEnroll={editVideo?.canEnroll}
            />
          ) : (
            <VideoInfo
              hideSidebar={props.hideSideBar}
              onEnroll={enrollVideo}
              onFormStateChange={(a, b) => {
                setFormState(a);
                setTitleFields(b);
              }}
              onFinish={saveVideo}
              backCallback={onReturnBackToView}
              editMode={editVideo}
              isMobile={props.isMobile}
            />
          )}
        </>
      )}
    </div>
  );
}
