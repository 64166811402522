import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {LearnVideosType} from "../Types/LearnVideosTypes";
import {PaginationTypes} from "../Types/PaginationTypes";
import {DepartmentType} from "../Types/DepartmentTypes";

export class VideoRepository implements AbstractRepository<PaginationTypes<LearnVideosType> | LearnVideosType>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }


    setVideoToQuiz(contentID: number): Promise<any[]> {

        return new Promise((resolve, reject) => {

            return axios.post('/videos/setContentToQuiz/' + contentID).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        })

    }

    userWatchTrophies(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.get('/videos/user/trophy/'+id).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getContentUrl(id): Promise<{url: string, status: boolean}>{

        return new Promise((resolve, reject) => {
            axios.get('/videos/urlRequest/'+ id).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });

    }

    getPDFUrl(key): Promise<{url: string, status: boolean}>{

        return new Promise((resolve, reject) => {
            axios.get('/videos/pdfUrl/'+ key).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });

    }

    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete("/videos/" + id).then((response) => {
                resolve(response.data)
            }).catch((error) => {
                reject(error);
            })

        })
    }

    // @ts-ignore
    getAll(pagination?: {limit: number, page: number, orderType: null | {column: string, type: string}, filter: null | {column: string, value: string, operator: string}}): Promise<PaginationTypes<LearnVideosType> | LearnVideosType[]> {
        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/videos${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getUserContent(): Promise<LearnVideosType[]> {
        return new Promise((resolve, reject) => {

            axios.get('/videos/user').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAllDep(): Promise<any[]>{

        return new Promise((resolve, reject) => {

            axios.get('/videos/department').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    markAsSeen(contentID: number): Promise<any[]> {

        return new Promise((resolve, reject) => {

            return axios.post('/videos/markAsSeen/' + contentID).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        })

    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    enrollVideo(id: number, depsSelected: number[], sendNotification: boolean): Promise<any>{

        return new Promise((resolve, reject) => {

            return axios.patch('/videos/enroll/'+id, {deps: depsSelected, sendNotification: sendNotification}).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });

    }

    post(object: any): Promise<any> {
       return new Promise((resolve, reject) => {

          axios.post('/videos', object).then((response) => {
              return resolve(response.data);
          }).catch((error) => {
              return reject(error);
          })

       });
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/videos/'+id, object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }


    updateCompanies(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/videos/'+id+'/companies', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    updateDepartments(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/videos/'+id+'/departments', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    updateImage(image: string, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/videos/'+id+'/image', {image: image}).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    updateGeneral(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/videos/'+id+'/general', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    uploadPicture(file: File, original?:boolean): Promise<string>{
        return new Promise((resolve, reject) => {

            const fileHolder = new FormData();
            fileHolder.append('file', file);

            axios.post(`/videos/${original ? "uploadNoResize" : 'upload'}`, fileHolder, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return resolve(response.data.image);
            }).catch((error) => {
                return reject(error);
            });

        });
    }

    getWatchList(){

        return new Promise<{ userTotal: number, contentData: any[]; newUserData: any[] }>((resolve, reject) => {

            axios.get('/videos/watchlist').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getWatchListSingleUser(userId: string){

        return new Promise<{ videos: any[], contentData: any[], userData?: any }>((resolve, reject) => {

            axios.get(`/videos/watchlist/user/${userId}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getWatchListSingle(contentID: number){

        return new Promise<{ userTotal: number, contentData: any, departments: any, userData?: any; }>((resolve, reject) => {

            axios.get('/videos/watchlist/'+contentID).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getWatchListDepartments(activeCompany?: number){

        return new Promise<{ userTotal: number, contentData: any, videos: any }>((resolve, reject) => {

            axios.get('/videos/watchlist/deps' + (activeCompany ? `/${activeCompany}` : '')).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            });
        });

    }

    getWatchListSingleDep(department: number){

        return new Promise<{ depData: any, videos: any }>((resolve, reject) => {

            axios.get(`/videos/watchlist/department/${department}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            });
        });

    }

    getWatchListUser(){

        return new Promise<{ videos: any, contentData: any }>((resolve, reject) => {

            axios.get('/videos/watchlist/user').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            });
        });

    }

    getWatchListSingleDepartment(contentID: number, depID: number){

        return new Promise<{ departmentUsers: {userData: any, contentData: any}[], contentData: any }>((resolve, reject) => {

            axios.get('/videos/watchlist/'+contentID+'/'+depID).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

}
