export const standartColors = {
    primaryYellow: '#FFE16A',
    darkYellow: '#FFC107',
    brightYellow: '#F2C94C',
    veryBrightYellow: '#FFF7CD',
    white: '#fff',
    subTitleHeadline: '#637381',
    subtitle2: '#828282',
    black: '#000',
    profile: '#F2F2F2',
    fontColorH1: '#2F3640',
    fontColorGrayH1: '#2F3640',
    darkerGray: '#BDBDBD',
    circleYellow: "#FFE47B",
    circleBlue: "#2F80ED",
    circleGray: "#E0E0E0"
}
