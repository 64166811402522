import {I18N} from "../i18n/i18n";
export function PassStringCreator(reset: any, languageState) {

    let buildText = '';

    if(reset.minLenght !== 0){
        buildText +=  I18N('passMinLenght', languageState, [{key: 'min', value: reset.minLenght}]) + '\n';
    }
    if(reset.digits){
        buildText +=  I18N('passMustDigits', languageState) + '\n';
    }
    if(reset.capitalLetters){
        buildText +=  I18N('passMustCapitalLetters', languageState) + '\n';
    }

    if(reset.specialChars){
        buildText +=  I18N('passMustSpecialChars', languageState) + '\n';
    }

    return buildText;

}
