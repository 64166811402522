import Fab from '@mui/material/Fab';
import { Drawer } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Icon from '../Icon';
import Container from './Container';
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import ColorModeContext from "../../ColorMode";
import {useTheme} from "@mui/material";
import playSide from '../../assets/playSide.svg';
import stepGreen from "../../assets/stepGreen.svg";
import stepGray from "../../assets/stepGray.svg";
import FormButton from "../FormButton";
import {DrawerConfigSx} from "../DrawerConfigSx";
import ViewBox from "../ViewBox";

const IconStyle = styled(Fab)(() => ({
    background: 'white',
    color: 'black',
    position: 'fixed'
}));

const SidebarContainer = styled('div')(() => ({
    position: 'fixed',
    right: '2px',
    top: '50%;',
    transform: 'translateY(-50%)',
    width: '50px',
    zIndex: '3',
    fontSize: '14px'
}));

const SidebarDrawer = styled('div')(() => ({
    width: '537px',
    borderRadius: '10px',
    fontSize: '12px',
    padding: '20px',
    '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        marginBottom: '20px',
        '& p': {
            margin: '0',
            fontWeight: 'bold'
        },
        '& svg': {
            cursor: 'pointer'
        }
    },
    '& .box': {
        marginTop: '59px',
        '& .small-boxes .card': {
            height: '50px',
            fontSize: '12px'
        },
        '& .card': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '151px',
            height: '88px',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
            margin: '7px',
            cursor: 'pointer'
        },
        '& > div': {
            display: 'flex',
            flexWrap: 'wrap'
        },
        '& p': {
            margin: '0',
            fontSize: '12px',
            fontWeight: 'bold'
        },
        '& .center-boxes': {
            justifyContent: 'center',
            '& p': {
                fontSize: '14px'
            }
        }
    },
    '& img': {
        marginTop: '5px',
        marginBottom: '5px'
    },
    '& .active-icon': {
        position: 'absolute',
        right: '8px',
        top: '5px'
    }
}));

const SettingsSidebar = (props) => {
    const language = useLanguageState();
    const [open, setOpen] = useState(false);

    const theme = useTheme<any>();
    const colorMode = React.useContext(ColorModeContext);
    return (
        <>


            <SidebarContainer>
                <IconStyle style={{boxShadow: theme.palette.type === "dark" && '0px 8px 16px rgba(145, 158, 171, 0.24)', borderColor: theme.palette.type === "dark" && theme.palette.background.paper, background: theme.palette.mode === 'dark' ? "#0A1929" : "#fff"}} ria-label="add" size="small" onClick={() => setOpen(true)}>
                    <Icon name="AdjustmentsIcon" size={20} color={theme.palette.mode === 'dark' ? '#fff' : "#000"} />
                </IconStyle>
                <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={open} onClose={() => setOpen(false)}>
                    <SidebarDrawer>
                        <div className="header">
                            <p>{I18N('sideSettings', language.language)}</p>
                            <div
                                className="cursor-pointer"
                                onClick={() => setOpen(false)}
                                role="button"
                                tabIndex={0}
                            >
                                <Icon name="XIcon" size={20} />
                            </div>
                        </div>

                        <ViewBox>
                            <div className="box" style={{marginTop: 2}}>
                                <p>{I18N('theme', language.language)}</p>
                                <div className="center-boxes">
                                    <Container
                                        onClick={() => {
                                            colorMode.toggleColorMode()
                                        }}
                                        title={I18N('lightMode', language.language)}
                                        icon="sun"
                                        active={theme.palette.mode === 'light'}
                                    />
                                    <Container
                                        onClick={() => colorMode.toggleColorMode()}
                                        title={I18N('darkMode', language.language)}
                                        icon="moon"
                                        active={theme.palette.mode === 'dark'}
                                    />
                                </div>
                            </div>
                        </ViewBox>

                        <ViewBox>
                            <div className="box" style={{marginTop:2}}>
                                <p>{I18N('language', language.language)}</p>
                                <div className="center-boxes">
                                    <Container
                                        onClick={() => language.setLanguage('de')}
                                        title={I18N('german', language.language)}
                                        icon="germanFlag"
                                        active={language.language === 'de'}
                                    />
                                    <Container
                                        onClick={() => language.setLanguage('en')}
                                        title={I18N('english', language.language)}
                                        icon="englishFlag"
                                        active={language.language === 'en'}
                                    />
                                </div>
                            </div>
                        </ViewBox>


                        {/*<div className="box">*/}
                        {/*    <p>{I18N('setupAssistant', language.language)}</p>*/}

                        {/*    <div style={{position: "relative", marginTop: 20}}>*/}
                        {/*        <hr style={{position: "absolute", width: '100%', color: "#f2f2f2", background: "#f2f2f2", borderColor: "#f2f2f2", opacity: 0.6, top: 4}} />*/}
                        {/*        <p style={{color: "#54667A", fontSize: 14, fontWeight: "normal", position: "relative", background: "#fff", marginLeft: 25, paddingLeft: 5, paddingRight: 10}}>*/}
                        {/*            {I18N('techAdminSide', language.language)}*/}
                        {/*        </p>*/}
                        {/*        <div onClick={() => {*/}
                        {/*            setOpen(false);*/}
                        {/*            props.setIsTechWizardOpen(!props.isTechWizardOpen)*/}
                        {/*        }*/}
                        {/*        } style={{cursor: "pointer", padding: 5, borderRadius: 10, background: "#FFE16A", width: 35, height: 35, zIndex:9, marginTop: -5, position: "absolute", right: 20}}><img width={20} height={20} style={{width: 20, height: 20, marginTop: 2, marginLeft: 2}} src={playSide} /></div>*/}
                        {/*    </div>*/}

                        {/*    <div>*/}

                        {/*        <ul>*/}

                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGreen} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('companyStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}


                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGreen} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('departmentStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}

                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGray} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('usersStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}



                        {/*        </ul>*/}

                        {/*    </div>*/}

                        {/*    <div style={{position: "relative", marginTop: 20}}>*/}
                        {/*        <hr style={{position: "absolute", width: '100%', color: "#f2f2f2", background: "#f2f2f2", borderColor: "#f2f2f2", opacity: 0.6, top: 4}} />*/}
                        {/*        <p style={{color: "#54667A", fontSize: 14, fontWeight: "normal", position: "relative", background: "#fff", marginLeft: 25, paddingLeft: 5, paddingRight: 10}}>*/}
                        {/*            {I18N('fachAdminSide', language.language)}*/}
                        {/*        </p>*/}
                        {/*        <div onClick={() => {*/}
                        {/*            setOpen(false);*/}
                        {/*            props.setIsFachWizardOpen(!props.isFachWizardOpen)*/}
                        {/*        }*/}
                        {/*        } style={{cursor: "pointer", padding: 5, borderRadius: 10, background: "#FFE16A", width: 35, height: 35, zIndex:9, marginTop: -5, position: "absolute", right: 20}}><img width={20} height={20} style={{width: 20, height: 20, marginTop: 2, marginLeft: 2}} src={playSide} /></div>*/}
                        {/*    </div>*/}

                        {/*    <div>*/}

                        {/*        <ul>*/}

                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGreen} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('categoriesStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}


                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGreen} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('trophiesStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}

                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGray} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('learningContentStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}

                        {/*            <li style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>*/}
                        {/*                <div style={{width: 30}}>*/}
                        {/*                    <img src={stepGray} />*/}
                        {/*                </div>*/}
                        {/*                <p style={{padding: 5, margin: 0}}>*/}
                        {/*                    {I18N('learningPlanStep', language.language)}*/}
                        {/*                </p>*/}

                        {/*            </li>*/}
                        {/*        </ul>*/}

                        {/*    </div>*/}



                        {/*</div>*/}

                    </SidebarDrawer>

                    <div style={{marginLeft: "auto", marginRight: "auto"}}>
                        <FormButton isLeft={true} style={{width: '100%'}} text={I18N("showReleaseNotes", language.language)} onClick={() => {
                            setOpen(false);
                            props.showReleaseNote(true)
                        }} isLoading={false} />
                    </div>

                </Drawer>
            </SidebarContainer>
        </>
    );
};

export default SettingsSidebar;
