import {I18N} from "../i18n/i18n";

export default function (object: any, requiredLanguage: 'en' | 'de', languageModelKey: string, requiredKey: string, fallbackKey?: string){

    if(object.hasOwnProperty(languageModelKey)){

        if(object[languageModelKey].length === 1){
            return object[languageModelKey][0][requiredKey];
        }

        const findRequired = object[languageModelKey].find((i) => i.key.toLowerCase() === requiredLanguage.toLowerCase());

        if(findRequired){
            return findRequired[requiredKey];
        }

    }

    if(fallbackKey){
        return object[fallbackKey];
    }

    return I18N('noLanguageFound', requiredLanguage);

}