import create from 'zustand';

type GlobalSettingsType = {
    settings: {key: string, value: string}[],
    setSettings: (settings: {key: string, value: string}[]) => void;
};

export const useGlobalSettingsState = create<GlobalSettingsType>(set => ({
    settings: [],
    setSettings: (settings) => {
        set({settings})
    }
}));
