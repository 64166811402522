import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../../../i18n/i18n";
import {useTheme} from "@mui/material";
import {useLanguageState} from "../../../States/LanguageState";
import {CompanyRepository} from "../../../Repositories/CompanyRepository";
import ReactApexChart from "react-apexcharts";


const companyRepository = new CompanyRepository()

export default function Licences(props: {
    companies: any;
}){

    const theme = useTheme();
    const language = useLanguageState(state => state.language);
    const [companySeries, setCompanySeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [licences, setLicences] = useState([]);

    const loadLicences = useCallback(async () => {

        const licenceList = await companyRepository.getLicenses();
        setLicences(licenceList);

    }, []);


    useEffect(() => {

        const labels = [];
        licences.forEach((licence)  => {
            labels.push(licence.name);
        });

        labels.push('No Licence');


        setLabels(labels);

    }, [licences]);

    const calculateData = useCallback((companyData: any) => {

        const calculations = labels.map(() => 0);


        companyData.forEach((company) => {

            if(company.licences !== null){

                const licenceIndex = labels.findIndex((i) => {
                    return i === company.licences.name
                });

                calculations[licenceIndex] = calculations[licenceIndex] + 1;
            }else{

                calculations[labels.length -1] = calculations[labels.length - 1] + 1;

            }
        });

        setCompanySeries(calculations);

    }, [labels, licences]);

    useEffect(() => {

        loadLicences().then(undefined)
        calculateData(props.companies);

    }, [props.companies]);


    const settings = useCallback(() => {
        return {
            labels: labels,
            stroke: {
                colors: [theme.palette.mode === 'dark' ? '#FFE16A' : '#fff'],
            },
            plotOptions: {
                pie: {
                    donut: {
                        borderColor: theme.palette.mode === 'dark' ? '#FFE16A' : '#fff',
                        labels: {
                            show: true,
                            value: {
                                color: theme.palette.mode === 'dark' ? '#FFE16A' : '#000',
                            },
                            total: {
                                label: I18N('total', language),
                                show: true,
                                color: theme.palette.mode === 'dark' ? '#fff' : "#000"
                            }
                        },
                        size: '90%'
                    }
                }
            },
            legend: { show: false },
            dataLabels: {
                enabled: false
            }
        }

    }, [language, theme, labels]);




    return (
        <>
            {labels.length === 0 && companySeries.length === 0 ? (
                'Loading'
            ): (
                <ReactApexChart
                    type="donut"
                    options={settings()} series={companySeries}
                    height={280}
                />
            )}
        </>

    )

}