import {AbstractRepository} from "./abstract-repository";
import {useAxios} from "./AxiosHook";
import axios from "axios";

export class ProfileRepository implements AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    getAll(): Promise<any[]> {
        return Promise.resolve([]);
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return Promise.resolve(undefined);
    }

    //@ts-ignore
    update(object: any, id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('/users/'+id, object).then((data) => {
                resolve(data);
            }).catch((err) => reject(err));
        });
    }

    updateSelf(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('/users/updateSelf', object).then((data) => {
                resolve(data);
            }).catch((err) => reject(err));
        });
    }

    updatePassword(object: any): Promise<any> {
        return new Promise(async (resolve, reject) => {




            axios.patch('/users/updatePassword', object).then((data) => {

                console.log(data);
                    if(data){
                        return resolve({
                            status: data.status,
                            ...data.data
                        })
                    }else{
                        return reject({
                            status: false
                        })
                    }
            }).catch((err) => {
                console.log(err.data);
                if(err.response.data.message === 'passwordsNoMatch'){
                    return reject({
                        message: 'passwordsNoMatch',
                        status: false
                    })
                }else{
                    return reject(({
                        status: false,
                    }))
                }
            })
        });
    }

    updateTimer(object: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await axios.patch('/users/updateTimer', object).then((data) => {
                if(data){
                    return resolve({
                        status: data.status,
                        err: null
                    })
                }else{
                    return resolve({
                        status: false,
                        err: null
                    })
                }
            }).catch((err) => {
                console.log(err);
                return resolve({
                    status: false, err: err
                })
            });
        });
    }

    updatePersonalInfo(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('/users/updatePersonalInfo', object).then((data) => {
                resolve(data);
            }).catch((err) => reject(err));
        });
    }

    updateImage(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.patch('/users/updateImage', object).then((data) => {
                resolve(data);
            }).catch((err) => reject(err));
        });
    }




    updatePasswordOld(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.put('/updatePassword', object).then((data) => {
                resolve(data);
            }).catch((err) => reject(err));
        });
    }

    updatePicture(file: File): Promise<any>{

        return new Promise((resolve, reject) => {
            let formData = new FormData();
            formData.append('file', file);
            axios.post("/users/upload", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                resolve(response.data);
            }).catch((err) => {
                reject(err);
            })

        })



    }



}


export function UpdateProfile(object: any): Promise<any>{

    const axios = useAxios();

    return new Promise((resolve, reject) => {
        axios.current.post('/updateProfileInfo', object).then((data) => {
            resolve(data);
        }).catch((err) => reject(err));
    });

}
