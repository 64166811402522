import {Box, Checkbox, FormControlLabel, FormGroup, Modal} from "@mui/material";
import React, {useCallback, useState} from "react";
import ReleaseNoteImg from "../assets/releaseNotes.jpeg";
import closeButton from "../assets/times.svg";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {useLoginState} from "../States/UserState";

export default function ShowInModal(props: {isOpen: boolean; onClose: () => void, releaseNoteData: any; editData: any; fromHomePage?: boolean}){

    const language = useLanguageState(state => state.language);
    const [isChecked, setIsChecked] = useState(!!Number(localStorage.getItem('doNotShowBox')));
    const loginState = useLoginState(state => state.data);

    console.log(loginState);

    const handleCheckboxChange = useCallback((e) => {

        setIsChecked(e.target.checked);
        localStorage.setItem('doNotShowBox', e.target.checked ? '1' : '0');

    }, []);

    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{...style, width: 850}}>


                <div style={{position: "absolute", top: 10, left: 15,fontSize: 10}}>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox onChange={handleCheckboxChange} checked={isChecked} />} label={I18N('doNotShowAgain', language)} />
                    </FormGroup>
                </div>
                <div style={{position: "absolute", top: 10, right: 15, cursor: "pointer", fontSize: 15, lineHeight: '12px'}} onClick={() => props.onClose()}> <img width={15} height={15} src={closeButton} /> </div>



                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>

                    {props.editData?.headerImage && (

                        <div style={{width: 750, marginTop: 50, height: "auto", position: "relative"}}>
                            {props.editData.headerText && (
                                <p style={{position: "absolute", zIndex: 99, marginTop: '30%', textAlign: "center", fontSize: props.editData?.fontSize ? (props.editData.fontSize + 'px') : 22, fontWeight: "bold", width: 750, color: props.editData.colorCode ?? "#000"}}>
                                    {props.editData.headerText}
                                </p>
                            )}
                            <img src={props.editData.headerImage} width={750} style={{width: 750}} />

                        </div>

                    )}

                    <div style={{width: 750}}>
                        {props.releaseNoteData?.map((i,key) => {
                            return (
                                <React.Fragment>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", position: "relative", padding: 10, marginBottom: 10,}}>
                                        {i.image && i.image !== '' && (((key+1) % 2) === 0) && (
                                            <div style={{position: "relative", padding: 20}}>
                                                <img style={{width: 270, height: "auto"}} src={i.image} />
                                            </div>
                                        )}
                                        <div style={{ position: "relative", width: (i.image && i.image !== '' && i.image !== null) ? 480 : 750}}>

                                            {i.title && i.title !== '' && (
                                                <h2>{i.title}</h2>
                                            )}
                                            <p dangerouslySetInnerHTML={{__html: i.text.replace('{userName}', loginState.given_name + ' ' + loginState.family_name)}}/>
                                        </div>
                                        {i.image && i.image !== '' && (((key+1) % 2) === 1) && (
                                            <div style={{ position: "relative", padding: 20, alignItems: "center", alignContent: "center", alignSelf: "center"}}>
                                                <img style={{width: 270, height: "auto"}} src={i.image} />
                                            </div>
                                        )}
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>

            </Box>
        </Modal>
    )

}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 750,
    overflowY: "scroll",
    maxHeight: '95vh',
    bgcolor: 'background.paper',
    border: '1px solid gray',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};