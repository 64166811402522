import { Drawer, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {colorForSelect} from "../../ComplinessTheme";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../States/LanguageState";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import NotCreatable from "react-select";
import Select from "react-select";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";


const sweetAlert = withReactContent(Swal)


const GeneralContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, updateOrCreateDocument: any; updateForm: any; canEditEverything: boolean, formState: {title: string, deadline: string; classification: any}, authStatus: any, users:any}) => {

    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({title: props.formState.title, deadline: props.formState.deadline, classification: props.formState.classification});
    const [loadingCreation, setLoadingCreation] = useState(false);

    const [canEditEverything] = useState(props.canEditEverything);

    const classificationList = [
        {label: I18N('confidential', language), value: 1},
        {label: I18N('public', language), value: 2},
        {label: I18N('internal', language), value: 3},
        {label: I18N('strictlyConfidential', language), value: 4},
    ]

    const saveChanges = useCallback(() => {
        props.updateForm(formState);
        props.updateOrCreateDocument();
    }, [formState, props]);


    useEffect(() => {

        setFormState({...props.formState});

    }, [props.formState])


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx}  anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={() => {
                            saveChanges();
                        }} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>

                            <NotCreatable styles={{control: base => ({
                                    ...base,
                                    width: 495,
                                    marginTop: 20,
                                    marginLeft: 0,
                                    height: 55,
                                    minHeight: 55
                                })}} theme={(template) => {
                                return {
                                    ...template,
                                    colors: {
                                        ...template.colors,
                                        ...colorForSelect(theme)
                                    }
                                }
                            }} isClearable={true} isMulti={false} onChange={(change) => {
                                //  setResponsilbeUser(change);
                            }}  value={{label: props.authStatus.data?.given_name + ' ' + props.authStatus.data?.family_name, value: props.authStatus.data.sub}} isDisabled={true} isLoading={false} placeholder={I18N('releasedBy', language)} options={props.users.map((user) => {
                                return {label: user.firstName + ' ' + user.lastName, value: user.id}
                            })} />

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralContentBar;
