import React from "react";
import loadingSVG from '../../assets/tail-spin.svg';
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {useTheme} from "@mui/material";
export default function TabBoxes(props: {
    tabs: any;
    onClick: (index: number, outIndex: number) => void;
    configurationInfo: number[];
    headerTitle?: string;
}){
    const language = useLanguageState(state => state.language);
    const theme = useTheme<any>();
    return (
        <div className="marginTop50">
            {props.tabs.map((inner, index) => {
                return (
                    <div className="">
                        {inner.length > 0 && (
                            <React.Fragment>
                                <p className="headerTextConfig">{props.headerTitle === "NO TITLE" ? "" : props.headerTitle ? index === 0 ? props.headerTitle : I18N('quizTabTitle', language) : (index === 0 ? 'Stammdaten' : index === 1 ? 'Lerninhalte' : 'Weitere Einstellungen')}</p>
                                {props.headerTitle === "NO TITLE" ? "" : <div className="borderLine" style={{borderColor: theme.palette.mode === "dark" && "#fff"}} />}
                                <div className="tabBoxes">
                                {inner.map((tab, innerIndex) => (
                                    <div className="tabBox" style={{boxShadow: theme.palette.type === "dark" && '0px 8px 16px rgba(145, 158, 171, 0.24)', borderColor: theme.palette.type === "dark" && theme.palette.background.paper}} key={tab.name} onClick={() => props.onClick(innerIndex, index)}>
                                        <div className="tabIcon" style={{backgroundColor: theme.palette.type === "dark" && theme.palette.background.paper}}>
                                            <img alt={tab.name} src={tab.icon} />
                                        </div>
                                        <div className="boxTextArea" style={{backgroundColor: theme.palette.type === "dark" && theme.palette.background.paper, color: theme.palette.type === "dark" && "#fff"}}>
                                            <div className="tabBoxName">
                                                {tab.name}
                                            </div>
                                            <div className="tabBoxInfo">
                                                <ul>
                                                    {tab.bottomCalculation !== undefined ? (
                                                        <React.Fragment>
                                                            {tab.bottomCalculation.map((bottom) => (
                                                                <li key={bottom.value.toString()}>
                                                                    <i className="activeBox" />
                                                                    {bottom.name} <p>{bottom.value}</p>
                                                                </li>
                                                            ))}
                                                        </React.Fragment>
                                                    ): (
                                                        <React.Fragment>
                                                            {props.configurationInfo !== null  ? (
                                                                <React.Fragment>
                                                                    {props.configurationInfo[tab.key] !== undefined && (
                                                                        <li><i className="activeBox" /> Anzahl {tab.name} <p>{props.configurationInfo[tab.key]}</p></li>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : (
                                                                <li><i className="activeBox" /> Anzahl {tab.name} <p><img src={loadingSVG} width={20} height={20} style={{width: 20, height:20}} /></p></li>
                                                            )}
                                                        </React.Fragment>
                                                    )}

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )
            })}
        </div>
    )

}
