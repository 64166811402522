import {useLanguageState} from "../../States/LanguageState";
import {PermissionItem, PermissionName} from "../Components/TableComponents";
import {I18N} from "../../i18n/i18n";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import GroupPermissionSearcher from "../Components/GroupPermissionSearcher";
import React from "react";

const avPermissions = ["seeOwn", 'see', 'create', 'edit', 'delete'];

export default function PasswordCheck(props: {groupList: any, setSelectedPermission: any}){
    const language = useLanguageState(state => state.language)
    return (
        <>
            <div style={{width: 218}}>
                <PermissionName onClick={() => props.setSelectedPermission({name: "UsePasswordCheck", permits: {seeOwn: '-', see: "ViewPasswordTextsTab", create: "-", delete: "-", edit: "-"}})} title={I18N("UsePasswordCheck", language)} />
            </div>
            <Swiper style={{width: '100%'}} freeMode slidesPerView="auto" spaceBetween={0}>

                {avPermissions.map((i) => {
                    return (
                        <SwiperSlide style={{width: 170}}>
                            <PermissionItem key={"UsePasswordCheck"}>
                                <GroupPermissionSearcher permissionList={{seeOwn: '-', see: "ViewPasswordTextsTab", create: "-", delete: "-", edit: "-"}} groups={props.groupList} permissionToLook={i} />
                            </PermissionItem>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    )

}