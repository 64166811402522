import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import {useWindowSize} from "../Helpers/useWindowSize";
import {QuizRepository} from "../Repositories/QuizRepository";
import {useTheme} from "@mui/material";
import {useBreadCrumb} from "../States/BreadCrumbState";

const WIDTH_LIMIT = 1100;

const quizRepository = new QuizRepository();


export default function QuizSingleAnalytics(props: {quiz: any, onClick: () => void}){

    const language = useLanguageState(state => state.language);

    const [activeQuestion, setActiveQuestion] = useState(null);
    const [activeAnswer, setActiveAnswer] = useState(null);
    const [isClicked] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const windowSize = useWindowSize();
    const theme = useTheme();
    const breadCrumb = useBreadCrumb();



    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 15){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        }else {
            return 'red'
        }


    }, []);


    const selectUserDataForQuestion = useCallback(() => {

        const getAnswerData = props.quiz.user_answers.map((data) => {
            return {
                user_id: data.user_id,
                data: JSON.parse(data.data),
            }
        });


        const data = props.quiz.quiz_questions.map((qData) => {
            const answerData = getAnswerData.map((userData) => {
                let findRightForQuestion = userData.data.compare.find((item) => item.questionId === qData.id);
                if(findRightForQuestion){
                   findRightForQuestion = findRightForQuestion.status ? 1 : 0;
                }else{
                    findRightForQuestion = -1;
                }
                const item = userData.data.answers.answers.filter((answerF) => answerF.questionId === qData.id);
                if(item.length > 0){
                    return {user: userData.user_id, answers: item[0].answers, answerStatus: findRightForQuestion};
                }else{
                    return false;
                }
            }).filter((item) => item !== false);

            return {
                id: qData.id,
                question: qData.question,
                answersStatus: answerData,
                question_answers: qData.question_answers.map((answer) => {
                    const findAnswerItem = answerData.filter((answerUser) => answerUser.answers.includes(answer.id));
                    const calculateWidth = (props.quiz.user_answers.length === 0 ? 0 : answer.correct ? (findAnswerItem.length / props.quiz.user_answers.length * 100) : (findAnswerItem.length / props.quiz.user_answers.length * 100) === 0 ? 0 : 100 - (findAnswerItem.length / props.quiz.user_answers.length * 100));
                    return {
                        answerUser: answerData,
                        answerCount: findAnswerItem.length,
                        answer: answer.answer,
                        id: answer.id,
                        isCorrect: answer.correct,
                        width: calculateWidth,
                        color: getColor(calculateWidth),
                    }
                })
            }
        })

        //console.log(data);
        return data;
    }, [props.quiz]);


    const getPDF = useCallback(async () => {

        setIsLoading(true);
        const request = await quizRepository.getPDF({name: props.quiz.name, content: selectUserDataForQuestion()}, props.quiz.name);
        setIsLoading(false);
        console.log(request);
    }, [props.quiz.name, selectUserDataForQuestion]);

    useEffect(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('quiz', language),
                onClick: () => props.onClick()
            },
            {
                title: props.quiz.name,
                onClick: undefined
            }
        ]);

        breadCrumb.setTitle(props.quiz.name);
        breadCrumb.setBackButton(props.onClick);
        breadCrumb.setChildren([{
            title: '',
            color: "transparent",
            isLoading: isLoading,
            isFullLoading: true,
            onClick: undefined
        }]);

    }, [props.quiz, isLoading]);

    return (
        <div onClick={() => {
            if(activeQuestion !== null){
                setActiveQuestion(null);

            }
            if(activeAnswer !== null){
                setActiveAnswer(null);
            }
        }}>

        <ViewBox title={props.quiz.name} rightComponent={(
            <div className="flex-row-button">
                <FormButton icon={"download"} color={"dark"} text={I18N('exportQuiz', language)} onClick={getPDF} withLoadingInFullScreen={true} isLoading={isLoading} />
            </div>
        )}>
            <div style={{display: "flex", flexDirection: "row", position: "relative"}}>
                <div style={{position: "relative", width: windowSize[0] < WIDTH_LIMIT ? '100%' : '50%'}}>
                    {selectUserDataForQuestion().map((quizQuestion, qI) => {
                        return (
                            <ul style={{listStyle: "none", position: "relative",}}>
                                <p className="quizIDLister"
                                   onMouseEnter={() => {
                                    setActiveQuestion(quizQuestion);
                                    setActiveAnswer(null);
                                }} onMouseLeave={() => {
                                    if(!isClicked){
                                        setActiveQuestion(null);
                                    }
                                }} style={{position: "relative", margin: 0, padding: 0, fontWeight: "bold", fontSize: 15}}>
                                    {I18N('question', language)} {qI + 1}: {quizQuestion.question}
                                </p>
                                {quizQuestion.question_answers.map((answer, i) => {

                                    return (
                                        <li style={{fontSize: 14}}>
                                            <div style={{display: "flex", alignItems: "center"}}
                                                 onMouseEnter={() => {
                                                     console.log(answer);
                                                setActiveAnswer(answer);
                                                setActiveQuestion(null);
                                            }} onMouseLeave={() => {
                                                if(!isClicked){
                                                    setActiveAnswer(null);
                                                }
                                            }}>
                                                <p style={{float: "left", width: 200}}>{`${I18N('answer', language)} ${i + 1}: ${answer.answer}`}</p>
                                                <p style={{float: "left", width: 250, marginLeft: 20, backgroundColor: "#f2f2f2", borderRadius: 5}}>
                                                    <div className={`rankingBar ${answer.color}`} style={{width: answer.width + '%'}} />
                                                </p>
                                                <div style={{clear: "both"}} />
                                            </div>

                                        </li>
                                    )
                                })}
                            </ul>
                        )
                    } )}
                </div>

                <div style={{marginLeft: 20}}>
                    {windowSize[0] > WIDTH_LIMIT && activeQuestion !== null && (
                        <div style={{position: "fixed", width: '30%'}}>
                        <ViewBox title={`${I18N('question', language)}: ${activeQuestion.question}`}>
                            <div style={{maxHeight: 400, overflowY: "scroll", }}>
                                <table style={{ width: '100%', borderCollapse: "collapse", borderRadius: 10}}>
                                    <thead style={{position: "sticky"}}>
                                    <tr className="tableViewUser">
                                        <th style={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}}>{I18N('user', language)}</th>
                                        <th style={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}}>{I18N('answer', language)}</th>
                                    </tr>

                                    </thead>

                                    <tbody className="tableInnerViewUser" >
                                    {activeQuestion.answersStatus.map((item) => (
                                        <tr>
                                            <td>{item.user}</td>
                                            <td>{item.answers.length < 1 ? (<p>{I18N('noAnswer', language)}</p>) : (item.answerStatus ? (
                                                <p style={{color: "green"}}>{I18N('correct', language)}</p>) : (
                                                <p style={{color: "red"}}>{I18N('wrong', language)}</p>))}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

                        </ViewBox>
                    </div>
                    )}

                </div>

                <div style={{marginLeft: 20}}>
                    {windowSize[0] > WIDTH_LIMIT && activeAnswer !== null && (
                        <div style={{position: "fixed", width: '30%'}}>
                            <ViewBox title={`${I18N('answer', language)}: ${activeAnswer.answer}`}>
                                <div style={{maxHeight: 400, overflowY: "scroll", }}>
                                    <table style={{ width: '100%', borderCollapse: "collapse", borderRadius: 10}}>
                                        <thead style={{position: "sticky"}}>
                                        <tr className="tableViewUser">
                                            <th style={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}}>{I18N('user', language)}</th>
                                            <th style={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}}>{I18N('answer', language)}</th>
                                        </tr>

                                        </thead>

                                        <tbody className="tableInnerViewUser" >
                                        {activeAnswer.answerUser.map((item) => (
                                            <tr>
                                                <td>{item.user}</td>
                                                <td>{(item.answers.findIndex((i) => i === activeAnswer.id) !== -1 ? (
                                                    <p style={{color: activeAnswer.isCorrect ? "green" : 'red'}}>{I18N('checked', language)}</p>) : (
                                                    <p style={{color: !activeAnswer.isCorrect ? "green" : 'red'}}>{I18N('notChecked', language)}</p>))}</td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                            </ViewBox>
                        </div>
                    )}

                </div>

            </div>

        </ViewBox>
        </div>
    )

}
