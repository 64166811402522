import React, {Component, useCallback, useEffect, useRef, useState} from 'react';
import { standartColors } from '../../assets/colors/Colors';
import { Searchbar } from '../../Searchbar/Searchbar';
import { TabView } from '../../UiLibrary/tabView/TabView';
import { ProfileTab } from './tabs/ProfileTab';
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import MyTrophies from "./tabs/MyTrophies";
import {useTheme} from "@mui/material";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import BreadCrumb from "../../ViewComponents/BreadCrumb";
import profileIcon from '../../assets/profileTopIcon.svg';
import {UsersRepository} from "../../Repositories/UsersRepository";
import {useLoginState} from "../../States/UserState";
import {BACKEND_URL} from "../../assets/AppConstants";
import TabIndicator from "../../ViewComponents/Tabs/TabIndicator";
import {MyVideos} from "./tabs/MyVideos";
import {ProfileRepository} from "../../Repositories/ProfileRepository";
import editProfilePicture from '../../assets/editPhotoProfile.svg';
type Props = {
    style: any
    isMobile: () => boolean
}
const userRepository = new UsersRepository();
const profileRepository = new ProfileRepository();

export function Profile(props: Props) {

    const theme = useTheme();
    const [activeTab, setActiveTab] = useState('profile')
    const breadCrumb = useBreadCrumb();
    const language = useLanguageState(state => state.language);
    const [userData, setUserData] = useState(null);
    const loginState = useLoginState();
    const [imageUploading, setImageUploading] = useState(false);
    const imageRef = useRef(null);
    const [showOverlay, setShowOverlay] = useState(false);
    const getUserDefaults = useCallback((key: string): string => {

        if (userData === null) {
            return key;
        } else {
            return userData[key]
        }

    }, [userData]);

    const [userState, setUserState] = useState({
        username: getUserDefaults('preferred_username'),
        newPassword: '',
        newPasswordRepeat: '',
        firstName: getUserDefaults('given_name'),
        lastName: getUserDefaults('family_name'),
        phone: loginState.phone,
        email: getUserDefaults('email'),
        showPassword: false,
        showPasswordOld: false,
        image: loginState.photo
    });

    useEffect(() => {

        window.scrollTo({
            behavior: "smooth",
            top: 0
        });

    }, []);


    useEffect(() => {

        breadCrumb.setTitle(I18N('account', language));
        breadCrumb.setCrumb([
            {
                title: I18N('dashboard', language),
                onClick: () => undefined
            },
            {
                title: I18N('account', language),
                onClick: undefined
            }
        ]);
        breadCrumb.setChildren([
            {
                title: '',
                color: 'transparent',
                onClick: undefined
            }
        ])

    }, []);

    useEffect(() => {

        userRepository.getUserInfo().then((response) => {
            setUserData(response);
        });


    }, []);



    useEffect(() => {

        setUserState({
            username: getUserDefaults('preferred_username').split('_')[0],
            newPassword: '',
            newPasswordRepeat: '',
            firstName: getUserDefaults('given_name'),
            lastName: getUserDefaults('family_name'),
            phone: loginState.phone,
            email: getUserDefaults('email'),
            showPassword: false,
            showPasswordOld: false,
            image: loginState.photo
        })

    }, [getUserDefaults, loginState.phone, loginState.photo]);


    const uploadPicture = useCallback(async (file: File) => {

        setImageUploading(true);

        const updatePicture = await profileRepository.updatePicture(file);
        loginState.setPhoto(updatePicture.image);
        setUserState({ ...userState, image: updatePicture.image });
        await profileRepository.updateImage({image: updatePicture.image});
        setImageUploading(false);
        console.log(updatePicture);
    }, [userState]);

    const openImageBox = useCallback(() => {

        if(imageRef !== null){
            imageRef.current.click();
        }


    }, [imageRef]);

    return (
        <div style={props.style}>
            <Searchbar />
            <BreadCrumb />
            <div style={styles.container}>

                <div style={{position: "relative"}}>

                    <div onMouseEnter={() => setShowOverlay(true)} onMouseLeave={() => setShowOverlay(false)} style={{position: "absolute", bottom: 18, zIndex: 99, display: "flex", left: 50}}>

                        <input ref={imageRef} type={"file"} style={{display: "none"}} accept={"image/*"} onChange={(event) => uploadPicture(event.target.files[0])} key={"imageuploadbox"} />
                        <img src={loginState.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' :( BACKEND_URL + '/users' + loginState.photo)} width={100} height={100} style={{width: 100, height: 100, borderRadius: '50%', cursor: "pointer"}} />

                        {showOverlay && (
                            <div onClick={openImageBox} style={{cursor: "pointer", display: "flex", justifyContent: "space-evenly", alignItems: "center", flexDirection: "column", position: "absolute", width: 100, height: 100, borderRadius: '50%', background: 'rgba(84, 102, 122, 0.75)'}}>
                                <img src={editProfilePicture} />
                                <p style={{margin: 0, padding:0, color: "#FFE16A", fontSize: 14}}>{I18N('editPicture', language)}</p>
                            </div>
                        )}

                        <div style={{marginLeft: 20}}>
                            <p style={{fontSize: 24, fontWeight: "bold", color: "#000", margin:0, padding:0}}>{userState.firstName} {userState.lastName}</p>
                            <p style={{margin:0, padding: 0, fontSize: 16, fontWeight: "normal", color: "#000"}}>{userState.username}</p>
                        </div>

                    </div>

                    <div style={{background: "rgba(255, 225, 106, 0.5)", backdropFilter: 'blur(5px)', position: "relative", borderRadius: '10px 10px 0 0', height: 260}}>
                        <img style={{width: 400, height: 306, position: "absolute", bottom: 10, right: 0}} src={profileIcon} />


                    </div>
                    <div style={{background: "#FFFFFF", height: 55, border: '1px solid rgba(224, 224, 224, 0.5)', borderRadius: '0 0 10px 10px'}}>
                        <div style={{height: 55, paddingRight: 40, width: '100%', display: 'flex', justifyContent: "flex-end", alignItems: 'center', position: 'relative', top: 0,}}>
                            <TabIndicator name={I18N('profile', language)} isActive={activeTab === 'profile'} onClick={() => setActiveTab('profile')} />
                            <TabIndicator name={I18N('myvideos', language)} isActive={activeTab === 'myvideos'} onClick={() => setActiveTab('myvideos')} />
                            <TabIndicator name={I18N('trophies', language)} isActive={activeTab === 'trophies'} onClick={() => setActiveTab('trophies')} />
                        </div>
                    </div>
                </div>



                <div style={{marginTop: 15}}>
                    {activeTab === 'profile' && (
                        <ProfileTab />
                    )}
                    {activeTab === "trophies" && (
                        <MyTrophies />
                    )}
                    {activeTab === 'myvideos' && (
                        <MyVideos />
                    )}
                </div>


            </div>
        </div >
    )
}

const styles = {
    container: { height: 'calc(100% - 100px)', width: '93%', display: 'flex', marginLeft: '25px', flexDirection: 'column' } as React.CSSProperties,
    header: {
        container: { fontWeight: 600, height: 120, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: standartColors.black } as React.CSSProperties,
    },

}
