import { I18N } from "../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";
import FormButton from "../ViewComponents/FormButton";
import DepAnalyticsVideo from "./DepAnalyticsVideo";
import UserAnalyticsVideoDep from "./UserAnalyticsVideoDep";
import moment from "moment/moment";
import TableMui from "../ViewComponents/TableMui";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import LanguageExporter from "../Helpers/LanguageExporter";
import VideoTypeExporter from "../Helpers/VideoTypeExporter";

const videoRepository = new VideoRepository();

export default function VideoAnalytics(props: {
    backHome: () => void;
}) {

    const language = useLanguageState(state => state.language);
    const [watchList, setWatchList] = useState([]);
    const [totalUser, setTotalUser] = useState(0);
    const [activePage, setActivePage] = useState<'content' | 'dep' | 'user'>('content');
    const [activeContent, setActiveContent] = useState(null);
    const [activeDepartment, setActiveDepartment] = useState(null);
    const [userData, setUserData] = useState([]);
    const [activeContentName, setActiveContentName] = useState(null);
    const [loading, setLoading] = useState(false);
    const[contentData, setContentData] = useState(null);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();


    const loadBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('contents', language),
                onClick: () => history.push('/contentAnalytics/contents')
            }
        ]);

        breadCrumb.setTitle(I18N('contents', language));
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, [loading]);


    useEffect(() => {

       loadBreadCrumb();

    }, [loadBreadCrumb]);


    const loadData = useCallback(async () => {
        setLoading(true);
        const watchListData = await videoRepository.getWatchList();

        setWatchList(watchListData.contentData);
        setTotalUser(watchListData.userTotal);
        setUserData(watchListData.newUserData);
        setLoading(false);
    }, []);

    useEffect(() => {
        loadData().then(undefined);
    }, []);

    const calculateRankingList = useCallback(() => {

        if(watchList.length < 1){
            return [];
        }

        const sortedData = watchList.sort((a, b) => {
            if (a.seen_videos.length > b.seen_videos.length) {
                return -1;
            }
            if (a.seen_videos.length < b.seen_videos.length) {
                return 1;
            }
            return 0;
        });

        const hundred = sortedData[0].seen_videos.length === 0 ? 100 : sortedData[0].seen_videos.length;
        const calculatedData: any[] = [];

        sortedData.forEach((content, index) => {
            let requiredFor = 0;
            let requiredUsers: any[] = [];

            if(content.requiredForAll === 1){
                console.log(userData);
                const filteredUserFound = userData.filter((i) => {
                    if(i.groupNames.includes("Superadmin")){
                        return false;
                    }else{
                        return  true;
                    }
                });
                requiredFor = filteredUserFound.length;
                requiredUsers = [];
            }else{
                content.video_departments.forEach((dep) => {


                    const filteredDepUsers = dep.department.users.filter((b) => {
                        const filteredUserFound = userData.findIndex((i) => {
                            if(i.groupNames.includes("Superadmin")){
                                return false;
                            }else if(i.id === b.id){
                                return  true;
                            }else{
                                return false;
                            }
                        });
                        if(filteredUserFound === -1){
                            return false;
                        }else{
                            return true;
                        }
                    });
                    console.log(filteredDepUsers);
                    requiredFor += filteredDepUsers.length
                    requiredUsers = [...requiredUsers, ...filteredDepUsers];
                });
            }

            const selectOnlyRequiredWatches = content.seen_videos.filter((seenVideo) => {
                let status = false;
                const udata= userData.find((i) => i.id === seenVideo.user_id);
                if(udata){
                    if(udata.groupNames.includes("Superadmin")){
                        return false;
                    }
                }

                if(content.requiredForAll){
                    status = true
                }else{
                    content.video_departments.forEach((dep) => {
                        status = true;
                    });
                }
                return status;
            });

            const oldWatches = selectOnlyRequiredWatches.filter((seenVideo) => {
                if(moment(seenVideo.seen_at).isAfter(moment().subtract(1, "year"))){
                    return true;
                }
            })

            if (index === 0) {
                calculatedData.push({ ...content, percentage: 100, requiredUsers: requiredUsers, requiredFor: requiredFor, totalWatch: selectOnlyRequiredWatches, oldWatches: oldWatches });
            } else {
                const percentage = selectOnlyRequiredWatches.length / hundred * 100;
                calculatedData.push({ ...content, percentage: percentage, requiredUsers: requiredUsers, requiredFor: requiredFor, totalWatch: selectOnlyRequiredWatches, oldWatches: oldWatches });
            }
        });

        return calculatedData;

    }, [watchList, totalUser, userData]);


    useEffect(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, [activePage]);

    const selectDep = useCallback((depID: number) => {
        setActiveDepartment(depID);
        setActivePage('user');
    }, []);

    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        }else {
            return 'red'
        }


    }, []);


    const contentColumns = useCallback(() => {

        return [
            {
                field: 'title',

                name: I18N("name", language),
                sortable: true,
                filterable: true,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>
                        {LanguageExporter(params.row, language, 'video_titles', 'title', 'title')} ({VideoTypeExporter(params.row.type)})</span>;
                }
            },
            {
                field: 'totalWatch',
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                type: "string",
                name: I18N("totalSeen", language),
                valueGetter: (params) => {
                 return params.row.totalWatch.length
                },
                renderCell: (params: any) => {
                    return (
                        <div className="endFlex">
                            <div className="rankingText">{params.row.totalWatch.length} / {params.row.requiredFor} ({isNaN(params.row.totalWatch.length / params.row.requiredFor * 100) ? '0' : (params.row.totalWatch.length / params.row.requiredFor * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.totalWatch.length / params.row.requiredFor * 100))}`} style={{width: (params.row.totalWatch.length / params.row.requiredFor * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
            {
                field: 'oldWatches',
                name: I18N('totalSeenLast12', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                valueGetter: (params) => {
                    return params.row.oldWatches.length
                },
                renderCell: (params: any) => {
                    return (
                        <div className={"endFlex"}>
                            <div className="rankingText">{params.row.oldWatches.length} / {params.row.totalWatch.length} ({isNaN(params.row.oldWatches.length / params.row.totalWatch.length * 100) ? '0' : (params.row.oldWatches.length / params.row.totalWatch.length * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.oldWatches.length / params.row.totalWatch.length * 100))}`} style={{width: (params.row.oldWatches.length / params.row.totalWatch.length * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
        ];

    }, [language]);

    const views = useCallback(() => {

        switch (activePage) {
            case "content": {
                return (
                    <React.Fragment>
                        <div style={{height: 670}}>
                        <TableMui sortingMode={"client"} filterMode={"client"} onClick={(data) => {
                            setActiveContent(data.row.id);
                            setActiveContentName(data.row.name);
                            setActivePage('dep');
                            setContentData(data.row);
                        }} data={calculateRankingList()} columns={contentColumns()} />
                        </div>
                    </React.Fragment>
                )
            }
            case "dep" : {
                return (
                    <DepAnalyticsVideo onBack={() => {
                        setActivePage('content');
                        setActiveContent(null);
                        setActiveDepartment(null);
                        setActiveContentName(null);
                        loadBreadCrumb();
                        setContentData(null)
                    }} onClick={selectDep} contentData={contentData} contentID={activeContent} />
                )
            }
            case "user": {
                return (
                    <UserAnalyticsVideoDep onBack={() => {
                        setActivePage('dep');
                        setActiveDepartment(null);
                    }} contentID={activeContent} depID={activeDepartment} />
                )
            }

        }

    }, [activePage, language, calculateRankingList, activeContent]);




    return (
        <div style={{marginTop: activePage !== 'content' ? 0 : 30}}>
            <div className="flex-row-button">
                {/*<FormButton text={I18N("back", language)} onClick={() => {*/}
                {/*        if(activePage === 'user'){*/}
                {/*            setActivePage('dep');*/}
                {/*            setActiveDepartment(null);*/}
                {/*        }else if(activePage === 'content') {*/}
                {/*            props.backHome();*/}
                {/*        }else{*/}
                {/*            setActivePage('content');*/}
                {/*            setActiveContent(null);*/}
                {/*            setActiveDepartment(null);*/}
                {/*            setActiveContentName(null);*/}
                {/*        }*/}
                {/*    }} isLoading={loading} withLoadingInFullScreen={true} />*/}
            </div>
            {views()}
        </div>
    )
}
