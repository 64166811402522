import RankingBGSVG from "../../assets/rankingbg.svg";
import {I18N} from "../../i18n/i18n";
import ViewBox from "../ViewBox";
import React, {useCallback} from "react";
import {useLanguageState} from "../../States/LanguageState";
import {useLoginState} from "../../States/UserState";
import {useTheme} from "@mui/material";

export default function UserRankingList(props: {
    contentData: {user_id: string, points: number, rank?: number}[]
}){

    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const userData = useLoginState(state => state.data);

//userData.given_name + ' ' + userData?.family_name
    const topTreePrinter = useCallback((index, topRank, len: number) => {

        return (
            <div className={`rankingCircle gray ${index === 2 ? 'yellow' : 'gray'} ${userData?.sub === topRank.user_id && 'top10Me'}`}>
                <div className="rank">
                    {index === 0 && (len === 2 ? '2' : (len === 1 ? '1' : '3'))}
                    {index === 1 && (len === 2 ? '1' : '2')}
                    {index === 2 && (len === 2 ? '1' : '1')}
                </div>
                <div className="userID">
                    {userData?.sub === topRank.user_id ?  'ME' : `User ${topRank.user_id.slice(0,5)}`}
                </div>
                <div className="points">
                    {I18N('points', language)}: {topRank.points}
                </div>
            </div>
        )

    }, [language, userData]);

    return (
        <ViewBox width={40} customClass="viewBoxRanking">
            <div className="rankingHeader" style={{backgroundImage: `url(${RankingBGSVG})`, backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : '#E0E0E0'}}>
                <div className="rankingTitle" style={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{I18N('userRanking', language)}</div>
                <div className="circleHolder">
                    {(props.contentData.slice(0,3).reverse().map((topRank, index) => topTreePrinter(index, topRank, props.contentData.slice(0, 3).length)))}
                </div>
            </div>

            <ul className="rankingList">
                {props.contentData.length > 3 && props.contentData.slice(3, 10).map((rank, index) => (
                    <li className="rankingListItem">
                        <div className="rank" style={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{index + 4}</div>
                        <div className="name" style={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{userData?.sub === rank.user_id ? userData.given_name + ' ' + userData?.family_name : 'User' + rank.user_id}</div>
                        <div className="point">{I18N('points', language)}: {rank.points}</div>
                    </li>
                ))}
                {props.contentData.findIndex((content) => content.user_id === userData?.sub) > 9 && (
                    <li className="rankingListItem myPoint">
                        <div className="rank" style={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{props.contentData.find((content) => content.user_id === userData?.sub).rank}</div>
                        <div className="name" style={{color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{userData.given_name} {userData.family_name}</div>
                        <div className="point">{I18N('points', language)}: {props.contentData.find((content) => content.user_id === userData.sub).points}</div>
                    </li>
                )}

            </ul>
        </ViewBox>
    )
}
