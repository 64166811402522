import {TextField, useTheme} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import UploadFile from "../../../ViewComponents/UploadFile";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {CompanyRepository} from "../../../Repositories/CompanyRepository";

const companyRepository = new CompanyRepository();

const saveKey = "companyFachData";

function CompanyStep(props: {title: string}, ref){
    const language = useLanguageState(state => state.language);
    const theme = useTheme();

    const [formData, setFormData] = useState({
        name: '',
        employees: '',
        logo: ''
    });
    const clearData = useCallback(() => {

        localStorage.removeItem(saveKey);
        setFormData({
            name: '',
            employees: '',
            logo: ''
        });
    }, []);

    const checkLocalStorage = useCallback(() => {

        const companyFachData = localStorage.getItem(saveKey);
        if(companyFachData){
            setFormData(JSON.parse(companyFachData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formData));

    }, [formData]);

    return (
        <div style={{padding: 10}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>
            <TextField
                fullWidth
                label={I18N('name', language)}
                style={{ marginTop: 20,color: "#fff" }}
                value={formData.name}
                onChange={(text) => setFormData({...formData, name: text.target.value})}
            />
            <TextField
                fullWidth
                label={I18N('employees', language)}
                style={{ marginTop: 20 }}
                value={formData.employees}
                onChange={(text) => setFormData({...formData, employees: text.target.value})}
            />
            <UploadFile background={theme.palette.mode === 'dark' ? '#001E3C' : "rgb(224, 224, 224)"} inputAttrs={{accept: 'image/*'}} url={formData.logo} uploadRepository={companyRepository} onUpload={(image) => {
                setFormData({...formData, logo: image});
                console.log(image);

            }} uploadKey="logo" />

            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )

}

const Company = React.forwardRef(CompanyStep)

export default Company;