import {ListItemIcon, MenuItem, MenuList, styled, Typography} from '@mui/material';
import {useHistory, useLocation} from 'react-router-dom';

export interface ISidebarMenuItem {
    title: string;
    icon: any;
    to: string;
}

interface ISidebarMenu {
    items: ISidebarMenuItem[];
    content?: string;
}

const SidebarMenu = (props: ISidebarMenu) => {
    const { items, content } = props;
    const history = useHistory();
    const route = useLocation();
    const page = content
        ? route.pathname.split('/')[3]
        : route.pathname.split('/')[1];

    const SidebarMenuContainer = styled(MenuList)(() => ({
        minWidth: 250,
        width: '95%',
        background: '#787C7E',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        color: '#fff',
        '& svg': {
            marginRight: '20px'
        },
        '& li': {
            padding: '6px 8px',
            margin: '0 8px'
        },
        '& li:not(:first-child)': {
            borderTop: '1px solid #FFFFFF33'
        },
        '& p': {
            fontSize: '14px'
        },
        '& .active': {
            backgroundColor: '#FFCC00 !important',
            borderRadius: '10px'
        }
    }));


    return (
        <SidebarMenuContainer>
            {items.map((item: ISidebarMenuItem, index: number) => (
                <MenuItem
                    key={index}
                    className={window.location.href.includes(`/Konfiguration/${item.to}`) ? 'active' : ''}
                    onClick={() => history.push('/Konfiguration/'+item.to)}>
                    <ListItemIcon>
                        <img src={item.icon} />
                    </ListItemIcon>
                    <Typography variant="inherit">{item.title}</Typography>
                </MenuItem>
            ))}
        </SidebarMenuContainer>
    );
};

export default SidebarMenu;
