import React, {useCallback, useEffect, useState} from 'react';
import { ClientOperation } from "./ClientOperation";
import ClientList from "./ClientList";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import { CompanyRepository } from "../Repositories/CompanyRepository";
import {CSVLink} from 'react-csv';
import {useSnackbar} from "notistack";
const companyRepository = new CompanyRepository();

type Props = {
    style: any;
    isMobile: () => boolean
}



export function ClientTab(props: Props) {
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editClient, setEditClient] = useState<null | any>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);
    const [contactPersons, onContactPersonsChange] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [tokenUrl, setTokenURl] = useState({});
    const [license, setLicense] = useState(undefined);
    const {enqueueSnackbar} = useSnackbar();
    const [formState, setFormState] = useState({
        name: '',
        contents: [],
        username: '',
        password: '',
        environment: 'test'
    })
    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);


    const onLicenseChange = useCallback((licenseData) => {
        setLicense(licenseData)
    }, [])

    const getCompanies = useCallback(async () => {
        const companyList = await companyRepository.getAllAdmin();
        setCompanies(companyList);
    }, []);


    useEffect(() => {
        getCompanies().then(undefined);
    }, [getCompanies]);


    const backToClientsTable = () => {
        setFormState({
            name: '',
            contents: [],
            username: '',
            password: '',
            environment: 'test'
        });
        onContactPersonsChange([]);
        setTokenURl({});
        setCreateOpen(false);
        setEditClient(null);
        setLicense(null);
        scrollTop();
    }


    const saveClient = useCallback(async () => {
        if (formState.name === '') {
            enqueueSnackbar(I18N("pleaseFill", language, [{ key: 'name', value: I18N('client', language) }]), {variant: "error"});
            return;
        }

        setFormLoading(true);
        if (editClient === null) {

            if (formState.username === '' || formState.password === '') {
                setFormLoading(false);
                enqueueSnackbar(I18N("pleaseFill", language, [{ key: 'name', value: I18N('client', language) }]), {variant: "error"});

                return;
            }

            const create = await companyRepository.createClient({ license: license, environment: formState.environment, contactPersons: contactPersons, client: formState.name, contents: formState.contents, username: formState.username, password: formState.password }, tokenUrl);

            if (create instanceof Error) {
                setFormLoading(false);
                enqueueSnackbar(I18N("unexpectedError", language, ), {variant: "error"});
            } else {
                setFormLoading(false);
                enqueueSnackbar(I18N("successfullyCreatedReturning", language, [{key: 'name', value: I18N('client', language)}]), {variant: "success"});
                backToClientsTable()
            }

        } else {
            const update = await companyRepository.updateClient({license: license, environment: formState.environment, contactPersons: contactPersons, client: formState.name, contents: formState.contents }, Number(editClient.ID));
            if (update instanceof Error) {
                setFormLoading(false);
                enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            } else {
                setFormLoading(false);
                enqueueSnackbar(I18N("successfullyUpdatedReturning", language, [{key: 'name', value: I18N('client', language)}]), {variant: "success"});
                backToClientsTable()
            }
        }

        setFormLoading(false);
    }, [formState, editClient, contactPersons, tokenUrl, license]);

    const buildCSVOutput = useCallback(() => {

        const output = [];

        companies.forEach((company) => {

            const contactList = company.contactPersons;
            console.log(company);

            output.push({
                clientName: company.name,
                environment: company.environment,
                license: (company.licences !== undefined && company.licences !== null) ? company.licences.name : '',
                last12Month: company.users.loggedInUsers,
                totalUsers: company.users.totalUsers,
                name: '',
                surname: '',
                gender: '',
                email: '',
            });

            if(contactList.length > 0){
                contactList.forEach((contact) => {
                    output.push({
                        clientName: '',
                        environment: '',
                        license: '',
                        last12Month: '',
                        totalUsers: '',
                        name: contact.name,
                        surname: contact.surname,
                        gender: I18N(contact.gender, language),
                        email: contact.email
                    })
                })
            }


        })

        return output;


    }, [companies, language]);

    return (
        <div style={props.style}>
            <div className="flex-row-button">
                {createOpen ? (<FormButton text={editClient !== null ? I18N('updateClient', language) : I18N('createClient', language)} onClick={saveClient} isLoading={formLoading} />) : (
                    <CSVLink filename={'Compliness Client List.csv'} data={buildCSVOutput()}><FormButton text={I18N('export', language)} onClick={() => {}} isLoading={formLoading} withLoadingInFullScreen={true} /></CSVLink>
                )}
                <FormButton text={(createOpen ? I18N("back", language) : I18N('new', language))} onClick={() => {
                    if (createOpen) {
                        backToClientsTable();
                    }
                    setCreateOpen(!createOpen);
                    scrollTop();
                }} isLoading={formLoading} withLoadingInFullScreen={true} />
            </div>

            {!createOpen ?
                <ClientList setLoadingMode={setFormLoading} editMode={(item) => {
                    setEditClient(item);
                    setCreateOpen(true);
                    scrollTop();
                }} />
                : (
                    <ClientOperation onLicenseChange={onLicenseChange} setTokenAndURL={setTokenURl} onContactPersonsChange={onContactPersonsChange} onFormChange={setFormState} formState={formState} backCallback={backToClientsTable} editMode={editClient} />
                )}
        </div>
    )

}
