import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {ApiKeyRepository} from "../Repositories/ApiKeyRepository";
import moment from "moment/moment";
import {UsersRepository} from "../Repositories/UsersRepository";
import TableMui from "../ViewComponents/TableMui";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import {PaginationTypes} from "../Types/PaginationTypes";
import {ApiKeyType} from "../Types/ApiKeyTypes";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import {useSnackbar} from "notistack";


const usersRepository = new UsersRepository();

const apiKeyRepository = new ApiKeyRepository();

export default function ApiKeyList(props: {reload: number, setEditMode: (item: any) => void}) {

    const {enqueueSnackbar} = useSnackbar();
    const [keys, setKeys] = useState<PaginationTypes<ApiKeyType>>(undefined);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [users, setUsers] = useState<any>([]);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const getApiKeys = useCallback(async () => {
        if(!waitingForPage){
            setLoading(true);
            const keyList = await apiKeyRepository.getAll({page, limit, filter, orderType});
            setKeys(keyList);
            setLoading(false);
        }
    }, [page, limit, filter, orderType, waitingForPage]);

    const deleteToken = useCallback(async (token: any) => {

        const del = await apiKeyRepository.delete(token.id);

        if(del instanceof Error){
            enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            return;
        }else{
            await getApiKeys().then(undefined);
            enqueueSnackbar(I18N("deleteSuccess", language), {variant: "success"});
        }

    }, [keys, language]);

    const updateStatus = useCallback(async (token: any) => {

       await apiKeyRepository.update({user_id: token.user_id, status: token.status === 1 ? 0 : 1}, token.id).then((re) => {
           enqueueSnackbar(I18N("successfullyUpdated", language, [{key: 'name', value: I18N('apikey', language)}]), {variant: "success"} );
           getApiKeys().then(undefined);
        }).catch((err) => {
           enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
        })

    }, []);

    const loadUsers = useCallback(async () => {

        const userList = await usersRepository.getAll();

        setUsers(userList);

    }, []);


    useEffect(() => {
        loadUsers().then(undefined);
    }, []);


    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);



    const tableColumnsMui = () => {
        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'token',
                width: 350,
                name: I18N("token", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'user_id',
                name: I18N("user", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    if(users.length > 0){
                        const uData = users.find((u) => u.id === value);
                        if(uData !== undefined){
                            return uData.firstName + ' ' + uData.lastName + ' ('+uData.email+')'
                        }
                    }else{
                        return '...'
                    }
                }
            },
            {
                field: 'last_used_at',
                width: 150,
                name: I18N("last_used_at", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return moment(value).format('DD/MM/YYYY HH:mm');
                }
            },
            {
                field: 'status',
                width: 100,
                name: I18N("status", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span onClick={() => updateStatus(params.row)} data-field="actions" style={{fontWeight: 'bold', cursor: "pointer"}}>{value === 1 ? "Activ" : "Deactivated"}</span>;
                }
            },
            {
                field: 'actions',
                name: I18N('actions', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params: any) => {
                    return <DeleteIcon row={params.row} callBack={deleteToken} ask={true} />;
                }
            },
        ];
    }

    useEffect(() => {
        getApiKeys().then(undefined);
    }, [getApiKeys, props.reload]);

    return (
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    tableName="apiKeysListTable"
                    setWaitingForPage={setWaitingForPage}
                    page={page}
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={keys?.data !== undefined ? keys.data : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT
                        if(event.target.dataset.field !== "actions"){
                            props.setEditMode(data.row);
                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={keys?.total}
                />
            </div>
    )

}
