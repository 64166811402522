export default function tableHeightCalculator(limit){

    switch (limit){
        case 10: {
            return 670;
        }
        case 15: {
            return 940;
        }
        case 20: {
            return 1210;
        }
        default: {
            return 670;
        }
    }

}