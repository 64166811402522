import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PaginationRequestType, PaginationTypes} from "../Types/PaginationTypes";
import {UserListsType} from "../Types/UserListsTypes";

export class PhishingRepository implements AbstractRepository<PaginationTypes<any> | any[]>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.delete('/phishing/' + id).then((response) => {
                return resolve(response.data)
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    getSenderAddresses(){
        return new Promise((resolve, reject) => {

            axios.get('/phishing/senderAddresses').then((response) => {
                resolve(response.data.senders.map((e) => e.email));
            }).catch((err) => {
                reject(err);
            })

        })
    }

    getCounts(){

        return new Promise((resolve, reject) => {

            axios.get('/phishing/counts').then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        })
    }

    getPDF(data: any, phishing_name: string, showUsers: boolean){

        return new Promise((resolve, reject) => {

            axios.post(`/phishing/phishingPDF`, {...data, showUsers: showUsers},{
                responseType: 'blob'
            }).then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${phishing_name}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                return resolve(response.data);

            }).catch((err) => {
                return reject(err);
            })

        });

    }


    pushCampaigns(id: number){

        return new Promise((resolve, reject) => {

            axios.get('/phishing/pushCampaign/'+id).then((response) => {

                return resolve(response.data)

            }).catch((err) => {
                return resolve(false);
            })
        });

    }
    getAllCampaigns(pagination?: PaginationRequestType): Promise<PaginationTypes<any[]>>{
        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/phishing/campaigns${pagination !== undefined ? `?active=${pagination.active}&page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }


    getAllExecuted(pagination?: PaginationRequestType): Promise<PaginationTypes<any[]>>{
        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/phishing/campaigns/executed${pagination !== undefined ? `?active=${pagination.active}&page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAllPlanned(pagination?: PaginationRequestType): Promise<PaginationTypes<any[]>>{
        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/phishing/campaigns/planned${pagination !== undefined ? `?active=${pagination.active}&page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }


    getAllLandingPages(pagination?: PaginationRequestType): Promise<PaginationTypes<any[]>>{
        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/phishing/landingPages${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAllEmails(pagination?: PaginationRequestType): Promise<PaginationTypes<any[]>> {

        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/phishing/emails${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }


    createEmail(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.post('/phishing/email', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    createLanding(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.post('/phishing/landing', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }



    updateLandingGeneral(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/landing/${id}/general`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateLandingData(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/landing/${id}/data`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateLanding(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/phishing/landing/' + id, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateEmail(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/phishing/email/' + id, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    updateEmailGeneral(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/email/${id}/general`,  object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    updateEmailData(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/email/${id}/data`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    updateCampaign(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/phishing/campaign/' + id, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateCampaignTemplates(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/campaign/${id}/template`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateCampaignGeneral(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/campaign/${id}/general`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    updateCampaignTargetGroup(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch(`/phishing/campaign/${id}/targetGroup`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    archiveCampaign(id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/phishing/archive/' + id, {}).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    createCampaign(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.post('/phishing/campaign', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }



    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
           resolve(true);
        })
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            resolve(true);
        })
    }



}
