import React, {useEffect, useState} from "react";
import {Box, Modal, TextField} from "@mui/material";
import UploadFile from "../ViewComponents/UploadFile";
import {VideoRepository} from "../Repositories/VideoRepository";
import { DefaultEditor } from 'react-simple-wysiwyg';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import {BACKEND_URL} from "../assets/AppConstants";
const videoRepository = new VideoRepository();

export default function EditContent(props: {isOpen: boolean; onClose: () => void, editItem: any; onEditContent: (item: any) => void; itemKey: number | undefined}){

    const language = useLanguageState(state => state.language)
    const [data, setData] = useState({
        image: '',
        html: '',
        title: '',
    });


    useEffect(() => {

        if(props.editItem !== null){
            setData({
                title: props.editItem.title,
                html: props.editItem.text,
                image: props.editItem.image
            })
        }


    }, [props.editItem])

    return (


        <Modal
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{...style, width: 800}}>

                <TextField fullWidth={true}
                           label={I18N('title', language)}
                           className="marginTop20"
                           value={data.title}
                           onChange={(text) => setData({...data, title: text.target.value})}
                />

                <div style={{marginBottom: 15}}>
                    <UploadFile uploadOriginal={true} inputAttrs={{accept: 'image/*'}} url={data.image} uploadRepository={videoRepository} onUpload={(image) => setData({...data, image: image})} uploadKey="cover" />

                </div>

                <DefaultEditor value={data.html} onChange={(htmldata) => setData({...data, html: htmldata.target.value})} />


                <FormButton style={{marginTop: 20,}} text={"Save"} icon={"save"} onClick={() => {
                    props.onEditContent({itemKey: props.itemKey, ...data, image: (data.image !== '' && data.image !== null) ? `${data.image?.includes(BACKEND_URL) ? data.image : BACKEND_URL + data.image}` : ''})
                    props.onClose();
                }} isLoading={false} />
            </Box>
        </Modal>
    )

}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid gray',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};