import {useLanguageState} from "../../States/LanguageState";
import {PermissionItem, PermissionName} from "../Components/TableComponents";
import {I18N} from "../../i18n/i18n";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import GroupPermissionSearcher from "../Components/GroupPermissionSearcher";
import React from "react";

const avPermissions = ["seeOwn", 'see', 'create', 'edit', 'delete'];

export default function Phishing(props: {groupList: any, setSelectedPermission: any}){
    const language = useLanguageState(state => state.language)

    return (
        <>
            <div style={{width: 218}}>
                <PermissionName onClick={() => props.setSelectedPermission({name: 'PhishingTemplates', permits: {seeOwn: '-', see: "ViewPhishingTemplates", create: "CreatePhishingTemplates", delete: "DeletePhishingTemplates", edit: "EditPhishingTemplates"}})} title={I18N("PhishingTemplates", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'PhishingCampaigns', permits: {seeOwn: '-', see: "ViewPhishingCampaigns", create: "CreatePhishingCampaigns", delete: "DeletePhishingCampaigns", edit: "EditPhishingCampaigns"}})} title={I18N("PhishingCampaigns", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'LandingPages', permits: {seeOwn: '-', see: "ViewPhishingLandingPages", create: "CreatePhishingLandingPages", delete: "DeletePhishingLandingPages", edit: "EditPhishingLandingPages"}})} title={I18N("LandingPages", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: 'ExecutedCampaignsDetails', permits: {seeOwn: '-', see: "ExecutedCampaignsDetails", create: "-", delete: "-", edit: "-"}})} title={I18N("ExecutedCampaignsDetails", language)} />
            </div>
            <Swiper style={{width: '100%'}} freeMode slidesPerView="auto" spaceBetween={0}>

                {avPermissions.map((i) => {
                    return (
                        <SwiperSlide style={{width: 170}}>
                            <PermissionItem key={"PhishingTemplates"}>
                                <GroupPermissionSearcher permissionList={{seeOwn: '-', see: "ViewPhishingTemplates", create: "CreatePhishingTemplates", delete: "DeletePhishingTemplates", edit: "EditPhishingTemplates"}} groups={props.groupList} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"PhishingCampaigns"}>
                                <GroupPermissionSearcher permissionList={{seeOwn: '-', see: "ViewPhishingCampaigns", create: "CreatePhishingCampaigns", delete: "DeletePhishingCampaigns", edit: "EditPhishingCampaigns"}} groups={props.groupList} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"LandingPages"}>
                                <GroupPermissionSearcher permissionList={{seeOwn: '-', see: "ViewPhishingLandingPages", create: "CreatePhishingLandingPages", delete: "DeletePhishingLandingPages", edit: "EditPhishingLandingPages"}} groups={props.groupList} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"ExecutedCampaigns"}>
                                <GroupPermissionSearcher permissionList={{seeOwn: '-', see: "ExecutedCampaignsDetails", create: "-", delete: "-", edit: "-"}} groups={props.groupList} permissionToLook={i} />
                            </PermissionItem>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    )

}