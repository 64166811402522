import React, {useCallback, useEffect, useState} from 'react';
import {Backdrop, Button, CircularProgress, IconButton, InputAdornment, TextField, useTheme} from '@mui/material';
import { standartColors } from '../../assets/colors/Colors';
import { Searchbar } from '../../Searchbar/Searchbar';
import { PWNEDRepository } from '../../Repositories/PWNED/pwned.repository';
import {PasswordTextsRepository} from "../../Repositories/PasswordTexts";
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {Icon} from "@iconify/react";
import eyeFill from "@iconify-icons/eva/eye-fill";
import eyeOffFill from "@iconify-icons/eva/eye-off-fill";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import BreadCrumb from "../../ViewComponents/BreadCrumb";
import {useHistory} from "react-router-dom";
import proofIcon from '../../assets/passwordProof.svg'
import bgIconPassword from '../../assets/bgIconPassword.svg'
const passwordtextRepository = new PasswordTextsRepository();


type Props = {
    style: any
    isMobile: () => boolean
}


const pwnedRepo = new PWNEDRepository()
export function Passwordpruefung(props: Props) {


    const [password, setPassword] = useState('');
    const [pwnedNumber, setPwnedNumber] = useState(-1);
    const [passwordStrong, setPasswordStrong] = useState("weak");
    const [passwordTexts, setPasswordTexts] = useState([]);
    const language = useLanguageState(state => state.language);
    const [showPassword, setShowPassword] = useState(false);
    const breadCrumb = useBreadCrumb();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const theme = useTheme();

    useEffect(() => {

        window.scrollTo({
            behavior: "smooth",
            top: 0
        });

    }, []);

    useEffect(() => {

        passwordtextRepository.getAll().then((response) => {

            setPasswordTexts(response)

        }).catch((error) => console.log(error));

    }, []);

    const checkPassword = useCallback(async () => {
        setIsLoading(true);
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')

        await pwnedRepo.checkForPassword(password).then((response) => {
            setPwnedNumber(response);
            if(strongPassword.test(password)){
                setPasswordStrong('strong');
            }else if(mediumPassword.test(password)){
                setPasswordStrong('medium');
            }else{
                if(password.length < 8){
                    setPasswordStrong('short');
                }else{
                    if(Number(response) > 5) {
                        setPasswordStrong('frequent');
                    }else{
                        setPasswordStrong('weak');
                    }

                }
            }
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
        })


    }, [password]);


    useEffect(() => {

        breadCrumb.setTitle(I18N("passwordCheck", language));
        breadCrumb.setCrumb([
            {
                title: I18N("home", language),
                onClick: () => history.push('/Dashboard'),
            },
            {
                title: I18N("passwordCheck", language),
                onClick: undefined,
            }
        ]);
        breadCrumb.setChildren(null);
    }, [language]);

        return (
            <div style={props.style}>
                <Searchbar />
                <BreadCrumb />
                <div style={styles.container}>
                    <Backdrop
                        open={isLoading}
                        style={{ color: standartColors.primaryYellow, zIndex:999 }}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <div style={{...styles.header.container, color: theme.palette.mode === "dark" && "#fff"}}>

                        {props.isMobile() &&
                            <div style={{ fontWeight: 'normal', fontSize: 13, marginTop: 15 }}>{I18N("pleaseEnterPassword", language)}</div>
                        }
                        <TextField
                            label={props.isMobile() ? '' : I18N('pleaseEnterPassword', language)}
                            style={{ width: '100%', marginTop: 25 }}
                            type={!showPassword ? 'password' : 'text'}
                            onChange={(text) => setPassword(text.target.value)}
                            onKeyDown={(key) => {
                                if(key.key === "Enter"){
                                    checkPassword();
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <React.Fragment>
                                        <InputAdornment position={'end'}>
                                            <IconButton style={{marginRight: 30}} onClick={() => setShowPassword(!showPassword)} edge="end">
                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                        <Button startIcon={(<img src={proofIcon} />)} style={styles.button} fullWidth variant="contained"
                                                onClick={checkPassword}
                                        >
                                            {I18N('check', language)}
                                        </Button>
                                    </React.Fragment>
                                )
                            }}
                        />
                    </div>

                    <div style={{flexDirection: "row", justifyContent: "space-between", display: "flex"}}>
                        <div style={styles.pswContent.container}>

                            <div style={{paddingBottom: 50}}>
                                <div style={styles.pswContent.header}>

                                    <div style={{ fontSize: 18, fontWeight: 'bold' }}>{I18N('results', language)}</div>
                                    {pwnedNumber !== -1 && <div>{I18N('passwordFoundTimes', language, [{value: pwnedNumber.toString(), key: 'times'}])}</div>}
                                </div>
                                {pwnedNumber !== -1 && (
                                <div style={styles.pswContent.header}>

                                    {passwordStrong === "short" && (
                                        <div style={styles.resultBox}>{I18N('shortPassword', language)}</div>
                                    )}

                                    {(passwordStrong === "frequent") && (
                                        <div style={styles.resultBox}>{I18N('veryOftenPassword', language)}</div>
                                    )}

                                    {passwordStrong === "medium" && (
                                        <div style={styles.resultBoxYellow}>{I18N("fewCharactersPassword", language)}</div>
                                    )}

                                    {passwordStrong === "strong" && (
                                        <div style={styles.resultBoxGreen}>{I18N('passwordOK', language)}</div>
                                    )}

                                    {passwordStrong === "weak" && (
                                        <div style={{...styles.resultBoxYellow}}>{I18N('fewCharactersPassword', language)}</div>
                                    )}


                                </div>
                                    )}
                            </div>
                        </div>
                        <div style={{...styles.textContent.container, backgroundImage: 'url('+bgIconPassword+')'}}>
                           <h4 style={styles.textContent.title}>{I18N('requirements', language)}</h4>
                            <div style={styles.textContent.text} dangerouslySetInnerHTML={{__html: passwordTexts.find(passText => passText.language === language) !== undefined ? passwordTexts.find(passText => passText.language === language).text : ''}} />
                        </div>
                    </div>

                </div>
            </div>
        )


}

const styles = {
    container: { marginTop: 60, width: 'auto', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
    header: {
        container: { fontWeight: 600, height: 120, width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', color: standartColors.black } as React.CSSProperties,
        buttom: {}
    },
    textContent: {
        container: { width: '49%', height: '100%', marginTop: 12, paddingBottom: 25, color: '212B36', borderRadius: 10, boxShadow: ' 0 0 12px 4px rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)', padding: 5 },
        title: {marginLeft: 10},
        text: {marginLeft: 10, fontSize: '12px', lineHeight: '14px'}
    },
    pswContent: {
        container: { width: '49%', height: '457px', marginTop: 12, color: '212B36', borderRadius: 10, boxShadow: ' 0 0 12px 4px rgb(145 158 171 / 24%), 0 16px 32px -4px rgb(145 158 171 / 24%)' } as React.CSSProperties,
        header: { height: 80, width: '96%', marginLeft: '2%', marginTop: 12, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' } as React.CSSProperties,
    },
    resultBox: {color: "#000", fontWeight: 600, height: 115, width: 300, borderRadius: 10, backgroundColor: '#ED8787', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: "center"} as React.CSSProperties,
    resultBoxYellow: {color: "#000", backgroundColor: "#FFE47B", fontWeight: 600, height: 115, width: 300, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: "center"} as React.CSSProperties,
    resultBoxGreen: {color: "#000", backgroundColor: "#89EDB3", fontWeight: 600, height: 115, width: 300, borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: "center"} as React.CSSProperties,
    button: {
        backgroundColor: standartColors.primaryYellow, color: standartColors.black, borderRadius: 10, height: '80%', width: 220, display: 'flex', justifyContent: 'center', alignItems: 'center'
    },
}
