import axios from "axios";

export class ConfigurationsRepository{

    getAll(request: string[]): Promise<any[]> {
        return new Promise((resolve, reject) => {

            axios.post('/configuration', {requested: request}).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getSearchResults(request: string){
        return new Promise((resolve, reject) => {

            axios.get('/search?s=' + request).then((response) => {
                resolve(response.data);
            }).catch((err) => {
                console.log(err);
                resolve([]);
            })

        })
    }

    getPermissionList(){
        return new Promise((resolve, reject) => {

            axios.get("/configuration/permissionList").then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                resolve([]);
            })

        })
    }

}
