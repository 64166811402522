import {Drawer, TextField, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {default as NoCreateSelect} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from '../../ViewComponents/VideoEditComponents/Drawer';
import {useLanguageState} from "../../States/LanguageState";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";
import {useLoginState} from "../../States/UserState";
import {UsersRepository} from "../../Repositories/UsersRepository";
import LanguageExporter from "../../Helpers/LanguageExporter";
import SelectAutocomplete from "../../ViewComponents/SelectAutoComplete";

const userRepository = new UsersRepository();

const PermissionEditBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, groups: any; permission: any, setContent: (data) => void;}) => {

    const language = useLanguageState();
    const [loadingData, setLoadingData] = useState(false);
    const [hasError, setHasError] = useState(false);
    const theme = useTheme();
    const authService = useLoginState();

    const [permissionState, setPermissionState] = useState({
        seeOwn: [],
        see: [],
        create: [],
        edit: [],
        delete: []
    })

    const [formState, setFormState] = useState({
        groupName: '',
        copyFrom: undefined
    });

    const searchPermit = useCallback((lookFor: string) => {

        const filterData = props.groups.filter((i) => {
            return i.permissions.findIndex((a) => a.name === lookFor) !== -1;
        });

        return filterData.map((i) => {
            return {label: i.name, value: i.id};
        });

    }, [props.groups]);

    useEffect(() => {
        if(props.permission){
            const permitList = props.permission.permits;
            setPermissionState({
                see: searchPermit(permitList.see),
                seeOwn: searchPermit(permitList.seeOwn),
                create: searchPermit(permitList.create),
                edit: searchPermit(permitList.edit),
                delete: searchPermit(permitList.delete)
            })
        }

    }, [props.groups, props.permission, searchPermit]);

    const saveChanges = useCallback(async () => {

        setLoadingData(true);
        console.log(permissionState, props.permission,)
        await userRepository.saveGroupData({
            permits: props.permission.permits,
            groups: permissionState,
            allGroups: props.groups
        }).then((response) => {
            props.setContent({});
            props.setIsOpen(false);
            setLoadingData(false);

        }).catch((err) => {
            console.log(err);
            setLoadingData(false);
        })

        setLoadingData(false);

    }, [props.permission, formState, permissionState, props.groups]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N(props.permission?.name, language.language)} {I18N('edit', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {/*GROUP NAME*/}
                            <div className="viewBoxTitle" style={{fontSize: 18}}>{I18N(props.permission?.name, language.language)}</div>
                            {/*COPY FROM*/}

                            {props.permission?.permits !== undefined && props.permission?.permits?.seeOwn !== '-' && (
                                <div className="marginTop20">
                                    <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('seeOwn', language.language)}</div>


                                    <SelectAutocomplete
                                        multiple={true}
                                        maxTags={2}
                                        options={props.groups.map((i) => {
                                            return {label: i.name, value: i.id}
                                        })}
                                        value={permissionState.seeOwn}
                                        label={I18N("seeOwn", language.language)}
                                        onChange={(change:any[]) => setPermissionState({...permissionState, seeOwn: change})}
                                    />

                                </div>
                            )}
                            <div className="clearfix" />
                            {props.permission?.permits !== undefined && props.permission?.permits?.see !== '-' && (
                                <div className="marginTop20">
                                    <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('see', language.language)}</div>


                                    <SelectAutocomplete
                                        multiple={true}
                                        maxTags={2}
                                        label={I18N("see", language.language)}
                                        options={props.groups.map((i) => {
                                            return {label: i.name, value: i.id}
                                        })}
                                        value={permissionState.see}
                                        onChange={(change:any[]) => setPermissionState({...permissionState, see: change})}
                                    />


                                </div>
                            )}


                            {props.permission?.permits !== undefined && props.permission?.permits?.create !== '-' && (
                                <div className="marginTop20">
                                    <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('create', language.language)}</div>

                                    <SelectAutocomplete
                                        multiple={true}
                                        maxTags={2}
                                        label={I18N("create", language.language)}
                                        options={props.groups.map((i) => {
                                            return {label: i.name, value: i.id}
                                        })}
                                        value={permissionState.create}
                                        onChange={(change:any[]) => setPermissionState({...permissionState, create: change})}
                                    />


                                </div>
                            )}


                            {props.permission?.permits !== undefined && props.permission?.permits?.edit !== '-' && (
                                <div className="marginTop20">
                                    <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('edit', language.language)}</div>

                                    <SelectAutocomplete
                                        multiple={true}
                                        maxTags={2}
                                        label={I18N("edit", language.language)}
                                        options={props.groups.map((i) => {
                                            return {label: i.name, value: i.id}
                                        })}
                                        value={permissionState.edit}
                                        onChange={(change:any[]) => setPermissionState({...permissionState, edit: change})}
                                    />



                                </div>
                            )}

                            {props.permission?.permits !== undefined && props.permission?.permits?.delete !== '-' && (
                                <div className="marginTop20">
                                    <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('delete', language.language)}</div>


                                    <SelectAutocomplete
                                        multiple={true}
                                        maxTags={2}
                                        label={I18N("delete", language.language)}
                                        options={props.groups.map((i) => {
                                            return {label: i.name, value: i.id}
                                        })}
                                        value={permissionState.delete}
                                        onChange={(change:any[]) => setPermissionState({...permissionState, delete: change})}
                                    />


                                </div>
                            )}


                            <div className="clearfix" />
                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default PermissionEditBar;
