import { Stack, TextField, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select/creatable';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import ViewBox from '../ViewComponents/ViewBox';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { colorForSelect } from '../ComplinessTheme';

type Props = {
  editMode?: any;
  backCallback: () => void;
  onNameChange: (name: string) => void;
  onLimitedCompaniesChange: (companies: any[]) => void;
  companies: any[];
  onFinish: () => void;
};

export function TrophyOperation({ companies, ...props }: Props) {
  console.log(props.editMode);
  const [name, setName] = useState<string>('');
  const [limitedCompanies, setLimitedCompanies] = useState<any[]>([]);
  const theme = useTheme();
  const language = useLanguageState((state) => state.language);
  const breadCrumb = useBreadCrumb();

  useEffect(() => {
    if (props.editMode !== null) {
      setName(props.editMode.name);
      setLimitedCompanies(
        props.editMode.trophies_companies?.map((c) => {
          return {
            value: c.company_id,
            label: c.company.name
          };
        }) || []
      );
    }
  }, [props.editMode]);

  useEffect(() => {
    props.onNameChange(name);
  }, [name, props.onNameChange]);

  useEffect(() => {
    props.onLimitedCompaniesChange(limitedCompanies);
    console.log(limitedCompanies);
  }, [limitedCompanies, props.onLimitedCompaniesChange]);

  useEffect(() => {
    breadCrumb.setTitle(
      props.editMode ? props.editMode.name : I18N('createTrophy', language)
    );
    breadCrumb.setAdditionalCrumb({
      title: props.editMode
        ? props.editMode.name
        : I18N('createTrophy', language),
      onClick: undefined
    });
    breadCrumb.setBackButton(props.backCallback);
    breadCrumb.setChildren([
      {
        color: 'lightGreen',
        key: 'learningPlanSaver',
        title: props.editMode
          ? I18N('updateTrophy', language)
          : I18N('createTrophy', language),
        onClick: props.onFinish,
        icon: 'save'
      }
    ]);
  }, [language, props.backCallback, props.editMode, props.onFinish]);

  return (
    <ViewBox>
      <Stack spacing={2}>
        <TextField
          fullWidth
          label={I18N('name', language)}
          style={{ marginTop: 20 }}
          value={name}
          onChange={(text) => setName(text.target.value)}
        />
        <Select
          theme={(template) => {
            return {
              ...template,
              colors: {
                ...template.colors,
                ...colorForSelect(theme)
              }
            };
          }}
          isClearable={true}
          isMulti
          onChange={(change) => {
            // @ts-expect-error wrong typing
            setLimitedCompanies(change);
          }}
          value={limitedCompanies}
          placeholder={I18N('limitedCompanies', language)}
          options={companies.map((company) => ({
            value: company.id,
            label: company.name
          }))}
        />
      </Stack>
    </ViewBox>
  );
}
