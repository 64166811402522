import {useEffect} from "react";
import {useLoginState} from "./States/UserState";
import {COOKIE_TOKEN, expireCookie} from "./Repositories/Cookies";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const AutoLogout = ({ children }) => {
    let timer;
    const authState = useLoginState(state => state.data);

// this function sets the timer that logs out the user after 10 secs
    const handleLogoutTimer = () => {
        if(authState.innerData.autoLogout){
            timer = setTimeout(() => {
                // clears any pending timer.
                resetTimer();
                // Listener clean up. Removes the existing event listener from the window
                Object.values(events).forEach((item) => {
                    window.removeEventListener(item, resetTimer);
                });
                // logs out user
                logoutAction();
            }, authState.innerData.autoLogoutTimer * 60000);
        }
    };

// this resets the timer if it exists.
    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

// when component mounts, it adds an event listeners to the window
// each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
// However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, []);

// logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
    const logoutAction = () => {
        expireCookie(COOKIE_TOKEN);
        expireCookie('refresh_token');
        window.location.href = '/';
        window.location.reload();
    };

    return children;
};

export default AutoLogout;