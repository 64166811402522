import React, {ReactElement, useEffect, useState} from "react";

interface TabProp {
    name: string,
    content: ReactElement
}

export default function ViewBoxTab(props: {
    tabs: TabProp[],
    activeTab?: number;
    onTabChange: (currentTab?: number) => void;
}){


    const [currentTab, setCurrentTab] = useState(props.activeTab !== undefined ? props.activeTab : 0);

    useEffect(() => {
        props.onTabChange(currentTab);
    }, [currentTab])

    return (
        <React.Fragment>
            <div className="tabList">
            {props.tabs.map((tab, index) => (
                <div onClick={() => setCurrentTab(index)} className={currentTab === index ? 'activeTab' : 'tab'}>
                    {tab.name}
                </div>
            ))}
            </div>
            <div className="tabInner">
                {props.tabs[currentTab].content}
            </div>
        </React.Fragment>
    )

}
