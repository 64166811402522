import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PaginationTypes} from "../Types/PaginationTypes";
import {ApiKeyType} from "../Types/ApiKeyTypes";

export class ApiKeyRepository implements  AbstractRepository<PaginationTypes<ApiKeyType>>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete('/apikeys/'+id).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAll(pagination?: {limit: number, page: number, orderType: null | {column: string, type: string},
        filter: null | {column: string, value: string, operator: string}}): Promise<PaginationTypes<ApiKeyType>> {
        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/apikeys${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/apikeys', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/apikeys/'+id, object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

}
