import React, {useCallback, useEffect, useState} from "react";
import {VideoRepository} from "../../../Repositories/VideoRepository";
import TableMui from "../../../ViewComponents/TableMui";
import {TrophyCalculator} from "../../../Helpers/TrophyCalculator";
import {I18N} from "../../../i18n/i18n";
import {useLanguageState} from "../../../States/LanguageState";
import DownPaperSVG from '../../../assets/downpaper.svg';
import DownloadTrophySVG from '../../../assets/downloadTrophy.svg';
import PlayButton from "../../../assets/play.svg";
import axios from "axios";
import {calculateContentProgress} from "../../../Helpers/calculateContentProgress";
import FormButton from "../../../ViewComponents/FormButton";
import {standartColors} from "../../../assets/colors/Colors";
import {Backdrop, CircularProgress} from "@mui/material";
import languageExporter from "../../../Helpers/LanguageExporter";

const videoRepository = new VideoRepository();

export default function MyTrophies(){

    const language = useLanguageState(state => state.language);
    const [contentData, setContentData] = useState([]);
    const [trophyData, setTrophyData] = useState([]);
    const [selectedTrophy, setSelectedTrophy] = useState(null);
    const [selectedInfo, setSelectedInfo] =useState([]);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingCreation, setLoadingCreation] = useState(false);
    const getData = useCallback( async () => {

        setLoadingData(true);
        const content = await videoRepository.getUserContent();
        setLoadingData(false);
        setContentData(content);

    }, []);


    useEffect(() => {

        getData().then(undefined);

    }, [getData]);

    const getTrophyDetailsForVideo = useCallback(async () => {

        if(selectedTrophy != null){
            setLoadingVideo(true);
            //const data = await videoRepository.userWatchTrophies(selectedTrophy.id);

            const data = contentData.filter((data) => {
                return data.trophyID === selectedTrophy.id
            })


            data.map((inner) => {
                if(inner.seen_videos.length > 0){
                    inner.progress = 100;
                }else if(inner.user_content_data.length > 0){

                    inner.progress = calculateContentProgress(inner.user_content_data[0]);

                }else {
                    inner.progress = 0;
                }
            })
            setLoadingVideo(false);
            setSelectedInfo(data);
        }

    }, [selectedTrophy, contentData]);

    useEffect(() => {
        getTrophyDetailsForVideo().then(undefined);
    }, [selectedTrophy, getTrophyDetailsForVideo])


    const calculateTrophyData = useCallback(() => {

        if(contentData.length < 1){
            return;
        }
        const trophyList = TrophyCalculator({contentData});
        console.log(trophyList);
        setTrophyData(trophyList);
    }, [contentData]);

    useEffect(() => {
        calculateTrophyData();
    }, [calculateTrophyData]);


    const downloadTrophy = useCallback((trophy) => {

        console.log(trophy);
        setLoadingCreation(true);
        axios.get(`/trophies/userTrophy/${trophy.user_id}/${trophy.trophy_id}`, {
            responseType: 'blob'
        }).then((response) => {
            setLoadingCreation(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${trophy.trophy_id}-${trophy.user_id}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response.data;

        }).catch((err) => {
            return err;
            setLoadingCreation(false);
        })

    }, []);

    const tableColumns = useCallback(() => {

        return [
            {
                field: 'name',
                name: I18N('name', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'progress',
                width: 300,
                name: I18N("progress", language),
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'normal'}}>{Math.ceil(params.row.progress)} %</span>;
                }
            },
            {
                field: 'download',
                name: I18N('download', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 150,
                renderCell: (params: any) => {
                    return (<div data-field="actions" onClick={() => {
                        if(Number(params.row.progress) === 100){
                            downloadTrophy(params.row);
                        }
                    }} className="ml-10 sticky">
                        <div className={`downloadMark ${Number(params.row.progress) !== 100 && "grayed"}`}><img data-field="actions" src={DownPaperSVG} style={{width: 22, height: 20, padding: 0, justifyContent: "center", margin: 10}}  /></div>
                    </div>)
                }
            },
        ];

    }, [language]);

    const tableColsTrophyVideos = useCallback(() => {

        return [
            {
                field: 'title',
                name: I18N('name', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    console.log(params);
                    return <span style={{fontWeight: 'bold'}}>{languageExporter(params.row, language, 'video_titles', 'title', 'title')}</span>;
                }
            },
            {
                field: 'progress',
                width: 300,
                name: I18N("progress", language),
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'normal'}}>{Math.ceil(params.row.progress)} %</span>;
                }
            },
            {
                field: 'watch',
                name: I18N('watch', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 150,
                renderCell: (params: any) => {
                    return (<div data-field="actions" onClick={() => {
                        window.location.href = '/Lernvideos#video='+params.row.id;
                    }} className="ml-10 sticky">
                        <div className={`downloadMark`}><img  data-field="actions" style={{width: 22, height: 20, padding: 0, justifyContent: "center", margin: 10}}  src={PlayButton} /></div>
                    </div>)
                }
            },
        ];

    }, []);

    return (
        <div style={{height: 500, width: '100%', display: 'flex', marginLeft: 25, flexDirection: 'column', position: "relative"}}>
            <Backdrop
                open={loadingCreation}
                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {selectedTrophy !== null && (<div style={{position: "absolute", right: 0, top: -70}}>
                <FormButton text={''} backButton={true} smallButton={true} onClick={() => setSelectedTrophy(null)} isLoading={loadingData} />
            </div>)}
            {selectedTrophy !== null ? (
                <TableMui key={"torphiesVideo"} onClick={(row) => {
                    window.location.href = '/Lernvideos#video='+row.id;
                }} isLoading={loadingVideo} data={selectedInfo} columns={tableColsTrophyVideos()} />
            ): (
                <TableMui key={"trophies"} onClick={(row, event) => {
                    if(event.target.dataset.field !== "actions") {
                        setSelectedTrophy(row);
                    }
                }} isLoading={loadingData} data={trophyData} getRowId={(row) => row.trophy_id} columns={tableColumns()} />
            )}
            {/*<TrophyCircles from={"myTrophies"} contentData={contentData} />*/}
        </div>
    )

}
