import { BACKEND_URL } from '../assets/AppConstants';
import { I18N } from '../i18n/i18n';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';
import { useLanguageState } from '../States/LanguageState';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { Backdrop, CircularProgress, TextField } from '@mui/material';
import { standartColors } from '../assets/colors/Colors';
import camera from '../assets/camera.svg';

interface Props {
  inputAttrs: React.InputHTMLAttributes<HTMLInputElement>;
  url: string;
  uploadRepository: any;
  onUpload: (file: string) => void;
  uploadKey: string;
  background?: string;
  showLoadingWhileUpload?: boolean;
  className?: string;
  uploadOriginal?: boolean;
  canUpload?: boolean;
}

export default function UploadFile({ canUpload, ...props }: Props) {
  /**
   * Creating ref object to get reference of input element on html dom
   */
  const refToInput = useRef<HTMLInputElement>(null);
  const [showBox, setShowBox] = useState(false);

  const language = useLanguageState((state) => state.language);
  const [loading, setLoading] = useState(false);

  /**
   * Handling click to upload box
   */
  const onClickToBox = useCallback(() => {
    if (refToInput.current !== null) {
      refToInput.current.click();
    }
  }, [refToInput]);

  /**
   * When pdf is uploaded, or already selected we will be showing the number of pages and the title of the pdf
   * this will be holding the number of pages after calculating it from the pdf
   */

  const [numberOfPages, setNumberOfPages] = useState(0);

  /**
   * Uploading file to backend by selected repository
   */
  const uploadFile = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (props.showLoadingWhileUpload) {
        setLoading(true);
      }
      const upload = await props.uploadRepository.uploadPicture(
        event.target.files[0],
        props.uploadOriginal
      );
      setLoading(false);
      props.onUpload(upload);
    },
    [props.onUpload, props.uploadOriginal]
  );

  return (
    <React.Fragment>
      <Backdrop
        open={loading}
        style={{ color: standartColors.primaryYellow, zIndex: 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <input
        type="file"
        id={props.uploadKey}
        key={props.uploadKey}
        className="hiddenElement"
        accept={props.inputAttrs.accept}
        onChange={uploadFile}
        ref={refToInput}
      />
      <div
        onMouseEnter={canUpload ? () => setShowBox(true) : () => {}}
        onMouseLeave={() => setShowBox(false)}
        className={`uploadBoxFull ${props.className}`}
        style={{ background: props.background ? props.background : '' }}
        onClick={onClickToBox}
      >
        {props.url !== '' && props.url !== null ? (
          <React.Fragment>
            {props.uploadKey === 'pdf' ? (
              <div className="pdfFileInBox">
                <Document
                  className="hiddenElement"
                  file={{
                    url: `${
                      props.url.includes(BACKEND_URL)
                        ? props.url
                        : BACKEND_URL + props.url
                    }`
                  }}
                  onLoadSuccess={(d) => setNumberOfPages(d.numPages)}
                />
                <p>{props.url.split('/')[props.url.split('/').length - 1]}</p>
                <p>
                  {I18N('totalPage', language)}: {numberOfPages}
                </p>
              </div>
            ) : (
              <React.Fragment>
                {props.className && props.className === 'newEditBox' ? (
                  <>
                    {showBox ? (
                      <div
                        style={{
                          position: 'relative',
                          width: '100%',
                          height: 394
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            maxHeight: 394,
                            height: 394,
                            background: 'rgba(84, 102, 122, 0.75)',
                            borderRadius: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <div style={{ width: 100, height: 40 }}>
                            <img
                              src={camera}
                              style={{
                                width: '100px',
                                height: '100px',
                                minWidth: 100,
                                maxWidth: 100
                              }}
                            />
                          </div>
                          <p style={{ padding: 0, margin: 0 }}>
                            {I18N('updatePhoto', language)}
                          </p>
                        </div>
                        <img
                          alt="content image"
                          src={`${
                            props.url.includes(BACKEND_URL)
                              ? props.url
                              : BACKEND_URL + props.url
                          }`}
                        />
                      </div>
                    ) : (
                      <img
                        alt="content image"
                        src={`${
                          props.url.includes(BACKEND_URL)
                            ? props.url
                            : BACKEND_URL + props.url
                        }`}
                      />
                    )}
                  </>
                ) : (
                  <img
                    alt="content image"
                    src={`${
                      props.url.includes(BACKEND_URL)
                        ? props.url
                        : BACKEND_URL + props.url
                    }`}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div>
            {I18N('pleaseClickToSelect', language, [
              { key: 'name', value: I18N(props.uploadKey, language) }
            ])}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
