import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {TextField, useTheme} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import FormButton from "../../../ViewComponents/FormButton";
import {colorForSelect} from "../../../ComplinessTheme";
import Select from "react-select";
import {UsersRepository} from "../../../Repositories/UsersRepository";
import {UserType} from "../../../Types/UserType";

const saveKey = 'categoriesStepTech';
const usersRepository = new UsersRepository();

function CategoriesStepIn(props: {title}, ref){

    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [formState, setFormState] = useState([{
        name: '',
        user: undefined,
    }]);
    const [users, setUsers] = useState([]);

    const loadUsers = useCallback(async () => {
        const userList = await usersRepository.getAll();
        setUsers(userList as UserType[]);
    }, []);

    useEffect(() => {
        loadUsers().then(undefined);
    }, [loadUsers])

    const clearData = useCallback(() => {

        setFormState([{
            name: '',
            user: ''
        }]);
        localStorage.removeItem(saveKey);

    }, []);

    const checkLocalStorage = useCallback(() => {

        const categoryTechData = localStorage.getItem(saveKey);
        if(categoryTechData){
            setFormState(JSON.parse(categoryTechData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formState));

    }, [formState]);

    return (
        <div style={{padding: 10, maxHeight: 380, minHeight: 380, overflowY: "scroll"}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>

            {formState.map((i, key) => {
                return (
                    <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                        <TextField
                            fullWidth
                            label={I18N('name', language)}
                            style={{ marginTop: 0,color: "#fff", marginRight: 5 }}
                            value={formState[key].name}
                            onChange={(text) => {
                                formState[key].name = text.target.value;
                                setFormState([...formState]);
                            }}
                        />
                        <Select placeholder={I18N('selectResponsibleUser', language)} styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 54,
                                    width: 300,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },}} theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} key="userSelect" options={users.map((user) => { return {value: user.id, label: `${user.firstName} ${user.lastName} (${user.email})`}})} value={formState[key].user} onChange={(uData) => {
                            formState[key].user = uData;
                            setFormState([...formState]);
                        }} />
                    </div>
                )
            })}

            <div style={{marginTop: 20}}>
                <FormButton text={I18N('new', language)} color="dark" icon="plus" onClick={() => {
                    formState.push({
                        name: '',
                        user: undefined,
                    });
                    setFormState([...formState]);
                }} isLoading={false} />
            </div>



            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )

}

const CategoriesStep = React.forwardRef(CategoriesStepIn);

export default CategoriesStep;

