import {Drawer} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../ViewComponents/VideoEditComponents/Drawer";
import {Android12Switch} from "../../ViewComponents/VideoEditComponents/Android12Switch";
import {useGlobalSettingsState} from "../../States/GlobalSettings";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import ViewBox from "../../ViewComponents/ViewBox";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
const companyRepository = new CompanyRepository();

const EditSettingsBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any, setContent: (data) => void;}) => {
    const language = useLanguageState();
    const [isChecked, setIsChecked] = useState(props.content.status);
    const [isAnonChecked, setIsAnonChecked] = useState(props.content.anonStatus);
    const [isLoading, setIsLoading] = useState(false);
    const globalSettingsState = useGlobalSettingsState();

    useEffect(() => {
        setIsChecked(props.content.status);
        setIsAnonChecked(props.content.anonStatus);
    }, [props.content.status, props.content.anonStatus]);


    const saveChanges = useCallback(async ( ) => {
        await companyRepository.updateGlobalSettings('psudeo', isChecked ? "1" : "0");
        await companyRepository.updateGlobalSettings('anon', isAnonChecked ? "1" : "0");
        const psudeoIndex = globalSettingsState.settings.findIndex((i) => i.key === 'psudeo');
        const anonIndex = globalSettingsState.settings.findIndex((i) => i.key === 'anon');

        if(anonIndex !== -1){
            globalSettingsState.settings[anonIndex].value = isAnonChecked ? "1" : "0";
        }else{
            globalSettingsState.settings.push({
                key: "anon",
                value: isAnonChecked ? "1" : "0"
            })
        }

        if(psudeoIndex !== -1){
            globalSettingsState.settings[psudeoIndex].value = isChecked ? "1" : "0";
        }else{
            globalSettingsState.settings.push({
                key: "psudeo",
                value: isChecked ? "1" : "0"
            })
        }
        globalSettingsState.setSettings([...globalSettingsState.settings]);
        props.setContent(isChecked ? 1 : 0);
        props.setIsOpen(false);
    }, [isChecked, isAnonChecked]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeSettings', language.language)}</p>
                        <FormButton icon="save" withLoadingInFullScreen={true} color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={isLoading} />
                    </div>
                    <div className="box"  style={{marginTop: 5}}>

                        <ViewBox>
                            <table>
                                <tr>
                                    <td style={{paddingLeft: 15, paddingTop: 10, fontWeight: "bold", fontSize: 14, lineHeight: '17px'}}>
                                        {I18N('pseudonymizationOfUser', language.language)}
                                    </td>
                                    <td style={{paddingLeft: 15, paddingTop: 13}}>
                                        <Android12Switch checked={isChecked} value={isChecked} onChange={() => {
                                            setIsChecked(!isChecked);
                                        }} color="warning" />
                                    </td>

                                </tr>
                                <tr>
                                    <td style={{paddingLeft: 15, paddingTop: 10, fontWeight: "bold", fontSize: 14, lineHeight: '17px'}}>
                                        {I18N('anonymizationOfUsers', language.language)}
                                    </td>
                                    <td style={{paddingLeft: 15, paddingTop: 13}}>
                                        <Android12Switch checked={isAnonChecked} value={isAnonChecked} onChange={() => {
                                            setIsAnonChecked(!isAnonChecked);
                                        }} color="warning" />
                                    </td>

                                </tr>
                            </table>
                        </ViewBox>


                    </div>
                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default EditSettingsBar;
