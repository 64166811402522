import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {ListsRepository} from "../Repositories/ListsRepository";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import TableMui from "../ViewComponents/TableMui";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {PaginationTypes} from "../Types/PaginationTypes";
import {UserListsType} from "../Types/UserListsTypes";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";

const listsRepository = new ListsRepository();

interface Props {

    setEditMode: (item) => void;
    deleteListItem: (item) => void;
}

export default function ListsList(props: Props){

    const language = useLanguageState(state => state.language);

    const [listItems, setListItems] = useState<PaginationTypes<UserListsType>>(undefined);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const loadLists = useCallback(async () => {
        if(!waitingForPage) {
            setLoading(true);
            const lists = await listsRepository.getAll({limit, page, filter, orderType});
            setListItems(lists as PaginationTypes<UserListsType>);
            setLoading(false);
        }
    }, [filter, limit, orderType, page, waitingForPage]);

    useEffect(() => {
        loadLists().then(undefined)
    }, [loadLists]);


    const deleteListItem = useCallback(async (item) => {

        await props.deleteListItem(item);
        await loadLists()

    }, [loadLists, props]);

    const tableColumnsMui = () => {
        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'name',
                name: I18N("name", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'description',
                name: I18N("description", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'actions',
                name: I18N('actions', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params: any) => {
                    return <DeleteIcon row={params.row} callBack={deleteListItem} ask={true} />;
                }
            },
        ];
    }

    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);



    return (
        <>
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    tableName="userListsListTable"
                    setWaitingForPage={setWaitingForPage}
                    page={page}
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={listItems?.data !== undefined ? listItems.data : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT
                        if(event.target.dataset.field !== "actions"){
                            props.setEditMode(data.row);
                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={listItems?.total}
                />
            </div>
        </>
    )

}
