import {Checkbox, TextField} from '@mui/material';
import React, { useCallback, useEffect, useState} from 'react';
import withReactContent from "sweetalert2-react-content";
import Swal from 'sweetalert2';
import {DepartmentsRepository} from "../Repositories/DepartmentsRepository";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {UsersRepository} from "../Repositories/UsersRepository";
import {VideoRepository} from "../Repositories/VideoRepository";
import Select from "react-select";
import ViewBox from "../ViewComponents/ViewBox";
import ViewBoxTab from "../ViewComponents/ViewBoxTab";
import FormButton from "../ViewComponents/FormButton";
import {NotificationsRepository} from "../Repositories/NotificationsRepository";
import {UserType} from "../Types/UserType";
import {LearnVideosType} from "../Types/LearnVideosTypes";
import {DepartmentType} from "../Types/DepartmentTypes";
import {useBreadCrumb} from "../States/BreadCrumbState";
import LanguageExporter from "../Helpers/LanguageExporter";
import VideoTypeExporter from "../Helpers/VideoTypeExporter";
const departmentRepository = new DepartmentsRepository();
const usersRepository = new UsersRepository();
const videoRepository = new VideoRepository();
const notificationsRepository = new NotificationsRepository();

const sweetAlert = withReactContent(Swal);


type Props = {
    style: any;
    isMobile: () => boolean;
}


export function CreateNotification(props: Props) {

    const language = useLanguageState(state => state.language)
    const [users, setUsers] = useState<UserType[]>([]);
    const [videos, setVideos] = useState<LearnVideosType[]>([]);
    const [departments, setDepartments] = useState<DepartmentType[]>([]);
    const breadCrumb = useBreadCrumb();

    const [video, setVideo] = useState<{label: string, value: number} | undefined>(undefined);
    const [to, setTo] = useState<string[]>([]);
    const [url, setUrl] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const [depTo, setDepTo] = useState<number[]>([]);


    const [loadingForm, setLoadingForm] = useState(false);


    const loadUsers = useCallback(async () => {

        const userList = await usersRepository.getAll();
        setUsers(userList as UserType[]);
    }, []);

    const loadVideos = useCallback(async () => {
        const videoList = await videoRepository.getAll();
        setVideos(videoList as LearnVideosType[]);
    }, []);


    const loadDepartments = useCallback(async () => {
        const depList = await departmentRepository.getAll();
        setDepartments(depList as DepartmentType[]);
    }, [])

    useEffect(() => {

        loadVideos().then(undefined);
        loadUsers().then(undefined);
        loadDepartments().then(undefined)

    }, []);


    const urlField = () => {
        return (
            <React.Fragment>
                <TextField
                    fullWidth
                    label={I18N("content", language)}
                    style={{ marginTop: 20 }}
                    value={content}
                    onChange={(text) => setContent(text.target.value)}
                />

                <TextField
                    fullWidth
                    label={I18N("url", language)}
                    style={{ marginTop: 20 }}
                    value={url}
                    onChange={(text) =>  setUrl(text.target.value)}
                />
            </React.Fragment>
        )
    }

    const videoField = useCallback(() => {
        return (
            <div className="marginTop20">
                <Select key="videoselect" options={videos.map((video) => { return {value: video.id, label: LanguageExporter(video, language, 'video_titles', 'title', 'title') + " ("+VideoTypeExporter(video.type)+")"}})} value={video} onChange={setVideo} />
            </div>
        )
    }, [videos, video, language]);


    const onTabChange = useCallback(() => {
        setVideo(undefined)
        setContent('');
        setUrl('');
    }, []);


    const usersField = useCallback(() => {

        return (
            <div className="selectItems">
                <div><Checkbox className="checkboxColor" checked={users.length === to.length} value={"on"} onChange={(event, checked) => {
                    if(checked){
                        setTo(users.map((user) => user.id))
                    }else {
                        setTo([]);
                    }
                }} /> <strong>{I18N('selectAll', language)}</strong></div>
                {users.map((user) => (
                    <div><Checkbox className="checkboxColor" onChange={(event, checked) => {
                        if(checked){
                            setTo([...to, user.id]);
                        }else{
                            let userList = to;
                            const index = userList.findIndex((user_id) => user_id === user.id);
                            if(index !== -1){
                                userList.splice(index, 1);
                                setTo([...userList]);
                            }
                        }
                    }} checked={to.findIndex((userIn) => userIn === user.id) !== -1} /> {user.username}</div>
                ))}
            </div>
        )

    }, [to, users])


    const departmentsField = useCallback(() => {

        return (
            <div className="selectItems">
                <div><Checkbox className="checkboxColor" checked={departments.length === depTo.length} value={"on"} onChange={(event, checked) => {
                    if(checked){
                        setDepTo(departments.map((dep) => dep.ID))
                    }else {
                        setDepTo([]);
                    }
                }} /> <strong>{I18N('selectAll', language)}</strong></div>
                {departments.map((dep) => (
                    <div><Checkbox className="checkboxColor" onChange={(event, checked) => {
                        if(checked){
                            setDepTo([...depTo, dep.ID]);
                        }else{
                            let depList = depTo;
                            const index = depList.findIndex((dep_id) => dep_id === dep.ID);
                            if(index !== -1){
                                depList.splice(index, 1);
                                setDepTo([...depList]);
                            }
                        }
                    }} checked={depTo.findIndex((depIn) => depIn === dep.ID) !== -1} /> {dep.name}</div>
                ))}
            </div>
        )

    }, [depTo, departments])


    const sendNotification = useCallback(async () => {

        let buildObject = {
            video_id: undefined,
            to: [],
            depTo: [],
            content: '',
            url: ''
        };

        if(video !== undefined){
            buildObject.video_id = video.value
            delete buildObject.content;
            delete buildObject.url;
        }else if(content !== ''){
            buildObject.content = content;
            buildObject.url = url;
        }else {
            await sweetAlert.fire({
                showCloseButton: true,
                title: I18N('error', language),
                html: I18N('pleaseFillContentNotification', language),
                icon: "error"
            });
            return ;
        }

        if(depTo.length < 1 && to.length < 1) {
            await sweetAlert.fire({
                title: I18N('error', language),
                html: I18N('pleaseFillUserOrDepartmentNotification', language),
                icon: "error"
            });
            return ;
        }

        if(depTo.length > 0){
            buildObject.depTo = depTo;
        }else{
            delete buildObject.depTo;
        }
        if(to.length > 0){
            buildObject.to = to;
        }else{
            delete buildObject.to;
        }


        setLoadingForm(true);
        await notificationsRepository.post(buildObject).then(async () => {

            setLoadingForm(false);
            await sweetAlert.fire({
                title: I18N('success', language),
                html: I18N('notificationsSent', language),
                icon: "success"
            });
            setTo([]);
            setDepTo([]);
            setContent('')
            setUrl('');
            setVideo(undefined);
        }).catch(async () => {
            await sweetAlert.fire({
                title: I18N('error', language),
                html: I18N('unexpectedError', language),
                icon: "error"
            });
            setLoadingForm(false);

        })



    }, [content, depTo, language, to, url, video]);


    useEffect(() => {
        breadCrumb.setTitle(I18N('notification', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined},{
            title: I18N('notification', language),
            onClick: undefined
        }]);
        breadCrumb.setChildren([
            {
                color: 'lightGreen',
                key: 'notificationsave',
                title:  I18N("sendNotification", language),
                onClick: sendNotification,
                isLoading: loadingForm,
                icon: "save"
            }
        ])

    }, [language, loadingForm, sendNotification]);


    return (
        <React.Fragment>
            <ViewBox title={I18N('content', language)}>
                <ViewBoxTab
                    onTabChange={onTabChange}
                    tabs={[
                        {name: I18N('url', language),  content: urlField()},
                        {name: I18N('content', language),  content: videoField()},
                    ]}
                />

            </ViewBox>

            <div className="flex-row">
                <ViewBox title={I18N('users', language)}>
                    {usersField()}
                </ViewBox>
                <ViewBox title={I18N('departments', language)}>
                    {departmentsField()}
                </ViewBox>
            </div>
        </React.Fragment>


    )

}
