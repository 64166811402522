import {styled} from "@mui/material/styles";

export const SidebarContainer = styled('div')(() => ({
    position: 'fixed',
    right: '2px',
    top: '50%;',
    transform: 'translateY(-50%)',
    width: '50px',
    zIndex: '1059',
    fontSize: '14px',
    backgroundColor: 'rgba(145, 158, 171, 0.25) !important',
}));

export const SidebarDrawer = styled('div')(() => ({
    width: '537px',
//    backgroundColor: 'rgba(145, 158, 171, 0.25) !important',
    borderRadius: '10px',
    fontSize: '12px',

    padding: '20px',
    '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        marginBottom: '20px',
        '& p': {
            margin: '0',
            fontWeight: 'bold'
        },
        '& svg': {
            cursor: 'pointer'
        }
    },
    '& .box': {
        marginTop: '59px',
        '& .small-boxes .card': {
            height: '50px',
            fontSize: '12px'
        },
        '& .card': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '151px',
            height: '88px',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
            margin: '7px',
            cursor: 'pointer'
        },
        '& > div': {
            display: 'flex',
            flexWrap: 'wrap'
        },
        '& p': {
            margin: '0',
            fontSize: '12px',
            fontWeight: 'bold'
        },
        '& .center-boxes': {
            justifyContent: 'center',
            '& p': {
                fontSize: '14px'
            }
        }
    },
    '& img': {
        marginTop: '5px',
        marginBottom: '5px'
    },
    '& .active-icon': {
        position: 'absolute',
        right: '8px',
        top: '5px'
    }
}));

export const SidebarDrawerBig = styled('div')(() => ({
    width: '1450px',
    maxWidth: '100%',
    borderRadius: '10px',
    fontSize: '12px',
    padding: '20px',
    '& .header': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '14px',
        marginBottom: '20px',
        '& p': {
            margin: '0',
            fontWeight: 'bold'
        },
        '& svg': {
            cursor: 'pointer'
        }
    },
    '& .box': {
        marginTop: '59px',
        '& .small-boxes .card': {
            height: '50px',
            fontSize: '12px'
        },
        '& .card': {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '151px',
            height: '88px',
            borderRadius: '10px',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
            margin: '7px',
            cursor: 'pointer'
        },
        '& > div': {
            display: 'flex',
            flexWrap: 'wrap'
        },
        '& p': {
            margin: '0',
            fontSize: '12px',
            fontWeight: 'bold'
        },
        '& .center-boxes': {
            justifyContent: 'center',
            '& p': {
                fontSize: '14px'
            }
        }
    },
    '& img': {
        marginTop: '5px',
        marginBottom: '5px'
    },
    '& .active-icon': {
        position: 'absolute',
        right: '8px',
        top: '5px'
    }
}));