import React, {useCallback, useEffect, useState} from "react";
import {TrophiesRepository} from "../Repositories/TrophiesRepository";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import TableMui from "../ViewComponents/TableMui";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import {PaginationTypes} from "../Types/PaginationTypes";
import {TrophiesTypes} from "../Types/TrophiesTypes";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import {useSnackbar} from "notistack";

const trophiesRepository = new TrophiesRepository();

export default function TrophyList(props: {editMode: (item) => void}) {

    const [trophies, setTrophies] = useState<PaginationTypes<TrophiesTypes>>(undefined);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderType, setOrderType] = useState(null);
    const [filter, setFilter] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const {enqueueSnackbar} = useSnackbar();
    const getTrophies = useCallback(async () => {
        if(!waitingForPage) {
            setLoading(true);
            const trophyList = await trophiesRepository.getAll({page, limit, filter, orderType});
            setTrophies(trophyList as PaginationTypes<TrophiesTypes>);
            setLoading(false);
        }
    }, [page, limit, filter, orderType, waitingForPage]);

    const deleteTrophy = useCallback(async (trophy: any) => {

        const del = await trophiesRepository.delete(trophy.id);

        if(del instanceof Error){
            enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            return;
        }else{
           await  getTrophies().then(undefined);
            enqueueSnackbar(I18N("deleteSuccess", language,[{key: 'name', value: I18N('trophy', language)}]), {variant: "success"});
        }

    }, [trophies, language, getTrophies]);


    useEffect(() => {
        getTrophies().then(undefined);
    }, [getTrophies]);


    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);


    const tableColumnsMui = () => {
        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'name',
                name: I18N("name", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'actions',
                name: I18N('actions', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params: any) => {
                    return <DeleteIcon row={params.row} callBack={deleteTrophy} ask={true} />;
                }
            },
        ];
    }


    return (
        <>
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    tableName="trophiesTableList"
                    page={page}
                    setWaitingForPage={setWaitingForPage}
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={trophies !== undefined ? trophies.data : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT
                        if(event.target.dataset.field !== "actions"){
                            props.editMode(data.row);
                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={trophies?.total}
                />
            </div>
        </>

    )

}
