import React, { useCallback, useEffect, useState } from 'react';
import { DepartmentsRepository } from '../Repositories/DepartmentsRepository';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import TableMui from '../ViewComponents/TableMui';
import DeleteIcon from '../ViewComponents/DeleteIcon';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { PaginationTypes } from '../Types/PaginationTypes';
import { DepartmentType } from '../Types/DepartmentTypes';
import tableHeightCalculator from '../Helpers/TableHeightCalculator';
import { useSnackbar } from 'notistack';

const departmentsRepository = new DepartmentsRepository();

export default function DepartmentList(props: { editMode: (item) => void }) {
  const [departments, setDepartments] =
    useState<PaginationTypes<DepartmentType>>(undefined);
  const language = useLanguageState((state) => state.language);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderType, setOrderType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [waitingForPage, setWaitingForPage] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  const getDepartments = useCallback(
    async (fastGet?: boolean) => {
      if (!waitingForPage || fastGet) {
        setLoading(true);
        const depList = await departmentsRepository.getAll({
          page: page,
          limit: limit,
          orderType: orderType,
          filter: filter
        });
        setDepartments(depList as PaginationTypes<DepartmentType>);
        setLoading(false);
      }
    },
    [page, limit, orderType, filter, waitingForPage]
  );

  const deleteDepartment = useCallback(
    async (department: any) => {
      const del = await departmentsRepository.delete(
        department.ID,
        department.unlink !== undefined ? department.unlink : false
      );

      if (del instanceof Error) {
        enqueueSnackbar(I18N('unexpectedError', language), {
          variant: 'error'
        });
        return;
      } else {
        if (del.status !== undefined && del.status === -1) {
          return del;
        }

        await getDepartments(true).then(undefined);

        enqueueSnackbar(
          I18N('deleteSuccess', language, [
            { key: 'name', value: I18N('department', language) }
          ]),
          { variant: 'success' }
        );
      }
    },
    [departments]
  );

  const tableColumnsMui = useCallback(() => {
    return [
      {
        field: 'ID',
        name: 'ID',
        width: 100,
        renderCell: (params: any) => {
          const { value } = params;
          return <span style={{ fontWeight: 'bold' }}>{value}</span>;
        }
      },
      {
        field: 'name',
        name: I18N('name', language),
        renderCell: (params: any) => {
          const { value } = params;
          return <span style={{ fontWeight: 'bold' }}>{value}</span>;
        }
      },
      {
        field: 'users',
        name: I18N('users', language),
        width: 100,
        renderCell: (params: any) => {
          const { row } = params;
          return <span style={{ fontWeight: 'bold' }}>{row._count.users}</span>;
        }
      },
      {
        field: 'learningContent',
        name: I18N('learnContent', language),
        width: 100,
        renderCell: (params: any) => {
          const { row } = params;
          return (
            <span style={{ fontWeight: 'bold' }}>
              {row._count.video_departments}
            </span>
          );
        }
      },
      {
        field: 'actions',
        name: I18N('actions', language),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        width: 100,
        renderCell: (params: any) => {
          return (
            <DeleteIcon
              unlinkRequired={true}
              row={params.row}
              callBack={deleteDepartment}
              ask={true}
            />
          );
        }
      }
    ];
  }, [language, deleteDepartment]);

  useEffect(() => {
    getDepartments().then(undefined);
  }, [getDepartments]);

  const onSortModelChange = useCallback((mode: GridSortModel) => {
    if (mode.length > 0) {
      setOrderType({
        column: mode[0].field,
        type: mode[0].sort
      });
    } else {
      setOrderType(null);
    }
  }, []);

  const onFilterModeChange = useCallback((mode: GridFilterModel) => {
    if (mode.items.length > 0) {
      setFilter({
        column: mode.items[0].columnField,
        value: mode.items[0].value,
        operator: mode.items[0].operatorValue
      });
    } else {
      setFilter(null);
    }
  }, []);

  return (
    <>
      <div style={{ height: tableHeightCalculator(limit) }}>
        <TableMui
          setWaitingForPage={setWaitingForPage}
          tableName="departmentListTable"
          onFilterModeChange={onFilterModeChange}
          onPageSizeChange={(size) => setLimit(size)}
          onPageChange={(pageData) => setPage(pageData + 1)}
          data={departments?.data !== undefined ? departments.data : []}
          onClick={(data, event) => {
            //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
            // IT CAPTURES THE ITEM THAT IS CLICKED
            // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
            // THINGS THAT WE DONT WANT TO REDIRECT
            if (
              event.target.dataset.field !== 'actions' &&
              event.target.dataset.field !== undefined
            ) {
              props.editMode(data.row);
            }
          }}
          onSortModelChange={onSortModelChange}
          isLoading={loading}
          columns={tableColumnsMui()}
          pageSize={limit}
          page={page}
          rowCount={departments?.total}
        />
      </div>
    </>
  );
}
