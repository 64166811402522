import * as HeroIcons from '@heroicons/react/outline';

export type IconName = keyof typeof HeroIcons;
export interface IIconProps {
    name: IconName;
    color?: string;
    background?: string;
    size?: number;
}

const DynamicHeroIcon = (props: IIconProps) => {
    const { name, color, size, background } = props;
    const SingleIcon = HeroIcons[name];

    return (
        <SingleIcon
            color={color}
            fill={background || 'none'}
            fontSize={size}
            height={size}
            width={size}
        />
    );
};

export default DynamicHeroIcon;

DynamicHeroIcon.defaultProps = {
    size: 24
};
