import {useLanguageState} from "../../States/LanguageState";
import {PermissionItem, PermissionName} from "../Components/TableComponents";
import {I18N} from "../../i18n/i18n";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import GroupPermissionSearcher from "../Components/GroupPermissionSearcher";
import React from "react";

const avPermissions = ["seeOwn", 'see', 'create', 'edit', 'delete'];

export default function Dashboard(props: {groupList: any, setSelectedPermission: any}){
    const language = useLanguageState(state => state.language)
    return (
        <>
            <div style={{width: 218}}>
                <PermissionName onClick={() => props.setSelectedPermission({name: "welcomeContent", permits: {seeOwn: 'ViewWelcomeContent', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("welcomeContent", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "openLearningContent", permits: {seeOwn: 'ViewOpenLearningContent', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("openLearningContent", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "lookingOverPersonalVideos", permits:  {seeOwn: 'ViewLookOverPersonalVideos', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("lookingOverPersonalVideos", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "trophies", permits: {seeOwn: 'ViewDashboardTrophies', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("trophies", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "userRanking", permits: {seeOwn: 'ViewUserRanking', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("userRanking", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "departmentRanking", permits:{seeOwn: 'ViewDepartmentRanking', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("departmentRanking", language)} />
                <PermissionName onClick={() => props.setSelectedPermission({name: "userChart", permits:{seeOwn: 'ViewUserChartDashboard', see: "-", create: "-", delete: "-", edit: "-"}})} title={I18N("userChart", language)} />
            </div>
            <Swiper style={{width: '100%'}} freeMode slidesPerView="auto" spaceBetween={0}>

                {avPermissions.map((i) => {
                    return (
                        <SwiperSlide style={{width: 170}}>
                            <PermissionItem key={"welcomeContent"}>
                                <GroupPermissionSearcher permissionList={{seeOwn: 'ViewWelcomeContent', see: "-", create: "-", delete: "-", edit: "-"}} groups={props.groupList} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"openLearningContent"}>
                                <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewOpenLearningContent', see: "-", create: "-", delete: "-", edit: "-"}} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"lookingOverPersonalVideos"}>
                                <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewLookOverPersonalVideos', see: "-", create: "-", delete: "-", edit: "-"}} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"trophies"}>
                                <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewDashboardTrophies', see: "-", create: "-", delete: "-", edit: "-"}} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"userRanking"}>
                                <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewUserRanking', see: "-", create: "-", delete: "-", edit: "-"}} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"departmentRanking"}>
                                <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewDepartmentRanking', see: "-", create: "-", delete: "-", edit: "-"}} permissionToLook={i} />
                            </PermissionItem>
                            <PermissionItem key={"userChart"}>
                                <GroupPermissionSearcher groups={props.groupList} permissionList={{seeOwn: 'ViewUserChartDashboard', see: "-", create: "-", delete: "-", edit: "-"}} permissionToLook={i} />
                            </PermissionItem>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    )

}
