import React, { useCallback, useEffect, useState } from 'react';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import CompanyList from './CompanyList';
import CompanyOperation from './CompanyOperation';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { useLocation } from 'react-router-dom';
import { useHasPermissions } from '../Helpers/MultiPermissionCheck';

type Props = {
  style: any;
  isMobile: () => boolean;
};

export default function CompanyTab(props: Props) {
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState<null | any>(null);
  const language = useLanguageState((state) => state.language);
  const [formLoading, setFormLoading] = useState(false);
  const [mountKey, setMountKey] = useState<string | undefined>(undefined);
  const breadCrumb = useBreadCrumb();
  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    logo: '',
    employees: ''
  });
  const canEdit = useHasPermissions(['EditCompany']);

  const scrollTop = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const backToCompaniesTable = () => {
    setCreateOpen(false);
    setEditCompany(null);
    setFormLoading(false);
    scrollTop();
  };

  const onReturnBackToView = useCallback(() => {
    breadCrumb.setTitle(I18N('companies', language));
    breadCrumb.setBackButton(null);
    breadCrumb.setCrumb([
      {
        title: I18N('configuration', language),
        onClick: undefined
      },
      {
        title: I18N('companies', language),
        onClick: onReturnBackToView
      }
    ]);
    breadCrumb.setChildren([
      {
        icon: 'plus',
        key: 'companyListCreate',
        title: I18N('new', language),
        onClick: () => {
          setCreateOpen(!createOpen);
          scrollTop();
        },
        color: 'dark'
      }
    ]);
    backToCompaniesTable();
  }, [language]);

  useEffect(() => {
    onReturnBackToView();
  }, [onReturnBackToView]);

  useEffect(() => {
    setEditCompany(null);
    setCreateOpen(false);
  }, []);

  useEffect(() => {
    if (location.key !== undefined) {
      if (location.key !== mountKey) {
        onReturnBackToView();
      }
    }
    setMountKey(location.key);
  }, [location, mountKey]);

  return (
    <div style={props.style}>
      {!createOpen ? (
        <CompanyList
          editMode={(item) => {
            setEditCompany(item);
            setCreateOpen(true);
            scrollTop();
          }}
        />
      ) : (
        <CompanyOperation
          onFormChange={setFormData}
          editMode={editCompany}
          onReturnBackToView={onReturnBackToView}
          canEdit={canEdit}
        />
      )}
    </div>
  );
}
