import ReactApexChart from "react-apexcharts";
import tActive from '../../assets/tActive.svg';
import tDeactive from '../../assets/tDeactive.svg';
import React, {useCallback} from "react";
import {I18N} from "../../i18n/i18n";
import {standartColors} from "../../assets/colors/Colors";
import {useLanguageState} from "../../States/LanguageState";
import axios from "axios";
import {useTheme} from "@mui/material";

export default function TrophyCircle(props: {
    trophy: any;
}) {

    console.log(props.trophy)
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const donutTrophySettings = useCallback(() => {
        return {
            labels: [I18N('finished', language), I18N('requireToGet', language)],
            colors: [standartColors.circleYellow, standartColors.circleGray],
            stroke: {
                colors: [theme.palette.mode === 'dark' ? 'transparent' : 'transparent'],
            },
            tooltip: {
                x: {
                    formatter: function (val) {
                        return val + "%"
                    }
                },
                y: {
                    formatter: function (val) {
                        return val + "%"
                    }
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        borderColor: theme.palette.mode === 'dark' ? '#FFE16A' : '#fff',
                        size: '90%'
                    }
                }
            },
            legend: { show: false },
            dataLabels: {
                enabled: false,
                formatter: function (val) {
                    return val + "%"
                }
            }
        }
    }, [language, theme]);

    const callTrophyTest = useCallback(() => {

        console.log(props.trophy);
        axios.get(`/trophies/userTrophy/${props.trophy.user_id}/${props.trophy.trophy_id}`, {
            responseType: 'blob'
        }).then((response) => {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${props.trophy.trophy_id}-${props.trophy.user_id}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
            return response.data;

        }).catch((err) => {
            return err;
        })

    }, [props.trophy]);

    return (
        <div className="trophyHolder" onClick={() => callTrophyTest()}>
            <ReactApexChart
                type="donut"
                options={donutTrophySettings()} series={props.trophy.awarded ? [100, 0] : [Math.ceil(props.trophy.progress), 100-Math.ceil(props.trophy.progress)]}
                height={200}
                // className={classes.chart}
            />
            <div className="inner">
                <img src={props.trophy.awarded ? tActive : tDeactive} style={{ height: '65px' }} alt="login" />
            </div>
            <p className="trophyName">{props.trophy.name}</p>
        </div>
    )

}
