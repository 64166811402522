import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import {useBreadCrumb} from "../States/BreadCrumbState";
import checkGreen from "../assets/checkGreen.svg";
import checkRed from "../assets/checkRed.svg";
import {useLanguageState} from "../States/LanguageState";
import EditIcon from "../ViewComponents/EditIcon";
// import EditSettingsBar from "./EditSettingsBar";
import {useLoginState} from "../States/UserState";
import {useGlobalSettingsState} from "../States/GlobalSettings";
import {UsersRepository} from "../Repositories/UsersRepository";
import {PasswordQualityType, UserGroupsType} from "../Types/UserType";
import TableMui from "../ViewComponents/TableMui";
import languageExporter from "../Helpers/LanguageExporter";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";

import {Chip} from "@mui/material";
import EmailEdit from "./EmailEdit";
import {
    defaultLinkNotification,
    defaultNotification,
    defaultResetPass,
    defaultVideoNotification,
    welcomeDefault
} from "./DefaultEmails";
import {NotificationsRepository} from "../Repositories/NotificationsRepository";

type Props = {
    style: any;
    isMobile: () => boolean;
}

const userRepository = new UsersRepository();
const notificationRepository = new NotificationsRepository();

const emaiList = {
    welcome: {content: welcomeDefault, isDefault: true, id: 9192312, availableVariables: ['firstName', 'lastName', 'client', 'url', 'username', 'password']},
    videoNotification: {content:defaultVideoNotification, isDefault: true, id: 88123123, availableVariables: ['firstName', 'lastName', 'videoName', "videoCategory", "link"]},
    resetPassword: {content:defaultResetPass, isDefault: true, id: 12412431255, availableVariables: ["firstName", 'lastName', "token"]},
    notification: {content:defaultNotification, isDefault: true, id: 512333, availableVariables: ["text"]},
    linkNotification: {content: defaultLinkNotification, isDefault: true, id: 56232, availableVariables: ["firstName", "lastName", "link"]}
}


export function EmailList(props: Props) {

    const language = useLanguageState(state => state.language)
    const breadCrumb = useBreadCrumb();
    const [loadingForm, setLoadingForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [mails, setMails] = useState([]);
    const [manuMails, setManuMails] = useState([]);

    const getMails = useCallback(async () => {

        const notification =  notificationRepository.getClientMails();

        setLoading(true);
        await Promise.all([notification]).then((a) => {
            setMails(a[0] as unknown as any);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        })

    }, []);

    useEffect(() => {
        getMails().then(undefined)
    }, []);


    useEffect(() => {

        const mailData = Object.keys(emaiList);

        const manu = [];

        mailData.forEach((data) => {
           const findInDB = mails.find((a) => a.key === data);
           if(!findInDB){
               manu.push({
                   id: emaiList[data].id,
                   key: data,
                   content: emaiList[data].content,
                   isDefault: true,
                   availableVariables: emaiList[data].availableVariables
               })
           }else{
               manu.push({
                   id: findInDB.id,
                   key: data,
                   content: findInDB.content,
                   isDefault: false,
                   availableVariables: emaiList[data].availableVariables
               })
           }
        });

        setManuMails([...manu]);


    }, [mails]);

    useEffect(() => {
        breadCrumb.setTitle(I18N('emailList', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined},{
            title: I18N('emailList', language),
            onClick: undefined
        }]);
        breadCrumb.setChildren([
            {
                color: 'transparent',
                key: 'emailListSettings',
                title:  I18N("x", language),
                onClick: () => undefined,
                isLoading: loadingForm,
                icon: "save"
            }
        ])

    }, [language, loadingForm,]);


    const tableColumnsMui = (language) => {
        return [
            {
                field: 'name',
                name: I18N('name', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    return <span style={{fontWeight: 'bold'}}>{I18N(row.key, language)}</span>;
                }
            },
        ];
    }



    return (
        <React.Fragment>
            <div style={{height: tableHeightCalculator(10)}}>
                <EmailEdit isOpen={isOpen} setIsOpen={setIsOpen} content={selectedItem} setContent={(statusData) => {
                    getMails().then(undefined);
                }} />

                <TableMui
                    tableName="groupListTable"
                    data={manuMails}
                    onClick={(data, event) => {
                        setSelectedItem(data.row);
                        setIsOpen(true);
                    }}
                    isLoading={loading}
                    page={1}
                    columns={tableColumnsMui(language)}
                    rowCount={manuMails.length}
                />
            </div>
        </React.Fragment>


    )

}
