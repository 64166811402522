import React from "react";
import WelcomeSVG from '../../assets/welcomsvg.svg';
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {useLoginState} from "../../States/UserState";
import {useTheme} from "@mui/material";
import dashboardHeader from '../../assets/dashboardHeader.svg';
import arrowRight from '../../assets/arrowRight.svg';

export function BoxHello(props: {
    contentData:any
}){

    const language = useLanguageState(state => state.language);
    const authState = useLoginState(state => state.data);
    const theme = useTheme();

    console.log(props.contentData);

    return (
        <div className="flex-row" style={{backgroundColor: theme.palette.mode === 'dark' ? 'transparent' :"#FFF7CD", borderRadius: 10, position: "relative", minHeight: 210}}>
            <div style={{paddingLeft: 20, zIndex: 2}}>
                <p style={{fontWeight: "bold"}}>{I18N("welcomeText", language)}</p>
                <p style={{fontSize: 14, padding: 0, margin: 0}}>Hey {authState?.name},</p>
                <p style={{fontSize: 14, padding: 0, margin: 0}}>{I18N("welcomeVideoText", language, [{key: "length", value: props.contentData.length}])}</p>
                <div onClick={() => {
                    window.location.href = '/Lernvideos'
                }} className="buttonHolder" style={{justifyContent: "flex-start", marginTop: 20}}><div className="smallButton" style={{color: '#000'}}><img src={arrowRight} style={{width: 20, marginRight: 10}} />{I18N("goToVideos", language)}</div> </div>
            </div>
            <div className="hideSmall">
                <img src={dashboardHeader} />
            </div>
        </div>
    )

}
