import React, { useCallback, useEffect, useState } from "react";
import { Table } from "../UiLibrary/table/Table";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {TableHeader} from "../Types/TableTypes";
import {useSnackbar} from "notistack";


const companyRepository = new CompanyRepository();

export default function ClientList(props: { editMode: (item) => void,  setLoadingMode: (set: boolean) => void; doNotShowDelete?: boolean }) {

    const [companies, setCompanies] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {enqueueSnackbar} = useSnackbar();
    const getCompanies = useCallback(async () => {
        setLoading(true);
        const companyList = await companyRepository.getAllAdmin({page: page, limit: limit});
        setCompanies(companyList);
        setLoading(false);
    }, [page, limit]);

    const deleteCompany = useCallback(async (company: any) => {

        props.setLoadingMode(true);
        const del = await companyRepository.delete(company.ID);
        props.setLoadingMode(false);
        if (del instanceof Error) {
            enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            return;
        } else {

            await getCompanies().then(undefined);
            enqueueSnackbar(I18N("deleteSuccess", language, [{ key: 'name', value: I18N('company', language) }]), {variant: "success"});
        }

    }, [companies, language, props, getCompanies]);


    const tableColumns = (): TableHeader[] => {
        const cols =  [
            { name: 'ID', dataName: 'ID', type: 'TEXT', callback: (item) => props.editMode(item) },
            { name: I18N('name', language), dataName: 'name', type: 'TEXT' },
            { name: I18N('environment', language), dataName: 'environment', type: 'TEXT'},
            { name: I18N('totalUsers', language), dataName: 'users.totalUsers', type: 'TEXT'},
            { name: I18N('activeUsers', language), dataName: 'users.loggedInUsers', type: 'TEXT'},
            { name: I18N("license", language), dataName: 'licenceData', type: 'TEXT'},
        ];
        if(!props.doNotShowDelete){
            cols.push({ name: I18N('actions', language), dataName: 'finished', type: 'DELETE', callback: deleteCompany })
        }
        //@ts-ignore
        return cols;
    }

    useEffect(() => {
        getCompanies().then(undefined);
    }, [getCompanies]);

    return (
        <Table
            page={page}
            limit={limit}
            loading={loading}
            setLimit={setLimit}
            setPage={setPage}
            columns={tableColumns()}
            rows={companies} />
    )

}
