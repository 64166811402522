import HeaderMenu from "../ViewComponents/NewHeaderMenu";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import LogoNew from "../assets/LogoData.svg";
import {Link} from "react-router-dom";
import EGRCItemHolder from "./EGRCItemHolder";
import ViewBox from "../ViewComponents/ViewBox";
import egrcHeader from "../assets/egrcHeader.svg";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {useBreadCrumb} from "../States/BreadCrumbState";
import SettingsSidebar from "../ViewComponents/SettingsSideBar/settings-sidebar";
import EGRCSender from "./EGRCSender";
import FormButton from "../ViewComponents/FormButton";
import EGRCContentPlayer from "./EGRCContentPlayer";
import EGRCTable from '../assets/egrctable.svg';
import EGRCList from '../assets/egrclist.svg';

export default function EGRCContentHome(props: {token: string}){
    const theme = useTheme()
    const language = useLanguageState(state => state.language);
    const [videoOpen, setVideoOpen] = useState(null);
   const breadCrumb = useBreadCrumb();
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeStyle, setActiveStyle] = useState('list');
    useEffect(() => {


        if(videoOpen){

            breadCrumb.setTitle(videoOpen.name);
            breadCrumb.setBackButton(() => setVideoOpen(false))
            breadCrumb.setCrumb([
                {
                    title: I18N('contentEGRC', language),
                    onClick: () => setVideoOpen(null)
                },
                {
                    title: videoOpen.name,
                    onClick: undefined
                }
            ])

        }else{


            breadCrumb.setTitle(I18N("contentEGRC", language));
            breadCrumb.setBackButton(null);
            breadCrumb.setCrumb([
                {
                    title: I18N('contentEGRC', language),
                    onClick: undefined
                }
            ])

        }


    },[videoOpen]);


    useEffect(() => {

        setLoading(true);
        EGRCSender(props.token).then((response) => {
            setVideos(response);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        })

    }, [props.token]);


    return (
        <div>

            <SettingsSidebar />
            <div style={{position: "fixed", zIndex:99, background: theme.palette.mode === 'dark' ? "rgb(10, 25, 41)" : "#fff", top: 0, width: '100%', left: 0}}>
                <div style={styles.searchBar.container}>
                    <div style={styles.searchBar.searchBar}>
                <Link to={"/Dashboard"}> <img width={33.52} height={40} src={theme.palette.mode === 'dark' ?  LogoNew : LogoNew} alt="login" /></Link>
                    </div>
                </div>

                <HeaderMenu closeVideoForEGRC={() => setVideoOpen(null)} token={props.token} isEGRC={true}/>
            </div>

            <div style={{marginTop: 150, padding: 30}}>


                {/*<BreadCrumb />*/}

                {!videoOpen && (
                    <div style={{background: "rgba(255, 225, 106, 0.5)", backdropFilter: 'blur(5px)', position: "relative", marginTop: 60, borderRadius: '10px 10px 0 0', height: 260}}>
                        <p style={{fontSize: 36, fontWeight: "bold", color: "#000", position: "absolute", left: 20, bottom: 20}}>{videoOpen ? videoOpen.name: I18N('contentEGRC', language)}</p>
                        <img style={{width: 400, height: 306, position: "absolute", bottom: 10, right: 0}} src={egrcHeader} />
                    </div>
                )}


                {videoOpen ? (
                   <EGRCContentPlayer token={props.token} content={videoOpen} />
                ): (
                    <>
                        <div style={{marginTop: 10,border: ' 1px solid rgba(145, 158, 171, 0.24)', padding: 6, borderRadius: 8, display: "flex", flexDirection: "row", alignItems: "flex-end", alignContent: "flex-end", float: "right", width: 80}}>
                            <div onClick={() => setActiveStyle('list')} style={{background: activeStyle === 'list' ? "rgba(145, 158, 171, 0.16)" : "#fff", cursor: "pointer",  width: 30, height:30, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 8, marginRight: 8}}><img src={EGRCList} /></div>
                            <div onClick={() => setActiveStyle('table')} style={{background: activeStyle === 'table' ? "rgba(145, 158, 171, 0.16)" : "#fff", cursor: "pointer", width: 30, height:30, display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", borderRadius: 8}}> <img src={EGRCTable} /></div>
                        </div>
                        <div style={{clear: "both"}} />

                        {activeStyle === 'list' ? (
                            <ViewBox>
                                <div style={{flexDirection: "row", display: "flex", flexWrap: "wrap"}}>
                                    {videos.map((i) => {
                                        return  <EGRCItemHolder openVideo={setVideoOpen} content={i.contents} />
                                    })}
                                </div>
                            </ViewBox>
                        ) : (
                            <ViewBox>
                                <table style={{ width: '100%', borderCollapse: "collapse", borderRadius: 10}}>
                                    <thead style={{position: "sticky"}}>
                                    <tr className="tableViewUser">
                                        <th style={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}}>{I18N('title', language)}</th>
                                    </tr>
                                    </thead>
                                    <tbody className="tableInnerViewUser" >
                                    {videos.map((item) => (
                                        <tr onClick={() => setVideoOpen(item.contents)} style={{cursor: "pointer", color: "#212B36", fontWeight: "bold", fontSize: 16}}>
                                            <td>{item.contents.name}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </ViewBox>
                        )}



                    </>

                )}


            </div>



            <FormButton text={""} onClick={undefined} isLoading={loading} withLoadingInFullScreen color={"transparent"} />

        </div>
    )
}

const styles = {
    searchBar: {
        searchBar: { height: 100, width: '80%', display: 'flex',  alignItems: 'center', marginLeft: 25, flexDirection: "row" } as React.CSSProperties,
        container: { height: 100, width: '95%', display: 'flex', justifyContent: "space-between", marginLeft: 25, flexDirection: "row" } as React.CSSProperties,
        textInput: {},
        searchIcon: { height: 20, width: 20 },
        languageIcon: { height: 20, width: 20, position: 'absolute', right: 15 } as React.CSSProperties,
    },
}
