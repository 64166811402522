import ViewBox from '../ViewComponents/ViewBox';
import { I18N } from '../i18n/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguageState } from '../States/LanguageState';
import { CompanyRepository } from '../Repositories/CompanyRepository';
import { TextField, useTheme } from '@mui/material';
import UploadFile from '../ViewComponents/UploadFile';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { useSnackbar } from 'notistack';
const companyRepository = new CompanyRepository();

type Props = {
  editMode?: any;
  onFormChange: (data: any) => void;
  onReturnBackToView: () => void;
  canEdit?: boolean;
};

export default function CompanyOperation({ canEdit, ...props }: Props) {
  const language = useLanguageState((state) => state.language);
  const breadCrumb = useBreadCrumb();
  const [formLoading, setFormLoading] = useState(false);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    name: '',
    employees: '',
    logo: '',
    shortcut: ''
  });

  useEffect(() => {
    if (props.editMode !== null) {
      setFormData({
        name: props.editMode.name,
        employees: props.editMode.employees,
        logo: props.editMode.logo,
        shortcut: props.editMode.shortcut
      });
    }
  }, [props.editMode]);

  const saveCompany = useCallback(async () => {
    setFormLoading(true);

    if (props.editMode !== null) {
      await companyRepository
        .update(
          { ...formData, employees: Number(formData.employees) },
          props.editMode.id
        )
        .then(async (response) => {
          enqueueSnackbar(
            I18N('successfullyUpdated', language, [
              { key: 'name', value: I18N('company', language) }
            ]),
            { variant: 'success' }
          );
          props.onReturnBackToView();
          setFormLoading(false);
        })
        .catch(async () => {
          enqueueSnackbar(I18N('unexpectedError', language), {
            variant: 'error'
          });
          setFormLoading(false);
        });
    } else {
      await companyRepository
        .post({ ...formData, employees: Number(formData.employees) })
        .then(async (response) => {
          enqueueSnackbar(
            I18N('successfullyCreated', language, [
              { key: 'name', value: I18N('company', language) }
            ]),
            { variant: 'success' }
          );
          props.onReturnBackToView();
          setFormLoading(false);
        })
        .catch(async () => {
          enqueueSnackbar(I18N('unexpectedError', language), {
            variant: 'error'
          });
          setFormLoading(false);
        });
    }
  }, [props.editMode, formData, language]);

  useEffect(() => {
    breadCrumb.setBackButton(props.onReturnBackToView);
    breadCrumb.setAdditionalCrumb({
      title: props.editMode
        ? props.editMode.name
        : I18N('createCompany', language),
      onClick: undefined
    });
    breadCrumb.setChildren([
      {
        key: 'companyInnerCreate',
        icon: 'save',
        title: props.editMode
          ? I18N('updateCompany', language)
          : I18N('createCompany', language),
        onClick: saveCompany,
        color: 'lightGreen',
        isDisabled: !canEdit
      }
    ]);
  }, [saveCompany, props.onReturnBackToView, props.editMode, language]);

  return (
    <div>
      <ViewBox>
        <div
          className="row"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <TextField
            fullWidth
            label={I18N('name', language)}
            style={{ marginTop: 20, color: '#fff' }}
            value={formData.name}
            onChange={(text) =>
              setFormData({ ...formData, name: text.target.value })
            }
            disabled={!canEdit}
          />
          <TextField
            fullWidth
            label={I18N('shortcut', language)}
            style={{ marginTop: 20, marginLeft: 20, width: '30%' }}
            value={formData.shortcut}
            onChange={(text) =>
              setFormData({ ...formData, shortcut: text.target.value })
            }
            disabled={!canEdit}
          />
        </div>

        <TextField
          fullWidth
          label={I18N('employees', language)}
          style={{ marginTop: 20 }}
          value={formData.employees}
          onChange={(text) =>
            setFormData({ ...formData, employees: text.target.value })
          }
          disabled={!canEdit}
        />
        <UploadFile
          background={
            theme.palette.mode === 'dark' ? '#001E3C' : 'rgb(224, 224, 224)'
          }
          inputAttrs={{ accept: 'image/*' }}
          url={formData.logo}
          uploadRepository={companyRepository}
          onUpload={(image) => {
            setFormData({ ...formData, logo: image });
            console.log(image);
          }}
          uploadKey="logo"
        />
      </ViewBox>
    </div>
  );
}
