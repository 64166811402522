import React, {useCallback, useEffect, useState} from 'react';
import { CategoryOperation } from "./CategoryOperation";
import CategoryList from "./CategoryList";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import { CategoriesRepository } from "../Repositories/CategoriesRepository";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";
const categoriesRepository = new CategoriesRepository();

type Props = {
    style: any;
    isMobile: () => boolean
}



export function CategoryTab(props: Props) {
    const breadCrumb = useBreadCrumb();
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editCategory, setEditCategory] = useState<null | any>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);
    const [name, setName] = useState<string>('');
    const [user, setUser] = useState<string>('');
    const [company, setCompany] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                onReturnBackToView();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    const backToCategoriesTable = () => {
        setCreateOpen(false);
        setEditCategory(null);
        setFormLoading(false);
        scrollTop();
    }


    const saveCategory = useCallback(async () => {

        if (name === '' || user === '' || company.length < 1) {
            enqueueSnackbar(I18N('pleaseFill', language, [{ key: 'name', value: I18N('name', language) }]), {variant: "error"});
            return;
        }


        if (editCategory === null) {
            setFormLoading(true);
            const create = await categoriesRepository.post({ name: name, user_id: user, companies: company });

            if (create instanceof Error) {
                setFormLoading(false);
                enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            } else {
                setFormLoading(false);
                enqueueSnackbar(I18N('successfullyCreatedReturning', language, [{ key: 'name', value: I18N('category', language) }]), {variant: "success"});
                onReturnBackToView()
            }
        } else {
            const update = await categoriesRepository.update({ name: name, user_id: user, companies: company }, Number(editCategory.id));
            if (update instanceof Error) {
                setFormLoading(false);
                enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            } else {
                setFormLoading(false);
                enqueueSnackbar(I18N('successfullyUpdatedReturning', language, [{ key: 'name', value: I18N('category', language) }]), {variant: "success"});
                onReturnBackToView()
            }
        }
        setFormLoading(false);
    }, [name, editCategory, user, company]);


    const onReturnBackToView = useCallback(() => {
        breadCrumb.setCrumb([{
            title: I18N('configuration', language),
            onClick: undefined
        },{
            title: I18N('categories', language),
            onClick: onReturnBackToView
        }]);
        breadCrumb.setBackButton(null);
        breadCrumb.setTitle(I18N('categories', language))

        breadCrumb.setChildren([
            {
                key: "categoryCreateNew",
                title: I18N('new', language),
                onClick: () => {
                    setCreateOpen(!createOpen);
                    scrollTop();
                },
                color: 'dark',
                icon: "plus"
            }
        ])
        backToCategoriesTable();
    }, [language]);

    useEffect(() => {
        onReturnBackToView()
    }, [onReturnBackToView]);

    return (
        <div style={props.style}>
            {!createOpen ?
                <CategoryList editMode={(item) => {
                    setEditCategory(item);
                    setCreateOpen(true);
                    scrollTop();
                }} />
                : (
                    <CategoryOperation onUserUpdated={setUser} onSave={saveCategory} onNameUpdate={setName} onCompanyUpdated={setCompany} backCallback={onReturnBackToView} editMode={editCategory} />
                )}
        </div>
    )

}
