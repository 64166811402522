import {Drawer, Switch, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import Select, {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../../ViewComponents/ViewBox";
const sweetAlert = withReactContent(Swal)


const phishingRepository = new PhishingRepository();

const TargetGroupBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void; groups: any[], departments:any[], lists: any[], userList: any[], selections: any, }) => {
    const language = useLanguageState(state => state.language);
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({userSelection: undefined, groupSelection: undefined, listSelection: undefined, departmentSelection: undefined});
    const theme= useTheme();
    const [status, setStatus] = useState({listSelection: false, department: false, groupSelection: false, userSelection: false});

    const saveChanges = useCallback(async () => {

        setLoadingData(true);
        const selections = {
            departments: [],
            lists: [],
            groups: [],
            users: [],
        };

        if(formState.departmentSelection){
            selections.departments = formState.departmentSelection.map((item) => item.value);
        }
        if(formState.userSelection){
            selections.users = formState.userSelection.map((item) => item.value);
        }
        if(formState.listSelection){
            selections.lists = formState.listSelection.map((item) => item.value);
        }
        if(formState.groupSelection){
            selections.groups = formState.groupSelection.map((item) => item.value);
        }

        let statusOfSelections = false;
        Object.keys(selections).forEach(item => {
            if(selections[item].length > 0){
                statusOfSelections = true;
            }
        })


        if(!statusOfSelections){
            await sweetAlert.fire({
                icon: 'error',
                title: I18N('error', language),
                html: I18N('pleaseFill', language, [{key:'name', value: I18N('campaign', language)}])
            })

            return;

        }

        await phishingRepository.updateCampaignTargetGroup(selections, props.content.id).then((response) => {
            props.setIsOpen(false);
            props.setContent(response);
            setLoadingData(false);
        }).catch((err) => {
            console.log(err);
            setLoadingData(false);
        })

        setLoadingData(false);


    }, [formState, props]);


    useEffect(() => {
        setStatus({department: props.selections.departments.length > 0, listSelection: props.selections.lists.length > 0, groupSelection: props.selections.groups.length > 0, userSelection: props.selections.userLists.length > 0});
        setFormState({userSelection: props.selections.userLists, groupSelection: props.selections.groups, listSelection: props.selections.lists, departmentSelection: props.selections.departments,})
    }, [props.selections.departments, props.selections.groups, props.selections.lists, props.selections.userLists]);


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>

                            <div style={{display: "flex",}}>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="marginTop20" styles={{  valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            width: 300,
                                            maxWidth: 300,
                                            minWidth: 300,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={true} isDisabled={!status.listSelection} isClearable={false} placeholder={I18N('userList', language)} options={props.lists.map((list) => {
                                    return {value: list.id, label: list.name}
                                })} value={formState.listSelection} onChange={(change) => {
                                    setFormState({...formState, listSelection: change})
                                }} />
                                <div style={{width: 70, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 25}}><Switch checked={status.listSelection} onChange={(e, checked) => {
                                    setStatus({...status, listSelection: checked});
                                    if(!checked){
                                        setFormState({...formState, listSelection: null});
                                    }
                                }} /></div>

                            </div>

                            <div style={{display: "flex",}}>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className=" marginTop20" styles={{  valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            height: 50,
                                            width: 300,
                                            maxWidth: 300,
                                            minWidth: 300,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={true} isClearable={false} isDisabled={!status.department} placeholder={I18N('departments', language)} options={props.departments.map((department) => {
                                    return {label: department.name, value: department.ID}
                                })} value={formState.departmentSelection} onChange={(change) => {
                                    setFormState({...formState, departmentSelection: change})
                                }} />

                                <div style={{width: 70, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 25}}><Switch checked={status.department} onChange={(e, checked) => {
                                    setStatus({...status, department: checked});
                                    if(!checked){
                                        setFormState({...formState, departmentSelection: null});
                                    }
                                }} /></div>

                            </div>


                            <div style={{display: "flex", flexDirection: "row"}}>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className=" marginTop20" styles={{  valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            width: 300,
                                            maxWidth: 300,
                                            minWidth: 300,
                                            height: 50,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={true} isClearable={false} isDisabled={!status.groupSelection} placeholder={I18N('permissions', language)} options={props.groups.map((group) => {
                                    return {label: group.name, value: group.id}
                                })} value={formState.groupSelection} onChange={(change) => {
                                    setFormState({...formState, groupSelection: change})
                                }} />

                                <div style={{width: 70, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 25}}><Switch checked={status.groupSelection} onChange={(e, checked) => {
                                    setStatus({...status, groupSelection: checked});
                                    if(!checked){
                                        setFormState({...formState, groupSelection: null});
                                    }
                                }} /></div>

                            </div>


                            <div style={{display: "flex", flexDirection: "row"}}>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className=" marginTop20" styles={{  valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            width: 300,
                                            maxWidth: 300,
                                            minWidth: 300,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={true} isClearable={false}  isDisabled={!status.userSelection} placeholder={I18N('users', language)} options={props.userList.map((user) => {
                                    return {label: user.firstName + ' ' + user.lastName, value: user.id}
                                })} value={formState.userSelection} onChange={(change) => {
                                    setFormState({...formState, userSelection: change})
                                }} />

                                <div style={{width: 70, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 25}}><Switch checked={status.userSelection} onChange={(e, checked) => {
                                    setStatus({...status, userSelection: checked});
                                    if(!checked){
                                        setFormState({...formState, userSelection: null});
                                    }
                                }} /></div>

                            </div>

                        </div>
                    </ViewBox>
                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default TargetGroupBar;
