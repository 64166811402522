import React, {useCallback, useEffect, useRef, useState} from "react";
import { useLanguageState } from "../../States/LanguageState";
import { UserContentDataRepository } from "../../Repositories/UserContentDataRepository";
import { VideoRepository } from "../../Repositories/VideoRepository";
import {API_VIDEOS, BACKEND_URL} from "../../assets/AppConstants";
import {I18N} from "../../i18n/i18n";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {LearnVideosType} from "../../Types/LearnVideosTypes";
import {QuizRepository} from "../../Repositories/QuizRepository";
import {standartColors} from "../../assets/colors/Colors";
import {Backdrop, CircularProgress, useTheme} from "@mui/material";
import FormButton from "../../ViewComponents/FormButton";
import {LogsRepository} from "../../Repositories/LogsRepository";
const videoRepository = new VideoRepository();
const userContentDataRepository = new UserContentDataRepository();
const sweetAlert = withReactContent(Swal);

const logger = new LogsRepository();

type Props = {
    video: LearnVideosType;
    returnBack: () => void;
    callQuiz: (contentId: number) => void;
    isPreview?: boolean
};

let lastData = {};

export default function ShowVideo(props: Props) {

    //Setting states;
    const language = useLanguageState(state => state.language);
    const iframeRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [fullLoading, setFullLoading] = useState(false);
    const [error, setError] = useState(false);




    useEffect(() => {

        if (window.postMessage) {
            setError(false);
        } else{
            setError(true);
            logger.post({type: "error", message: "Scorm api not working, POSTMESSAGE API DISABLED OR NOT WORKING"});
        }

    }, []);


    const exitFull = useCallback(() => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            // @ts-ignore
        } else if (document.webkitExitFullscreen) { /* Safari */
            // @ts-ignore
            document.webkitExitFullscreen();
            // @ts-ignore
        } else if (document.msExitFullscreen) { /* IE11 */
            // @ts-ignore
            document.msExitFullscreen();
        }
    }, [iframeRef])

    const saveVideoData = useCallback(async (fromVideo: any) => {
        if (props.video.type === 1 && props.video.id === fromVideo.content_id) {

            if(JSON.stringify(lastData) !== JSON.stringify(fromVideo)) {
                lastData = fromVideo;
                await userContentDataRepository.update({data: JSON.stringify(fromVideo), content_id: props.video.id});
                if (fromVideo.courseStatus) {
                    if (props.video.quizes !== null && props.video.quizes !== undefined) {
                        setFullLoading(true);
                        await videoRepository.setVideoToQuiz(props.video.id);
                        setFullLoading(false);
                        props.callQuiz(props.video.id);
                    } else {
                        exitFull();
                        await videoRepository.markAsSeen(props.video.id).then(() => undefined);
                        await sweetAlert.fire({
                            showCloseButton: true,
                            title: I18N('success', language),
                            html: I18N('successfullyFinished', language),
                            icon: "success"
                        });
                        props.returnBack();
                    }
                }
            }
        }
    }, [language, lastData, props, exitFull]);

    useEffect(() => {
        if(props.isPreview)
            return;
        window.addEventListener("message", (event) => {
            //@ts-ignore */
            if (event.origin === API_VIDEOS) {
                saveVideoData(event.data);
            }
        });
        return () => {
            window.removeEventListener("message", (event) => {
                //@ts-ignore */
                if (event.origin === API_VIDEOS) {
                    saveVideoData(event.data);
                }
            })
        };
    }, [saveVideoData]);


    const onloadIframe = useCallback(() => {
        if(props.isPreview)
            return;
        if (iframeRef.current !== null) {
            if (props.video.user_content_data.length > 0) {
                iframeRef.current.contentWindow.postMessage({
                    clear: false,
                    content_id: props.video.id,
                    data: JSON.parse(props.video.user_content_data[0].data)
                    //@ts-ignore */
                }, BACKEND_URL);
            } else {
                iframeRef.current.contentWindow.postMessage(
                    { clear: true, content_id: props.video.id }
                    //@ts-ignore */
                    , BACKEND_URL);

            }
        }
    }, [props.video, iframeRef]);


    const loadVideo = useCallback(async () => {

        const getVideoURL = await videoRepository.getContentUrl(props.video.id);

        if(getVideoURL.status){
            setVideoUrl(getVideoURL.url)
        }else{
            await sweetAlert.fire({
                title: I18N('error', language),
                html: I18N('errorWhileLoadingContent', language),
                icon: "error"
            });
        }


    }, [props.video]);


    //Loading video when page loads
    useEffect(() => {
        loadVideo().then(undefined);
    }, [loadVideo]);




    const makeFull = useCallback(() => {

                if (iframeRef.current.requestFullscreen) {
                    iframeRef.current.requestFullscreen();
                } else if (iframeRef.current.webkitRequestFullscreen) {
                    iframeRef.current.webkitRequestFullscreen();
                } else if (iframeRef.current.mozRequestFullScreen) {
                    iframeRef.current.mozRequestFullScreen();
                } else if (iframeRef.current.msRequestFullscreen) {
                    iframeRef.current.msRequestFullscreen();
                }

    }, [iframeRef]);

    return (
        <div style={styles.videoPlayer.container}>


            <div className="flex-row-button" style={{marginTop: -50, marginBottom: 10, marginLeft: '20%'}}>
                <FormButton text={I18N('fullScreen', language)} onClick={makeFull} isLoading={false} />
            </div>

            <Backdrop
                open={fullLoading}
                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {error ? (
               I18N('postMessageAPI', language)
            ): (
                <>
                    {videoUrl !== null ? (
                        <iframe ref={iframeRef} style={styles.videoPlayer.iframe}
                                allowFullScreen={true}
                                allowTransparency={true}
                                onLoad={onloadIframe}
                            //@ts-ignore */
                                src={videoUrl + '?origin=' + BACKEND_URL + '&letterbox=true&lang=' + language}
                        />
                    ): (
                        'Loading content...'
                    )}
                </>
            )}


        </div>
    )
}

const styles = {
    videoPlayer: {
        container: { height: '70vh', width: '80%', },
        iframe: { height: '100%', width: '120%', borderRadius: 10 }
    }
}
