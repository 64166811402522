import * as React from 'react';
import Modal from '@mui/material/Modal';
import {useCallback} from "react";
import RunQuiz from "../QuizEditor/RunQuiz";
import ShowVideo from "../RouteComponents/LearnVideos/ShowVideo";
import ShowPDF from "../RouteComponents/LearnPdf/ShowPDF";
import {VideoPlayer} from "../VideoPlayer/VideoPlayer";

export default function PreviewModal(props: {isOpen: boolean, onClose: () => void; editMode: any}) {

    const videoShower = useCallback(() => {
        console.log(props.editMode.quizes);
        if(props.editMode.quizes !== null && props.editMode.quizes !== undefined){

            if(props.editMode.quizes.quiz_progress.filter((quizD) => quizD.content_id === props.editMode.id).length > 0){
                return <RunQuiz selectedVideo={props.editMode} onFinishQuiz={() => undefined} />
            }
        }


        if(props.editMode.type === 1){
            return (
                <ShowVideo isPreview={true} returnBack={() => undefined} callQuiz={() => undefined} video={props.editMode} />
            )
        }if(props.editMode.type === 4){
            return <RunQuiz selectedVideo={props.editMode} onFinishQuiz={() => undefined} />
        }else{
            return (
                <React.Fragment>
                    {props.editMode.type === 2 ? (
                        <ShowPDF isPreview={true} callQuiz={() => undefined} returnBack={() => undefined} pdf={props.editMode} content={props.editMode.user_content_data.length > 0 ? JSON.parse(props.editMode.user_content_data[0].data) : null} />
                    ): (
                        <VideoPlayer isPreview={true} callQuiz={() => undefined} video={props.editMode} returnBack={() => undefined} content={props.editMode.user_content_data.length > 0 ? JSON.parse(props.editMode.user_content_data[0].data) : null} />
                    )}
                </React.Fragment>
            )
        }

    }, [props.editMode]);

    return (
        <div>
            <Modal

                open={props.isOpen}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={{    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%',
                    boxShadow: '24',}}>
                    {videoShower()}
                </div>

            </Modal>
        </div>
    );
}