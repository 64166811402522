import create from 'zustand';

type LoginStateType = {
    photo: string,
    phone: string,
    setPhoto: (photo: string) => void;
    setPhone: (phone: string) => void;
    setToken: (token: any) => void;
    initData: (photo, phone, extraData) => void;
    token: null | any,
    data: null | any;
    setCompany: (company: number[]) => void;
    company: number[] | null;
};

export const useLoginState = create<LoginStateType>(set => ({
    photo: '',
    phone: '',
    token: null,
    data: null,
    company: null,
    setCompany: (company: number[]) => {set({company: company})},
    setPhoto: (photo: string) => {
        set({photo: photo})
    },
    setPhone: (phone: string) => {
        set({phone: phone})
    },
    initData: (photo, phone, extraData: any) => {set({photo: photo, phone: phone, data: extraData});},
    setToken: (token) => set({token: token})

}));
