import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PasswordQualityType, UserGroupsType, UserType} from "../Types/UserType";
import {BACKEND_URL} from "../assets/AppConstants";
import {PaginationRequestType, PaginationTypes} from "../Types/PaginationTypes";

export class UsersRepository implements AbstractRepository<PaginationTypes<UserType> | UserType[]> {
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }


    async impersonate(user_id){
        return await axios.post('/users/impersonate', {user_id: user_id});
    }

    resetPassword(client, username): Promise<{status: boolean}>{
        axios.defaults.baseURL = BACKEND_URL;
        return new Promise((resolve, reject) => {

            axios.post('/users/resetpassword', {
                client: client,
                username: username
            }).then((response) => {
                return resolve({
                    status: response.data.status
                })
            }).catch(() => {
                return reject({
                    status: false
                })
            })

        });

    }

    resetPasswordSave(client, token, password): Promise<{status: boolean}>{
        axios.defaults.baseURL = BACKEND_URL;
        return new Promise((resolve, reject) => {

            axios.post('/users/resetpasswordsave', {
                client: client,
                token: token,
                password: password
            }).then((response) => {
                return resolve({
                    status: response.data.status,
                    ...response.data
                })
            }).catch(() => {
                return reject({
                    status: false
                })
            })

        });

    }

    delete(id: string, unArchive?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete(`/users/${id}?unArchive=${unArchive}`).then(() => {
                return resolve({status: true});
            }).catch((err) => {
                return reject({
                    status: false,
                    code: err.response.status,
                    data: err.response.data
                })
            })


        });
    }

    getTop10(): Promise<any>{
        return new Promise((resolve, reject) => {

            axios.get('/users/top10').then((response) => {
                return resolve(response.data);
            }).catch((err) => reject(err));

        });
    }

    updateLanguage(language: 'de' | 'en'): Promise<UserType>{
        return new Promise((resolve, reject) => {
            axios.patch('/users/updateLanguage', {
                language: language
            }).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        })
    }


    updateUserPsudoStatus(psudoStatus: boolean): Promise<boolean>{
        return new Promise((resolve, reject) => {
            axios.patch('/users/userData/psudoStatus', {
                psudoStatus: psudoStatus
            }).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        })
    }

    updateUserCompaniesStatus(companies: number[]): Promise<boolean>{
        return new Promise((resolve, reject) => {
            axios.patch('/users/companyData/updateUserCompaniesStatus', {
                companies: companies
            }).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        })
    }


    getAll(pagination?: PaginationRequestType, showArchive?: boolean): Promise<PaginationTypes<UserType> | UserType[]> {
        return new Promise((resolve, reject) => {
            let supAdmin = '';
            if(pagination?.superAdmin !== undefined){
                supAdmin = `&superAdmin=${pagination.superAdmin}`;
            }
            console.log(pagination);
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/users${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}${supAdmin}&showArchive=${showArchive}` : `?showArchive=${showArchive}`}`).then((users) => {
                if(pagination !== undefined){
                    resolve(users.data as PaginationTypes<UserType>)
                }else{
                    resolve(users.data as UserType[]);
                }
            }).catch((error) => {
                reject(error);
            })

        });
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
       return new Promise((resolve, reject) => {
           axios.get('/users/uSingle/' + id).then((response) => {
               return resolve(response.data);
           }).catch((err) => {
               reject({
                   status: false,
                   code: err.response.status,
                   data: err.response.data
               })
           })
       })
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

           axios.post('/users', object).then((response) => {
               return resolve(response.data);
           }).catch((err) => {
               reject({
                   status: false,
                   code: err.response.status,
                   data: err.response.data
               })
           })

        });
    }

    createBulk(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/users/bulk', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                reject({
                    status: false,
                    code: err.response.status,
                    data: err.response.data
                })
            })

        });
    }


    //@ts-ignore
    update(object: any, id: string): Promise<any> {

        return new Promise((resolve, reject) => {

            axios.patch('/users/'+id, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                reject({
                    status: false,
                    code: err.response.status,
                    data: err.response.data
                })
            })

        });
    }

    savePasswordQuality(object, id) {

        return new Promise((resolve, reject) => {

            axios.post(`/users/passwordQuality/${id}`, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                reject({
                    status: false,
                    code: err.response.status,
                    data: err.response.data
                })
            })

        });
    }

    passwordQuality(): Promise<PasswordQualityType[]>{
        return new Promise((resolve, reject) => {

            axios.get('/users/passwordQuality').then((groups) => {
                resolve(groups.data);
            }).catch((error) => {
                reject(error);
            })

        });
    }

    getGroups(): Promise<UserGroupsType[]>{
        return new Promise((resolve, reject) => {

            axios.get('/users/groups').then((groups) => {
                resolve(groups.data);
            }).catch((error) => {
                reject(error);
            })

        });
    }


    createGroup(formState): Promise<UserGroupsType[]>{
        return new Promise((resolve, reject) => {

            axios.post('/users/groups', {...formState}).then((groups) => {
                resolve(groups.data);
            }).catch((error) => {
                reject(error);
            })

        });
    }

    saveGroupData(formState): Promise<UserGroupsType[]>{
        return new Promise((resolve, reject) => {

            axios.post('/users/groups/update', {...formState}).then((groups) => {
                resolve(groups.data);
            }).catch((error) => {
                reject(error);
            })

        });
    }

    getUserGroup(id: string): Promise<any>{

        return new Promise((resolve, reject) => {

            axios.get('/users/userGroup/'+id).then((group) => {
                resolve(group.data);
            }).catch((error) => {
                reject(error);
            })

        })

    }


    async loginUsingKeyCloak(client: string, username: string, password: string){


        //@ts-ignore
        return await axios.post(window.env.KEYCLOAK_URL + "/realms/"+window.env.KEYCLOAK_REALM+"/protocol/openid-connect/token", `grant_type=password&client_id=${client}&username=${username}&password=${password}`, {
            headers: {
                'content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            return {
                status: true,
                data: response.data
            }
        }).catch((err) => {
            return {
                status: false,
                data: null
            }
        })
    }


    async getUserInfo(): Promise<{
        "sub": string,
        "email_verified": boolean,
        "name": string,
        "preferred_username": string,
        "given_name": string,
        "family_name": string,
        "email": string
    }>{

        //@ts-ignore
        return await axios.get(window.env.KEYCLOAK_URL + '/realms/'+window.env.KEYCLOAK_REALM+'/protocol/openid-connect/userinfo').then((response) => response.data);

    }

    async refreshKeycloak(client: string, refresh_token: string){

        //https://userservice.goriscon.app/auth/realms/awareness_test/protocol/openid-connect/token
        //@ts-ignore
        return await axios.post(window.env.KEYCLOAK_URL + "/realms/"+window.env.KEYCLOAK_REALM+"/protocol/openid-connect/token", `grant_type=refresh_token&client_id=${client}&refresh_token=${refresh_token}`, {
            headers: {
                'content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((response) => {
            return {
                status: true,
                data: response.data
            }
        }).catch((err) => {
            return {
                status: false,
                data: null
            }
        })
    }


}
