import React, {useCallback, useEffect, useState} from "react";
import ViewBox from "./ViewBox";
import {BoxHello} from "./WelcomeBoxes/BoxHello";
import {BoxSlider} from "./WelcomeBoxes/BoxSlider";
import {useLoginState} from "../States/UserState";

type WelcomeBoxType = {
    contentData: any;
    type: 1 | 2;
}

export function WelcomeBox(props: WelcomeBoxType){

    const authState = useLoginState();
    const [data, setData] = useState([]);

    const calculateBoxValues = useCallback(() => {

        const contentList = props.contentData.filter((vid) => {
            if(vid.seen_videos.length > 0) {
                return false;
            }else if(vid.inPlan !== undefined){
                return true;
            }else if(vid.video_departments.findIndex((inDp) => inDp.department_id === authState.data?.innerData.department) !== -1){
                return true
            }else if(vid.requiredForAll){
                return true;
            }else if(vid.visibleForAll){
                return false;
            }
            return false;
        })

        setData(contentList);


    }, [props.contentData, authState]);


    useEffect(() => {

        calculateBoxValues();

    }, [calculateBoxValues]);


    return (
        <ViewBox padding={0}>
            {props.type === 1 ? <BoxHello contentData={data} />  : <BoxSlider contentData={data} />}
        </ViewBox>
    )

}



