import imageWizard from '../assets/wizard.svg';
import stepGreen from '../assets/stepGreen.svg';
import stepGray from '../assets/stepGray.svg';
import stepYellow from '../assets/stepYellow.svg';
export default function StepDetails(props: {
    totalStep: number;
    activeStep: number;
    steps: any[];
}){

    console.log(props.steps)
    return (
        <div>
            <img src={imageWizard} width={400} style={{position: "absolute", opacity: 0.4, bottom: 50}} />
            <div style={{height: 200, padding: 20, width: '100%', fontSize: 12, fontWeight: 400}}>
                {props.steps[props.activeStep].stepDescription}
            </div>
            <div>
                {props.steps.map((i, key) => {
                    if(key === 0 || key == props.steps.length - 1){
                        return ''
                    }
                    return (
                        <table style={{marginLeft: 40}}>
                            <tr>
                                <td style={{paddingRight: 20}}>
                                    {props.activeStep === key ? (
                                        <img src={stepYellow} />
                                    ): (
                                        <img src={key <= props.activeStep - 1 ? stepGreen : stepGray} />
                                    )}

                                </td>
                                <td>
                                    {i.title}
                                </td>
                            </tr>
                        </table>
                    )
                })}
            </div>

        </div>
    )

}