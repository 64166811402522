import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import Select from "react-select";
import {UsersRepository} from "../Repositories/UsersRepository";
import LTextField from "../ViewComponents/LTextField";
import {colorForSelect} from "../ComplinessTheme";
import {TextField, useTheme} from "@mui/material";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {useBreadCrumb} from "../States/BreadCrumbState";
import SelectAutocomplete from "../ViewComponents/SelectAutoComplete";
import {UserType} from "../Types/UserType";
const usersRepository = new UsersRepository();
const companyRepository = new CompanyRepository();

type Props = {

    editMode?: any
    backCallback: () => void;
    onNameUpdate: (name: string) => void;
    onUserUpdated: (user_id: string) => void;
    onCompanyUpdated: (comp: any) => void;
    onSave: () => void;
}

export function CategoryOperation(props: Props) {

    const [name, setName] = useState<string>('');
    const [users, setUsers]  = useState<any>([]);
    const [user, setUser] = useState<any>(undefined);
    const [selectedSubCompanies, setSelectedSubCompanies] = useState<any>([]);
    const [subCompanies, setSubCompanies] = useState([]);
    const breadCrumb = useBreadCrumb();
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    useEffect(() => {
        if(props.editMode !== null) {
            setName(props.editMode.name);
            if(users !== null){
                const findUser = users.find((keyCloakUser) => keyCloakUser.id === props.editMode.user_id);
                if(findUser !== undefined){
                    setUser({value: findUser.id, label: `${findUser.firstName} ${findUser.lastName} (${findUser.emailAddress})`});
                }
            }
            if(subCompanies !== null){
                const arr = props.editMode.categories_companies.map((i) => {
                    return i.company_id
                });

                const findCompany = subCompanies.filter((item) => arr.includes(item.id));
                setSelectedSubCompanies(findCompany.map((i) => {
                    return {label: i.name, value: i.id};
                }))
            }
        }
    }, [props.editMode, users, subCompanies]);


    useEffect(() => {
        breadCrumb.setBackButton(props.backCallback);
        breadCrumb.setTitle(props.editMode ? props.editMode.name : I18N('createCategory', language));
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? props.editMode.name : I18N('createCategory', language),
            onClick: undefined
        })
        breadCrumb.setChildren([
            //{key: "categoryInnerBack", title: '', isBackButton: true,   onClick: props.backCallback, isSmall: true, color: "yellow"},
            {key: "categoryInnerCreate", title: props.editMode ? I18N('updateCategory', language) : I18N('createCategory', language), onClick: props.onSave, color: "lightGreen", icon: "save"},
        ])

    }, [props.onSave])



    useEffect(() => {
        props.onNameUpdate(name);
    }, [name, props.onNameUpdate]);

    useEffect(() => {
        if(user === undefined){
            props.onUserUpdated('');
        }else{
            props.onUserUpdated(user.value as unknown as string);
        }
    }, [user, props.onUserUpdated]);


    useEffect(() => {

        if(selectedSubCompanies.length < 1){
            props.onCompanyUpdated([]);
        }else{
            props.onCompanyUpdated(selectedSubCompanies.map(((i) => i.value)));
        }

    }, [selectedSubCompanies, props.onCompanyUpdated]);


    const loadUsers = useCallback(async () => {

        const userList = await usersRepository.getAll();
        //@ts-ignore
        const filteredList = userList.filter((i) => {
            return i.groupNames.includes("Fachlicher Administrator")
        })

        //console.log(filteredList);
        setUsers(filteredList);
    }, []);

    const loadSubCompanies = useCallback( async () => {

        const subList = await companyRepository.getSubCompanies();
        setSubCompanies(subList);
    }, []);

    useEffect(() => {
        loadUsers().then(undefined);
        loadSubCompanies().then(undefined)
    }, [loadUsers]);



    return (
        <React.Fragment>
            <ViewBox>
                <TextField fullWidth={true}
                           label={I18N('name', language)}
                           className="marginTop20"
                           value={name}
                           onChange={(text) => setName(text.target.value)}
                />
                {/*<LTextField*/}
                {/*    fullWidth={true}*/}
                {/*    label={I18N('name', language)}*/}
                {/*    className="marginTop20"*/}
                {/*    value={name}*/}
                {/*    onChange={(text) => setName(text)}*/}
                {/*/>*/}
            </ViewBox>
            <ViewBox title={I18N('categoryUser', language)}>

                <Select styles={{  valueContainer: (styles) => {
                        return {
                            ...styles,
                            height: 50,
                            scrollbarWidth: "none",

                            msOverflowStyle: "none",
                            overflowX: "hidden",
                            overflowY: "scroll"
                        };
                    },}} theme={(template) => {
                    return {
                        ...template,
                        colors: {
                            ...template.colors,
                            ...colorForSelect(theme)
                        }
                    }
                }} key="userSelect" options={users.map((user) => { return {value: user.id, label: `${user.firstName} ${user.lastName} (${user.emailAddress})`}})} value={user} onChange={setUser} />
            </ViewBox>
            <ViewBox title={I18N('companies', language)}>

                <SelectAutocomplete multiple={true} maxTags={3} options={subCompanies.map((comp) => { return {value: comp.id, label: comp.name}})} value={selectedSubCompanies} onChange={(data) => setSelectedSubCompanies(data)} />


                {/*<Select styles={{*/}
                {/*    valueContainer: (styles) => {*/}
                {/*        return {*/}
                {/*            ...styles,*/}
                {/*            height: 50,*/}
                {/*            scrollbarWidth: "none",*/}

                {/*            msOverflowStyle: "none",*/}
                {/*            overflowX: "hidden",*/}
                {/*            overflowY: "scroll"*/}
                {/*        };*/}
                {/*    },*/}
                {/*}} theme={(template) => {*/}
                {/*    return {*/}
                {/*        ...template,*/}
                {/*        colors: {*/}
                {/*            ...template.colors,*/}
                {/*            ...colorForSelect(theme)*/}
                {/*        }*/}
                {/*    }*/}
                {/*}} isMulti={true} key="subCompanySelect" options={subCompanies.map((comp) => { return {value: comp.id, label: comp.name}})} value={selectedSubCompanies} onChange={(data, ) => setSelectedSubCompanies(data)} />*/}
            </ViewBox>

        </React.Fragment>

    )


}
