import {Drawer, Switch, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../ComplinessTheme";
import Select from "react-select/creatable";
import {OptionTypeBase} from "react-select/src/types";
import {UsersRepository} from "../../../Repositories/UsersRepository";
import {useLanguageState} from "../../../States/LanguageState";
import {I18N} from "../../../i18n/i18n";
import {SidebarContainer, SidebarDrawer} from "../../../ViewComponents/VideoEditComponents/Drawer";
import FormButton from "../../../ViewComponents/FormButton";
import {ProfileRepository} from "../../../Repositories/ProfileRepository";
import {DrawerConfigSx} from "../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../ViewComponents/ViewBox";

const profileRepository = new ProfileRepository();


const PersonalInfoBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, userInfo: any; onUpdate: () => void;}) => {
    const language = useLanguageState();
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);

    const [formState, setFormState] = useState<{allowEmails: boolean; firstName: string; lastName: string; email: string; phone: string; username: string}>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        username: '',
        allowEmails: false
    });

    useEffect(() => {

        setFormState({
            firstName: props.userInfo.firstName,
            lastName: props.userInfo.lastName,
            email: props.userInfo.email,
            phone: props.userInfo.phone,
            username: props.userInfo.username,
            allowEmails: props.userInfo.allowEmails
        })

    }, [props.userInfo]);


    const saveChanges = useCallback(async () => {

        setLoadingData(true);

        await profileRepository.updatePersonalInfo({...formState, receiveEmails: formState.allowEmails}).then((response) => {
            props.setIsOpen(false);
            props.onUpdate();
            return true;
        }).catch((err) => {
            console.log(err);
            return err;
        })


        setLoadingData(false);


    }, [formState]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx}  anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('updatePersonalInfo', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <TextField
                                fullWidth
                                label={I18N('username', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.username}
                                onChange={(text) => setFormState({...formState, username: text.target.value})}
                            />
                            <TextField
                                fullWidth
                                label={I18N('firstName', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.firstName}
                                onChange={(text) => setFormState({...formState, firstName: text.target.value})}
                            />

                            <TextField
                                fullWidth
                                label={I18N('lastName', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.lastName}
                                onChange={(text) => setFormState({...formState, lastName: text.target.value})}
                            />

                            <TextField
                                fullWidth
                                label={I18N('email', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.email}
                                onChange={(text) => setFormState({...formState, email: text.target.value})}
                            />

                            <TextField
                                fullWidth
                                label={I18N('phone', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.phone}
                                onChange={(text) => setFormState({...formState, phone: text.target.value})}
                            />

                            <div className="marginTop20" style={{width: '100%'}}>
                                <p className="viewBoxTitle">{I18N('allowEmails', language.language)}</p>

                            <Select theme={(template) => {
                                return {
                                    ...template,
                                    colors: {
                                        ...template.colors,
                                        ...colorForSelect(theme)
                                    }
                                }
                            }} className="fullWidthSelect marginTop10" styles={{control: base => ({
                                    ...base,
                                    height: 55,
                                    minHeight: 55
                                })}} key="statusSelect"
                                    isMulti={false}
                                    placeholder={I18N("receiveEmails", language.language)}
                                    options={[{value: 1, label: I18N('active', language.language)}, {value: 0, label: I18N("inActive", language.language)}]}
                                    value={formState.allowEmails ? {value: true, label: I18N('active', language.language)} : {value: false, label: I18N("inActive", language.language)}} onChange={(change) => {
                                setFormState({...formState, allowEmails: !!change.value})
                            }} />

                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default PersonalInfoBar;
