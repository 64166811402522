import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../../ViewComponents/ViewBox";


const phishingRepository = new PhishingRepository();

const GeneralContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void;}) => {
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({senderAddress: props.content.senderAddress, regarding: props.content.regarding, name: props.content.name, domain: {label: props.content.linkDomain, value: props.content.linkDomain}});

    const saveChanges = useCallback(() => {

        setLoadingData(true);

        phishingRepository.updateEmailGeneral({...formState, domain: formState.domain.value}, props.content.id).then((response) => {
            props.setContent(response);
            props.setIsOpen(false);
            setLoadingData(false);
        }).catch((e) => {
            console.log(e);
            setLoadingData(false);
        });

    }, [formState, props.content]);



    return (
        <SidebarContainer>
            <Drawer  BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <TextField
                                fullWidth
                                label={I18N('name', language)}
                                style={{ marginTop: 20 }}
                                value={formState.name}
                                onChange={(text) => setFormState({...formState, name: text.target.value})}
                            />


                            <TextField
                                fullWidth
                                label={I18N('regarding', language)}
                                style={{ marginTop: 20, marginBottom: 20 }}
                                value={formState.regarding}
                                onChange={(text) => setFormState({...formState, regarding: text.target.value})}
                            />

                            <TextField
                                fullWidth
                                label={I18N('senderAddress', language)}
                                style={{ marginBottom: 20 }}
                                value={formState.senderAddress}
                                onChange={(text) => setFormState({...formState, senderAddress: text.target.value})}
                            />



                            <div className="marginTop20">
                                <div className="viewBoxTitle">{I18N('linkDomain', language)}</div>

                                <NoCreateSelect  styles={{valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            height: 50,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },container: (styles) => {
                                        return {
                                            ...styles,
                                            width: '100%',
                                        };
                                    }}}
                                                 theme={(template) => {
                                                     return {
                                                         ...template,
                                                         colors: {
                                                             ...template.colors,
                                                             ...colorForSelect(theme)
                                                         }
                                                     }
                                                 }} value={formState.domain} placeholder={I18N('linkDomain', language)} options={[{value: 'inntrust.de', label: 'inntrust.de'}, {value: 'webaims.de', label: 'webaims.de'}]} onChange={(e) => setFormState({...formState, domain: e})} />



                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralContentBar;
