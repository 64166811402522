import React, {useCallback, useEffect, useState} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { EmailPruefung } from '../RouteComponents/EmailPruefung/EmailPruefung';
import { Konfiguration } from '../RouteComponents/Konfiguration/Konfiguration';
import { LearnVideos } from '../RouteComponents/LearnVideos/LearnVideos';
import { Passwordpruefung } from '../RouteComponents/Passwordpruefung/Passwordpruefung';
import { Profile } from '../RouteComponents/Profile/Profile';
import { Sidebar } from '../Sidebar/Sidebar';
import { DashboardView } from './DashboardView';
import {useLoginState} from "../States/UserState";
import ShowAllNotification from "../Notification/ShowAll";
import {getToken} from "../Repositories/Cookies";
import {SuperAdminEntry} from "../RouteComponents/Konfiguration/superadmin";
import {AnalyticsHome} from "../ContentAnalytics/AnalyticsHome";
import Documents from "../Documents/Documents";
import PhishingDashboard from "../Phishing/PhishingDashboard";
import SettingsSidebar from "../ViewComponents/SettingsSideBar/settings-sidebar";
import {useTheme} from "@mui/material";
import axios from "axios";
import {SuperAdminConfig} from "../RouteComponents/SuperAdmin/Configurations";
import ConfigurationRouter from "../RouteComponents/Konfiguration/ConfigurationRouter";
import FachWizardMain from "../WizardBox/FachWizard/FachWizardMain";
import TechWizardMain from "../WizardBox/TechWizard/TechWizardMain";
import MultiWizardMain from "../WizardBox/MultiWizard/MultiWizardMain";
import ReleaseNotes from "../ReleaseNotes/ReleaseNotes";
import {ReleaseNotesRepository} from "../Repositories/ReleaseNotesRepository";
import ShowInModal from "../ReleaseNotes/ShowInModal";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {useGlobalSettingsState} from "../States/GlobalSettings";
import ConfigGlobalPermissionCheck from "../Helpers/ConfigGlobalPermissionCheck";

const releaseNoteRepository = new ReleaseNotesRepository();
const companyRepository = new CompanyRepository();


type Props = {
    logout: () => void
    isMobile: () => boolean
}

type State = {
    width: number;
    height: number
}

export function Dashboard(props: Props) {
    const theme = useTheme<any>();
    const loginState = useLoginState();
    const clientName = getToken();
    const [isFachWizardOpen, setIsFachWizardOpen] = useState(false);
    const [isTechWizardOpen, setIsTechWizardOpen] = useState(false);
    const [isMultiWizardOpen, setIsMultiWizardOpen] = useState(false);
    const [latestReleaseNote, setLatestReleaseNote] = useState(null);
    const [showReleaseNote, setShowReleaseNote] = useState(false);
    const [releaseNoteMainData, setReleaseNoteMainData] = useState(null);
    const globalSettingsState = useGlobalSettingsState();
    const getContainerStyle = () => {
        return {  width: 'calc(100vw)', paddingLeft: 50, marginTop: 200, paddingRight: 50, border: '#000 2 solid' }
    }

    //Getting Global Settings
    useEffect(() => {
       companyRepository.getGlobalSettings().then((response) => {
            globalSettingsState.setSettings(response);
        }).catch((err) => {
            console.log("error loading global settings");
       });
    }, []);



    useEffect(() => {

        if(loginState){
            if(loginState.company !== null){
                axios.defaults.headers['x-auth-company'] = loginState.company;
            }
        }


    }, [loginState.company]);


    const getReleaseNotes = useCallback(async () => {

        const getLatest = await releaseNoteRepository.latestNote();
        if(getLatest["release_notes_data"] !== undefined){
            setLatestReleaseNote(getLatest['release_notes_data']);
            setReleaseNoteMainData(getLatest);
            const noteUserSeen = getLatest['release_note_seen_users'].findIndex((i) => {
                return i.user_id === loginState.data.sub
            });

            const doNotShowAgain = localStorage.getItem('doNotShowBox');
            if(noteUserSeen === -1){
                setShowReleaseNote(true);
                releaseNoteRepository.setUserSeen(getLatest['id'], loginState.data.sub);
            }else{
                if(!!!Number(doNotShowAgain)){
                    setShowReleaseNote(true);
                }
            }

        }

    },[]);

    useEffect(() => {

        getReleaseNotes().then(undefined).catch(undefined);

    }, []);

        return (
            <>
                <ShowInModal fromHomePage={true} editData={releaseNoteMainData} isOpen={showReleaseNote} onClose={() => setShowReleaseNote(false)} releaseNoteData={latestReleaseNote} />
                <BrowserRouter>
                    <div style={{...styles.container, color: theme.palette.color}}>
                        <FachWizardMain isOpen={isFachWizardOpen} setIsOpen={setIsFachWizardOpen} />
                        <TechWizardMain isOpen={isTechWizardOpen} setIsOpen={setIsTechWizardOpen} />
                        <MultiWizardMain isOpen={isMultiWizardOpen} setIsOpen={setIsMultiWizardOpen} />
                        <SettingsSidebar showReleaseNote={setShowReleaseNote} setIsFachWizardOpen={setIsFachWizardOpen} setIsTechWizardOpen={setIsTechWizardOpen} setIsMultiWizardOpen={setIsMultiWizardOpen} isMultiWizardOpen={isMultiWizardOpen} isFachWizardOpen={isFachWizardOpen} isTechWizardOpen={isTechWizardOpen}  />

                        <Route path="/Dashboard" render={() => <div ><DashboardView isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewLearningContent') && (
                            <Route path="/Lernvideos" render={() => <div ><LearnVideos isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewPasswordTextsTab') && (
                            <Route path="/Passwortpruefung" render={() => <div><Passwordpruefung isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}

                        {/* <Route path="/Benutzer" render={() => <div><User style={styles.routerContent} /></div>} /> */}
                        <Route path="/Profile" render={() => <div><Profile style={getContainerStyle()} isMobile={props.isMobile} /></div>} />

                        {loginState.token !== null && loginState.token !== undefined && ConfigGlobalPermissionCheck(loginState.token.resource_access[clientName].roles) && (
                            <Route path="/Konfiguration" render={() => <div><Konfiguration isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('superadmin') && (
                            <Route path="/superadmin" render={() => <div><SuperAdminEntry isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}
                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('superadmin') && (
                            <Route path="/superadminconfiguration" render={() => <div><SuperAdminConfig isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}

                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('superadmin') && (
                            <Route path="/releasenotes" render={() => <div><ReleaseNotes isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}

                        {loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('ViewEmailProofTab') && (
                            <Route path="/Emailpruefung" render={() => <div><EmailPruefung isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        )}
                        <Route path="/notifications" render={() => <div><ShowAllNotification style={getContainerStyle()}/></div>} />
                        <Route path="/contentAnalytics" render={() => <div><AnalyticsHome isMobile={props.isMobile} style={getContainerStyle()}/></div>} />
                        <Route path="/documents" render={() => <div><Documents isMobile={props.isMobile} style={getContainerStyle()} /></div>} />
                        <ErrorBoundary>
                            <Route path='/phishing' render={() => <div><PhishingDashboard style={getContainerStyle()} /></div>} />
                        </ErrorBoundary>
                    </div>
                </BrowserRouter>
            </>

        )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        // @ts-ignore
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

const styles = {
    container: { display: 'flex', flexDirection: 'row' } as React.CSSProperties,
    routerContent: { height: '100vh', border: '#000 2 solid' }
}
