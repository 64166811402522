import ViewBox from "../../ViewComponents/ViewBox";
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {TextField, useTheme} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import { EditorState, convertToRaw, ContentState, Modifier} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {PhishingRepository} from "../../Repositories/PhishingRepository";
import FullButton from "../../ViewComponents/FullButton";
import Select from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {BACKEND_URL} from "../../assets/AppConstants";
import ViewBoxTab from "../../ViewComponents/ViewBoxTab";

const phishingRepository = new PhishingRepository();
const videoRepository = new VideoRepository();

export default function EmailOperation(props: {
    editMode: null | any;
    onSaveRequest?: (data) => void;
    disableAll?: boolean;
    fromModal?: boolean;
    onClose?: () => void;
    fromModalUpdate?: (data) => void;
    closeCreate?: () => void;
}){

    const theme = useTheme();
    const language = useLanguageState(state => state.language);
    const [formState, setFormState] = useState({senderAddress: '', regarding: '', name: '', domain: undefined, html: '', tab: 'html'});
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [senderAddresses, setSenderAddresses] = useState([]);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();


    useEffect(() => {

        const senders = phishingRepository.getSenderAddresses().then((response) => {
            setSenderAddresses(response as any[]);
        })

    }, []);

    const saveChanges = useCallback(async () => {

        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const update = await phishingRepository.updateEmail({...formState, data: editorChanger}, props.editMode.id);
        if(props.fromModalUpdate){
            props.fromModalUpdate(update);
        }

    }, [editorState, formState, props]);

    useEffect(() => {

        if(props.editMode !== null){

            setFormState({
                senderAddress: props.editMode.senderAddress,
                regarding: props.editMode.regarding,
                name: props.editMode.name,
                domain: (props.editMode.linkDomain === undefined || props.editMode.linkDomain === null) ? undefined : {value: props.editMode.linkDomain, label: props.editMode.linkDomain},
                html: '',
                tab: 'html'
            });

            const htmltodraft = htmlToDraft(props.editMode.data);
            const contentState = ContentState.createFromBlockArray(htmltodraft.contentBlocks);
            const state = EditorState.createWithContent(contentState);
            setEditorState(state);
        }

    }, [props.editMode]);


    useEffect(() => {
        if(props.onSaveRequest){
            const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))
            props.onSaveRequest({name: formState.name, type: formState.tab, regarding: formState.regarding, senderAddress: formState.senderAddress, data: formState.tab === 'html' ? formState.html : editorChanger, domain: formState.domain});
        }
    }, [editorState, props.onSaveRequest, formState.name, formState.regarding, formState.senderAddress]);


    const createOrUpdateEmail = useCallback(async () => {


        formState.domain = formState.domain === undefined || formState.domain === null ? null : formState.domain.value;
        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const newData = {...formState, type: formState.tab, data: formState.tab === 'html' ? formState.html : editorChanger};

        await phishingRepository.createEmail(newData).then((response) => {
            if(props.closeCreate){
                props.closeCreate()
            }
        }).catch((e) => {
            console.log(e);
        })

    }, [formState, editorState]);


    useEffect(() => {
        if(!props.fromModal){
            breadCrumb.setChildren([
                {
                    color: "lightGreen",
                    onClick: () => {
                        createOrUpdateEmail();
                    },
                    icon: "save",
                    title: I18N('createEmailTemplate', language),
                }
            ]);
            breadCrumb.setBackButton(() => {
                if(props.closeCreate){
                    props.closeCreate();
                }
            });
            breadCrumb.setTitle(I18N('createEmail', language));
            breadCrumb.setCrumb([
                {
                    title: I18N('phishing', language),
                    onClick: () => history.push('/phishing/overview')
                },{
                    title: I18N('emailTemplates', language),
                    onClick: () => () => {
                        if(props.closeCreate){
                            props.closeCreate();
                        }
                    }
                }, ,{
                    title: I18N('createEmail', language),
                    onClick: () => history.push('/phishing/emailemplates')
                }])
        }
    }, [createOrUpdateEmail, props.fromModal]);



    return (
        <div>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{width: '60%', marginRight: 10}}>
                    <ViewBox title={I18N('dispatch', language)}>
                        <TextField
                            disabled={props.disableAll}
                            fullWidth
                            label={I18N('name', language)}
                            style={{ marginTop: 20 }}
                            value={formState.name}
                            onChange={(text) => setFormState({...formState, name: text.target.value})}
                        />


                        <TextField
                            disabled={props.disableAll}
                            fullWidth
                            label={I18N('regarding', language)}
                            style={{ marginTop: 20, marginBottom: 20 }}
                            value={formState.regarding}
                            onChange={(text) => setFormState({...formState, regarding: text.target.value})}
                        />

                        <TextField
                            disabled={props.disableAll}
                            fullWidth
                            label={I18N('senderAddress', language)}
                            style={{ marginBottom: 20 }}
                            value={formState.senderAddress}
                            onChange={(text) => setFormState({...formState, senderAddress: text.target.value})}
                        />

                        {/*<Select  theme={(template) => {*/}
                        {/*    return {*/}
                        {/*        ...template,*/}
                        {/*        colors: {*/}
                        {/*            ...template.colors,*/}
                        {/*            ...colorForSelect(theme)*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*}} styles={{control: base => ({*/}
                        {/*        ...base,*/}
                        {/*        height: 55,*/}
                        {/*        minHeight: 55,*/}
                        {/*        marginBottom: 20*/}
                        {/*    })}} value={formState.senderAddress === '' ? undefined : {label: formState.senderAddress, value: formState.senderAddress}} placeholder={I18N('senderAddress', language)} options={senderAddresses.map((i) =>{*/}
                        {/*    return  {label: i, value: i}*/}
                        {/*})} onChange={(e) => setFormState({...formState, senderAddress: e.value})} />*/}

                        <Select  theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} styles={{control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} value={formState.domain} placeholder={I18N('linkDomain', language)} options={[{value: 'inntrust.de', label: 'inntrust.de'}, {value: 'webaims.de', label: 'webaims.de'}]} onChange={(e) => setFormState({...formState, domain: e})} />


                    </ViewBox>
                    <ViewBox title={I18N('placeholderList', language)}>
                        <p>
                            {`{{contact.FIRSTNAME}}`} <b>{I18N('usersFirstname',language)}</b>
                        </p>
                        <p>
                            {`{{contact.LASTNAME}}`} <b>{I18N('usersLastname', language)}</b>
                        </p>
                        <p>
                            {`{{contact.LINK}}`} <b>{I18N('connectingToUserListLink', language)}</b>
                        </p>
                    </ViewBox>
                </div>
                <div style={{width: '90%', marginLeft: 10}}>
                    <ViewBox title={I18N("content", language)}>
                        {props.disableAll ? (
                            <div dangerouslySetInnerHTML={{__html: props.editMode.data}}></div>
                        ): (
                            <ViewBoxTab onTabChange={(tab) => {
                                setFormState({...formState, tab: tab === 0 ? 'html' : 'editor'})
                            }} tabs={[{
                                name: I18N('html', language),
                                content: (<div><textarea style={{background: theme.palette.mode === 'dark' && theme.palette.background.default, color: theme.palette.mode === 'dark' && '#fff', width: '100%'}} onChange={(e) => setFormState({...formState, html: e.target.value})} rows={20}  value={formState.html}/></div>)
                            }, {
                                name: I18N("editor", language),
                                content: (
                                    <Editor
                                        toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                                        readOnly={props.disableAll}
                                        editorState={editorState}
                                        toolbar={{
                                            image: {
                                                uploadEnabled: true,
                                                uploadCallback: async (e) => {
                                                    return await videoRepository.uploadPicture(e, true).then((response) => {
                                                        return { data: { link: BACKEND_URL + response } }
                                                    })
                                                },
                                                previewImage: true,
                                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                                                alt: { present: false, mandatory: false },
                                                defaultSize: {
                                                    height: 'auto',
                                                    width: 'auto',
                                                },
                                            },
                                        }}
                                        toolbarCustomButtons={[<Replacements language={language} />]}
                                        wrapperClassName="classEditorEmail"
                                        editorClassName="classEditorInner"
                                        onEditorStateChange={(data) => {
                                            setEditorState(data);
                                        }}
                                    />
                                )
                            }]} />


                        )}

                    </ViewBox>



                </div>
            </div>
            {props.fromModal && (
                <div style={{display: "flex", justifyContent: "center", flexDirection: "row"}}>
                    <div style={{width: '30%', alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex", margin: '25px auto'}}>
                        <FullButton text={I18N('cancel', language)} onClick={props.onClose} isLoading={false} buttonClass={'red'} />
                    </div>
                    <div style={{width: '65%', alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex", margin: '25px auto'}}>
                        <FullButton text={I18N('updateWName', language, [{key: 'name', value: I18N('email', language)}])} onClick={saveChanges} isLoading={false} buttonClass={'green'} />
                    </div>
                </div>
            )}
        </div>


    )
}


export const Replacements = (props: any) => {
    const [open, setOpen] = useState<boolean>(false);
    const addPlaceholder = (selectedPlaceholder): void => {
        const contentState = Modifier.replaceText(
            props.editorState.getCurrentContent(),
            props.editorState.getSelection(),
            selectedPlaceholder,
            props.editorState.getCurrentInlineStyle(),
        )
        const result = EditorState.push(props.editorState, contentState, 'insert-characters');
        if (props.onChange) {
            props.onChange(result);
        }
        setOpen(false);
    };

    return (
        <div className="rdw-image-wrapper" aria-label="rdw-block-control" role="button" tabIndex={0}>
            <div className="rdw-option-wrapper" onClick={() => setOpen(!open)}>
                <span>{I18N('addPlaceholder', props.language)}</span>
            </div>
            <div className={`rdw-image-modal ${open ? '' : 'placeholder-ul'}`} style={{width: 300, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                <div className="buttonTXPlaceholder" onClick={() => addPlaceholder("{{contact.FIRSTNAME}}")}>{`{{contact.FIRSTNAME}}`}</div>
                <div className="buttonTXPlaceholder" onClick={() => addPlaceholder("{{contact.LASTNAME}}")}>{`{{contact.LASTNAME}}`}</div>
                <div className="buttonTXPlaceholder" onClick={() => addPlaceholder("{{contact.LINK}}")}>{`{{contact.LINK}}`}</div>
            </div>
        </div>
    );
};
