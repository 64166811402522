import {I18N} from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../States/LanguageState";
import {useBreadCrumb} from "../States/BreadCrumbState";
import EditIcon from "../ViewComponents/EditIcon";
import GeneralInfoBar from "./Bars/GeneralInfoBar";
import plusLearningPlan from "../assets/plusLearning.svg";
import checkGreen from '../assets/checkGreen.svg';
import checkRed from '../assets/checkRed.svg';
import QuizQuestionBar from "./Bars/QuizQuestionBar";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import {QuizRepository} from "../Repositories/QuizRepository";
import {useSnackbar} from "notistack";

const quizRepository = new QuizRepository();

interface Props {
    editMode?: any;
    onFormChange: (state: formState) => void;
    backCallback: () => void;
    onFinish: () => void;
}

export interface formState {
    id: number,
    name: string,
    status: { label: string, value: number } | null;
    percentage: {label: string, value: number} | null;
    questions: {question: string, key?: number, id?: number, answers: {id?: number, answer: string, correct: boolean}[]}[];
    companies: {value: number, label: string}[] | null
}

export default function EditQuizContent(props: Props) {

    const {editMode} = props;
    const language = useLanguageState(state => state.language);
    const breadCrumb = useBreadCrumb();
    const [generalInfoOpen, setGeneralInfoOpen] = useState(false);
    const [clickedQuestion, setClickedQuestion] = useState(null);
    const [isQuestionOpen, setIsQuestionOpen] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    const [quizData, setQuizData] = useState(props.editMode);

    const [formState, setFormState] = useState<formState>({
        name: '',
        id: 0,
        status: null,
        percentage: null,
        companies: null,
        questions: [{
            key: 0,
            id: 0,
            question: '',
            answers: [
                {id: 0, answer: '', correct: false},
            ]
        }]
    });

    useEffect(() => {
        //setting form from state
        if(quizData !== null){

            setData(quizData);
        }

    }, [editMode, language, quizData]);


    const setData = useCallback((qData) => {

        setFormState({
            id: qData.id,
            name: qData.name,
            status: {value: qData.status, label: qData.status === 1 ? I18N('active', language): I18N('inactive', language)},
            percentage: {value: qData.percentage, label: qData.percentage + '%'},
            companies: qData.quiz_companies.map((i) => {return {
                label: i.company.name,
                value: i.company.id
            }}),
            questions: qData.quiz_questions.map((question,key) => {
                return {
                    id: question.id,
                    key: key,
                    question: question.question,
                    answers: question.question_answers.map((answer) => {
                        return {
                            id: answer.id,
                            answer: answer.answer,
                            correct: !!answer.correct
                        }
                    })
                }
            })
        })

    }, [language]);

    useEffect(() => {
        breadCrumb.setTitle(props.editMode ? props.editMode.name : I18N('createQuiz', language));
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? props.editMode.name : I18N('createQuiz', language),
            onClick: undefined
        });
        breadCrumb.setBackButton(props.backCallback);
        breadCrumb.setChildren([
            {
                color: 'transparent',
                key: 'quizOperationCreate',
                title: '',
                onClick: undefined
            }
        ])

    }, [language, props.backCallback, props.editMode, props.onFinish]);

    const loadSingleOne = useCallback(async () => {

        const data = await quizRepository.getWithID(quizData.id);

        setData(data);

    }, [quizData.id, setData]);

    const deleteQuestionFromQuiz = useCallback(async (questionData) => {

        const del = await quizRepository.deleteQuestion(formState.id, questionData.id);

        if (del instanceof Error) {
            //setLoading(false);
            enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            return;
        } else {
            if(del.status !== undefined && del.status === -1){
                //setLoading(false);
                return del;
            }
            await loadSingleOne().then(undefined);
            enqueueSnackbar(I18N('deleteSuccess', language, [{ key: 'name', value: I18N('quiz', language) }]), {variant: "success"});
        }

    }, [formState]);

    return (
        <React.Fragment>
        <GeneralInfoBar isOpen={generalInfoOpen} setIsOpen={setGeneralInfoOpen} quiz={formState} setContent={setQuizData} />
        <QuizQuestionBar isOpen={isQuestionOpen} setIsOpen={(status) => {
            setIsQuestionOpen(status);
            if(!status){
                setClickedQuestion(null)
            }
        }
        } quiz={formState} selectedQuestion={clickedQuestion} setContent={setQuizData} />
            <ViewBox title={I18N('generalInformation', language)} rightComponent={(<EditIcon onClick={() => setGeneralInfoOpen(true)} />)}>
                <table style={{borderSpacing: 8}}>
                    <th>
                        <td style={{width: 200}}></td>
                        <td></td>
                    </th>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('title', language)}
                        </td>
                        <td style={{fontSize: 14, fontWeight: "bold"}}>
                            {formState.name}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('percentage', language)}
                        </td>
                        <td>
                            <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{formState.percentage?.label}</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('companies', language)}
                        </td>
                        <td style={{fontSize: 14, display: "flex", flexDirection: "row"}}>

                            {formState.companies?.map((i)=> <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{i.label}</div>)}
                        </td>
                    </tr>
                    <tr>
                        <td style={{fontSize: 12}}>
                            {I18N('status', language)}
                        </td>
                        <td style={{fontSize: 14,}}>
                            <div style={{background: formState.status?.value ? '#25AF60' : "#c91313", paddingRight: 10, color: "#fff", paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 12, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{I18N(formState.status?.label, language)}</div>
                        </td>
                    </tr>
                </table>
            </ViewBox>


            <ViewBox title={I18N("quiz", language)}  rightComponent={(<div style={{cursor: "pointer"}} onClick={() => {
                setClickedQuestion(null);
                setIsQuestionOpen(true);
            }}><img src={plusLearningPlan} /></div>)} >

                {formState.questions.map((question, qKey) => {

                    return (
                        <div style={{marginTop: 25}}>
                            <div style={{borderBottom: '1px solid #E0E0E0', display: "flex", justifyContent: "space-between", flexDirection: "row", fontSize: 14, fontWeight: "350", color: "#000", marginBottom: 15}}>
                                <div>{qKey + 1}. {I18N('question', language)}</div>
                                <div style={{display: "flex", width: 60, justifyContent: "space-between"}}>
                                    <DeleteIcon size={10} callBack={deleteQuestionFromQuiz}  row={question} />
                                    <EditIcon onClick={() => {
                                        setClickedQuestion(question);
                                        setIsQuestionOpen(true);
                                    }} />
                                </div>

                            </div>

                            <div style={{padding: 10}}>
                                <p style={{fontSize: 12, fontWeight: 300, color: "#828282", margin: 0, padding:0}}>
                                    {I18N("question", language)}
                                </p>
                                <p style={{color: "#000000", fontWeight: "700",  fontSize: 14, margin: 5, padding: 0}}>
                                    {question.question}
                                </p>
                            </div>

                            {question.answers.map((answer, aKey) => (
                                <div style={{padding: 10}}>
                                    <p style={{fontSize: 12, color: "#828282", fontWeight: 300, padding: 0, margin:0}}>
                                        {aKey + 1}: {I18N('answer', language)}
                                    </p>
                                    <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", }}>
                                        <p style={{margin: 5, padding: 0, fontSize: 14, fontWeight: 350, color: "#000"}}>{answer.answer}</p>
                                        <p style={{padding: 0, display: "flex", alignItems: "center", margin: 5, fontSize: 12, fontWeight: 350, color: "#828282"}}>
                                            {I18N('correctAnswer', language)} {answer.correct ? <img style={{marginLeft: 15}} src={checkGreen} /> : <img style={{marginLeft: 15}} src={checkRed} />}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )
                })}

            </ViewBox>


        </React.Fragment>
    )
}
