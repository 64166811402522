import {AbstractRepository} from "./abstract-repository";
import axios from "axios";

export class DocumentsRepository implements AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return Promise.resolve(undefined);
    }

    getAll(): Promise<{availableDocuments: any, history: any[], exportHistory: any[], inWork: any, inWorkHistory: any[]}> {

        return new Promise((resolve, reject) => {

            axios.get('/documents').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    openInCrate(): Promise<{docID: string}>{
        return new Promise((resolve, reject) => {
            axios.post('/documents/createInOpen').then((response) =>{
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    exportPDF(data){

        return new Promise((resolve, reject) => {

            axios.post(`/documents/export`, data,{
                responseType: 'blob'
            }).then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `document.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                return resolve(response.data);

            }).catch((err) => {
                return reject(err);
            })

        });

    }

    export(){
        return new Promise((resolve, reject) => {

            axios.get('/documents/export').then(response => {
                return resolve(response.data)
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/documents', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    update(object: any): Promise<any> {
        return Promise.resolve(undefined);
    }



}
