import {TextField} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import DeeplyRequest from "../Repositories/DeplyRequest";
import TranslateSVG from '../assets/translate.svg';
import LoadingSVG from '../assets/smallLoader.svg';
import {useLanguageState} from "../States/LanguageState";
const availableLanguages = [
    'DE',
    'EN'
];

export default function LTextField(props: {
    value: string;
    onChange: (text: string) => void;
    label: string;
    style?: any
    fullWidth?: boolean;
    className?: string;
    values?: {key: string, value: string}[]
    sendInputStates?: (state) => void;
}){
    const [isLoading, setIsLoading] = useState(false);
    const languageState = useLanguageState((state) => state.language)
    // const [targetLanguage, setTargetLanguage] = useState('T');
    const [activeLanguage, setActiveLanguage] = useState(languageState.toUpperCase());

    const [inputStates, setInputStates] = useState(availableLanguages.map(lang => {
        return {key: lang, value: ''};
    }));

    console.log(inputStates);

    useEffect(() => {

        if(props.values){

            if(props.values.length === 0){
                setInputStates([{key: 'DE', value: props.value }]);
                console.log("setting 0 german");
            }else if(props.values.length === 1){
                setInputStates([{key: 'DE', value: props.value }])
                console.log("setting 1 german");
            }else{
                setInputStates(props.values);
            }
        }


    },[props.value]);


    useEffect(() => {

        if(inputStates.length < availableLanguages.length){

            availableLanguages.forEach((i) => {
                const index = inputStates.findIndex((a) => {
                    return a.key.toUpperCase() === i;
                });
                if(index === -1){
                    if(i === "DE"){
                        inputStates.push({key: i, value: props.value});
                    }else{
                        inputStates.push({key: i, value: ''});
                    }
                    setInputStates([...inputStates]);
                }
            });
        }

    }, [inputStates]);


    const onChange = useCallback( async (text: string, lang: string) => {

        const index = inputStates.findIndex((item) => item.key === lang);

        if(index === -1){
            return;
        }

        inputStates[index].value = text;

        setInputStates([...inputStates]);

        if(props.sendInputStates){
            props.sendInputStates([...inputStates]);
        }

    }, [inputStates, props.sendInputStates]);

    const translateAll = useCallback( async () => {

        setIsLoading(true);

        let text = '';

        text = inputStates.find((lang) => lang.key === activeLanguage).value;

        for (const aLang of availableLanguages) {
            if(aLang !== activeLanguage){
                const translation = await DeeplyRequest(text, aLang);
                if(aLang === 'DE'){
                    props.onChange(translation.text);
                    await onChange(translation.text, aLang);
                }else{
                    await onChange(translation.text, aLang);
                }
            }
        }



        setIsLoading(false);

    }, [activeLanguage, props.value, onChange]);

    return (
        <div className="languageInput" style={{width: '100%'}}>
            <ul className="languages">
                {availableLanguages.map((lang) => <li style={{fontWeight: activeLanguage === lang ? 'bold' : 'normal', color: activeLanguage === lang ? '#000' : '#9e9e9e'}} onClick={() => setActiveLanguage(lang)}>{lang}</li>)}
                <li style={{marginTop: isLoading ? -10 : 0}} onClick={() => translateAll()}>{isLoading ? <img src={LoadingSVG} width={30} height={30} /> : <img src={TranslateSVG} />}</li>
            </ul>
            <div className="inputFlex">
                <TextField
                    fullWidth={props.fullWidth}
                    className={props.className}
                    label={props.label}
                    style={props.style}
                    value={inputStates.find((item) => item.key === activeLanguage).value}
                    onChange={(event) => {
                        if(activeLanguage === 'DE'){
                            onChange(event.target.value, activeLanguage)
                        } else {
                            onChange(event.target.value, activeLanguage)
                        }
                    }
                    }
                />
            </div>
        </div>
    )

}
