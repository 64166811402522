import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../../../i18n/i18n";
import {useTheme} from "@mui/material";
import {useLanguageState} from "../../../States/LanguageState";
import {CompanyRepository} from "../../../Repositories/CompanyRepository";
import ReactApexChart from "react-apexcharts";


export default function EnvChart(props: {
    companies: any;
}){

    const theme = useTheme();
    const language = useLanguageState(state => state.language);
    const [companySeries, setCompanySeries] = useState([]);
    const [labels] = useState(["Test", "Production", "Intern", "Not Defined"]);


    const calculateData = useCallback((companyData: any) => {

        const calculations = [0, 0, 0, 0];


        companyData.forEach((company) => {

            if(company.environment === "test"){
                calculations[0] = calculations[0] + 1;
            }else if(company.environment === 'production'){
                calculations[1] = calculations[1] + 1;
            }else if(company.environment === 'intern'){
                calculations[2] = calculations[2] + 1;
            }else {
                calculations[3] = calculations[3] + 1;
            }
        });

        setCompanySeries(calculations);

    }, []);

    useEffect(() => {

        calculateData(props.companies);

    }, [calculateData, props.companies]);


    const settings = useCallback(() => {
        return {
            labels: labels,
            stroke: {
                colors: [theme.palette.mode === 'dark' ? '#FFE16A' : '#fff'],
            },
            plotOptions: {
                pie: {
                    donut: {
                        borderColor: theme.palette.mode === 'dark' ? '#FFE16A' : '#fff',
                        labels: {
                            show: true,
                            value: {
                                color: theme.palette.mode === 'dark' ? '#FFE16A' : '#000',
                            },
                            total: {
                                label: I18N('total', language),
                                show: true,
                                color: theme.palette.mode === 'dark' ? '#fff' : "#000"
                            }
                        },
                        size: '90%'
                    }
                }
            },
            legend: { show: false },
            dataLabels: {
                enabled: false
            }
        }

    }, [language, theme]);




    return (
        <ReactApexChart
            type="donut"
            options={settings()} series={companySeries}
            height={280}
        />
    )

}