import {Drawer} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {I18N} from "../../i18n/i18n";
import { EditorState, convertToRaw, ContentState, Modifier} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer, SidebarDrawerBig} from "../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../States/LanguageState";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";


const DataContentBar = (props: {isOpen: boolean, formStatus: number; setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void; updateOrCreateDocument: () => any;}) => {
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [editorState, setEditorState] = useState(props.content);


    const saveChanges = useCallback(() => {
        setLoadingData(true);
        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        props.setContent(editorChanger);
        props.updateOrCreateDocument();
        setLoadingData(false);
    }, [editorState, props]);

    // useEffect(() => {
    //     setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.content.data).contentBlocks)));
    // }, [props.content]);


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawerBig>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {props.formStatus === 2 && (<div className="disabledEditor"><p>{I18N('editorDisabled', language)}</p></div>)}
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

                                <div style={{width: '100%'}}>
                                    <Editor
                                        toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="contenteditor"
                                        onEditorStateChange={(data) => {
                                            if(editorState.status !== 2){
                                                setEditorState(data);
                                                props.setContent(data);
                                            }
                                        }}
                                    />
                                </div>

                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawerBig>
            </Drawer>
        </SidebarContainer>
    );
};

export default DataContentBar;