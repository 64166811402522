import {AbstractRepository} from "./abstract-repository";
import axios from "axios";

export class PasswordTextsRepository implements  AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete('/passwordtext/'+id).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAll(): Promise<any[]> {
        return new Promise((resolve, reject) => {

            axios.get('/passwordtext').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/passwordtext', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    //@ts-ignore
    update(object: any, code: string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/passwordtext/'+code, object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

}
