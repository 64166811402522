import {I18N} from "../i18n/i18n";
import React, {useEffect, useRef, useState} from "react";
import {useLanguageState} from "../States/LanguageState";
let dragCounter = 0;
export function DragNDrop(props: {
    openInput: () => void;
    onDrop: (files: File[]) => void;
}){

    const language = useLanguageState( state => state.language);
    const [dragging, setDragging] = useState(false);
    const dropRef = useRef<HTMLDivElement>();
    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation();
        console.log(e);
        dragCounter++
        //if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        //}
    }
    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--
        if (dragCounter === 0) {
            setDragging(false);
        }
    }
    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            props.onDrop(e.dataTransfer.files)
            e.dataTransfer.clearData()
            dragCounter = 0
        }
    }


    useEffect(() => {
        let div = dropRef.current
        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)
        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, []);

    return (

        <div ref={dropRef} className={`uploadBoxMultiple ${dragging && 'dragging'}`} onClick={() => props.openInput()}>
            <p>{I18N('clickHereToSelect', language)}</p>
        </div>

    )



}
