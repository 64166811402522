import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useLanguageState} from "../States/LanguageState";
// import EditSettingsBar from "./EditSettingsBar";
import {UsersRepository} from "../Repositories/UsersRepository";
import {PasswordQualityType, UserGroupsType} from "../Types/UserType";
import TableMui from "../ViewComponents/TableMui";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import {Chip, styled} from "@mui/material";
import HeaderItem from "./HeaderItem";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import Plus from '../assets/plusShape.svg';
import {HeadItem, PermissionCover, PermissionItem, PermissionName} from "./Components/TableComponents";
import Configuration from "./Permissions/Configuration";
import {ConfigurationsRepository} from "../Repositories/Configurations";
import Dashboard from "./Permissions/Dashboard";
import LearningContent from "./Permissions/LearningContent";
import PasswordCheck from "./Permissions/PasswordCheck";
import EmailCheck from "./Permissions/EmailCheck";
import Phishing from "./Permissions/Phishing";
import Analytics from "./Permissions/Analytics";
import CreateGroupBar from "./Bars/CreateGroup";
import PermissionEditBar from "./Bars/PermissionEdit";

type Props = {
    style: any;
    isMobile: () => boolean;
}

const userRepository = new UsersRepository();
const configurationRepository = new ConfigurationsRepository();

export function PermissionMatrix(props: Props) {

    const language = useLanguageState(state => state.language)
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [isPermissionEditOpen, setIsPermissionEditOpen] = useState(false);
    const [selectedPermission, setSelectedPermission] = useState(null);
    const breadCrumb = useBreadCrumb();
    const [loadingForm, setLoadingForm] = useState(false);
    const [editSetting, setEditSetting] = useState(false);
    const [groups, setGroups] = useState<UserGroupsType[]>([]);

    const [passwordQualities, setPasswordQualities] = useState<PasswordQualityType[]>([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [selected, setSelected] = useState('configuration');


    const permissionList = useCallback(async () => {
        setLoading(true);
        const permissionList: any = await configurationRepository.getPermissionList();

        setGroups(permissionList.filter((i) => !i.name.includes("Superadmin")));
        setLoading(false);
    }, []);

    useEffect(() => {
        permissionList().then(undefined);
    }, []);


    useEffect(() => {
        breadCrumb.setTitle(I18N('passwordQuality', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined},{
            title: I18N('passwordQuality', language),
            onClick: undefined
        }]);
        breadCrumb.setChildren([
            {
                color: 'transparent',
                key: 'passwordQualitySettings',
                title:  I18N("x", language),
                onClick: () => undefined,
                isLoading: loadingForm,
                icon: "save"
            }
        ])

    }, [language, loadingForm,]);



    const selectedGroupReturner = useCallback(() => {

        switch (selected){
            case "configuration": {
                return (<Configuration setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }
            case "dashboard": {
                return (<Dashboard setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }
            case "learnContent": {
                return (<LearningContent setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }
            case "passwordCheck": {
                return (<PasswordCheck setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }
            case "emailCheck": {
                return (<EmailCheck setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }
            case "phishing": {
                return (<Phishing setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }
            case "statistics": {
                return (<Analytics setSelectedPermission={(permission) => {
                    setSelectedPermission(permission);
                    setIsPermissionEditOpen(true);
                }
                } groupList={groups} />)
            }


        }

    }, [groups, selected]);


    return (
        <React.Fragment>
            <CreateGroupBar isOpen={isCreateOpen} setIsOpen={setIsCreateOpen} groups={groups} setContent={() => {
                permissionList();
            }
            } />
            <PermissionEditBar permission={selectedPermission} isOpen={isPermissionEditOpen} setIsOpen={setIsPermissionEditOpen} groups={groups} setContent={() => {
                permissionList();
            }
            } />
           <div style={{background: "#F4F6F8", width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", height: 50, borderTopRightRadius: 8, borderTopLeftRadius: 8}}>
               <div style={{width: '85%', paddingLeft: 10, paddingRight: 10, paddingTop: 10}}>
                   <Swiper freeMode slidesPerView="auto" spaceBetween={30}>
                       <SwiperSlide style={{maxWidth: 150, width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("configuration")} title={I18N("configuration", language)} active={selected === "configuration"} />
                       </SwiperSlide>
                       <SwiperSlide style={{maxWidth: 150,  width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("dashboard")} title={I18N("dashboard", language)} active={selected === "dashboard"} />
                       </SwiperSlide>
                       <SwiperSlide style={{maxWidth: 150,  width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("learnContent")} title={I18N("learnContent", language)} active={selected === "learnContent"} />
                       </SwiperSlide>
                       <SwiperSlide style={{maxWidth: 150,  width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("passwordCheck")} title={I18N("passwordCheck", language)} active={selected === "passwordCheck"} />
                       </SwiperSlide>
                       <SwiperSlide style={{maxWidth: 150,  width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("emailCheck")} title={I18N("emailCheck", language)} active={selected === "emailCheck"} />
                       </SwiperSlide>
                       <SwiperSlide style={{maxWidth: 150,  width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("phishing")} title={I18N("phishing", language)} active={selected === "phishing"} />
                       </SwiperSlide>
                       <SwiperSlide style={{maxWidth: 150,  width: "auto"}}>
                           <HeaderItem onClick={() => setSelected("statistics")} title={I18N("statistics", language)} active={selected === "statistics"} />
                       </SwiperSlide>
                   </Swiper>
               </div>



                <div style={{width: '15%', fontSize: 14, textAlign: "center", alignItems: "center", justifyContent: "center", alignSelf: "center", fontWeight: "bold", cursor: "pointer"}} onClick={() => setIsCreateOpen(true)}>
                   <img src={Plus} style={{marginRight: 10}} /> {I18N("addNewRole", language)}
                </div>
            </div>
            <div style={{background: "#F4F6F8", width: '100%', display: "flex", flexDirection: "row", justifyContent: "space-between", height: 60, borderTopRightRadius: 8, borderTopLeftRadius: 8}}>

                <div style={{width: '100%', marginLeft: 170, paddingLeft: 10, paddingRight: 10, paddingTop: 10}}>
                    <Swiper freeMode slidesPerView="auto" spaceBetween={30}>
                        <SwiperSlide style={{width: 140, maxWidth: 150}}>
                            <HeadItem title={I18N("seeOwn", language)} />
                        </SwiperSlide>
                        <SwiperSlide style={{width: 140, maxWidth: 150}}>
                            <HeadItem title={I18N("see", language)} />
                        </SwiperSlide>
                        <SwiperSlide style={{width: 140, maxWidth: 150}}>
                            <HeadItem title={I18N("create", language)} />
                        </SwiperSlide>
                        <SwiperSlide style={{width: 140, maxWidth: 150}}>
                            <HeadItem title={I18N("edit", language)} />
                        </SwiperSlide>
                        <SwiperSlide style={{width: 140, maxWidth: 150}}>
                            <HeadItem title={I18N("delete", language)} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "row"}}>

                {selectedGroupReturner()}

            </div>
        </React.Fragment>


    )

}
