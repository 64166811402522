import React from "react";

export const PermissionName = (props:any) => {
    return (
        <div onClick={props.onClick} style={{cursor: "pointer", width: '100%', height: 46, color: "#212B36", fontSize: 13, paddingLeft: 14, lineHeight:'46px', fontWeight: "bold", borderBottom: '1px solid rgba(145, 158, 171, 0.24)'}}>
            {props.title}
        </div>
    )
}

export const PermissionCover = (props: any) => {
    return (

        <div style={{width: 350}}>
            {props.children}
        </div>
    )
}

export const HeadItem = (props) => {
    return (
        <div style={{width: 'auto', maxWidth: 150, background: "#F4F6F8", color: "#637381", height: 45, fontWeight: 600, fontSize: 13, lineHeight:'46px'}}>
            {props.title}
        </div>
    )
}

export const PermissionItem = (props: any) => {
    return (
        <div style={{width: '100%', display: "flex", flexDirection: "row", alignItems: "center",  height: 45.09, color: "#212B36",  lineHeight:'46px', fontWeight: 400, borderBottom: '1px solid rgba(145, 158, 171, 0.24)'}}>
            {props.children}
        </div>
    )
}