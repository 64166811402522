import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import { EditorState, convertToRaw, ContentState, Modifier} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer, SidebarDrawerBig} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import ViewBox from "../../../../ViewComponents/ViewBox";
import {Replacements} from "../../../Templates/EmailOperation";
import ViewBoxTab from "../../../../ViewComponents/ViewBoxTab";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";


const phishingRepository = new PhishingRepository();

const DataContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void;}) => {
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [formState, setFormState] = useState({image: '', html: '', type: 'html'});

    const saveChanges = useCallback(() => {

        setLoadingData(true);
        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const data = formState.type === 'editor' ? editorChanger : formState.html;
        phishingRepository.updateLandingData({...formState, data: data}, props.content.id).then((response) => {
            props.setContent(response);
            props.setIsOpen(false);
            setLoadingData(false);
        }).catch((er) => {
            setLoadingData(false);
            console.log(er);
        })


    }, [props.content, editorState, formState]);

    useEffect(() => {
        if(props.content.type !== 'html'){
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(props.content.data).contentBlocks)));
        }
        setFormState({
            image: props.content.image ?? '',
            html: props.content.type === 'html' ? props.content.data : '',
            type: props.content.type
        });
    }, [props.content]);



    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawerBig>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {props.content.type === 'editor' ? (
                                <div style={{marginTop: 5}}>
                                    <Editor
                                        toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                                        editorState={editorState}
                                        wrapperClassName={"classEditorEmail"}
                                        editorClassName={ "classEditorInner"}
                                        onEditorStateChange={(data) => {
                                            setEditorState(data);
                                        }}
                                    /></div>
                            ): (
                                <div><textarea onChange={(e) => setFormState({...formState, html: e.target.value})} style={{width: '100%'}} rows={20} value={formState.html} /></div>
                            )}


                        </div>
                    </ViewBox>

                </SidebarDrawerBig>
            </Drawer>
        </SidebarContainer>
    );
};

export default DataContentBar;
