import crypto from 'crypto';
import axios from 'axios';
import { environment } from '../../environment/environment';


type passwordCheckRes = {
    data: string
}
const urls = {
    passwordUrl: 'https://api.pwnedpasswords.com/',
    emailUrl: 'https://haveibeenpwned.com/',
}
export class PWNEDRepository {

    checkForPassword(password: string): Promise<number> {

        const pswHash = this.createHashSha1(password.trim())

        return new Promise((resolve, reject) => {
            axios.get(urls.passwordUrl + 'range/' + pswHash.substring(0, 5), {transformRequest: (data, headers) => {
                console.log(headers);
                    delete headers['Authorization'];
                    delete headers['x-auth-company']
                    return data;
                }}).then((res: passwordCheckRes) => {
                let datas = res.data.split('\n')
                console.log(res);
                let pwnedCount = 0
                datas.forEach(hash => {
                    if (hash.includes(pswHash.substring(5, pswHash.length).toUpperCase())) {
                        pwnedCount = Number(hash.split(':')[1])
                    } else {

                    }
                })
                console.log(pwnedCount);
                resolve(pwnedCount)
            })
        })
    }

    checkForEmail(email: string): Promise<{status: boolean, data: { Name: string, BreachDate: string, DataClasses: string[] }[]}> {

        return new Promise((resolve, reject) => {
            axios.post('/emailproof', {email: email}).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    private createHashSha1(password: string) {
        return crypto.createHash('sha1')
            .update(password)
            .digest('hex');
    }
}

