import React, {useCallback, useEffect, useState} from 'react';
import {LearningPlanRepository} from "../Repositories/LearningPlanRepository";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import TableMui from "../ViewComponents/TableMui";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import {PaginationTypes} from "../Types/PaginationTypes";
import {LearningPlanType} from "../Types/LearningPlanTypes";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";

const learningPlansRepository = new LearningPlanRepository();

interface Props {

    setEditMode: (item: any) => void;
    deleteListItem: (item) => void;
}

export default function LearningPlanList(props: Props){

    const [plans, setPlans] = useState<PaginationTypes<LearningPlanType>>(undefined);
    const language = useLanguageState(state => state.language);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderType, setOrderType] = useState(null);
    const [filter, setFilter] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);

    const loadPlans = useCallback(async () => {
        if(!waitingForPage) {
            setLoading(true);
            const getPlans = await learningPlansRepository.getAll({page: page, limit: limit, filter, orderType});
            setPlans(getPlans as PaginationTypes<LearningPlanType>);
            setLoading(false);
        }
    }, [page, limit, filter, orderType, waitingForPage]);


    useEffect(() => {
        loadPlans().then(undefined);
    }, [loadPlans]);

    const deleteLearningPlan = useCallback(async (item) => {

        await props.deleteListItem(item);
        await loadPlans()

    }, [loadPlans, props]);

    const tableColumnsMui = useCallback(() => {


        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'description',
                name: I18N('description', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return value;
                }
            },
            {
                field: 'department_departmentTolearning_plans.name',
                name: I18N('departmentSelection', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    return row.depList.map((i) => i.name).join(', ');
                }
            },
            {
                field: 'status',
                name: I18N('status', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return value ===  1 ? 'Active' : 'Deactivated';
                }
            },
            {
                field: 'actions',
                width: 100,
                disableReorder: true,
                disableColumnMenu: true,
                filterable: false,
                sortable: false,
                name: I18N('actions', language),
                renderCell: (params: any) => {
                    return <DeleteIcon row={params.row} callBack={deleteLearningPlan} ask={true} />;
                }
            },
        ];

    }, [language]);

    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);


    return (
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    tableName="learningPlanListTable"
                    setWaitingForPage={setWaitingForPage}
                    page={page}
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={plans?.data !== undefined ? plans.data : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT
                        if(event.target.dataset.field !== "actions"){
                            props.setEditMode(data.row);
                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={plans?.total}
                />
            </div>
    )

}
