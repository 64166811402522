import React, {useCallback, useEffect, useState} from "react";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import QuizOperation , { formState } from "./QuizOperation";
import QuizList from "./QuizList";
import {QuizRepository} from "../Repositories/QuizRepository";
import {useBreadCrumb} from "../States/BreadCrumbState";
import EditQuizContent from "./EditQuizContent";
import {useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";

const quizRepository = new QuizRepository();


export default function QuizTab() {
    const language = useLanguageState(state => state.language);
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [createMode, setCreateMode] = useState(false);
    const breadCrumb = useBreadCrumb();
    const {enqueueSnackbar} = useSnackbar();
    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                onReturnBackToView();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    const [formState, setFormState] = useState<formState>({
        name: '',
        status: null,
        percentage: null,
        companies: null,
        questions: [{
            question: '',
            answers: [
                {answer: '', correct: false},
            ]
        }]
    })

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const backToListsTable = useCallback(() => {

        setCreateMode(false);
        setSelectedQuiz(null);
        scrollTop();
    }, [scrollTop]);





    const updateOrCreate = useCallback(async () => {

        if(formState.percentage === null || formState.status === null || formState.companies === null){
            enqueueSnackbar(I18N('pleaseFill', language, [{key: 'name', value: I18N('percentage', language)}]), {variant: "error"})

            return
        }

        const form = JSON.parse(JSON.stringify(formState));
        form.percentage = form.percentage.value;
        form.status = form.status.value;
        form.companies = form.companies.map((i) => i.value);

        if(selectedQuiz === null){
            await quizRepository.post(form).then(async () => {

                enqueueSnackbar(I18N('successfullyCreatedReturning', language, [{key: 'name', value: I18N('quiz', language)}]), {variant: "success"})

                onReturnBackToView();
            }).catch(async () => {

                enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})

            })
        }else{
            await quizRepository.update(form, selectedQuiz.id).then(async () => {

                enqueueSnackbar(I18N('successfullyUpdatedReturning', language, [{ key: 'name', value: I18N('quiz', language) }]), {variant: "success"})

                onReturnBackToView();
            }).catch(async (err) => {

                enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})
            })
        }


    }, [formState, selectedQuiz]);


    const onReturnBackToView = useCallback(() => {

        breadCrumb.setBackButton(null);
        breadCrumb.setTitle(I18N('quizEditor', language))
        breadCrumb.setCrumb([{title: I18N("configuration", language), onClick: undefined}, {
            title: I18N('quizEditor', language),
            onClick: onReturnBackToView
        }]);
        breadCrumb.setChildren([
            {
                key: "quizlistcreate",
                title: I18N('new', language),
                onClick: () => {
                    setCreateMode(!createMode);
                    scrollTop();
                },
                color: 'dark',
                icon: "plus"
            }
        ])
        backToListsTable();
    }, [language]);

    useEffect(() => {
        onReturnBackToView();
    }, []);

    return (
        <React.Fragment>
                <React.Fragment>
                    {!selectedQuiz && !createMode ? (
                        <QuizList setEditMode={(item) => {
                            setSelectedQuiz(item);
                            setCreateMode(true);
                            scrollTop();
                        }} />
                    ) : (
                        <>
                            {selectedQuiz ? (
                                <EditQuizContent backCallback={onReturnBackToView} onFinish={updateOrCreate} onFormChange={setFormState} editMode={selectedQuiz} />
                            ): (
                                <QuizOperation backCallback={onReturnBackToView} onFinish={updateOrCreate} onFormChange={setFormState} editMode={selectedQuiz} />
                            )}
                        </>

                    )}
                </React.Fragment>

        </React.Fragment>
    )

}
