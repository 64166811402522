import Icon from '../Icon';
import SvgIcons from '../Icon/SvgIcons';

interface IContainer {
    title: string;
    icon?: string;
    active?: boolean;
    onClick?: () => void;
}

const Container = ({ title, icon, active, onClick }: IContainer) => {
    return (
        <div className="card" onClick={onClick} role="button" tabIndex={0}>
            {active && (
                <span className="active-icon">
          <Icon color="#4DAA51" background="#98F1C1" name="CheckCircleIcon" />
        </span>
            )}
            {icon && <SvgIcons icon={icon} />}
            <p>{title}</p>
        </div>
    );
};
export default Container;
