import {I18N} from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useEffect, useState} from "react";
import {useLanguageState} from "../States/LanguageState";
import Select from "react-select";
import FormButton from "../ViewComponents/FormButton";
import {Switch, TextField, useTheme} from "@mui/material";
import CheckedIcon from '../assets/checked.svg';
import NotChecked from '../assets/notchecked.svg';
import {colorForSelect} from "../ComplinessTheme";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {useBreadCrumb} from "../States/BreadCrumbState";
import SelectAutocomplete from "../ViewComponents/SelectAutoComplete";
const companyRepository = new CompanyRepository();
interface Props {
    editMode?: any;
    onFormChange: (state: formState) => void;
    backCallback: () => void;
    onFinish: () => void;
}

export interface formState {
    name: string,
    status: { label: string, value: number } | null;
    percentage: {label: string, value: number} | null;
    questions: {question: string, id?: number, answers: {id?: number, answer: string, correct: boolean}[]}[];
    companies: {value: number, label: string}[] | null
}

const passPercentages = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];


export default function QuizOperation(props: Props) {

    const {editMode, onFormChange} = props;
    const [companies, setCompanies] = useState([]);
    const language = useLanguageState(state => state.language);
    const breadCrumb = useBreadCrumb();
    const theme = useTheme();
    const [formState, setFormState] = useState<formState>({
        name: '',
        status: null,
        percentage: null,
        companies: null,
        questions: [{
            question: '',
            answers: [
                {answer: '', correct: false},
            ]
        }]
    });

    useEffect(() => {
        companyRepository.getSubCompanies().then((response) => {
            setCompanies(response);
        }).catch(e => {
        })
    }, []);


    useEffect(() => {
        //setting form from state
        if(editMode !== null){

            setFormState({
                name: editMode.name,
                status: {value: editMode.status, label: editMode.status === 1 ? I18N('active', language): I18N('inactive', language)},
                percentage: {value: editMode.percentage, label: editMode.percentage + '%'},
                companies: editMode.quiz_companies.map((i) => {return {
                    label: i.company.name,
                    value: i.company.id
                }}),
                questions: editMode.quiz_questions.map((question) => {
                    return {
                        id: question.id,
                        question: question.question,
                        answers: question.question_answers.map((answer) => {
                            return {
                                id: answer.id,
                                answer: answer.answer,
                                correct: !!answer.correct
                            }
                        })
                    }
                })
            })
        }

    }, [editMode, language]);


    useEffect(() => {
        onFormChange(formState);
    }, [formState, onFormChange]);

    useEffect(() => {
        breadCrumb.setTitle(props.editMode ? props.editMode.name : I18N('createQuiz', language));
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? props.editMode.name : I18N('createQuiz', language),
            onClick: undefined
        });
        breadCrumb.setBackButton(props.backCallback);
        breadCrumb.setChildren([
            {
                color: 'lightGreen',
                key: 'quizOperationCreate',
                title: props.editMode ? I18N('updateQuiz', language) : I18N("createQuiz", language),
                onClick: props.onFinish,
                icon: "save"
            }
        ])

    }, [language, props.backCallback, props.editMode, props.onFinish]);


    return (
        <React.Fragment>

                <ViewBox title={I18N('generalInformation', language)}>
                    <div className="flex-row-input">
                        <TextField
                            fullWidth
                            label={I18N("name", language)}
                            style={{ marginTop: 20, minWidth: '40%' }}
                            className="marginRight10"
                            value={formState.name}
                            onChange={(text) => setFormState({...formState, name: text.target.value})}
                        />

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop10 marginLeft10" styles={{control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} key="percentageSelect"
                                isMulti={false}
                                placeholder={I18N("passPercentage", language)}
                                options={passPercentages.map((percentage) => { return {value: percentage, label: `${percentage}%`}})}
                                value={formState.percentage} onChange={(change) => {
                                setFormState({...formState, percentage: change})
                        }} />

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} styles={{control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}}
                                className="fullWidthSelect marginTop10 marginLeft10"
                                placeholder={I18N("status", language)}
                                key="statusSelect"
                                isMulti={false}
                                options={[{value: 1, label: I18N("active", language)}, {value: 0, label: I18N("inActive", language)}]}
                                value={formState.status}
                                onChange={(change) => {
                                setFormState({...formState, status: change})
                        }} />



                        <SelectAutocomplete multiple={true} size={'55px'} maxTags={2} options={companies.map((i) => {
                            return {
                                label: i.name, value: i.id
                            }
                        })} value={formState.companies ?? []} onChange={(change) => setFormState({...formState, companies: change as any})} />


                        {/*<Select theme={(template) => {*/}
                        {/*    return {*/}
                        {/*        ...template,*/}
                        {/*        colors: {*/}
                        {/*            ...template.colors,*/}
                        {/*            ...colorForSelect(theme)*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*}} styles={{ valueContainer: (styles) => {*/}
                        {/*        return {*/}
                        {/*            ...styles,*/}
                        {/*            height: 50,*/}
                        {/*            scrollbarWidth: "none",*/}

                        {/*            msOverflowStyle: "none",*/}
                        {/*            overflowX: "hidden",*/}
                        {/*            overflowY: "scroll"*/}
                        {/*        };*/}
                        {/*    },control: base => ({*/}
                        {/*        ...base,*/}
                        {/*        height: 55,*/}
                        {/*        minHeight: 55*/}
                        {/*    })}}*/}
                        {/*        className="fullWidthSelect marginTop10 marginLeft10"*/}
                        {/*        placeholder={I18N("company", language)}*/}
                        {/*        key="statusSelect"*/}
                        {/*        isMulti={true}*/}
                        {/*        options={companies.map((i) => {*/}
                        {/*            return {*/}
                        {/*                label: i.name, value: i.id*/}
                        {/*            }*/}
                        {/*        })}*/}
                        {/*        value={formState.companies}*/}
                        {/*        onChange={(change) => {*/}
                        {/*            setFormState({...formState, companies: change as any})*/}
                        {/*        }} />*/}

                    </div>
                </ViewBox>

            <ViewBox title={I18N('questions', language)}>
                <FormButton text={I18N('newQuestion', language)} onClick={() => {

                    setFormState({...formState, questions: [...formState.questions, {question: '', answers: [{answer: '', correct: false}]}]});

                }} isLoading={false} />

                {formState.questions.map((question, qKey) => {

                    return (
                        <div className="step">
                            <div className="stepName">
                                <span style={{background: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff'}}> {qKey + 1}. {I18N('question', language)}</span>
                            </div>
                            <div className="">
                                <div style={{width: '100%', flexWrap: "wrap",}}>
                                    <TextField
                                        fullWidth
                                        label={I18N("question", language)}
                                        style={{ marginTop: 20, minWidth: '60%' }}
                                        className="marginRight10"
                                        value={question.question}
                                        onChange={(text) => {
                                            formState.questions[qKey].question = text.target.value;
                                            setFormState({...formState, questions: [...formState.questions]})
                                        }}
                                    />
                                </div>
                                <div style={{marginBottom: 20}}>
                                    {question.answers.map((answer, aKey) => (
                                        <div style={{width: '100%', marginBottom: 5}}>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                                <TextField
                                                    fullWidth
                                                    label={`${aKey + 1}. ${I18N("answer", language)}`}
                                                    style={{ marginTop: 20, minWidth: '60%' }}
                                                    className="marginRight10"
                                                    value={answer.answer}
                                                    onChange={(text) => {
                                                        formState.questions[qKey].answers[aKey].answer = text.target.value;
                                                        setFormState({...formState, questions: [...formState.questions]})
                                                    }}
                                                />
                                                <div style={{width: '20%', paddingTop: 40}}>
                                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", fontSize: 12}}> Richtige Antwort? <Switch color="success" checked={answer.correct} onChange={(event, checked) => {
                                                        formState.questions[qKey].answers[aKey].correct =  checked;
                                                        setFormState({...formState, questions: [...formState.questions]});
                                                    }} checkedIcon={<img src={CheckedIcon} />} icon={<img src={NotChecked} />} className="marginTopMinus10" /></div>

                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <FormButton isLeft={true} text={I18N('newAnswer', language)} onClick={() => {

                                    formState.questions[qKey].answers.push({
                                        answer: '',
                                        correct: false
                                    })

                                    setFormState({...formState, questions: [...formState.questions]});

                                }} isLoading={false} />
                            </div>
                        </div>

                    )

                })}

            </ViewBox>

        </React.Fragment>
    )
}
