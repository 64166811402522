import React, {useCallback, useEffect, useState} from 'react';
import ClientList from "./ClientList";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import LogsTab from "../Logs/LogsTab";

type Props = {
    style: any;
    isMobile: () => boolean
    onDone: () => void;
}



export function ClientListLogs(props: Props) {
    const [editClient, setEditClient] = useState<null | any>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);


    const backToClientsTable = () => {
        setEditClient(null);
        scrollTop();
    }


    return (
        <div style={props.style}>
            <div className="flex-row-button">
                {!editClient && (<FormButton text={(I18N("back", language))} onClick={() => {
                    if (editClient) {
                        backToClientsTable();
                    }
                    props.onDone();
                    scrollTop();
                }} isLoading={formLoading} withLoadingInFullScreen={true} />)}
            </div>

            {!editClient ?
                <ClientList doNotShowDelete={true} setLoadingMode={setFormLoading} editMode={(item) => {
                    setEditClient(item);
                    scrollTop();
                }} />
                : (
                    <LogsTab client={editClient} onDone={backToClientsTable} style={{}} isMobile={() => false} />
                )}
        </div>
    )

}
