import {calculateContentProgress} from "./calculateContentProgress";

export function TrophyCalculator(props: {contentData: any}){

    const trophyList = [];
    props.contentData.forEach((content) => {

        const selectTrophyForContent = content.allTrophies.find((trop) => {
            return trop.id === content.trophyID;
        })
        const findUserTrophyForContent = content.trophyData.find((trophy) => {
            return trophy.trophy_id === content.trophies.id;
        });

        //WHEN TROPHY IS NOT DEFINED IN ARRAY
        if(trophyList.findIndex((trop) => trop.name === content.trophies.name) === -1){

            //WHEN TROPHY IS ASSGINED TO USER ITS 100%
            if(findUserTrophyForContent){

                trophyList.push({
                    user_id: findUserTrophyForContent.user_id,
                    trophy_id: content.trophies.id,
                    name: content.trophies.name,
                    awarded: true,
                    progress: 100
                })

            }else{
                let progress = 0;
                if(content.user_content_data.length > 0){
                    const progressOfContent = calculateContentProgress(content.user_content_data[0]);

                    if(progressOfContent !== 0){
                        progress = progressOfContent / selectTrophyForContent.videos.length;
                    }


                    trophyList.push({
                        user_id: undefined,
                        trophy_id: content.trophies.id,
                        name: content.trophies.name,
                        awarded: false,
                        progress: progress
                    })

                }else{

                    if(content.seen_videos.length > 0) {
                        trophyList.push({
                            user_id: undefined,
                            trophy_id: content.trophies.id,
                            name: content.trophies.name,
                            awarded: false,
                            progress: 100 / selectTrophyForContent.videos.length
                        })
                    }else{
                        trophyList.push({
                            user_id: undefined,
                            trophy_id: content.trophies.id,
                            name: content.trophies.name,
                            awarded: false,
                            progress: progress
                        })
                    }



                }


            }


        }else{
         //WHEN TROPHY IS DEFINED IN ARRAY

                const selectTrophy = trophyList.findIndex((trop) => trop.name === content.trophies.name);
                if(!trophyList[selectTrophy].awarded){

                    let progress = trophyList[selectTrophy].progress;
                    let progressNew = 0;
                    if(content.user_content_data.length > 0){
                        const progressOfContent = calculateContentProgress(content.user_content_data[0]);

                        if(progressOfContent !== 0){
                            progressNew = progressOfContent / selectTrophyForContent.videos.length;
                        }


                        trophyList[selectTrophy] = {
                            user_id: undefined,
                            trophy_id: content.trophies.id,
                            name: content.trophies.name,
                            awarded: false,
                            progress: progress + progressNew
                        }

                    }else{

                        if(content.seen_videos.length > 0){
                            progressNew = 100 / selectTrophyForContent.videos.length;
                            trophyList[selectTrophy] = {
                                user_id: undefined,
                                trophy_id: content.trophies.id,
                                name: content.trophies.name,
                                awarded: false,
                                progress: progress + progressNew
                            }


                        }
                    }


                }


        }


    });

    return trophyList;
}
