import axios from "axios";

type Translations = {
    detected_source_language: string,
    text: string
}

export default async function DeeplyRequest(text: string, targetLanguage: string){

    return await axios.post<Translations>(`/languages/translate`, {text: text, target: targetLanguage}).then((response) => {
        return response.data;
    });

}
