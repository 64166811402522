import moment from "moment/moment";

export function CalculateDepartmentAnalyticsData(watchList, videos){

    if(watchList === null){
        return [];
    }
    return watchList.map((departmentData) => {
        const totalUsers = departmentData.users.length;
        let totalVideos = departmentData.video_departments.length;

        videos.forEach((video) => {
            if(departmentData.video_departments.findIndex((videoDep) => videoDep.video_id === video.id) === -1){
                totalVideos += 1;
            }
        })



        let watched = 0;
        let oldWatched = 0;

        departmentData.users.forEach((userData) => {
            videos.forEach((inner) => {
                inner.seen_videos.forEach((videoSeen) => {
                    if(videoSeen.user_id === userData.id){
                        if(moment(videoSeen.seen_at).isAfter(moment().subtract(1, 'year'))){
                            watched += 1;
                            oldWatched += 1;
                        }else{
                            watched += 1;
                        }
                    }
                })
            });
        })

        departmentData.video_departments.forEach((video) => {
            console.log(video);
            video.videos.seen_videos.forEach((seen) => {
                console.log(seen.seen_at);
                if(departmentData.users.findIndex((user) => user.id === seen.user_id) !== -1){
                    if(moment(seen.seen_at).isAfter(moment().subtract(1, 'year'))){
                        watched += 1;
                        oldWatched += 1;
                    }else{
                        watched += 1;
                    }
                }
            });
        });
        return {
            ...departmentData,
            totalUsers,
            totalVideos,
            watched,
            oldWatched
        }
    });

}
