import React from "react";
import {Avatar, AvatarGroup} from "@mui/material";

function nameNormalizer(groupName: string){

    let firstLetters = '';
    const nameSplitter = groupName.split(" ");
    nameSplitter.forEach((a) => {
        firstLetters += a.charAt(0);
    })

    return firstLetters.toUpperCase();
}

export default function GroupPermissionSearcher(props: {groups: any, permissionList: any, permissionToLook: string}){

    const findPermissionInGroup = props.groups.filter((i) => {
       return i.permissions.findIndex((i) => i.name === props?.permissionList[props.permissionToLook]) !== -1;
    });

    return (
        <>
            <AvatarGroup sx={{
                '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 11 },
            }} max={4}>
                {findPermissionInGroup.map((group) => (
                    <Avatar sx={{ width: 24, height: 24, fontSize: 11 }}>{nameNormalizer(group.name)}</Avatar>
                ))}
            </AvatarGroup>
        </>
    );

}