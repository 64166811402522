import ViewBox from "../../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import {TextField, useTheme} from "@mui/material";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import FullButton from "../../ViewComponents/FullButton";
import {PhishingRepository} from "../../Repositories/PhishingRepository";
import UploadFile from "../../ViewComponents/UploadFile";
import ViewBoxTab from "../../ViewComponents/ViewBoxTab";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";

const phishingRepository = new PhishingRepository();
const videoRepository = new VideoRepository();

export default function LandingOperation(props: {
    editMode: null | any;
    onSaveRequest?: (data: any) => void;
    disableAll?: boolean;
    onClose?: () => void;
    fromModal?: boolean;
    fromModalUpdate?: (updatedData: any) => void;
    closeCreate?: () => void;
}){

    const breadCrumb = useBreadCrumb();
    const history = useHistory();


    const language = useLanguageState(state => state.language);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [formState, setFormState] = useState({designation: '', image: '', html: '', type: 'html'});
    const theme = useTheme();

    const saveChanges = useCallback(async () => {

        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))

        const update = await phishingRepository.updateLanding( {designation: formState.designation, data:editorChanger}, props.editMode.id);
        if(props.fromModalUpdate){
            props.fromModalUpdate(update);
        }

    }, [editorState, formState.designation, props]);

    const createOrUpdateLanding = useCallback(async () => {

        const editorChanger = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        const fixData = JSON.parse(JSON.stringify(formState));
        fixData.data = fixData.type === 'editor' ? editorChanger : fixData.html;
        await phishingRepository.createLanding(fixData).then((response) => {
            if(props.closeCreate){
                props.closeCreate();
            }

        }).catch((err) => {
            console.log(err);
        })

    }, [formState, editorState]);

    useEffect(() => {

        if(props.editMode !== null){
            setFormState({
                designation: props.editMode.designation,
                image: props.editMode.image ?? '',
                html: props.editMode.type === 'html' ? props.editMode.data : '',
                type: props.editMode.type
            });

            if(props.editMode.type === "editor"){
                const htmltodraft = htmlToDraft(props.editMode.data);
                const contentState = ContentState.createFromBlockArray(htmltodraft.contentBlocks);
                const state = EditorState.createWithContent(contentState);
                setEditorState(state);
            }

        }
    }, [props.editMode]);



    useEffect(() => {
        if(!props.fromModal) {
            breadCrumb.setChildren([
                {
                    color: "lightGreen",
                    onClick: () => {
                        createOrUpdateLanding();
                    },
                    icon: "save",
                    title: I18N('createLanding', language),
                }
            ]);
            breadCrumb.setBackButton(() => {
                if (props.closeCreate) {
                    props.closeCreate();
                }
            });
            breadCrumb.setTitle(I18N('createLanding', language));
            breadCrumb.setCrumb([
                {
                    title: I18N('phishing', language),
                    onClick: () => history.push('/phishing/overview')
                }, {
                    title: I18N('landingPages', language),
                    onClick: () => {
                        if (props.closeCreate) {
                            props.closeCreate();
                        }
                    }
                }, , {
                    title: I18N('createLanding', language),
                    onClick: () => history.push('/phishing/landingpages')
                }])
        }
    }, [createOrUpdateLanding, props.fromModal]);

    return (
        <div>
            <ViewBox title={I18N('general', language)}>
                <TextField
                    disabled={props.disableAll}
                    fullWidth
                    label={I18N('designation', language)}
                    style={{ marginTop: 20 }}
                    value={formState.designation}
                    onChange={(text) => setFormState({...formState, designation: text.target.value})}
                />
            </ViewBox>

            <ViewBox title={I18N('backgroundImage', language)}>
                <UploadFile inputAttrs={{accept: 'image/*'}} url={formState.image} uploadRepository={videoRepository} onUpload={(image) => setFormState({...formState, image: image})} uploadKey="cover" />
            </ViewBox>

            <ViewBox title={I18N('content', language)}>
                {props.disableAll ? (
                    <div dangerouslySetInnerHTML={{__html: props.editMode.data}}></div>
                ): (
                    <>
                        {props.editMode !== null ? (
                            <>
                                {props.editMode.type === 'editor' ? (
                                    <div style={{marginTop: 5}}>
                                        <Editor
                                        toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                                        editorState={editorState}
                                        wrapperClassName={props.fromModal ? "classEditorEmailSmall" : "classEditorEmail"}
                                        editorClassName={props.fromModal ? "classEditorInnerSmall" : "classEditorInner"}
                                        onEditorStateChange={(data) => {
                                            setEditorState(data);
                                        }}
                                    /></div>
                                ): (
                                    <div><textarea onChange={(e) => setFormState({...formState, html: e.target.value})} style={{width: '100%'}} rows={20} value={formState.html} /></div>
                                )}
                            </>
                        ): (
                            <ViewBoxTab tabs={[{
                                name: I18N('html', language),
                                content: (<div><textarea style={{background: theme.palette.mode === 'dark' && theme.palette.background.default, color: theme.palette.mode === 'dark' && '#fff', width: '100%'}} onChange={(e) => setFormState({...formState, html: e.target.value})} rows={20}  value={formState.html}/></div>)
                            },{
                                name: I18N('editor', language),
                                content: (<div style={{marginTop: 5}}><Editor
                                    toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                                    editorState={editorState}
                                    wrapperClassName={props.fromModal ? "classEditorEmailSmall" : "classEditorEmail"}
                                    editorClassName={props.fromModal ? "classEditorInnerSmall" : "classEditorInner"}
                                    onEditorStateChange={(data) => {
                                        setEditorState(data);
                                    }}
                                /></div>)
                            }]} onTabChange={(index) => {setFormState({...formState, type: index === 0 ? 'html' : 'editor'})}} />
                        )}


                    </>



                )}

            </ViewBox>

            {props.fromModal && (
                <div style={{justifyContent: "center", display: "flex", flexDirection: "row"}}>
                    <div style={{width: '30%', alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex", margin: '25px auto'}}>
                        <FullButton text={I18N('cancel', language)} onClick={props.onClose} isLoading={false} buttonClass={'red'} />
                    </div>
                    <div style={{width: '65%', alignItems: "center", justifyContent: "center", alignContent: "center", display: "flex", margin: '25px auto'}}>
                        <FullButton text={I18N('updateWName', language, [{key: 'name', value: I18N('landing', language)}])} onClick={saveChanges} isLoading={false} buttonClass={'green'} />
                    </div>
                </div>

            )}
        </div>

    )
}
