import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../ComplinessTheme";
import Select from "react-select/creatable";
import {OptionTypeBase} from "react-select/src/types";
import {UsersRepository} from "../../../Repositories/UsersRepository";
import {useLanguageState} from "../../../States/LanguageState";
import {I18N} from "../../../i18n/i18n";
import {SidebarContainer, SidebarDrawer} from "../../../ViewComponents/VideoEditComponents/Drawer";
import FormButton from "../../../ViewComponents/FormButton";
import {ProfileRepository} from "../../../Repositories/ProfileRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {PassStringCreator} from "../../../Helpers/PassStringCreator";
import {useSnackbar} from "notistack";
import {DrawerConfigSx} from "../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../ViewComponents/ViewBox";
const sweetAlert = withReactContent(Swal)
const profileRepository = new ProfileRepository();


const ChangePasswordBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, userInfo: any; onUpdate: () => void;}) => {
    const language = useLanguageState();
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [formState, setFormState] = useState<{password: string; passwordRepeat: string; oldPassword: string}>({
       password: '', passwordRepeat: '', oldPassword: ''
    });

    useEffect(() => {

        setFormState({
            oldPassword: '',
            password: '',
            passwordRepeat: ''
        })

    }, [props.userInfo]);

    const saveChanges = useCallback(async () => {

        setLoadingData(true);

        await profileRepository.updatePassword({...formState}).then(async (response) => {
            console.log(response);

            if(response){
                if(response.status){
                    props.setIsOpen(false);
                    props.onUpdate();
                    return true;
                }else{

                    if(response.regexString){
                        const string =  PassStringCreator(response, language.language);
                        await enqueueSnackbar(string, {variant: "error", style: { whiteSpace: 'pre-line' }})
                        return false;
                    }else{
                        if(response.message){
                            if(response.message === 'passwordsNoMatch'){
                                await enqueueSnackbar(I18N("pleaseEnterSamePassword", language.language), {variant: "error"});
                                return false;
                            }
                        }
                        await enqueueSnackbar(I18N("unexpectedError", language.language), {variant: "error"});
                        return false;
                    }



                }

            }else{
                console.log("err");
            }

        }).catch(async (err) => {
            console.log(err);
            if(err.message){
                if(err.message === 'passwordsNoMatch'){
                    await enqueueSnackbar(I18N("pleaseEnterSamePassword", language.language), {variant: "error"});
                    return false;
                }
            }
            await enqueueSnackbar(I18N("pleaseCheckYourOldPassword", language.language), {variant: "error"});
            return false;
        })


        setLoadingData(false);


    }, [formState]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changePassword', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <TextField
                                fullWidth
                                type="password"
                                label={I18N('oldPassword', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.oldPassword}
                                onChange={(text) => setFormState({...formState, oldPassword: text.target.value})}
                            />

                            <TextField
                                fullWidth
                                type="password"
                                label={I18N('password', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.password}
                                onChange={(text) => setFormState({...formState, password: text.target.value})}
                            />

                            <TextField
                                fullWidth
                                label={I18N('passwordRepeat', language.language)}
                                style={{ marginTop: 20 }}
                                type="password"
                                value={formState.passwordRepeat}
                                onChange={(text) => setFormState({...formState, passwordRepeat: text.target.value})}
                            />

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default ChangePasswordBar;
