import {Box, Modal} from "@mui/material";
import {useEffect, useState} from "react";
import WelcomeStep from "./WelcomeStep";
import StepDetails from "./StepDetails";
import WelcomeStepInner from "./WelcomeStepInner";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import closeButton from '../assets/times.svg';
export default function WizardBox(props: {
    open: boolean;
    setOpen: (status) => void;
    steps: any[];
    sendActiveStep: (stepNumber: number) => void;
    onClearData: () => void;
}){

    const [activeStep, setActiveStep] = useState(0);
    const [totalStep, setTotalStep] = useState(props.steps.length - 2);
    const language = useLanguageState(state => state.language);


    useEffect(() => {
        props.sendActiveStep(activeStep);
    }, [activeStep]);



    return (
        <Modal
            open={props.open}
            onClose={props.setOpen}
        >
            <Box sx={{
                borderRadius: 3,
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 1000,
                height: 'auto',
                bgcolor: 'background.paper',
                boxShadow: 24,
                display: "flex",
                flexDirection: 'row'
            }}>
                <div style={{position: "absolute", top: 10, right: 15, cursor: "pointer"}} onClick={() => props.setOpen(false)}><img width={15} height={15} src={closeButton} /></div>
               <div style={{backgroundColor: "#FFF7CD", height: 600, width: '30%', borderRadius: 7}}>
                   {props.steps[activeStep].welcomeStep ? (
                       <WelcomeStep />
                   ): (
                       <StepDetails totalStep={totalStep} activeStep={activeStep} steps={props.steps} />
                   )}
               </div>
                <div style={{width: '70%', backgroundColor: 'auto'}}>
                    {props.steps[activeStep].welcomeStep ? (
                        <WelcomeStepInner text={props.steps[activeStep].stepDescription} />
                    ): (
                        <div style={{padding: 30}}>
                            <div style={{backgroundColor: "#F2F2F2", borderRadius: 20, width: '100%', height: 30}}>
                                <div style={{height: 30, backgroundColor: "#FFE16A", width: ((activeStep - 1) * 100 / (props.steps.length - 2)) + '%', borderTopRightRadius: ((activeStep - 1) * 100 / (props.steps.length - 2)) === 100 ? 10 : 0, borderBottomRightRadius: ((activeStep - 1) * 100 / (props.steps.length - 2)) === 100? 10 : 0, borderTopLeftRadius: 10, borderBottomLeftRadius: 10}}></div>
                            </div>
                            <p style={{textAlign: "center", fontSize: 16, fontWeight: "bold"}}>{I18N('percentage', language)}: {((activeStep - 1) * 100 / (props.steps.length - 2)).toFixed(0)}%</p>
                            {props.steps[activeStep].stepInnerComponent}
                        </div>
                    )}

                    <div style={{position: "absolute", bottom: 20, display: "flex", justifyContent: "space-between", width: '62%', marginLeft: 40}}>
                        <FormButton isLeft={true} color={activeStep !== 0 ? "gray" : "transparent"}  backText={I18N("back", language)} text={I18N('back', language)} onClick={() => setActiveStep(activeStep - 1)} isLoading={false} />
                        <div style={{display: "flex", justifyContent:  "space-between"}}>
                             <FormButton text={I18N('clear',language)} style={{marginRight: 10}} onClick={props.onClearData} color={activeStep !== 0 && activeStep !== (props.steps.length - 1) ? "dark" : "transparent"} isLoading={false} />
                            <FormButton text={I18N('next',language)} onClick={() => setActiveStep(activeStep + 1)} color={activeStep !== (props.steps.length - 1) ? "yellow" : 'transparent'} isLoading={false} />
                            <FormButton text={I18N('finish',language)} onClick={() => setActiveStep(activeStep + 1)} color={activeStep !== (props.steps.length - 1) ? "transparent" : 'green'} isLoading={false} />
                        </div>

                    </div>

                </div>
            </Box>
        </Modal>
    )

}