import React, { useCallback, useEffect, useState } from 'react';
import { DepartmentsRepository } from '../Repositories/DepartmentsRepository';
import { VideoRepository } from '../Repositories/VideoRepository';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import ViewBox from '../ViewComponents/ViewBox';
import UploadFile from '../ViewComponents/UploadFile';
import { ContentTypeState } from './VideoPage';
import { DepartmentType } from '../Types/DepartmentTypes';
import { useBreadCrumb } from '../States/BreadCrumbState';
import PublishVideoBar from '../ViewComponents/PublishVideoBar/PublishVideoBar';
import editIcon from '../assets/editIcon.svg';
import checkGreen from '../assets/checkGreen.svg';
import checkRed from '../assets/checkRed.svg';
import enrollSVG from '../assets/enrollSVG.svg';
import iconPreview from '../assets/iconPreview.svg';
import enrollButton from '../assets/enrollButton.svg';
import previewContent from '../assets/previewContent.svg';
import { useTheme } from '@mui/material';
import GeneralContentBar from '../ViewComponents/VideoEditComponents/GeneralContentBar';
import CompaniesBar from '../ViewComponents/VideoEditComponents/CompaniesBar';
import DepartmentsBar from '../ViewComponents/VideoEditComponents/DepartmentsBar';
import EditIcon from '../ViewComponents/EditIcon';
import RunQuiz from '../QuizEditor/RunQuiz';
import ShowVideo from '../RouteComponents/LearnVideos/ShowVideo';
import ShowPDF from '../RouteComponents/LearnPdf/ShowPDF';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import PreviewModal from './PreviewModal';
import languageExporter from '../Helpers/LanguageExporter';

const departmentsRepository = new DepartmentsRepository();
const videoRepository = new VideoRepository();

type Props = {
  isMobile: () => boolean;
  editMode?: any;
  backCallback: () => void;
  onFormStateChange: (formState: ContentTypeState) => void;
  onFinish: () => void;
  onEnroll: (depsSelected: DepartmentType[], sendNotification: boolean) => void;
  canEdit?: boolean;
  canEnroll?: boolean;
};

export function ContentEdit({ canEdit, canEnroll, ...props }: Props) {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [departments, setDepartments] = useState<DepartmentType[]>([]);
  const language = useLanguageState((state) => state.language);
  const breadCrumb = useBreadCrumb();
  const [editGeneralContent, setEditGeneralContent] = useState(false);
  const [editDepartments, setEditDepartments] = useState(false);
  const [editCompanies, setEditCompanies] = useState(false);
  const theme = useTheme();
  const [showPreview, setShowPreview] = useState(false);

  const [contentData, setContentData] = useState(props.editMode);

  const getDepartments = useCallback(async () => {
    const depList = await departmentsRepository.getAll();

    setDepartments(depList as DepartmentType[]);
  }, []);

  useEffect(() => {
    getDepartments().then(undefined);
  }, []);

  useEffect(() => {
    breadCrumb.setBackButton(props.backCallback);
    breadCrumb.setAdditionalCrumb({
      title: contentData ? contentData.title : I18N('createContent', language),
      onClick: undefined
    });
    breadCrumb.setTitle(
      contentData ? contentData.title : I18N('createContent', language)
    );
    breadCrumb.setChildren([
      {
        color: 'transparent',
        key: 'videoInfoEdit',
        title: '',
        onClick: undefined,
        icon: 'save'
      }
    ]);
  }, [language, props.onEnroll, props.onFinish, props.backCallback]);

  const autoUpdateImageWhenUploaded = useCallback(async (image: string) => {
    await videoRepository
      .updateImage(image, contentData.id)
      .then((response) => {
        setContentData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div style={{ background: 'transparent' }}>
      <PreviewModal
        isOpen={showPreview}
        onClose={() => setShowPreview(false)}
        editMode={props.editMode}
      />
      <PublishVideoBar
        video={contentData}
        onEnroll={props.onEnroll}
        departments={departments}
        setIsOpen={setSideBarOpen}
        isOpen={sideBarOpen}
      />
      <GeneralContentBar
        setContent={setContentData}
        isOpen={editGeneralContent}
        setIsOpen={() => setEditGeneralContent(false)}
        content={contentData}
      />
      <CompaniesBar
        setContent={setContentData}
        isOpen={editCompanies}
        setIsOpen={() => setEditCompanies(false)}
        content={contentData}
      />
      <DepartmentsBar
        setContent={setContentData}
        isOpen={editDepartments}
        setIsOpen={() => setEditDepartments(false)}
        content={contentData}
      />
      <div className="flex-row">
        <ViewBox
          style={{
            background: theme.palette.mode === 'dark' ? '#0A1929' : undefined
          }}
          title={I18N('contentImage', language)}
        >
          <UploadFile
            className="newEditBox"
            showLoadingWhileUpload={true}
            inputAttrs={{ accept: 'image/*' }}
            url={contentData.image}
            uploadRepository={videoRepository}
            onUpload={autoUpdateImageWhenUploaded}
            uploadKey="cover"
            canUpload={canEdit}
          />
        </ViewBox>
        <div style={{ width: '50%' }}>
          <ViewBox
            rightComponent={
              canEdit && (
                <EditIcon onClick={() => setEditGeneralContent(true)} />
              )
            }
            title={I18N('informationAboutContent', language)}
            style={{
              width: '100%',
              background: theme.palette.mode === 'dark' ? '#0A1929' : undefined
            }}
          >
            <table style={{ borderSpacing: 15 }}>
              <tr>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 12
                  }}
                >
                  {I18N('title', language)}
                </td>
                <td
                  style={{
                    color:
                      theme.palette.mode === 'dark'
                        ? '#fff'
                        : 'rgba(62, 70, 73, 1)',
                    fontSize: 14,
                    fontWeight: 'bold'
                  }}
                >
                  {languageExporter(
                    contentData,
                    language,
                    'video_titles',
                    'title',
                    'title'
                  )}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 12
                  }}
                >
                  {I18N('responsibleUser', language)}
                </td>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 14
                  }}
                >
                  {contentData.accountable
                    ? `${contentData.accountable.firstName} ${contentData.accountable.lastName}`
                    : '-'}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 12
                  }}
                >
                  {I18N('implementationUser', language)}
                </td>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 14
                  }}
                >
                  {contentData.execution_responsibles.map((i) => {
                    return (
                      <div>{`${i.user?.firstName} ${i.user?.lastName}`}</div>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 12
                  }}
                >
                  {I18N('category', language)}
                </td>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 14
                  }}
                >
                  {contentData.video_categories
                    .map((i) => i.categories.name)
                    .join(', ')}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 12
                  }}
                >
                  {I18N('trophy', language)}
                </td>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 14
                  }}
                >
                  {contentData.trophies.name}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 12
                  }}
                >
                  {I18N('additionalQuiz', language)}
                </td>
                <td
                  style={{
                    color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649',
                    fontSize: 14
                  }}
                >
                  {contentData.quizes === null
                    ? ' - '
                    : contentData.quizes.name}
                </td>
              </tr>
            </table>
          </ViewBox>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                background: 'linear-gradient(180deg, #FFE16A 0%, #FAC804 100%)',
                marginTop: 85,
                height: 146,
                position: 'relative',
                width: '90%',
                marginRight: 20,
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <img
                src={previewContent}
                width={120}
                alt="preview content"
                height={89}
                style={{ position: 'absolute', top: -30, left: 15 }}
              />
              <img
                src={iconPreview}
                onClick={() => setShowPreview(true)}
                width={55}
                alt="preview button"
                height={55}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 15,
                  top: 7
                }}
              />
              <p
                style={{
                  color: '#000',
                  padding: 0,
                  margin: 0,
                  marginBottom: 15,
                  marginLeft: 20,
                  fontSize: 24,
                  fontWeight: 'bold',
                  lineHeight: '29px'
                }}
              >
                {I18N('previewContentText', language)}
              </p>
              <p
                style={{
                  color: '#000',
                  padding: 0,
                  margin: 0,
                  maxWidth: '80%',
                  marginBottom: 15,
                  marginLeft: 20,
                  fontSize: 12,
                  fontWeight: 'normal',
                  lineHeight: '14px'
                }}
              >
                {I18N('previewContentSubText', language)}
              </p>
            </div>
            <div
              style={{
                background: 'linear-gradient(180deg, #FFE16A 0%, #FAC804 100%)',
                marginTop: 85,
                height: 146,
                position: 'relative',
                width: '90%',
                borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <img
                src={enrollSVG}
                width={120}
                height={89}
                alt="enroll svg"
                style={{ position: 'absolute', top: -30, left: 15 }}
              />
              <img
                src={enrollButton}
                alt="enroll button"
                onClick={canEnroll ? () => setSideBarOpen(true) : undefined}
                width={55}
                height={55}
                style={{
                  cursor: canEnroll ? 'pointer' : 'not-allowed',
                  position: 'absolute',
                  right: 15,
                  top: 7
                }}
              />
              <p
                style={{
                  color: '#000',
                  padding: 0,
                  margin: 0,
                  marginBottom: 5,
                  marginLeft: 20,
                  fontSize: 24,
                  fontWeight: 'bold',
                  lineHeight: '29px'
                }}
              >
                {I18N('enrollContentText', language)}
              </p>
              <p
                style={{
                  color: '#000',
                  padding: 0,
                  margin: 0,
                  maxWidth: '80%',
                  marginBottom: 15,
                  marginLeft: 20,
                  fontSize: 12,
                  fontWeight: 'normal',
                  lineHeight: '14px'
                }}
              >
                {I18N('enrollContentSubText', language)}
              </p>
              <p
                style={{
                  position: 'absolute',
                  right: 15,
                  bottom: -8,
                  fontSize: 10
                }}
              >
                {contentData
                  ? contentData.enrolled_at === null
                    ? I18N('noEnrolment', language)
                    : I18N('lastEnrolledAt', language) +
                      ': ' +
                      new Date(contentData.enrolled_at).toLocaleDateString()
                  : ''}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-row">
        <ViewBox
          style={{
            background: theme.palette.mode === 'dark' ? '#0A1929' : undefined
          }}
          rightComponent={
            canEdit && <EditIcon onClick={() => setEditCompanies(true)} />
          }
          title={I18N('companies', language)}
        >
          <table style={{ width: '98%', borderSpacing: 0 }}>
            <thead
              style={{
                background:
                  theme.palette.mode === 'dark' ? 'rgb(0, 8, 17)' : '#F2F2F2',
                width: '98%'
              }}
            >
              <th style={{ borderRadius: 10 }}>
                <td
                  style={{
                    padding: 15,
                    color: '#828282',
                    fontSize: 14,
                    fontWeight: 'normal'
                  }}
                >
                  {I18N('name', language)}
                </td>
              </th>
            </thead>
            <tbody>
              {contentData.videos_companies.map((i) => (
                <tr>
                  <td
                    style={{
                      paddingLeft: 15,
                      paddingTop: 7,
                      fontWeight: 'bold',
                      fontSize: 14,
                      lineHeight: '17px'
                    }}
                  >
                    {i.company.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ViewBox>

        <ViewBox
          style={{
            background: theme.palette.mode === 'dark' ? '#0A1929' : undefined
          }}
          rightComponent={
            canEdit && <EditIcon onClick={() => setEditDepartments(true)} />
          }
          title={I18N('department', language)}
        >
          <table style={{ width: '98%', borderSpacing: 0 }}>
            <thead
              style={{
                background:
                  theme.palette.mode === 'dark' ? 'rgb(0, 8, 17)' : '#F2F2F2',
                width: '98%'
              }}
            >
              <tr style={{ borderRadius: 10, padding: 15 }}>
                <th
                  style={{
                    color: '#828282',
                    borderRadius: '10px 0 0 10px',
                    padding: 15,
                    textAlign: 'left',
                    fontSize: 14,
                    fontWeight: 'normal'
                  }}
                >
                  {I18N('name', language)}
                </th>
                <th
                  style={{
                    color: '#828282',
                    borderRadius: '0 10px 10px 0',
                    padding: 15,
                    textAlign: 'left',
                    fontSize: 14,
                    fontWeight: 'normal'
                  }}
                >
                  {I18N('mandatory', language)}
                </th>
              </tr>
            </thead>
            <tbody>
              {contentData.visibleForAll ? (
                <>
                  {departments.map((i) => (
                    <tr>
                      <td
                        style={{
                          paddingLeft: 15,
                          paddingTop: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          lineHeight: '17px'
                        }}
                      >
                        {i.name}
                        <p
                          style={{
                            fontWeight: 'normal',
                            padding: 0,
                            margin: 2
                          }}
                        >
                          {i.departments_companies
                            .map((i) => i.company.name)
                            .join(',')}
                        </p>
                      </td>
                      <td style={{ paddingLeft: 15, paddingTop: 10 }}>
                        {contentData.requiredForAll ? (
                          <img
                            src={checkGreen}
                            style={{ width: 18, height: 18 }}
                            alt="check green"
                          />
                        ) : (
                          <>
                            {contentData.video_departments.filter((e) => {
                              return e.department_id === i.ID && e.awardable;
                            }).length > 0 ? (
                              <img
                                src={checkGreen}
                                style={{ width: 18, height: 18 }}
                                alt="check green"
                              />
                            ) : (
                              <img
                                src={checkRed}
                                style={{ width: 18, height: 18 }}
                                alt="check red"
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {contentData.video_departments.map((i) => (
                    <tr>
                      <td
                        style={{
                          paddingLeft: 15,
                          paddingTop: 10,
                          fontWeight: 'bold',
                          fontSize: 14,
                          lineHeight: '17px'
                        }}
                      >
                        {i.department.name}
                        <p
                          style={{
                            fontWeight: 'normal',
                            padding: 0,
                            margin: 2
                          }}
                        >
                          {i.department.departments_companies
                            .map((i) => i.company.name)
                            .join(',')}
                        </p>
                      </td>
                      <td style={{ paddingLeft: 15, paddingTop: 10 }}>
                        {contentData.requiredForAll ? (
                          <img
                            src={checkGreen}
                            style={{ width: 18, height: 18 }}
                            alt="check green"
                          />
                        ) : (
                          <>
                            {i.awardable ? (
                              <img
                                src={checkGreen}
                                style={{ width: 18, height: 18 }}
                                alt="check green"
                              />
                            ) : (
                              <img
                                src={checkRed}
                                style={{ width: 18, height: 18 }}
                                alt="check red"
                              />
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </ViewBox>
      </div>
    </div>
  );
}
