import { getToken } from '../Repositories/Cookies';
import { useLoginState } from '../States/UserState';

export default function MultiPermissionCheck(
  permissionList: any[],
  checkPermission: string[]
) {
  let status = false;

  permissionList.forEach((i) => {
    if (checkPermission.includes(i)) {
      status = true;
    }
  });

  return status;
}

export const useHasPermissions = (permissions: string[]) => {
  const clientName = getToken();
  const loginState = useLoginState();

  const roleList = loginState.token.resource_access[clientName].roles;

  return MultiPermissionCheck(roleList, permissions);
};
