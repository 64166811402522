import React, {ChangeEvent, useCallback, useRef} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import {useUploadingState} from "../States/CurrentlyUploading";
import {VideoUploadRepository} from "../Repositories/VideoUploadRepository";
import UploadingListView from "./UploadingListView";
import {DragNDrop} from "./DragNDrop";
import {useSnackbar} from "notistack";
const videoUploadRepository = new VideoUploadRepository();
type Props = {
    backCallback: () => void;
}

export function UploadContentOperation(props: Props) {

    const language = useLanguageState(state => state.language);
    const selectRef = useRef<HTMLInputElement>(null);
    const uploadingState = useUploadingState();
    const {enqueueSnackbar} = useSnackbar();
    const openInput = useCallback(() => {
        if(selectRef.current !== null)
            selectRef.current.click();
    }, [selectRef]);

    const selectFiles = useCallback(async (drop: File[] | null, event: ChangeEvent<HTMLInputElement> | null) => {

        const alreadyUploading = [];
        let isAlreadyUploading = false;
        let files: any = {};
        if(drop !== null){
            files = drop;
        }else{
            files = event.target.files;
        }

        if(uploadingState.uploading !== null){
            Object.keys(files).forEach((fileKey) => {
                const singleFile = files.item(Number(fileKey));
                uploadingState.uploading.forEach((currentlyUploading) => {
                    if(singleFile.name === currentlyUploading.name){
                        alreadyUploading.push(singleFile);
                        isAlreadyUploading = true
                    }
                });
            });
        }


        if(isAlreadyUploading){
            enqueueSnackbar(I18N('alreadyUploadingFiles', language) + alreadyUploading.map((file) => file.name + '</br>'), {variant: "error"})

            return;
        }

        uploadingState.setUploading(files);

        Object.keys(files).map((fileKey) => {
            const singleFile = files.item(Number(fileKey));
            videoUploadRepository.uploadPicture(singleFile, (file, progress, loaded, finished) => uploadingState.updateProgress(file, progress, loaded, finished));
        })

        selectRef.current.files = null;
    }, [language, uploadingState]);

    return (
        <React.Fragment>
            <ViewBox>
                <input type="file" onChange={(event) => selectFiles(null, event)} multiple={true} accept={'application/zip,application/pdf,video/mp4'} style={{display: "none"}} ref={selectRef} />
                <DragNDrop openInput={openInput} onDrop={(files) => selectFiles(files, null)} />
            </ViewBox>
            {uploadingState.uploading !== null && uploadingState.uploading.length > 0 && (
                <ViewBox>
                    <UploadingListView />
                </ViewBox>
            )}
        </React.Fragment>

    )
}
