import React, {useCallback, useEffect, useState} from 'react';
import {ReleaseNoteOperation} from "./ReleaseNoteOperation";
import {useLocation} from "react-router-dom";
import ReleaseNoteList from "./ReleaseNoteList";
import {Searchbar} from "../Searchbar/Searchbar";

type Props = {
    style: any;
    isMobile: () => boolean;
}



export default function ReleaseNotes(props: Props) {
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<null | any>(null);
    const [reloadStatus, setRealoadStatus] = useState(1);
    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                backToReleaseNotes();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const backToReleaseNotes = () => {
        setCreateOpen(false);
        setEditMode(null);
        scrollTop();
    }


    return (
        <div style={props.style}>
            <Searchbar />
                <div style={{ width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' }}>
                    {createOpen ? (
                        <ReleaseNoteOperation
                            editMode={editMode}
                            backCallback={backToReleaseNotes} />
                    ): (<ReleaseNoteList setEditMode={(item) => {
                        setEditMode(item);
                        setCreateOpen(true);
                    }} reload={reloadStatus} />)}
                </div>
        </div>
    )

}
