import axios, { AxiosResponse } from 'axios';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BACKEND_URL } from './assets/AppConstants';
import { Backdrop } from '@mui/material';
import Cookies from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';

axios.defaults.baseURL = BACKEND_URL;

export const SamlWrapper = ({ children }: { children: ReactNode }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [isLoading, setIsLoading] = useState(true);

  const client = searchParams.get('client');

  useEffect(() => {
    if (client) {
      axios
        .get(`/saml-url/${client}`)
        .then((response: AxiosResponse<string | boolean>) => {
          if (response.data === false) {
            return;
          }
          if (response.data) {
            const samlUrl = response.data as string;
            localStorage.setItem('samlUrl', samlUrl);
            window.location.href = samlUrl;
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [client]);

  if (isLoading) {
    return <Backdrop open={true} />;
  }

  const samlUrl = localStorage.getItem('samlUrl') as string;
  const refreshToken = Cookies.get('refresh_token');

  let isTokenExpired = false;

  if (refreshToken) {
    const { exp } = jwtDecode(refreshToken) as JwtPayload;
    if (exp) {
      isTokenExpired = Date.now() >= exp * 1000;
    }
  }

  if (samlUrl && (!refreshToken || isTokenExpired)) {
    window.location.href = samlUrl;
  }

  return <>{children}</>;
};
