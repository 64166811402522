import { TextField} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";


type Props = {
    editMode?: any;
    onNameChange: (name: string) => void;
}

export function LicenseOperation(props: Props) {

    const language = useLanguageState(state => state.language)

    const [name, setName] = useState<string>('');

    useEffect(() => {
        if(props.editMode !== null) {
            setName(props.editMode.name);
        }
    }, [props.editMode]);


    useEffect(() => {
        props.onNameChange(name);
    }, [name, props]);


    return (
        <ViewBox>
            <TextField
                fullWidth
                label={I18N("title", language)}
                className="marginTop20"
                value={name}
                onChange={(text) => setName(text.target.value)}
            />
        </ViewBox>
    )


}
