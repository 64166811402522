import { I18N } from '../i18n/i18n';
import ViewBox from '../ViewComponents/ViewBox';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguageState } from '../States/LanguageState';
import { VideoRepository } from '../Repositories/VideoRepository';
import moment from 'moment/moment';
import NotiSend from '../assets/notiSend.svg';
import { NotificationsRepository } from '../Repositories/NotificationsRepository';
import { useTheme } from '@mui/material';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { useSnackbar } from 'notistack';
import { useGlobalSettingsState } from '../States/GlobalSettings';
import ShowIcon from '../assets/show.svg';
import HideIcon from '../assets/hide.svg';
import LanguageExporter from '../Helpers/LanguageExporter';
import VideoTypeExporter from '../Helpers/VideoTypeExporter';
const videoRepository = new VideoRepository();
const notificationsRepository = new NotificationsRepository();

export default function UserAnalyticsSingle(props: {
  contentID: string;
  onClick: (depID: number) => void;
  onBack: () => void;
}) {
  const language = useLanguageState((state) => state.language);
  const [watchList, setWatchList] = useState(null);
  const [videos, setVideos] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [userData, setUserData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const breadCrumb = useBreadCrumb();
  const [showUserNames, setShowUserNames] = useState(false);
  const [buttonActivated, setButtonActivated] = useState(true);
  const globalSettings = useGlobalSettingsState((state) => state.settings);

  useEffect(() => {
    const findPsudeo = globalSettings.findIndex((i) => i.key === 'psudeo');
    const findAnon = globalSettings.findIndex((i) => i.key === 'anon');

    if (findAnon !== -1) {
      if (Number(globalSettings[findAnon].value)) {
        setShowUserNames(false);
        setButtonActivated(false);
      } else {
        setButtonActivated(true);
        if (findPsudeo !== -1) {
          setShowUserNames(!Number(globalSettings[findPsudeo].value));
        } else {
          setShowUserNames(false);
        }
      }
    } else {
      setButtonActivated(true);
      if (findPsudeo !== -1) {
        setShowUserNames(!Number(globalSettings[findPsudeo].value));
      } else {
        setShowUserNames(false);
      }
    }
  }, [globalSettings]);

  useEffect(() => {
    breadCrumb.setCrumb([
      {
        title: I18N('analytics', language),
        onClick: undefined
      },
      {
        title: I18N('users', language),
        onClick: () => props.onBack()
      },
      {
        title: showUserNames
          ? watchList?.firstName + ' ' + watchList?.lastName
          : watchList?.id,
        onClick: undefined
      }
    ]);

    breadCrumb.setTitle(
      showUserNames
        ? watchList?.firstName + ' ' + watchList?.lastName
        : watchList?.id
    );
    breadCrumb.setBackButton(props.onBack);
    breadCrumb.setChildren([
      {
        title: '',
        color: 'transparent',
        isLoading: loadingForm,
        isFullLoading: true,
        onClick: undefined
      }
    ]);
  }, [watchList, loadingForm]);

  const loadData = useCallback(async () => {
    setLoadingForm(true);
    const watchListData = await videoRepository.getWatchListSingleUser(
      props.contentID
    );
    setLoadingForm(false);
    setWatchList(watchListData.contentData);
    setVideos(watchListData.videos);
    if (
      watchListData.userData !== undefined &&
      watchListData.userData !== null
    ) {
      setUserData(watchListData.userData);
    }
  }, [props.contentID, showUserNames]);

  useEffect(() => {
    loadData().then(undefined);
  }, [loadData]);

  const sendNotification = useCallback(async (video_id: number) => {
    setLoadingForm(true);
    await notificationsRepository
      .post({
        to: [props.contentID],
        video_id: video_id,
        fromAnalytics: 1
      })
      .then(async () => {
        setLoadingForm(false);
        enqueueSnackbar(I18N('notificationSent', language), {
          variant: 'success'
        });
      })
      .catch(async (e) => {
        setLoadingForm(false);
        if (e.response.data.reason === 'limit') {
          enqueueSnackbar(I18N('limitReachedPleaseTryAgain', language), {
            variant: 'error'
          });
        } else {
          enqueueSnackbar(I18N('unexpectedError', language), {
            variant: 'error'
          });
        }
      });
  }, []);

  return (
    <ViewBox style={{ flexDirection: 'column' }}>
      {/* <div className="endFlex flex-row" style={{marginLeft: "auto", width: "auto", cursor: "pointer", justifyItems: "flex-end", float: "right", fontWeight: "bold", fontSize: 15, lineHeight: 1.8, marginRight:30}} onClick={() => buttonActivated && setShowUserNames(!showUserNames)}>
                {buttonActivated && (<>{I18N("showUsernames", language)} {showUserNames ? <img src={ShowIcon} width={25} height={25} /> : <img src={HideIcon} width={25} height={25} /> }</>)}
            </div> */}

      <div className="tableView" style={{ marginTop: 50 }}>
        <thead className="sticky">
          <tr className="head sticky">
            <th
              style={{
                width: '70%',
                backgroundColor:
                  theme.palette.mode === 'dark' &&
                  theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
              className="tableTh"
            >
              <div className="ml-10">{I18N('video', language)}</div>
            </th>
            <th
              style={{
                width: '51%',
                backgroundColor:
                  theme.palette.mode === 'dark' &&
                  theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
              className="tableTh"
            >
              <div className="ml-10">Zuletzt gesehen</div>
            </th>
            <th
              style={{
                width: '50%',
                paddingRight: 15,
                backgroundColor:
                  theme.palette.mode === 'dark' &&
                  theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
              className="tableTh"
            >
              <div className="ml-10">Aktion</div>
            </th>
          </tr>
        </thead>
        <ul className="ranking">
          {videos?.map((videoData) => {
            const userWatched = watchList.seen_videos.find(
              (video) => video.video_id === videoData.id
            );
            const totalNotifications = watchList.notifications.filter(
              (noti) => noti.video_id === videoData.id
            );
            return (
              <li className="rankingBars" onClick={() => {}}>
                <div className="videoTitle">
                  {LanguageExporter(
                    videoData,
                    language,
                    'video_titles',
                    'title',
                    'title'
                  )}{' '}
                  ({VideoTypeExporter(videoData.type)})
                </div>
                <div
                  className="endFlex"
                  style={{
                    alignContent: 'flex-end',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    justifySelf: 'flex-end',
                    fontWeight: 'bold',
                    color:
                      userWatched !== undefined
                        ? moment(userWatched.seen_at).isBefore(
                            moment().subtract(1, 'year')
                          )
                          ? 'red'
                          : 'black'
                        : 'black'
                  }}
                >
                  {userWatched !== undefined
                    ? moment(userWatched.seen_at).format('DD.MM.YYYY')
                    : '---'}
                </div>
                <div
                  className="endFlex"
                  style={{
                    alignContent: 'flex-end',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    justifySelf: 'flex-end'
                  }}
                >
                  <img
                    src={NotiSend}
                    style={{ cursor: 'pointer' }}
                    onClick={() => sendNotification(videoData.id)}
                  />{' '}
                  <strong className="notificationCounter">
                    {totalNotifications.length}
                  </strong>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </ViewBox>
  );
}
