import { Konfiguration } from './Konfiguration';
import { Route } from 'react-router-dom';
import React from 'react';
import CompanyTab from '../../Company/CompanyTab';
import { DepartmentTab } from '../../Departments/DepartmentTab';
import { I18N } from '../../i18n/i18n';
import { useLanguageState } from '../../States/LanguageState';
import { getToken } from '../../Repositories/Cookies';
import { useLoginState } from '../../States/UserState';
import { User } from '../User/User';
import { CategoryTab } from '../../Categories/CategoryTab';
import { VideoPage } from '../../VideoInfo/VideoPage';
import LearningPlanTab from '../../LearningPlan/LearningPlanTab';
import { TrophyTab } from '../../Trophies/TrophyTab';
import ListsTab from '../../Lists/ListsTab';
import QuizTab from '../../QuizEditor/QuizTab';
import { PasswordTexts } from '../../PasswordTexts/PasswordTexts';
import { CreateNotification } from '../../Notification/CreateNotification';
import { ApiKeyTab } from '../../ApiKeys/ApiKeyTab';
import { AnalyticsSettings } from '../../Settings/Analytics/AnalyticsSettings';
import UserOperation from '../User/UserOperation';
import { SuperAdmins } from '../User/SuperAdmins';
import ReleaseNotes from '../../ReleaseNotes/ReleaseNotes';
import { PasswordQuality } from '../../PasswordQuality/PasswordQuality';
import { PermissionMatrix } from '../../PermissionMatrix/PermissionMatrix';
import MultiPermissionCheck from '../../Helpers/MultiPermissionCheck';
import { EmailList } from '../../Emails/EmailList';

type Props = {
  style: any;
  isMobile: () => boolean;
  setHideSideBar: (status: boolean) => void;
};

export default function ConfigurationRouter(props: Props) {
  const language = useLanguageState((state) => state.language);
  const clientName = getToken();
  const loginState = useLoginState();

  const roleList = loginState.token.resource_access[clientName].roles;

  return (
    <div>
      {MultiPermissionCheck(
        loginState.token.resource_access[clientName].roles,
        ['ViewAllCompanies', 'ViewCompanies']
      ) && (
        <Route
          path="/Konfiguration/companies"
          render={() => (
            <div>
              <CompanyTab style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, [
        'ViewDepartments',
        'ViewAllDepartments'
      ]) && (
        <Route
          path="/Konfiguration/departments"
          render={() => (
            <div>
              <DepartmentTab
                title={I18N('departments', language)}
                style={{}}
                isMobile={props.isMobile}
              />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['query-realms']) && (
        <Route
          path="/Konfiguration/superadmin"
          exact={true}
          render={() => (
            <div>
              <SuperAdmins style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}

      {MultiPermissionCheck(roleList, ['query-realms']) && (
        <Route
          path="/Konfiguration/superadmin/:id"
          exact={true}
          render={() => (
            <div>
              <SuperAdmins style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewAllUsers', 'ViewUser']) && (
        <Route
          path="/Konfiguration/users"
          exact={true}
          render={() => (
            <div>
              <User style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewAllUsers', 'ViewUser']) && (
        <Route
          path="/Konfiguration/users/:id"
          exact={true}
          render={() => (
            <div>
              <User style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, [
        'ViewAllCategories',
        'ViewCategories'
      ]) && (
        <Route
          path="/Konfiguration/categories"
          render={() => (
            <div>
              <CategoryTab style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewContent', 'ViewAllContent']) && (
        <Route
          path="/Konfiguration/contents"
          render={() => (
            <div>
              <VideoPage
                hideSideBar={props.setHideSideBar}
                style={{}}
                isMobile={props.isMobile}
              />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, [
        'ViewAllLearningPlan',
        'ViewLearningPlan'
      ]) && (
        <Route
          path="/Konfiguration/learningplans"
          render={() => (
            <div>
              <LearningPlanTab />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewAllTrophies', 'ViewTrophies']) && (
        <Route
          path="/Konfiguration/trophies"
          render={() => (
            <div>
              <TrophyTab style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewList', 'ViewAllList']) && (
        <Route
          path="/Konfiguration/userlists"
          render={() => (
            <div>
              <ListsTab />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewAllQuiz', 'ViewQuiz']) && (
        <Route
          path="/Konfiguration/quiz"
          render={() => (
            <div>
              <QuizTab />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['EditPasswordTexts']) && (
        <Route
          path="/Konfiguration/passwordtexts"
          render={() => (
            <div>
              <PasswordTexts style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['CreateNotification']) && (
        <Route
          path="/Konfiguration/notifications"
          render={() => (
            <div>
              <CreateNotification style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewApiKeys']) && (
        <Route
          path="/Konfiguration/apikeys"
          render={() => (
            <div>
              <ApiKeyTab style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['EditAnalyticsSettings']) && (
        <Route
          path="/Konfiguration/analytics"
          render={() => (
            <div>
              <AnalyticsSettings style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['EditPasswordQuality']) && (
        <Route
          path="/Konfiguration/passwordquality"
          render={() => (
            <div>
              <PasswordQuality style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['ViewPermissionMatrix']) && (
        <Route
          path="/Konfiguration/permissionmatrix"
          render={() => (
            <div>
              <PermissionMatrix style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
      {MultiPermissionCheck(roleList, ['EditPasswordQuality']) && (
        <Route
          path="/Konfiguration/mails"
          render={() => (
            <div>
              <EmailList style={{}} isMobile={props.isMobile} />
            </div>
          )}
        />
      )}
    </div>
  );
}
