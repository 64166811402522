
type Props = {
    info: string;
    icon: any;
    title: string;
}
export default function InfoBox(props: Props){


    return (
        <div style={{display: "flex", boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)', justifyContent: "center", alignItems: "center", marginTop: 20, position: "relative", width: '100%', height: '105px', backgroundColor: "rgba(244,244,244,1)", borderRadius: 10}}>
            <p style={{position: "absolute", top: 0, zIndex: 2, left: 17, fontSize: 14, fontWeight: 350, color: "#54667A"}}>
                {props.title}
            </p>
            <p style={{fontSize: 48,  zIndex: 2, fontWeight: 500, color: "#2f3640"}}>{props.info}</p>
            <img  src={props.icon} style={{position: "absolute", fill: "grey",  zIndex: 2, right: 50, top: 3, width: 99, height: 99}} />
        </div>
    )

}