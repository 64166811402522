import { I18N } from "../i18n/i18n";
import ReactApexChart from "react-apexcharts";
import {standartColors} from "../assets/colors/Colors";
import ViewBox from "./ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../States/LanguageState";
import {useLoginState} from "../States/UserState";
import {useTheme} from "@mui/material";

export default function UserStatsCircle(props: {
    contentData: any;
}){

    const language = useLanguageState(state => state.language);
    const authState = useLoginState();

    const [userStats, setUserStats] = useState([]);
    const theme = useTheme();

    useEffect(() => {

        if(props.contentData.length > 0){
            const viewed = props.contentData.filter((vid) => vid.seen_videos.length > 0);
            const toView = props.contentData.filter((vid) => {
                if(vid.seen_videos.length > 0){
                    return false;
                }else if(vid.video_departments.findIndex((inDp) => inDp.department_id === authState.data?.innerData.department) !== -1){
                    return true
                }else if(vid.requiredForAll){
                    return true;
                }else if(vid.visibleForAll){
                    return false
                }
                return false;
            })

            setUserStats([toView.length, viewed.length]);

        }

    }, [authState.data, props.contentData]);

    const donutSettings = useCallback(() => {

        return {
            labels: [I18N('open', language), I18N('viewed', language)],
            colors: [theme.palette.mode === 'dark' ? '#FFE16A' : standartColors.circleBlue, theme.palette.mode === 'dark' ? '#FFC721' : standartColors.circleYellow],
            stroke: {
                colors: [theme.palette.mode === 'dark' ? '#FFE16A' : '#fff'],
            },
            plotOptions: {
                pie: {
                    donut: {
                        borderColor: theme.palette.mode === 'dark' ? '#FFE16A' : '#fff',
                        labels: {
                            show: true,
                            value: {
                                color: theme.palette.mode === 'dark' ? '#FFE16A' : '#000',
                            },
                            total: {
                                label: I18N('total', language),
                                show: true,
                                color: theme.palette.mode === 'dark' ? '#fff' : "#000"
                            }
                        },
                        size: '90%'
                    }
                }
            },
            legend: { show: false },
            dataLabels: {
                enabled: false
            }
        }

    }, [language, theme]);

    return (
        <ViewBox width={30} title={I18N('myVideosOverview', language)}>
            <ReactApexChart
                type="donut"
                options={donutSettings()} series={userStats}
                height={280}
                // className={classes.chart}
            />
            <div className="userStatsFooter">
                <div className="dataMarker" />
                <div className="name">{I18N('open', language)}</div>
                <div className="dataMarker dark"/>
                <div className="name">{I18N('viewed', language)}</div>
            </div>
        </ViewBox>
    )

}
