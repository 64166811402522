import {Drawer, Switch, TextField, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import Select from "react-select/creatable";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from '../../ViewComponents/VideoEditComponents/Drawer';
import {useLanguageState} from "../../States/LanguageState";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {QuizRepository} from "../../Repositories/QuizRepository";
import CheckedIcon from "../../assets/checked.svg";
import NotChecked from "../../assets/notchecked.svg";
import plusLearningPlan from "../../assets/plusBlack.svg";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";

const sweetAlert = withReactContent(Swal)
const quizRepository = new QuizRepository();

const QuizQuestionBar = (props: {quiz: any; isOpen: boolean, setIsOpen: (status: boolean) => void, selectedQuestion: any; setContent: (data) => void;}) => {

    const language = useLanguageState();
    const [loadingData, setLoadingData] = useState(false);
    const theme = useTheme();
    const [newAnswerText, setNewAnswerText] = useState('');
    const refItem = useRef<HTMLDivElement>(null);

    const [formState, setFormState] = useState({
        id: 0,
        key: 0,
        question: '',
        answers: []
    });

    useEffect(() => {
        const question = props.selectedQuestion
        if(question){
            setFormState({
                key: question.key,
                id: question.id,
                question: question.question,
                answers: question.answers
            })
        }else{
            setFormState({
                id: 0,
                key: 0,
                question: '',
                answers: []
            })
        }


    }, [props.selectedQuestion]);


    const addNewQuestion = useCallback(() => {

        formState.answers.push({id: 0, answer: newAnswerText, correct: false})

        setFormState({...formState, answers: [...formState.answers]});

        refItem.current.scrollIntoView({ behavior: "smooth" });
        setNewAnswerText('');
    }, [formState, newAnswerText]);



    const saveChanges = useCallback(async () => {

        setLoadingData(true);
        console.log(formState);


        await quizRepository.updateQuestion({...formState}, props.quiz.id).then(response => {
            props.setContent(response);
            props.setIsOpen(false);
            setLoadingData(false);
            setFormState({
                id: 0,
                key: 0,
                question: '',
                answers: []
            })
        }).catch((e) => console.log(e));

        setLoadingData(false);

    }, [formState, props.quiz]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer style={{overflowX: "hidden"}}>
                    <div className="header">
                        <p>{I18N('changeDetails', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5, paddingBottom: 75}}>
                            <p style={{color: "#001E3C", fontSize: 14, fontWeight: 350}}>{formState.key + 1}. {I18N("question", language.language)}</p>
                            <TextField
                                fullWidth
                                label={I18N("question", language.language)}
                                style={{ marginTop: 20}}
                                value={formState.question}
                                onChange={(text) => {
                                    setFormState({...formState, question: text.target.value})
                                }}
                            />

                            <div style={{marginTop: 20}}>

                                {formState.answers.map((answer, keyData) => {
                                    return (
                                        <>
                                            <TextField
                                                fullWidth
                                                label={(keyData + 1) + ". "+I18N("answer", language.language)}
                                                style={{ marginTop: 20}}
                                                value={answer.answer}
                                                onChange={(text) => {
                                                    formState.answers[keyData].answer = text.target.value;
                                                    setFormState({...formState, answers: [...formState.answers]})
                                                }}
                                            />
                                            <p style={{marginTop: 7, display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                                                <p style={{color: "#828282", fontSize: 12, fontWeight: 350,margin:0, padding: 0}}>{I18N('correctAnswer', language.language)}  </p>
                                                <div style={{width: 58, height: 38, padding: 10}}>
                                                    <Switch inputProps={{ "aria-label": "secondary checkbox" }}
                                                            size={"medium"} color="success" checked={answer.correct} onChange={(event, checked) => {
                                                        formState.answers[keyData].correct =  checked;
                                                        setFormState({...formState, answers: [...formState.answers]});
                                                    }} checkedIcon={<img style={{margin:0}}  src={CheckedIcon} />} icon={<img style={{margin:0}} src={NotChecked} />} className="marginTopMinus10" />
                                                </div>

                                            </p>
                                        </>
                                    )
                                })}


                            </div>

                        </div>
                        <div ref={refItem} style={{paddingTop: 75}}></div>
                    </ViewBox>


                    <div style={{background: "#fff", zIndex:9, position: "fixed", width: '480px', paddingTop: 15, paddingBottom: 15, bottom: 35, paddingRight:10, paddingLeft: 10, borderRadius: 8, display: "flex", flexDirection: "row", alignContent: "center", alignItems: "center"}}>
                        <TextField
                            fullWidth
                            label={I18N("newAnswer", language.language)}
                            value={newAnswerText}
                            onKeyDown={(key) => {
                                if(key.key === "Enter"){
                                    addNewQuestion();
                                }
                            }}
                            onChange={(text) => {
                                setNewAnswerText(text.target.value)
                            }}
                        />
                        <div style={{cursor: "pointer", marginLeft: 30}} onClick={() => {
                            addNewQuestion();

                        }}><img src={plusLearningPlan} /></div>
                    </div>
                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default QuizQuestionBar;
