import Fab from '@mui/material/Fab';
import { Drawer, FormControlLabel, FormGroup, Switch } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Icon from '../Icon';
import Container from '../SettingsSideBar/Container';
import { useLanguageState } from '../../States/LanguageState';
import { I18N } from '../../i18n/i18n';
import ColorModeContext from '../../ColorMode';
import { useTheme } from '@mui/material';
import FormButton from '../FormButton';
import { SidebarContainer, SidebarDrawer } from '../VideoEditComponents/Drawer';
import { Android12Switch } from '../VideoEditComponents/Android12Switch';
import { DrawerConfigSx } from '../DrawerConfigSx';
import ViewBox from '../ViewBox';
import { DepartmentType } from '../../Types/DepartmentTypes';

const IconStyle = styled(Fab)(() => ({
  background: 'white',
  color: 'black',
  position: 'fixed'
}));

const PublishVideoBar = (props: {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  video: any;
  departments: any;
  onEnroll: (depsSelected: DepartmentType[], sendNotification: boolean) => void;
}) => {
  const language = useLanguageState();
  const [loading, setLoading] = useState(false);

  const [groupDeps, setGroupDeps] = useState({});

  const [deps, setDeps] = useState([]);

  const [sendNotification, setSendNotification] = useState(true);

  useEffect(() => {
    console.log(props.video);
    console.log(props.departments);

    if (props.video !== undefined && props.video !== null) {
      if (props.video.visibleForAll) {
        const createDepList = props.departments.map((i) => {
          return { ...i, status: true };
        });
        manupulateData(createDepList);
        setDeps(createDepList);
      } else {
        const createDepList = props.video.video_departments.map((i) => {
          return { ...i.department, status: true };
        });
        manupulateData(createDepList);
        setDeps(createDepList);
      }
    }
  }, [props.video, props.departments]);

  const manupulateData = useCallback(
    (depList) => {
      const companyHolder = {};

      const selectedCompanies = props.video.videos_companies.map(
        (i) => i.company_id
      );

      const pushToHolder = (companyName, depData) => {
        if (companyHolder[companyName] !== undefined) {
          companyHolder[companyName].push(depData);
        } else {
          companyHolder[companyName] = [depData];
        }
      };

      depList.forEach((i) => {
        i.departments_companies.forEach((comp) => {
          if (selectedCompanies.includes(comp.company.id)) {
            pushToHolder(comp.company.name, i);
          }
        });
      });
      setGroupDeps(companyHolder);
    },
    [props.video]
  );

  return (
    <SidebarContainer>
      <Drawer
        BackdropProps={{ invisible: true }}
        style={{ background: 'rgba(255,255,255,0)' }}
        sx={DrawerConfigSx}
        anchor="right"
        open={props.isOpen}
        onClose={() => props.setIsOpen(false)}
      >
        <SidebarDrawer>
          <div className="header">
            <p>{I18N('enrollLearningContent', language.language)}</p>
            <FormButton
              color="green"
              smallButton={true}
              text={I18N('enroll', language.language)}
              withLoadingInFullScreen
              onClick={async () => {
                setLoading(true);
                await props.onEnroll(
                  deps.filter((i) => i.status),
                  sendNotification
                );
                setLoading(false);
              }}
              isLoading={loading}
            />
          </div>
          <ViewBox>
            <div className="box" style={{ marginTop: 5 }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: 20,
                  marginBottom: 10
                }}
              >
                <p
                  style={{
                    fontSize: 19,
                    fontWeight: 'bold',
                    marginRight: 20,
                    padding: 0,
                    marginTop: -5
                  }}
                >
                  {I18N('sendNotification', language.language)}
                </p>
                <Android12Switch
                  value={sendNotification}
                  checked={sendNotification}
                  onChange={() => {
                    setSendNotification(!sendNotification);
                  }}
                  color="warning"
                />
              </div>

              <table
                style={{ width: '98%', borderSpacing: 0 }}
                className="depsTableData"
              >
                <thead style={{ width: '98%', display: 'none', height: 0 }}>
                  <tr style={{ borderRadius: 10 }}>
                    <th
                      style={{
                        padding: 15,
                        textAlign: 'left',
                        color: '#3E4649',
                        fontSize: 14,
                        fontWeight: 'bold'
                      }}
                    ></th>
                    <th
                      style={{
                        padding: 15,
                        textAlign: 'left',
                        color: '#3E4649',
                        fontSize: 14,
                        fontWeight: 'bold'
                      }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(groupDeps).map((i) => {
                    const isAllGroupSelected = groupDeps[i].map(
                      (innerGroup) => innerGroup.ID
                    );

                    let selected = true;

                    const companyDepList = deps.filter((innerDep) =>
                      isAllGroupSelected.includes(innerDep.ID)
                    );

                    companyDepList.forEach((innerCompanyDep) => {
                      if (!innerCompanyDep.status) {
                        selected = false;
                      }
                    });

                    return (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 20,
                            marginBottom: 10
                          }}
                        >
                          <p
                            style={{
                              fontSize: 19,
                              fontWeight: 'bold',
                              marginRight: 20,
                              padding: 0,
                              marginTop: -5
                            }}
                          >
                            {i}
                          </p>
                          <Android12Switch
                            value={selected}
                            checked={selected}
                            onChange={() => {
                              deps.forEach((innerDepData) => {
                                if (
                                  isAllGroupSelected.includes(innerDepData.ID)
                                ) {
                                  innerDepData.status = !selected;
                                }
                              });
                              setDeps([...deps]);
                            }}
                            color="warning"
                          />
                        </div>
                        {groupDeps[i].map((depData, indexData) => {
                          const indexOfEl = deps.findIndex(
                            (innerData) => innerData.ID === depData.ID
                          );
                          return (
                            <tr>
                              <td
                                style={{
                                  color: '#3E4649',
                                  fontSize: 16,
                                  maxWidth: 50,
                                  paddingLeft: 15,
                                  paddingBottom: 5
                                }}
                              >
                                {depData.name}
                              </td>
                              <td
                                style={{
                                  alignContent: 'flex-end',
                                  float: 'right'
                                }}
                              >
                                <Android12Switch
                                  value={deps[indexOfEl].ID}
                                  checked={deps[indexOfEl].status}
                                  onChange={() => {
                                    deps[indexOfEl].status =
                                      !deps[indexOfEl].status;
                                    setDeps([...deps]);
                                  }}
                                  color="warning"
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </ViewBox>
        </SidebarDrawer>
      </Drawer>
    </SidebarContainer>
  );
};

export default PublishVideoBar;
