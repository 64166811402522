export default function VideoTypeExporter(type: number){

    switch (type){
        case 1: {
            return "SCORM"
        }
        case 2: {
            return "PDF"
        }
        case 3: {
            return "MP4"
        }
        case 4: {
            return "QUIZ"
        }
    }

}