export default function WelcomeStepInner(props: {
    text: string
}){

    return (
        <div style={{height: 600, fontWeight: "bold", color: "#2F3640", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", fontSize: 22, alignSelf: "center"}}>
            {props.text}
        </div>
    )

}