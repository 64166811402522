import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {TextField} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import FormButton from "../../../ViewComponents/FormButton";

const saveKey = 'trophiesStepTech';

function TrophiesStepIn(props: {title}, ref){

    const language = useLanguageState(state => state.language);
    const [formState, setFormState] = useState([{
        name: '',
    }]);

    const clearData = useCallback(() => {

        setFormState([{
            name: '',
        }]);
        localStorage.removeItem(saveKey);

    }, []);

    const checkLocalStorage = useCallback(() => {

        const categoryTechData = localStorage.getItem(saveKey);
        if(categoryTechData){
            setFormState(JSON.parse(categoryTechData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formState));

    }, [formState]);

    return (
        <div style={{padding: 10, maxHeight: 380, minHeight: 380, overflowY: "scroll"}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>

            {formState.map((i, key) => {
                return (
                    <div style={{display: "flex", flexDirection: "row", marginTop: 10}}>
                        <TextField
                            fullWidth
                            label={I18N('name', language)}
                            style={{ marginTop: 0,color: "#fff", marginRight: 5 }}
                            value={formState[key].name}
                            onChange={(text) => {
                                formState[key].name = text.target.value;
                                setFormState([...formState]);
                            }}
                        />
                    </div>
                )
            })}

            <div style={{marginTop: 20}}>
                <FormButton text={I18N('new', language)} color="dark" icon="plus" onClick={() => {
                    formState.push({
                        name: '',
                    });
                    setFormState([...formState]);
                }} isLoading={false} />
            </div>



            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )

}

const TrophiesStep = React.forwardRef(TrophiesStepIn);

export default TrophiesStep;

