export const COOKIE_TOKEN = "client_token";

export function getToken(tokenCookies?: string, tokenName?: string): string | undefined{
    tokenCookies = `; ${getCookies()}`;
    if(!tokenCookies)
        return undefined;

    const parts = tokenCookies.split(`; ${tokenName !== undefined ? tokenName : COOKIE_TOKEN}=`);
    if (parts.length === 2) {
        const cookie = parts.pop().split(';').shift();
        try {
            return cookie;
        } catch (err) {
            expireCookie(tokenName !== undefined ? tokenName : COOKIE_TOKEN);
            return undefined;
        }
    }
}


export function getCookies(){
    return document.cookie;
}

export function expireCookie(cookieName: string): boolean{
    document.cookie = `${cookieName}=0; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;
    return true;
}

export function setCookie(cookieName: string, cookie: string, expire_at?: Date){
    let expire: string | Date = new Date();
    expire = new Date(expire.setDate(expire.getDate() + 15)).toUTCString();
    if(expire_at)
        expire = expire_at.toUTCString();

    document.cookie = `${cookieName}=${cookie}; expires=${expire}; path=/`;
}




