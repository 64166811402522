import {Drawer, TextField, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import Select from "react-select/creatable";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from '../../ViewComponents/VideoEditComponents/Drawer';
import {useLanguageState} from "../../States/LanguageState";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {QuizRepository} from "../../Repositories/QuizRepository";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";

const companyRepository = new CompanyRepository();
const sweetAlert = withReactContent(Swal)
const quizRepository = new QuizRepository();
const passPercentages = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

const GeneralInfoBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, quiz: any; setContent: (data) => void;}) => {

    const language = useLanguageState();
    const [loadingData, setLoadingData] = useState(false);
    const theme = useTheme();
    const [companies, setCompanies] = useState([]);


    const [formState, setFormState] = useState({
        name: '',
        status: undefined,
        percentage: undefined,
        companies: undefined,
    });

    useEffect(() => {

        setFormState({
            name: props.quiz.name,
            status: props.quiz.status,
            percentage: props.quiz.percentage,
            companies: props.quiz.companies
        })

    }, [props.quiz]);

    const loadCompanies = useCallback(async () => {
        const listOfCompanies = await companyRepository.getSubCompanies();
        setCompanies(listOfCompanies);
        return listOfCompanies;
    }, []);

    useEffect(() => {
        loadCompanies().then(undefined);
    }, [loadCompanies])

    const saveChanges = useCallback(async () => {

       setLoadingData(true);

       const buildNewObject = {
           name: formState.name,
           percentage: formState.percentage.value,
           status: formState.status.value,
           companies: formState.companies.map((i) => i.value)
       }

       await quizRepository.updateGeneral(buildNewObject, props.quiz.id).then((response) => {
           setLoadingData(false);
           setFormState({
               name: '',
               status: undefined,
               percentage: undefined,
               companies: undefined,
           });
           props.setContent(response);
           props.setIsOpen(false);
       }).catch((err) => {
           console.log(err);
           setLoadingData(false);
       })

    }, [formState, props.quiz]);

    return (
        <SidebarContainer>
            <Drawer  BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>

                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {/*name*/}
                            <TextField
                                fullWidth
                                label={I18N('name', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.name}
                                onChange={(text) => setFormState({...formState, name: text.target.value})}
                            />
                            {/*percentage*/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle">{I18N('percentage', language.language)}</p>

                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop10" styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} key="percentageSelect"
                                        isMulti={false}
                                        placeholder={I18N("passPercentage", language.language)}
                                        options={passPercentages.map((percentage) => { return {value: percentage, label: `${percentage}%`}})}
                                        value={formState.percentage} onChange={(change) => {
                                    setFormState({...formState, percentage: change})
                                }} />

                            </div>


                            {/**COMPANIES*/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle">{I18N("companies", language.language)}</p>
                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} styles={{ valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            height: 50,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} placeholder={I18N('companies', language.language)} className="fullWidthSelect marginTop10 " isMulti={true} key="subCompanySelect" options={companies.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.companies} onChange={(data, ) => setFormState({...formState, companies: data as any})} />
                            </div>

                            {/**Status**/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle">{I18N("status", language.language)}</p>
                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop10 " styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={false} options={[{label: I18N('active', language.language), value: 1}, {label: I18N('inActive', language.language), value: 0}]} value={formState.status} onChange={(change) => {
                                    setFormState({...formState, status: change})
                                }} />
                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralInfoBar;
