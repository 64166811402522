import ViewBox from "../../../ViewComponents/ViewBox";
import {I18N} from "../../../i18n/i18n";
import {useLanguageState} from "../../../States/LanguageState";
import {Backdrop, Chip, CircularProgress, TextField, useTheme} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Select from "react-select";
import SelectCreatable from "react-select/creatable";
import {UsersRepository} from "../../../Repositories/UsersRepository";
import {UserGroupsType, UserType} from "../../../Types/UserType";
import {DepartmentsRepository} from "../../../Repositories/DepartmentsRepository";
import {ListsRepository} from "../../../Repositories/ListsRepository";
import {UserListsType} from "../../../Types/UserListsTypes";
import {DepartmentType} from "../../../Types/DepartmentTypes";
import {standartColors} from "../../../assets/colors/Colors";
import {Grid, Switch} from "@mui/material";
import {PhishingRepository} from "../../../Repositories/PhishingRepository";
import FullButton from "../../../ViewComponents/FullButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import EmailOperation from "../../Templates/EmailOperation";
import LandingOperation from "../../Templates/LandingOperation";
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment/moment";
import {colorForSelect} from "../../../ComplinessTheme";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {Label} from "@material-ui/icons";
import {useBreadCrumb} from "../../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import SelectAutocomplete from "../../../ViewComponents/SelectAutoComplete";
import {VideoRepository} from "../../../Repositories/VideoRepository";
import {LearnVideosType} from "../../../Types/LearnVideosTypes";
import LanguageExporter from "../../../Helpers/LanguageExporter";

const userRespository = new UsersRepository();
const departmentsRepository = new DepartmentsRepository();
const userListRepository = new ListsRepository();
const phishingRepository = new PhishingRepository();
const learningContent = new VideoRepository();

const sweetAlert = withReactContent(Swal)


export default function CampaignOperation(props: {
    editMode: null | any
    editAllowed?: boolean;
    onChangeForm?: (formState) => void;
    closeCreate?: () => void;
}){

    const language = useLanguageState(state => state.language);
    const [formState, setFormState] = useState({
        learnContent: undefined, listConnection: undefined, designation: '', pushNow: false, executionDate: null, status: undefined, landingPage: undefined, email: undefined, person: undefined, userSelection: undefined, groupSelection: undefined, listSelection: undefined, departmentSelection: undefined});
    const [userList, setUserList] = useState<UserType[]>([]);
    const [learningContentList, setLearningContentList] = useState([]);
    const [lists, setLists] = useState<UserListsType[]>([]);
    const [departments, setDepartments] = useState<DepartmentType[]>([]);
    const [groups, setGroups] = useState<UserGroupsType[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingCreation, setLoadingCreation] = useState(false);
    const [status, setStatus] = useState({listSelection: false, department: false, groupSelection: false, userSelection: false});
    const [landingList, setLandingList] = useState([]);
    const [emailList, setEmailList] = useState([]);
    const theme = useTheme();
    const breadCrumb = useBreadCrumb();
    const history = useHistory();


    const loadContent = useCallback(async () => {

        const con = await learningContent.getAll(undefined);

        setLearningContentList(con as LearnVideosType[]);

    }, []);
    const saveOrUpdateCampaign = useCallback(async () => {

        const validationRulesForListing = {
            person: true,
            landingPage: true,
            email: true,
            status: true
        }

        let validationStatus = true;

        Object.keys(validationRulesForListing).forEach((item) => {

            if(formState[item] === undefined || formState[item] === null) {
                validationStatus = false;
            }
        })


        if(!validationStatus){
            await sweetAlert.fire({
                icon: 'error',
                title: I18N('error', language),
                html: I18N('pleaseFill', language, [{key:'name', value: I18N('campaign', language)}])
            })

            return;

        }



        const selections = {
            departments: [],
            lists: [],
            groups: [],
            users: [],
            list_connection: null
        };

        if(formState.listConnection){
            selections.list_connection = formState.listConnection.value;
        }

        if(formState.departmentSelection){
            selections.departments = formState.departmentSelection.map((item) => item.value);
        }
        if(formState.userSelection){
            selections.users = formState.userSelection.map((item) => item.value);
        }
        if(formState.listSelection){
            selections.lists = formState.listSelection.map((item) => item.value);
        }
        if(formState.groupSelection){
            selections.groups = formState.groupSelection.map((item) => item.value);
        }

        let statusOfSelections = false;
        Object.keys(selections).forEach(item => {
            if(selections[item].length > 0){
                statusOfSelections = true;
            }
        })


        if(!statusOfSelections){
            await sweetAlert.fire({
                icon: 'error',
                title: I18N('error', language),
                html: I18N('pleaseFill', language, [{key:'name', value: I18N('campaign', language)}])
            })

            return;

        }

        const executionTime = moment(formState.executionDate).format('YYYY-MM-DD')


        const builtObject = {
            responsible_user_id: formState.person.value,
            execution_date: executionTime,
            landing_page_id: formState.landingPage.value,
            email_id: formState.email.value,
            status: formState.status.value,
            designation: formState.designation,
            ...selections
        }

        if(formState.learnContent !== undefined && formState.learnContent !== null){
            builtObject['learnContent'] = formState.learnContent.value
        }

        setLoading(true);


        const createStatus = await phishingRepository.createCampaign(builtObject).then(() => {

            setLoading(false);
            if(props.closeCreate){
                props.closeCreate();
            }
        }).catch((err) => {
            console.log(fail);
        })

    }, [formState, language]);

    const createUserList = useCallback(async (name) => {
        setLoadingCreation(true);
        const create = await userListRepository.post({name: name, users: [], description: ' '}).catch(async () => {
            setLoadingCreation(false);
            await sweetAlert.fire({
                title: I18N('error', language),
                html: I18N('unexpectedError', language),
                icon: "error"
            });
        });
        setLoadingCreation(false);
        setFormState({...formState, listConnection: {value: create.id, label: create.name}});

    }, [formState]);

    const loadUsers = useCallback(async () => {
        setLoading(true);
        const uList =  userRespository.getAll();
        const permissionList =  userRespository.getGroups();
        const depList =  departmentsRepository.getAll();
        const listsData =  userListRepository.getAll();
        const emailListData = phishingRepository.getAllEmails();
        const landingListData = phishingRepository.getAllLandingPages();

        await Promise.all([uList,permissionList, depList, listsData, emailListData, landingListData]).then((data) => {
            setUserList(data[0] as UserType[]);
            setGroups(data[1]);
            setDepartments(data[2] as DepartmentType[]);
            setLists(data[3] as UserListsType[]);
            //@ts-ignore
            setLandingList(data[5]);
            //@ts-ignore
            setEmailList(data[4]);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });

        setLoading(false);

    }, []);

    useEffect(() => {
        Promise.all([loadUsers(), loadContent()]).then(undefined);
    }, [loadUsers, loadContent]);

    const updateLanding = useCallback((formModalUpdateData: any) => {
        console.log(formModalUpdateData);
        setFormState({...formState, landingPage: {value: formModalUpdateData.id, label: formModalUpdateData.designation}});
    }, [formState]);

    const updateEmail = useCallback((formModalUpdateData: any) => {
        console.log(formModalUpdateData);
        setFormState({...formState, email: {value: formModalUpdateData.id, label: formModalUpdateData.name + '('+formModalUpdateData.senderAddress+')'}});
    }, [formState]);

    useEffect(() => {
        breadCrumb.setChildren([
            {
                color: "lightGreen",
                onClick: () => {
                    saveOrUpdateCampaign();
                },
                icon: "save",
                title: I18N('createCampaign', language),
            }
        ]);
        breadCrumb.setBackButton(() => {
            if(props.closeCreate){
                props.closeCreate();
            }
        });
        breadCrumb.setTitle(I18N('createCampaign', language));
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('campaigns', language),
                onClick: () => () => {
                    if(props.closeCreate){
                        props.closeCreate();
                    }
                }
            }, ,{
                title: I18N('createCampaign', language),
                onClick: () => history.push('/phishing/campaigns')
            }])

    }, [saveOrUpdateCampaign]);


    return (
        <React.Fragment>
            <Backdrop
                open={loading}
                style={{ color: standartColors.primaryYellow, zIndex:1  }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>

                <div style={{width: '60%', marginRight: 10}}>
                    <ViewBox title={I18N('generalOperationCampaign', language)}>
                        <TextField
                            fullWidth
                            label={I18N('designation', language)}
                            style={{ marginTop: 20 }}
                            value={formState.designation}
                            onChange={(text) => setFormState({...formState, designation: text.target.value})}
                        />



                        <LocalizationProvider dateAdapter={AdapterDateFns} >
                            <Grid container spacing={2} direction={"row"}>
                                <Grid item md={7}>
                                    <DesktopDatePicker
                                        label={I18N('executionDate', language)}
                                        inputFormat="MM/dd/yyyy"
                                        value={formState.executionDate}
                                        disabled={formState.pushNow}
                                        onChange={(date) => setFormState({...formState, executionDate: date})}
                                        renderInput={(params) => <TextField fullWidth style={{marginTop: 20}} {...params} />}
                                    />
                                </Grid>
                                <Grid item md={2} style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: "center", marginTop: 20}}>
                                    <Switch checked={formState.pushNow} onClick={() => {
                                        if(formState.pushNow){
                                            setFormState({...formState, executionDate: null, pushNow: !formState.pushNow})
                                        }else{
                                            setFormState({...formState, executionDate: moment().format("YYYY/MM/DD"), pushNow: !formState.pushNow})
                                        }

                                    }} />
                                </Grid>
                                <Grid onClick={() => {
                                    if(formState.pushNow){
                                        setFormState({...formState, executionDate: null, pushNow: !formState.pushNow})
                                    }else{
                                        setFormState({...formState, executionDate: moment().format("YYYY/MM/DD"), pushNow: !formState.pushNow})
                                    }
                                }} item md={3} style={{cursor:'pointer', justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: "center", marginTop: 20}}>
                                    <Chip color={formState.pushNow ? "success" : "default"} variant={formState.pushNow ? 'filled' : 'outlined'} label= {I18N('pushNow', language)}/>
                                </Grid>

                            </Grid>
                        </LocalizationProvider>

                        {/*<DatePicker onChange={(date) => setFormState({...formState, executionDate: date})} value={formState.executionDate} renderInput={*/}
                        {/*    <TextField*/}
                        {/*        fullWidth*/}
                        {/*        label={I18N('executionDate', language)}*/}
                        {/*        style={{ marginTop: 20 }}*/}
                        {/*        value={formState.executionDate}*/}
                        {/*    />*/}
                        {/*} />*/}


                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop20" styles={{
                            valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },
                            control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} isMulti={false} placeholder={I18N('responsiblePerson', language)} options={userList.map((user) => {
                            return {label: user.firstName + ' ' + user.lastName, value: user.id}
                        })} value={formState.person} onChange={(change) => {
                            setFormState({...formState, person: change})
                        }} />


                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}}
                                isMulti={false} placeholder={I18N('learnContent', language)} options={learningContentList.map((list) => {
                            return {value: list.id, label: LanguageExporter(list, language, 'video_titles', 'title', 'title')}
                        })} value={formState.learnContent} onChange={(change) => {
                            setFormState({...formState, learnContent: change})
                        }} />

                        <SelectCreatable theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} isLoading={loadingCreation} onCreateOption={async (inputValue) => await createUserList(inputValue)} isMulti={false} placeholder={I18N('userList', language)} options={lists.map((list) => {
                            return {value: list.id, label: list.name}
                        })} value={formState.listConnection} onChange={(change) => {
                            setFormState({...formState, listConnection: change})
                        }} />

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} isMulti={false} placeholder={I18N('status', language)} options={[{value: 1, label: I18N('active', language)}, {value: 0, label: I18N('inactive', language)}]} value={formState.status} onChange={(change) => {
                            setFormState({...formState, status: change})
                        }} />

                    </ViewBox>
                    <ViewBox title={I18N('emails', language)}>

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} isMulti={false} isClearable={true} placeholder={I18N('emails', language)} options={emailList.map((email) => {
                            return {label: email.name + ` (${email.senderAddress})`, value: email.id}
                        })} value={formState.email} onChange={(change) => {
                            setFormState({...formState, email: change})
                        }} />

                        <div style={{display: "flex", justifyContent: "space-between", marginTop: 15}}>
                            <FullButton isLeft={true} text={I18N('showTemplate', language)} onClick={async () => {
                                await sweetAlert.fire({
                                    width: '85%',
                                    html: (<EmailOperation fromModal={true} disableAll={true} editMode={(emailList.find((eData) => eData.id === formState.email.value))}  />),
                                })
                            }} isLoading={false} disabled={!(formState.email !== undefined && formState.email !== null)}  buttonClass={"yellow"}/>

                            {props.editAllowed && (
                                <FullButton  isLeft={true} text={I18N('editTemplate', language)} onClick={async () => {
                                    await sweetAlert.fire({
                                        width: '85%',
                                        showConfirmButton: false,
                                        html: (<EmailOperation fromModal={true} onClose={() => sweetAlert.close()} fromModalUpdate={async (formModalUpdateData) => {
                                            await loadUsers().then(undefined);
                                            updateEmail(formModalUpdateData);
                                            sweetAlert.close();
                                        }}  editMode={(emailList.find((eData) => eData.id === formState.email.value))} />),
                                    })
                                }} isLoading={false}  disabled={!(formState.email !== undefined && formState.email !== null)}  buttonClass={"yellow"}/>
                            )}

                        </div>

                    </ViewBox>
                </div>
                <div style={{width: '90%', marginLeft: 10}}>
                    <ViewBox title={I18N("targetGroup", language)}>
                        <div style={{display: "flex"}}>
                            <div style={{width: 150, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 15}}><Switch checked={status.listSelection} onChange={(e, checked) => {
                                setStatus({...status, listSelection: checked});
                                if(!checked){
                                    setFormState({...formState, listSelection: undefined});
                                }
                            }} /></div>

                            <SelectAutocomplete
                                multiple={true}
                                maxTags={2}
                                options={lists.map((list) => {
                                    return {value: list.id, label: list.name}
                                })}
                                placeholder={I18N('userList', language)}
                                disabled={!status.listSelection}
                                value={formState.listSelection}
                                onChange={(change) => {
                                    setFormState({...formState, listSelection: change})
                                }} />


                            {/*<Select theme={(template) => {*/}
                            {/*    return {*/}
                            {/*        ...template,*/}
                            {/*        colors: {*/}
                            {/*            ...template.colors,*/}
                            {/*            ...colorForSelect(theme)*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*}} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {*/}
                            {/*        return {*/}
                            {/*            ...styles,*/}
                            {/*            height: 50,*/}
                            {/*            scrollbarWidth: "none",*/}

                            {/*            msOverflowStyle: "none",*/}
                            {/*            overflowX: "hidden",*/}
                            {/*            overflowY: "scroll"*/}
                            {/*        };*/}
                            {/*    },control: base => ({*/}
                            {/*        ...base,*/}
                            {/*        height: 55,*/}
                            {/*        minHeight: 55*/}
                            {/*    })}} isMulti={true} isDisabled={!status.listSelection} placeholder={I18N('userList', language)} options={lists.map((list) => {*/}
                            {/*    return {value: list.id, label: list.name}*/}
                            {/*})} value={formState.listSelection} onChange={(change) => {*/}
                            {/*    setFormState({...formState, listSelection: change})*/}
                            {/*}} />*/}
                        </div>

                        <div style={{display: "flex"}}>
                            <div style={{width: 150, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 15}}><Switch checked={status.department} onChange={(e, checked) => {
                                setStatus({...status, department: checked});
                                if(!checked){
                                    setFormState({...formState, departmentSelection: undefined});
                                }
                            }} /></div>

                            <SelectAutocomplete
                                multiple={true}
                                maxTags={2}
                                options={departments.map((department) => {
                                    return {label: department.name, value: department.ID}
                                })}
                                placeholder={I18N('departments', language)}
                                disabled={!status.department}
                                value={formState.departmentSelection}
                                onChange={(change) => {
                                    setFormState({...formState, departmentSelection: change})
                                }} />

                            {/*<Select theme={(template) => {*/}
                            {/*    return {*/}
                            {/*        ...template,*/}
                            {/*        colors: {*/}
                            {/*            ...template.colors,*/}
                            {/*            ...colorForSelect(theme)*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*}} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {*/}
                            {/*        return {*/}
                            {/*            ...styles,*/}
                            {/*            height: 50,*/}
                            {/*            scrollbarWidth: "none",*/}

                            {/*            msOverflowStyle: "none",*/}
                            {/*            overflowX: "hidden",*/}
                            {/*            overflowY: "scroll"*/}
                            {/*        };*/}
                            {/*    },control: base => ({*/}
                            {/*        ...base,*/}
                            {/*        height: 55,*/}
                            {/*        minHeight: 55*/}
                            {/*    })}} isMulti={true} isDisabled={!status.department} placeholder={I18N('departments', language)} options={departments.map((department) => {*/}
                            {/*    return {label: department.name, value: department.ID}*/}
                            {/*})} value={formState.departmentSelection} onChange={(change) => {*/}
                            {/*    setFormState({...formState, departmentSelection: change})*/}
                            {/*}} />*/}
                        </div>


                        <div style={{display: "flex"}}>
                            <div style={{width: 150, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 15}}><Switch checked={status.groupSelection} onChange={(e, checked) => {
                                setStatus({...status, groupSelection: checked});
                                if(!checked){
                                    setFormState({...formState, groupSelection: undefined});
                                }
                            }} /></div>

                            <SelectAutocomplete
                                multiple={true}
                                maxTags={2}
                                options={groups.map((group) => {
                                    return {label: group.name, value: group.id}
                                })}
                                value={formState.groupSelection}
                                placeholder={I18N('permissions', language)}
                                disabled={!status.groupSelection}
                                onChange={(change) => {
                                    setFormState({...formState, groupSelection: change})
                                }}  />


                            {/*<Select theme={(template) => {*/}
                            {/*    return {*/}
                            {/*        ...template,*/}
                            {/*        colors: {*/}
                            {/*            ...template.colors,*/}
                            {/*            ...colorForSelect(theme)*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*}} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {*/}
                            {/*        return {*/}
                            {/*            ...styles,*/}
                            {/*            height: 50,*/}
                            {/*            scrollbarWidth: "none",*/}

                            {/*            msOverflowStyle: "none",*/}
                            {/*            overflowX: "hidden",*/}
                            {/*            overflowY: "scroll"*/}
                            {/*        };*/}
                            {/*    },control: base => ({*/}
                            {/*        ...base,*/}
                            {/*        height: 55,*/}
                            {/*        minHeight: 55*/}
                            {/*    })}} isMulti={true} isDisabled={!status.groupSelection} placeholder={I18N('permissions', language)} options={groups.map((group) => {*/}
                            {/*    return {label: group.name, value: group.id}*/}
                            {/*})} value={formState.groupSelection} onChange={(change) => {*/}
                            {/*    setFormState({...formState, groupSelection: change})*/}
                            {/*}} />*/}
                        </div>


                        <div style={{display: "flex"}}>
                            <div style={{width: 150, display: "flex", alignContent: "center",justifyContent: "center", marginTop: 15}}><Switch checked={status.userSelection} onChange={(e, checked) => {
                                setStatus({...status, userSelection: checked});
                                if(!checked){
                                    setFormState({...formState, userSelection: undefined});
                                }
                            }} /></div>

                            <SelectAutocomplete
                                multiple={true}
                                maxTags={2}
                                placeholder={I18N('users', language)}
                                options={userList.map((user) => {
                                return {label: user.firstName + ' ' + user.lastName, value: user.id}
                                })}
                                value={formState.userSelection} onChange={(change) => {
                                setFormState({...formState, userSelection: change})
                                }}
                                disabled={!status.userSelection}
                            />

                            {/*<Select theme={(template) => {*/}
                            {/*    return {*/}
                            {/*        ...template,*/}
                            {/*        colors: {*/}
                            {/*            ...template.colors,*/}
                            {/*            ...colorForSelect(theme)*/}
                            {/*        }*/}
                            {/*    }*/}
                            {/*}} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {*/}
                            {/*        return {*/}
                            {/*            ...styles,*/}
                            {/*            height: 50,*/}
                            {/*            scrollbarWidth: "none",*/}

                            {/*            msOverflowStyle: "none",*/}
                            {/*            overflowX: "hidden",*/}
                            {/*            overflowY: "scroll"*/}
                            {/*        };*/}
                            {/*    },control: base => ({*/}
                            {/*        ...base,*/}
                            {/*        height: 55,*/}
                            {/*        minHeight: 55*/}
                            {/*    })}} isMulti={true} isDisabled={!status.userSelection} placeholder={I18N('users', language)} options={userList.map((user) => {*/}
                            {/*    return {label: user.firstName + ' ' + user.lastName, value: user.id}*/}
                            {/*})} value={formState.userSelection} onChange={(change) => {*/}
                            {/*    setFormState({...formState, userSelection: change})*/}
                            {/*}} />*/}
                        </div>

                    </ViewBox>
                    <ViewBox title={I18N('landingPages', language)}>

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} isMulti={false} isClearable={true} placeholder={I18N('landingPages', language)} options={landingList.map((landing) => {
                            return {label: landing.designation, value: landing.id}
                        })} value={formState.landingPage} onChange={(change) => {
                            setFormState({...formState, landingPage: change})
                        }} />

                        <div style={{display: "flex", justifyContent: "flex-start", marginTop: 15}}>
                            <FullButton isLeft={true} text={I18N('showTemplate', language)} onClick={async () => {
                                await sweetAlert.fire({
                                    width: '75%',
                                    html: (<LandingOperation fromModal={true} disableAll={true} editMode={(landingList.find((lData) => lData.id === formState.landingPage.value))} />),
                                })
                            }} isLoading={false} isSmaller={true} disabled={!(formState.landingPage !== undefined && formState.landingPage !== null)} buttonClass={"yellow"}/>
                            {props.editAllowed && (
                                <FullButton  isLeft={true} text={I18N('editTemplate', language)} onClick={async () => {
                                    await sweetAlert.fire({
                                        showConfirmButton: false,
                                        width: '75%',
                                        html: (<LandingOperation onClose={() => sweetAlert.close()} fromModalUpdate={async (formModalUpdateData) => {
                                            await loadUsers().then(undefined);
                                            updateLanding(formModalUpdateData);
                                            sweetAlert.close();
                                        }} fromModal={true} editMode={(landingList.find((lData) => lData.id === formState.landingPage.value))} />),
                                    })
                                }} isLoading={false}  isSmaller={true}  disabled={!(formState.landingPage !== undefined && formState.landingPage !== null)} buttonClass={"yellow"}/>
                            )}

                        </div>

                    </ViewBox>
                </div>

            </div>
        </React.Fragment>
    )
}
