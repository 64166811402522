import React, {useCallback, useEffect, useState} from 'react';
import ApiKeyList from "./ApiKeyList";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import {ApiKeyRepository} from "../Repositories/ApiKeyRepository";
import {ApiOperation} from "./ApiOperation";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";

const apiKeyRepository = new ApiKeyRepository();

type Props = {
    style: any;
    isMobile: () => boolean;
}



export function ApiKeyTab(props: Props) {
    const [loading, setLoading] = useState(false);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<null | any>(null);
    const language = useLanguageState(state => state.language);
    const [reloadStatus, setRealoadStatus] = useState(1);
    const {enqueueSnackbar} = useSnackbar();
    const breadCrumb = useBreadCrumb();

    const [userId, setUserId] = useState('');

    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                onReturnBackToView();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const backToApiTable = () => {
        setCreateOpen(false);
        setEditMode(null);
        scrollTop();
    }


    const createNew = useCallback(async () => {

        setLoading(true);
        if(editMode !== null) {
         await apiKeyRepository.update({user_id: userId}, editMode.id).then(() => {
                setRealoadStatus(reloadStatus + 1);
                setLoading(false);
                enqueueSnackbar(I18N("successfullyUpdatedReturning", language, [{ key: 'name', value: I18N('apikey', language) }]), {variant: "success"})
                onReturnBackToView();
            }).catch(() => {
                enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"})
                setLoading(false);
            });

        }else {
            await apiKeyRepository.post({user_id: userId}).then(() => {
                setRealoadStatus(reloadStatus + 1)
                setLoading(false);
                enqueueSnackbar(I18N("successfullyCreatedReturning", language, [{ key: 'name', value: I18N('apikey', language) }]), {variant: "success"})
                onReturnBackToView()
            }).catch(() => {
                enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"})
                setLoading(false);
            })


        }

    }, [editMode, reloadStatus, userId]);


    const onReturnBackToView = useCallback(() => {

        breadCrumb.setTitle(I18N('api_keys', language))
        breadCrumb.setBackButton(null);
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined},{
            title: I18N('api_keys', language),
            onClick: onReturnBackToView
        }]);
        breadCrumb.setChildren([
            {
                key: "apiKeyCreate",
                title: I18N('new', language),
                onClick: () => {
                    setCreateOpen(!createOpen);
                    scrollTop();
                },
                color: 'dark',
                icon: "plus"
            }
        ])
        backToApiTable();
    }, [language]);

    useEffect(() => {
        onReturnBackToView();
    }, []);

    return (
        <div style={props.style}>
            {createOpen ? (
                <ApiOperation
                    editMode={editMode}
                    onFinish={createNew}
                    backCallback={onReturnBackToView} onUserChange={(userId) => setUserId(userId)} />
            ): (<ApiKeyList setEditMode={(item) => {
                setEditMode(item);
                setCreateOpen(true);
            }} reload={reloadStatus} />)}
        </div>
    )

}
