import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PaginationRequestType} from "../Types/PaginationTypes";

export class CompanyRepository implements  AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }


    getSubCompanies(pagination?: PaginationRequestType, extractAdmin: boolean = true): Promise<any>{

        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/subcompany${!extractAdmin ? `?includeAdmin=false` : ''}${pagination !== undefined ? `${!extractAdmin ? '&' : '?'}page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getGlobalSettings(): Promise<{key: string, value: string}[]>{

        return new Promise((resolve, reject) => {

            axios.get("/company/getGlobalSettings").then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        })

    }

    updateGlobalSettings(key: string, value: string){

        return new Promise((resolve, reject) => {

            axios.post("/company/updateGlobalSettings", {key: key, value: value}).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        })

    }



    getLicenses(pagination?: {page: number, limit: number}): Promise<any>{

        return new Promise((resolve, reject) => {

                axios.get(`/company/licenses${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}` : ''}`).then((response) => {
                    return resolve(response.data);
                }).catch((error) => {
                    return reject(error);
                })
        });

    }

    createLicense(name: any): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            axios.post('/company/licenses', {name: name}).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            });
        });

    }

    updateLicense(name: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/company/licenses/'+id, {name: name}).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }


    deleteLicense(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete('/company/licenses/'+id).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }


    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete('/company/client/'+id).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    deleteCompanyForClient(id: number | string, unlink: undefined | boolean): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.delete('/company/'+id, {
                data: {
                    unlink: unlink
                }
            }).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.get('/company').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getAllAdmin(pagination?: {page: number, limit: number}): Promise<any[]> {
        return new Promise((resolve, reject) => {

            axios.get(`/company/admin${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/company', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }


    createClient(object: any, tokenURL: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if(tokenURL.token !== undefined && tokenURL.url !== undefined){
                axios.post(`${tokenURL.url}/company/createClient`, object, {
                    headers: {
                        Authorization: tokenURL.token.includes('Bearer') ? tokenURL.token : `Bearer ${tokenURL.token}`
                    }
                }).then((response) => {
                    return resolve(response.data);
                }).catch((error) => {
                    return reject(error);
                })
            }else{
                axios.post('/company/createClient', object).then((response) => {
                    return resolve(response.data);
                }).catch((error) => {
                    return reject(error);
                })
            }

        });
    }

    updateClient(object: any, clientId): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/company/updateClient/'+clientId, object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    uploadPicture(file: File): Promise<string>{
        return new Promise((resolve, reject) => {

            const fileHolder = new FormData();
            fileHolder.append('file', file);

            axios.post('/company/uploadlogo', fileHolder, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return resolve(response.data.image);
            }).catch((error) => {
                return reject(error);
            });

        });
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/company/'+id, object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

}
