import {Drawer, TextField, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import Select from "react-select/creatable";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from '../../ViewComponents/VideoEditComponents/Drawer';
import {useLanguageState} from "../../States/LanguageState";
import {learningPlanFormState} from "../LearningPlanEdit";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import {DepartmentType} from "../../Types/DepartmentTypes";
import {DepartmentsRepository} from "../../Repositories/DepartmentsRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {LearningPlanRepository} from "../../Repositories/LearningPlanRepository";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";
import {useLoginState} from "../../States/UserState";
import SelectAutocomplete from "../../ViewComponents/SelectAutoComplete";

const companyRepository = new CompanyRepository();
const departmentsRepository = new DepartmentsRepository();
const sweetAlert = withReactContent(Swal)
const learningPlanRepository = new LearningPlanRepository();

const GeneralContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, plan: any; setContent: (data) => void;}) => {

    const language = useLanguageState();
    const [loadingData, setLoadingData] = useState(false);
    const [hasError, setHasError] = useState(false);
    const theme = useTheme();
    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);
    const authService = useLoginState();
    const [formState, setFormState] = useState<learningPlanFormState>({
        description: '',
        department: undefined,
        status: {label: I18N('active', language.language), value: 1},
        trigger: undefined,
        companies: undefined
    });

    useEffect(() => {
        console.log(formState);
    },[formState]);

    const globalTriggers = [
        {label: I18N('trigger_userCreated', language.language), value: 'userCreated'},
        {label: I18N('trigger_userMoved', language.language), value: 'userMoved'},
        {label: I18N('trigger_userFinished', language.language), value: 'userFinished'}
    ]



    const loadDepartments = useCallback(async () => {

        const depList = await departmentsRepository.getAll();
        setDepartments(depList as DepartmentType[]);
        return departments;

    }, []);
    const loadCompanies = useCallback(async () => {
        const listOfCompanies = await companyRepository.getSubCompanies();
        const userComp = listOfCompanies.filter((a) => authService.company.includes(a.id));
        setCompanies(userComp);
        return userComp;
    }, [authService]);

    //After loading all do this
    useEffect(() => {

        Promise.all([loadCompanies(), loadDepartments()]).then((response) => {

            setFormState({
                department: props.plan.depList.map((item) => {
                    return {label: item.name, value: item.ID}
                }),
                description: props.plan.description,
                status: {label: I18N(props.plan.status ? 'active' : 'inActive', language.language), value: props.plan.status},
                trigger: {label: globalTriggers.find((gTrigger) => gTrigger.value === props.plan.trigger).label, value: props.plan.trigger},
                companies: props.plan.learningplans_companies.map((i) => {
                    return {
                        label: i.company.name,
                        value: i.company.id
                    }
                })
            });

        }).catch(err => console.log(err))

    }, []);



    const saveChanges = useCallback(async () => {

        const updatedFormState = JSON.parse(JSON.stringify(formState));


        if(updatedFormState.description === '' || updatedFormState.department === undefined ||  updatedFormState.department === null || updatedFormState.department.length < 1 || updatedFormState.trigger === undefined || updatedFormState.trigger === null || updatedFormState.companies === undefined || updatedFormState.companies === null || updatedFormState.companies.length < 1){

            setHasError(true);
            return;

        }



        updatedFormState.department = updatedFormState.department.map((item) => item.value);
        updatedFormState.status = !!updatedFormState.status.value;
        updatedFormState.trigger = updatedFormState.trigger.value;
        updatedFormState.companies = updatedFormState.companies.map((i) => i.value);

        await learningPlanRepository.updateGeneralInfo(updatedFormState, props.plan.id).then(async (response) => {
            setLoadingData(false);
            sweetAlert.fire({
                title: I18N('success', language.language),
                html: I18N('successfullyUpdatedReturning', language.language, [{ key: 'name', value: I18N('learningPlan', language.language) }]),
                icon: "success"
            });
            setLoadingData(false);
            props.setIsOpen(false);
            props.setContent(response)
        }).catch(async (err) => {
            setLoadingData(false);
            await sweetAlert.fire({
                title: I18N('error', language.language),
                html: I18N('unexpectedError', language.language),
                icon: "error"
            });
            setLoadingData(false);
        })

    }, [formState]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {/*name*/}
                            <TextField
                                fullWidth
                                label={I18N('description', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.description}
                                error={hasError && formState.description === ''}
                                onChange={(text) => setFormState({...formState, description: text.target.value})}
                            />
                            {/*TRIGGER*/}
                            <div className="marginTop20">
                                <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('trigger', language.language)}</div>

                                <NoCreateSelect theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop10 " styles={{control: base => ({
                                        ...base,
                                        borderColor: (hasError && (formState.trigger === null || formState.trigger === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",
                                        height: 55,
                                        minHeight: 55
                                    })}}
                                                placeholder={I18N('trigger', language.language)}
                                                isMulti={false}
                                                options={globalTriggers}
                                                value={formState.trigger}
                                                onChange={(change) => setFormState({...formState, trigger: change})}
                                />

                            </div>

                            {/*DEPARTMENTS*/}
                            <div className="marginTop20">
                                <div className="viewBoxTitle"  style={{fontSize: 13}}>{I18N('departmentSelection', language.language)}</div>

                                <SelectAutocomplete
                                    multiple={true}
                                    maxTags={3}
                                    options={departments.map((department) => { return {value: department.ID, label: department.name}})}
                                    value={formState.department}
                                    onChange={(item) => setFormState({...formState, department: item})}
                                />

                                {/*<NoCreateSelect theme={(template) => {*/}
                                {/*    return {*/}
                                {/*        ...template,*/}
                                {/*        colors: {*/}
                                {/*            ...template.colors,*/}
                                {/*            ...colorForSelect(theme)*/}
                                {/*        }*/}
                                {/*    }*/}
                                {/*}} className="fullWidthSelect marginTop10" styles={{*/}
                                {/*    valueContainer: (styles) => {*/}
                                {/*        return {*/}
                                {/*            ...styles,*/}
                                {/*            height: 50,*/}
                                {/*            scrollbarWidth: "none",*/}
                                {/*            msOverflowStyle: "none",*/}
                                {/*            overflowX: "hidden",*/}
                                {/*            overflowY: "scroll"*/}
                                {/*        };*/}
                                {/*    },*/}
                                {/*    control: base => ({*/}
                                {/*        ...base,*/}
                                {/*        height: 55,*/}
                                {/*        minHeight: 55,*/}
                                {/*        borderColor: (hasError && (formState.department === null || formState.department === undefined || formState.department.length < 1)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",*/}
                                {/*    })}} isMulti={true} placeholder={I18N('departmentSelection', language.language)} options={departments.map((department) => { return {value: department.ID, label: department.name}})} value={formState.department} onChange={(change) => {*/}
                                {/*    //@ts-ignore*/}
                                {/*    setFormState({...formState, department: change})*/}
                                {/*}} />*/}

                            </div>


                            {/**COMPANIES*/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle">{I18N("companies", language.language)}</p>
                                <SelectAutocomplete
                                    multiple={true}
                                    maxTags={3}
                                    options={companies.map((comp) => { return {value: comp.id, label: comp.name}})}
                                    value={formState.companies}
                                    onChange={(item) => setFormState({...formState, companies: item})}
                                />
                                {/*<NoCreateSelect theme={(template) => {*/}
                                {/*    return {*/}
                                {/*        ...template,*/}
                                {/*        colors: {*/}
                                {/*            ...template.colors,*/}
                                {/*            ...colorForSelect(theme)*/}
                                {/*        }*/}
                                {/*    }*/}
                                {/*}} styles={{ valueContainer: (styles) => {*/}
                                {/*        return {*/}
                                {/*            ...styles,*/}
                                {/*            height: 50,*/}
                                {/*            scrollbarWidth: "none",*/}

                                {/*            msOverflowStyle: "none",*/}
                                {/*            overflowX: "hidden",*/}
                                {/*            overflowY: "scroll"*/}
                                {/*        };*/}
                                {/*    },control: base => ({*/}
                                {/*        ...base,*/}
                                {/*        height: 55,*/}
                                {/*        minHeight: 55,*/}
                                {/*        borderColor: (hasError && (formState.companies === null || formState.companies === undefined || formState.companies.length < 1)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",*/}
                                {/*    })}} placeholder={I18N('companies', language.language)} className="fullWidthSelect marginTop10 " isMulti={true} key="subCompanySelect" options={companies.map((comp) => { return {value: comp.id, label: comp.name}})} value={formState.companies} onChange={(data, ) => setFormState({...formState, companies: data as any})} />*/}
                            </div>

                            {/**Status**/}
                            <div className="marginTop20">
                                <p className="viewBoxTitle">{I18N("status", language.language)}</p>
                                <NoCreateSelect theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop10 " styles={{control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55,
                                        borderColor: (hasError && (formState.status === null || formState.status === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",
                                    })}} isMulti={false} options={[{label: I18N('active', language.language), value: 1}, {label: I18N('inActive', language.language), value: 0}]} value={formState.status} onChange={(change) => {
                                    setFormState({...formState, status: change})
                                }} />
                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralContentBar;
