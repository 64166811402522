import React, { useCallback, useEffect, useRef, useState } from 'react';
import { I18N } from '../i18n/i18n';
import { VideoRepository } from '../Repositories/VideoRepository';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { UserContentDataRepository } from '../Repositories/UserContentDataRepository';
import ReactPlayer from 'react-player';
import { useLanguageState } from '../States/LanguageState';
import PlaySVG from '../assets/play.svg';
import PauseSVG from '../assets/pause.svg';
import Back10 from '../assets/back10.png';
import { secondsToHms } from '../Helpers/SecondsToPrettyTime';
import { standartColors } from '../assets/colors/Colors';
import { Backdrop, CircularProgress } from '@mui/material';

const videoRepository = new VideoRepository();
const sweetAlert = withReactContent(Swal);
const userContentDataRepository = new UserContentDataRepository();

type Props = {
  video: any;
  returnBack: () => void;
  content: any;
  callQuiz: (contentId: number) => void;
  isPreview?: boolean;
};

type State = {};

export function VideoPlayer(props: Props) {
  let oldProgress = 0;
  const [isPlaying, setIsPlaying] = useState(false);
  const language = useLanguageState((state) => state.language);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [fullLoading, setFullLoading] = useState(false);
  const loadVideo = useCallback(async () => {
    const getVideoURL = await videoRepository.getContentUrl(props.video.id);

    if (getVideoURL.status) {
      setVideoUrl(getVideoURL.url);
    } else {
      await sweetAlert.fire({
        title: I18N('error', language),
        html: I18N('errorWhileLoadingContent', language),
        icon: 'error'
      });
    }
  }, [props.content]);

  useEffect(() => {
    loadVideo().then(undefined);
  }, [loadVideo]);

  const markVideoAsSeen = useCallback(async () => {
    if (props.video.quizes !== null && props.video.quizes !== undefined) {
      setFullLoading(true);
      await videoRepository.setVideoToQuiz(props.video.id);
      setFullLoading(false);
      props.callQuiz(props.video.id);
    } else {
      await sweetAlert
        .fire({
          title: I18N('success', language),
          html: I18N('youHaveFinishedTheVideo', language),
          icon: 'question',
          showConfirmButton: true,
          confirmButtonText: I18N('finished', language),
          showCancelButton: true,
          cancelButtonText: I18N('cancel', language)
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await videoRepository
              .markAsSeen(props.video.id)
              .then(() => undefined);
            await sweetAlert.fire({
              title: I18N('success', language),
              html: I18N('successfullyFinished', language),
              icon: 'success'
            });
            props.returnBack();
          }
        });
    }
  }, [props.video.id, language]);

  const checkPreviousProgress = useCallback(() => {
    if (playerRef.current !== null) {
      if (props.content !== null) {
        const contentJson = JSON.parse(props.content);
        const getTotal = playerRef.current.getSecondsLoaded();
        if (getTotal >= contentJson.playedSeconds) {
          playerRef.current.seekTo(contentJson.played, 'fraction');
        }
      }
    }
  }, [props.content]);

  useEffect(() => {
    if (props.isPreview) return;
    checkPreviousProgress();
  }, [checkPreviousProgress, playerRef, props.content, props.video]);

  const saveVideoProgress = useCallback(
    async (progress) => {
      if (props.isPreview) return;
      console.log(progress.playedSeconds, oldProgress);
      if (progress.playedSeconds - 4 >= oldProgress) {
        if (progress.playedSeconds !== 0) {
          const saveData = await userContentDataRepository.update({
            data: JSON.stringify(progress),
            content_id: props.video.id
          });
        }
        oldProgress = progress.playedSeconds;
      }
    },
    [props.video, oldProgress]
  );

  const back10secs = useCallback(() => {
    if (playerRef.current !== null) {
      const currentTime = playerRef.current.getCurrentTime();
      if (currentTime - 10 >= 0) {
        playerRef.current.seekTo(currentTime - 10, 'seconds');
      } else {
        playerRef.current.seekTo(0);
      }
    }
  }, [playerRef]);

  return (
    <React.Fragment>
      <Backdrop
        open={fullLoading}
        style={{ color: standartColors.primaryYellow, zIndex: 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {videoUrl !== null && (
        <div
          onClick={() => setIsPlaying(!isPlaying)}
          style={{ position: 'relative' }}
          className={'onlyHoverVideo'}
        >
          <div className={`playPause ${isPlaying ? 'onlyHover' : ''}`}>
            {isPlaying ? (
              <React.Fragment>
                <img
                  style={{ filter: 'brightness(0) invert(1)', marginRight: 20 }}
                  onClick={back10secs}
                  src={Back10}
                  width={50}
                  height={50}
                />
                <img src={PauseSVG} width={50} height={50} />
              </React.Fragment>
            ) : (
              <img width={50} height={50} src={PlaySVG} />
            )}
          </div>
          <div className="currentTime">
            {secondsToHms(currentTime)} / {secondsToHms(totalTime)}
          </div>

          <ReactPlayer
            ref={playerRef}
            onReady={() => {
              checkPreviousProgress();
              setPlayerLoaded(true);
            }}
            onSeek={() => {
              if (playerLoaded) {
                setIsPlaying(true);
              }
            }}
            onPause={() => setIsPlaying(false)}
            onStart={() => setIsPlaying(true)}
            onDuration={(duration) => setTotalTime(duration)}
            playing={isPlaying}
            loop={false}
            style={{ pointerEvents: 'none' }}
            width={'100%'}
            height={'100%'}
            onEnded={markVideoAsSeen}
            onProgress={(progress) => {
              saveVideoProgress(progress).then(undefined);
              setCurrentTime(progress.playedSeconds);
            }}
            controls={false}
            url={videoUrl}
          />
        </div>
      )}
    </React.Fragment>
  );
}
