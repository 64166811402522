import ViewBox from '../../ViewComponents/ViewBox';
import { I18N } from '../../i18n/i18n';
import FormButton from '../../ViewComponents/FormButton';
import Select from 'react-select';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguageState } from '../../States/LanguageState';
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
  useTheme
} from '@mui/material';
import { UsersRepository } from '../../Repositories/UsersRepository';
import { DepartmentsRepository } from '../../Repositories/DepartmentsRepository';
import { LanguageRepository } from '../../Repositories/LanguageRepository';
import { DepartmentType } from '../../Types/DepartmentTypes';
import DragAndDropUploader from './DragAndDropUploader';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { UserType } from '../../Types/UserType';
import { Icon } from '@iconify/react';
import randomIcon from '@iconify-icons/eva/shuffle-fill';
import eyeFill from '@iconify-icons/eva/eye-fill';
import eyeOffFill from '@iconify-icons/eva/eye-off-fill';
import { colorForSelect } from '../../ComplinessTheme';
import { styled } from '@mui/material/styles';
import { ChildrenType, useBreadCrumb } from '../../States/BreadCrumbState';
const sweetAlert = withReactContent(Swal);

const userRepository = new UsersRepository();
const departmentRepository = new DepartmentsRepository();
const languageRepository = new LanguageRepository();

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

export default function ImportUsers(props: { onFinish: () => void }) {
  const theme = useTheme();
  const [departments, setDepartments] = useState<DepartmentType[]>([]);
  const breadCrumb = useBreadCrumb();
  const [sendEmail, setSendEmail] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [uList, setUList] = useState([]);
  const [upKey, setUpKey] = useState(1);
  const [errorCalculator, setErrorCalculator] = useState([]);
  const [alreadyCreatedUsers, setAlreadyCreatedUsers] = useState<UserType[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  //Load departments
  const loadDepartments = useCallback(async (returnData?: boolean) => {
    const depList = await departmentRepository.getAll();
    setDepartments(depList as DepartmentType[]);
    if (returnData) {
      return depList;
    }
  }, []);

  //Loading languages
  const loadLanguages = useCallback(async () => {
    const languages = await languageRepository.getAll();
    setLanguages(languages);
  }, []);

  //Loading groups
  const loadGroups = useCallback(async () => {
    const groupList = await userRepository.getGroups();
    setGroups(groupList);
  }, []);

  const loadUsers = useCallback(async () => {
    const userData = await userRepository.getAll();
    setAlreadyCreatedUsers(userData as UserType[]);
  }, []);

  useEffect(() => {
    loadDepartments().then(undefined);
    loadGroups().then(undefined);
    loadLanguages().then(undefined);
    loadUsers().then(undefined);
  }, []);

  const language = useLanguageState((state) => state.language);

  const importLastCheck = useCallback(
    async (getProblems, withTags) => {
      getProblems.forEach((item, index) => {
        withTags.forEach((itemWithTags, indexInner) => {
          if (
            item.username === itemWithTags.username ||
            item.email === itemWithTags.email
          ) {
            withTags.splice(indexInner, 1);
          }
        });
      });

      let depList = [];
      withTags.forEach((user, indexOfUser) => {
        const iDep = departments.findIndex(
          (item) => item.name === user.department
        );

        if (iDep === -1) {
          if (depList.findIndex((i) => i.name === user.department) === -1) {
            depList.push({ name: user.department, indexOfUser: indexOfUser });
          }
        }
      });

      if (depList.length > 0) {
        await sweetAlert
          .fire({
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            width: 600,
            reverseButtons: true,
            confirmButtonText: I18N('yes', language),
            cancelButtonText: I18N('no', language),
            title: I18N('willCreateDeps', language),
            html: (
              <>
                <table style={{ textAlign: 'center', width: '100%' }}>
                  {depList.map((i) => {
                    return (
                      <tr style={{ textAlign: 'center', width: '100%' }}>
                        <td>{i.name}</td>
                      </tr>
                    );
                  })}
                </table>
                <p>{I18N('followingDepartmentsWillBeCreated', language)}</p>
              </>
            ),
            icon: 'warning'
          })
          .then(async (response) => {
            if (response.isConfirmed) {
              setLoading(true);
              for (const i of depList) {
                await departmentRepository.post({ name: i.name });
              }
              setLoading(false);
            } else {
              depList.forEach((i) => {
                withTags[i.indexOfUser].department = '';
              });
            }
          });

        setLoading(true);
        const depData: any = await loadDepartments(true);
        setLoading(false);
        withTags = withTags.map((i) => {
          const findDep = depData.find((item) => item.name === i.department);
          return {
            ...i,
            department: findDep
              ? { label: findDep.name, value: findDep.ID }
              : undefined
          };
        });
      } else {
        withTags = withTags.map((i) => {
          console.log(i);
          const findDep = departments.find(
            (item) => item.name === i.department
          );
          return {
            ...i,
            department: findDep
              ? { label: findDep.name, value: findDep.ID }
              : undefined
          };
        });
      }

      if (withTags.length > 0) {
        setUList([...withTags]);
      } else {
        sweetAlert.fire({
          icon: 'info',
          title: I18N('noUserLeftToImport', language),
          html: I18N('noUserLeftToImportText', language)
        });

        setUpKey(upKey + 1);
      }
    },
    [departments, language, loadDepartments, upKey]
  );

  const checkWithDB = useCallback(
    async (duplicateDatabase, getProblems, withTags) => {
      if (duplicateDatabase.length > 0) {
        await sweetAlert
          .fire({
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: true,
            width: 600,
            reverseButtons: true,
            title: I18N('foundUsersDuplicateDatabase', language),
            html: (
              <>
                <table>
                  <tr>
                    <th>{I18N('username', language)}</th>
                    <th>{I18N('firstName', language)}</th>
                    <th>{I18N('lastName', language)}</th>
                    <th>{I18N('emailAddress', language)}</th>
                  </tr>
                  {duplicateDatabase.map((i) => {
                    return (
                      <tr>
                        <td>{i.username}</td>
                        <td>{i.name}</td>
                        <td>{i.surname}</td>
                        <td>{i.email}</td>
                      </tr>
                    );
                  })}
                </table>
                <p>{I18N('foundUsersDuplicateDatabaseText', language)}</p>
              </>
            ),
            icon: 'warning'
          })
          .then(() => {
            duplicateDatabase.forEach((itemDB) => {
              const tagInner = withTags.findIndex(
                (itUser) =>
                  itemDB.username === itUser.username ||
                  itemDB.email === itUser.email
              );
              if (tagInner !== -1) {
                withTags.splice(tagInner, 1);
              }
            });
            importLastCheck(getProblems, withTags);
          });
      } else {
        importLastCheck(getProblems, withTags);
      }
    },
    [importLastCheck, language]
  );

  const getMatchingProblems = useCallback(
    (userWithTags: any[], alreadyCreatedUsers: UserType[]) => {
      const duplicateUsers = [];

      userWithTags.forEach((item, index) => {
        let compareResult = userWithTags.filter((itemSecond, indexInner) => {
          return (
            (itemSecond.username === item.username ||
              item.email === itemSecond.email) &&
            indexInner !== index
          );
        });

        compareResult.forEach((compareItem, compareIndex) => {
          const indexOfDup = duplicateUsers.findIndex(
            (duplicateItem, duplicateIndex) => {
              return (
                compareItem.email === duplicateItem.email ||
                compareItem.username === duplicateItem.username
              );
            }
          );

          if (indexOfDup === -1) {
            duplicateUsers.push(compareItem);
          }
        });
      });

      return duplicateUsers;
    },
    []
  );

  const whenDataUploaded = useCallback(
    async (data) => {
      let withTags = [];

      const firstColumn = data.data[0];

      delete data.data[0];

      data.data.forEach((item) => {
        const innerBuild = {};
        firstColumn.forEach((i, key) => {
          innerBuild[i] = item[key];
        });
        withTags.push(innerBuild);
      });

      withTags = withTags.filter((item) => {
        return !(item.username === '' || item.email === '');
      });

      withTags = withTags.map((i) => {
        const language = languages.find((item) => item.code === i.language);
        let group = groups.find((item) => item.name === i.group);

        if (!group) {
          group = groups.find((item) => item.name === 'User');
        }

        return {
          ...i,
          username: i.username,
          language: language
            ? { label: language.name, value: language.code }
            : undefined,
          group: group ? [{ label: group.name, value: group.id }] : undefined
        };
      });

      const getProblems = getMatchingProblems(withTags, alreadyCreatedUsers);
      console.log(getProblems);

      const duplicateDatabase = [];
      withTags.forEach((item) => {
        if (
          alreadyCreatedUsers.findIndex(
            (itemDatabase) =>
              itemDatabase.email === item.email ||
              itemDatabase.username === item.username
          ) !== -1
        ) {
          duplicateDatabase.push(item);
        }
      });

      if (getProblems.length > 0) {
        await sweetAlert
          .fire({
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            width: 600,
            reverseButtons: true,
            confirmButtonText: I18N('yes', language),
            cancelButtonText: I18N('no', language),
            title: I18N('foundUsersDuplicate', language),
            html: (
              <>
                <table style={{ textAlign: 'center' }}>
                  <tr>
                    <th>{I18N('username', language)}</th>
                    <th>{I18N('firstName', language)}</th>
                    <th>{I18N('lastName', language)}</th>
                    <th>{I18N('emailAddress', language)}</th>
                  </tr>
                  {getProblems.map((i) => {
                    return (
                      <tr>
                        <td>{i.username}</td>
                        <td>{i.name}</td>
                        <td>{i.surname}</td>
                        <td>{i.email}</td>
                      </tr>
                    );
                  })}
                </table>
                <p>{I18N('foundUsersDuplicateText', language)}</p>
              </>
            ),
            icon: 'warning'
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await checkWithDB(duplicateDatabase, getProblems, withTags);
            } else {
              setUpKey(upKey + 1);
            }
          });
      } else {
        await checkWithDB(duplicateDatabase, getProblems, withTags);

        //setUList([...withTags]);
      }
    },
    [
      getMatchingProblems,
      alreadyCreatedUsers,
      departments,
      languages,
      groups,
      language,
      checkWithDB,
      upKey
    ]
  );

  const setUserData = useCallback(
    (
      type:
        | 'username'
        | 'name'
        | 'surname'
        | 'email'
        | 'phone'
        | 'language'
        | 'department'
        | 'group',
      index: number,
      data: any
    ) => {
      const stepData = uList;
      const selectedStep = uList[index];

      switch (type) {
        case 'username': {
          selectedStep.username = data;
          break;
        }
        case 'name': {
          selectedStep.name = data;
          break;
        }
        case 'surname': {
          selectedStep.surname = data;
          break;
        }
        case 'email': {
          selectedStep.email = data;
          break;
        }
        case 'phone': {
          selectedStep.phone = data;
          break;
        }
        case 'language': {
          selectedStep.language = data;
          break;
        }
        case 'department': {
          selectedStep.department = data;
          break;
        }
        case 'group': {
          selectedStep.group = data;
          break;
        }
      }

      stepData[index] = selectedStep;
      setUList([...stepData]);
    },
    [uList]
  );

  const deleteRow = useCallback(
    (index: number) => {
      uList.splice(index, 1);
      setUList([...uList]);
    },
    [uList]
  );

  const normalizeUserName = useCallback((userName: string) => {
    return userName.replace(/[^a-z._A-Z ]/g, '');
  }, []);

  const startImportingUsers = useCallback(async () => {
    setLoading(true);
    const mapNewUList = uList.map((user) => {
      // MN - COMMENT THIS OUT
      return {
        ...user,
        username: user.username,
        department: user.department.value,
        language: user.language.value,
        group: user.group.map((i) => i.value)
      };
    });
    await userRepository
      .createBulk({
        users: mapNewUList,
        password: password,
        sendEmail: sendEmail
      })
      .then((response) => {
        setLoading(false);
        props.onFinish();
      })
      .catch((err) => {
        console.log(err);
        if (err.code === 422) {
          sweetAlert.fire({
            icon: 'error',
            reverseButtons: true,
            title: I18N('error', language),
            html: I18N('pleaseCheck', language, [
              { key: 'name', value: err.data[0] }
            ])
          });
        } else {
          sweetAlert.fire({
            icon: 'error',
            reverseButtons: true,
            title: I18N('error', language),
            html: I18N('unexpectedError', language)
          });
        }

        setLoading(false);
      });
  }, [uList, password, language, sendEmail]);

  const checkBeforeImport = useCallback(async () => {
    const errFields = [];

    uList.forEach((item, itemIndex) => {
      let errCalculator = {
        username: false,
        name: false,
        surname: false,
        email: false,
        language: false,
        department: false,
        phone: false,
        group: false
      };
      const itemKeys = Object.keys(item);

      console.log(itemKeys);
      itemKeys.forEach((itemKey) => {
        if (item[itemKey] !== null || item[itemKey] !== undefined) {
          if (Array.isArray(item[itemKey])) {
            if (item[itemKey].length < 1) {
              errCalculator[itemKey] = true;
            }
          } else {
            if (
              typeof item[itemKey] === 'string' ||
              item[itemKey] instanceof String
            ) {
              if (item[itemKey] === '') {
                errCalculator[itemKey] = true;
              }
            } else {
              if (item[itemKey]?.value === undefined) {
                errCalculator[itemKey] = true;
              }
            }
          }
        } else {
          errCalculator[itemKey] = true;
        }
      });
      errFields[itemIndex] = errCalculator;
    });

    let anyErrors = false;

    errFields.forEach((items) => {
      const keyList = Object.keys(items);
      keyList.forEach((key) => {
        if (items[key] === true) {
          anyErrors = true;
        }
      });
    });

    setPasswordError(false);

    if (anyErrors || password === '') {
      await sweetAlert.fire({
        showCloseButton: true,
        icon: 'error',
        title: I18N('error', language),
        html: I18N('pleaseFillAllFields', language)
      });
      if (password === '') {
        setPasswordError(true);
      }
    } else {
      startImportingUsers();
    }

    setErrorCalculator(errFields);
  }, [uList, startImportingUsers]);

  useEffect(() => {
    breadCrumb.setBackButton(props.onFinish);
    breadCrumb.setTitle(I18N('importUsers', language));
    breadCrumb.setAdditionalCrumb({
      title: I18N('importUsers', language),
      onClick: undefined
    });
    const items = [];
    if (uList.length > 0) {
      items.push({
        key: 'importButton',
        title: I18N('import', language),
        onClick: () => checkBeforeImport(),
        isLoading: loading,
        color: 'lightGreen'
      });
    }

    if (items.length === 2) {
      breadCrumb.setChildren(items);
    } else {
      breadCrumb.setChildren([
        ...items,
        {
          color: 'yellow',
          key: 'downloadExampleCSV',
          title: I18N('downloadExampleCSV', language),
          onClick: () => {
            document.getElementById('downloadCSVAction').click();
          }
        }
      ]);
    }
  }, [checkBeforeImport, language, loading, uList]);

  return (
    <>
      <div
        style={{
          display: 'none',
          marginRight: 60,
          marginTop: -46,
          marginBottom: 60,
          justifyContent: 'flex-end',
          flexDirection: 'row'
        }}
      >
        <div>
          <a
            style={{ textDecoration: 'none', color: '#000', display: 'none' }}
            href={'/exampleImportUser.csv'}
            download="exampleImportUser.csv"
            target="_blank"
            id="downloadCSVAction"
            rel="noreferrer"
          ></a>
        </div>
      </div>

      <ViewBox
        title={
          uList.length > 0
            ? I18N('importUsersWithNumber', language, [
                { key: 'total', value: uList.length.toString() }
              ])
            : I18N('importUsers', language)
        }
      >
        {uList.length < 1 ? (
          <DragAndDropUploader key={upKey} setResults={whenDataUploaded} />
        ) : (
          <React.Fragment>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}
            >
              <TextField
                error={passwordError}
                fullWidth
                label={I18N('password', language)}
                style={{ marginTop: 20 }}
                value={password}
                onChange={(text) => setPassword(text.target.value)}
                type={showPassword ? 'text' : 'password'}
                // error={isPasswordValid()}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <InputAdornment position={'end'}>
                        <IconButton
                          onClick={() => {
                            setPassword(Math.random().toString(36).slice(2));
                            setShowPassword(true);
                          }}
                          edge="end"
                        >
                          <Icon icon={randomIcon} />
                        </IconButton>
                      </InputAdornment>
                      <InputAdornment position={'end'}>
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    </React.Fragment>
                  )
                }}
              />

              <div>
                <FormControlLabel
                  value="top"
                  control={
                    <Android12Switch
                      checked={sendEmail}
                      onChange={(e, status) => setSendEmail(status)}
                      placeholder={'Mail'}
                    />
                  }
                  label={I18N('sendWelcomeEmail', language)}
                  labelPlacement="top"
                />
              </div>
            </div>

            {uList.map((user, key) => (
              <div className="step">
                <div className="stepName">
                  <span
                    style={{
                      background:
                        theme.palette.mode === 'dark'
                          ? theme.palette.background.default
                          : '#fff'
                    }}
                  >
                    {' '}
                    {key + 1}. {I18N('user', language)}
                  </span>
                </div>
                <div className="stepInner">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 15
                      }}
                    >
                      <div className="startEvent marginRight10">
                        <TextField
                          error={errorCalculator[key]?.username}
                          fullWidth
                          label={I18N('username', language)}
                          className="marginRight10"
                          value={user.username}
                          onChange={(text) => {
                            setUserData('username', key, text.target.value);
                          }}
                        />
                      </div>
                      <div className="execution marginRight10">
                        <TextField
                          error={errorCalculator[key]?.name}
                          fullWidth
                          label={I18N('firstName', language)}
                          className="marginRight10"
                          value={user.name}
                          onChange={(text) => {
                            setUserData('name', key, text.target.value);
                          }}
                        />
                      </div>
                      <div className="deadline marginRight10">
                        <TextField
                          error={errorCalculator[key]?.surname}
                          fullWidth
                          label={I18N('lastName', language)}
                          className="marginRight10"
                          value={user.surname}
                          onChange={(text) => {
                            setUserData('surname', key, text.target.value);
                          }}
                        />
                      </div>
                      <div className="videoBox zIndexm1">
                        <TextField
                          error={errorCalculator[key]?.email}
                          fullWidth
                          label={I18N('emailAddress', language)}
                          className="marginRight10"
                          value={user.email}
                          onChange={(text) => {
                            setUserData('email', key, text.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div className="startEvent marginRight10 ">
                        <TextField
                          error={errorCalculator[key]?.phone}
                          fullWidth
                          label={I18N('phoneNumber', language)}
                          className="marginRight10"
                          value={user.phone}
                          onChange={(text) => {
                            setUserData('phone', key, text.target.value);
                          }}
                        />
                      </div>
                      <div
                        className="execution marginRight10"
                        style={{ zIndex: uList.length + 10 - key }}
                      >
                        <Select
                          theme={(template) => {
                            return {
                              ...template,
                              colors: {
                                ...template.colors,
                                ...colorForSelect(theme)
                              }
                            };
                          }}
                          className="fullWidthSelect"
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 55,
                              minHeight: 55
                            })
                          }}
                          placeholder={I18N('language', language)}
                          isMulti={false}
                          options={languages.map((i) => {
                            return {
                              label: i.name,
                              value: i.code
                            };
                          })}
                          value={user.language}
                          onChange={(change) => {
                            setUserData('language', key, change);
                          }}
                        />
                      </div>
                      <div
                        className="deadline marginRight10"
                        style={{ zIndex: uList.length + 10 - key }}
                      >
                        <Select
                          theme={(template) => {
                            return {
                              ...template,
                              colors: {
                                ...template.colors,
                                ...colorForSelect(theme)
                              }
                            };
                          }}
                          className="fullWidthSelect"
                          styles={{
                            control: (base) => ({
                              ...base,
                              height: 55,
                              minHeight: 55
                            })
                          }}
                          placeholder={I18N('department', language)}
                          isMulti={false}
                          options={departments.map((i) => {
                            return { label: i.name, value: i.ID };
                          })}
                          value={user.department}
                          onChange={(change) => {
                            setUserData('department', key, change);
                          }}
                        />
                      </div>
                      <div
                        className="videoBox"
                        style={{ zIndex: uList.length + 10 - key }}
                      >
                        <Select
                          theme={(template) => {
                            return {
                              ...template,
                              colors: {
                                ...template.colors,
                                ...colorForSelect(theme)
                              }
                            };
                          }}
                          className="fullWidthSelect"
                          styles={{
                            valueContainer: (base) => ({
                              ...base,
                              zIndex: 9999999,
                              height: 50,
                              scrollbarWidth: 'none',

                              msOverflowStyle: 'none',
                              overflowX: 'hidden',
                              overflowY: 'scroll'
                            }),
                            control: (base) => ({
                              ...base,
                              minHeight: 55,
                              zIndex: 2
                            })
                          }}
                          isMulti={true}
                          options={groups.map((i) => {
                            return { value: i.id, label: i.name };
                          })}
                          placeholder={I18N('permission', language)}
                          value={user.group}
                          onChange={(change) => {
                            setUserData('group', key, change);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '1%', marginTop: 35, marginLeft: -30 }}>
                    <FormButton
                      text={'-'}
                      smallButton={true}
                      onClick={() => deleteRow(key)}
                      isLoading={false}
                      deleteButton={true}
                    />
                  </div>
                  <div className="clearMe" />
                </div>
              </div>
            ))}
          </React.Fragment>
        )}
      </ViewBox>
    </>
  );
}
