import { TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import UploadFile from "../ViewComponents/UploadFile";
import {VideoRepository} from "../Repositories/VideoRepository";
import DepartmentSelector from "../ViewComponents/DepartmentSelector";
import {CompanyRepository} from "../Repositories/CompanyRepository";
const videoRepository = new VideoRepository();
const companyRepository = new CompanyRepository();


type Props = {
    editMode?: any
    backCallback: () => void;
    setFormChange: (form: {
        name: string,
        image: string,
        companies: number[]
    }) => void;
}

export function UploadContentEdit(props: Props) {

    const language = useLanguageState(state => state.language);
    const [companies, setCompanies] = useState([]);
    const [formState, setFormState] = useState({
        name: '',
        image: '',
        companies: []
    })

    useEffect(() => {
        if(props.editMode !== null) {
            setFormState({
                name: props.editMode.name,
                image: props.editMode.image,
                companies: props.editMode.company_content.map((company) => company.company_id)
            })
        }
    }, [props.editMode]);

    useEffect(() => {
        props.setFormChange(formState);
    }, [formState, props]);

    const loadCompanies = useCallback(async () => {
        const list = await companyRepository.getAllAdmin();
        setCompanies(list);
    }, []);

    useEffect(() => {
        loadCompanies();
    }, []);

    return (
        <React.Fragment>
            <ViewBox>
                <TextField
                    fullWidth
                    label={I18N('name', language)}
                    style={{ marginTop: 20 }}
                    value={formState.name}
                    onChange={(text) => setFormState({...formState, name: text.target.value})}
                />
            </ViewBox>

            <div className="flex-row">
                <ViewBox title={I18N('contentImage', language)}>
                    <UploadFile showLoadingWhileUpload={true} inputAttrs={{accept: 'image/*'}} url={formState.image} uploadRepository={videoRepository} onUpload={(image) => setFormState({...formState, image: image})} uploadKey="cover" />
                </ViewBox>
                <ViewBox title={I18N('clients', language)}>
                    <DepartmentSelector onSelectionChange={(data) => setFormState({...formState, companies: data})} preSelected={props.editMode.company_content.map((comp) => comp.company_id)} preLoaded={companies} />
                </ViewBox>
            </div>

        </React.Fragment>

    )
}
