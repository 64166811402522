import {useCallback, useRef, useState} from "react";
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import WizardBox from "../WizardBox";
import CategoriesStep from "./Components/CategoriesStep";
import TrophiesStep from "./Components/TrophiesStep";
import LearningContentStep from "./Components/LearningContentStep";
import LearningPlanStep from "./Components/LearningPlantStep";
import FinishTechStep from "./Components/FinishTechStep";
import CompanyStep from "../FachWizard/Components/CompanyStep";
import DepartmentStep from "../FachWizard/Components/DepartmentStep";
import UsersStep from "../FachWizard/Components/UsersStep";

export default function TechWizardMain(props: {isOpen: boolean,  setIsOpen: (status: boolean) => void;}){

    const language = useLanguageState(state => state.language);
    const [activeStep, setActiveStep] = useState(0);
    const companyRef = useRef(null);
    const usersRef = useRef(null);
    const departmentRef = useRef(null);


    const onClearData = useCallback(() => {

        switch (activeStep){
            case 1: {
                if(companyRef.current !== null){
                    companyRef.current.click();
                }
                return;
            }
            case 2: {
                if(departmentRef.current !== null){
                    departmentRef.current.click();
                }
                return;
            }
            case 3: {
                if(usersRef.current !== null){
                    usersRef.current.click();
                }
                return;
            }
            default: {
                return '';
            }
        }

    }, [activeStep]);

    const steps = [
        {welcomeStep: true, stepDescription: I18N("welcomeTechDescription", language)},
        {title: I18N('companyStep', language), stepDescription: I18N('companyStepDescription', language), stepInnerComponent: <CompanyStep ref={companyRef} title={I18N('companyStep', language)} />},
        {title: I18N('departmentStep', language), stepDescription: I18N('departmentStepDescription', language), stepInnerComponent: <DepartmentStep ref={departmentRef} title={I18N('departmentStep', language)} />},
        {title: I18N('usersStep', language), stepDescription: I18N('userStepDescription', language), stepInnerComponent: <UsersStep ref={usersRef} title={I18N('usersStep', language)} />},

        {finishStep: true, stepDescription: I18N('finishTechDescription', language), stepInnerComponent: <FinishTechStep />}
    ]

    return (
        <WizardBox sendActiveStep={setActiveStep} onClearData={onClearData} steps={steps} open={props.isOpen} setOpen={props.setIsOpen} />
    )

}