import ViewBox from "../ViewComponents/ViewBox";
import {Searchbar} from "../Searchbar/Searchbar";
import React, {useCallback, useEffect, useState} from "react";
import {NotificationsRepository} from "../Repositories/NotificationsRepository";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import SingleNotification from "./SingleNotification";
import FormButton from "../ViewComponents/FormButton";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const sweetAlert = withReactContent(Swal)

const notificationsRepository = new NotificationsRepository();

interface Props {
    style: any;
}

export default function ShowAllNotification(props: Props){

    const [notifications, setNotifications] = useState([]);
    const languageState = useLanguageState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        window.scrollTo({
            behavior: "smooth",
            top: 0
        });

    }, []);

    const getNotifications = useCallback(async () => {
        setIsLoading(true);
        const get = await notificationsRepository.getAll();
        setNotifications(get);
        setIsLoading(false);
    }, []);



    useEffect(() => {
        getNotifications().then(undefined)
    }, [getNotifications]);


    const onAfterClick = useCallback((notification, index) => {
        notifications[index] = {...notification, isClicked: 1, isRead: 1}
        setNotifications([...notifications]);
    }, [notifications]);

    const setAsRead = useCallback(async () => {

        await sweetAlert.fire({
            showCloseButton: true,
            title: '',
            html: I18N('markAsReadQuestion', languageState.language),
            icon: "question",
            showCancelButton: true,
            confirmButtonText: I18N('yes', languageState.language),
            cancelButtonText: I18N('no', languageState.language)

        }).then(async (response) => {
            if(response.isConfirmed){
                setIsLoading(true);
                await notificationsRepository.markAsReadAll();
                setIsLoading(false);
                getNotifications().then(undefined);
                await sweetAlert.fire({
                    title: 'Success',
                    html: I18N('markedSuccess', languageState.language),
                    icon: "success"
                })
            }
        })

    }, [getNotifications, languageState.language]);

    const deleteAll = useCallback(async () => {

        await sweetAlert.fire({
            title: '',
            html: I18N('deleteAllQuestion', languageState.language),
            icon: "question",
            showCancelButton: true,
            confirmButtonText: I18N('yes', languageState.language),
            cancelButtonText: I18N('no', languageState.language)

        }).then(async (response) => {
            if(response.isConfirmed){
                setIsLoading(true);
                await notificationsRepository.deleteAll();
                setIsLoading(false);
                getNotifications().then(undefined);
                await sweetAlert.fire({
                    title: 'Success',
                    html: I18N('deleteSuccessNotifications', languageState.language),
                    icon: "success"
                })
            }
        })

    }, []);


    return (
        <div style={props.style}>
            <Searchbar />
            <div style={{width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column'}} className="container">

                <div className="headerContainer">
                    {I18N('notifications', languageState.language)}

                    <ul className="breadCrumb">
                        <li>{I18N('notifications', languageState.language)}</li>
                    </ul>
                </div>

                <div className="flex-row-button">
                    <FormButton deleteButton={true} text={I18N('deleteAll', languageState.language)} onClick={deleteAll} isLoading={isLoading} />
                    <FormButton text={I18N('markAsReadAll', languageState.language)} onClick={setAsRead} isLoading={isLoading} />
                </div>

                <ViewBox>
                    {notifications.map((notification, index) => <SingleNotification fullView={true} notification={notification} index={index} onAfterClick={onAfterClick} />)}
                    {notifications.length < 1 && (
                        <li className="noNotification">{I18N('noNotifications', languageState.language)}</li>
                    )}
                </ViewBox>
            </div>

        </div>

    )
}
