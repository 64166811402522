import React, { useCallback, useEffect, useState } from 'react';
import { standartColors } from '../assets/colors/Colors';
import withReactContent from "sweetalert2-react-content";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { LanguageRepository } from "../Repositories/LanguageRepository";
import { PasswordTextsRepository } from "../Repositories/PasswordTexts";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import {useTheme} from "@mui/material";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useSnackbar} from "notistack";


const languageRepository = new LanguageRepository();
const passwordTextsRepository = new PasswordTextsRepository();


type Props = {
    style: any;
    isMobile: () => boolean;
}

export function PasswordTexts(props: Props) {

    const [languages, setLanguages] = useState([]);
    const [loadingForm, setLoadingForm] = useState(false);
    const language = useLanguageState(state => state.language);
    const breadCrumb = useBreadCrumb();
    const {enqueueSnackbar} = useSnackbar();
    const [editorState, setEditorState] = useState([]);
    const theme = useTheme();
    const getLanguages = useCallback(async () => {
        const languageList = await languageRepository.getAll();
        const texts = await passwordTextsRepository.getAll();
        let list = [];
        languageList.forEach((language) => {
            const findText = texts.find((text) => text.language === language.code);
            if (findText === undefined) {
                list.push({ code: language.code, state: EditorState.createEmpty() });
            } else {
                const htmltodraft = htmlToDraft(findText.text);
                const contentState = ContentState.createFromBlockArray(htmltodraft.contentBlocks);
                const state = EditorState.createWithContent(contentState);
                list.push({ code: language.code, state: state })
            }
        }, []);
        setEditorState(list);
        setLanguages(languageList);

    }, []);
    const saveTexts = useCallback(async () => {
        setLoadingForm(true);
        await editorState.map(async (item) => {
            await passwordTextsRepository.update({ text: draftToHtml(convertToRaw(item.state.getCurrentContent())) }, item.code)
        });

        enqueueSnackbar(I18N('successfullyUpdated', language, [{ key: 'name', value: I18N('passwordTexts', language) }]), {variant: "success"})

        setLoadingForm(false);
    }, [editorState]);
    useEffect(() => {
        getLanguages().then(undefined);
    }, []);

    useEffect(() => {
        breadCrumb.setTitle(I18N('passwordTexts', language))
        breadCrumb.setCrumb([{title: I18N("configuration", language), onClick: undefined}, {
            title: I18N('passwordTexts', language),
            onClick: undefined
        }]);
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([
            {
                color: 'lightGreen',
                key: 'passwordtextsave',
                title:  I18N("saveChanges", language),
                onClick: saveTexts,
                isLoading: loadingForm,
                icon: "save",
            }
        ])

    }, [language, loadingForm, saveTexts]);


    return (
        <div style={props.style}>
            {languages.map((language) => {
                return (
                    <ViewBox title={language.name}>
                        <Editor

                            editorState={editorState.find((item) => item.code === language.code).state}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbarClassName={theme.palette.mode === 'dark' && "headerDark"}
                            onEditorStateChange={(data) => {
                                const found = editorState.find((item) => item.code === language.code);
                                found.state = data;
                                setEditorState([...editorState]);
                            }}
                        />
                    </ViewBox>
                )
            })}
        </div>
    )


}
