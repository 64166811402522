import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PaginationRequestType, PaginationTypes} from "../Types/PaginationTypes";
import {CategoryType} from "../Types/CategoryType";

export class ReleaseNotesRepository implements  AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
      return new Promise(() => {});
    }
    setUserSeen(id, user_id){
        return new Promise((resolve, reject) => {
            axios.patch(`/releasenotes/${id}/${user_id}/updateUser`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }
    latestNote(){
        return new Promise((resolve, reject) => {
            axios.get(`/releasenotes/latestNote`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    release(id: number): Promise<any> {

        return new Promise((resolve, reject) => {
            axios.patch(`/releasenotes/${id}/release`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });


    }

    getAll(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            axios.get(`/releasenotes`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/releasenotes', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.patch('/releasenotes/'+id, object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

}
