import ReactApexChart from "react-apexcharts";
import ViewBox from "./ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {DepartmentsRepository} from "../Repositories/DepartmentsRepository";
import {UsersRepository} from "../Repositories/UsersRepository";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {ColorsForChart} from "../Helpers/ColorsForChart";
import {VideoRepository} from "../Repositories/VideoRepository";
import {CalculateDepartmentAnalyticsData} from "../Helpers/CalculateDepartmentAnalyticsData";

const videoRepository = new VideoRepository();
const departmentRepository = new DepartmentsRepository();
const usersRepository = new UsersRepository();
const companyRepository = new CompanyRepository();

export function UserChart(){

    const language = useLanguageState(state => state.language);
    const [deps, setDeps] = useState([]);
    const [users, setUsers] = useState([]);
    const [companyData, setCompanyData] = useState(null);
    const [watchList, setWatchList] = useState(null);
    const [videos, setVideos] = useState([]);
    const loadData = useCallback(async () => {
        const watchListData = await videoRepository.getWatchListDepartments();
        const departments = await departmentRepository.getAll();
        const users = await usersRepository.getAll();
        const company = await companyRepository.getAll();

        console.log(departments);
        //@ts-ignore

        setDeps(departments);
        //@ts-ignore
        setUsers(users);
        console.log(users);
        console.log(company);
        setCompanyData(company);
        setWatchList(watchListData.contentData);
        setVideos(watchListData.videos);
    }, []);

    useEffect(() => {
        loadData().then(undefined);
    }, [])


    const calculateData = useCallback(() => {

        const data = CalculateDepartmentAnalyticsData(watchList, videos);


        return data.map((singleData) => {
            return {name: singleData.name, percentage: isNaN(singleData.watched / (singleData.totalVideos * singleData.totalUsers) * 100) ? '0' : (singleData.watched / (singleData.totalVideos * singleData.totalUsers) * 100).toFixed(0)}

        })

    }, [watchList, videos]);

    return (
        <ViewBox title={I18N("userBoxTitle", language)}>
            <div style={{borderBottomWidth: 1,}}>
                <ReactApexChart options={{chart: {type: 'polarArea'},
                    tooltip: {
                        y: {
                            formatter: function(value, opts) {
                                return `${value}%`
                            },
                        },
                    },
                    colors: ColorsForChart(deps.length),
                    stroke: {
                        colors: ['#fff'],
                        show: true,
                        width: 3
                    }, fill: {
                        opacity: 0.8
                    }, labels: calculateData().map((dep) => dep.name ), xaxis: {
                        tickAmount: 1
                    }, yaxis: {
                        tickAmount: 1
                    }}} series={calculateData().map((dep) => {
                        return dep.percentage
                })} type="polarArea" />
            </div>

                <div className="flex-row" style={{borderWidth: 1, borderTop: "solid", borderColor: "#E0E0E0", borderTopWidth: 1, marginTop: 20}}>
                    <div style={{textAlign: "center", width: '50%', borderRight: "solid", borderWidth: 1, borderRightWidth: 1, borderColor: "#E0E0E0",}}>
                        <p style={{color: '#4F4F4F', fontSize: 13, fontWeight: 400}}>{I18N("totalEmployees", language)}</p>
                        <p style={{fontWeight: "bold", fontSize: 20}}>{companyData?.employees}</p>
                    </div>
                    <div style={{textAlign: "center", width: '50%'}}>
                        <p style={{color: '#4F4F4F', fontSize: 13, fontWeight: 400}}> {I18N("accessToAwereness", language)}</p>
                        <p style={{fontWeight: "bold", fontSize: 20}}>{users.length} / {companyData?.employees}</p>
                    </div>

                </div>
        </ViewBox>
    )

}
