const availableConfiugrationPermissionsToCheck = [
  'ViewCategories',
  'ViewAllCategories',
  'ViewAllDepartments',
  'ViewDepartments',
  'ViewAllUsers',
  'ViewUser',
  'ViewPermissionMatrix',
  'ViewAllCategories',
  'ViewCategories',
  'ViewAllContent',
  'ViewContent',
  'ViewAllLearningPlan',
  'ViewLearningPlan',
  'ViewAllTrophies',
  'ViewTrophies',
  'ViewAllList',
  'ViewList',
  'ViewAllQuiz',
  'ViewQuiz',
  'EditPasswordTexts',
  'EditPasswordQuality',
  'CreateNotification',
  'ViewApiKeys',
  'EditAnalyticsSettings',
  'ViewAnalytics'
];

export default function ConfigGlobalPermissionCheck(permissionList: any[]) {
  let status = false;

  permissionList.forEach((i) => {
    if (availableConfiugrationPermissionsToCheck.includes(i)) {
      status = true;
    }
  });

  return status;
}
