import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import TableMui from "../ViewComponents/TableMui";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {PaginationTypes} from "../Types/PaginationTypes";
import {UserListsType} from "../Types/UserListsTypes";
import {QuizRepository} from "../Repositories/QuizRepository";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import {useSnackbar} from "notistack";
const quizRepository = new QuizRepository();

interface Props {

    setEditMode: (item) => void;
}

export default function QuizList(props: Props){

    const language = useLanguageState(state => state.language);

    const [listItems, setListItems] = useState<PaginationTypes<UserListsType>>(undefined);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const {enqueueSnackbar} = useSnackbar();

    const loadQuiz = useCallback(async (fastReload?: boolean) => {
        if(!waitingForPage || fastReload) {
            setLoading(true);
            const lists = await quizRepository.getAll({limit, page, filter, orderType});
            setListItems(lists as PaginationTypes<UserListsType>);
            setLoading(false);
        }
    }, [waitingForPage, filter, limit, orderType, page]);

    useEffect(() => {
        loadQuiz().then(undefined)
    }, [loadQuiz]);

    const deleteQuiz = useCallback(async (quiz) => {
        const del = await quizRepository.delete(quiz.id, quiz.unlink ?? false);

        if (del instanceof Error) {
            setLoading(false);
            enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            return;
        } else {
            if(del.status !== undefined && del.status === -1){
                setLoading(false);
                return del;
            }
            await loadQuiz(true).then(undefined);
            enqueueSnackbar(I18N('deleteSuccess', language, [{ key: 'name', value: I18N('quiz', language) }]), {variant: "success"});
        }


    }, []);


    const tableColumnsMui = () => {
        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'name',
                name: I18N("name", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'percentage',
                name: I18N("percentage", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}%</span>;
                }
            },
            {
                field: 'status',
                name: I18N("status", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value ? I18N('active', language) : I18N('inActive', language)}</span>;
                }
            },
            {
                field: 'actions',
                name: I18N('actions', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params: any) => {
                    return <DeleteIcon row={params.row} callBack={deleteQuiz} unlinkRequired={true} ask={true} />;
                }
            },
        ];
    }

    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);



    return (
        <>
            <div style={{height: tableHeightCalculator(limit)}}>
                <TableMui
                    tableName="quizListTable"
                    setWaitingForPage={setWaitingForPage}
                    page={page}
                    onFilterModeChange={onFilterModeChange}
                    onPageSizeChange={(size) => setLimit(size)}
                    onPageChange={(page) => setPage(page + 1)}
                    data={listItems?.data !== undefined ? listItems.data : []}
                    onClick={(data, event) => {
                        //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                        // IT CAPTURES THE ITEM THAT IS CLICKED
                        // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                        // THINGS THAT WE DONT WANT TO REDIRECT
                        if(event.target.dataset.field !== "actions"){
                            if(data.row.quiz_progress.length > 0){
                                enqueueSnackbar(I18N('currentlyInUseQuiz', language), {variant: "error"});
                            }else{

                                props.setEditMode(data.row);
                            }

                        }
                    }}
                    onSortModelChange={onSortModelChange}
                    isLoading={loading}
                    columns={tableColumnsMui()}
                    pageSize={limit}
                    rowCount={listItems?.total}
                />
            </div>
        </>
    )

}
