import {Autocomplete, Box, Checkbox, Divider, FormControlLabel, Paper, TextField} from '@mui/material';
import React, {FormEvent, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {isArrayLike, isObject} from "lodash";

interface ISelectAutocomplete {
    options: any[];
    label?: string;
    multiple?: boolean;
    tagBackground?: string;
    orangeBackground?: boolean;
    required?: boolean;
    value: any;
    placeholder?: string;
    padding?: string;
    tagColor?: string;
    defaultValue?: { id: number; title: string };
    disabled?: boolean;
    onChange: (event: any) => void;
    maxTags?: number;
    size?: string;
    margin?: number;
}
interface ITagStyle {
    height: string;
    opacity?: string;
    fontSize: string;
    background?: string;
    color?: string;

}
const SelectAutocomplete = (props: ISelectAutocomplete) => {
    const {
        options,
        label,
        multiple,
        tagBackground,
        onChange,
        value,
        orangeBackground,
        padding,
        required,
        placeholder,
        defaultValue,
        tagColor,
        disabled,
        maxTags,
        size,
        margin
    } = props;

    const language = useLanguageState((state) => state.language)
    const checkSelected = (
        param1: { id: number; title: string },
        param2: { id: number; title: string }
    ) => {
        if (!value) {
            return false;
        }
        if (param1.id === param2.id) {
            return true;
        }
        return false;
    };

    const autocompleteTagStyle: ITagStyle = {
        height: '25px',
        fontSize: '14px',
        background: tagBackground && 'white',
        color: tagColor
    };

    if (tagBackground) {
        autocompleteTagStyle.background = tagBackground;
    }

    const [selectAll, setSelectAll] = useState<boolean>(false);


    useEffect(() => {

        setSelectAll(options.filter((option) => value?.map((a) => a.value).includes(option.value)).length === options.length);

    }, [options, value]);

    const handleToggleSelectAll = () => {
        setSelectAll((prev) => {
            if (!prev) onChange(options);
            else onChange(undefined);
            return !prev;
        });
    };

    return (
        <Autocomplete
            id="tags-outlined"
            placeholder={label}
            multiple={multiple}
            options={options.map((item) =>  {return {...item, id: item.value, title: item.label}})}
            sx={{
                '& .MuiAutocomplete-inputRoot ': {
                    padding: '9px'
                }
            }}
            fullWidth
            isOptionEqualToValue={checkSelected}
            value={value !== undefined ?   value.map((item) => {return {...item, id: item.value, title: item.label}}) : []}
            defaultValue={defaultValue}
            limitTags={maxTags ?? 1}
            // @ts-ignore
            getOptionLabel={option => option.label || option.name || ''}
            filterSelectedOptions
            disabled={disabled}
            onChange={(_e, data) => {
                onChange(data as any);
            }}
            PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                    <Paper style={{zIndex: 0}} {...restPaperProps}>
                        <Box
                            onMouseDown={(e) => e.preventDefault()} // prevent blur
                            pl={1.5}
                            py={0.5}
                        >
                            <FormControlLabel
                                onClick={(e) => {
                                    e.preventDefault(); // prevent blur
                                    handleToggleSelectAll();
                                }}
                                label="Select all"
                                control={
                                    <Checkbox id="select-all-checkbox" checked={selectAll} />
                                }
                            />
                        </Box>
                        <Divider />
                        {children}
                    </Paper>
                );
            }}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        style={{zIndex: 0}}
                        required={required}
                        placeholder={(value?.length > 0 ? '' : label ?? (placeholder ?? I18N("pleaseSelect", language)))}
                        sx={
                            orangeBackground
                                ? {
                                    '& .MuiButtonBase-root': {
                                        height: '25px'
                                    },
                                    '& .MuiChip-root': {
                                        backgroundColor: '#CD6516',
                                        color: 'white'
                                    },
                                    margin: margin ?? '10px 0',
                                    '& .MuiAutocomplete-inputRoot ': {
                                        borderRadius: '4px',
                                        minHeight: size ?? 'auto'
                                    },
                                    '& .MuiAutocomplete-input': {
                                        padding: `${padding || 0} !important`
                                    },
                                    '& input': {
                                        padding: '10px'
                                    }
                                }
                                : {
                                    margin: margin ?? '10px 0',
                                    '& .MuiAutocomplete-inputRoot ': {
                                        borderRadius: '4px',
                                        minHeight: size ?? 'auto'
                                    },
                                    '& .MuiAutocomplete-tag': autocompleteTagStyle,
                                    '& .MuiAutocomplete-input': {
                                        padding: `${padding || 0} !important`
                                    },
                                    '& input': {
                                        padding: '10px'
                                    }
                                }
                        }
                        InputLabelProps={{
                            shrink: true
                        }}
                        label={(!(value?.length > 0) ? '' : label ?? I18N("pleaseSelect", language))}
                    />
                );
            }}
        />
    );
};
export default SelectAutocomplete;