import React, {useCallback, useEffect, useState} from 'react';
import { standartColors } from '../../assets/colors/Colors';
import { Searchbar } from '../../Searchbar/Searchbar';
import { I18N } from "../../i18n/i18n";
import { useLanguageState } from "../../States/LanguageState";
import { useLoginState } from "../../States/UserState";
import ContentConfigSVG from '../../assets/contentconfig.svg';
import DepartmentConfigSVG from '../../assets/departmentsconfig.svg';
import TabBoxes from "../Konfiguration/TabBoxes";

import {getToken} from "../../Repositories/Cookies";
import {UploadContentTab} from "../../UploadContent/UploadContentTab";
import {ClientTab} from "../../Clients/ClientTab";
import {ConfigurationsRepository} from "../../Repositories/Configurations";
import {useTheme} from "@mui/material";
import {LicensesTab} from "../../Clients/LicensesTab";
import LogsTab from "../../Logs/LogsTab";
import {ClientListLogs} from "../../Clients/ClientListLogs";
import {LogsRepository} from "../../Repositories/LogsRepository";

const loggerRepository = new LogsRepository();
const configurationRepository = new ConfigurationsRepository();

type Props = {
    style: any
    isMobile: () => boolean
}


export function SuperAdminConfig(props: Props) {

    //Setting states
    const clientName = getToken();
    const loginState = useLoginState();
    const [activeTab, setActiveTab] = useState(null);
    const [configurationInfo, setConfigurationInfo] = useState(null);
    const language = useLanguageState(state => state.language)
    const [requiredOnes, setRequiredOnes] = useState([]);
    const theme = useTheme();

    useEffect(() => {
        setActiveTab(null);
    }, [props]);

    useEffect(() => {

        loggerRepository.post({
            type: 'info',
            message: "Client list loaded"
        })

    }, []);

    //Scrolling to top when pag eopens
    useEffect(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, [activeTab]);


    // Getting box data when page opens
    useEffect(() => {

        if(configurationInfo === null && requiredOnes.length !== 0){
            configurationRepository.getAll(requiredOnes).then((response) => {
                setConfigurationInfo(response);
            }).catch((err) => {
                console.log('notLoaded');
            });
        }

    }, []);


    //Setting configuration tabs with permissions
    const getConfigTabs = useCallback(() => {

        let configTabs = [];
        let request = [];
        if (loginState.token === null) {
            return [];
        }


            configTabs.push({ name: I18N('uploadContent', language), key: 'uploadContent', icon: ContentConfigSVG, component: <UploadContentTab style={{}} isMobile={props.isMobile} /> });
            request.push('uploadContent');



            configTabs.push({ name: I18N('clients', language), key: 'clients', icon: ContentConfigSVG, component: <ClientTab style={{}} isMobile={props.isMobile} /> });
            request.push('clients');



            configTabs.push({ name: I18N('licences', language), key: 'licences', icon: DepartmentConfigSVG, component: <LicensesTab style={{}} isMobile={props.isMobile} /> });
            request.push('licences');



            configTabs.push({ name: I18N('logs', language), key: 'logs', icon: DepartmentConfigSVG, component: <ClientListLogs onDone={() => {setActiveTab(null)}} style={{}} isMobile={props.isMobile} /> });


        if(requiredOnes.length === 0){
            setRequiredOnes(request);
        }

        return [configTabs];

    }, [clientName, language, loginState.token, props.isMobile, requiredOnes.length]);

    return (
        <div style={props.style}>
            <Searchbar />
            <div style={styles.container}>
                <div style={{...styles.header.container, color: theme.palette.mode === "dark" && "#fff"}}>
                    {activeTab === null ? I18N('adminConfig', language) : getConfigTabs()[activeTab.outIndex][activeTab.index].name}

                    <ul className="breadCrumb">
                        <li onClick={() => setActiveTab(null)}>{I18N('adminConfig', language)}</li>
                        {activeTab !== null && (
                            <React.Fragment>
                                <li className="divider" />
                                <li>{getConfigTabs()[activeTab.outIndex][activeTab.index].name}</li>
                            </React.Fragment>

                        )}
                    </ul>


                </div>
                {activeTab === null ? (
                    <TabBoxes configurationInfo={configurationInfo} onClick={(index, outIndex) => setActiveTab({index: index, outIndex: outIndex})} tabs={getConfigTabs()} />
                ) : (
                    <React.Fragment>
                        {getConfigTabs()[activeTab.outIndex][activeTab.index].component}
                    </React.Fragment>
                )}

            </div>
        </div >
    )

}

const styles = {
    container: { width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
    header: {
        container: { fontWeight: 600, height: 18, fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', color: standartColors.black } as React.CSSProperties,
    },
    shortCuts: {
        container: { height: '20%', width: 'calc(100% - 20px)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around', overflow: 'scroll', color: standartColors.black } as React.CSSProperties,
        shortcut: { backgroundColor: standartColors.primaryYellow, height: 120, borderRadius: 10, fontSize: 18, fontWeight: 'bold', display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginLeft: 20 } as React.CSSProperties,
        title: { marginLeft: 20, marginRight: 20 },
        icon: { height: 40, width: 40, marginLeft: 20, marginRight: 20 }
    },
    boxContainer: { height: '100%', width: '20%', borderRadius: 10, boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)', border: '1px solid #E0E0E0' } as React.CSSProperties,
    boxContent: { marginTop: 10, marginLeft: 20, marginRight: 20, height: '95%' } as React.CSSProperties,
    title: { fontSize: 16, fontWeight: 'bold' } as React.CSSProperties,
    totalBoxes: { height: '45%', width: '100%', backgroundColor: standartColors.primaryYellow, borderRadius: 10 },
    totalBoxTitle: { height: 30, width: '95%', marginLeft: '2.5%', display: 'flex', alignItems: 'center', fontWeight: 'bold' } as React.CSSProperties,
    totalBox: { height: '90%', width: '100', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, fontWeight: 'bold' } as React.CSSProperties,
    routerContent: { height: '100vh', width: 'calc(100vw - 350px)', border: '#000 2 solid' }
}
