import React, { useCallback, useEffect, useState } from 'react';
import { standartColors } from '../../assets/colors/Colors';
import { Searchbar } from '../../Searchbar/Searchbar';
import { I18N } from '../../i18n/i18n';
import { useLanguageState } from '../../States/LanguageState';
import { useLoginState } from '../../States/UserState';
import { CompanyRepository } from '../../Repositories/CompanyRepository';
import { getToken } from '../../Repositories/Cookies';
import { ConfigurationsRepository } from '../../Repositories/Configurations';
import BreadCrumb from '../../ViewComponents/BreadCrumb';
import { useBreadCrumb } from '../../States/BreadCrumbState';
import ConfigurationsSidebarComponent from '../../ViewComponents/SideBarSettings/SettingsSidebar';
import ConfigurationRouter from './ConfigurationRouter';
import { useLocation } from 'react-router-dom';

const configurationRepository = new ConfigurationsRepository();
const companyRepository = new CompanyRepository();

type Props = {
  style: any;
  isMobile: () => boolean;
};

export function Konfiguration(props: Props) {
  //Settting states
  const [activeTab, setActiveTab] = useState({ index: 0, outIndex: 0 });
  const [hideSideBar, setHideSideBar] = useState(false);

  //Scrolling page to top
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, [activeTab]);

  return (
    <div style={props.style}>
      <Searchbar />
      <div style={{ ...styles.container }}>
        <BreadCrumb />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: activeTab === null && 60
          }}
        >
          {!hideSideBar && (
            <div style={{ width: '25%', minWidth: 250 }}>
              <ConfigurationsSidebarComponent
                active={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          )}
          <div style={{ width: !hideSideBar ? '75%' : '100%', minWidth: 720 }}>
            <ConfigurationRouter
              style={props.style}
              isMobile={props.isMobile}
              setHideSideBar={setHideSideBar}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '93%',
    display: 'flex',
    marginLeft: 25,
    flexDirection: 'column'
  } as React.CSSProperties,
  header: {
    container: {
      fontWeight: 600,
      height: 18,
      fontSize: 18,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      color: standartColors.black
    } as React.CSSProperties
  },
  shortCuts: {
    container: {
      height: '20%',
      width: 'calc(100% - 20px)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
      overflow: 'scroll',
      color: standartColors.black
    } as React.CSSProperties,
    shortcut: {
      backgroundColor: standartColors.primaryYellow,
      height: 120,
      borderRadius: 10,
      fontSize: 18,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      marginLeft: 20
    } as React.CSSProperties,
    title: { marginLeft: 20, marginRight: 20 },
    icon: { height: 40, width: 40, marginLeft: 20, marginRight: 20 }
  },
  boxContainer: {
    width: '20%',
    borderRadius: 10,
    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
    border: '1px solid #E0E0E0'
  } as React.CSSProperties,
  boxContent: {
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    height: '95%'
  } as React.CSSProperties,
  title: { fontSize: 16, fontWeight: 'bold' } as React.CSSProperties,
  totalBoxes: {
    height: '45%',
    width: '100%',
    backgroundColor: standartColors.primaryYellow,
    borderRadius: 10
  },
  totalBoxTitle: {
    height: 30,
    width: '95%',
    marginLeft: '2.5%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  } as React.CSSProperties,
  totalBox: {
    height: '90%',
    width: '100',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 25,
    fontWeight: 'bold'
  } as React.CSSProperties,
  routerContent: {
    height: '100vh',
    width: 'calc(100vw - 350px)',
    border: '#000 2 solid'
  }
};
