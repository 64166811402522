import { AbstractRepository } from './abstract-repository';
import axios from 'axios';
import {
  PaginationRequestType,
  PaginationTypes
} from '../Types/PaginationTypes';
import { TrophiesTypes } from '../Types/TrophiesTypes';

export class TrophiesRepository
  implements
    AbstractRepository<PaginationTypes<TrophiesTypes> | TrophiesTypes[]>
{
  buildObject(
    a: any,
    b: any,
    c: any,
    d: any,
    e: any,
    f: any,
    g: any,
    h: any,
    i: any,
    j: any,
    k: any,
    l: any,
    m: any
  ): any {}

  delete(id: number | string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .delete('/trophies/' + id)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  getAll(
    pagination?: PaginationRequestType
  ): Promise<PaginationTypes<TrophiesTypes> | TrophiesTypes[]> {
    return new Promise((resolve, reject) => {
      const orderType =
        pagination !== undefined
          ? pagination.orderType !== null
            ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}`
            : ''
          : '';
      const filterType =
        pagination !== undefined
          ? pagination.filter !== null
            ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}`
            : ''
          : '';
      axios
        .get(
          `/trophies${
            pagination !== undefined
              ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}`
              : ''
          }`
        )
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  getUrl(): string {
    return '';
  }

  getWithID(id: number): Promise<any> {
    return Promise.resolve(undefined);
  }

  post(object: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .post('/trophies', object)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  //@ts-ignore
  update(object: any, id: number): Promise<any> {
    console.log(object, id);
    return new Promise((resolve, reject) => {
      axios
        .patch('/trophies/' + id, object)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }
}
