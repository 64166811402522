import React, { useCallback, useEffect, useState } from 'react';
import { I18N } from '../../i18n/i18n';
import { useLanguageState } from '../../States/LanguageState';
import TableMui from '../../ViewComponents/TableMui';
import FormButton from '../../ViewComponents/FormButton';
import { useBreadCrumb } from '../../States/BreadCrumbState';
import { PhishingRepository } from '../../Repositories/PhishingRepository';
import { useLoginState } from '../../States/UserState';
import { Alert } from '@mui/material';
import { useGlobalSettingsState } from '../../States/GlobalSettings';
import ShowIcon from '../../assets/show.svg';
import HideIcon from '../../assets/hide.svg';

const phishingRepository = new PhishingRepository();

export default function AnalyticsInner(props: {
  item: any;
  setCalculations: (data: any) => void;
  onBack: () => void;
  exportData: any;
}) {
  const language = useLanguageState((state) => state.language);
  const [openLinks, setOpenLinks] = useState(0);
  const [clickLinks, setClickLinks] = useState(0);
  const [builtItems, setBuiltItems] = useState([]);
  const breadCrumb = useBreadCrumb();
  const [loading, setLoading] = useState(false);
  const companyList = useLoginState((state) => state.company);

  const globalSettings = useGlobalSettingsState((state) => state.settings);
  const [showUserNames, setShowUserNames] = useState(false);
  const [buttonActivated, setButtonActivated] = useState(true);

  useEffect(() => {
    const findPsudeo = globalSettings.findIndex((i) => i.key === 'psudeo');
    const findAnon = globalSettings.findIndex((i) => i.key === 'anon');

    if (findAnon !== -1) {
      if (Number(globalSettings[findAnon].value)) {
        setShowUserNames(false);
        setButtonActivated(false);
      } else {
        setButtonActivated(true);
        if (findPsudeo !== -1) {
          setShowUserNames(!Number(globalSettings[findPsudeo].value));
        } else {
          setShowUserNames(false);
        }
      }
    } else {
      setButtonActivated(true);
      if (findPsudeo !== -1) {
        setShowUserNames(!Number(globalSettings[findPsudeo].value));
      } else {
        setShowUserNames(false);
      }
    }
  }, [globalSettings]);

  useEffect(() => {
    breadCrumb.setCrumb([
      {
        title: I18N('analytics', language),
        onClick: undefined
      },
      {
        title: I18N('phishing', language),
        onClick: () => props.onBack()
      },
      {
        title: props.item.designation,
        onClick: undefined
      }
    ]);

    breadCrumb.setTitle(props.item.designation);
    breadCrumb.setBackButton(props.onBack);
    breadCrumb.setChildren([
      {
        title: '',
        color: 'transparent',
        isLoading: false,
        isFullLoading: true,
        onClick: undefined
      }
    ]);
  }, [props.item]);

  const exportData = useCallback(async () => {
    setLoading(true);
    await phishingRepository.getPDF(
      {
        builtItems: builtItems,
        tot: props.item.numberOfContacts,
        clickIn: props.item.phishing_clicked.filter(
          (item) => item.clicked_at !== ''
        ).length,
        openIn: props.item.phishing_clicked.length,
        open:
          (props.item.phishing_clicked.length / props.item.numberOfContacts) *
          340,
        clickLinks:
          (props.item.phishing_clicked.filter((item) => item.clicked_at !== '')
            .length /
            props.item.numberOfContacts) *
          340,
        total: props.item.phishing_clicked.length,
        showNames: showUserNames,
        name: props.item.designation,
        ...props.item
      },
      props.item.designation,
      !(companyList.length > 1)
    );
    setLoading(false);
  }, [builtItems, props.item, companyList, showUserNames]);

  useEffect(() => {
    setOpenLinks(
      (props.item.phishing_clicked.length / props.item.numberOfContacts) * 100
    );
    setClickLinks(
      (props.item.phishing_clicked.filter((item) => item.clicked_at !== '')
        .length /
        props.item.numberOfContacts) *
        100
    );

    const itemList = [];
    props.item.uData.forEach((item) => {
      let status = 1;
      const findInClick = props.item.phishing_clicked.find(
        (i) => i.user_id === item.id
      );
      console.log(findInClick);
      if (findInClick) {
        if (findInClick.clicked_at === '') {
          status = 2;
        } else {
          status = 3;
        }
      }

      let stat = {
        statusText: I18N('sent', language),
        statusColor: '#27AE60'
      };
      if (status === 2) {
        stat = {
          statusText: I18N('opened', language),
          statusColor: '#F9CB38'
        };
      } else if (status === 3) {
        stat = {
          statusText: I18N('clicked', language),
          statusColor: '#F86D70'
        };
      }

      itemList.push({
        name: item.firstName,
        surname: item.lastName,
        email: item.emailAddress,
        status: status,
        id: item.id,
        ...stat
      });
    });

    setBuiltItems(itemList);
  }, [props.item, language]);

  // useEffect(() => {
  //
  //     props.setCalculations({builtItems: builtItems, tot: props.item.numberOfContacts, clickIn: props.item.phishing_clicked.filter((item) => item.clicked_at !== '').length, openIn: props.item.phishing_clicked.length, open: (props.item.phishing_clicked.length / props.item.numberOfContacts * 340), clickLinks: (props.item.phishing_clicked.filter((item) => item.clicked_at !== '').length / props.item.numberOfContacts * 340), total: props.item.phishing_clicked.length, name: props.item.designation, ...props.item});
  //
  // }, [builtItems, clickLinks, openLinks, props.item]);

  const campaignColumns = useCallback(() => {
    return [
      {
        field: 'id',
        name: I18N('id', language),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => {
          return <span style={{ fontWeight: 'bold' }}>{params.row.id}</span>;
        }
      },
      {
        field: 'name',
        name: I18N('name', language),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => {
          return (
            <span style={{ fontWeight: 'bold' }}>
              {showUserNames ? params.row.name : '-'}
            </span>
          );
        }
      },
      {
        field: 'surname',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        name: I18N('lastName', language),
        renderCell: (params: any) => {
          return (
            <p style={{ fontWeight: 'bold' }}>
              {showUserNames ? params.row.surname : '-'}
            </p>
          );
        }
      },
      {
        field: 'email',
        name: I18N('emailAddress', language),
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: any) => {
          return (
            <p style={{ fontWeight: 'bold' }}>
              {showUserNames ? params.row.email : '-'}
            </p>
          );
        }
      },
      {
        field: 'status',
        name: I18N('status', language),
        sortable: false,
        width: 150,
        filterable: false,
        disableColumnMenu: true,

        renderCell: (params: any) => {
          if (params.row.status === 1) {
            return (
              <p style={{ margin: 0, color: '#27AE60' }}>
                {I18N('sent', language)}
              </p>
            );
          } else if (params.row.status === 2) {
            return (
              <p style={{ margin: 0, color: '#F9CB38' }}>
                {I18N('opened', language)}
              </p>
            );
          } else {
            return (
              <p style={{ margin: 0, color: '#F86D70' }}>
                {I18N('clicked', language)}
              </p>
            );
          }
        }
      }
    ];
  }, [language, showUserNames]);

  return (
    <div>
      <h1>{props.item.designation}</h1>

      <div
        style={{
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 30
        }}
      >
        <div>
          {/*//@ts-ignore*/}
          <div
            role="progressbar"
            aria-valuenow={
              (props.item.numberOfContacts / props.item.numberOfContacts) * 100
            }
            aria-valuemin={0}
            aria-valuemax={100}
            style={{
              // @ts-ignore
              '--value':
                (props.item.numberOfContacts / props.item.numberOfContacts) *
                100,
              '--color': '#27AE60',
              '--inner': props.item.numberOfContacts
            }}
          ></div>
          <p
            style={{
              textAlign: 'center',
              color: '#000000',
              marginTop: 10,
              fontWeight: 'bold'
            }}
          >
            {I18N('sentEmails', language)}
          </p>
        </div>

        <div>
          {/*//@ts-ignore*/}
          <div
            role="progressbar"
            aria-valuenow={openLinks}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{
              // @ts-ignore
              '--value': openLinks,
              '--color': '#F9CB38',
              '--inner': props.item.phishing_clicked.length
            }}
          ></div>
          <p
            style={{
              textAlign: 'center',
              color: '#000000',
              marginTop: 10,
              fontWeight: 'bold'
            }}
          >
            {I18N('openEmails', language)}
          </p>
        </div>

        <div>
          {/*//@ts-ignore*/}
          <div
            role="progressbar"
            aria-valuenow={clickLinks}
            aria-valuemin={0}
            aria-valuemax={100}
            style={{
              // @ts-ignore
              '--value': clickLinks,
              '--color': '#F86D70',
              '--inner': props.item.phishing_clicked.filter(
                (item) => item.clicked_at !== ''
              ).length
            }}
          ></div>
          <p
            style={{
              textAlign: 'center',
              color: '#000000',
              marginTop: 10,
              fontWeight: 'bold'
            }}
          >
            {I18N('clickedEmails', language)}
          </p>
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          className="endFlex flex-row"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            justifyItems: 'flex-end',
            float: 'right',
            fontWeight: 'bold',
            fontSize: 15,
            lineHeight: 1.8,
            marginRight: 30
          }}
        >
          {/* {buttonActivated && (<div onClick={() => buttonActivated && setShowUserNames(!showUserNames)} style={{cursor: "pointer", display :"flex", flexDirection: "row", justifyContent: "center", alignContent: "center", marginTop: 10}}>
                            <p style={{padding: 0, margin:0, marginRight: 10}}>{I18N("showUsernames", language)}</p>
                        {showUserNames ? <img style={{marginRight: 30}} src={ShowIcon} width={25} height={25} /> : <img style={{marginRight: 30}} src={HideIcon} width={25} height={25} /> }</div>
                    )} */}
          <div>
            <FormButton
              backButton={false}
              withLoadingInFullScreen
              style={{ marginBottom: -30 }}
              color="dark"
              icon="download"
              smallButton={false}
              text={I18N('exportQuiz', language)}
              onClick={exportData}
              isLoading={loading}
            />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {companyList.length > 1 ? (
            <Alert
              style={{ marginTop: 30, fontWeight: 'bold' }}
              severity="info"
            >
              {I18N('multiCompanySelectedNoShowListOfReceivers', language)}
            </Alert>
          ) : (
            <div style={{ height: 670 }}>
              <TableMui
                isLoading={false}
                rowCount={builtItems.length}
                data={builtItems}
                key="analyticsInner"
                onClick={(data) => {}}
                columns={campaignColumns()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
