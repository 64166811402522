import { I18N } from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";
const videoRepository = new VideoRepository();


export default function UserAnalyticsVideoDep(props: {contentID: number, depID: number; onBack: () => void}) {

    const language = useLanguageState(state => state.language);
    const [watchList, setWatchList] = useState(null);
    const [userList, setUserList] = useState(null);


    const loadData = useCallback(async () => {

        const watchListData = await videoRepository.getWatchListSingleDepartment(props.contentID, props.depID);

        setWatchList(watchListData.contentData);
        setUserList(watchListData.departmentUsers);
    }, []);

    useEffect(() => {
        loadData().then(undefined);
    }, []);

    const calculateRankingList = useCallback(() => {

        if(watchList === null || userList === null){
            return [];
        }

        let userData = [];
        userList.forEach((user) => {

            //When user already finished no need calculation
            if(user.contentData.seen_videos.length > 0){
                userData.push({
                    ...user.userData,
                    percentage: 100,
                });
            }else{

                if(user.contentData.user_content_data.length < 1){
                    userData.push({
                        ...user.userData,
                        percentage: 0
                    })
                }else{
                    const contentProgress = JSON.parse(JSON.parse(user.contentData.user_content_data[0].data));
                    if(watchList.type === 1){
                        //when its video

                        const points = contentProgress.points;
                        userData.push({
                            ...user.userData,
                            percentage: points.score === null ? 0 : Math.ceil(points.score / points.max * 100)
                        })

                    }else if(watchList.type === 3) {
                        const calculate = contentProgress.played * 100;
                        userData.push({
                            ...user.userData,
                            percentage: Math.ceil(calculate)
                        })

                    } else{
                        userData.push({
                            ...user.userData,
                            percentage: Math.ceil(contentProgress.totalPage === null ? 0 : contentProgress.currentPage / contentProgress.totalPage * 100)
                        })

                    }


                }

            }


        });



        userData.sort((a, b) => {
            if (a.percentage > b.percentage) {
                return -1;
            }
            if (a.percentage < b.percentage) {
                return 1;
            }
            return 0;
        });


        return userData;


    }, [watchList, userList]);

    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        } else {
            return 'red'
        }


    }, []);

    return (
        <div style={{marginTop: 30}}>
            <ViewBox title={I18N('contentDetails', language)}>
                <div className="tableView">
                    <thead className="sticky" >
                    <tr className="head sticky"  >
                        <th style={{width: '48%'}} className="tableTh"><div className="ml-10">{I18N('title', language)}</div></th>
                        <th style={{width: '39%'}} className="tableTh"><div className="ml-10">{I18N('totalSeen', language)}</div></th>
                        <th style={{width: '40%'}} className="tableTh"><div className="ml-10">{I18N('totalSeenLast12', language)}</div></th>
                    </tr>
                    </thead>
                    <ul className="ranking">
                        {calculateRankingList().map((userData) => (

                            <li className="rankingBars">
                                <div className="videoTitle">
                                    {userData.firstName} {userData.lastName}
                                </div>
                                <div className="endFlex">
                                    <div className="rankingText">{userData.percentage} / {userData.percentage} ({(userData.percentage / userData.percentage * 100).toFixed(0)}%)</div>
                                    <div className={`rankingBar ${getColor((userData.percentage / userData.percentage * 100))}`} style={{width: (userData.percentage / userData.percentage * 100) + '%'}}>
                                    </div>
                                </div>
                                <div className="endFlex">
                                    <div className="rankingText">{userData.percentage} / {userData.percentage} ({(userData.percentage / userData.percentage * 100).toFixed(0)}%)</div>
                                    <div className={`rankingBar ${getColor((userData.percentage / userData.percentage * 100))}`} style={{width: (userData.percentage / userData.percentage * 100) + '%'}}>
                                    </div>
                                </div>
                            </li>

                        ))}
                    </ul>
                </div>

            </ViewBox>

            <ViewBox title={watchList !== null ? `${watchList.title} (${watchList.type === 1 ? 'Video' : 'PDF'})` : 'Loading...'}>
                <ul className="departmentRankings">
                    {calculateRankingList().map((userData) => (
                        <li className="departmentRankingItem">
                            <div className="title" >
                                {userData.firstName} {userData.lastName}
                            </div>
                            <div className="rank" style={{ width: userData.percentage + '%' }}>
                                <p>{I18N('total', language)}: {userData.percentage} %</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </ViewBox>
        </div>
    )
}
