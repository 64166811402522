import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {useTheme} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import FormButton from "../../../ViewComponents/FormButton";
import {colorForSelect} from "../../../ComplinessTheme";
import Select from "react-select";
import {TrophiesTypes} from "../../../Types/TrophiesTypes";
import {CategoryType} from "../../../Types/CategoryType";
import {TrophiesRepository} from "../../../Repositories/TrophiesRepository";
import {CategoriesRepository} from "../../../Repositories/CategoriesRepository";
import {VideoUploadRepository} from "../../../Repositories/VideoUploadRepository";

const saveKey = 'learningContentStepTech';
const trophyRepository = new TrophiesRepository();
const categoriesRepository = new CategoriesRepository();
const videoUploadRepository = new VideoUploadRepository();
const categoriesKey = "categoriesStepTech";
const trophiesKey = "trophiesStepTech";

function LearningContentStepIn(props: {title}, ref){

    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [formState, setFormState] = useState([{
        content: undefined,
        trophy: undefined,
        category: undefined
    }]);
    const [trophies, setTrophies] = useState([]);
    const [categories, setCategories] = useState([]);
    const [learningContents, setLearningContents] = useState([]);
    const [createdCategories, setCreatedCategories] = useState([]);
    const [createdTrophies, setCreatedTrophies] = useState([]);


    useEffect(() => {

        let categoryData: string | any[] = localStorage.getItem(categoriesKey);
        let trophiesData: string | any[] = localStorage.getItem(trophiesKey);

        try {
            categoryData = JSON.parse(categoryData) as any[];
            trophiesData = JSON.parse(trophiesData) as any[];

            setCreatedCategories(categoryData.map((i) => {

                return {id: -1, userInnerData: i.user.label, ...i};
            }));
            setCreatedTrophies(trophiesData.map((i) => {
                return {id: -1, ...i};
            }));

        }catch (err) {

            console.log(err);
        }

    }, []);

    const getTrophies = useCallback(async () => {

        const trophyList = await trophyRepository.getAll();

        setTrophies(trophyList as TrophiesTypes[]);

    }, []);

    const getContentList = useCallback(async () => {

        let getList = await videoUploadRepository.getContentList();

        setLearningContents(getList);

    }, []);

    const getCategories = useCallback(async () => {

        const categoryList = await categoriesRepository.getAll();

        setCategories(categoryList as CategoryType[]);

    }, []);


    useEffect(() => {
        Promise.all([getTrophies(), getContentList(), getCategories()]).then(undefined);
    }, [getCategories, getTrophies, getContentList]);

    const clearData = useCallback(() => {

        setFormState([{
            content: undefined,
            trophy: undefined,
            category: undefined
        }]);
        localStorage.removeItem(saveKey);

    }, []);

    const checkLocalStorage = useCallback(() => {

        const categoryTechData = localStorage.getItem(saveKey);
        if(categoryTechData){
            setFormState(JSON.parse(categoryTechData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formState));

    }, [formState]);

    return (
        <div style={{padding: 10, maxHeight: 380, minHeight: 380, overflowY: "scroll"}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>

            {formState.map((i, key) => {
                return (
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 10}}>
                        <Select placeholder={I18N('selectContent', language)} styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 54,
                                    width: 200,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },}} value={formState[key].content} theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} options={learningContents.map(content => { return {value: content.id, label: content.name + ' (' + (content.type === 1 ? 'Video' : content.type === 2 ? 'PDF' : 'MP4 VIDEO') + ')'}})} onChange={(item) => {
                            formState[key].content = item;
                            setFormState([...formState]);
                        }} />
                        <Select placeholder={I18N('selectCategory', language)} styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 54,
                                    width: 200,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },}} theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} value={formState[key].category} options={[...createdCategories,...categories].map(category => { return {value: category.id, label: `${category.name} ${category.userInnerData !== undefined ? `(${category.userInnerData})` : category.user !== undefined ? `(${category?.user?.firstName} ${category?.user?.lastName})` : ''}`}})} onChange={(item) => {
                            formState[key].category = item;
                            setFormState([...formState]);
                        }} />

                        <Select  styles={{  valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 54,
                                    width: 200,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },}} theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} isClearable={true} isMulti={false} onChange={(change) => {
                            formState[key].trophy = change;
                            setFormState([...formState]);
                        }}  value={formState[key].trophy}  placeholder={I18N('selectTrophy', language)} options={[...createdTrophies, ...trophies].map((trophy) =>  ({value: trophy.id, label: trophy.name}))} />
                    </div>
                )
            })}

            <div style={{marginTop: 20}}>
                <FormButton text={I18N('new', language)} color="dark" icon="plus" onClick={() => {
                    formState.push({
                        content: undefined,
                        trophy: undefined,
                        category: undefined
                    });
                    setFormState([...formState]);
                }} isLoading={false} />
            </div>



            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )

}

const LearningContentStep = React.forwardRef(LearningContentStepIn);

export default LearningContentStep;

