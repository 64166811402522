import {Drawer, FormControlLabel, FormGroup, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import {useTheme} from "@mui/material";
import FormButton from "../FormButton";
import {SidebarContainer, SidebarDrawer} from "./Drawer";
import {TrophiesRepository} from "../../Repositories/TrophiesRepository";
import {CategoriesRepository} from "../../Repositories/CategoriesRepository";
import {QuizRepository} from "../../Repositories/QuizRepository";
import {TrophiesTypes} from "../../Types/TrophiesTypes";
import {CategoryType} from "../../Types/CategoryType";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import Select from "react-select/creatable";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {OptionTypeBase} from "react-select/src/types";
import {CompanyRepository} from "../../Repositories/CompanyRepository";
import {Android12Switch} from "./Android12Switch";
import {DepartmentType} from "../../Types/DepartmentTypes";
import {DepartmentsRepository} from "../../Repositories/DepartmentsRepository";
import {DrawerConfigSx} from "../DrawerConfigSx";
import ViewBox from "../ViewBox";

const videoRepository = new VideoRepository();

const departmentsRepository = new DepartmentsRepository();


const DepartmentsBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any, setContent: (data) => void;}) => {
    const language = useLanguageState();
    const theme = useTheme();
    const [departments, setDepartments] = useState([]);

    const [loadingData, setLoadingData] = useState(false);

    const [formState, setFormState] = useState<{
        visibleForAll: boolean,
        requiredForAll: boolean,
        mandatoryDeps: any[],
        visibleDeps: any[]
    }>({
        visibleForAll: false,
        requiredForAll: false,
        mandatoryDeps: [],
        visibleDeps: []
    });


    const getDepartments = useCallback(async() => {

        const depList = await departmentsRepository.getAll();

        setDepartments(depList as DepartmentType[]);

        return depList as DepartmentType[];
    }, []);

    const loadStateWhenDataLoaded = useCallback((departments: DepartmentType[]) => {

        const stateData = {
            visibleForAll: false,
            requiredForAll: false,
            mandatoryDeps: [],
            visibleDeps: []
        }

        if(props.content.requiredForAll){
            stateData.requiredForAll = true;
        }
        if(props.content.visibleForAll){
            stateData.visibleForAll = true;
        }

        const depList = props.content.video_departments.map((dep) => dep.department_id);
        const awardableDeps = props.content.video_departments.filter((dep) => dep.awardable).map((dep) => dep.department_id);

        const depListVisible = departments.filter((i) => depList.includes(i.ID));
        const depListMandatory = departments.filter((i) => awardableDeps.includes(i.ID));

        stateData.mandatoryDeps = depListMandatory.map((i) => {
            return i.ID;
        })
        stateData.visibleDeps = depListVisible.map((i) => {
            return i.ID;
        })



        setFormState({...stateData});
        console.log(stateData);

    }, [props.content]);

    useEffect(() => {

        setLoadingData(true);
        Promise.all([getDepartments()]).then(((response) => {

            loadStateWhenDataLoaded(response[0]);

            setLoadingData(false);
        })).catch((err) => {
            console.log(err);
            setLoadingData(false)
        })

    }, [loadStateWhenDataLoaded]);


    const saveChanges = useCallback(async () => {
        setLoadingData(true);

        await videoRepository.updateDepartments({
            awardable: formState.mandatoryDeps,
            departments: formState.visibleDeps,
            visibleForAll: formState.visibleForAll,
            requiredForAll: formState.requiredForAll,
        }, props.content.id).then((response) => {
            console.log("success");
            props.setIsOpen(false)
            props.setContent(response);
            setLoadingData(false);
        }).catch((err) => {
            console.log('err');
            setLoadingData(false);
        })

        setLoadingData(false);
    }, [formState, props.content]);

    return (
        <SidebarContainer>
            <Drawer  BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDepartments', language.language)}</p>
                        <FormButton icon="save" withLoadingInFullScreen={true} color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>

                            <table style={{width: '98%', borderSpacing: 0}}>
                                <thead style={{  width: '98%'}}>
                                <tr style={{borderRadius: 10}}>
                                    <th style={{padding: 15, color: "#3E4649", fontSize: 14, fontWeight: 'bold' }}>{I18N('visibleForAll', language.language)}</th>
                                    <th style={{padding: 15, color: "#3E4649", fontSize: 14, fontWeight: 'bold' }}>{I18N('requiredForAll', language.language)}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{textAlign: "center"}}>
                                        <Android12Switch checked={formState.visibleForAll} value={formState.visibleForAll} onChange={() => {
                                            setFormState({...formState, visibleDeps: !formState.visibleForAll ?  departments.map((i) => i.ID) : [], visibleForAll: !formState.visibleForAll})
                                        }} color="warning" />
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                        <Android12Switch checked={formState.requiredForAll} value={formState.requiredForAll} onChange={() => {
                                            setFormState({...formState, mandatoryDeps: !formState.requiredForAll ?  [...formState.visibleDeps] : [], requiredForAll: !formState.requiredForAll})
                                        }} color="warning" />
                                    </td>
                                </tr>


                                </tbody>
                            </table>
                            <table style={{width: '98%', borderSpacing: 0}}>
                                <thead style={{  width: '98%'}}>
                                <tr style={{borderRadius: 10}}>
                                    <th style={{padding: 15, textAlign: "left", color: "#3E4649", fontSize: 14, fontWeight: 'bold' }}>{I18N('department', language.language)}</th>
                                    <th style={{padding: 15,textAlign: "left", color: "#3E4649", fontSize: 14, fontWeight: 'bold' }}>{I18N('available', language.language)}</th>
                                    <th style={{padding: 15,textAlign: "left",  color: "#3E4649", fontSize: 14, fontWeight: 'bold' }}>{I18N('mandatory', language.language)}</th>
                                </tr>
                                </thead>
                                <tbody>




                                {departments.map((item) => (
                                    <tr>
                                        <td style={{padding: 15}}>
                                            {item.name}
                                        </td>
                                        <td style={{padding: 15}}>
                                            <Android12Switch disabled={formState.visibleForAll} checked={formState.visibleDeps.includes(item.ID)} onChange={() => {
                                                const check = formState.visibleDeps.findIndex((i) => i === item.ID);
                                                if(check === -1){
                                                    setFormState({...formState, visibleDeps: [...formState.visibleDeps, item.ID]})
                                                }else{
                                                    formState.visibleDeps.splice(check, 1);
                                                    setFormState({...formState, visibleDeps: [...formState.visibleDeps]});
                                                }
                                            }}  color="warning" />
                                        </td>
                                        <td style={{padding: 15}}>
                                            <Android12Switch disabled={formState.requiredForAll || (!formState.visibleForAll && (!formState.visibleDeps.includes(item.ID) ))} checked={formState.mandatoryDeps.includes(item.ID)} onChange={() => {
                                                const check = formState.mandatoryDeps.findIndex((i) => i === item.ID);
                                                if(check === -1){
                                                    setFormState({...formState, mandatoryDeps: [...formState.mandatoryDeps, item.ID]})
                                                }else{
                                                    formState.mandatoryDeps.splice(check, 1);
                                                    setFormState({...formState, mandatoryDeps: [...formState.mandatoryDeps]});
                                                }
                                            }} color="warning" />
                                        </td>
                                    </tr>

                                ))}

                                </tbody>

                            </table>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default DepartmentsBar;
