import React, {useCallback, useEffect, useState} from "react";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import ListsList from "./ListsList";
import ListItemOperation, { formState } from "./ListItemOperation";
import { ListsRepository } from "../Repositories/ListsRepository";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useLocation} from "react-router-dom";
import {useSnackbar} from "notistack";

const listsRepository = new ListsRepository();


export default function ListsTab() {
    const language = useLanguageState(state => state.language);
    const [loadingForm, setLoadingForm] = useState(false);
    const [selectedListItem, setSelectedListItem] = useState(null);
    const [createMode, setCreateMode] = useState(false);
    const breadCrumb = useBreadCrumb();
    const {enqueueSnackbar} = useSnackbar();
    const [formState, setFormState] = useState<formState>({
        users: [],
        videos: undefined,
        name: '',
        description: '',
    })
    const location = useLocation();
    const [mountKey, setMountKey] = useState(undefined);

    useEffect(() => {
        if(location.key !== undefined){
            if(location.key !== mountKey){
                onReturnBackToView();
            }
        }
        setMountKey(location.key);
    }, [location, mountKey]);

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);

    const backToListsTable = useCallback(() => {

        setCreateMode(false);
        setSelectedListItem(null);
        scrollTop();
    }, []);

    const updateOrCreateList = useCallback(async () => {

        if (formState.name === '' || formState.description === '') {
            enqueueSnackbar(I18N("pleaseFill", language, [{ key: 'name', value: I18N('listItem', language) }]), {variant: "error"});
            return;
        }

        setLoadingForm(true);

        if(formState.videos === undefined || formState.videos === null) {
            formState.videos = [];
        }

        const formItemParser = JSON.parse(JSON.stringify(formState));

        formItemParser.videos = formItemParser.videos.map((video) => video.value);

        if (selectedListItem !== null) {

            const update = await listsRepository.update(formItemParser, selectedListItem.id).catch(async () => {
                setLoadingForm(false);
                enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            });

            if (!(update instanceof Error)) {
                setLoadingForm(false);
                enqueueSnackbar(I18N("successfullyUpdatedReturning", language,[{ key: 'name', value: I18N('listItem', language) }]), {variant: "success"});
                onReturnBackToView();
            }

        } else {

            const create = await listsRepository.post(formItemParser).catch(async () => {
                setLoadingForm(false);
                enqueueSnackbar(I18N("unexpectedError", language,), {variant: "error"});
            })

            if (!(create instanceof Error)) {
                setLoadingForm(false);
                enqueueSnackbar(I18N("successfullyCreatedReturning", language,[{ key: 'name', value: I18N('listItem', language) }]), {variant: "success"});
                onReturnBackToView();
            }

        }

        setLoadingForm(false);

    }, [backToListsTable, formState, language, selectedListItem]);


    const deleteListItem = useCallback(async (item) => {

        await listsRepository.delete(item.id).then(async () => {
            enqueueSnackbar(I18N("deleteSuccess", language,[{ key: 'name', value: I18N('listItem', language) }]), {variant: "success"});
        });


    }, [language]);

    const onReturnBackToView = useCallback(() => {

        breadCrumb.setBackButton(null);
        breadCrumb.setTitle(I18N('lists', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined}, {
            title: I18N('lists', language),
            onClick: onReturnBackToView
        }]);
        breadCrumb.setChildren([
            {
                key: "listCreateKey",
                title: I18N('new', language),
                onClick: () => {
                    setCreateMode(!createMode);
                    scrollTop();
                },
                color: 'dark',
                icon: "plus"
            }
        ])
        backToListsTable();
    }, [language]);

    useEffect(() => {
        onReturnBackToView();
    }, []);


    return (
        <React.Fragment>
            {!selectedListItem && !createMode ? (
                <ListsList setEditMode={(item) => {
                    setSelectedListItem(item);
                    setCreateMode(true);
                    scrollTop();
                }} deleteListItem={deleteListItem} />
            ) : (
                <ListItemOperation onFinish={updateOrCreateList} backCallback={onReturnBackToView} onFormChange={setFormState} editMode={selectedListItem} />
            )}
        </React.Fragment>
    )

}
