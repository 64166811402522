import {I18N} from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useRef, useState} from "react";
import ViewBoxTab from "../ViewComponents/ViewBoxTab";
import {useLanguageState} from "../States/LanguageState";
import {TextField, useTheme} from "@mui/material";
import Select from "react-select";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import {ClassificationDecider, StatusDecider, StatusDeciderColor} from "./StatusDecider";
import {UserType} from "../Types/UserType";
import { Editor } from "react-draft-wysiwyg";
import EditPen from '../assets/editPen.svg';
import {DocumentsRepository} from "../Repositories/DocumentsRepository";
import FormButton from "../ViewComponents/FormButton";
import ReactDiffViewer, {DiffMethod} from "../Helpers/DiffViewer";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineSeparator
} from "@mui/lab";
import FullButton from "../ViewComponents/FullButton";
import {BACKEND_URL} from "../assets/AppConstants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {groupBy} from "../Helpers/groupBy";
import {useLoginState} from "../States/UserState";
import {tableOfContentCalculator} from "../Helpers/TableOfContentsCalculator";
import {colorForSelect} from "../ComplinessTheme";
import DownloadButton from "../assets/downloadButton.svg";
import FullScreenOpen from "../assets/fullscreenOpen.svg";
import DocBookSVG from "../assets/docBook.svg";
import CSVG from "../assets/chapter2.svg";
import slugify from "../Helpers/Slugify";
import {Link} from "react-router-dom";
import TabIndicator from "../ViewComponents/Tabs/TabIndicator";
import EditIcon from "../ViewComponents/EditIcon";
import GeneralContentBar from "./Bars/GeneralContentBar";
import DataContentBar from "./Bars/ContentBar";
import ReleaseMadeByBar from "./Bars/ReleaseMadeByBar";
const sweetAlert = withReactContent(Swal)
Swal.mixin({
    confirmButtonColor: "#8CEEB6",
    cancelButtonColor: "#F2F2F2",
    reverseButtons: true,
    customClass: {
        cancelButton: "cancelCustom",
        confirmButton: "confirmCustom"
    }
})


const documentsRepository = new DocumentsRepository();

type Props = {
    document: null | any;
    users: UserType[];
    history: any[],
    inWorkHistory: any[],
    inWork: null | any;
    setEditMode: (status: boolean) => void;
    onUpdate: () => void;
    exportHistory: any[]
    tableOfContents: any[]
}


export default function EditDocument(props: Props){
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [formData, setFormData] = useState({title: '', deadline: '', classification: undefined, status: 4, oID: "create"});
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [currentTab, setCurrentTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [activeVersion, setActiveVersion] = useState(props.document?.__v);
    const [responsibleUser, setResponsilbeUser] = useState(undefined);
    const [implementationUsers, setImplementationUsers] = useState(undefined);
    const [somethingChanged, setSomethingChanged]  = useState(false);
    const [selectedVersion, setSelectedVersion] = useState(undefined);
    const [availableVersionNumbers, setAvailableVersionNumbers] = useState([]);
    const [canEditEverything, setCanEditEverything] = useState(false);
    const authStatus = useLoginState();
    const [isMinorCompare, setIsMinorCompare] = useState(null);
    const contentRef = useRef(null);

    const [activeTab, setActiveTab] = useState('general');

    const [editGeneralContent, setEditGeneralContent] = useState(false);
    const [editContent, setEditContent] = useState(false);
    const [editMadeBy, setEditMadeBy] = useState(false);
    //const previous = usePrevious({implementationUsers, responsibleUser, editorState, formData});

    // useEffect(() => {
    //
    //     if(!somethingChanged && JSON.stringify(previous) !== JSON.stringify({implementationUsers, responsibleUser, editorState, formData})){
    //         console.log("yes");
    //         setSomethingChanged(true);
    //     }
    //
    // }, [implementationUsers, responsibleUser, editorState, formData, previous, somethingChanged]);



    useEffect(() => {
        console.log(responsibleUser, authStatus.data?.sub, "idssshere")
        if(responsibleUser !== undefined){
            console.log(responsibleUser.value, authStatus.data, "idssshere")
            if(responsibleUser.value === authStatus.data?.sub){
                setCanEditEverything(true);
            }else{
                setCanEditEverything(false);
            }
        }else{
            setCanEditEverything(true);
        }

    }, [authStatus.data?.sub, responsibleUser]);


    useEffect(() => {

        console.log(implementationUsers, responsibleUser);
    }, [implementationUsers, responsibleUser]);


    useEffect(() => {

        if(props.inWork?.responsibleUsers.length > 0){
            const findUser = props.users.find((user) => user.id === props.inWork?.responsibleUsers[0]);

            if(findUser){
                setResponsilbeUser({label: findUser.firstName + ' ' + findUser.lastName, value: findUser.id, innerData: findUser.innerData});
            }
        }

        if(props.inWork?.implementationUsers.length > 0){
            const list = [];
            props.inWork.implementationUsers.forEach((item) => {
                const finduser = props.users.find((u) => u.id === item);
                if(finduser)
                list.push({label: finduser.firstName + ' ' + finduser.lastName, value: finduser.id, innerData: finduser.innerData});
            });
            if(list.length > 0)
                setImplementationUsers(list);
            else
                setImplementationUsers(undefined);
        }


    }, [props.users, props.inWork]);


    useEffect(() => {

        if(props.inWork === null){

            const drafter = htmlToDraft(props.document.document);
            const contentState = ContentState.createFromBlockArray(drafter.contentBlocks);
            const state = EditorState.createWithContent(contentState);
            setEditorState(state);
            setFormData({
                status: props.document.status,
                title: props.document.title,
                deadline: props.document.deadline,
                classification: classificationList.find((i) => i.value === props.inWork.classification),
                oID: props.document?._id
            });

            return;
        }
        const drafter = htmlToDraft(props.inWork.document);
        const contentState = ContentState.createFromBlockArray(drafter.contentBlocks);
        const state = EditorState.createWithContent(contentState);
        setEditorState(state);
        setFormData({
            status: props.inWork.status,
            title: props.inWork.title,
            deadline: props.inWork.deadline,
            classification: classificationList.find((i) => i.value === props.inWork.classification),
            oID: props.document?._id
        });


        if(props.inWorkHistory !== null && props.inWorkHistory.length > 0){
            let keyList = Object.keys(groupBy(props.inWorkHistory, 'version'));
            setAvailableVersionNumbers(keyList);
            console.log(keyList, props.inWork.version);
            if(keyList.length > 0 && Number(props.inWork.version) !== Number(keyList[keyList.length - 1])){
                keyList.push(props.inWork.version);
            }
            keyList = keyList.reverse();
            if(keyList.length > 0){
                setSelectedVersion({label: `Version ${Number(keyList[0]) + 1}`, value: keyList[0]});

            }
        }

    }, [props]);

    const classificationList = [
        {label: I18N('confidential', language), value: 1},
        {label: I18N('public', language), value: 2},
        {label: I18N('internal', language), value: 3},
        {label: I18N('strictlyConfidential', language), value: 4},
    ]


    const teamEditSwal = useCallback(async () => {

        await sweetAlert.fire({
            showCloseButton: true,
            title: I18N('teamEditView', language),
            showConfirmButton: false,
            showCancelButton: false,
            html: (<TeamEditViewModal implementationUsers={implementationUsers} setImplementationUsers={setImplementationUsers} responsibleUser={responsibleUser} setResponsibleUser={setResponsilbeUser} users={props.users} setSomethingChanged={setSomethingChanged} />)
        });


    }, [language, props.users, responsibleUser, implementationUsers]);


    const teamEditView = useCallback(() => {

        return (
            <ViewBox title={I18N('team', language)} rightComponent={(canEditEverything ? (<img onClick={teamEditSwal} style={{cursor:"pointer"}} src={EditPen} />) : (''))}>
                <p style={{fontSize: 14,  color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{I18N('responsibleUser', language)}</p>
                {props.inWork?.responsibleUsers.length > 0 && (
                    <div style={{display: "flex", justifyContent: "left", alignItems: "left"}}>
                        <img src={props.users.find((user) => user.id === props.inWork.responsibleUsers[0])?.innerData.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' : BACKEND_URL + '/users' +props.users.find((user) => user.id === props.inWork.responsibleUsers[0])?.innerData.photo} style={{height: 40, width: 40, borderRadius: 50, cursor: "pointer" }}  alt={"userImage"}/>
                        <p style={{fontSize: 12, fontWeight: 400, color: "#fff", marginLeft: 10}}>{props.users.find((user) => user.id === props.inWork.responsibleUsers[0])?.firstName} {props.users.find((user) => user.id === props.inWork.responsibleUsers[0])?.lastName}</p>
                    </div>
                )}

                <p style={{fontSize: 14,  color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{I18N('implementationUser', language)}</p>
                <div style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}} className="avatarOverAvatar">
                    {props.inWork?.implementationUsers.map((impUser) => {
                        const userData = props.users.find((user) => user.id === impUser);
                        if(!userData){
                            return;
                        }
                        return (
                            <img src={userData.innerData.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' : BACKEND_URL + '/users' +userData.innerData.photo} style={{height: 40, width: 40, borderRadius: 50, cursor: "pointer" }}  alt={"userImage"}/>
                        )
                    })}
                </div>

            </ViewBox>
        )

    }, [language, props.inWork?.implementationUsers, props.inWork?.responsibleUsers, props.users, teamEditSwal, canEditEverything]);



    const updateOrCreateDocument = useCallback(async (useContentFromPrevious?: string, status?: number) => {
        const data = {
            deadline: formData.deadline,
            title: formData.title,
            classification: (formData.classification !== undefined && formData.classification !== null) ? formData.classification.value : 1,
            document: useContentFromPrevious !== undefined ? useContentFromPrevious : draftToHtml(convertToRaw(editorState.getCurrentContent())),
            status: status !== undefined ? status : formData.status,
            implementationUsers: implementationUsers !== undefined ? implementationUsers.map((i) => i.value) : ["88120927-23f7-47f3-8b29-332e2bcc14ef", "00180875-93cd-4af5-8d2b-ca0d1333161d"],
            responsibleUsers: responsibleUser !== undefined ? [responsibleUser.value] : ["88120927-23f7-47f3-8b29-332e2bcc14ef"],
            oID: formData.oID
        };
        const x = await documentsRepository.post(data);
        await sweetAlert.fire({
            showCloseButton: true,
            confirmButtonColor: "#8CEEB6",
            cancelButtonColor: "#F2F2F2",
            reverseButtons: true,
            customClass: {
                cancelButton: "cancelCustom",
                confirmButton: "confirmCustom"
            },
            title: I18N('success', language),
            html: I18N('successfullyUpdated', language, [{key: 'name', value: I18N('documents', language)}]),
            icon: "success"
        })
        //alert("saved");
        setSomethingChanged(false);
        props.onUpdate();
    }, [formData.deadline, formData.title, formData.classification, formData.status, editorState, implementationUsers, responsibleUser, language, props]);


    const regexReplaceDocument = useCallback(() => {

        const regex = /<h([1-6]).*?>(.*?)<\/h[1-6]>/gi;

        if(props.inWork !== null){
            if(props.inWork){
                return props.inWork.document.replace(regex, (match, capture, capture2) => {
                    console.log(match, capture, capture2);
                    return `<section id="heading-${capture}-${slugify(capture2)}"><h${capture}>${capture2}</h${capture}></section>`;
                });
            }else{
                return "";
            }

        }else{

            if(props.document){
                return props.document.document.replace(regex, (match, capture, capture2) => {
                    console.log(match, capture, capture2);
                    return `<section id="heading-${capture}-${slugify(capture2)}"><h${capture}>${capture2}</h${capture}></section>`;
                });
            }else{
                return "";
            }
        }



    }, [props.document]);


    const contentData = useCallback(() => {
        return (
            <div style={{justifyContent: "space-between", width: '100%', display: "flex"}}>
                <div style={{width: '70%', position: "relative"}}>
                    <ViewBox rightComponent={(canEditEverything ? <EditIcon onClick={() => setEditContent(true)} /> : "")}>
                        <div dangerouslySetInnerHTML={{__html: regexReplaceDocument()}}></div>
                    </ViewBox>
                </div>
                <div style={{width: '29%'}}>
                    <div>
                        <ViewBox rightComponent={(canEditEverything ? <EditIcon onClick={() => setEditContent(true)} /> : "")} title={I18N('tableOfContents', language)}>
                            <ol>
                                {tableOfContentCalculator(contentRef).map((item) => (
                                    <li style={{paddingRight: 10, paddingLeft: 10, fontSize: 16, marginBottom:8, padding: 0}}>

                                        {item.itemTitle}
                                        {item.child.length > 0 && (
                                            <ol type="a" style={{padding: 0, paddingLeft: 10, marginBottom: 5}}>
                                                {item.child.map((inner) => (
                                                    <li style={{fontSize: 16}}>
                                                        {inner.itemTitle}
                                                    </li>
                                                ))}
                                            </ol>
                                        )}
                                    </li>
                                ))}
                            </ol>
                            <div style={{display: "none"}} ref={contentRef} dangerouslySetInnerHTML={{__html: props.inWork.document}}></div>
                        </ViewBox>

                    </div>

                </div>

            </div>

        )
    }, [canEditEverything, regexReplaceDocument, language, props.inWork.document]);

    const exportHistoryTable = useCallback(() => {


        return (
            <div className="tableView" style={{width: '95%', borderCollapse: "separate", borderSpacing: 0}}>
                <thead className="sticky" >
                <tr className="head sticky"  >
                    <th style={{width: '10%', backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{I18N('id', language)}</div></th>
                    <th style={{width: '60%' , backgroundColor:  theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{I18N('user', language)}</div></th>
                    <th style={{width: '40%', backgroundColor:  theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{I18N('date', language)}</div></th>
                    <th style={{width: '40%', minWidth: 150, backgroundColor:  theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{I18N('version', language)}</div></th>
                </tr>
                </thead>
                <tbody>
                {props.exportHistory.map((item, i) => {
                    const userDataOfExport = props.users.find((uItem) => {
                        return uItem.id === item.user_id;
                    })
                    return (
                        <tr className="exportTable">
                            <td style={{paddingLeft: 10, paddingTop: 20}}>{props.exportHistory.length - i}</td>
                            <td style={{paddingTop: 20}}>{userDataOfExport !== undefined ? userDataOfExport.firstName + ' ' + userDataOfExport.lastName : item.user_id}</td>
                            <td style={{paddingTop: 20}}>{item.date}</td>
                            <td style={{paddingTop: 20}}>{item.version + 1}</td>
                        </tr>)
                })}
                </tbody>

            </div>
        )

    }, [language, props.exportHistory, props.users, theme]);

    const generalInformation = useCallback(() => {

        return (
            <>
                <div style={{justifyContent: "space-between", width: '100%', display: "flex"}}>
                    <div style={{width: '49%', position: "relative"}}>
                        <ViewBox rightComponent={(canEditEverything ? <EditIcon onClick={() => setEditGeneralContent(true)} /> : "")} title={I18N('generalInformation', language) + ' - ' + formData.oID}>

                            <table style={{borderSpacing: 15}}>
                                <tr>
                                    <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12}}>{I18N('title', language)}</td>
                                    <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}>{formData.title}</td>
                                </tr>
                                <tr>
                                    <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('validUntil', language)}</td>
                                    <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{formData.deadline}</td>
                                </tr>
                                <tr>
                                    <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('classification', language)}</td>
                                    <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}>{formData.classification?.label ?? ''}</td>
                                </tr>
                            </table>
                        </ViewBox>
                    </div>
                    <div style={{width: '49%', position: "relative"}}>
                        <ViewBox title={I18N('exportHistory', language)}>
                            {exportHistoryTable()}
                        </ViewBox>
                    </div>

                </div>
            </>


        )

    }, [language, props.inWork?.status, canEditEverything, formData, classificationList, teamEditView, exportHistoryTable]);


    const restoreVersion = useCallback(async () => {

        //TODO: CHECK VERSION NUMBER FOR TRANSLATION
        const historyItem = props.history.find((h) => (activeVersion - 1) === h.version);

        await sweetAlert.fire({
            showCloseButton: true,
            title: I18N('areYouSureVersion', language),
            html: I18N('areYouSureVersionText', language, [{key: "versionNR", value: historyItem.version + 2}]),
            showCancelButton: true,
            confirmButtonText: I18N("yes", language),
            cancelButtonText: I18N('no', language),
            confirmButtonColor: "#8CEEB6",
            cancelButtonColor: "#F2F2F2",
            reverseButtons: true,
            customClass: {
                cancelButton: "cancelCustom",
                confirmButton: "confirmCustom"
            },
            icon: "question"
        }).then(async (response) => {
            if(response.isConfirmed){
                const historyItem = props.history.find((h) => (activeVersion - 1) === h.version);
                await updateOrCreateDocument(historyItem.document);
            }
        })



    }, [activeVersion, props.history, updateOrCreateDocument]);



    const versionHistory = useCallback(() => {

        const selectHistoryItem = props.history.find((h) => (activeVersion - 1) === h.version);

        const historyData = selectHistoryItem !== undefined ? selectHistoryItem.document.replace(/<\/?[^>]+(>|$)/g, "").replace(/\&nbsp;/g, " ") : ''

        let newerData = props.document?.document.replace(/<\/?[^>]+(>|$)/g, "").replace(/\&nbsp;/g, " ");

        if(activeVersion !== props.document?.__v){
            const trySelecting =  props.history.find((h) => activeVersion === h.version);
            if(trySelecting === undefined){
                newerData = '';
            }else{
                newerData = trySelecting.document.replace(/<\/?[^>]+(>|$)/g, "").replace(/\&nbsp;/g, " ");
            }
        }

        let minorVersion = null;
        let minorData = '';
        const selectMinorVersion = props.inWork;
        //const minorHistory = props.inWorkHistory.filter((inWorkHistory) =>  inWorkHistory.version === props.document?.__v + 1);
        if(selectMinorVersion?.version > props.document?.__v){
            minorVersion = selectMinorVersion//selectMinorVersion[selectMinorVersion.length - 1];
            minorData = minorVersion.document.replace(/<\/?[^>]+(>|$)/g, "").replace(/\&nbsp;/g, " ");
        }


        console.log(minorVersion);


        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: '49%'}}>
                    <ViewBox title={I18N('historyTitle', language)}>
                        <Timeline position="alternate">

                            {minorVersion !== null && (
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <p style={{margin:0, cursor: "pointer"}} onClick={() => {
                                            setActiveVersion(props.document?.__v);
                                            setIsMinorCompare(minorVersion);
                                        }}>
                                            {StatusDecider(minorVersion?.status, language) + ' / '}
                                            {I18N('version', language)} {props.document?.__v + 1}.1
                                        </p>
                                        <p style={{padding: 0, margin: 0, fontSize: 14, fontWeight: 200}}>{minorVersion?.date}</p>

                                    </TimelineContent>
                                </TimelineItem>
                            )}


                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <p style={{margin:0, cursor: "pointer"}} onClick={() => {
                                        setActiveVersion(props.document?.__v);
                                        setIsMinorCompare(null);
                                    }}>
                                        {StatusDecider(props.document?.status, language) + ' / '}
                                        {I18N('version', language)} {props.document?.__v + 1}
                                    </p>
                                    <p style={{padding: 0, margin: 0, fontSize: 14, fontWeight: 200}}>{props.document?.date}</p>

                                </TimelineContent>
                            </TimelineItem>

                            {props.history.map((item, i) => (
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <p style={{cursor: "pointer",margin: 0}} onClick={() => {
                                            setActiveVersion(item.version);
                                            setIsMinorCompare(null);
                                        }}>
                                        {I18N('version', language)} {item.version + 1}
                                        </p>
                                        <p style={{margin: 0, padding: 0, fontSize: 14, fontWeight: 200}}>
                                            {props.inWork?.date}
                                        </p>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </ViewBox>
                </div>
                <div style={{width: "50%"}}>
                    <ViewBox title={`${I18N('version', language)} ${activeVersion + 1}${isMinorCompare !== null ? '.1' : ''}`} rightComponent={(
                        <FormButton disabled={!canEditEverything && isMinorCompare === null} text={I18N('restoreVersion', language)} withLoadingInFullScreen={true} onClick={() => (canEditEverything && isMinorCompare === null) ? restoreVersion() : undefined} isLoading={loading} />
                    )}>
                        <div style={{marginTop: 20}}>
                            {props.document !== null && props.history.length > 0 && (
                                <ReactDiffViewer
                                    useDarkTheme={theme.palette.mode === 'dark'}
                                    oldValue={isMinorCompare !== null ? newerData : historyData}
                                    newValue={isMinorCompare !== null ? minorData : newerData}
                                    splitView={false}
                                    compareMethod={DiffMethod.WORDS}
                                    //styles={newStyles}
                                    // renderContent={highlightSyntax}
                                />

                            )}
                        </div>

                    </ViewBox>
                </div>
            </div>
        )

    }, [activeVersion, canEditEverything, props.inWork, props.history, isMinorCompare, theme]);


    const changeStatus = useCallback((status: number) => {

        const form = {...formData, status: status}
        setFormData({...form});

    }, [formData]);


    const statusPage = useCallback(() => {

        const selectUserInWork = props.users.find((item) => item.id === props.inWork?.user_id);

        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: '49%', position: "relative"}}>
                    <ViewBox title={I18N('historyTitle', language)} rightComponent={(
                        <div style={{minWidth: 200}}>
                            <Select theme={(template) => {
                                return {
                                    ...template,
                                    colors: {
                                        ...template.colors,
                                        ...colorForSelect(theme)
                                    }
                                }
                            }} style={{margin:0, minWidth: 200}} onChange={(change) => {
                                setSelectedVersion(change);
                            }}  value={selectedVersion} placeholder={I18N('version', language)} options={availableVersionNumbers.map((item) => {return {label: `Version ${Number(item) + 1}`, value: item}})} />
                        </div>

                    )}>
                        <Timeline position="alternate">

                            {availableVersionNumbers.length > 0 && Number((availableVersionNumbers[0])) === Number(selectedVersion?.value) && (
                                <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <p style={{margin:0, fontWeight: "bold"}}>{StatusDecider(props.inWork?.status, language)}</p>
                                    <p style={{padding: 0, margin: 0, fontSize: 14, fontWeight: 200}}>{props.inWork?.date}</p>
                                    <p style={{padding: 0, margin: 0, fontSize: 14, fontWeight: 200}}>{selectUserInWork?.firstName} {selectUserInWork?.lastName}</p>
                                </TimelineContent>
                            </TimelineItem>
                            )}

                            {props.inWorkHistory.filter((item) => {

                                if(selectedVersion !== undefined && selectedVersion !== null){
                                    return Number(selectedVersion.value) === item.version
                                }else{
                                    return false;
                                }
                            }).map((item, i) => {
                                const selectUserInWork = props.users.find((useritem) => useritem.id === item.user_id);

                                return (

                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot />
                                        <TimelineConnector />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <p style={{fontWeight: "bold"}}>{StatusDecider(item.status, language)}</p>
                                        <p style={{padding: 0, margin: 0, fontSize: 14, fontWeight: 200}}>{item.date}</p>
                                        <p style={{padding: 0, margin: 0, fontSize: 14, fontWeight: 200}}>{selectUserInWork?.firstName} {selectUserInWork?.lastName}</p>
                                    </TimelineContent>
                                </TimelineItem>
                                )
                            })}
                        </Timeline>
                    </ViewBox>
                </div>
                <div style={{width: '50%'}}>
                    <ViewBox title={I18N('release', language)} rightComponent={(canEditEverything ? (<img onClick={() => setEditMadeBy(true)} style={{cursor:"pointer"}} src={EditPen} />) : (''))}>

                        <table style={{borderSpacing: 15}}>
                            <tr>
                                <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12}}>{I18N('madeBy', language)}</td>
                                <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}>{ authStatus.data?.given_name + ' ' + authStatus.data?.family_name}</td>
                            </tr>
                        </table>


                    </ViewBox>

                    {canEditEverything && (
                        <React.Fragment>
                            {formData.status !== 1 && formData.status !== 2 && formData.status !== 3 && (
                                <FullButton onClick={() => {
                                    changeStatus(2);
                                    updateOrCreateDocument(undefined,2);
                                }} buttonClass="blue" isLeft text={I18N('complete', language)} isLoading={false} />
                            )}

                            {formData.status === 2 && (
                                <FullButton onClick={() => {
                                    changeStatus(4);
                                    updateOrCreateDocument(undefined,4);
                                }} buttonClass="blue" isLeft text={I18N('returnToInWork', language)} isLoading={false} />
                            )}

                            {formData.status !== 1 && formData.status !== 3 && formData.status === 2 && (
                                <FullButton onClick={() => {
                                    changeStatus(1);
                                    updateOrCreateDocument(undefined,1);
                                }} buttonClass="green" isLeft text={I18N('publish', language)} isLoading={false}/>
                            )}

                            {formData.status !== 1 && formData.status !== 3 && (
                                <FullButton onClick={() => {
                                    changeStatus(3);
                                    updateOrCreateDocument(undefined,3);
                                }} buttonClass="red" isLeft text={I18N('archive', language)} isLoading={false} />
                            )}
                        </React.Fragment>
                    )}


                </div>
            </div>
        )



    }, [language, props.inWork, props.inWork, props.history, changeStatus, selectedVersion, responsibleUser, canEditEverything, authStatus.data]);




    const onBack = useCallback(() => {

        if(!somethingChanged){
            props.setEditMode(false);
            return;
        }

        sweetAlert.fire({
            title: I18N('saveChangesAndBack', language),
            showConfirmButton: true,
            confirmButtonText: I18N('yes', language),
            cancelButtonText: I18N('no', language),
            confirmButtonColor: "#8CEEB6",
            cancelButtonColor: "#F2F2F2",
            reverseButtons: true,
            customClass: {
                cancelButton: "cancelCustom",
                confirmButton: "confirmCustom"
            },
            showCancelButton: true
        }).then(async (response) => {
            if(response.isConfirmed){
                await updateOrCreateDocument();
                props.setEditMode(false)
            }else{
                props.setEditMode(false);
            }
        })

    }, [language, props, updateOrCreateDocument]);

    console.log(responsibleUser, implementationUsers)
    return (

        <>

            <ReleaseMadeByBar isOpen={editMadeBy} setIsOpen={setEditMadeBy} updateOrCreateDocument={updateOrCreateDocument} updateForm={setFormData} canEditEverything={canEditEverything} formState={formData} authStatus={authStatus} users={props.users} />
            <DataContentBar formStatus={formData.status} updateOrCreateDocument={updateOrCreateDocument} isOpen={editContent} setIsOpen={setEditContent} content={editorState} setContent={setEditorState} />
            <GeneralContentBar isOpen={editGeneralContent} updateForm={(data) => {
                setFormData({...formData, ...data})
            }} setIsOpen={setEditGeneralContent} canEditEverything={canEditEverything} updateOrCreateDocument={updateOrCreateDocument} formState={formData} />
            <div style={{height: 300, marginTop: 60, padding: 20, position: "relative", background: 'rgba(224, 224, 224, 0.5)', borderRadius: '10px 10px 0 0', border: '1px solid #E0E0E0'}}>

                <div style={{ width: 200, color: "#fff", borderRadius: '10px 10px 0 0', height: 30, lineHeight: '30px', fontSize: 14, textAlign: "center", position: "absolute", right: 0, left: 0, marginLeft: "auto", marginRight: "auto", top: -30, background: StatusDeciderColor(props.inWork?.status)}}>{StatusDecider(props.inWork?.status, language)}</div>

                <img src={DocBookSVG} style={{position: "absolute", opacity: 0.5, right: 0}} />

                <p style={{fontSize: 48, margin:0, paddingTop: 20, color: "#000"}}>{document?.title}</p>

                <div style={{position: "absolute", bottom: -40, width: '98%'}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", width: '100%'}}>
                        <div style={{justifyContent: "flex-start", display: "flex"}}>
                            <div>
                                <p style={{fontSize: 10, color: "#000"}}>{I18N('responsibleUser', language)}</p>
                                {responsibleUser && (
                                    <div onClick={canEditEverything ? teamEditSwal : undefined} style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 0}}>
                                        <img src={responsibleUser?.innerData?.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' : BACKEND_URL + '/users' +responsibleUser?.innerData?.photo} style={{height: 100, width: 100, borderRadius: '50%', cursor: "pointer" }}  alt={"userImage"}/>
                                        <p style={{fontSize: 12, fontWeight: 400, color: "#fff", marginLeft: 10}}>{responsibleUser?.firstName} {responsibleUser?.lastName}</p>
                                    </div>
                                )}
                            </div>

                            <div style={{marginLeft: 60, marginTop: -35}}>
                                <div style={{marginTop: 44}} />
                                <p style={{fontSize: 10, color: "#000"}}>{I18N('implementationUser', language)}</p>


                                {implementationUsers && (
                                    <div onClick={canEditEverything ? teamEditSwal : undefined} style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                                        {implementationUsers.map((user) => (
                                            <img src={user.innerData?.photo === '' ? BACKEND_URL + '/images/no-user-image.gif' : BACKEND_URL + '/users' +user.innerData?.photo} style={{height: 40, width: 40, borderRadius: 50, cursor: "pointer" }}  alt={"userImage"} />
                                        ))}
                                    </div>
                                )}


                            </div>

                        </div>

                    </div>


                </div>

            </div>

            <div style={{background: "#FFFFFF", height: 55, border: '1px solid rgba(224, 224, 224, 0.5)', borderRadius: '0 0 10px 10px'}}>
                <div style={{height: 55, paddingRight: 40, width: '100%', display: 'flex', justifyContent: "flex-end", alignItems: 'center', position: 'relative', top: 0,}}>
                    <Link to="/documents" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('generalInformation', language)} isActive={activeTab === 'general'} onClick={() => setActiveTab('general')} /></Link>
                    <Link to="/documents" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('content', language)} isActive={activeTab === 'content'} onClick={() => setActiveTab('content')} /></Link>
                    <Link to="/documents" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('versionHistory', language)} isActive={activeTab === 'version'} onClick={() => setActiveTab('version')} /></Link>
                    <Link to="/documents" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}> <TabIndicator name={I18N('approvalStatus', language)} isActive={activeTab === 'status'} onClick={() => setActiveTab('status')} /></Link>

                </div>
            </div>

            {/*<div className="flex-row-button">*/}
            {/*    <FormButton text={''} smallButton={true} backButton={true} onClick={onBack} isLoading={false} />*/}
            {/*    <FormButton text={I18N('saveChanges', language)} withLoadingInFullScreen={true} onClick={() => updateOrCreateDocument()} isLoading={loading} />*/}
            {/*</div>*/}

            {activeTab === "general" && (
                generalInformation()
            )}

            {activeTab === "content" && (
                contentData()
            )}

            {activeTab === "version" && (
                versionHistory()
            )}

            {activeTab === "status" && (
                statusPage()
            )}
        </>



    )



}


function TeamEditViewModal(props: {responsibleUser, setResponsibleUser, implementationUsers, setImplementationUsers, users, setSomethingChanged}) {
    const theme = useTheme();
    const language = useLanguageState(state => state.language);
    const [responsibleInModal, setResponsibleInModal] = useState(props.responsibleUser);
    const [implementationUserInModal, setImplementationUserInModal] = useState(props.implementationUsers);
    return (
        <div>
            <p style={{fontSize: 18, color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{I18N('responsibleUser', language)}</p>
            <div style={{paddingTop: 20, paddingBottom: 20}}>
                <Select theme={(template) => {
                    return {
                        ...template,
                        colors: {
                            ...template.colors,
                            ...colorForSelect(theme)
                        }
                    }
                }} isClearable={true} isMulti={false} onChange={(change) => {
                    setResponsibleInModal(change);
                }}  value={responsibleInModal} isLoading={false} placeholder={I18N('releasedBy', language)} options={props.users.map((user) => {
                    return {label: user.firstName + ' ' + user.lastName, value: user.id}
                })} />
            </div>

            <p style={{fontSize: 18,  color: theme.palette.mode === 'dark' ? '#fff' : '#000'}}>{I18N('implementationUser', language)}</p>

            <div style={{paddingTop: 20, paddingBottom: 20, zIndex: 999999, overflow: "none"}}>
                <Select theme={(template) => {
                    return {
                        ...template,
                        colors: {
                            ...template.colors,
                            ...colorForSelect(theme)
                        }
                    }
                }} styles={{ valueContainer: (styles) => {
                        return {
                            ...styles,
                            height: 50,
                            scrollbarWidth: "none",

                            msOverflowStyle: "none",
                            overflowX: "hidden",
                            overflowY: "scroll"
                        };
                    },}} isClearable={true} isMulti={true} onChange={(change) => {
                    setImplementationUserInModal(change);
                }}  value={implementationUserInModal} isLoading={false} placeholder={I18N('releasedBy', language)} options={props.users.map((user) => {
                    return {label: user.firstName + ' ' + user.lastName, value: user.id}
                })} />
            </div>

            <div style={{marginTop: 20}}>

                <div style={{display: "flex", flexDirection: "row", width: '100%'}}>
                    <div style={{width: '30%'}}>
                        <FullButton text={I18N('cancel', language)} onClick={() => {
                            sweetAlert.close();
                        }} isLoading={false} buttonClass={"red minWidthAuto noBorderRight"} />
                    </div>
                    <div style={{width: '70%'}}>
                        <FullButton text={I18N('saveChanges', language)} onClick={() => {
                            props.setResponsibleUser(responsibleInModal);
                            props.setImplementationUsers(implementationUserInModal);
                            props.setSomethingChanged(true);
                            sweetAlert.close()
                        }} isLoading={false} buttonClass={"lightGreen noBorderLeft"} />
                    </div>

                </div>

            </div>
        </div>
    )

}
