import React, { useEffect, useState } from 'react';
import { standartColors } from '../assets/colors/Colors';
import { Searchbar } from '../Searchbar/Searchbar';
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import { useLoginState } from "../States/UserState";
import ContentConfigSVG from '../assets/contentconfig.svg';
import TabBoxes from "../RouteComponents/Konfiguration/TabBoxes";
import QuizAnalyticsSVG from '../assets/quizAnalytics.svg';
import {getToken} from "../Repositories/Cookies";
import DepartmentConfigSVG from "../assets/departmentsconfig.svg";
import UserConfigSVG from "../assets/userconfig.svg";
import VideoAnalytics from "./VideoAnalytics";
import DepartmentAnalytics from "./DepartmentAnalytics";
import UserAnalytics from "./UserAnalytics";
import QuizAnalytics from "./QuizAnalytics";
import {useTheme} from "@mui/material";
import CompanyAnalytics from "./CompanyAnalytics";
import BreadCrumb from "../ViewComponents/BreadCrumb";
import phishingHeader from "../assets/phishingHeader.svg";
import {Link, Route, useHistory, useLocation} from "react-router-dom";
import TabIndicator from "../ViewComponents/Tabs/TabIndicator";
import PhishingAnalytics  from '../Phishing/Analytics/AnalyticsHome';

type Props = {
    style: any
    isMobile: () => boolean
}


export function AnalyticsHome(props: Props) {

    const clientName = getToken();
    const loginState = useLoginState();
    const [activeTab, setActiveTab] = useState(null);
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {

        const path = window.location.search;

        if(path.includes('?to=')){

            const getKeys = path.replace("?to=", '');
            const splitKeys = getKeys.split("-");
            if(splitKeys.length === 2){
                setActiveTab({index: splitKeys[0], outIndex: splitKeys[1]});
            }

        }else{
            setActiveTab(null);
        }

    }, [props]);

    useEffect(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, [activeTab]);


    const getConfigTabs = () => {
        let configTabs = [];
        let quizTabs = [];
        if (loginState.token === null) {
            return [];
        }
        if (loginState.token.resource_access[clientName].roles.includes('ViewAnalyticsVideos')) {
            configTabs.push({ name: I18N('analyticsVideo', language), icon: ContentConfigSVG, component: <VideoAnalytics backHome={() => setActiveTab(null)} /> });
        }
        if (loginState.token.resource_access[clientName].roles.includes('ViewAnalyticsDepartments')) {
            configTabs.push({ name: I18N('analyticsDepartment', language), icon: DepartmentConfigSVG, component: <DepartmentAnalytics backHome={() => setActiveTab(null)} /> });
        }
        if (loginState.token.resource_access[clientName].roles.includes('ViewAnalyticsUsers')) {
            configTabs.push({ name: I18N('analyticsUser', language), icon: UserConfigSVG, component: <UserAnalytics backHome={() => setActiveTab(null)} /> });
        }
        if (loginState.token.resource_access[clientName].roles.includes('ViewAnalyticsVideos')) {
            configTabs.push({ name: I18N('analyticsCompany', language), icon: UserConfigSVG, component: <CompanyAnalytics backHome={() => setActiveTab(null)} /> });
        }


        if (loginState.token.resource_access[clientName].roles.includes('ViewAnalyticsUsers')) {
            quizTabs.push({ name: I18N('quizTab', language), icon: QuizAnalyticsSVG, component: <QuizAnalytics backHome={() => setActiveTab(null)} /> });
        }
        return [configTabs, quizTabs];
    }

    useEffect(() => {
        if(location.pathname === '/contentAnalytics'){
            history.push('/contentAnalytics/contents')
        }
    }, [props]);


            return (

                <div style={props.style}>
                    <Searchbar />
                    <div style={styles.container}>

                        <BreadCrumb />

                        <div style={{background: "rgba(255, 225, 106, 0.5)", backdropFilter: 'blur(5px)', position: "relative", marginTop: 10, borderRadius: '10px 10px 0 0', height: 260}}>
                            <img style={{width: 400, height: 306, position: "absolute", bottom: 10, right: 0}} src={phishingHeader} />
                            <div style={{position: "absolute", bottom: -40, display: "flex", left: 50}}>
                            </div>

                        </div>


                        <div style={{background: "#FFFFFF", height: 55, border: '1px solid rgba(224, 224, 224, 0.5)', borderRadius: '0 0 10px 10px'}}>
                            <div style={{height: 55, paddingRight: 40, width: '100%', display: 'flex', justifyContent: "flex-end", alignItems: 'center', position: 'relative', top: 0,}}>
                                <Link to="/contentAnalytics/contents" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('contents', language)} isActive={location.pathname === "/contentAnalytics/contents"} onClick={undefined} /></Link>
                                <Link to="/contentAnalytics/departments" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('departments', language)} isActive={location.pathname === "/contentAnalytics/departments"} onClick={undefined} /></Link>
                                <Link to="/contentAnalytics/users" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('users', language)} isActive={location.pathname === "/contentAnalytics/users"} onClick={undefined}/></Link>
                                <Link to="/contentAnalytics/companies" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}> <TabIndicator name={I18N('companies', language)} isActive={location.pathname === "/contentAnalytics/companies"} onClick={undefined} /></Link>
                                <Link to="/contentAnalytics/quiz" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}> <TabIndicator name={I18N('quizTab', language)} isActive={location.pathname === "/contentAnalytics/quiz"} onClick={undefined} /></Link>
                                <Link to="/contentAnalytics/analyticsphishing" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}> <TabIndicator name={I18N('phishing', language)} isActive={location.pathname === "/contentAnalytics/analyticsphishing"} onClick={undefined} /></Link>
                            </div>
                        </div>


                        <Route path="/contentAnalytics/contents" render={() => <VideoAnalytics backHome={() => setActiveTab(null)} />} />
                        <Route path="/contentAnalytics/departments" render={() => <DepartmentAnalytics backHome={() => setActiveTab(null)} />} />
                        <Route path="/contentAnalytics/users" render={() => <UserAnalytics backHome={() => setActiveTab(null)} />} />
                        <Route path="/contentAnalytics/companies" render={() => <CompanyAnalytics backHome={() => setActiveTab(null)} /> } />
                        <Route path="/contentAnalytics/quiz" render={() => <QuizAnalytics backHome={() => setActiveTab(null)} />} />
                        <Route path="/contentAnalytics/analyticsphishing" render={() => <PhishingAnalytics clearShowMode={() => null} showMode={null} backHome={() => setActiveTab(null)} />} />
                    </div>
                </div>

            //     <div style={props.style}>
            //     <Searchbar />
            //     <div style={styles.container}>
            //         <div style={{...styles.header.container, color: theme.palette.mode === "dark" && "#fff"}}>
            //     {activeTab === null ? I18N('contentAnalytics', language) : getConfigTabs()[activeTab.outIndex][activeTab.index].name}
            //
            // <ul className="breadCrumb">
            // <li onClick={() => setActiveTab(null)}>{I18N('contentAnalytics', language)}</li>
            // {activeTab !== null && (
            //     <React.Fragment>
            //         <li className="divider" />
            //         <li>{getConfigTabs()[activeTab.outIndex][activeTab.index].name}</li>
            //         </React.Fragment>
            //
            // )}
            // </ul>
            //
            //
            // </div>
            // {activeTab === null ? (
            //     <TabBoxes headerTitle={I18N('contentAnalytics', language)} configurationInfo={[]} onClick={(index, outIndex) => setActiveTab({index: index, outIndex: outIndex})} tabs={getConfigTabs()} />
            // ) : (
            //     <React.Fragment>
            //         {getConfigTabs()[activeTab.outIndex][activeTab.index].component}
            //     </React.Fragment>
            // )}
            //
            // </div>
            // </div >
        )

        }

        const styles = {
            container: { width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
            header: {
                container: { fontWeight: 600, height: 18, fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', color: standartColors.black } as React.CSSProperties,
            },
            shortCuts: {
                container: { height: '20%', width: 'calc(100% - 20px)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around', overflow: 'scroll', color: standartColors.black } as React.CSSProperties,
                shortcut: { backgroundColor: standartColors.primaryYellow, height: 120, borderRadius: 10, fontSize: 18, fontWeight: 'bold', display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginLeft: 20 } as React.CSSProperties,
                title: { marginLeft: 20, marginRight: 20 },
                icon: { height: 40, width: 40, marginLeft: 20, marginRight: 20 }
            },
            boxContainer: { height: '100%', width: '20%', borderRadius: 10, boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)', border: '1px solid #E0E0E0' } as React.CSSProperties,
            boxContent: { marginTop: 10, marginLeft: 20, marginRight: 20, height: '95%' } as React.CSSProperties,
            title: { fontSize: 16, fontWeight: 'bold' } as React.CSSProperties,
            totalBoxes: { height: '45%', width: '100%', backgroundColor: standartColors.primaryYellow, borderRadius: 10 },
            totalBoxTitle: { height: 30, width: '95%', marginLeft: '2.5%', display: 'flex', alignItems: 'center', fontWeight: 'bold' } as React.CSSProperties,
            totalBox: { height: '90%', width: '100', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, fontWeight: 'bold' } as React.CSSProperties,
            routerContent: { height: '100vh', width: 'calc(100vw - 350px)', border: '#000 2 solid' }
        }
