import {Box, Modal, TextField} from "@mui/material";
import {I18N} from "../i18n/i18n";
import UploadFile from "../ViewComponents/UploadFile";
import {DefaultEditor} from "react-simple-wysiwyg";
import FormButton from "../ViewComponents/FormButton";
import {BACKEND_URL} from "../assets/AppConstants";
import React, {useState} from "react";
import {useLanguageState} from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";

const videoRepository = new VideoRepository();

export default function HeaderImageModal(props: {isOpen: boolean; onClose: () => void; onEditContent: (data: any) => void, previousData: any}){

   const [data, setData] = useState<{image: string; title: string, colorCode: string; fontSize: string}>({image: props.previousData.headerImage ?? '', title: props.previousData.headerText ?? '', colorCode: props.previousData.colorCode ?? '#000', fontSize: props.previousData.fontSize ?? 22});
    const language = useLanguageState(state => state.language);

    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{...style, width: 800}}>

                <TextField fullWidth={true}
                           label={I18N('title', language)}
                           className="marginTop20"
                           value={data.title}
                           onChange={(text) => setData({...data, title: text.target.value})}
                />

                <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                    <TextField fullWidth={true}
                               label={I18N('color', language)}
                               className="marginTop20"
                               value={data.colorCode}
                               onChange={(text) => setData({...data, colorCode: text.target.value})}
                    />
                    <TextField fullWidth={true}
                               label={I18N('fontSize', language)}
                               className="marginTop20 marginLeft10"
                               value={data.fontSize}
                               onChange={(text) => setData({...data, fontSize: text.target.value})}
                    />
                </div>


                <div style={{marginBottom: 15}}>
                    <UploadFile uploadOriginal={true} inputAttrs={{accept: 'image/*'}} url={data.image} uploadRepository={videoRepository} onUpload={(image) => setData({...data, image: image})} uploadKey="cover" />
                </div>

                <FormButton style={{marginTop: 20,}} text={"Save"} icon={"save"} onClick={() => {
                    props.onEditContent({...data, image: (data.image !== '' && data.image !== null) ? `${data.image?.includes(BACKEND_URL) ? data.image : BACKEND_URL + data.image}` : ''})
                    props.onClose();
                }} isLoading={false} />
            </Box>
        </Modal>
    )

}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '1px solid gray',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};