import React, {useCallback, useState} from 'react';
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import { CompanyRepository } from "../Repositories/CompanyRepository";
import Licenses from "./Licenses";
import {LicenseOperation} from "./LicenseOperation";
import {useSnackbar} from "notistack";
const companyRepository = new CompanyRepository();

type Props = {
    style: any;
    isMobile: () => boolean
}



export function LicensesTab(props: Props) {
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [editLicense, setEditLicense] = useState<null | any>(null);
    const language = useLanguageState(state => state.language);
    const [formLoading, setFormLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [name, setName] = useState("")

    const scrollTop = useCallback(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, []);


    const backToClientsTable = () => {
        setName('');
        setCreateOpen(false);
        setEditLicense(null);
        scrollTop();
    }

    const saveLicense =  useCallback(async () => {

        if(name === ''){

            enqueueSnackbar(I18N('pleaseFill', language, [{ key: 'name', value: I18N('name', language) }]), {variant: "error"});

            return;

        }
        setFormLoading(true);

        if (editLicense === null) {
            const license = await companyRepository.createLicense(name).then(async () => {
                enqueueSnackbar(I18N('successfullyCreated', language, [{ key: 'name', value: I18N('name', language) }]), {variant: "success"});
                backToClientsTable();
            }).catch(async err => {
                setFormLoading(false);

                enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"});
            })
        }else {
            const license = await companyRepository.updateLicense(name, editLicense.id).then(async () => {
                enqueueSnackbar(I18N('successfullyUpdated', language, [{ key: 'name', value: I18N('name', language) }]), {variant: "success"});
                backToClientsTable();
            }).catch(async err => {
                setFormLoading(false);
                enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"});
            })
        }

        setFormLoading(false);

    },[name, editLicense, language, backToClientsTable]);


    return (
        <div style={props.style}>
            <div className="flex-row-button">
                {createOpen && (<FormButton text={editLicense !== null ? I18N('updateLicense', language) : I18N('createLicense', language)} onClick={saveLicense} isLoading={formLoading} />)}
                <FormButton text={(createOpen ? I18N("back", language) : I18N('new', language))} onClick={() => {
                    if (createOpen) {
                        backToClientsTable();
                    }
                    setCreateOpen(!createOpen);
                    scrollTop();
                }} isLoading={formLoading} withLoadingInFullScreen={true} />
            </div>

            {!createOpen ?
                <Licenses setLoadingMode={setFormLoading} editMode={(item) => {
                    setEditLicense(item);
                    setCreateOpen(true);
                    scrollTop();
                }} />
                : (
                    <LicenseOperation onNameChange={(name) => {setName(name)}} editMode={editLicense} />
                )}
        </div>
    )

}
