import React, { useCallback, useEffect, useState } from "react";
import { Table } from "../UiLibrary/table/Table";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {TableHeader} from "../Types/TableTypes";
import {useSnackbar} from "notistack";

const companyRepository = new CompanyRepository();

export default function Licenses(props: { editMode: (item) => void,  setLoadingMode: (set: boolean) => void; }) {

    const [licences, setLicences] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {enqueueSnackbar} = useSnackbar();
    const getLicenses = useCallback(async () => {
        setLoading(true);
        const licenseList = await companyRepository.getLicenses({page: page, limit: limit});
        setLicences(licenseList);
        setLoading(false);
    }, [page, limit]);

    const deleteLicense = useCallback(async (license: any) => {

        props.setLoadingMode(true);
        const del = await companyRepository.deleteLicense(license.id);
        props.setLoadingMode(false);
        if (del instanceof Error) {
            enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"});
            return;
        } else {
            if(licences !== undefined){
                await getLicenses().then(undefined)
            }
            enqueueSnackbar(I18N('deleteSuccess', language, [{ key: 'name', value: I18N('license', language) }]), {variant: "success"});
        }

    }, [licences, language, props, getLicenses]);


    const tableColumns = (): TableHeader[] => {
        return [
            { name: 'ID', dataName: 'id', type: 'TEXT', callback: (item) => props.editMode(item) },
            { name: I18N('name', language), dataName: 'name', type: 'TEXT' },
            { name: I18N('actions', language), dataName: 'finished', type: 'DELETE', callback: deleteLicense }
        ];
    }

    useEffect(() => {
        getLicenses().then(undefined);
    }, [getLicenses]);

    return (
        <Table
            page={page}
            limit={limit}
            loading={loading}
            setLimit={setLimit}
            setPage={setPage}
            columns={tableColumns()}
            rows={licences} />
    )

}
