import {Searchbar} from "../Searchbar/Searchbar";
import {I18N} from "../i18n/i18n";
import React, {useEffect} from "react";
import {standartColors} from "../assets/colors/Colors";
import {useLanguageState} from "../States/LanguageState";

import CampaignHome from "./Tabs/Campaigns/CampaignHome";
import phishingHeader from "../assets/phissingIllu.svg";
import TabIndicator from "../ViewComponents/Tabs/TabIndicator";
import {Link, Route, useHistory, useLocation} from "react-router-dom";
import BreadCrumb from "../ViewComponents/BreadCrumb";
import OverViewTab from "./Tabs/OverView/OverViewTab";
import EmailTemplates from "./Tabs/EmailTemplates/EmailTemplates";
import LandingPages from "./Tabs/LandingPages/LandingPages";
import {useBreadCrumb} from "../States/BreadCrumbState";

export default function PhishingDashboard(props: {style: any}){

    const language = useLanguageState(state => state.language);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(location.pathname === '/phishing'){
            history.push('/phishing/overview');
        }
    }, [props]);

    return (
        <div style={props.style}>
            <Searchbar />
            <div style={styles.container}>

                <BreadCrumb />

                <div style={{background: "rgba(255, 225, 106, 0.5)", backdropFilter: 'blur(5px)', position: "relative", marginTop: 60, borderRadius: '10px 10px 0 0', height: 260}}>
                    <img style={{width: 400, height: 306, position: "absolute", bottom: 10, right: 0}} src={phishingHeader} />
                    <div style={{position: "absolute", bottom: -40, display: "flex", left: 50}}>
                    </div>

                </div>

                <div style={{background: "#FFFFFF", height: 55, border: '1px solid rgba(224, 224, 224, 0.5)', borderRadius: '0 0 10px 10px'}}>
                    <div style={{height: 55, paddingRight: 40, width: '100%', display: 'flex', justifyContent: "flex-end", alignItems: 'center', position: 'relative', top: 0,}}>
                        <Link to="/phishing/overview" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('overview', language)} isActive={location.pathname === "/phishing/overview"} onClick={undefined} /></Link>
                        <Link to="/phishing/campaigns" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('campaigns', language)} isActive={location.pathname === "/phishing/campaigns"} onClick={undefined} /></Link>
                        <Link to="/phishing/landingpages" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}><TabIndicator name={I18N('landingPages', language)} isActive={location.pathname === "/phishing/landingpages"} onClick={undefined}/></Link>
                        <Link to="/phishing/templates" style={{color: "#000", fontWeight: 'bold', marginLeft: 10, marginRight: 10, textDecoration: "none"}}> <TabIndicator name={I18N('templates', language)} isActive={location.pathname === "/phishing/templates"} onClick={undefined} /></Link>

                    </div>
                </div>


                <Route path="/phishing/overview" render={() => <div><OverViewTab /></div>} />
                <Route path="/phishing/campaigns" render={() => <div><CampaignHome backHome={() => {}} /></div>} />
                <Route path="/phishing/landingpages" render={() => <div><LandingPages /></div>} />
                <Route path="/phishing/templates" render={() => <div><EmailTemplates /></div>} />
            </div>


        </div >
    )

}

const styles = {
    container: { width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
    header: {
        container: { fontWeight: 600, height: 18, fontSize: 18, width: '100%', display: 'flex', flexDirection: 'column', color: standartColors.black } as React.CSSProperties,
    },
    shortCuts: {
        container: { height: '20%', width: 'calc(100% - 20px)', display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around', overflow: 'scroll', color: standartColors.black } as React.CSSProperties,
        shortcut: { backgroundColor: standartColors.primaryYellow, height: 120, borderRadius: 10, fontSize: 18, fontWeight: 'bold', display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', marginLeft: 20 } as React.CSSProperties,
        title: { marginLeft: 20, marginRight: 20 },
        icon: { height: 40, width: 40, marginLeft: 20, marginRight: 20 }
    },
    boxContainer: { height: '100%', width: '20%', borderRadius: 10, boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)', border: '1px solid #E0E0E0' } as React.CSSProperties,
    boxContent: { marginTop: 10, marginLeft: 20, marginRight: 20, height: '95%' } as React.CSSProperties,
    title: { fontSize: 16, fontWeight: 'bold' } as React.CSSProperties,
    totalBoxes: { height: '45%', width: '100%', backgroundColor: standartColors.primaryYellow, borderRadius: 10 },
    totalBoxTitle: { height: 30, width: '95%', marginLeft: '2.5%', display: 'flex', alignItems: 'center', fontWeight: 'bold' } as React.CSSProperties,
    totalBox: { height: '90%', width: '100', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 25, fontWeight: 'bold' } as React.CSSProperties,
    routerContent: { height: '100vh', width: 'calc(100vw - 350px)', border: '#000 2 solid' }
}
