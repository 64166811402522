import { Drawer, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {colorForSelect} from "../../ComplinessTheme";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../States/LanguageState";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import NotCreatable from "react-select";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";


const sweetAlert = withReactContent(Swal)


const GeneralContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, updateOrCreateDocument: any; updateForm: any; canEditEverything: boolean, formState: {title: string, deadline: string; classification: any}}) => {

    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({title: props.formState.title, deadline: props.formState.deadline, classification: props.formState.classification});
    const [loadingCreation, setLoadingCreation] = useState(false);

    const [canEditEverything] = useState(props.canEditEverything);

    const classificationList = [
        {label: I18N('confidential', language), value: 1},
        {label: I18N('public', language), value: 2},
        {label: I18N('internal', language), value: 3},
        {label: I18N('strictlyConfidential', language), value: 4},
    ]

    const saveChanges = useCallback(() => {
        props.updateForm(formState);
        props.updateOrCreateDocument();
    }, [formState, props]);


    useEffect(() => {

        setFormState({...props.formState});

    }, [props.formState])


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={() => {
                            saveChanges();
                        }} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>

                            <TextField
                                fullWidth
                                label={I18N('title', language)}
                                disabled={!canEditEverything}
                                style={{ marginTop: 20 }}
                                value={formState.title}
                                onChange={(text) => {
                                    setFormState({...formState, title: text.target.value});
                                }}
                            />

                            <TextField
                                disabled={!canEditEverything}
                                label={I18N('validUntil', language)}
                                style={{ marginTop: 20 }}
                                value={formState.deadline}
                                onChange={(text) => {
                                    setFormState({...formState, deadline: text.target.value});
                                }}
                            />

                            <div className="customSelect">
                                <NotCreatable theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} styles={{control: base => ({
                                        ...base,
                                        width: 495,
                                        marginTop: 20,
                                        marginLeft: 0,
                                        height: 55,
                                        minHeight: 55
                                    })}}
                                              isDisabled={!canEditEverything}
                                              id={"selectClassi"}
                                              isMulti={false}
                                              isSearchable={false}
                                              options={classificationList}
                                              value={formState.classification}
                                              isClearable={true}
                                              placeholder={''}
                                              onChange={(change) => {
                                                  if(canEditEverything){
                                                      setFormState({...formState, classification: change});
                                                  }
                                              }}
                                />
                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralContentBar;
