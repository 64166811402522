import {Alert, Drawer, FormControl, InputLabel, MenuItem, Select, TextareaAutosize, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useLanguageState} from "../States/LanguageState";
import {I18N} from "../i18n/i18n";
import FormButton from "../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer, SidebarDrawerBig} from "../ViewComponents/VideoEditComponents/Drawer";
import ViewBox from "../ViewComponents/ViewBox";
import {DrawerConfigSx} from "../ViewComponents/DrawerConfigSx";
import {NotificationsRepository} from "../Repositories/NotificationsRepository";
import InfoBox from "../Phishing/Tabs/OverView/InfoBox";

const notificationsRepository = new NotificationsRepository();

const EmailEdit = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any, setContent: (data) => void;}) => {
    const language = useLanguageState();
    const [isLoading, setIsLoading] = useState(false);

    const [formState, setFormState] = useState({
        content: '',
    });


    useEffect(() => {

        if(props.content){
            setFormState({...formState, content: props.content.content});
        }
    }, [props.content])



    const saveChanges = useCallback(async () => {

        if(!props.content){
            return;
        }
        await notificationsRepository.postClientMails({key: props.content.key, content:formState.content});
        props.setContent('');
        props.setIsOpen(false);

    }, [formState, props.content]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawerBig>
                    <div className="header">
                        <p>{I18N('changeEmail', language.language)}</p>
                        <FormButton icon="save" withLoadingInFullScreen={true} color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={isLoading} />
                    </div>
                    <div className="box"  style={{marginTop: 5}}>

                        <ViewBox>
                            <p className="viewBoxTitle">{I18N("emailname", language.language)}</p>
                            <TextField
                                fullWidth
                                disabled={true}
                                label={I18N('emailname', language.language)}
                                style={{ marginTop: 20}}
                                value={I18N(props.content?.key, language.language)}
                            />


                        </ViewBox>

                        <ViewBox>

                            <p className="viewBoxTitle">{I18N("emailContent", language.language)}</p>
                            <div style={{marginTop: 20, width: '100%'}}>
                                <TextareaAutosize style={{width: '100%'}} value={formState.content} onChange={(data) => setFormState({...formState, content: data.target.value})} />
                            </div>

                        </ViewBox>

                        <Alert variant={"filled"} style={{marginTop: 20,}} color={"info"}>
                            <p style={{fontSize: 15, fontWeight: "bold"}}>
                                {I18N("availableVariables", language.language)}
                            </p>
                            <ul>
                            {props.content?.availableVariables.map((i) => {
                                return (
                                    <li>
                                        {"{{"+i+"}}"}
                                        <br />
                                    </li>
                                )
                            })}
                            </ul>
                        </Alert>

                    </div>
                </SidebarDrawerBig>
            </Drawer>
        </SidebarContainer>
    );
};

export default EmailEdit;
