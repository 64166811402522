import { I18N } from "../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import {VideoRepository} from "../Repositories/VideoRepository";
import DepartmentSingleAnalytics from "./DepartmentSingleAnalytics";
import FormButton from "../ViewComponents/FormButton";
import TableMui from "../ViewComponents/TableMui";
import {CalculateDepartmentAnalyticsData} from "../Helpers/CalculateDepartmentAnalyticsData";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
const videoRepository = new VideoRepository();


export default function DepartmentAnalytics(props: {backHome: () => void; activeCompany?: any}) {

    const language = useLanguageState(state => state.language);
    const [watchList, setWatchList] = useState(null);
    const [totalUser, setTotalUser] = useState(0);
    const [videos, setVideos] = useState([]);
    const [activePage, setActivePage] = useState('depHome');
    const [activeDepartment, setActiveDepartment] = useState(null);
    const [loading, setLoading] = useState(false);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();

    const loadActiveCompanyBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('companies', language),
                onClick: () => history.push('/contentAnalytics/companies')
            },
            {
                title: props.activeCompany?.name,
                onClick: () => history.push('/contentAnalytics/companies')
            }
        ]);

        breadCrumb.setTitle(props.activeCompany?.name);
        breadCrumb.setBackButton(props.backHome);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, []);

    const loadBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('departments', language),
                onClick: () => history.push('/contentAnalytics/departments')
            }
        ]);

        breadCrumb.setTitle(I18N('departments', language));
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, [loading]);

    useEffect(() => {

        if(props.activeCompany){
            loadActiveCompanyBreadCrumb();
        }else{
            loadBreadCrumb();
        }


    }, [loadBreadCrumb, loadActiveCompanyBreadCrumb, props.activeCompany]);


    const loadData = useCallback(async () => {
        setLoading(true);
        const watchListData = await videoRepository.getWatchListDepartments(props.activeCompany?.id);
        setWatchList(watchListData.contentData);
        setTotalUser(watchListData.userTotal);
        setVideos(watchListData.videos);
        setLoading(false);
    }, [props.activeCompany]);

    useEffect(() => {
        loadData().then(undefined);
    }, []);

    const calculateRankingList = useCallback(() => {

        return CalculateDepartmentAnalyticsData(watchList, videos);

    }, [watchList, videos]);


    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        } else {
            return 'red'
        }


    }, []);

    const contentColumns = useCallback(() => {

        return [
            {
                field: 'name',
                name: I18N("name", language),
                sortable: true,
                filterable: true,
                disableColumnMenu: false,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.name}</span>;
                }
            },
            {
                field: 'Gesamt gesehen',
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                name: I18N("totalSeen", language),
                valueGetter: (params) => {
                    return params.row.watched
                },
                renderCell: (params: any) => {
                    return (
                        <div className="endFlex">
                            <div className="rankingText">{params.row.watched} / {params.row.totalVideos * params.row.totalUsers} ({isNaN(params.row.watched / (params.row.totalVideos * params.row.totalUsers) * 100) ? '0' : (params.row.watched / (params.row.totalVideos * params.row.totalUsers) * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.watched / params.row.totalUsers * 100))}`} style={{width: (params.row.watched / (params.row.totalVideos * params.row.totalUsers) * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
            {
                field: 'Gesehen letzte 12 Monate',
                name: I18N('totalSeenLast12', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                valueGetter: (params) => {
                    return params.row.oldWatched
                },
                renderCell: (params: any) => {
                    return (
                        <div className={"endFlex"}>
                            <div className="rankingText">{params.row.oldWatched} / {(params.row.watched)} ({isNaN((params.row.oldWatched / (params.row.watched) * 100)) ? '0' : (params.row.oldWatched / (params.row.watched) * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.oldWatched / (params.row.watched) * 100))}`} style={{width: (params.row.oldWatched / (params.row.watched) * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
        ];

    }, []);

    const returnView = useCallback(() => {

        if(activePage === 'depHome'){
            return (
              <React.Fragment>
                  <div style={{height: 670}}>
                      <TableMui sortingMode={"client"} filterMode={"client"} onClick={(data) => {
                          setActiveDepartment(data.row.ID);
                          setActivePage('depIn');
                      }} data={calculateRankingList()} columns={contentColumns()} />
                  </div>
              </React.Fragment>
            )
        }else if(activePage === 'depIn'){
            return <DepartmentSingleAnalytics onBackFull={() => {
                props.backHome();
            }} onBack={() => {
                setActivePage('depHome');
                if(props.activeCompany){
                    loadActiveCompanyBreadCrumb();
                }else{
                    loadBreadCrumb();
                }
                setActiveDepartment(null);
            }} contentID={activeDepartment} activeCompany={props.activeCompany} onClick={() => {}} />
        }

    }, [activePage, calculateRankingList, getColor, language])

    return (
        <div style={{marginTop: 30}}>
            {/*<FormButton text={I18N("back", language)} onClick={() => {*/}
            {/*    if(activePage === 'depIn'){*/}
            {/*        setActivePage('depHome');*/}
            {/*        setActiveDepartment(null);*/}
            {/*    }else if(activePage === 'depHome') {*/}
            {/*        props.backHome();*/}
            {/*    }*/}
            {/*}} isLoading={loading} withLoadingInFullScreen={true} />*/}
            {returnView()}
        </div>
    )
}
