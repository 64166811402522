import React, {useCallback, useEffect, useState} from "react";
import {Table} from "../UiLibrary/table/Table";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {VideoUploadRepository} from "../Repositories/VideoUploadRepository";
import {useSnackbar} from "notistack";
const uploadContentRepository = new VideoUploadRepository();


export default function UploadList(props: {editMode: (item) => void}) {

    const [contents, setContents] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {enqueueSnackbar} = useSnackbar();
    const getContents = useCallback(async () => {

        setLoading(true);
        const contentList = await uploadContentRepository.getContent({page: page, limit: limit});
        setContents(contentList);
        setLoading(false);

    }, [page, limit]);

    const deleteContent = useCallback(async (trophy: any) => {

        const del = await uploadContentRepository.delete(trophy.id);

        if(del instanceof Error){

            enqueueSnackbar(I18N('unexpectedError', language), {variant: "error"})

            return;
        }else{
            await getContents().then(undefined);
            enqueueSnackbar(I18N('deleteSuccess', language, [{key: 'name', value: I18N('trophy', language)}]), {variant: "success"})
        }

    }, [contents, language]);


    const tableColumns = (): { name: string, dataName: string, type: 'BOOLEAN' | 'TEXT' | undefined, callback?: (item) => void }[] => {
        return [
            {name: 'ID', dataName: 'id', type: 'TEXT', callback: (item) => props.editMode(item)},
            {name: I18N('name', language), dataName: 'name', type: 'TEXT'},
            {name: I18N('actions', language), dataName: 'finished', type: 'BOOLEAN', callback: deleteContent}
        ];
    }

    useEffect(() => {
        getContents().then(undefined);
    }, [getContents]);

    return (
        <Table
            page={page}
            limit={limit}
            loading={loading}
            setLimit={setLimit}
            setPage={setPage}
            columns={tableColumns()}
            rows={contents} />
    )

}
