export function ColorsForChart(requestedLength: number){

    const colors = [
        "#5CE3D5",
        "#5CC290",
        "#6073A0",
        "#FFED80",
        "#6CB6FB",
        "#FF9B85",
        "#DEC5FC",
        "#A998F6"
    ];


    if(requestedLength > colors.length){
        return undefined;
    }

    return colors;


}
