import { I18N } from "../../i18n/i18n";
import ViewBox from "../ViewBox";
import React, { useCallback } from "react";
import { useLanguageState } from "../../States/LanguageState";

export default function DepartmentRankingList(props: {
    contentData: {
        dep_id: number,
        points: number,
        name: string
    }[];
}) {

    const language = useLanguageState(state => state.language);

    const calculateRankingList = useCallback(() => {

        const hundered = props.contentData[0] !== undefined ? props.contentData[0].points : 100;
        const calculatedData: { dep_id: number, points: number, name: string, percentage: number }[] = [];

        props.contentData.forEach((content, index) => {
            if (index === 0) {
                calculatedData.push({ ...content, percentage: 100 });
            } else {
                const percentage = content.points / hundered * 100;
                calculatedData.push({ ...content, percentage: percentage });
            }
        });

        return calculatedData;

    }, [props.contentData]);

    return (
        <ViewBox width={60} title={I18N('departmentRanking', language)}>
            <ul className="departmentRankings">
                {calculateRankingList().map((dep) => (
                    <li className="departmentRankingItem">
                        <div className="title" >
                            {dep.name}
                        </div>
                        <div className="rank" style={{ width: dep.percentage + '%' }}>
                            <p>{I18N('points', language)}: {dep.points}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </ViewBox>
    )
}
