import React, { useCallback, useEffect, useState } from "react";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import LearningPlanOperation from "./LearningPlanOperation";
import LearningPlanList from "./LearningPlanList";
import { LearningPlanRepository } from "../Repositories/LearningPlanRepository";
import { useBreadCrumb } from "../States/BreadCrumbState";
import LearningPlanEdit from "./LearningPlanEdit";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

const learningPlanRepository = new LearningPlanRepository();

export default function LearningPlanTab() {
  const language = useLanguageState((state) => state.language);
  const [loadingForm, setLoadingForm] = useState(false);
  const [selectedLearningPlan, setSelectedLearningPlan] = useState(null);
  const [createMode, setCreateMode] = useState(false);
  const breadCrumb = useBreadCrumb();
  const [formHasError, setFormHasError] = useState(false);
  const [formState, setFormState] = useState({
    description: "",
    department: undefined,
    status: { label: I18N("active", language), value: 1 },
    trigger: undefined,
    companies: undefined,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [steps, setSteps] = useState([]);

  const scrollTop = useCallback(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, []);

  const location = useLocation();
  const [mountKey, setMountKey] = useState(undefined);

  useEffect(() => {
    if (location.key !== undefined) {
      if (location.key !== mountKey) {
        onReturnBackToView();
      }
    }
    setMountKey(location.key);
  }, [location, mountKey]);

  const backToLearningPlans = useCallback(() => {
    setCreateMode(false);
    setSelectedLearningPlan(null);
    scrollTop();
  }, []);

  const updateOrCreateLearningPlan = useCallback(async () => {
    setFormHasError(false);

    if (
      formState.description === "" ||
      formState.department === undefined ||
      formState.department === null
    ) {
      enqueueSnackbar(I18N("pleaseFill", language), { variant: "error" });
      setFormHasError(true);
      return;
    }

    let error = false;

    steps.forEach((step) => {
      if (step.execution === undefined) {
        error = true;
      }
      if (step.startEvent === undefined) {
        error = true;
      }
      if (step.deadline === undefined) {
        error = true;
      }
    });

    if (error) {
      enqueueSnackbar(I18N("pleaseFill", language), { variant: "error" });
      setFormHasError(true);
      return;
    }

    const updatedFormState = JSON.parse(JSON.stringify(formState));
    let updatedSteps = JSON.parse(JSON.stringify(steps));

    updatedSteps = updatedSteps.map((item) => {
      return {
        startEvent: item.startEvent.value,
        execution: item.execution.value,
        videos: item.videos,
        deadline: item.deadline.value,
      };
    });

    updatedFormState.department = updatedFormState.department.map(
      (item) => item.value,
    );
    updatedFormState.status = !!updatedFormState.status.value;
    updatedFormState.steps = updatedSteps;
    updatedFormState.trigger = updatedFormState.trigger.value;
    updatedFormState.companies =
      updatedFormState.companies === undefined ||
      updatedFormState.companies === null
        ? []
        : updatedFormState.companies.map((i) => i.value);

    setLoadingForm(true);
    if (selectedLearningPlan !== null) {
      await learningPlanRepository
        .update(updatedFormState, selectedLearningPlan.id)
        .then(async () => {
          setLoadingForm(false);
          enqueueSnackbar(
            I18N("successfullyUpdatedReturning", language, [
              { key: "name", value: I18N("learningPlan", language) },
            ]),
            { variant: "success" },
          );

          setLoadingForm(false);
          onReturnBackToView();
        })
        .catch(async (err) => {
          setLoadingForm(false);
          enqueueSnackbar(I18N("unexpectedError", language), {
            variant: "error",
          });

          setLoadingForm(false);
        });
    } else {
      await learningPlanRepository
        .post(updatedFormState)
        .then(async () => {
          setLoadingForm(false);
          enqueueSnackbar(
            I18N("successfullyCreatedReturning", language, [
              { key: "name", value: I18N("learningPlan", language) },
            ]),
            { variant: "success" },
          );
          setLoadingForm(false);
          onReturnBackToView();
        })
        .catch(async (err) => {
          setLoadingForm(false);
          enqueueSnackbar(I18N("unexpectedError", language), {
            variant: "error",
          });
          setLoadingForm(false);
        });
    }
  }, [formState, steps]);

  const deleteLearningPlan = useCallback(
    async (item) => {
      return await learningPlanRepository.delete(item.id).then(async (r) => {
        enqueueSnackbar(
          I18N("deleteSuccess", language, [
            { key: "name", value: I18N("learningPlan", language) },
          ]),
          { variant: "success" },
        );
        return true;
      });
    },
    [language],
  );

  const onReturnBackToView = useCallback(() => {
    breadCrumb.setBackButton(null);
    breadCrumb.setTitle(I18N("learningPlan", language));
    breadCrumb.setCrumb([
      { title: I18N("configuration", language), onClick: undefined },
      {
        title: I18N("learningPlan", language),
        onClick: onReturnBackToView,
      },
    ]);
    breadCrumb.setChildren([
      {
        key: "createNewLearningplan",
        title: I18N("new", language),
        onClick: () => {
          setCreateMode(!createMode);
          scrollTop();
        },
        color: "dark",
        icon: "plus",
      },
    ]);
    backToLearningPlans();
  }, [language]);

  useEffect(() => {
    onReturnBackToView();
  }, []);

  return (
    <React.Fragment>
      {!selectedLearningPlan && !createMode ? (
        <LearningPlanList
          setEditMode={(item) => {
            setSelectedLearningPlan(item);
            setCreateMode(true);
            scrollTop();
          }}
          deleteListItem={deleteLearningPlan}
        />
      ) : (
        <>
          {selectedLearningPlan ? (
            <LearningPlanEdit
              onStepsChange={setSteps}
              onFinish={updateOrCreateLearningPlan}
              onReturnBackToView={onReturnBackToView}
              onFormChange={setFormState}
              editMode={selectedLearningPlan}
            />
          ) : (
            <LearningPlanOperation
              formHasError={formHasError}
              onStepsChange={setSteps}
              onFinish={updateOrCreateLearningPlan}
              onReturnBackToView={onReturnBackToView}
              onFormChange={setFormState}
              editMode={selectedLearningPlan}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}
