import React, {Component, useCallback} from 'react';
import CheckMarkSVG from '../../assets/check-mark.svg';
import TimesSvg from '../../assets/times.svg';
import {I18N} from "../../i18n/i18n";
import {useLanguageState} from "../../States/LanguageState";
import LeftFilled from '../../assets/leftFilled.svg'
import LeftEmpty from '../../assets/leftEmpty.svg'
import RightFilled from '../../assets/rightFilled.svg'
import RightEmpty from '../../assets/rightEmpty.svg'
import ArrowUp from '../../assets/aup.svg';
import ArrowDown from '../../assets/adown.svg';
import ReactTooltip from "react-tooltip";
import InfoIcon from '../../assets/info.svg';
import TrashIcon from '../../assets/trash.svg';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {TableProps} from "../../Types/TableTypes";
import ViewBox from "../../ViewComponents/ViewBox";
import {useTheme} from "@mui/material";
const sweetAlert = withReactContent(Swal)


const rows = [0,1,2,3,4,5,6,7,8,9];


export function Table(props: TableProps) {

    const language = useLanguageState(state => state.language);
    const theme = useTheme();

    const deleteCallBack = useCallback(async (deleteCall: () => void) => {

        await sweetAlert.fire({
            showCloseButton: true,
            title: I18N('deleteHeader', language),
            html: I18N('deleteItem', language),
            icon: "question",
            showCancelButton: true,
        }).then(async (response) => {
            if(response.isConfirmed){
                await deleteCall();
                return;
            }
            return;
        });


    }, [language]);



        if(props.loading !== undefined && props.loading){
            return (
                <div className="tableContainer">
                    <ViewBox title="">
                        <div className="tableView">
                            <thead className="sticky" >
                            <tr className="head sticky"  >
                                {props.columns.map((column) => (
                                    <th style={{width: '1%', backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh"><div className="ml-10">{column.name}</div></th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {rows.map(() => (
                                <tr className="tableTr sticky">
                                    {props.columns.map((column) => (
                                        <td><div className="ml-10 sticky"><div className={`linear-background ${theme.palette.mode === 'dark' && 'dark'}`}/></div></td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </div>
                    </ViewBox>
                    </div>
            )
        }


        if (props.rows.length < 1) {
            return (
                <div className="tableContainer">
                    <div className="viewBox">
                        <div className="tableView">
                            {I18N('noData', language)}
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="tableContainer">
                <ReactTooltip />
                <div className="viewBox" style={{border: "none"}}>
                    <table className="tableView">
                        <thead className="sticky" style={{zIndex:99}} >
                            <tr className="head sticky"  >

                                {props.columns.map((column) => (
                                    <th style={{backgroundColor: theme.palette.mode === 'dark' && theme.palette.background.paper, color: theme.palette.text.primary}} className="tableTh" onClick={() => {
                                        if(column.order){
                                            let orderWay = 'asc';
                                            if(props.orderType !== null && props.orderType !== undefined){
                                                if(props.orderType.column === column.dataName){
                                                    if(props.orderType.type === 'asc'){
                                                        orderWay = 'desc'
                                                    }else{
                                                        //orderWay = 'asc'
                                                        props.orderCallback(null, null);
                                                        return;
                                                    }
                                                }
                                            }
                                            props.orderCallback(column.dataName, orderWay)
                                        }
                                    }}><div className="ml-10" style={{cursor: column.order ? 'pointer' : 'default'}}>
                                        {column.name}
                                        {props.orderType !== undefined && props.orderType !== null && props.orderType.column === column.dataName && (props.orderType.type === 'asc' ? <img src={ArrowDown} className="imageClass transformImage" /> : <img className="imageClass" src={ArrowDown} />)}
                                        {column.info !== undefined && <img className={`infoClass ${props.orderType !== undefined && props.orderType !== null && props.orderType.column === column.dataName && 'openCol'}`} src={InfoIcon} width={15} height={15} data-tip={column.info} />}
                                    </div>
                                    </th>
                                ))}

                            </tr>
                        </thead>
                        <tbody>
                        {props.rows.data.map((row) => (
                            <tr className="tableTr">
                                {props.columns.map((column, key) => {


                                    let callBack = undefined;
                                    if(column.callback !== undefined){
                                        callBack = column.callback;
                                    }else{
                                        if(key === 1){
                                            if(props.columns[0].callback !== undefined){
                                                callBack = props.columns[0].callback;
                                            }
                                        }
                                    }

                                if(column.type === "BOOLEAN"){
                                    return (<td
                                        style={{cursor: (column.callback !== undefined) ? "pointer" : 'default' }} onClick={() => column.callback !== undefined ? column.callback(row) : undefined}
                                    ><div className="ml-10 sticky">
                                        {(Array.isArray(row[column.dataName]) && row[column.dataName].length > 0) && (row[column.dataName]) ?
                                            <div className="trueMark"><img src={CheckMarkSVG} /></div>
                                            :
                                            <div className="falseMark"><img src={TimesSvg} /></div>
                                        }
                                    </div></td>)
                                } if(column.type === 'DELETE'){
                                        return (<td
                                            style={{cursor: (column.callback !== undefined) ? "pointer" : 'default' }} onClick={() => column.callback !== undefined ? deleteCallBack(() => column.callback(row)) : undefined}
                                        ><div className="ml-10 sticky">
                                            <div className="falseMark"><img src={TrashIcon} /></div>
                                        </div></td>)
                                    }else{
                                    let data = "";

                                        let isSplitable = column.dataName.split('.');
                                        if(isSplitable.length > 1){
                                            data = row;
                                            isSplitable.forEach((key) => {
                                                data = data[key];
                                            })
                                        }else{
                                            data = row[column.dataName]
                                        }

                                        if(data === ''){
                                            data = ''
                                        }
                                        // if(data === ''){
                                        //    data = column.dataName;
                                        // }

                                   return (<td style={{cursor: (callBack !== undefined) ? "pointer" : 'default' }} onClick={callBack === undefined ? undefined : () => callBack(row)}>
                                       <div className="ml-10 sticky">
                                           {column.fixData !== undefined ? column.fixData(data) : data}
                                       </div>
                                   </td>)
                                }

                            })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="footerTable">
                        <p>Reihen pro Seite: <select value={props.limit} className="selectItem" onChange={(event) => props.setLimit(+event.target.value)}><option value={10}>10</option> <option value={20}>20</option> <option value={30}>30</option></select></p>
                        <p>{((props.page - 1) * props.limit) === 0 ? 1 : (props.page - 1) * props.limit} - {(props.limit * props.page) > props.rows.total ? props.rows.total : props.limit * props.page} von {props.rows.total}</p>
                        <div>
                            <img style={{cursor: props.page === 1 ? 'default' : "pointer"}} src={props.page === 1 ? LeftEmpty : LeftFilled} onClick={() => props.setPage(props.page - 1 === 0 ? 1 : props.page - 1)} />
                            <img style={{cursor: props.page + 1 > props.rows.current ? "default" : "pointer"}} src={props.page + 1 > props.rows.current ? RightEmpty : RightFilled} onClick={() => props.setPage(props.page + 1 > props.rows.current ? props.page : props.page + 1)} />
                        </div>
                    </div>
                </div>
            </div>

        )

}

