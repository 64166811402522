import React, {useCallback, useEffect, useState} from "react";
import ViewBox from "../ViewComponents/ViewBox";
import {Checkbox, FormControlLabel, FormGroup, LinearProgress, linearProgressClasses} from "@mui/material";
import {styled} from "@mui/material";
import FormButton from "../ViewComponents/FormButton";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {LearnVideosType} from "../Types/LearnVideosTypes";
import {QuizRepository} from "../Repositories/QuizRepository";
import QuizSuccess from '../assets/quizSuccess.svg';
import QuizFail from '../assets/quizFail.svg';
import {VideoRepository} from "../Repositories/VideoRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const quizRepository = new QuizRepository();
const videoRepository = new VideoRepository();
const sweetAlert = withReactContent(Swal)

export default function RunQuiz(props: {selectedVideo: LearnVideosType, onFinishQuiz: (isFail: boolean, contentId: number) => void;}){

    const [quizData, setQuizData] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const language = useLanguageState(state => state.language)
    const [answerData, setAnswerData] = useState([]);
    const [quizResult, setQuizResult] = useState(null);
    const [loadingQuizFinish, setLoadingQuizFinish] = useState(false);

    useEffect(() => {

        const quiz = props.selectedVideo.quizes;

        console.log(quiz);

        const questions = quiz.quiz_questions.map((question) => {
            return {
                questionId: question.id,
                question: question.question,
                answers: question.question_answers
            }
        })

        setQuizData(questions);

        const quizProgress = quiz.quiz_progress.length > 0 ? JSON.parse(quiz.quiz_progress[0].content) : {};

        const currentQuestionData = quizProgress.currentQuestion !== undefined ? quizProgress.currentQuestion - 1 : 0;

        setCurrentQuestion(currentQuestionData);

        const answerDataProgress = quizProgress.answers !== undefined ? quizProgress.answers : [];

        setAnswerData(answerDataProgress);

    }, [props.selectedVideo]);


    useEffect(() => {

        if(props.selectedVideo.type === 4){
            videoRepository.setVideoToQuiz(props.selectedVideo.id);
        }
    }, [props.selectedVideo]);

    const saveAnswer = useCallback(async () => {

        await quizRepository.saveAnswer(props.selectedVideo.quizes.id, props.selectedVideo.id, answerData, currentQuestion + 1);

    }, [answerData, currentQuestion, props.selectedVideo.id, props.selectedVideo.quizes.id]);

    const finishQuiz = useCallback(async () => {

        setLoadingQuizFinish(true);
        const result =  await quizRepository.finishQuiz(props.selectedVideo.quizes.id, props.selectedVideo.id, answerData);
        setLoadingQuizFinish(false);
        setQuizResult(result);

    }, [answerData, props.selectedVideo.id, props.selectedVideo.quizes.id]);



    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        width: '50%',
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            //@ts-ignore
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "#FFE16A",
        },
    }));



    const goNext = useCallback(async (goNext?: boolean) => {

        if(quizData !== null){

            if(goNext !== true){
                if(quizData[currentQuestion] !== undefined){
                    const findCurrentAnswers = answerData.findIndex((ans) => ans.questionId === quizData[currentQuestion].questionId);
                    if(findCurrentAnswers === -1){
                        showEmptyError()
                        return;
                    }else{
                        if(answerData[findCurrentAnswers].answers.length < 1){
                            showEmptyError();
                            return;
                        }
                    }
                }
            }


            if(quizData[currentQuestion + 1] !== undefined){
                saveAnswer().then(undefined);
                setCurrentQuestion(currentQuestion + 1);
            }else{
              await finishQuiz();
            }
        }

    }, [currentQuestion, quizData, saveAnswer]);


    const showEmptyError = useCallback(() => {

        sweetAlert.fire({
            icon: "question",
            title: I18N('emptyAnswer', language),
            html: I18N('areYouSureEmpty', language),
            showCancelButton:true,
        }).then((swalStatus) => {
            if(swalStatus.isConfirmed){
                goNext(true);
            }
        })

    }, [goNext]);

    const goBack = useCallback(() => {

        if(quizData !== null){
            if(quizData[currentQuestion - 1] !== undefined){
                setCurrentQuestion(currentQuestion - 1);
            }
        }

    }, [currentQuestion, quizData]);


    const onFinishQuiz = useCallback(async () => {
       await props.onFinishQuiz(!quizResult.status, props.selectedVideo.id)
        setQuizResult(null);
        setAnswerData([]);
        setCurrentQuestion(0);
    }, [props, quizResult]);


    return (
      <div style={{marginTop: 40}}>



              <React.Fragment>
                  {quizData !== null  && (
                      <ViewBox>
                          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center", marginTop: 50}}>
                              <BorderLinearProgress variant="determinate" value={quizResult !== null ? 100 : (((currentQuestion) / quizData.length ) * 100)}  />
                              {quizResult !== null ? (
                                 <React.Fragment>
                                     <p style={{fontSize: 14, fontWeight: "bold"}}>{I18N('finishPercentage', language)}: {quizResult.percentage.toFixed(0)}%</p>

                                     <div>

                                         {quizResult.status ? (
                                             <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                 <div style={{display: "flex", marginRight: 75 , justifySelf: "flex-end"}}>
                                                     <img width={500} src={QuizSuccess} />
                                                 </div>
                                                 <div style={{display: "flex", marginRight: 75, justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                                                     <p style={{fontSize: 36, fontWeight: "bold"}}>{I18N('quizSuccess', language)}</p>
                                                     <p style={{fontSize: 28}}>{I18N('quizSuccessText', language)}</p>
                                                 </div>


                                             </div>
                                         ): (
                                             <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                 <div style={{display: "flex", marginLeft: 75, justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                                                     <p style={{fontSize: 36, fontWeight: "bold"}}>{I18N('quizFail', language)}</p>
                                                     <p style={{fontSize: 28}}>{I18N('quizFailText', language)}</p>
                                                 </div>
                                                 <div style={{display: "flex", marginLeft: 75, justifySelf: "flex-end"}}>
                                                     <img width={500} src={QuizFail} />
                                                 </div>

                                             </div>


                                         )}

                                     </div>
                                 </React.Fragment>


                              ): (
                                  <React.Fragment>
                                      <p style={{fontSize: 16, marginTop: 10, marginBottom: 20}}>{quizData[currentQuestion].question}</p>
                                      <ul style={{listStyle: "none", textAlign: "center"}}>
                                          {quizData[currentQuestion].answers.map((answer, key) => {
                                              let checked = false;
                                              const aData = answerData.findIndex((answerDataInner) => answerDataInner.questionId === quizData[currentQuestion].questionId);
                                              if(aData !== -1){
                                                  const findIfAlreadyAnswered = answerData[aData].answers.findIndex((answeredData) => answeredData === answer.id);
                                                  if(findIfAlreadyAnswered !== -1){
                                                      checked = true
                                                  }
                                              }
                                              return (
                                                  <li><FormGroup>
                                                      <FormControlLabel control={<Checkbox sx={{
                                                          color: "#E0E0E0",
                                                          '&.Mui-checked': {
                                                              color: "#6FCF97",
                                                          },
                                                      }} checked={checked} key={`question-${currentQuestion}-answer-${key}`} onChange={(e, b) => {

                                                          const aData = answerData.findIndex((answerDataInner) => answerDataInner.questionId === quizData[currentQuestion].questionId);

                                                          if(b){
                                                              if(aData === -1){
                                                                  setAnswerData([...answerData, {questionId: quizData[currentQuestion].questionId, answers: [answer.id]}])
                                                              }else{
                                                                  const findIfAlreadyAnswered = answerData[aData].answers.findIndex((answeredData) => answeredData === answer.id);
                                                                  if(findIfAlreadyAnswered === -1){
                                                                      answerData[aData].answers = [...answerData[aData].answers, answer.id];
                                                                  }else{
                                                                      answerData[aData].answers[findIfAlreadyAnswered].status = b;
                                                                  }
                                                                  setAnswerData([...answerData]);
                                                              }
                                                          }else{
                                                              const findIfAlreadyAnswered = answerData[aData].answers.findIndex((answeredData) => answeredData === answer.id);
                                                              if(findIfAlreadyAnswered === -1){
                                                                  answerData[aData].answers = [...answerData[aData].answers, answer.id];
                                                              }else{
                                                                  answerData[aData].answers.splice(findIfAlreadyAnswered, 1);
                                                              }
                                                              setAnswerData([...answerData]);
                                                          }



                                                      }} color="success" />} label={answer.answer} />
                                                  </FormGroup></li>
                                              )

                                          })}
                                      </ul>
                                  </React.Fragment>
                              )}

                          </div>

                          <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                              {quizResult === null && (<FormButton disabled={(quizData[(currentQuestion - 1)] === undefined)} isLeft={true} text={I18N('previousQuestion', language)} backButton={false} smallButton={true} onClick={goBack} isLoading={false} />)}
                              <FormButton withLoadingInFullScreen={true} text={(quizData.length === currentQuestion + 1) ? I18N('finishQuiz', language) : I18N("nextQuestion", language)} backButton={false} smallButton={true} onClick={quizResult !== null ? onFinishQuiz : goNext} isLoading={loadingQuizFinish} />
                          </div>


                      </ViewBox>
                  )}

              </React.Fragment>

      </div>
    )


}
