import { I18N } from "../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import moment from "moment/moment";
import FormButton from "../ViewComponents/FormButton";
import TableMui from "../ViewComponents/TableMui";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import DepartmentAnalytics from "./DepartmentAnalytics";
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
const companyRepository = new CompanyRepository();

export default function CompanyAnalytics(props: {backHome: () => void;}) {

    const language = useLanguageState(state => state.language);
    const [activePage, setActivePage] = useState('depHome');
    const [activeCompany, setActiveCompany] = useState(null);
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState([]);
    const [calculationResult, setCalculationResult] = useState([]);

    const breadCrumb = useBreadCrumb();
    const history = useHistory();

    const loadBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('companies', language),
                onClick: () => history.push('/contentAnalytics/companies')
            }
        ]);

        breadCrumb.setTitle(I18N('companies', language));
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, [loading]);

    useEffect(() => {

        loadBreadCrumb();

    }, [loadBreadCrumb]);

    const loadCompanyData = useCallback(async () => {
        setLoading(true);
        const watchlistData = await companyRepository.getSubCompanies(undefined, false);
        setCompanyData(watchlistData);
        setLoading(false);
    }, []);


    useEffect(() => {
        loadCompanyData();
    }, [loadCompanyData]);


    const loadVideoCompaniesData = useCallback(() => {


        const companyDataNew = companyData.map((item) => {
            const companyUserList = item.users_companies;
            const companyUsersOnlyId = item.users_companies.map((u) => u.user_id);

            let last12 = 0;
            let totalSeen = 0;


            const itemData = item.videos_companies.map((video) => {
                const seenData = video.videos.seen_videos;
                const seenInTotal = seenData.filter((videoSeen) => {
                    return companyUsersOnlyId.includes(videoSeen.user_id);
                });
                const last12Months = seenInTotal.filter((seenAll) => {
                    return moment(seenAll.seen_at).endOf("day").isAfter(moment().startOf('day').subtract('12', 'months'));
                });
                last12 = last12Months.length;
                totalSeen = seenInTotal.length;
                return {
                    video,
                    seenInTotal,
                    last12Months
                }
            });



            return {...item, itemData, totalUsers: companyUserList.length, last12, totalSeen};

        });
        console.log(companyDataNew);
        setCalculationResult(companyDataNew);

    }, [companyData]);

    useEffect(() => {
        loadVideoCompaniesData();
    }, [companyData, loadVideoCompaniesData]);


    const getColor = useCallback((percent: number) => {
        console.log(percent);
        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        } if(percent === 0){
            return '';
        }
        else {
            return 'red'
        }


    }, []);

    const contentColumns = useCallback(() => {

        return [
            {
                field: 'name',
                name: I18N("name", language),
                sortable: true,
                filterable: true,
                disableColumnMenu: false,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.name}</span>;
                }
            },
            {
                field: 'Gesamt gesehen',
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                name: I18N("totalSeen", language),
                valueGetter: (params) => {
                    return params.row.totalSeen
                },
                renderCell: (params: any) => {
                    return (
                        <div className="endFlex">
                            <div className="rankingText">{params.row.totalSeen} / {params.row.itemData.length * params.row.totalUsers} ({isNaN(params.row.totalSeen / (params.row.itemData.length * params.row.totalUsers) * 100) ? '0' : (params.row.totalSeen / (params.row.itemData.length * params.row.totalUsers) * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.totalSeen / params.row.totalUsers * 100))}`} style={{width: (params.row.totalSeen / (params.row.itemData.length * params.row.totalUsers) * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
            {
                field: 'Gesehen letzte 12 Monate',
                name: I18N('totalSeenLast12', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                valueGetter: (params) => {
                    return params.row.last12
                },
                renderCell: (params: any) => {
                    return (
                        <div className={"endFlex"}>
                            <div className="rankingText">{params.row.last12} / {(params.row.totalSeen)} ({isNaN((params.row.last12 / (params.row.totalSeen) * 100)) ? '0' : (params.row.last12 / (params.row.totalSeen) * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.last12 / (params.row.totalSeen) * 100))}`} style={{width: (params.row.last12 / (params.row.totalSeen) * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
        ];

    }, []);

    const returnView = useCallback(() => {

        if(activePage === 'depHome'){
            return (
              <React.Fragment>
                  <div style={{height: 670}}>
                      <TableMui sortingMode={"client"} filterMode={"client"} onClick={(data) => {
                          setActiveCompany(data.row);
                          setActivePage('depIn');
                      }} data={calculationResult} columns={contentColumns()} />
                  </div>
              </React.Fragment>
            )
        }else if(activePage === 'depIn'){
            return <DepartmentAnalytics activeCompany={activeCompany} backHome={() => {
                setActivePage('depHome');
                loadBreadCrumb();
            }} />
        }

    }, [activePage, calculationResult, getColor, language])

    return (
        <div style={{marginTop: 30}}>
            {/*{activeCompany === null && (*/}
            {/*    <FormButton text={I18N("back", language)} onClick={() => {*/}
            {/*    if(activePage === 'depIn'){*/}
            {/*        setActivePage('depHome');*/}
            {/*        setActiveCompany(null);*/}
            {/*    }else if(activePage === 'depHome') {*/}
            {/*        props.backHome();*/}
            {/*    }*/}
            {/*}} isLoading={loading} withLoadingInFullScreen={true} />)}*/}
            {returnView()}
        </div>
    )
}
