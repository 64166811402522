import ViewBox from "../../../ViewComponents/ViewBox";
import {useLanguageState} from "../../../States/LanguageState";
import {I18N} from "../../../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import EditIcon from "../../../ViewComponents/EditIcon";
import GeneralContentBar from "./Bars/GeneralContentBar";
import DataContentBar from "./Bars/DataContentBar";
import {useBreadCrumb} from "../../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";

export default function EditEmailTemplate(props: {emailTemplate: any, closeEditMode: any}) {

    const language = useLanguageState(state => state.language);
    const [state, setState] = useState(props.emailTemplate);
    const theme = useTheme();
    const [editGeneralContent, setEditGeneralContent] = useState(false);
    const [editContent, setEditContent] = useState(false);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();

    const loadCrumb = useCallback(() => {

        breadCrumb.setChildren([]);
        breadCrumb.setBackButton(() => {
            if(props.closeEditMode){
                props.closeEditMode();
            }
        });
        breadCrumb.setTitle(props.emailTemplate.designation,);
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('landingPages', language),
                onClick: () => {
                    props.closeEditMode();
                }
            }, ,{
                title: props.emailTemplate.designation,
                onClick: () => history.push('/phishing/landingpages')
            }])

    }, []);

    useEffect(() => {

        loadCrumb();

    },[]);

    return (
        <>
            <GeneralContentBar isOpen={editGeneralContent} setIsOpen={setEditGeneralContent} content={state} setContent={setState} />
            <DataContentBar isOpen={editContent} setIsOpen={setEditContent} content={state} setContent={setState} />
            <div className="flex-row">
                <ViewBox rightComponent={(<EditIcon onClick={() => setEditGeneralContent(true)} />)} width={40} title={I18N("details", language)}>
                    <table style={{borderSpacing: 15}}>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12, paddingRight: 10}}>{I18N('title', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}>{state.designation}</td>
                        </tr>
                    </table>
                </ViewBox>
                <ViewBox rightComponent={(<EditIcon onClick={() => setEditContent(true)} />)} width={60} title={I18N('content', language)}>
                    <div style={{position: "relative", overflow: "scroll"}} dangerouslySetInnerHTML={{__html: state.data.replace(/<p><\/p>/g, "<br/>")}} />
                </ViewBox>
            </div>

        </>

    )
}