import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import {useBreadCrumb} from "../States/BreadCrumbState";
import checkGreen from "../assets/checkGreen.svg";
import checkRed from "../assets/checkRed.svg";
import {useLanguageState} from "../States/LanguageState";
import EditIcon from "../ViewComponents/EditIcon";
// import EditSettingsBar from "./EditSettingsBar";
import {useLoginState} from "../States/UserState";
import {useGlobalSettingsState} from "../States/GlobalSettings";
import {UsersRepository} from "../Repositories/UsersRepository";
import {PasswordQualityType, UserGroupsType} from "../Types/UserType";
import TableMui from "../ViewComponents/TableMui";
import languageExporter from "../Helpers/LanguageExporter";
import DeleteIcon from "../ViewComponents/DeleteIcon";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import PasswordQualityEdit from "./PasswordQualityEdit";
import {Chip} from "@mui/material";

type Props = {
    style: any;
    isMobile: () => boolean;
}

const userRepository = new UsersRepository();

export function PasswordQuality(props: Props) {

    const language = useLanguageState(state => state.language)
    const breadCrumb = useBreadCrumb();
    const [loadingForm, setLoadingForm] = useState(false);
    const [editSetting, setEditSetting] = useState(false);
    const [groups, setGroups] = useState<UserGroupsType[]>([]);
    const [passwordQualities, setPasswordQualities] = useState<PasswordQualityType[]>([]);
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState(null);
    const groupList = useCallback(async () => {

        const group =  userRepository.getGroups();
        const passwordQuality =  userRepository.passwordQuality();

        setLoading(true);
        await Promise.all([group, passwordQuality]).then((a) => {
            setGroups(a[0]);
            setPasswordQualities(a[1]);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        })

    }, []);

    useEffect(() => {
        groupList().then(undefined)
    }, []);

    useEffect(() => {
        breadCrumb.setTitle(I18N('passwordQuality', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined},{
            title: I18N('passwordQuality', language),
            onClick: undefined
        }]);
        breadCrumb.setChildren([
            {
                color: 'transparent',
                key: 'passwordQualitySettings',
                title:  I18N("x", language),
                onClick: () => undefined,
                isLoading: loadingForm,
                icon: "save"
            }
        ])

    }, [language, loadingForm,]);


    const findItemAndReturn = useCallback((groupId, request: string) => {

        const itemData = passwordQualities.find((item) => {
            return item.group_id === groupId;
        });

        if(!itemData){
            return '-'
        }
        return itemData[request];


    }, [passwordQualities]);


    const tableColumnsMui = (language) => {
        return [
            {
                field: 'role',
                name: I18N('role', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    return <span style={{fontWeight: 'bold'}}>{row.name}</span>;
                }
            },
            {
                field: 'min_password',
                width: 150,
                name: I18N('minPassword', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    console.log(params.row)
                    return findItemAndReturn(params.row.id, 'min_lenght')
                }
            },
            {
                width: 150,
                field: 'digits',
                name: I18N('digits', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    const status = findItemAndReturn(row.id, 'digits');
                    if(status === '-'){
                        return '-'
                    }
                    return <Chip label={I18N(status? 'yes' : 'no', language)} color={status ? 'success' : 'error'} />
                }
            },
            {
                field: 'specialChars',
                width: 150,
                hidden: false,
                sortable: false,
                name: I18N('specialChars', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    const status = findItemAndReturn(row.id, 'specialChars');
                    if(status === '-'){
                        return '-'
                    }
                    return <Chip label={I18N(status? 'yes' : 'no', language)} color={status ? 'success' : 'error'} />
                }
            },
            {
                field: 'capitalLetters',
                width: 150,
                sortable: false,
                filterable: false,
                name: I18N('capitalLetters', language),
                renderCell: (params: any) => {
                    const {row} = params;
                    const status = findItemAndReturn(row.id, 'capitalLetters');
                    if(status === '-'){
                        return '-'
                    }
                    return <Chip label={I18N(status? 'yes' : 'no', language)} color={status ? 'success' : 'error'} />

                }
            },
        ];
    }



    return (
        <React.Fragment>
            <div style={{height: tableHeightCalculator(10)}}>
            <PasswordQualityEdit isOpen={isOpen} setIsOpen={setIsOpen} content={selectedItem} selectedGroup={selectedGroup} setContent={(statusData) => {
               setPasswordQualities(statusData);
                groupList().then(undefined);

            }} />

            <TableMui
                tableName="groupListTable"
                data={groups}
                onClick={(data, event) => {
                    //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                    // IT CAPTURES THE ITEM THAT IS CLICKED
                    // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                    // THINGS THAT WE DONT WANT TO REDIRECT
                    setSelectedGroup(data.row);
                    setSelectedItem(passwordQualities.find((i) => i.group_id === data.row.id))
                    setIsOpen(true);
                }}
                isLoading={loading}
                page={1}
                columns={tableColumnsMui(language)}
                rowCount={groups.length}
            />
            </div>
        </React.Fragment>


    )

}
