import {Chip, Drawer, Grid, Switch, TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import Select, {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import SelectCreatable from "react-select/creatable";
import {ListsRepository} from "../../../../Repositories/ListsRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../../ViewComponents/ViewBox";


const phishingRepository = new PhishingRepository();
const userListRepository = new ListsRepository();
const sweetAlert = withReactContent(Swal)

const GeneralContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void; lists: any[], userList: any[]}) => {
    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({listConnection: undefined, designation: '', pushNow: false, executionDate: null, status: undefined, person: undefined});
    const [loadingCreation, setLoadingCreation] = useState(false);


    const saveChanges = useCallback(() => {
        const executionTime = moment(formState.executionDate).format('YYYY-MM-DD')

      const build = {
          responsible_user_id: formState.person.value,
          execution_date: executionTime,
          status: formState.status.value,
          designation: formState.designation,
          list_connection: null,
      }

        if(formState.listConnection){
            build.list_connection = formState.listConnection.value;
        }

      phishingRepository.updateCampaignGeneral(build, props.content.id).then((e) => {
          props.setContent(e);
          props.setIsOpen(false);
      }).catch((e) =>{
          console.log(e);
      })


    }, [formState.designation, props]);

    useEffect(() => {
        const statusArray = [{value: 1, label: I18N('active', language)}, {value: 0, label: I18N('inactive', language)}];
        const resUser = props.userList.find((item) => item.id === props.content.responsible_user_id);
        let listConnect = null;
        if(props.content.list_connection !== null && props.lists.length > 0){
            listConnect = props.lists.find((item) => item.id === props.content.list_connection);
        }
        setFormState({designation: props.content.designation, pushNow: false, executionDate: props.content.execution_date, status: statusArray.find((item) => item.value === props.content.status),
            person: !resUser ? undefined : {value: resUser.id, label: resUser.firstName + ' ' + resUser.lastName},
            listConnection: listConnect === null ? undefined : {value: listConnect.id, label: listConnect.name}});

    }, [language, props.content, props.lists, props.userList]);


    const createUserList = useCallback(async (name) => {
        setLoadingCreation(true);
        const create = await userListRepository.post({name: name, users: [], description: ' '}).catch(async () => {
            setLoadingCreation(false);
            await sweetAlert.fire({
                title: I18N('error', language),
                html: I18N('unexpectedError', language),
                icon: "error"
            });
        });
        setLoadingCreation(false);
        setFormState({...formState, listConnection: {value: create.id, label: create.name}});

    }, [formState]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <TextField
                                fullWidth
                                label={I18N('designation', language)}
                                style={{ marginTop: 20 }}
                                value={formState.designation}
                                onChange={(text) => setFormState({...formState, designation: text.target.value})}
                            />



                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <Grid container spacing={2} direction={"row"}>
                                    <Grid item md={7}>
                                        <DesktopDatePicker
                                            label={I18N('executionDate', language)}
                                            inputFormat="MM/dd/yyyy"
                                            value={formState.executionDate}
                                            disabled={formState.pushNow}
                                            onChange={(date) => setFormState({...formState, executionDate: date})}
                                            renderInput={(params) => <TextField fullWidth style={{marginTop: 20}} {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={2} style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: "center", marginTop: 20}}>
                                        <Switch checked={formState.pushNow} onClick={() => {
                                            if(formState.pushNow){
                                                setFormState({...formState, executionDate: null, pushNow: !formState.pushNow})
                                            }else{
                                                setFormState({...formState, executionDate: moment().format("YYYY/MM/DD"), pushNow: !formState.pushNow})
                                            }

                                        }} />
                                    </Grid>
                                    <Grid onClick={() => {
                                        if(formState.pushNow){
                                            setFormState({...formState, executionDate: null, pushNow: !formState.pushNow})
                                        }else{
                                            setFormState({...formState, executionDate: moment().format("YYYY/MM/DD"), pushNow: !formState.pushNow})
                                        }
                                    }} item md={3} style={{cursor:'pointer', justifyContent: 'center', alignItems: 'center', alignContent: 'center', alignSelf: "center", marginTop: 20}}>
                                        <Chip color={formState.pushNow ? "success" : "default"} variant={formState.pushNow ? 'filled' : 'outlined'} label= {I18N('pushNow', language)}/>
                                    </Grid>

                                </Grid>
                            </LocalizationProvider>

                            <Select theme={(template) => {
                                return {
                                    ...template,
                                    colors: {
                                        ...template.colors,
                                        ...colorForSelect(theme)
                                    }
                                }
                            }} className="fullWidthSelect marginTop20" styles={{
                                valueContainer: (styles) => {
                                    return {
                                        ...styles,
                                        height: 50,
                                        width: 450,
                                        scrollbarWidth: "none",

                                        msOverflowStyle: "none",
                                        overflowX: "hidden",
                                        overflowY: "scroll"
                                    };
                                },
                                control: base => ({
                                    ...base,
                                    height: 55,
                                    minHeight: 55
                                })}} isMulti={false} placeholder={I18N('responsiblePerson', language)} options={props.userList.map((user) => {
                                return {label: user.firstName + ' ' + user.lastName, value: user.id}
                            })} value={formState.person} onChange={(change) => {
                                setFormState({...formState, person: change})
                            }} />

                            <SelectCreatable theme={(template) => {
                                return {
                                    ...template,
                                    colors: {
                                        ...template.colors,
                                        ...colorForSelect(theme)
                                    }
                                }
                            }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                    return {
                                        ...styles,
                                        height: 50,
                                        scrollbarWidth: "none",
                                        width: 450,
                                        msOverflowStyle: "none",
                                        overflowX: "hidden",
                                        overflowY: "scroll"
                                    };
                                },control: base => ({
                                    ...base,
                                    height: 55,
                                    minHeight: 55
                                })}} isLoading={loadingCreation} onCreateOption={async (inputValue) => await createUserList(inputValue)} isMulti={false} placeholder={I18N('userList', language)} options={props.lists.map((list) => {
                                return {value: list.id, label: list.name}
                            })} value={formState.listConnection} onChange={(change) => {
                                setFormState({...formState, listConnection: change})
                            }} />

                            <Select theme={(template) => {
                                return {
                                    ...template,
                                    colors: {
                                        ...template.colors,
                                        ...colorForSelect(theme)
                                    }
                                }
                            }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                    return {
                                        ...styles,
                                        height: 50,
                                        scrollbarWidth: "none",
                                        width: 450,
                                        msOverflowStyle: "none",
                                        overflowX: "hidden",
                                        overflowY: "scroll"
                                    };
                                },control: base => ({
                                    ...base,
                                    height: 55,
                                    minHeight: 55
                                })}} isMulti={false} placeholder={I18N('status', language)} options={[{value: 1, label: I18N('active', language)}, {value: 0, label: I18N('inactive', language)}]} value={formState.status} onChange={(change) => {
                                setFormState({...formState, status: change})
                            }} />


                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralContentBar;
