import ViewBoxTab from "../../../ViewComponents/ViewBoxTab";
import {I18N} from "../../../i18n/i18n";
import TableMui from "../../../ViewComponents/TableMui";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import FormButton from "../../../ViewComponents/FormButton";
import CampaignOperation from "./CampaignCreate";
import {PhishingRepository} from "../../../Repositories/PhishingRepository";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import moment from "moment";
import {PaginationTypes} from "../../../Types/PaginationTypes";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {useLoginState} from "../../../States/UserState";
import {getToken} from "../../../Repositories/Cookies";
import {useBreadCrumb} from "../../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import {Edit} from "@material-ui/icons";
import EditCampaign from "./EditCampaign";
import Select from "react-select";
import ViewBox from "../../../ViewComponents/ViewBox";
import tableHeightCalculator from "../../../Helpers/TableHeightCalculator";
const sweetAlert = withReactContent(Swal)

const phishingRepository = new PhishingRepository();

export default function CampaignHome(props: {backHome: () => void}){

    const [campaigns, setCampaigns] = useState<PaginationTypes<any>>(null);
    const [planned, setPlanned] = useState<PaginationTypes<any>>(null);
    const [executed, setExecuted] = useState<PaginationTypes<any>>(null);
    const language = useLanguageState(state => state.language);
    const [loading, setLoading] = useState(false);
    const clientName = getToken();
    const [activeTab, setActiveTab] = useState(0);
    const loginState = useLoginState();
    const [createEditMode, setCreateEditMode] = useState(false);
    const [editMode, setEditMode] = useState(null);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [requestedUserId, setRequestedUserId] = useState(null);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();
    const [activeData, setActiveData] = useState('planned');
    //BREADCRUMB


    const crumbData = useCallback(() => {

        breadCrumb.setChildren([
            {
                color: "dark",
                onClick: () => {
                    setCreateEditMode(true);
                },
                icon: "plus",
                title: I18N('create', language),
            }
        ]);
        breadCrumb.setBackButton(() => history.push('/phishing/overview'));
        breadCrumb.setTitle(I18N('campaigns', language));
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('campaigns', language),
                onClick: () => history.push('/phishing/campaigns')
            }])


    }, [])


    useEffect(() => {

        crumbData();

    }, [])

    //BREADCRUMB



    useEffect(() => {

        if(!loginState.token.resource_access[clientName].roles.includes('realm-admin')){
            setRequestedUserId(true);
        }else{
            setRequestedUserId(false);
        }

    }, [clientName, loginState.token.resource_access]);



    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);



    const loadCampaigns = useCallback(async () => {

        if(requestedUserId === null){
            return [];
        }


        setLoading(true);


        const  campListExecuted =  phishingRepository.getAllExecuted({filter: requestedUserId ? {column: 'responsible_user_id', operator: 'equals', value: loginState.token.sub} : filter, page: page, orderType, limit});
        const  campListPlanned =  phishingRepository.getAllPlanned({filter: requestedUserId ? {column: 'responsible_user_id', operator: 'equals', value: loginState.token.sub} : filter, page: page, orderType, limit});
        const  campList =  phishingRepository.getAllCampaigns({filter: requestedUserId ? {column: 'responsible_user_id', operator: 'equals', value: loginState.token.sub} : filter, page: page, orderType, limit});
        await Promise.all([campListExecuted, campListPlanned, campList]).then((response) => {
            setCampaigns(response[2]);
            setExecuted(response[0]);
            setPlanned(response[1]);
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
        })
        setLoading(false);

    }, [filter, limit, orderType, page, requestedUserId]);

    useEffect(() => {

        loadCampaigns().then(undefined).catch(undefined);

    }, [loadCampaigns]);



    const campaignColumns = useCallback(() => {

        return [
            {
                field: 'designation',
                name: I18N("designation", language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.designation}</span>;
                }
            },
            {
                field: 'template',
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                name: I18N("template", language),
                renderCell: (params: any) => {
                    return (
                        params.row.phishing_landing_pages.designation + '(' + params.row.phishing_emails.senderAddress + ')'
                    )
                }
            },
            {
                field: 'numberOfContacts',
                name: I18N('numberOfContacts', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                renderCell: (params: any) => {
                    return (
                        params.row.numberOfContacts
                    )
                }
            },
            {
                field: 'execution',
                name: I18N('executionP', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                renderCell: (params: any) => {
                    return (
                        params.row.execution_date
                    )
                }
            }
        ];

    }, []);


    return (
        <React.Fragment>
            {createEditMode ? (
                <CampaignOperation closeCreate={() => {
                    loadCampaigns();
                    crumbData();

                    setEditMode(null);
                    setCreateEditMode(false);
                }} editAllowed={!requestedUserId} onChangeForm={undefined} editMode={null} />
            ): (
                <>
                    {editMode ? (
                        <EditCampaign editMode={editMode} closeEdit={() => {
                            loadCampaigns();
                            crumbData();
                            setEditMode(null);
                            setCreateEditMode(false);
                            setEditMode(null)
                        }} />
                    ): (
                        <div style={{height: '100%', width: '100%', display: 'flex', marginLeft: 25,  flexDirection: 'column'}}>
                            <div style={{width: 150}}>
                                <Select styles={{control: base => ({
                                        ...base,
                                        width: 150,
                                        marginTop: 20,
                                        marginLeft: 0,
                                        height: 30,
                                        minHeight: 55
                                    })}} onChange={(value, action) => {
                                    setActiveData(value.value);
                                    setPage(1);
                                }} value={{label: I18N(activeData, language), value: activeData}} options={[{label: I18N('planned', language), value: 'planned'}, {label: I18N('accomplished', language), value: 'accomplished'}, {label: I18N('all', language), value: 'all'}]}>

                                </Select>
                            </div>




                                {activeData === 'planned' && (
                                    <div style={{height: tableHeightCalculator(limit)}}><TableMui

                                        onSortModelChange={onSortModelChange}
                                        isLoading={loading}
                                        pageSize={limit}
                                        rowCount={planned?.total}
                                        onFilterModeChange={onFilterModeChange}
                                        onPageSizeChange={(size) => setLimit(size)}
                                        onPageChange={(page) => setPage(page + 1)}
                                        data={planned?.data ?? []} key="tabsCampaignFirst" onClick={(data) => {
                                        setEditMode(data.row);
                                    }} columns={campaignColumns()} /></div>
                                )}


                                {activeData === 'accomplished' && (
                                    <div style={{height: tableHeightCalculator(limit)}}><TableMui
                                        onSortModelChange={onSortModelChange}
                                        isLoading={loading}
                                        pageSize={limit}
                                        rowCount={executed?.total}
                                        onFilterModeChange={onFilterModeChange}
                                        onPageSizeChange={(size) => setLimit(size)}
                                        onPageChange={(page) => setPage(page + 1)}
                                        data={executed?.data ?? []}
                                        columns={campaignColumns()} key={'tableCampaignFinished'} onClick={(data) => {
                                        setEditMode(data.row);
                                    }} /></div>
                                )}

                                {activeData === 'all' && (
                                    <div style={{height: tableHeightCalculator(limit)}}><TableMui
                                        onSortModelChange={onSortModelChange}
                                        isLoading={loading}
                                        pageSize={limit}
                                        rowCount={campaigns?.total}
                                        onFilterModeChange={onFilterModeChange}
                                        onPageSizeChange={(size) => setLimit(size)}
                                        onPageChange={(page) => setPage(page + 1)}
                                        data={campaigns?.data ?? []}
                                        key="tableLandingAlle"  columns={campaignColumns()} onClick={(data) => {
                                        setEditMode(data.row);
                                    }} /></div>
                                )}


                            {/*<ViewBoxTab tabs={[{*/}
                            {/*    name: I18N('planned', language),*/}
                            {/*    content: (<div style={{height: 650}}><TableMui*/}

                            {/*        onSortModelChange={onSortModelChange}*/}
                            {/*        isLoading={loading}*/}
                            {/*        pageSize={limit}*/}
                            {/*        rowCount={campaigns?.total}*/}
                            {/*        onFilterModeChange={onFilterModeChange}*/}
                            {/*        onPageSizeChange={(size) => setLimit(size)}*/}
                            {/*        onPageChange={(page) => setPage(page + 1)}*/}
                            {/*        data={campaigns?.data ?? []} key="tabsCampaignFirst" onClick={(data) => {*/}
                            {/*        setEditMode(data.row);*/}
                            {/*    }} columns={campaignColumns()} /></div>)*/}
                            {/*},{*/}
                            {/*    name: I18N('accomplished', language),*/}
                            {/*    content: (<div style={{height: 650}}><TableMui*/}
                            {/*        onSortModelChange={onSortModelChange}*/}
                            {/*        isLoading={loading}*/}
                            {/*        pageSize={limit}*/}
                            {/*        rowCount={campaigns?.total}*/}
                            {/*        onFilterModeChange={onFilterModeChange}*/}
                            {/*        onPageSizeChange={(size) => setLimit(size)}*/}
                            {/*        onPageChange={(page) => setPage(page + 1)}*/}
                            {/*        data={campaigns?.data ?? []}*/}
                            {/*        columns={campaignColumns()} key={'tableCampaignFinished'} onClick={(data) => {*/}
                            {/*        setEditMode(data.row);*/}
                            {/*    }} /></div>)*/}
                            {/*},*/}
                            {/*    {*/}
                            {/*        name: I18N('all', language),*/}
                            {/*        content: (<div style={{height: 650}}><TableMui*/}
                            {/*            onSortModelChange={onSortModelChange}*/}
                            {/*            isLoading={loading}*/}
                            {/*            pageSize={limit}*/}
                            {/*            rowCount={campaigns?.total}*/}
                            {/*            onFilterModeChange={onFilterModeChange}*/}
                            {/*            onPageSizeChange={(size) => setLimit(size)}*/}
                            {/*            onPageChange={(page) => setPage(page + 1)}*/}
                            {/*            data={campaigns?.data ?? []}*/}
                            {/*            key="tableLandingAlle"  columns={campaignColumns()} onClick={(data) => {*/}
                            {/*            setEditMode(data.row);*/}
                            {/*        }} /></div>)*/}
                            {/*    }]} onTabChange={(currentTab) => {setActiveTab(currentTab)}} />*/}
                        </div>
                    )}

                </>
            )}


        </React.Fragment>

    )

}
