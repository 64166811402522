import {Drawer, TextField, useTheme} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {default as NoCreateSelect} from "react-select";
import {colorForSelect} from "../../ComplinessTheme";
import {I18N} from "../../i18n/i18n";
import FormButton from "../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from '../../ViewComponents/VideoEditComponents/Drawer';
import {useLanguageState} from "../../States/LanguageState";
import {DrawerConfigSx} from "../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../ViewComponents/ViewBox";
import {useLoginState} from "../../States/UserState";
import {UsersRepository} from "../../Repositories/UsersRepository";

const userRepository = new UsersRepository();

const CreateGroupBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, groups: any; setContent: (data) => void;}) => {

    const language = useLanguageState();
    const [loadingData, setLoadingData] = useState(false);
    const [hasError, setHasError] = useState(false);
    const theme = useTheme();
    const authService = useLoginState();
    const [formState, setFormState] = useState({
        groupName: '',
        copyFrom: undefined
    });


    const saveChanges = useCallback(async () => {

        setLoadingData(true);
        await userRepository.createGroup({name: formState.groupName, copyFrom: formState.copyFrom ? formState.copyFrom.value : undefined}).then((response) => {
            props.setContent({});
            props.setIsOpen(false);
            setLoadingData(false);

        }).catch((err) => {
            console.log(err);
            setLoadingData(false);
        })

        setLoadingData(false);

    }, [formState]);

    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('createGroup', language.language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language.language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            {/*GROUP NAME*/}
                            <TextField
                                fullWidth
                                label={I18N('groupName', language.language)}
                                style={{ marginTop: 20 }}
                                value={formState.groupName}
                                error={hasError && formState.groupName === ''}
                                onChange={(text) => setFormState({...formState, groupName: text.target.value})}
                            />

                            {/*COPY FROM*/}
                            <div className="marginTop20">
                                <div className="viewBoxTitle" style={{fontSize: 13}}>{I18N('copyFrom', language.language)}</div>

                                <NoCreateSelect theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} isClearable={true} className="fullWidthSelect marginTop10 " styles={{control: base => ({
                                        ...base,
                                        borderColor: (hasError && (formState.copyFrom === null || formState.copyFrom === undefined)) ? "#FF6262": "rgba(0, 0, 0, 0.23)",
                                        height: 55,
                                        minHeight: 55
                                    })}}
                                                placeholder={I18N('copyFrom', language.language)}
                                                isMulti={false}
                                                options={props.groups.map((i) => {
                                                    return {label: i.name, value: i.id}
                                                })}
                                                value={formState.copyFrom}
                                                onChange={(change) => setFormState({...formState, copyFrom: change})}
                                />

                            </div>

                            {/*DEPARTMENTS*/}

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default CreateGroupBar;
