export const blueDark = {
    50: '#E2EDF8',
    100: '#CEE0F3',
    200: '#91B9E3',
    300: '#5090D3',
    main: '#5090D3',
    400: '#265D97',
    500: '#1E4976',
    600: '#173A5E',
    700: '#132F4C', // contrast 13.64:1
    800: '#001E3C',
    900: '#0A1929'
};

export const colorForSelect = (theme) => {
    if(theme.palette.type === 'light'){
        return {}
    }
    return  {
        danger: 'purple',
            dangerLight: theme.palette.grey[200],
            neutral0: theme.palette.background.default,
            neutral5: theme.palette.background.default,
            neutral10: theme.palette.background.primary,
            neutral20: theme.palette.grey[200],
            neutral30: theme.palette.text.paper,
            neutral40: theme.palette.grey[100],
            neutral50: theme.palette.text.secondary,
            neutral60: theme.palette.background.paper,
            neutral70: theme.palette.background.paper,
            neutral80: theme.palette.text.primary,
            neutral90: theme.palette.background.paper,
            primary: theme.palette.text.primary,
            primary25: theme.palette.background.default,
            primary50: theme.palette.background.paper,
            primary75: theme.palette.background.paper,
    }
}

const ComplinessTheme = (mode: 'light' | 'dark') => {
    return {
        components: {
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        ':disabled': {
                            opacity: '0.3'
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 10
                    }
                }
            },
            RadialBar: {
                new: '#C4C4C4',
                inApproval: '#6FCF97',
                approved: '#27AE60',
                expired: '#FF6262'
            }
        },
        typography: {
            fontFamily: '-apple-system, BlinkMacSystemFont, \'Segoe UI\', \'Roboto\', \'Oxygen\',\n' +
                '    \'Ubuntu\', \'Cantarell\', \'Fira Sans\', \'Droid Sans\', \'Helvetica Neue\',\n' +
                '    sans-serif',
            overline: {
                fontSize: '14px',
                textTransform: 'none'
            },
            subtitle2: {
                fontSize: '12px',
                fontWeight: 'bold'
            }
        },

        palette: {
            blueDark: {
              ...blueDark
            },
            type: mode,
            ...(mode === 'dark' && {
                background: {
                    default: "#001E3C",
                    paper: "#0A1929"
                },
                type: mode,
                borderColor: blueDark[900],
                color: blueDark[50]
            }),
            primaryDark: blueDark,
            mode,
            primary: {
                main: '#3E4649'
            },
            success: {
                main: '#8CEEB6',
                dark: '#219653'
            },
            secondary: { main: '#54667A', contrastText: '#FFE16A' },
            error: {
                main: '#FF6262'
            },
            neutral: {
                main: '#F2F2F2',
                contrastText: '#000'
            },
            ratingColors: {
                low: '#219653',
                medium: '#ffeb3b',
                high: '#F2994A',
                veryHigh: '#FF6262'
            }
        }
    };
};

export default ComplinessTheme;
