import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {TextField, useTheme} from "@mui/material";
import {I18N} from "../../../i18n/i18n";
import FormButton from "../../../ViewComponents/FormButton";
import {colorForSelect} from "../../../ComplinessTheme";
import Select from "react-select";
import {DepartmentType} from "../../../Types/DepartmentTypes";
import {DepartmentsRepository} from "../../../Repositories/DepartmentsRepository";

const saveKey = 'learningPlanTechStep';
const departmentsRepository = new DepartmentsRepository();

function LearningPlanStepIn(props: {title}, ref){

    const language = useLanguageState(state => state.language);
    const theme = useTheme();
    const [formState, setFormState] = useState([{
        name: undefined,
        departments: undefined,
        trigger: undefined
    }]);
    const [departments, setDepartments] = useState<DepartmentType[]>([]);

    const loadDepartments = useCallback(async () => {

        const depList = await departmentsRepository.getAll();
        setDepartments(depList as DepartmentType[]);

    }, []);

    useEffect(() => {
        loadDepartments().then(undefined)
    }, [loadDepartments]);
    const clearData = useCallback(() => {

        setFormState([{
            name: undefined,
            departments: undefined,
            trigger: undefined
        }]);
        localStorage.removeItem(saveKey);

    }, []);

    const checkLocalStorage = useCallback(() => {

        const categoryTechData = localStorage.getItem(saveKey);
        if(categoryTechData){
            setFormState(JSON.parse(categoryTechData));
        }
    }, []);

    useEffect(() => {
        checkLocalStorage();
    }, []);

    useEffect(() => {

        localStorage.setItem(saveKey, JSON.stringify(formState));

    }, [formState]);

    const globalTriggers = [
        {label: I18N('trigger_userCreated', language), value: 'userCreated'},
        {label: I18N('trigger_userMoved', language), value: 'userMoved'},
        {label: I18N('trigger_userFinished', language), value: 'userFinished'}
    ]

    return (
        <div style={{padding: 10, maxHeight: 380, minHeight: 380, overflowY: "scroll"}}>

            <p style={{fontWeight: "bold", fontSize: 16, }}>{props.title}</p>

            {formState.map((i, key) => {
                return (
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 10}}>
                        <TextField
                            fullWidth
                            label={I18N('name', language)}
                            style={{ marginTop: 10,color: "#fff", marginRight: 5 }}
                            value={formState[key].name}
                            onChange={(text) => {
                                formState[key].name = text.target.value;
                                setFormState([...formState]);
                            }}
                        />

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop10" styles={{
                            valueContainer: (styles) => {
                                return {
                                    ...styles,
                                    height: 50,
                                    scrollbarWidth: "none",

                                    msOverflowStyle: "none",
                                    overflowX: "hidden",
                                    overflowY: "scroll"
                                };
                            },
                            control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}} isMulti={true} placeholder={I18N('departmentSelection', language)} options={departments.map((department) => { return {value: department.ID, label: department.name}})} value={formState[key].departments} onChange={(change) => {
                            //@ts-ignore
                            formState[key].departments = change;
                            setFormState([...formState]);
                        }} />

                        <Select theme={(template) => {
                            return {
                                ...template,
                                colors: {
                                    ...template.colors,
                                    ...colorForSelect(theme)
                                }
                            }
                        }} className="fullWidthSelect marginTop10 marginLeft10" styles={{control: base => ({
                                ...base,
                                height: 55,
                                minHeight: 55
                            })}}
                                placeholder={I18N('trigger', language)}
                                isMulti={false}
                                options={globalTriggers}
                                value={formState[key].trigger}
                                onChange={(change) => {
                                    formState[key].trigger = change;
                                    setFormState([...formState]);
                                }}
                        />
                    </div>
                )
            })}

            <div style={{marginTop: 20}}>
                <FormButton text={I18N('new', language)} color="dark" icon="plus" onClick={() => {
                    formState.push({
                        name: undefined,
                        departments: undefined,
                        trigger: undefined
                    });
                    setFormState([...formState]);
                }} isLoading={false} />
            </div>



            <div style={{display: "none"}} onClick={clearData} ref={ref} />
        </div>
    )

}

const LearningPlanStep = React.forwardRef(LearningPlanStepIn);

export default LearningPlanStep;

