import {I18N} from "../i18n/i18n";
import ViewBox from "../ViewComponents/ViewBox";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../States/LanguageState";
import {Checkbox, TextField, useTheme} from "@mui/material";
import {UsersRepository} from "../Repositories/UsersRepository";
import Select from "react-select";
import {VideoRepository} from "../Repositories/VideoRepository";
import {UserType} from "../Types/UserType";
import {LearnVideosType} from "../Types/LearnVideosTypes";
import {ArrowLeft, ArrowRight} from "@material-ui/icons";
import {colorForSelect} from "../ComplinessTheme";
import {useBreadCrumb} from "../States/BreadCrumbState";
import SelectAutocomplete from "../ViewComponents/SelectAutoComplete";
import languageExporter from "../Helpers/LanguageExporter";
import LanguageExporter from "../Helpers/LanguageExporter";
import VideoTypeExporter from "../Helpers/VideoTypeExporter";

const usersRepository = new UsersRepository();
const videoRepository = new VideoRepository();

interface Props {
    editMode?: any;
    onFormChange: (state: formState) => void;
    onFinish: () => void;
    backCallback: () => void;
}

export interface formState {
    name: string,
    description: string;
    users: string[];
    videos: {label: string, value: number}[] | undefined;
}

export default function ListItemOperation(props: Props) {
    const language = useLanguageState(state => state.language);
    const [checkboxSelected, setCheckboxSelected] = useState([]);
    const [checkboxNotSelected, setCheckboxNotSelected] = useState([]);
    const theme = useTheme();
    const breadCrumb = useBreadCrumb();
    const [formState, setFormState] = useState<formState>(props.editMode !== null ? {
        name: props.editMode.name,
        description: props.editMode.description,
        users: props.editMode.lists_users.map((user) => user.user_id),
        videos: props.editMode.lists_videos.map((video) => {return {label:
                LanguageExporter(video.videos, language, 'video_titles', 'title', 'title') + " ("+VideoTypeExporter(video.videos.type)+")", value: video.video_id}}),
    } : {
        users: [],
        videos: undefined,
        name: '',
        description: '',
    })

    const [users, setUsers] = useState([]);
    const [videos, setVideos] = useState([]);

    const loadUsers = useCallback(async () => {

        const userList = await usersRepository.getAll();
        setUsers(userList as UserType[]);
    }, []);

    const loadVideos = useCallback(async () => {
        const videoList = await videoRepository.getAll();
        setVideos(videoList as LearnVideosType[]);
    }, []);


    useEffect(() => {
        loadUsers().then(undefined);
        loadVideos().then(undefined);
    }, []);


    useEffect(() => {
        props.onFormChange(formState);
    }, [formState])


    const newUserItemShower = useCallback((user, isSelected: boolean) => {
        if(isSelected){
            if(users){
                user = users.find((inUser) => inUser.id === user);
            }
        }
        return (
            <div className="selectItem"><Checkbox className="checkboxColor" onChange={(event, checked) => {

                if(isSelected){

                    if(checked){
                        setCheckboxSelected([...checkboxSelected, user.id]);
                    }else{
                        let userList = checkboxSelected;
                        const index = userList.findIndex((user_id) => user_id === user.id);
                        if(index !== -1){
                            userList.splice(index, 1);
                            setCheckboxSelected([...userList]);
                        }
                    }

                }else{
                    if(checked){
                        setCheckboxNotSelected([...checkboxNotSelected, user.id]);
                    }else{
                        let userList = checkboxNotSelected;
                        const index = userList.findIndex((user_id) => user_id === user.id);
                        if(index !== -1){
                            userList.splice(index, 1);
                            setCheckboxNotSelected([...userList]);
                        }
                    }

                }
            }} checked={isSelected ? checkboxSelected.findIndex((userIn) => userIn === user.id) !== -1 : checkboxNotSelected.findIndex((userIn) => userIn === user.id) !== -1} /> {user?.username}</div>
        )
    }, [checkboxNotSelected, checkboxSelected, users]);


    useEffect(() => {
        breadCrumb.setTitle(props.editMode ? props.editMode.name : I18N('createListItem', language));
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? props.editMode.name : I18N('createListItem', language),
            onClick: undefined
        });
        breadCrumb.setBackButton(props.backCallback);
        breadCrumb.setChildren([
            {
                color: 'lightGreen',
                key: 'listItemInnerSaver',
                title: props.editMode ? I18N('updateListItem', language) : I18N("createListItem", language),
                onClick: props.onFinish,
                icon: "save"
            }
        ])

    }, [language, props.backCallback, props.editMode, props.onFinish]);

    return (
        <React.Fragment>
            <div className="flex-row">
                <ViewBox title={I18N('title', language)}>
                    <TextField
                        fullWidth
                        label={I18N("title", language)}
                        style={{ marginTop: 20 }}
                        value={formState.name}
                        onChange={(text) => setFormState({...formState, name: text.target.value})}
                    />
                </ViewBox>
                <ViewBox title={I18N("description", language)}>
                    <TextField
                        fullWidth
                        label={I18N("description", language)}
                        style={{ marginTop: 20 }}
                        value={formState.description}
                        onChange={(text) => setFormState({...formState, description: text.target.value})}
                    />
                </ViewBox>
            </div>

            <ViewBox title={I18N('content', language)}>
                <div className="marginTop20">

                        <SelectAutocomplete multiple={true} maxTags={2} options={videos.map((video) => { return {value: video.id, label: LanguageExporter(video, language, 'video_titles', 'title', 'title') + " ("+VideoTypeExporter(video.type)+")"}})} value={formState.videos} onChange={(data) => setFormState({...formState, videos: data})} />


                        {/*<Select styles={{ valueContainer: (styles) => {*/}
                        {/*        return {*/}
                        {/*            ...styles,*/}
                        {/*            height: 50,*/}
                        {/*            scrollbarWidth: "none",*/}

                        {/*            msOverflowStyle: "none",*/}
                        {/*            overflowX: "hidden",*/}
                        {/*            overflowY: "scroll"*/}
                        {/*        };*/}
                        {/*    },}} theme={(template) => {*/}
                        {/*    return {*/}
                        {/*        ...template,*/}
                        {/*        colors: {*/}
                        {/*            ...template.colors,*/}
                        {/*            ...colorForSelect(theme)*/}
                        {/*        }*/}
                        {/*    }*/}
                        {/*}} key="videoselect" isMulti={true} options={videos.map((video) => { return {value: video.id, label: video.title}})} value={formState.videos} onChange={(change) => {*/}
                        {/*    //@ts-ignore*/}
                        {/*    setFormState({...formState, videos: change})*/}
                        {/*}} />*/}
                </div>
            </ViewBox>

            <ViewBox>
                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <div style={{width: '45%'}}>
                        <p style={{fontSize: 16, fontWeight: "bold", marginLeft: 6}}>{I18N('notSelectedUsers', language)}</p>
                        <div style={{width: '100%', maxHeight: 200, height:200, overflowY: "scroll", marginRight: '1%', border: '1px solid #e0e0e0', borderRadius: 10}}>
                            <div className="selectItem"><Checkbox className="checkboxColor" checked={users.filter((item) => !formState.users.includes(item.id)).length === checkboxNotSelected.length} value={"on"} onChange={(event, checked) => {

                                if(checked){
                                    setCheckboxNotSelected([...users.filter((item) => !formState.users.includes(item.id)).map((item) => item.id)])
                                }else {
                                    setCheckboxNotSelected([]);
                                }
                            }} /> <strong>{I18N('selectAll', language)}</strong></div>
                            {users.filter((item) => !formState.users.includes(item.id)).map((user) => newUserItemShower(user, false))}
                        </div>
                    </div>

                    <div style={{width: '10%', display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", alignItems: "center"}}>
                        <div style={{background: "#FFE16A", borderRadius: 3, cursor: "pointer", }} onClick={() => {
                            setFormState({...formState, users: [...formState.users, ...checkboxNotSelected]});
                            setCheckboxNotSelected([]);
                            setCheckboxSelected([]);
                        }}>
                            <ArrowRight  style={{color: "#000"}} />
                        </div>

                        <div style={{background: "#FFE16A", borderRadius: 3, marginTop: 7, cursor: "pointer", }} onClick={() => {

                            const formStateUser = formState.users;

                            checkboxSelected.forEach((i) => {
                                const index = formStateUser.findIndex((user_id) => user_id === i);
                                if(index !== -1){
                                    formStateUser.splice(index, 1);
                                }
                            });
                            setFormState({...formState, users: [...formStateUser]});
                            setCheckboxNotSelected([]);
                            setCheckboxSelected([]);

                        }}>
                            <ArrowLeft style={{color: "#000"}}   />
                        </div>

                    </div>
                    <div style={{width: '45%',}}>
                        <p style={{fontSize: 16, fontWeight: "bold", marginLeft: 6}}>{I18N('selectedUsers', language)}</p>
                        <div style={{ width: '100%', maxHeight: 200, height:200, overflowY: "scroll", marginLeft: '1%', border: '1px solid #e0e0e0',  borderRadius: 10}}>
                            <div className="selectItem"><Checkbox className="checkboxColor" checked={checkboxSelected.length === formState.users.length} value={"on"} onChange={(event, checked) => {
                                if(checked){
                                    setCheckboxSelected([...formState.users]);
                                }else {
                                    setCheckboxSelected([])
                                }
                            }} /> <strong>{I18N('selectAll', language)}</strong></div>
                            {formState.users.map((user) => newUserItemShower(user, true))}
                        </div>
                    </div>

                </div>

            </ViewBox>

        </React.Fragment>
    )
}
