export const welcomeDefault = `Hallo {{firstName}} {{lastName}},
<br/>
<br />
<br />
es wurde ein neuer Compliness-Benutzer für Dich angelegt.<br />
    <br/>
Über folgenden Link kannst Du ein Passwort vergeben und Dich anmelden: <a href="{{url}}/?firstTimeLogin={{client}}cmpl__{{username}}cmpl__{{password}}">https://compliness.de</a>
<br />
<br />
Wir wünschen viel Spaß mit den Lerninhalten.
<br>
<br>
Mit freundlichen Grüßen<br/>
Dein Compliness-Team`;


export const defaultVideoNotification = `Hallo {{firstName}} {{lastName}},
<br/><br/>
für Dich steht ein neuer Lerninhalt bereit.
<br/><br/>
Lerninhalt: {{videoName}}<br/>
Kategorie: {{videoCategory}}<br/>
<br/><br/>
Über folgenden Link gelangst Du direkt zum Lerninhalt: {{link}}
<br/><br/>
Viel Spaß beim Lernen!
<br/><br/>
<br/><br/>
Mit freundlichen Grüßen<br/>
Dein Compliness-Team <br/>
`;


export const defaultResetPass = `
Hallo {{firstName}} {{lastName}},<br>
<br>
für Deinen Compliness-User wurde die Zurücksetzung des Passworts beantragt.
<br><br>
Das Passwort kann über folgenden Link zurückgesetzt werden: https://compliness.de/?token={{token}}
<br><br>
Sofern dieser Vorgang nicht von Dir beantragt wurde, empfehlen wir dies Deinem Administrator mitzuteilen und anschließend diese Mail zu löschen.
<br><br>
Mit freundlichen Grüßen<br>
Dein Compliness-Team<br>
`

export const defaultNotification = `
{{text}}
`;

export const defaultLinkNotification = `
Hallo {{firstName}} {{lastName}},
<br/><br/>
für Dich steht ein Lerninhalt bereit.
<br/><br/>
Über folgenden Link gelangst Du direkt zum Lerninhalt: {{link}}
<br/><br/>
Viel Spaß beim Lernen!
<br/>
<br/>
Mit freundlichen Grüßen<br/>
Dein Compliness-Team<br/>

<br/><br/>
`