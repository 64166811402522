import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {UserGroupsType, UserType} from "../Types/UserType";

export class UserContentDataRepository implements AbstractRepository<UserType> {

    post(object: UserType): Promise<UserType> {
        throw new Error("Method not implemented.");
    }
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: string): Promise<any> {
        return new Promise((resolve, reject) => {
        });
    }


    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.get('/contentdata').then((users) => {
                resolve(users.data);
            }).catch((error) => {
                reject(error);
            })

        });
    }


    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    //@ts-ignore
    update(object: any): Promise<any> {

        return new Promise((resolve, reject) => {

            axios.patch('/contentdata/', object).then((response) => {
                return resolve(response.data);
            }).catch(() => {
                return reject({
                    status: false
                })
            })

        });
    }

    getGroups(): Promise<UserGroupsType[]>{
        return new Promise((resolve, reject) => {

            axios.get('/users/groups').then((groups) => {
                resolve(groups.data);
            }).catch((error) => {
                reject(error);
            })

        });
    }

    getUserGroup(id: string): Promise<any>{

        return new Promise((resolve, reject) => {

            axios.get('/users/userGroup/'+id).then((group) => {
                resolve(group.data);
            }).catch((error) => {
                reject(error);
            })

        })

    }

    getUrl(): string {
        return "";
    }


}
