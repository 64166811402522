import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PaginationRequestType, PaginationTypes} from "../Types/PaginationTypes";
import {UserListsType} from "../Types/UserListsTypes";

export class QuizRepository implements AbstractRepository<PaginationTypes<UserListsType> | UserListsType[]>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string, unlink: undefined | boolean): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.delete('/quizes/' + id, {
                data: {
                    unlink: unlink
                }
            }).then((response) => {
                return resolve(response.data)
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    deleteQuestion(quizId, questionId): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.delete(`/quizes/${quizId}/question/${questionId}`).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            });
        })
    }

    finishQuiz(quizId: number, contentId: number, answers: any){
        return new Promise((resolve, reject) => {

            return axios.post('/quizes/finish', {quizId: quizId, contentId: contentId, answers}).then((response) => {
                return resolve(response.data)
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    saveAnswer(quizId: number, contentId: number, answers: any, currentQuestion){
        return new Promise((resolve, reject) => {

            return axios.put('/quizes/update', {quizId: quizId, contentId: contentId, answers, currentQuestion}).then((response) => {
                return resolve(response.data)
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    getPDF(data: any, quiz_name: string){

        return new Promise((resolve, reject) => {

            axios.post(`/quizes/quizPDF`, data,{
                responseType: 'blob'
            }).then((response) => {

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${quiz_name}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                return resolve(response.data);

            }).catch((err) => {
                return reject(err);
            })

        });

    }

    getAll(pagination?: PaginationRequestType): Promise<PaginationTypes<UserListsType> | UserListsType[]> {

        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/quizes${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.get(`/quizes/${id}`).then((response) => {
                return resolve(response.data);
            }).catch((err) => reject(err))
        })
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.post('/quizes', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/quizes/' + id, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateGeneral(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/quizes/' + id + '/general', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateQuestion(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/quizes/' + id + '/question', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }



}
