import create from 'zustand';

type TableStatesType = {
    table: any,
    setTables: (language: any) => void;
};

export const useTableStates = create<TableStatesType>(set => ({
    table: {},
    setTables: (table) => {
        set({table: table})
    }
}));
