import TrashIcon from "../assets/trash.svg";
import React, {useCallback, useState} from "react";
import {I18N} from "../i18n/i18n";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useLanguageState} from "../States/LanguageState";
import DeleteModal from "./DeleteModal";
import ArchiveIcon from '../assets/archiveBox.svg'
import FormButton from "./FormButton";
const sweetAlert = withReactContent(Swal)

export default function DeleteIcon(props: {callBack?: (row: any) => Promise<void>, isArchive?: boolean, ask?: boolean, row: any, size?: number, unlinkRequired?: boolean}){

    const language = useLanguageState(state => state.language);
    const [unlinkModal, setUnlinkModal] = useState(false);
    const [unlinkData, setUnlinkData] = useState(null);
    const deleteCallBack = useCallback(async (deleteCall: () => Promise<any>) => {

        await sweetAlert.fire({
            showCloseButton: true,
            title: I18N(props.isArchive ? 'archiveHeader' :'deleteHeader', language),
            html: I18N(props.isArchive ? 'archiveItem' :'deleteItem', language),
            icon: "question",
            reverseButtons: true,
            confirmButtonColor: "#8CEEB6",
            cancelButtonColor: "#F2F2F2",
            cancelButtonText: I18N('noCancel', language),
            confirmButtonText: I18N(props.isArchive ? 'yesArchive' :'yesDelete', language),
            customClass: {
                cancelButton: "cancelCustom",
                confirmButton: "confirmCustom"
            },
            showCancelButton: true,
        }).then(async (response) => {
            if(response.isConfirmed){
                if(props.unlinkRequired){
                    await deleteCall().then((response) => {
                        if(response.status === -1){
                            setUnlinkData(response);
                            setUnlinkModal(true);
                        }else{
                            return;
                        }
                    }).catch((e) => {
                       console.log(e);

                    });
                    return;
                }else{

                    await deleteCall().catch((e) => console.log(e));
                    return;
                }


            }
            return;
        });


    }, [language, props.callBack, props.unlinkRequired, props.isArchive]);

    return (
        <>
            <DeleteModal unlinkFunction={async () => {
                if(props.row){
                    await props.callBack({...props.row, unlink: true})
                    setUnlinkModal(false);
                }
            }
            } data={unlinkData} isOpen={unlinkModal} onClose={() => setUnlinkModal(false)} />
            <td
                style={{cursor: (props.callBack !== undefined) ? "pointer" : 'default' }} onClick={() => props.callBack !== undefined ? deleteCallBack(() => props.callBack(props.row)) : undefined}
            ><div className="ml-10 sticky">
                <div className="falseMark" style={{width: props.size + 13 ?? 40, height: props.size + 13 ?? 40, background: props.isArchive && '#F86D70'}}><img style={{width: props.size ?? 15, height: props.size ?? 15}} width={props.size ?? 'auto'} height={props.size ?? 'auto'} data-field="actions" src={props.isArchive ? ArchiveIcon : TrashIcon} /></div>
            </div></td>
        </>
    )

}
