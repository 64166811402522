import create from 'zustand';

type AuthServiceStateType = {
    state: boolean,
    redirectUrl: string;
    setRedirectUrl: (redirectUrl: string) => void;
    setState: (state:boolean) => void;
};

export const useAuthServiceState = create<AuthServiceStateType>(set => ({
    state: false,
    redirectUrl: '',
    setRedirectUrl: (redirectUrl) => {
        set({redirectUrl: redirectUrl})
    },
    setState: (state) => {
        set({state: state})
    }
}));
