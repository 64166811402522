import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  useTheme
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { standartColors } from '../assets/colors/Colors';
import searchIcon from '../assets/Search.svg';
import { useLoginState } from '../States/UserState';
import { BACKEND_URL } from '../assets/AppConstants';
import ProfileSVG from '../assets/Profile.svg';
import ProfileDark from '../assets/profile-dark.svg';
import StarSVG from '../assets/Star.svg';
import VideoDark from '../assets/video-dark.svg';
import StarDark from '../assets/start-dark.svg';
import VideoSVG from '../assets/Video.svg';
import searchDark from '../assets/SearchDarkl.svg';
import { Link, useHistory } from 'react-router-dom';
import { useLanguageState } from '../States/LanguageState';
import { NotificationsRepository } from '../Repositories/NotificationsRepository';
import { I18N } from '../i18n/i18n';
import { UsersRepository } from '../Repositories/UsersRepository';
import SingleNotification from '../Notification/SingleNotification';
import { COOKIE_TOKEN, expireCookie, getToken } from '../Repositories/Cookies';
import BellSVG from '../assets/bell.svg';
import LocaitonImg from '../assets/Location.svg';
import LocaitonDark from '../assets/LocationDark.svg';
import BellDark from '../assets/bell-dark.svg';
import { CompanyRepository } from '../Repositories/CompanyRepository';
import settingsIcon from '../assets/Setting.svg';
import settingsDark from '../assets/settings-dark.svg';
import HeaderMenu from '../ViewComponents/NewHeaderMenu';
import searchNew from '../assets/searchIcon.svg';
import LogoNew from '../assets/LogoData.svg';
import SearchDialog from '../Search/SearchDialog';
import ConfigGlobalPermissionCheck from '../Helpers/ConfigGlobalPermissionCheck';
const userRepository = new UsersRepository();
const companyRepository = new CompanyRepository();
const notificationsRepository = new NotificationsRepository();

export function Searchbar() {
  const [searchValue, setSearchValue] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const loginState = useLoginState();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isCompanySelectOpen, setIsCompanySelectOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const language = useLanguageState((state) => state.language);
  const languageState = useLanguageState();
  const history = useHistory();
  const clientName = getToken();
  const [userData, setUserData] = useState(null);
  const theme = useTheme();
  const [companyData, setCompanyData] = useState([]);
  const [logoState, setLogoState] = useState(LogoNew);

  useEffect(() => {
    companyRepository
      .getSubCompanies()
      .then((response) => setCompanyData(response));
    userRepository.getUserInfo().then((response) => setUserData(response));
  }, []);

  const [notifications, setNotifications] = useState([]);

  const getEmail = useCallback(() => {
    if (userData !== null) {
      return userData.email;
    }
    return '...';
  }, [userData]);

  const getName = useCallback(() => {
    if (userData !== null) {
      return userData.given_name + ' ' + userData.family_name;
    }
    return '...';
  }, [userData]);

  const getNotifications = useCallback(async () => {
    const get = await notificationsRepository.getAll();
    setNotifications(get);
  }, []);

  useEffect(() => {
    getNotifications().then(undefined);
  }, []);

  const onAfterClick = useCallback(
    (notification, index) => {
      notifications[index] = { ...notification, isClicked: 1, isRead: 1 };
      setNotifications([...notifications]);
    },
    [notifications]
  );

  useEffect(() => {
    const company = new CompanyRepository();
    if (logoState === LogoNew) {
      company
        .getAll()
        .then((response) => {
          if (response.logo !== null) {
            if (
              !loginState.token.resource_access[clientName].roles.includes(
                'superadmin'
              )
            ) {
              setLogoState(BACKEND_URL + response.logo);
            }
          }
        })
        .catch((err) => {
          console.error(err);
          console.log('error while getting company data');
        });
    }
  }, [loginState, logoState]);

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 99,
        background: theme.palette.mode === 'dark' ? 'rgb(10, 25, 41)' : '#fff',
        top: 0,
        width: '100%',
        left: 0
      }}
    >
      <div style={styles.searchBar.container}>
        <div style={styles.searchBar.searchBar}>
          <Link to={'/Dashboard'}>
            {' '}
            <img
              width={logoState === LogoNew ? 33.52 : 'auto'}
              height={logoState === LogoNew ? 40 : 75}
              src={logoState}
              alt="login"
            />
          </Link>
        </div>

        <div className="headerRightHolder">
          {loginState.data.azp !== 'adminclient' ? (
            <>
              {' '}
              {/*//@ts-ignore*/}
              <p style={{ marginRight: 10 }}>
                {loginState.data?.innerData?.group?.findIndex(
                  (i) => i.name === 'Superadmin'
                ) !== -1 && loginState.data.azp}{' '}
              </p>
            </>
          ) : (
            'AdminClient'
          )}

          {/*//@ts-ignore*/}
          <p>
            {' '}
            {
              // @ts-ignore
              window.env !== undefined &&
                // @ts-ignore
                window.env.KEYCLOAK_REALM === 'awareness_tester' &&
                'TEST'
            }
          </p>

          {loginState.token !== null &&
            loginState.token !== undefined &&
            ConfigGlobalPermissionCheck(
              loginState.token.resource_access[clientName].roles
            ) && (
              <Link style={{ marginLeft: 25 }} to="/Konfiguration">
                <img
                  style={{
                    height: 26,
                    width: 26,
                    marginTop: 0,
                    marginRight: 10
                  }}
                  src={
                    theme.palette.mode === 'dark' ? settingsDark : settingsIcon
                  }
                />
              </Link>
            )}

          {loginState.data.azp !== 'adminclient' && (
            <>
              <div className="notHolder" style={{ marginLeft: 20 }}>
                <img
                  style={{ ...styles.profileTab.imageNoti, height: 26 }}
                  onClick={() => {
                    setIsLanguageOpen(false);
                    setIsProfileOpen(false);
                    setIsNotificationOpen(false);
                    setIsCompanySelectOpen(!isCompanySelectOpen);
                  }}
                  src={
                    theme.palette.mode === 'dark' ? LocaitonDark : LocaitonImg
                  }
                />
                <div className="newNotification" style={{ top: -5 }}>
                  {loginState.company.length ?? '...'}
                </div>
                {isCompanySelectOpen && (
                  <>
                    <div style={{ width: '100%' }}>
                      <FormLabel
                        id="demo-radio-buttons-group-label"
                        style={{
                          position: 'absolute',
                          zIndex: 10,
                          width: 350,
                          fontSize: 18,
                          fontWeight: 'bold',
                          color:
                            theme.palette.mode === 'dark' ? '#fff' : '#000',
                          padding: 10
                        }}
                      >
                        {I18N('activeCompanies', language)}
                      </FormLabel>

                      <div
                        className="notificationHolder"
                        style={{
                          background:
                            theme.palette.mode === 'dark'
                              ? theme.palette.background.paper
                              : '#fff',
                          padding: 20,
                          marginTop: 40,
                          minHeight: 200,
                          maxHeight: 200
                        }}
                      >
                        {localStorage.getItem('iaxx1') === '1' ? (
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup
                              style={{
                                color: theme.palette.mode === 'dark' && '#fff'
                              }}
                            >
                              {companyData.map((item) => {
                                return (
                                  <FormControlLabel
                                    style={{
                                      fontSize: 13,
                                      color:
                                        theme.palette.mode === 'dark'
                                          ? '#fff'
                                          : '#4F4F4F',
                                      marginBottom: 8
                                    }}
                                    value={item.id}
                                    control={
                                      <Checkbox
                                        color="success"
                                        checked={
                                          loginState.company.findIndex(
                                            (i) => Number(i) === item.id
                                          ) !== -1
                                        }
                                        onChange={async (e) => {
                                          const copyCompanies = JSON.parse(
                                            JSON.stringify(loginState.company)
                                          );
                                          if (e.target.checked) {
                                            copyCompanies.push(
                                              Number(e.target.value)
                                            );
                                          } else {
                                            const findData =
                                              copyCompanies.findIndex(
                                                (i) =>
                                                  Number(i) ===
                                                  Number(e.target.value)
                                              );
                                            if (findData !== -1) {
                                              copyCompanies.splice(findData, 1);
                                            }
                                          }
                                          await userRepository.updateUserCompaniesStatus(
                                            copyCompanies
                                          );

                                          window.location.reload();
                                        }}
                                      />
                                    }
                                    label={`${item.name} (${item.employees})`}
                                  />
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        ) : (
                          <FormControl component="fieldset" variant="standard">
                            <FormGroup
                              style={{
                                color: theme.palette.mode === 'dark' && '#fff'
                              }}
                            >
                              {loginState.data.innerData.users_companies.map(
                                (a) => {
                                  const item = a.company;
                                  return (
                                    <FormControlLabel
                                      style={{
                                        fontSize: 13,
                                        color:
                                          theme.palette.mode === 'dark'
                                            ? '#fff'
                                            : '#4F4F4F',
                                        marginBottom: 8
                                      }}
                                      value={item.id}
                                      control={
                                        <Checkbox
                                          color="success"
                                          checked={
                                            loginState.company.findIndex(
                                              (i) => Number(i) === item.id
                                            ) !== -1
                                          }
                                          onChange={async (e) => {
                                            const copyCompanies = JSON.parse(
                                              JSON.stringify(loginState.company)
                                            );

                                            if (e.target.checked) {
                                              copyCompanies.push(
                                                Number(e.target.value)
                                              );
                                            } else {
                                              const findData =
                                                copyCompanies.findIndex(
                                                  (i) =>
                                                    Number(i) ===
                                                    Number(e.target.value)
                                                );
                                              if (findData !== -1) {
                                                copyCompanies.splice(
                                                  findData,
                                                  1
                                                );
                                              }
                                            }
                                            await userRepository.updateUserCompaniesStatus(
                                              copyCompanies
                                            );

                                            window.location.reload();
                                          }}
                                        />
                                      }
                                      label={`${item.name} (${item.employees})`}
                                    />
                                  );
                                }
                              )}
                            </FormGroup>
                          </FormControl>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          <div className="notHolder">
            <img
              style={styles.profileTab.imageNoti}
              onClick={() => {
                if (!isNotificationOpen) {
                  notificationsRepository
                    .markAsRead()
                    .then(undefined)
                    .catch(undefined);
                }
                setIsLanguageOpen(false);
                setIsCompanySelectOpen(false);
                setIsProfileOpen(false);
                setIsNotificationOpen(!isNotificationOpen);
              }}
              src={theme.palette.mode === 'dark' ? BellDark : BellSVG}
            />
            {notifications.filter((not) => not.isRead === 0).length > 0 && (
              <div className="newNotification">
                {notifications.filter((not) => not.isRead === 0).length}
              </div>
            )}
            {isNotificationOpen && (
              <div
                className="notificationHolder"
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.background.paper
                      : '#fff'
                }}
              >
                <div
                  onClick={() => history.push('/notifications')}
                  className="notificationShowAllButton"
                  style={{
                    background:
                      theme.palette.mode === 'dark'
                        ? theme.palette.background.default
                        : '#d7d7d7',
                    color: theme.palette.mode === 'dark' && '#fff'
                  }}
                >
                  {I18N('showAll', languageState.language)}
                </div>
                <ul className="notificationList">
                  {notifications.map((notification, index) => (
                    <SingleNotification
                      notification={notification}
                      index={index}
                      onAfterClick={onAfterClick}
                    />
                  ))}
                  {notifications.length < 1 && (
                    <li className="noNotification">
                      {I18N('noNotifications', languageState.language)}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
          <div>
            <img
              style={styles.profileTab.image}
              onClick={() => {
                setIsProfileOpen(!isProfileOpen);
                setIsLanguageOpen(false);
                setIsNotificationOpen(false);
              }}
              src={
                loginState.photo === ''
                  ? BACKEND_URL + '/images/no-user-image.gif'
                  : BACKEND_URL + '/users' + loginState.photo
              }
            />
            {isProfileOpen && (
              <div
                style={{
                  ...styles.profileTabOpen.holder,
                  color: theme.palette.mode === 'dark' && '#fff',
                  background:
                    theme.palette.mode === 'dark'
                      ? theme.palette.background.paper
                      : '#fff'
                }}
              >
                <div style={styles.profileTabOpen.name}>{getName()}</div>
                <div style={styles.profileTabOpen.email}>{getEmail()}</div>
                <div style={styles.profileTabOpen.line} />
                <Link
                  to={'/Profile'}
                  onClick={() => setIsProfileOpen(false)}
                  style={{
                    textDecoration: 'none',
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000'
                  }}
                >
                  <div style={styles.profileTabOpen.itemHolder}>
                    <div style={styles.profileTabOpen.icon}>
                      <img
                        src={
                          theme.palette.mode === 'dark'
                            ? ProfileDark
                            : ProfileSVG
                        }
                      />
                    </div>
                    <div style={styles.profileTabOpen.text}>
                      {I18N('myProfile', languageState.language)}
                    </div>
                  </div>
                </Link>
                <div style={styles.profileTabOpen.line} />

                <div
                  onClick={() => {
                    expireCookie(COOKIE_TOKEN);
                    expireCookie('refresh_token');
                    window.location.href = '/';
                    window.location.reload();
                  }}
                  style={styles.profileTabOpen.button}
                >
                  {I18N('logout', languageState.language)}
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => setSearchOpen(!searchOpen)}
            style={{ marginLeft: 25 }}
          >
            <img width={26} height={26} src={searchNew} />
          </div>

          <SearchDialog opened={searchOpen} setOpened={setSearchOpen} />
        </div>
      </div>
      <HeaderMenu />
    </div>
  );
}

const styles = {
  profileTabOpen: {
    button: {
      backgroundColor: standartColors.primaryYellow,
      color: '#000',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: 45,
      marginLeft: 10,
      marginRight: 10,
      fontSize: 18,
      fontWeight: 'bold',
      borderRadius: 10
    } as React.CSSProperties,
    itemHolder: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      display: 'flex',
      marginTop: 15,
      cursor: 'pointer'
    } as React.CSSProperties,
    icon: {
      justifyContent: 'flex-start',
      alignSelf: 'flex-start',
      width: '20px',
      height: '25px'
    } as React.CSSProperties,
    text: {
      marginLeft: '15px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 'bold'
    } as React.CSSProperties,
    line: {
      height: 10,
      width: '100%',
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      zIndex: 10,
      borderBottomColor: '#fff',
      marginBottom: 10
    } as React.CSSProperties,
    holder: {
      padding: '10px 10px 10px 10px',
      borderRadius: 5,
      marginLeft: -10,
      zIndex: 999,
      marginTop: 10,
      position: 'absolute',
      width: 155,
      height: 'auto',
      borderWidth: 1,
      borderColor: '#E0E0E0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    } as React.CSSProperties,
    holderNotification: {
      maxHeight: 300,
      overflowY: 'scroll',
      borderRadius: 5,
      marginLeft: -10,
      zIndex: 9,
      background: '#fff',
      marginTop: 10,
      position: 'absolute',
      width: 250,
      height: 'auto',
      borderWidth: 1,
      borderColor: '#E0E0E0',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    } as React.CSSProperties,
    name: {
      wordWrap: 'break-word',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 'bold'
    } as React.CSSProperties,
    email: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#828282'
    } as React.CSSProperties
  },
  profileTab: {
    profile: {
      height: 100,
      width: '20%',
      alignItems: 'center',
      display: 'flex',
      marginLeft: 75
    },
    image: { height: 40, width: 40, borderRadius: 50, cursor: 'pointer' },
    imageNoti: { height: 30, width: 30, marginRight: 30, cursor: 'pointer' },
    language: { height: 30, width: 30, marginLeft: 30, cursor: 'pointer' }
  },
  searchBar: {
    searchBar: {
      height: 100,
      width: '80%',
      display: 'flex',
      alignItems: 'center',
      marginLeft: 25,
      flexDirection: 'row'
    } as React.CSSProperties,
    container: {
      height: 100,
      width: '95%',
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 25,
      flexDirection: 'row'
    } as React.CSSProperties,
    textInput: {},
    searchIcon: { height: 20, width: 20 },
    languageIcon: {
      height: 20,
      width: 20,
      position: 'absolute',
      right: 15
    } as React.CSSProperties
  },
  button: {
    backgroundColor: standartColors.primaryYellow,
    color: '#000',
    borderRadius: 10,
    height: '100%',
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
