
export function tableOfContentCalculator(contentRef) {
    if(contentRef.current !== null){
        const selectHeadingTagsOnly = contentRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6');
        console.log(selectHeadingTagsOnly);
        const itemList = [];
        selectHeadingTagsOnly.forEach((item, index) => {
            let headItem = false;
            if(Number(item.tagName.replace("H", "")) === 1){
                headItem = true;
            }

            if(headItem){
                itemList.push({tagIndex: index, tag: Number(item.tagName.replace("H", "")), itemTitle: item.innerHTML, child: []});
            }else{
                for(let i = (index); i < selectHeadingTagsOnly.length; i++){

                    const innerNumber = Number(selectHeadingTagsOnly[i].tagName.replace("H", ""));
                    if(innerNumber === 1){
                        break;
                    }else{
                        if(itemList[itemList.length -1].child.find((itemChild) => itemChild.tagIndex === i)){
                            break
                        }
                        itemList[itemList.length -1].child.push({tagIndex: i, tag: Number(selectHeadingTagsOnly[i].tagName.replace("H", "")), itemTitle: selectHeadingTagsOnly[i].innerHTML})
                    }
                }
            }
        });
        return itemList;
    }
    return []
}
