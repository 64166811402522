import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import {UsersRepository} from "../Repositories/UsersRepository";
import NotCreatable, {ValueType} from "react-select";
import {colorForSelect} from "../ComplinessTheme";
import {useTheme} from "@mui/material";
import {useBreadCrumb} from "../States/BreadCrumbState";


const usersRepository = new UsersRepository();


type Props = {
    editMode?: any
    backCallback: () => void;
    onUserChange: (user: string) => void;
    onFinish: () => void;
}

export function ApiOperation(props: Props) {

    const [user, setUser] = useState<ValueType<any, false> | null>(null);
    const language = useLanguageState(state => state.language);
    const [users, setUsers] = useState<any>([]);
    const breadCrumb = useBreadCrumb();
    const theme = useTheme();
    useEffect(() => {
        if(props.editMode !== null && users.length > 0) {
            console.log(props.editMode, users);
            const findUser = users.find((userX) => userX.id === props.editMode.user_id);
            if(findUser !== undefined){
                setUser({value: findUser.id, label: findUser.firstName + ' ' +  findUser.lastName + ' (' + findUser.email + ')'});
            }

        }
    }, [props.editMode, users]);


    const loadUsers = useCallback(async () => {

        const userList = await usersRepository.getAll();

        setUsers(userList);

    }, []);

    useEffect(() => {
        loadUsers().then(undefined);
    }, []);

    useEffect(() => {
        if(user !== null && user !== false){
            props.onUserChange(user.value);
        }else {
            props.onUserChange('');
        }
    }, [props, user]);

    useEffect(() => {

        breadCrumb.setTitle(props.editMode ? I18N('updateApiKey', language) : I18N('createApiKey', language));
        breadCrumb.setAdditionalCrumb({
            title: props.editMode ? I18N('updateApiKey', language) : I18N('createApiKey', language),
            onClick: undefined
        });
        breadCrumb.setBackButton(props.backCallback);

        breadCrumb.setChildren([
            {
                color: 'lightGreen',
                key: 'quizOperationCreate',
                title: props.editMode ? I18N('updateApiKey', language) : I18N("createApiKey", language),
                onClick: props.onFinish,
                icon: "save"
            }
        ])

    }, [language, props.backCallback, props.editMode, props.onFinish]);

    return (
        <ViewBox>
            <div style={{width: '100%', marginTop: 20}}>
                <NotCreatable theme={(template) => {
                    return {
                        ...template,
                        colors: {
                            ...template.colors,
                            ...colorForSelect(theme)
                        }
                    }
                }} placeholder={I18N('user', language)} options={users.map((uData) => {
                    return {value: uData.id, label: uData.firstName + ' ' +  uData.lastName + ' (' + uData.email + ')'}
                })} isClearable={true} isMulti={false} value={user} onChange={(item) => setUser(item)} />
            </div>
    </ViewBox>
)
}
