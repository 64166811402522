import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import moment from "moment/moment";
import TableMui from "../ViewComponents/TableMui";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
import {ReleaseNotesRepository} from "../Repositories/ReleaseNotesRepository";
import Pencil from '../assets/editPen.svg';

const releaseNotesRepository = new ReleaseNotesRepository();

export default function ReleaseNoteList(props: {reload: number, setEditMode: (item: any) => void}) {

    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [waitingForPage, setWaitingForPage] = useState(true);
    const [releaseNotes, setReleaseNotes] = useState([]);


    const getReleaseNotes = useCallback(async () => {
        if(!waitingForPage){
            setLoading(true);
            let noteList = await releaseNotesRepository.getAll();

            const releasedOnes = noteList.filter((i) => {
                return i.status;
            });

            const lastOne = releasedOnes[releasedOnes.length - 1];
            if(lastOne !== undefined){

                noteList = noteList.map((i) => {
                    if(i.id === lastOne.id){
                        i.status = 3;
                        return {...i}
                    }else{
                        return {...i}
                    }
                })

            }


            setReleaseNotes(noteList.reverse());
            setLoading(false);
        }
    }, [waitingForPage]);


    useEffect(() => {
        getReleaseNotes().then(undefined).catch(undefined);
    },[getReleaseNotes]);

    const tableColumnsMui = () => {
        return [
            {
                field: 'id',
                name: 'ID',
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'versionNumber',
                width: 350,
                name: I18N("versionNumber", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'release_date',
                width: 350,
                name: I18N("releaseDate", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{moment(value).format('DD.MM.YYYY')}</span>;
                }
            },
            {
                field: 'created_at',
                width: 350,
                name: I18N("created_at", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{moment(value).format('DD.MM.YYYY')}</span>;
                }
            },
            {
              field: "released_at",
              name: I18N("released_at", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    if(value === null || value === undefined){
                        return "NO RELEASE"
                    }
                    return <span style={{fontWeight: 'bold'}}>{moment(value).format('DD.MM.YYYY')}</span>;
                }
            },
            {
                field: 'status',
                name: I18N("status", language),
                renderCell: (params: any) => {
                    const {value} = params;
                    switch (value){
                        case 3: {
                            return I18N('currentlyShown', language);
                        }
                        case 1: {
                            return I18N('released', language);
                        }
                        case 0: {
                            return I18N('notReleased', language)
                        }
                    }
                }
            },
            {
                field: 'actions',
                name: I18N('actions', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 100,
                renderCell: (params: any) => {
                    return <img src={Pencil} />
                }
            },
        ];
    }


    return (
        <div style={{height: tableHeightCalculator(0)}}>
            <TableMui
                tableName="apiKeysListTable"
                setWaitingForPage={setWaitingForPage}
                data={releaseNotes ?? []}
                onClick={(data, event) => {
                    //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
                    // IT CAPTURES THE ITEM THAT IS CLICKED
                    // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
                    // THINGS THAT WE DONT WANT TO REDIRECT
                    if(event.target.dataset.field !== "actions"){
                        props.setEditMode(data.row);
                    }
                }}
                isLoading={loading}
                columns={tableColumnsMui()}
                rowCount={releaseNotes?.length ?? 0}
                page={1}
            />
        </div>
    )

}
