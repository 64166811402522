import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import {BACKEND_URL} from "../../assets/AppConstants";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {standartColors} from "../../assets/colors/Colors";
import {UserContentDataRepository} from "../../Repositories/UserContentDataRepository";
import {VideoRepository} from "../../Repositories/VideoRepository";
import {I18N} from "../../i18n/i18n";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useLanguageState} from "../../States/LanguageState";
import {Backdrop, CircularProgress} from "@mui/material";
const sweetAlert = withReactContent(Swal)
const userContentDataRepository = new UserContentDataRepository();
const videoRepository = new VideoRepository();

export default function ShowPDF(props: {
    pdf: any;
    content?: null | any;
    returnBack: () => void;
    callQuiz: (contentId: number) => void;
    isPreview?: boolean
}){

    const [currentPage, setCurrentPage] = useState(props.content !== null ? JSON.parse(props.content).currentPage : 1);
    const [numOfPages, setNumOfPages] = useState(0);
    const language = useLanguageState(state => state.language);
    const [pdfUrl, setPdfUrl] = useState(null);
    const pdfRef = useRef(null);
    const divRef = useRef(null);
    const [mode, setMode] = useState(false);
    const [fullLoading, setFullLoading] = useState(false);

    useEffect(() => {
        divRefData();
    }, [divRef]);

    const divRefData = useCallback(() => {

        console.log()
        if(divRef.current.getBoundingClientRect().width > divRef.current.getBoundingClientRect().height){
            setMode(true);
        }else{
            setMode(false);
        }

    },[divRef])


    const goBack = useCallback(() => {

        if(currentPage !== 1){
            setCurrentPage(currentPage - 1);
        }

    }, [currentPage]);

    const goNext = useCallback(() => {

        if(numOfPages !== currentPage){
            setCurrentPage(currentPage + 1);
        }

    }, [currentPage, numOfPages])


    const markAsSeen = useCallback(async () => {
        if(props.isPreview)
            return;
        if (props.pdf.quizes !== null && props.pdf.quizes !== undefined) {
            setFullLoading(true);
            await videoRepository.setVideoToQuiz(props.pdf.id);
            setFullLoading(false);
            props.callQuiz(props.pdf.id);
        }else{
            await videoRepository.markAsSeen(props.pdf.id).then(() => undefined);
            await sweetAlert.fire({
                showCloseButton: true,
                title: I18N('success', language),
                html: I18N('successfullyFinished', language),
                icon: "success"
            });
            props.returnBack();
        }
    }, [props.pdf.id, language]);

    // useEffect(() => {
    //     if(numOfPages === currentPage){
    //         markAsSeen().then(undefined);
    //     }
    // }, [numOfPages, currentPage, props.pdf.id, language]);

    const savePdfPageData = useCallback(async () => {
        if(props.isPreview)
            return;
        if(numOfPages !== 0 && currentPage !== undefined){
            const saveData = await userContentDataRepository.update({
                data: JSON.stringify({
                    currentPage: currentPage,
                    isFinished: currentPage === numOfPages,
                    totalPage: numOfPages
                }),
                content_id: props.pdf.id
            })
        }
    }, [currentPage, numOfPages, props.pdf]);

    useEffect(() => {
        savePdfPageData().then(undefined);
    }, [savePdfPageData]);

    const loadPdf = useCallback(async () => {
        setPdfUrl(BACKEND_URL + '/videos/pdf/' + props.pdf.key);
    }, [props.pdf]);

    useEffect(() => {
        loadPdf().then(undefined);
    }, [loadPdf]);


    return (
        <div style={styles.videoPlayer.container}>
            <Backdrop
                open={fullLoading}
                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={styles.section} ref={divRef}>
            {pdfUrl !== null ? (
                    <Document
                        ref={pdfRef}
                        file={pdfUrl}
                        onLoadSuccess={(d) => {
                            setNumOfPages(d.numPages);
                            console.log(d);
                        }}
                        onLoadError={(d) => console.log(d)}
                        loading={<div style={{display: "none"}} />}
                    >

                        <Page key={currentPage}
                              pageNumber={currentPage}
                              height={!mode ? 800 : 600}
                        />
                        <div className="pageControl">
                            <button className="buttonPDF" onClick={goBack}>{"<"}</button>
                            <strong>{currentPage} of {numOfPages}</strong>
                            {numOfPages === currentPage ? (
                                <button className="buttonPDFFinish" onClick={markAsSeen}>{I18N('finish', language)}</button>
                            ): (
                                <button className="buttonPDF" onClick={goNext}>{">"}</button>
                            )}

                        </div>
                        <div className="leftPrevButton" onClick={goBack} />
                        <div className="rightNextButton" onClick={goNext} />
                    </Document>
                ): (
                    "Loading pdf..."
            )}
            </div>
        </div>
    )

}


const styles = {
    section: {
      width: '100%',
    },
    videoPreview: {
        container: { height: 'calc(100% - 100px)', width: '93%', display: 'flex', marginLeft: 25, flexDirection: 'column' } as React.CSSProperties,
        header: {
            container: { height: 80, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: standartColors.black, fontWeight: 'bold' } as React.CSSProperties,
            buttom: {}
        },
        filterButton: { backgroundColor: standartColors.primaryYellow, width: 200, height: 45, borderRadius: 10, display: 'flex', justifyContent: 'space-around', alignItems: 'center' },
        videoGallery: {
            container: { height: 500, width: '100%', display: 'flex', flexWrap: 'wrap', } as React.CSSProperties,
            video: { height: 363, width: 366, marginTop: 50, marginLeft: 50, borderRadius: 10, } as React.CSSProperties,
            videoMobile: { marginTop: 50, borderRadius: 10, },
            previewPic: { height: '100%', width: '100%', borderRadius: 10 }
        },
    },
    videoPlayer: {
        container: { width: "auto", alignItems: "center", alignSelf: "center", maxHeight: 200} as React.CSSProperties,
    }
}
