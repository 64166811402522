import React, {useEffect, useState} from 'react';
import {I18N} from "../../i18n/i18n";
import ViewBox from "../../ViewComponents/ViewBox";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import checkGreen from "../../assets/checkGreen.svg";
import checkRed from "../../assets/checkRed.svg";
import {useLanguageState} from "../../States/LanguageState";
import EditIcon from "../../ViewComponents/EditIcon";
import EditSettingsBar from "./EditSettingsBar";
import {useLoginState} from "../../States/UserState";
import {useGlobalSettingsState} from "../../States/GlobalSettings";

type Props = {
    style: any;
    isMobile: () => boolean;
}


export function AnalyticsSettings(props: Props) {

    const language = useLanguageState(state => state.language)
    const breadCrumb = useBreadCrumb();
    const userData = useLoginState();
    const globalSettings = useGlobalSettingsState(state => state.settings);
    const [status, setStatus] = useState(false);
    const [statusAnon, setStatusAnon] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [editSetting, setEditSetting] = useState(false);


    useEffect(() => {
        const psudeoData = globalSettings.find((i) => i.key === "psudeo");
        const anonData = globalSettings.find((i) => i.key === 'anon');
        setStatusAnon(anonData !== undefined ? !!Number(anonData.value) : false)
        setStatus(psudeoData !== undefined ? !!Number(psudeoData.value) : false);
    }, [globalSettings]);

    useEffect(() => {
        breadCrumb.setTitle(I18N('analytics', language))
        breadCrumb.setCrumb([{title: I18N('configuration', language), onClick: undefined},{
            title: I18N('analytics', language),
            onClick: undefined
        }]);
        breadCrumb.setChildren([
            {
                color: 'transparent',
                key: 'analyticsSettings',
                title:  I18N("sendNotification", language),
                onClick: () => undefined,
                isLoading: loadingForm,
                icon: "save"
            }
        ])

    }, [language, loadingForm,]);


    return (
        <React.Fragment>
            <EditSettingsBar isOpen={editSetting} setIsOpen={setEditSetting} content={{status: status, anonStatus: statusAnon}} setContent={(statusData) => {
                setStatus(statusData);
            }} />
           <ViewBox title={I18N('details', language)} rightComponent={(<EditIcon onClick={() => setEditSetting(true)} />)}>
               <table>
                   <tr>
                       <td style={{paddingLeft: 15, paddingTop: 10, fontWeight: "bold", fontSize: 14, lineHeight: '17px'}}>
                           {I18N('pseudonymzationOfUsers', language)}
                       </td>
                       <td style={{paddingLeft: 15, paddingTop: 13}}>
                           {status ? <img src={checkGreen} style={{width: 18, height: 18}} /> : <img src={checkRed} style={{width: 18, height: 18}} /> }
                       </td>
                   </tr>
                   <tr>
                       <td style={{paddingLeft: 15, paddingTop: 10, fontWeight: "bold", fontSize: 14, lineHeight: '17px'}}>
                           {I18N('anonymizationOfUsers', language)}
                       </td>
                       <td style={{paddingLeft: 15, paddingTop: 13}}>
                           {statusAnon ? <img src={checkGreen} style={{width: 18, height: 18}} /> : <img src={checkRed} style={{width: 18, height: 18}} /> }
                       </td>
                   </tr>
               </table>


           </ViewBox>
        </React.Fragment>


    )

}
