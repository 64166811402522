import React, { useCallback, useEffect, useState } from "react";
import { I18N } from "../i18n/i18n";
import { useLanguageState } from "../States/LanguageState";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import TableMui, {IColumn} from "../ViewComponents/TableMui";
import {LogsRepository} from "../Repositories/LogsRepository";
import FormButton from "../ViewComponents/FormButton";
import moment from "moment/moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Select from "react-select";
import {colorForSelect} from "../ComplinessTheme";
import {useTheme} from "@mui/material";
import tableHeightCalculator from "../Helpers/TableHeightCalculator";
const sweetAlert = withReactContent(Swal)

const logsRepository = new LogsRepository();
type Props = {
    style: any;
    isMobile: () => boolean
    onDone: () => void;
    client?: any;
}
export default function LogsTab(props: Props) {


    //Setting states
    const [logs, setLogs] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [orderType, setOrderType] = useState(null);
    const [filter, setFilter] = useState(null);
    const [selectedWeek, setSelectedWeek] = useState({label: 'Week '+ moment().week(), value: moment().week()})
    const theme = useTheme();


    //Call categories function
    const getLogs = useCallback(async (selectedWeek) => {
        setLoading(true);

        //{page: page, limit: limit, filter, orderType}
        const logsData = await logsRepository.getSpecificClient(props.client.name, selectedWeek.value, moment().year());
        setLogs(logsData);
        setLoading(false);
    }, [props.client]);

    useEffect(() => {
        getLogs(selectedWeek).then(undefined);
    }, [getLogs, selectedWeek]);


    const tableColumnsMui = useCallback(() : IColumn[]  => {
        return [
            {
                field: 'isFrontEnd',
                name: I18N('source', language),
                width: 100,
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value ? 'Frontend' : "Backend"}</span>;
                }
            },
            {
                field: 'type',
                width: 100,

                name: I18N('type', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return <span style={{fontWeight: 'bold'}}>{value}</span>;
                }
            },
            {
                field: 'client',
                width: 100,

                name: I18N('client', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return value;
                }
            },
            {
                field: 'message',
                name: I18N('message', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return value.toString();
                }
            },
            {
                field: 'userID',
                width: 360,

                name: I18N('userID', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return value;
                }
            },
            {
                field: 'userInfo',
                width: 200,
                name: I18N('userInfo', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    if(value !== null && value !== undefined){
                        if(value.client !== null && value.client !== undefined){
                            if(value.device !== null && value.device !== undefined){
                                return value.device.type + '(' + value.client.name + ' ' + value.client.version + ')'
                            }

                        }
                    }
                }
            },
            {
                field: 'timeStamp',
                width: 150,

                name: I18N('timeStamp', language),
                renderCell: (params: any) => {
                    const {value} = params;
                    return moment(value).format('DD.MM.YYYY HH:mm');
                }
            },

        ];

    }, [language]);




    //Sorting functions/
    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);
    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);
    //Sorting functions

    const showContent = useCallback((rowData) => {
        const value = rowData.userInfo;
        let userINFO = '';
        if(value !== null && value !== undefined){
            if(value.client !== null && value.client !== undefined){
                if(value.device !== null && value.device !== undefined){
                    userINFO = value.device.type + '(' + value.client.name + ' ' + value.client.version + ')'
                }

            }
        }


        return (
            <div>
                Type: {rowData.type}<br />
                From: {rowData.isFrontEnd ? 'Frontend' : "Backend"}<br />
                Client: {rowData.client}<br />
                UserID: {rowData.userID}<br />
                User Info: {userINFO}<br />
                Date: {moment(rowData.timeStamp).format('DD.MM.YYYY HH:mm')}<br /><br />
                Message: {rowData.message}

            </div>
        )

    }, []);

    const getArrayOfWeeks = () => {

        const curWeek = moment().week();

        const arrOfWeeks = [];

        for(let i = 1; i <= curWeek; i++){
            arrOfWeeks.push({label: 'Week '+ i, value: i});
        }

        return arrOfWeeks;

    }

    return (
        <div style={props.style}>
            <div className="flex-row-button">
                <Select  theme={(template) => {
                    return {
                        ...template,
                        colors: {
                            ...template.colors,
                            ...colorForSelect(theme)
                        }
                    }
                }} styles={{control: base => ({
                        ...base,
                        width: 150,
                        height: 55,
                        minHeight: 55,
                        marginBottom: 20
                    })}} value={selectedWeek} placeholder={I18N('senderAddress', language)} options={getArrayOfWeeks()} onChange={(e) => setSelectedWeek(e)} />
                <FormButton text={I18N("back", language)} onClick={props.onDone} withLoadingInFullScreen={false} isLoading={false} />
            </div>
        <div style={{height: tableHeightCalculator(limit)}}>
            <TableMui
                onFilterModeChange={onFilterModeChange}
                data={logs !== undefined ? logs : []}
                onClick={async (row) => {
                    await sweetAlert.fire({
                        confirmButtonColor: "#8CEEB6",
                        cancelButtonColor: "#F2F2F2",
                        reverseButtons: true,
                        customClass: {
                            cancelButton: "cancelCustom",
                            confirmButton: "confirmCustom"
                        },
                        title: I18N("log", language),
                        html: showContent(row.row),
                        icon: row.row.type
                    });
                }}
                onSortModelChange={onSortModelChange}
                isLoading={loading}
                columns={tableColumnsMui()}
                pageSize={limit}
            />
        </div>
        </div>
    )

}
