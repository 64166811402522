import ViewBox from "../../../ViewComponents/ViewBox";
import {useLanguageState} from "../../../States/LanguageState";
import {I18N} from "../../../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import EditIcon from "../../../ViewComponents/EditIcon";
import GeneralContentBar from "./Bars/GeneralContentBar";
import DataContentBar from "./Bars/DataContentBar";
import {useHistory} from "react-router-dom";
import {useBreadCrumb} from "../../../States/BreadCrumbState";

export default function EditEmailTemplate(props: {emailTemplate: any, closeCreate: () => void}) {

    const language = useLanguageState(state => state.language);
    const [state, setState] = useState(props.emailTemplate);
    const theme = useTheme();
    const [editGeneralContent, setEditGeneralContent] = useState(false);
    const [editContent, setEditContent] = useState(false);
    const history = useHistory();
    const breadCrumb = useBreadCrumb();

    const loadCrumb = useCallback(() => {

        breadCrumb.setChildren([]);
        breadCrumb.setBackButton(() => {
            if(props.closeCreate){
                props.closeCreate();
            }
        });
        breadCrumb.setTitle(props.emailTemplate.name);
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('emailTemplates', language),
                onClick: () => {
                    props.closeCreate();
                }
            }, ,{
                title: props.emailTemplate.name,
                onClick: () => history.push('/phishing/emailtemplates')
            }])

    }, []);

    useEffect(() => {

        loadCrumb();

    },[]);


    return (
        <>
            <GeneralContentBar isOpen={editGeneralContent} setIsOpen={setEditGeneralContent} content={state} setContent={setState} />
            <DataContentBar isOpen={editContent} setIsOpen={setEditContent} content={state} setContent={setState} />
            <div className="flex-row">
                <ViewBox rightComponent={(<EditIcon onClick={() => setEditGeneralContent(true)} />)} width={40} title={I18N("mail", language)}>
                    <table style={{borderSpacing: 15}}>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' :  "#3E4649", fontSize: 12, marginRight: 5}}>{I18N('title', language)} </td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : 'rgba(62, 70, 73, 1)', fontSize: 14, fontWeight: "bold"}}> {state.name}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12, paddingRight: 10}}> {I18N('sendingAddress', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}> {state.senderAddress}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('subject', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}> {state.regarding}</td>
                        </tr>
                        <tr>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : "#3E4649", fontSize: 12}}>{I18N('linkDomain', language)}</td>
                            <td style={{color: theme.palette.mode === 'dark' ? '#fff' : '#3E4649', fontSize: 14, fontWeight: "bold"}}> {state.linkDomain}</td>
                        </tr>
                    </table>
                </ViewBox>
                <ViewBox rightComponent={(<EditIcon onClick={() => setEditContent(true)} />)} width={60} title={I18N('content', language)}>
                    <div className="imageDataFix" style={{overflow: "scroll", position: "relative"}} dangerouslySetInnerHTML={{__html: state.data.replace(/<p><\/p>/g, "<br/>")}} />
                </ViewBox>
            </div>

        </>

    )
}