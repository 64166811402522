import React, {useCallback, useEffect, useState} from "react";
import {I18N} from "../../../i18n/i18n";
import {useTheme} from "@mui/material";
import {useLanguageState} from "../../../States/LanguageState";
import {CompanyRepository} from "../../../Repositories/CompanyRepository";
import ReactApexChart from "react-apexcharts";


export default function NumberOfUsers(props: {
    companies: any;
}){

    const theme = useTheme();
    const language = useLanguageState(state => state.language);
    const [companySeries, setCompanySeries] = useState([]);
    const [labels, setLabels] = useState([]);


    const calculateData = useCallback((companyData: any) => {

        const calculations = [];
        const labels = [];

        companyData.forEach((company) => {
            calculations.push(company.users.loggedInUsers);
            labels.push(company.name);
        });

        setCompanySeries(calculations);
        setLabels(labels)

    }, []);

    useEffect(() => {

        calculateData(props.companies);

    }, [calculateData, props.companies]);


    const settings = useCallback(() => {
        return {
            labels: labels,
            stroke: {
                colors: [theme.palette.mode === 'dark' ? '#FFE16A' : '#fff'],
            },
            plotOptions: {
                pie: {
                    donut: {
                        borderColor: theme.palette.mode === 'dark' ? '#FFE16A' : '#fff',
                        labels: {
                            show: true,
                            value: {
                                color: theme.palette.mode === 'dark' ? '#FFE16A' : '#000',
                            },
                            total: {
                                label: I18N('total', language),
                                show: true,
                                color: theme.palette.mode === 'dark' ? '#fff' : "#000"
                            }
                        },
                        size: '90%'
                    }
                }
            },
            legend: { show: false },
            dataLabels: {
                enabled: false
            }
        }

    }, [language, theme, labels]);




    return (
        <>
            {labels.length === 0 ? (
                'Loading'
            ): (
                <ReactApexChart
                    type="donut"
                    options={settings()} series={companySeries}
                    height={280}
                />
            )}
        </>

    )

}