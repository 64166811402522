import {AbstractRepository} from "./abstract-repository";
import axios from "axios";

export class NotificationsRepository implements  AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {
        });
    }


    getClientMails(){
        return new Promise((resolve, reject) => {
            axios.get('/notifications/clientNotifications').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    postClientMails(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('/notifications/clientNotifications', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    getAll(): Promise<any[]> {
        return new Promise((resolve, reject) => {

            axios.get('/notifications').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });
    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.post('/notifications', object).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    markAsRead(){
        return new Promise((resolve, reject) => {

            return axios.post('/notifications/readAll').then((response) => {
                return resolve(response);
            }).catch((err) => {
                return reject(err);
            })

            }
        )
    }

    markAsReadAll(){
        return new Promise((resolve, reject) => {

            return axios.patch('/notifications/markasreadall').then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        });
    }


    deleteAll(){
        return new Promise((resolve,reject) => {
            return axios.patch('/notifications/deleteall').then((response) => {
                return resolve(response.data);
            }).catch((err) => reject(err));
        })
    }

    markAsClicked(notificationID: number){
        return new Promise((resolve, reject) => {
           return axios.patch(`/notifications/clicked/${notificationID}`).then((response) => {
               return resolve(response);
           }).catch((err) => reject(err));
        });
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {
        });
    }

}
