import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import Select, {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import FullButton from "../../../../ViewComponents/FullButton";
import LandingOperation from "../../../Templates/LandingOperation";
import EmailOperation from "../../../Templates/EmailOperation";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";
import ViewBox from "../../../../ViewComponents/ViewBox";


const phishingRepository = new PhishingRepository();
const sweetAlert = withReactContent(Swal)


const TemplatesBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void; emailList: any, landingList: any}) => {
    const language = useLanguageState(state => state.language);
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({ landingPage: undefined, email: undefined,});

    const theme = useTheme();

    const saveChanges = useCallback(() => {

        const build = {
            landing_page_id: formState.landingPage.value,
            email_id: formState.email.value,
        }

        phishingRepository.updateCampaignTemplates(build, props.content.id).then(response => {
            props.setIsOpen(false);
            props.setContent(response);
        }).catch(err => {
            console.log(err);
        })

    }, [formState, props]);


    useEffect(() => {

        const landingPage = props.landingList.find((item) => item.id === props.content.landing_page_id);
        const email = props.emailList.find((item) => item.id === props.content.email_id);

        if(landingPage && email){
            setFormState({ landingPage: {value: landingPage.id, label: landingPage.designation}, email: {value: email.id, label: email.name},})

        }


    }, [props.content.email_id, props.content.landing_page_id, props.emailList, props.landingList]);

    const updateLanding = useCallback((formModalUpdateData: any) => {
        console.log(formModalUpdateData);
        setFormState({...formState, landingPage: {value: formModalUpdateData.id, label: formModalUpdateData.designation}});
    }, [formState]);

    const updateEmail = useCallback((formModalUpdateData: any) => {
        console.log(formModalUpdateData);
        setFormState({...formState, email: {value: formModalUpdateData.id, label: formModalUpdateData.name + '('+formModalUpdateData.senderAddress+')'}});
    }, [formState]);


    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <div className="marginTop20">
                                <div className="viewBoxTitle">{I18N('emailTemplate', language)}</div>
                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            width: 500,
                                            height: 50,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={false} isClearable={true} placeholder={I18N('emails', language)} options={props.emailList.map((email) => {
                                    return {label: email.name + ` (${email.senderAddress})`, value: email.id}
                                })} value={formState.email} onChange={(change) => {
                                    setFormState({...formState, email: change})
                                }} />
                                <div style={{display: "flex", justifyContent: "center", width: '100%', marginTop: 15}}>
                                    <FullButton isLeft={true} text={I18N('showTemplate', language)} onClick={async () => {
                                        await sweetAlert.fire({
                                            width: '85%',
                                            html: (<EmailOperation disableAll={true} editMode={(props.emailList.find((eData) => eData.id === formState.email.value))}  />),
                                        })
                                    }} isLoading={false} isSmaller={true} disabled={!(formState.landingPage !== undefined && formState.landingPage !== null)} buttonClass={"dark"}/>
                                    <FullButton  isLeft={true} text={I18N('editTemplate', language)}  onClick={async () => {
                                        await sweetAlert.fire({
                                            width: '85%',

                                            showConfirmButton: false,
                                            html: (<EmailOperation fromModal={true} onClose={() => sweetAlert.close()} fromModalUpdate={async (formModalUpdateData) => {
                                                updateEmail(formModalUpdateData);
                                                sweetAlert.close();
                                            }}  editMode={(props.emailList.find((eData) => eData.id === formState.email.value))} />),
                                        })
                                    }} isLoading={false}  isSmaller={true}  disabled={!(formState.landingPage !== undefined && formState.landingPage !== null)} buttonClass={"dark"}/>
                                </div>


                            </div>

                            <div className="marginTop20">
                                <div className="viewBoxTitle">{I18N('landingPage', language)}</div>
                                <Select theme={(template) => {
                                    return {
                                        ...template,
                                        colors: {
                                            ...template.colors,
                                            ...colorForSelect(theme)
                                        }
                                    }
                                }} className="fullWidthSelect marginTop20" styles={{  valueContainer: (styles) => {
                                        return {
                                            ...styles,
                                            height: 50,
                                            width: 500,
                                            scrollbarWidth: "none",

                                            msOverflowStyle: "none",
                                            overflowX: "hidden",
                                            overflowY: "scroll"
                                        };
                                    },control: base => ({
                                        ...base,
                                        height: 55,
                                        minHeight: 55
                                    })}} isMulti={false} isClearable={true} placeholder={I18N('landingPages', language)} options={props.landingList.map((landing) => {
                                    return {label: landing.designation, value: landing.id}
                                })} value={formState.landingPage} onChange={(change) => {
                                    setFormState({...formState, landingPage: change})
                                }} />

                                <div style={{display: "flex", justifyContent: "center", width: '100%', marginTop: 15}}>
                                    <FullButton isLeft={true} text={I18N('showTemplate', language)} onClick={async () => {
                                        await sweetAlert.fire({
                                            width: '75%',
                                            html: (<LandingOperation disableAll={true} editMode={(props.landingList.find((lData) => lData.id === formState.landingPage.value))} />),
                                        })
                                    }} isLoading={false} isSmaller={true} disabled={!(formState.landingPage !== undefined && formState.landingPage !== null)} buttonClass={"dark"}/>
                                    <FullButton  isLeft={true} text={I18N('editTemplate', language)} onClick={async () => {
                                        await sweetAlert.fire({
                                            showConfirmButton: false,
                                            width: '75%',
                                            html: (<LandingOperation onClose={() => sweetAlert.close()} fromModalUpdate={async (formModalUpdateData) => {
                                                updateLanding(formModalUpdateData);
                                                sweetAlert.close();
                                            }} fromModal={true} editMode={(props.landingList.find((lData) => lData.id === formState.landingPage.value))} />),
                                        })
                                    }} isLoading={false}  isSmaller={true}  disabled={!(formState.landingPage !== undefined && formState.landingPage !== null)} buttonClass={"dark"}/>
                                </div>
                            </div>

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default TemplatesBar;
