import {I18N} from "../../../i18n/i18n";
import {useLanguageState} from "../../../States/LanguageState";

export default function FinishTechStep(){
    const language = useLanguageState(state => state.language)
    return (
        <div style={{height: 400, fontWeight: "bold", color: "#2F3640", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "center", fontSize: 22, alignSelf: "center"}}>
            {I18N('finishTechDescription', language)}
        </div>
    )
}