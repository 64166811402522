import React, { useCallback, useEffect, useState } from 'react';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import { CategoriesRepository } from '../Repositories/CategoriesRepository';
import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import TableMui, { IColumn } from '../ViewComponents/TableMui';
import DeleteIcon from '../ViewComponents/DeleteIcon';
import { CompanyRepository } from '../Repositories/CompanyRepository';
import tableHeightCalculator from '../Helpers/TableHeightCalculator';
import { useSnackbar } from 'notistack';
import DeleteModal from '../ViewComponents/DeleteModal';
import { useLoginState } from '../States/UserState';
import { useHasPermissions } from '../Helpers/MultiPermissionCheck';

const companyRepository = new CompanyRepository();
const categoriesRepository = new CategoriesRepository();

export default function CompanyList(props: { editMode: (item) => void }) {
  //Setting states
  const [companies, setCompanies] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const language = useLanguageState((state) => state.language);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderType, setOrderType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [waitingForPage, setWaitingForPage] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const userInfo = useLoginState((state) => state.company);
  const canEdit = useHasPermissions(['EditCompany']);

  //Call categories function
  const getCompanies = useCallback(
    async (getFast?: boolean) => {
      if (!waitingForPage || getFast) {
        setLoading(true);
        const companyList = await companyRepository.getSubCompanies({
          page: page,
          limit: limit,
          filter,
          orderType
        });
        //const company = companyList.data.filter((a) => userInfo.includes(a.id))
        setCompanies({
          ...companyList,
          total: companyList.data.length,
          data: [...companyList.data]
        });
        setLoading(false);
      }
    },
    [page, limit, filter, orderType, waitingForPage, userInfo]
  );

  //Deleting categories
  const deleteCompany = useCallback(
    async (company: any) => {
      setLoading(true);
      const del = await companyRepository.deleteCompanyForClient(
        company.id,
        company.unlink ?? false
      );

      if (del instanceof Error) {
        setLoading(false);
        enqueueSnackbar(I18N('unexpectedError', language), {
          variant: 'error'
        });
        return;
      } else {
        if (del.status !== undefined && del.status === -1) {
          setLoading(false);
          return del;
        }
        await getCompanies(true).then(undefined);
        enqueueSnackbar(
          I18N('deleteSuccess', language, [
            { key: 'name', value: I18N('company', language) }
          ]),
          { variant: 'success' }
        );
      }
    },
    [companies, language, getCompanies]
  );

  //setting categories table columns
  const tableColumnsMui = useCallback((): IColumn[] => {
    return [
      {
        field: 'id',
        name: 'ID',
        width: 100,
        renderCell: (params: any) => {
          const { value } = params;
          return <span style={{ fontWeight: 'bold' }}>{value}</span>;
        }
      },
      {
        field: 'name',
        name: I18N('name', language),
        renderCell: (params: any) => {
          const { value } = params;
          return value;
        }
      },
      {
        field: 'shortcut',
        name: I18N('shortcut', language),
        renderCell: (params: any) => {
          const { value } = params;
          return value;
        }
      },
      {
        field: 'employees',
        name: I18N('employees', language),
        renderCell: (params: any) => {
          const { value } = params;
          return value;
        }
      },
      {
        field: 'users_companies',
        name: I18N('users', language),
        renderCell: (params: any) => {
          const { value } = params;
          return value.length;
        }
      },
      {
        field: 'learnContent',
        name: I18N('learnContent', language),
        renderCell: (params: any) => {
          const { row } = params;
          return row._count.videos_companies;
        }
      },
      {
        field: 'actions',
        width: 100,
        disableReorder: true,
        disableColumnMenu: true,
        filterable: false,
        sortable: false,
        name: I18N('actions', language),
        renderCell: (params: any) => {
          return (
            <DeleteIcon
              unlinkRequired={true}
              row={params.row}
              callBack={deleteCompany}
              ask={true}
            />
          );
        }
      }
    ];
  }, [language]);

  //Loading categories when page loads
  useEffect(() => {
    getCompanies().then(undefined);
  }, [getCompanies]);

  //Sorting functions/
  const onSortModelChange = useCallback((mode: GridSortModel) => {
    if (mode.length > 0) {
      setOrderType({
        column: mode[0].field,
        type: mode[0].sort
      });
    } else {
      setOrderType(null);
    }
  }, []);
  const onFilterModeChange = useCallback((mode: GridFilterModel) => {
    if (mode.items.length > 0) {
      setFilter({
        column: mode.items[0].columnField,
        value: mode.items[0].value,
        operator: mode.items[0].operatorValue
      });
    } else {
      setFilter(null);
    }
  }, []);
  //Sorting functions

  return (
    <div style={{ height: tableHeightCalculator(limit) }}>
      <TableMui
        page={page}
        onPageSizeChange={setLimit}
        setWaitingForPage={setWaitingForPage}
        tableName="companiesTableList"
        onPageChange={(page) => setPage(page + 1)}
        onFilterModeChange={onFilterModeChange}
        data={companies !== undefined ? companies.data : []}
        onClick={(data, event) => {
          //THIS HOLD NORMAL CLICK EVENT OF THE COLUMN WHICH WE CAN EXTRACT THE FIELD ID AND UNDERSTAND THAT IF ITS CLICK ON THE ACTIONS FIELD,
          // IT CAPTURES THE ITEM THAT IS CLICKED
          // IN ORDER TO KEEP THE SAME BEHAVIOUR WHEN WE CLICK THE OUTSIDE OF THE ROW WE SHOULD ALSO INCLUDE DATA-FIELD="ACTIONS" TO BUTTONS OR THE
          // THINGS THAT WE DONT WANT TO REDIRECT
          if (
            event.target.dataset.field !== 'actions' &&
            event.target.dataset.field !== undefined
          ) {
            props.editMode(data.row);
          }
        }}
        onSortModelChange={onSortModelChange}
        isLoading={loading}
        columns={tableColumnsMui()}
        pageSize={limit}
        rowCount={companies?.total}
      />
    </div>
  );
}
