import ViewBox from "../ViewComponents/ViewBox";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import React, {useCallback, useEffect, useState} from "react";
import {Backdrop, CircularProgress, TextField} from "@mui/material";
import moment from "moment";
import Pencil from '../assets/editPen.svg';
import DeleteIcon from '../assets/times.svg';
import ReleaseNoteImg from '../assets/releaseNotes.jpeg';
import FormButton from "../ViewComponents/FormButton";
import EditContent from "./EditContent";
import {ReleaseNotesRepository} from "../Repositories/ReleaseNotesRepository";
import ShowInModal from "./ShowInModal";
import {useSnackbar} from "notistack";
import {standartColors} from "../assets/colors/Colors";
import HeaderImageModal from "./HeaderImageModal";
const releaseNoteRepository = new ReleaseNotesRepository();
export function ReleaseNoteOperation(props: {editMode: any; backCallback: () => void;}){

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const language = useLanguageState(state => state.language);
    const [showModal, setShowModal] = useState(false);
    const [editElement, setEditElement] = useState(null);
    const [fullLoading, setFullLoading] = useState(false);
    const [headerImageModal, setHeaderImageModal] = useState(false);
    const [editData, setEditData] = useState<{release_date: string, status: number, versionNumber: string, headerText: string; headerImage: string, colorCode: string, fontSize: string}>({
        release_date: moment(props.editMode.release_date).format("DD.MM.YYYY"),
        status: props.editMode.status,
        versionNumber: props.editMode.versionNumber,
        headerText: props.editMode.headerText,
        headerImage: props.editMode.headerImage,
        colorCode: props.editMode.colorCode,
        fontSize: props.editMode.fontSize
    });
    const [releaseNoteData, setReleaseNoteData] = useState<{id: number, image: string, order: number, release_note_id: number, text: string, title: string}[]>([]);

    const duplicateNote = useCallback(async () => {
        setFullLoading(true);
        await releaseNoteRepository.post({...editData, releaseNoteData: releaseNoteData}).then(async () => {
            setFullLoading(false);
            await enqueueSnackbar(I18N("successfullyDuplicated", language, [{key: 'name', value: I18N('releaseNote', language)}]), {variant: "success"})
        }).catch(async (e) => {
            setFullLoading(false);
            await enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"})
        });
    }, [releaseNoteData, editData]);

    const saveData = useCallback(async () => {

        setFullLoading(true);
        await releaseNoteRepository.update({...editData, releaseNoteData: releaseNoteData}, props.editMode.id).then(async (response) => {
            setFullLoading(false);
            await enqueueSnackbar(I18N("successfullyUpdated", language, [{key: 'name', value: I18N('releaseNote', language)}]), {variant: "success"})
        }).catch(async (e) => {
            setFullLoading(false);
            await enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"})
        });

    },[editData, releaseNoteData]);

    useEffect(() => {
        const data = props.editMode.release_notes_data;
        setReleaseNoteData([...data]);
    }, [props.editMode]);

    const deleteElement = useCallback((key: number) => {

        releaseNoteData.splice(key, 1);
        setReleaseNoteData([...releaseNoteData]);

    }, [releaseNoteData]);

    const editElementFunc = useCallback((key: number) => {

        setEditElement({...releaseNoteData[key], itemKey: key});

    }, [releaseNoteData]);

    const onEditContent = useCallback((item: {html: string, title: string, image: string, itemKey: number}) => {

        releaseNoteData[item.itemKey] = {...releaseNoteData[item.itemKey], text: item.html, title: item.title, image: item.image}
        setReleaseNoteData([...releaseNoteData]);

    }, [releaseNoteData]);


    const releaseNow = useCallback(async () => {
        setFullLoading(true);
       await releaseNoteRepository.release(props.editMode.id).then(async (response) => {
           setFullLoading(false);
           await enqueueSnackbar(I18N("successfullyReleased", language, [{key: 'name', value: I18N('releaseNote', language)}]), {variant: "success"})
        }).catch(async (e) =>{
            setFullLoading(false);
           await enqueueSnackbar(I18N("unexpectedError", language), {variant: "error"})
        });

    }, [props.editMode]);

    const editPen = (key: number) => (
        <div style={{zIndex:99}}>
            <div onClick={() => deleteElement(key)} style={{cursor:"pointer", position: "absolute", top: 10, right: 40}}><img width={15} height={15} src={DeleteIcon} /></div>
            <div onClick={() => editElementFunc(key)} style={{cursor:"pointer", position: "absolute", top: 10, right: 10}}><img src={Pencil} /></div>
        </div>
    )

    const editHeaderImage = (deleteHeader: () => void, editImage: () => void) => {
       return (
           <div style={{zIndex:99}}>
               <div onClick={() => deleteHeader()} style={{cursor:"pointer", position: "absolute", top: 10, right: 40}}><img width={15} height={15} src={DeleteIcon} /></div>
               <div onClick={() => editImage()} style={{cursor:"pointer", position: "absolute", top: 10, right: 10}}><img src={Pencil} /></div>
           </div>
       )
    }

    const deleteHeader = useCallback(() => {

        setEditData({...editData, headerText: '', headerImage: '', fontSize: '22', colorCode: "#000"});

    }, [editData]);

    const editImage = useCallback(() => {
        setHeaderImageModal(true);
    }, []);


    const getReleasedTexts = useCallback(() => {

        let text =  props.editMode.released_at !== null ? " - " + (I18N('released_at', language) + ": "+ moment(props.editMode.released_at).format("DD.MM.YYYY"))  : ""
        if(props.editMode.status === 3){
            if(text !== ""){
                text += ", "
            }
            text += I18N('currentlyShown', language);
        }
        return text;

    }, [language, props.editMode]);

    return (
        <div>
            <Backdrop
                open={fullLoading}
                style={{ color: standartColors.primaryYellow, zIndex: 1 }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <ShowInModal isOpen={showModal} onClose={() => setShowModal(false)} editData={editData} releaseNoteData={releaseNoteData} />
            <EditContent itemKey={editElement?.itemKey} editItem={editElement} onEditContent={onEditContent} isOpen={editElement !== null} onClose={() => setEditElement(null)} />
            <HeaderImageModal previousData={editData} isOpen={headerImageModal} onClose={() => setHeaderImageModal(false)} onEditContent={(uData) => {
                setEditData({
                    ...editData,
                    headerImage: uData.image,
                    headerText: uData.title,
                    colorCode: uData.colorCode,
                    fontSize: uData.fontSize
                })
            }} />
            <div className="flex-row-button">
                {editData.status === 0 && (<FormButton text="Release Now" onClick={releaseNow} isLoading={false} />)}
                <FormButton text="Duplicate Note" color="lightGreen" onClick={duplicateNote} isLoading={false} />
                <FormButton text="Show In Modal" color="dark" onClick={() => setShowModal(true)} isLoading={false} />
                {editData.status === 0 && (<FormButton text="Save" icon={"save"} color="green" onClick={saveData} isLoading={false} />)}
            </div>

            <ViewBox title={I18N('releaseNoteEdit', language) + getReleasedTexts()}>

                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <TextField fullWidth={true}
                               label={I18N('releaseDate', language)}
                               className="marginTop20 marginRight10"
                               value={editData.release_date}
                               onChange={(text) => setEditData({...editData, release_date: text.target.value})}
                    />
                    <TextField fullWidth={true}
                               label={I18N('versionNumber', language)}
                               className="marginTop20"
                               value={editData.versionNumber}
                               onChange={(text) => setEditData({...editData, versionNumber: text.target.value})}
                    />
                </div>


                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>




                    {(editData?.headerImage) ? (
                        <div style={{width: 750, height: "auto", position: "relative"}}>
                            {editHeaderImage(deleteHeader, editImage)}
                            <img src={editData.headerImage} width={750} style={{width: 750}} />
                            {editData.headerText && (
                                <p style={{position: "absolute", zIndex: 99, top: '48%', textAlign: "center", fontSize: editData?.fontSize ? (editData.fontSize + 'px') : 22, fontWeight: "bold", width: 750, color: editData.colorCode ?? "#000"}}>
                                    {editData.headerText}
                                </p>
                            )}
                        </div>
                    ) : (
                        <div onClick={() => editImage()} style={{width: 750, height: 75, border: '1px dashed gray', marginBottom: 10, marginTop: 10, position: "relative", textAlign: "center", }}>
                            <div style={{lineHeight: '75px'}}>{I18N("addHeaderImage", language)}</div>
                            {editHeaderImage(deleteHeader, editImage)}
                        </div>
                    )}



                    <div style={{width: 750}}>
                        {releaseNoteData.map((i,key) => {
                            return (
                                <React.Fragment>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", position: "relative", border: '1px dashed gray', padding: 10, marginBottom: 10,}}>
                                        {editPen(key)}
                                        {i.image && i.image !== '' && (((key+1) % 2) === 0) && (
                                            <div style={{position: "relative", padding: 20}}>
                                                <img style={{width: 270, height: "auto"}} src={i.image} />
                                            </div>
                                        )}
                                        <div style={{ position: "relative", width: (i.image && i.image !== '' && i.image !== null) ? 480 : 750}}>

                                            {i.title && i.title !== '' && (
                                                <h2>{i.title}</h2>
                                            )}
                                            <p dangerouslySetInnerHTML={{__html: i.text}}/>
                                        </div>
                                        {i.image && i.image !== '' && (((key+1) % 2) === 1) && (
                                            <div style={{ position: "relative", padding: 20, alignItems: "center", alignContent: "center", alignSelf: "center"}}>
                                                <img style={{width: 270, height: "auto"}} src={i.image} />
                                            </div>
                                        )}
                                    </div>

                                    <div onClick={() => {
                                        releaseNoteData.splice(key+1, 0, {id: -1, image: null, order: 0, release_note_id: i.release_note_id, text: '', title: null})
                                        setReleaseNoteData([...releaseNoteData]);

                                    }
                                    } style={{border: '1px dotted black', padding: 10, marginBottom: 10, width: '100%', cursor: "pointer", textAlign: "center", fontWeight: "bold"}}>
                                        {I18N('addNewRow', language)}
                                    </div>

                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>

            </ViewBox>
        </div>
    )

}