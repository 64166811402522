import {Backdrop, CircularProgress, IconButton, InputAdornment, TextField, useTheme} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import ViewBox from "../ViewComponents/ViewBox";
import {VideoUploadRepository} from "../Repositories/VideoUploadRepository";
import DepartmentSelector from "../ViewComponents/DepartmentSelector";
import {Icon} from "@iconify/react";
import randomIcon from "@iconify-icons/eva/shuffle-fill";
import eyeFill from "@iconify-icons/eva/eye-fill";
import eyeOffFill from "@iconify-icons/eva/eye-off-fill";
import {FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select as MuiSelect} from "@mui/material";
import FullButton from "../ViewComponents/FullButton";
import Select from 'react-select/creatable';
import axios from "axios";
import {params} from "../App";
import {BACKEND_PRODUCTION_URL, BACKEND_URL, TEST_ENDPOINT} from "../assets/AppConstants";
import {standartColors} from "../assets/colors/Colors";
import TrashIcon from '../assets/trash.svg';
import FormButton from "../ViewComponents/FormButton";
import {CompanyRepository} from "../Repositories/CompanyRepository";
import {colorForSelect} from "../ComplinessTheme";

const uploadContentRepository = new VideoUploadRepository();
const companyRepository = new CompanyRepository();


type Props = {

    editMode?: any
    backCallback: () => void;
    onFormChange: (state: any) => void;
    onContactPersonsChange: (state: any) => void;
    setTokenAndURL: (tokenURL) => void;
    onLicenseChange: (license: any) => void;
    formState: {
        name: string;
        contents: number[];
        username: string;
        password: string;
        environment: string
    }
}

export function ClientOperation(props: Props) {

    const language = useLanguageState(state => state.language);
    const [contents, setContents] = useState([]);
    const [showPassword, setShowPassword] = useState(null);
    const [contactPersons, setContactPersons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [licenses, setLicenses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    //@ts-ignore
    const [environment, setEnvironment] = useState((window.env !== undefined && window.env.KEYCLOAK_REALM === "awareness_tester") ? 'test' : 'production');
    const [license, setLicense] = useState(undefined);
    const theme = useTheme();

    const loadLicenses = useCallback(async () => {

        const licenseData = await companyRepository.getLicenses(undefined);
        setLicenses(licenseData);

    }, []);

    useEffect(() =>{
        loadLicenses().then(undefined);
    },[loadLicenses]);

    useEffect(()=>{
        console.log(props);
    }, [props])

    useEffect(() => {

        props.onContactPersonsChange(contactPersons);
        props.onLicenseChange(license);

    }, [contactPersons, license]);


    useEffect(() => {
        if(props.editMode !== null) {
            props.onFormChange({
                password: '',
                username: '',
                name: props.editMode.name,
                contents: props.editMode.content.map((comp) => comp.content_id),
                environment: props.editMode.environment
            });

            let personList = [];
            props.editMode.contactPersons.map((item) => {
                personList.push({
                    name: item.name, surname: item.surname, email: item.email, gender: item.gender
                })
            });
            setContactPersons(personList);


            if(props.editMode.licenses !== undefined && props.editMode.licenses !== null){

                setLicense({
                    value: props.editMode.licenses.id,
                    label: props.editMode.licenses.name
                })

            }

        }else{
            setContactPersons([
                {name: '', surname: '', email: '', gender: undefined}
            ]);
        }
    }, [props.editMode]);


    const generateRandomPassword = useCallback(() => {

        props.onFormChange({...props.formState, password: Math.random().toString(36).slice(2)});
        setShowPassword(true);

    }, [props.formState]);

    const getContents = useCallback(async () => {
        const contentList = await uploadContentRepository.getContent();
        setContents([...contentList]);
    }, []);


    const getTokenAndURL = useCallback(async () => {

        let url = '';
        let token = ''
        let loginToken = '';
        let testToken = '7680d6fd-1db9-44de-b006-05a4b3a94a0e';
        let productionToken = '7680d6fd-1db9-44de-b006-05a4b3a94a0e';
        //@ts-ignore
        if(window.env !== undefined && window.env.KEYCLOAK_REALM === "awareness_tester" && environment === 'test'){
            url = BACKEND_URL;
            token = axios.defaults.headers.Authorization
            //@ts-ignore
        }else if(window.env !== undefined && window.env.KEYCLOAK_REALM === "awareness_test" && environment === 'production'){
            url = BACKEND_URL;
            token = axios.defaults.headers.Authorization
        }else{

            if(environment === 'test'){

                loginToken = testToken;
                url = TEST_ENDPOINT + '/backend';
            }else{
                loginToken = productionToken;
                url = BACKEND_PRODUCTION_URL + '/backend';
            }

        }

        let authToken = token;
        if(loginToken !== ''){
            authToken = await axios.get(`${url}/apikeys/loginUsingToken`, {
                params: {
                    token: loginToken
                }
            }).then(async (res) => {
                return res.data.access_token;
            }).catch((er) => {
                console.log("cant access remote login");
                //alert(JSON.stringify(er.response.data));
            })
        }
        props.setTokenAndURL({token: authToken, url});
        return {
            token: authToken, url
        }

    }, [environment, props.setTokenAndURL]);

    const getContentsForOtherClient = useCallback(async () => {
        setLoading(true);
        const tokenURL = await getTokenAndURL();
        const data = await uploadContentRepository.getContentForRemote(tokenURL.token, tokenURL.url);
        setLoading(false);
        setContents([...data]);
    }, [getTokenAndURL]);

    useEffect(() => {
        if(props.editMode !== null){
            getContents().then(undefined);
        }

    }, [getContents, props.editMode]);

    useEffect(() => {
        getContentsForOtherClient().then(undefined);
    }, [getContentsForOtherClient]);

    return (
       <React.Fragment>
           <Backdrop
               open={(loading)}
           >
               <CircularProgress color="inherit" />
           </Backdrop>
           <div style={{justifyContent: "space-between", display: "flex"}}>
               <div style={{width: '49%'}}>
                   <ViewBox title={I18N('adminClientInfo', language)}>
                       <TextField
                           fullWidth
                           label={I18N('name', language)}
                           className="marginTop20"
                           value={props.formState.name}
                           onChange={(text) => props.onFormChange({...props.formState, name: text.target.value})}
                       />
                       {props.editMode === null && (
                           <React.Fragment>
                               <div className="flex-row">
                                   <TextField
                                       style={{marginTop: 20, marginRight: 10}}
                                       fullWidth
                                       label={I18N('adminUser', language)}
                                       className="marginTop20"
                                       value={props.formState.username}
                                       onChange={(text) => props.onFormChange({...props.formState, username: text.target.value})}
                                   />
                                   <TextField
                                       fullWidth
                                       label={I18N('password', language)}
                                       style={{ marginTop: 20 }}
                                       value={props.formState.password}
                                       onChange={(text) => props.onFormChange({...props.formState, password: text.target.value})}
                                       type={showPassword ? 'text' : 'password'}
                                       // error={isPasswordValid()}
                                       InputProps={{
                                           endAdornment: (
                                               <React.Fragment>
                                                   <InputAdornment position={'end'}>
                                                       <IconButton onClick={generateRandomPassword} edge="end">
                                                           <Icon icon={randomIcon} />
                                                       </IconButton>
                                                   </InputAdornment>
                                                   <InputAdornment position={'end'}>
                                                       <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                           <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                                       </IconButton>
                                                   </InputAdornment>
                                               </React.Fragment>
                                           )
                                       }}
                                   />
                               </div>
                           </React.Fragment>
                       )}

                   </ViewBox>

                   {props.editMode !== null && props.editMode !== undefined && props.editMode.users !== undefined && (
                       <ViewBox>

                           <div style={{display: "flex", justifyContent: "space-around"}}>
                               <p><b>{I18N('activeUsers', language)}:</b> {props.editMode.users.loggedInUsers}</p>
                               <p><b>{I18N('totalUsers', language)}:</b> {props.editMode.users.totalUsers}</p>
                           </div>


                       </ViewBox>
                   )}


                   <ViewBox title={I18N('environment', language)}>
                       <FormControl>
                           <RadioGroup
                               row
                               aria-labelledby="demo-row-radio-buttons-group-label"
                               name="row-radio-buttons-group"
                               onChange={(val) => props.onFormChange({...props.formState, environment: val.target.value})}
                               value={props.formState.environment}
                           >
                               <FormControlLabel value="test" control={<Radio />} label="Test" />
                               <FormControlLabel value="production" control={<Radio />} label="Production" />
                               <FormControlLabel value="intern" control={<Radio />} label="Intern" />
                           </RadioGroup>
                       </FormControl>
                   </ViewBox>

                   {props.formState.environment === "production" && (
                       <ViewBox title={I18N("license", language)}>

                           <Select theme={(template) => {
                               return {
                                   ...template,
                                   colors: {
                                       ...template.colors,
                                       ...colorForSelect(theme)
                                   }
                               }
                           }} className="fullWidthSelect marginTop20" styles={{control: base => ({
                                   ...base,
                                   height: 55,
                                   minHeight: 55,
                                   zIndex: 99
                               })}} isMulti={false} isLoading={isLoading} onCreateOption={async (data) => {
                                   setIsLoading(true);

                                   companyRepository.createLicense(data).then((result) => {
                                       loadLicenses();
                                       setLicense({label: result.name, value: result.id})
                                        setIsLoading(false);
                                       }).catch((err) => {
                                           setIsLoading(false);
                                       })
                           }} placeholder={I18N('license', language)} options={licenses.map((data) => {
                               return {label: data.name, value: data.id}
                           })} value={license} onChange={(change) => {
                               setLicense(change)
                           }} />

                       </ViewBox>
                   )}


                   {/*{props.editMode === null && (*/}
                   {/*    <ViewBox title={I18N('environment', language)}>*/}
                   {/*    <FormControl>*/}
                   {/*        <RadioGroup*/}
                   {/*            row*/}
                   {/*            aria-labelledby="demo-row-radio-buttons-group-label"*/}
                   {/*            name="row-radio-buttons-group"*/}
                   {/*            onChange={(val) => setEnvironment(val.target.value)}*/}
                   {/*            value={environment}*/}
                   {/*        >*/}
                   {/*            <FormControlLabel value="test" control={<Radio />} label="Test" />*/}
                   {/*            <FormControlLabel value="production" control={<Radio />} label="Production" />*/}
                   {/*            <FormControlLabel value="intern" disabled={true} control={<Radio disabled={true} />} label="Intern" />*/}
                   {/*        </RadioGroup>*/}
                   {/*    </FormControl>*/}
                   {/*</ViewBox>*/}
                   {/*)}*/}
                   <ViewBox title={I18N('contents', language)}>
                       <DepartmentSelector
                           onSelectionChange={(data) => props.onFormChange({...props.formState, contents: data})}
                           preSelected={props.editMode === null ? [] : props.editMode.content.map((comp) => comp.content_id)}
                           preLoaded={contents.map((content) => {return {...content, ID: content.id}})}
                       />
                   </ViewBox>
               </div>
               <div style={{width: '49%'}}>
                   <ViewBox title={I18N('adminClientContact', language)} rightComponent={(
                       <div style={{width: 150}}>
                           <FullButton text={I18N('new', language)} onClick={() => {
                               setContactPersons([...contactPersons, {name: '', surname: '', email: '', gender: ''}]);
                           }} isLoading={false} buttonClass={"green"} />
                       </div>
                   )}>

                       {contactPersons.map((item, index) => (

                           <div>
                               <div className="contactPersonText" style={{position:"relative"}}>{index + 1}. {I18N('contactPerson', language)} <div style={{position: "absolute", right: 10, top: 0, width: 21, cursor: "pointer"}} onClick={() => {
                                      setContactPersons(contactPersons.filter((itemU, indexInner) => indexInner !== index));
                               }}><img src={TrashIcon} /></div></div>
                               <div style={{display: "flex", justifyContent: "space-between"}}>
                                   <FormControl fullWidth style={{marginTop: 20, marginRight: 10}}>
                                       <InputLabel id="demo-simple-select-label">{I18N('gender', language)}</InputLabel>
                                       <MuiSelect
                                           labelId="demo-simple-select-label"
                                           id="demo-simple-select"
                                           value={item.gender}
                                           label={I18N('gender', language)}
                                           onChange={(change) => {
                                               contactPersons[index].gender = change.target.value;
                                               setContactPersons([...contactPersons]);
                                           }}
                                       >
                                           <MenuItem value={'male'}>{I18N('male', language)}</MenuItem>
                                           <MenuItem value={'female'}>{I18N('female', language)}</MenuItem>
                                       </MuiSelect>
                                   </FormControl>

                                   <TextField
                                       style={{marginTop: 20, marginRight: 10}}
                                       fullWidth
                                       label={I18N('firstName', language)}
                                       className="marginTop20"
                                       value={item.name}
                                       onChange={(text) => {
                                           contactPersons[index].name = text.target.value;
                                           setContactPersons([...contactPersons]);
                                       }}
                                   />
                                   <TextField
                                       style={{marginTop: 20, marginRight: 10}}
                                       fullWidth
                                       label={I18N('lastName', language)}
                                       className="marginTop20"
                                       value={item.surname}
                                       onChange={(text) => {
                                           contactPersons[index].surname = text.target.value;
                                           setContactPersons([...contactPersons]);
                                       }}
                                   />
                               </div>
                               <TextField
                                   style={{marginTop: 20, marginRight: 10}}
                                   fullWidth
                                   label={I18N('emailAddress', language)}
                                   className="marginTop20"
                                   value={item.email}
                                   onChange={(text) => {
                                       contactPersons[index].email = text.target.value;
                                       setContactPersons([...contactPersons]);
                                   }}
                               />
                           </div>

                       ))}


                   </ViewBox>
               </div>
           </div>

       </React.Fragment>


    )


}
