import React, {ChangeEvent, useCallback, useEffect, useState} from "react";
import {DepartmentsRepository} from "../Repositories/DepartmentsRepository";
import {Checkbox} from "@mui/material";
import {I18N} from "../i18n/i18n";
import {useLanguageState} from "../States/LanguageState";
import {DepartmentType} from "../Types/DepartmentTypes";

const departmentsRepository = new DepartmentsRepository();

interface Props {
    preLoaded?: any[];
    onSelectionChange: (selected: number[]) => void;
    preSelected: number[];
}

export default function DepartmentSelector(props: Props){

    /**
     * States for this component to be able to reactive
     * departments state holds department list from backend
     * loading is holds true/false when loading data from backend
     * selected is holding selected departments
     */
    const [departments, setDepartments] = useState<DepartmentType[]>([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState(props.preSelected);
    const language = useLanguageState(state => state.language);

    /**
     * This function is responsible for loading departments from backend
     * Sends request to load departments
     */
    const loadDepartments = useCallback(async () => {
        setLoading(true);
        const depList = await departmentsRepository.getAll().catch(() => setLoading(false));
        if(depList)
            setDepartments(depList as DepartmentType[]);

        setLoading(false);
    }, []);


    // Calling load departments when this component loaded
    useEffect(() => {
        if(props.preLoaded !== undefined){
            setDepartments(props.preLoaded);
        }else{
            loadDepartments().then(undefined);
        }
    }, [loadDepartments, props.preLoaded]);


    /**
     * Handles selection changes and sends new changes to the parent component
     */
    const onSelectionChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean, department: any) => {
        let updatedSelected = selected;
            if(checked){
                updatedSelected = [...selected, department.ID];
                setSelected(updatedSelected);
            }else{
                const index = updatedSelected.findIndex((dep_id) => dep_id === department.ID);
                if(index !== -1){
                    updatedSelected.splice(index, 1);
                    setSelected([...updatedSelected]);
                }
            }
            props.onSelectionChange(updatedSelected);
    }, [selected, props]);


    const onSelectAllOperation = useCallback((status: boolean) => {

        if(status) {
            const selectingIDS = departments.map((dep) => dep.ID);

            setSelected(selectingIDS);
            props.onSelectionChange(selectingIDS);
        }else{
            setSelected([]);
            props.onSelectionChange([]);
        }
    }, [departments, props]);


    return (
        <div className="checkboxSelectionHolder">
            {loading ? (
                <div>
                    {I18N('loading', language)}
                </div>
            ) : (
                <React.Fragment>
                    <div>
                        <Checkbox
                            className="checkboxColor"
                            onChange={(event, status) => onSelectAllOperation(status)}
                            checked={departments.length === selected.length} />
                        {I18N('selectAll', language)}
                    </div>
                    {departments.map((department) => (
                        <div>
                            <Checkbox
                                className="checkboxColor"
                                onChange={(event, status) => onSelectionChange(event, status, department)}
                                checked={selected.findIndex((depIn) => depIn === department.ID) !== -1} />
                            {department.name}
                        </div>
                    ))}
                </React.Fragment>
            )}

        </div>
    )

}
