import { I18N } from '../i18n/i18n';
import ViewBox from '../ViewComponents/ViewBox';
import React, { useCallback, useEffect, useState } from 'react';
import { useLanguageState } from '../States/LanguageState';
import { VideoRepository } from '../Repositories/VideoRepository';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment/moment';
import NotiSend from '../assets/notiSend.svg';
import { NotificationsRepository } from '../Repositories/NotificationsRepository';
import { Backdrop, CircularProgress, useTheme } from '@mui/material';
import { standartColors } from '../assets/colors/Colors';
import ShowIcon from '../assets/show.svg';
import HideIcon from '../assets/hide.svg';
import { ColorsForChart } from '../Helpers/ColorsForChart';
import { useBreadCrumb } from '../States/BreadCrumbState';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import { useLoginState } from '../States/UserState';
import { useGlobalSettingsState } from '../States/GlobalSettings';
const videoRepository = new VideoRepository();
const notificationsRepository = new NotificationsRepository();

const sweetAlert = withReactContent(Swal);

export default function DepAnalyticsVideo(props: {
  contentID: number;
  onClick: (depID: number) => void;
  onBack: () => void;
  contentData: any;
}) {
  const language = useLanguageState((state) => state.language);
  const [watchList, setWatchList] = useState(null);
  const [totalUser, setTotalUser] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [userList, setUserList] = useState([]);
  const [showUserNames, setShowUserNames] = useState(false);
  const [buttonActivated, setButtonActivated] = useState(true);
  const theme = useTheme();
  const breadCrumb = useBreadCrumb();
  const globalSettings = useGlobalSettingsState((state) => state.settings);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const findPsudeo = globalSettings.findIndex((i) => i.key === 'psudeo');
    const findAnon = globalSettings.findIndex((i) => i.key === 'anon');

    if (findAnon !== -1) {
      if (Number(globalSettings[findAnon].value)) {
        setShowUserNames(false);
        setButtonActivated(false);
      } else {
        setButtonActivated(true);
        if (findPsudeo !== -1) {
          setShowUserNames(!Number(globalSettings[findPsudeo].value));
        } else {
          setShowUserNames(false);
        }
      }
    } else {
      setButtonActivated(true);
      if (findPsudeo !== -1) {
        setShowUserNames(!Number(globalSettings[findPsudeo].value));
      } else {
        setShowUserNames(false);
      }
    }
  }, [globalSettings]);

  useEffect(() => {
    console.log(props.contentData, 'content data');
  }, [props.contentData]);

  useEffect(() => {
    breadCrumb.setCrumb([
      {
        title: I18N('analytics', language),
        onClick: undefined
      },
      {
        title: I18N('contents', language),
        onClick: () => props.onBack()
      },
      {
        title: watchList?.title,
        onClick: undefined
      }
    ]);

    breadCrumb.setTitle(watchList?.title);
    breadCrumb.setBackButton(props.onBack);
    breadCrumb.setChildren([
      {
        title: '',
        color: 'transparent',
        isLoading: loadingForm,
        isFullLoading: true,
        onClick: undefined
      }
    ]);
  }, [watchList, loadingForm]);

  const loadData = useCallback(async () => {
    setLoadingForm(true);
    const watchListData = await videoRepository.getWatchListSingle(
      props.contentID
    );
    setLoadingForm(false);
    setWatchList(watchListData.contentData);
    console.log(watchListData.userTotal, 'Total User', watchListData.userData);
    setTotalUser(watchListData.userTotal);
    setDepartments(watchListData.departments);
    const filterUser = watchListData.userData.filter((i) => {
      if (i.groups.findIndex((a) => a.name === 'Super Admin') !== -1) {
        return false;
      } else {
        return true;
      }
    });
    setUserList(filterUser);
  }, [props.contentID]);

  useEffect(() => {
    loadData().then(undefined);
  }, []);

  const calculateRankingList = useCallback(() => {
    if (watchList === null || departments.length < 1) {
      return { calculatedData: [], label: [], serie: [] };
    }

    let depList = [];
    if (watchList.requiredForAll) {
      departments.forEach((dep) => {
        let totalFinished = 0;

        dep.users.forEach((userID) => {
          if (
            watchList.seen_videos.findIndex(
              (user) => user.user_id === userID.id
            ) !== -1
          ) {
            totalFinished += 1;
          }
        });
        depList.push({ department: { ...dep }, finished: totalFinished });
      });
    } else {
      watchList.video_departments.forEach((dep) => {
        let totalFinished = 0;

        dep.department.users.forEach((userID) => {
          if (
            watchList.seen_videos.findIndex(
              (user) => user.user_id === userID.id
            ) !== -1
          ) {
            totalFinished += 1;
          }
        });
        depList.push({ ...dep, finished: totalFinished });
      });
    }

    depList.sort((a, b) => {
      if (a.finished > b.finished) {
        return -1;
      }
      if (a.finished < b.finished) {
        return 1;
      }
      return 0;
    });

    console.log(depList);
    const hundred = depList[0].finished === 0 ? 0 : depList[0].finished;
    const calculatedData: any[] = [];

    depList.forEach((content, index) => {
      if (index === 0) {
        calculatedData.push({
          ...content,
          finished: content.finished,
          percentage: content.finished > 0 ? 100 : 0
        });
      } else {
        const percentage =
          content.finished > 0 ? (content.finished / hundred) * 100 : 0;
        calculatedData.push({
          ...content,
          finished: content.finished,
          percentage: percentage
        });
      }
    });

    const label = [];
    const serie = [];
    calculatedData.forEach((data) => {
      label.push(data.department.name);
      serie.push(data.finished);
    });

    return { calculatedData, label, serie };
  }, [watchList, departments]);

  const getColor = useCallback((percent: number) => {
    if (percent >= 75) {
      return 'green';
    } else if (percent >= 50) {
      return 'lightGreen';
    } else if (percent >= 25) {
      return 'orange';
    } else if (percent >= 10) {
      return 'lightOrange';
    } else if (isNaN(percent)) {
      return '';
    } else {
      return 'red';
    }
  }, []);

  const sendNotifiactionToUsers = useCallback(async (user_ids, video_id) => {
    await notificationsRepository
      .post({
        to: user_ids,
        video_id: video_id,
        fromAnalytics: 1
      })
      .then(async () => {
        setLoadingForm(false);

        enqueueSnackbar(I18N('notificationsSent', language), {
          variant: 'success'
        });
      })
      .catch(async () => {
        setLoadingForm(false);
        enqueueSnackbar(I18N('unexpectedError', language), {
          variant: 'error'
        });
        return;
      });
  }, []);

  const sendNotification = useCallback(
    async (video_id: number, user_id: string) => {
      setLoadingForm(true);

      await sweetAlert
        .fire({
          confirmButtonColor: '#8CEEB6',
          cancelButtonColor: '#F2F2F2',
          reverseButtons: true,
          customClass: {
            cancelButton: 'cancelCustom',
            confirmButton: 'confirmCustom'
          },
          showCancelButton: true,
          cancelButtonText: I18N('sendThisUser', language),
          confirmButtonText: I18N('sendTotAll', language),
          //title: I18N('sendNotificationType', language),
          html: I18N('doYouWantToSendTOAll', language, [
            { key: 'user_id', value: user_id }
          ]),
          icon: 'question'
        })
        .then(async (response) => {
          if (response.isDismissed) {
            setLoadingForm(false);
            return true;
          } else {
            if (response.isConfirmed) {
              let dataOfRequiredUsers = props.contentData.requiredUsers;

              if (props.contentData.requiredUsers.length === 0) {
                dataOfRequiredUsers = userList;

                dataOfRequiredUsers = userList.filter((i) => {
                  const userData = watchList?.seen_videos.findIndex(
                    (u) => u.user_id === i.id
                  );
                  return userData === -1;
                });

                dataOfRequiredUsers = dataOfRequiredUsers.map((i) => i.id);
              } else {
                dataOfRequiredUsers = dataOfRequiredUsers.filter((i) => {
                  const userData = watchList?.seen_videos.findIndex(
                    (u) => u.user_id === i.id
                  );
                  return userData === -1;
                });

                dataOfRequiredUsers = dataOfRequiredUsers.map((i) => i.id);
              }

              await sendNotifiactionToUsers(dataOfRequiredUsers, video_id);
            } else {
              await sendNotifiactionToUsers([user_id], video_id);
            }
          }
        });
    },
    [
      language,
      props.contentData.requiredUsers,
      sendNotifiactionToUsers,
      userList,
      watchList?.seen_videos
    ]
  );

  useEffect(() => {
    console.log('watchlist', watchList, 'seen', watchList?.seen_videos);
  }, [watchList]);

  const returnUserData = useCallback((userInner, userId, uShow) => {
    if (userInner !== null && userInner !== undefined) {
      if (
        !moment(userInner.archived_at).isBefore(
          moment(new Date()).subtract(1, 'year').add('day', 1)
        )
      ) {
        if (uShow) {
          return userInner.firstName + ' - ' + userInner.lastName;
        }
      } else {
        return userId;
      }
    }
    return userId;
  }, []);

  return (
    <div style={{ marginTop: 30 }}>
      <Backdrop
        open={loadingForm}
        style={{ color: standartColors.primaryYellow, zIndex: 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="flex-row">
        <ViewBox>
          {/* <div className="endFlex flex-row" style={{cursor: "pointer", justifyItems: "flex-end", float: "right", fontWeight: "bold", fontSize: 15, lineHeight: 1.8, marginRight:30}} onClick={() => buttonActivated && setShowUserNames(!showUserNames)}>
                        {buttonActivated && (<>{I18N("showUsernames", language)} {showUserNames ? <img src={ShowIcon} width={25} height={25} /> : <img src={HideIcon} width={25} height={25} /> }</>)}
                    </div> */}
          <div style={{ clear: 'both' }} />
          <div className="tableView">
            <thead className="sticky">
              <tr className="head sticky">
                <th
                  style={{
                    width: '51%',
                    backgroundColor:
                      theme.palette.mode === 'dark' &&
                      theme.palette.background.paper,
                    color: theme.palette.text.primary
                  }}
                  className="tableTh"
                >
                  <div className="ml-10">{I18N('title', language)}</div>
                </th>
                <th
                  style={{
                    width: '39%',
                    backgroundColor:
                      theme.palette.mode === 'dark' &&
                      theme.palette.background.paper,
                    color: theme.palette.text.primary
                  }}
                  className="tableTh"
                >
                  <div className="ml-10">{I18N('lastSeen', language)}</div>
                </th>
                <th
                  style={{
                    width: '50%',
                    paddingRight: 15,
                    backgroundColor:
                      theme.palette.mode === 'dark' &&
                      theme.palette.background.paper,
                    color: theme.palette.text.primary
                  }}
                  className="tableTh"
                >
                  <div className="ml-10">{I18N('reminder', language)}</div>
                </th>
              </tr>
            </thead>
            <ul className="ranking">
              {props.contentData.requiredUsers.length === 0 ? (
                <>
                  {userList.map((i) => {
                    let userInner = i;
                    const userData = watchList?.seen_videos.findIndex(
                      (u) => u.user_id === i.id
                    );
                    console.log(userData, userData.seen_at);
                    let notLength = 0;
                    if (userData !== -1) {
                      notLength = watchList?.seen_videos[
                        userData
                      ]?.users?.notifications.filter(
                        (noti) => noti.video_id === props.contentID
                      ).length;
                    }
                    console.log('notleng', notLength);
                    if (isNaN(Number(notLength))) {
                      notLength = 0;
                    }
                    console.log(notLength, 'tot notification upper');
                    return (
                      <li className="rankingBars" onClick={() => {}}>
                        <div className="videoTitle">
                          {returnUserData(userInner, i.id, showUserNames)}
                        </div>
                        <div
                          className="endFlex"
                          style={{
                            fontWeight: 'bold',
                            color:
                              userData !== -1
                                ? moment(
                                    watchList?.seen_videos[userData].seen_at
                                  ).isBefore(moment().subtract(1, 'year'))
                                  ? 'red'
                                  : 'black'
                                : 'black'
                          }}
                        >
                          {userData !== -1
                            ? moment(
                                watchList?.seen_videos[userData].seen_at
                              ).format('DD.MM.YYYY')
                            : '-'}
                        </div>
                        <div
                          className="endFlex"
                          style={{
                            alignContent: 'flex-end',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            justifySelf: 'flex-end'
                          }}
                        >
                          <img
                            src={NotiSend}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              sendNotification(props.contentData.id, i.id)
                            }
                          />{' '}
                          <strong className="notificationCounter">
                            {notLength}
                          </strong>
                        </div>
                      </li>
                    );
                  })}
                </>
              ) : (
                <>
                  {props.contentData.requiredUsers.map((i) => {
                    console.log(watchList?.seen_videos);
                    const userData = watchList?.seen_videos.find((a) => {
                      return a.user_id === i.id;
                    });
                    //console.log(i, userData)
                    let totalNotifications = [];
                    let userDataX = userList.find((a) => a.id === i.id);
                    if (userDataX) {
                      totalNotifications = userDataX.notifications.filter(
                        (noti) =>
                          noti.video_id === props.contentID &&
                          noti.fromAnalytics === 1
                      );
                    }
                    //console.log();
                    let userInner = userDataX;

                    // if(userData){
                    //     totalNotifications = userData.users.notifications.filter((noti) => noti.video_id === props.contentID);
                    //     if(totalNotifications.length > 0){
                    //         userInner = userList.find((u) => u.id === userData.user_id);
                    //     }
                    // }

                    return (
                      <li className="rankingBars" onClick={() => {}}>
                        <div className="videoTitle">
                          {userInner !== null && userInner !== undefined
                            ? `${
                                showUserNames && totalNotifications.length > 0
                                  ? `${userInner.firstName} ${userInner.lastName}`
                                  : i.id
                              }`
                            : i.id}
                        </div>
                        <div
                          className="endFlex"
                          style={{
                            fontWeight: 'bold',
                            color: moment(userData?.seen_at).isBefore(
                              moment().subtract(1, 'year')
                            )
                              ? 'red'
                              : 'black'
                          }}
                        >
                          {userData
                            ? moment(userData.seen_at).format('DD.MM.YYYY')
                            : '-'}
                        </div>
                        <div
                          className="endFlex"
                          style={{
                            alignContent: 'flex-end',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            justifySelf: 'flex-end'
                          }}
                        >
                          <img
                            src={NotiSend}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              sendNotification(props.contentData.id, i.id)
                            }
                          />{' '}
                          <strong className="notificationCounter">
                            {totalNotifications.length}
                          </strong>
                        </div>
                      </li>
                    );
                  })}
                </>
              )}
            </ul>
          </div>
        </ViewBox>
        <ViewBox title={I18N('departments', language)}>
          <ReactApexChart
            options={{
              chart: { type: 'polarArea' },
              colors: ColorsForChart(calculateRankingList().serie.length),
              stroke: {
                colors: ['#fff'],
                show: true,
                width: 3
              },
              fill: {
                opacity: 0.8
              },
              labels: calculateRankingList().label,
              xaxis: {
                tickAmount: 1
              },
              yaxis: {
                tickAmount: 1
              }
            }}
            series={calculateRankingList().serie}
            type="polarArea"
          />
        </ViewBox>
      </div>
    </div>
  );
}
