import {AbstractRepository} from "./abstract-repository";
import axios from "axios";

export class LogsRepository implements  AbstractRepository<any>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return Promise.resolve(undefined);
    }

    getAll(): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('/logger').then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }

    getSpecificClient(client: string, week, year): Promise<any> {
        return new Promise((resolve, reject) => {
            axios.get('/logger/'+client+`/${year}/${week}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })
        });
    }


    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: {
        type: string,
        message: string;
    }): Promise<any> {
        return new Promise((resolve, reject) => {

            axios.post('/logger', object).then((response) => {
                resolve(response);
            }).catch((err) => {
                reject(err);
            })
        })
    }

    update(object: any): Promise<any> {
        return Promise.resolve(undefined);
    }


}
