import {I18N} from "../../../i18n/i18n";
import TableMui from "../../../ViewComponents/TableMui";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../../States/LanguageState";
import {getToken} from "../../../Repositories/Cookies";
import {useLoginState} from "../../../States/UserState";
import ShowSecond from "../../../assets/Show2.svg";
import {PhishingRepository} from "../../../Repositories/PhishingRepository";
import ViewBox from "../../../ViewComponents/ViewBox";
import InfoBox from "./InfoBox";
import phishingInfoBookmark from '../../../assets/phishingInfoBookmark.svg';
import phishingInfoCalendar from '../../../assets/phishingInfoCalendar.svg';
import phishingInfoImage from '../../../assets/phishingInfoImage.svg';
import {useBreadCrumb} from "../../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import AnalyticsInner from "../../Analytics/AnalyticsInner";
import tableHeightCalculator from "../../../Helpers/TableHeightCalculator";

const phishingRepository = new PhishingRepository();

export default function OverViewTab(){

    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [campaigns, setCampaigns] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [requestUserID, setRequestUserId] = useState(null);
    const loginState = useLoginState();
    const clientName = getToken();
    const [orderType, setOrderType] = useState(null);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();
    const [isShown, setIsShown] = useState(false);
    const [calculatedData, setCalculatedData] = useState(null);
    const [counts, setCounts] = useState(null);

    const loadCounts = useCallback(async () => {
        const counts = await phishingRepository.getCounts();
        console.log(counts);
        setCounts(counts);
    }, []);

    useEffect(() => {

        loadCounts().then(undefined);

    }, [loadCounts]);

    useEffect(() => {

        if(!loginState.token.resource_access[clientName].roles.includes('realm-admin')){
            setRequestUserId(true);
        }else{
            setRequestUserId(false);
        }

    }, [clientName, loginState.token.resource_access]);

    const loadCampaigns = useCallback(async () => {

        if(requestUserID === null){
            return [];
        }
        setLoading(true);
        const camp = await phishingRepository.getAllCampaigns({filter: requestUserID ? {column: 'responsible_user_id', operator: 'equals', value: loginState.token.sub} : {column: 'execution_status', operator: "equals", value:1}, page: page, orderType, limit});
        setCampaigns(camp);
        setLoading(false);
    }, [limit, orderType, page, requestUserID]);


    useEffect(() => {

        loadCampaigns().then(undefined)

    }, [loadCampaigns]);

    const contentColumns = useCallback(() => {

        return [
            {
                field: 'designation',
                name: I18N("designation", language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.designation}</span>;
                }
            },
            {
                field: 'numberOfSentMessage',
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 90,
                name: I18N("numberOfSentMessage", language),
                renderCell: (params: any) => {
                    return (
                        params.row.numberOfContacts
                    )
                }
            },
            {
                field: 'clickRate',
                name: I18N('clickRate', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 80,
                renderCell: (params: any) => {
                    return (
                        (params.row.phishing_clicked.filter((item) => item.clicked_at !== '').length / params.row.numberOfContacts * 100).toFixed(0) + '%'
                    )
                }
            },
            {
                field: 'actionView',
                name: I18N('actionView', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 79,
                renderCell: (params: any) => {
                    return (
                        <div style={{cursor: "pointer", background: "#FFE16A", width: 40, height: 40, display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", borderRadius: 10}}>
                            <img src={ShowSecond} width={25} height={25} />
                        </div>
                    )
                }
            }
        ];

    }, []);



    const setBreadCrumb = useCallback(() => {

        breadCrumb.setChildren([]);
        breadCrumb.setBackButton(null);
        breadCrumb.setTitle(I18N('overView', language));
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('overView', language),
                onClick: () => history.push('/phishing/overview')
            }])


    }, [])
    useEffect(() => {
        setBreadCrumb();
    }, [setBreadCrumb]);

    return (
        <React.Fragment>


            {isShown ? (
                <AnalyticsInner exportData={() => {}} onBack={() => {
                    setIsShown(null);
                    setBreadCrumb();
                }} setCalculations={setCalculatedData} item={isShown} />
            ) : (

                <div className="flex-row">


                    <div style={{height: tableHeightCalculator(limit), width: '48%'}}>
                            <TableMui
                            page={page}
                            onPageChange={setPage}
                            pageSize={limit}
                            onPageSizeChange={setLimit}
                            isLoading={loading}
                            rowCount={campaigns?.total ?? 0}
                            onClick={(data) => {
                                setIsShown(data.row);
                            if(requestUserID){

                            //setActiveTab({index: 1, outIndex: 0});

                        }else{
                            //setActiveTab({index: 2, outIndex: 0});

                        }
                            //setShowMode(data.row);
                        }} data={campaigns?.data ?? []} columns={contentColumns()} />
                    </div>

                <div style={{width: "48%"}}>
                    <InfoBox title={I18N('plannedCampaigns', language)} icon={phishingInfoCalendar} info={counts?.plannedCampaigns} />
                    <InfoBox title={I18N('emailTemplatesAmount', language)} icon={phishingInfoBookmark} info={counts?.emailTemplates} />
                    <InfoBox title={I18N('landingPagesAmount', language)} icon={phishingInfoImage} info={counts?.landingPages} />
                </div>

                </div>


                )}



        </React.Fragment>
    )

}