import {Drawer,  TextField} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useTheme} from "@mui/material";
import {default as NoCreateSelect, OptionsType} from "react-select";
import {colorForSelect} from "../../../../ComplinessTheme";
import {I18N} from "../../../../i18n/i18n";
import FormButton from "../../../../ViewComponents/FormButton";
import {SidebarContainer, SidebarDrawer} from "../../../../ViewComponents/VideoEditComponents/Drawer";
import {useLanguageState} from "../../../../States/LanguageState";
import {PhishingRepository} from "../../../../Repositories/PhishingRepository";
import ViewBox from "../../../../ViewComponents/ViewBox";
import {DrawerConfigSx} from "../../../../ViewComponents/DrawerConfigSx";


const phishingRepository = new PhishingRepository();

const GeneralContentBar = (props: {isOpen: boolean, setIsOpen: (status: boolean) => void, content: any; setContent: (data) => void;}) => {
    const language = useLanguageState(state => state.language);
    const [loadingData, setLoadingData] = useState(false);
    const [formState, setFormState] = useState({designation: props.content.designation});

    const saveChanges = useCallback(() => {

        setLoadingData(true);

        phishingRepository.updateLandingGeneral({designation: formState.designation}, props.content.id).then((response) => {
            props.setContent(response);
            props.setIsOpen(false);
            setLoadingData(false);
        }).catch((e) => {
            console.log(e);
            setLoadingData(false);
        });

    }, [formState.designation, props]);



    return (
        <SidebarContainer>
            <Drawer BackdropProps={{invisible: true}} style={{background: 'rgba(255,255,255,0)'}} sx={DrawerConfigSx} anchor="right" open={props.isOpen} onClose={() => props.setIsOpen(false)}>
                <SidebarDrawer>
                    <div className="header">
                        <p>{I18N('changeDetails', language)}</p>
                        <FormButton withLoadingInFullScreen={true} icon="save" color="green" smallButton={true} text={I18N('save', language)} onClick={saveChanges} isLoading={loadingData} />
                    </div>
                    <ViewBox>
                        <div className="box"  style={{marginTop: 5}}>
                            <TextField
                                fullWidth
                                label={I18N('name', language)}
                                style={{ marginTop: 20 }}
                                value={formState.designation}
                                onChange={(text) => setFormState({...formState, designation: text.target.value})}
                            />

                        </div>
                    </ViewBox>

                </SidebarDrawer>
            </Drawer>
        </SidebarContainer>
    );
};

export default GeneralContentBar;
