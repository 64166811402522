import TableMui from "../../../ViewComponents/TableMui";
import React, {useCallback, useEffect, useState} from "react";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import {useLanguageState} from "../../../States/LanguageState";
import {I18N} from "../../../i18n/i18n";
import {PhishingRepository} from "../../../Repositories/PhishingRepository";
import EditLandingPage from "./EditLandingPage";
import {useBreadCrumb} from "../../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
import LandingOperation from "../../Templates/LandingOperation";
import tableHeightCalculator from "../../../Helpers/TableHeightCalculator";

const phishingRepository = new PhishingRepository();

export default function LandingPages(){
    const language = useLanguageState(state => state.language);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [createOpen, setCreateOpen] = useState(false);

    const [loading, setLoading] = useState(false);
    const [landingPages, setLandingPages] = useState(undefined);

    const [editMode, setEditMode] = useState(null);
    const breadCrumb = useBreadCrumb();
    const history = useHistory();


    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);


    const landingPageColumns = useCallback(() => {

        return [
            {
                field: 'designation',
                name: I18N("designation", language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.designation}</span>;
                }
            },
            // {
            //     field: 'urlPhishing',
            //     sortable: false,
            //     filterable: false,
            //     disableColumnMenu: true,
            //     name: I18N("urlPhishing", language),
            //     renderCell: (params: any) => {
            //         return (
            //             params.row.url
            //         )
            //     }
            // },
            // {
            //     field: 'senderAddress',
            //     name: I18N('senderAddress', language),
            //     sortable: false,
            //     filterable: false,
            //     disableColumnMenu: true,
            //     width: 250,
            //     renderCell: (params: any) => {
            //         return (
            //             params.row.senderAddress
            //         )
            //     }
            // },
        ];

    }, [language]);


    const loadData = useCallback(async () => {
        setLoading(true);
        const landingPageData = await phishingRepository.getAllLandingPages({limit, page, filter, orderType});
        setLandingPages(landingPageData);
        setLoading(false);
    }, [filter, limit, orderType, page]);


    useEffect(() => {
        loadData().then(undefined);
    }, [loadData])


    useEffect(() => {
        crumbData();
    }, []);


    const crumbData = useCallback(() => {

        breadCrumb.setChildren([
            {
                color: "dark",
                onClick: () => {
                    setCreateOpen(true)
                },
                icon: "plus",
                title: I18N('create', language),
            }
        ]);
        breadCrumb.setBackButton(() => history.push('/phishing/overview'));
        breadCrumb.setTitle(I18N('landingPages', language));
        breadCrumb.setCrumb([
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/phishing/overview')
            },{
                title: I18N('landingPages', language),
                onClick: () => history.push('/phishing/landingpages')
            }])


    }, [])

    return (
        <>
            {createOpen ? (
                <LandingOperation closeCreate={() => {
                    setCreateOpen(false);
                    crumbData();
                    loadData();
                }} editMode={null} fromModal={false} disableAll={false} />
            ): (
                <>
                    {editMode ? (
                        <EditLandingPage closeEditMode={() => {
                            setCreateOpen(false);
                            setEditMode(null);
                            crumbData();
                            loadData();
                        }} emailTemplate={editMode} />
                    ) : (
                        <div style={{height: tableHeightCalculator(limit)}}><TableMui
                            onSortModelChange={onSortModelChange}
                            isLoading={loading}
                            pageSize={limit}
                            rowCount={landingPages?.total}
                            onFilterModeChange={onFilterModeChange}
                            onPageSizeChange={(size) => setLimit(size)}
                            onPageChange={(page) => setPage(page + 1)}
                            key="tableLanding" data={landingPages?.data !== undefined ? landingPages.data : []} columns={landingPageColumns()} onClick={(data) => {
                            setEditMode(data.row);
                        }} /></div>
                    )}
                </>
            )}

        </>

    )

}