import React from "react";
import { useLanguageState } from "../States/LanguageState";
import { I18N } from "../i18n/i18n";
import { Backdrop, CircularProgress } from "@mui/material";
import { standartColors } from "../assets/colors/Colors";

interface Props {
    text: string;
    onClick: () => void;
    isLoading: boolean;
    withLoadingInFullScreen?: boolean;
    isLeft?: boolean;
    disabled?: boolean;
    buttonClass: string;
    isSmaller?: boolean
}

export default function FullButton(props: Props) {

    const language = useLanguageState(state => state.language);

    return (
        <>

            <div className="buttonHolder" style={{justifyContent: props.isLeft ? 'flex-start' : 'flex-end', width: props.isSmaller ? 'auto' : '100%', marginRight: props.isSmaller ? '10px' : 0}} >
                <div className={`buttonStyle ${props.disabled && 'isDisabled'} ${props.buttonClass}`} onClick={!props.disabled && props.isLoading ? undefined : props.onClick}>
                        <React.Fragment>
                            {props.isLoading ? I18N('loading', language) : props.text}
                        </React.Fragment>
                </div>
            </div >

            <Backdrop
                open={(props.isLoading && props.withLoadingInFullScreen)}
                style={{ color: standartColors.primaryYellow, zIndex: 1}}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )

}
