import {AbstractRepository} from "./abstract-repository";
import axios from "axios";
import {PaginationRequestType, PaginationTypes} from "../Types/PaginationTypes";
import {LearningPlanType} from "../Types/LearningPlanTypes";

export class LearningPlanRepository implements AbstractRepository<PaginationTypes<LearningPlanType> | LearningPlanType[]>{
    buildObject(a: any, b: any, c: any, d: any, e: any, f: any, g: any, h: any, i: any, j: any, k: any, l: any, m: any): any {
    }

    delete(id: number | string): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.delete('/learningplan/' + id).then((response) => {
                return resolve(response.data)
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    getAll(pagination?: PaginationRequestType): Promise<PaginationTypes<LearningPlanType> | LearningPlanType[]> {

        return new Promise((resolve, reject) => {
            const orderType = pagination !== undefined ? pagination.orderType !== null ? `&column=${pagination.orderType.column}&type=${pagination.orderType.type}` : '' : '';
            const filterType = pagination !== undefined ? pagination.filter !== null ? `&filterColumn=${pagination.filter.column}&filterOperator=${pagination.filter.operator}&filterValue=${pagination.filter.value}` : '' : '';
            axios.get(`/learningplan${pagination !== undefined ? `?page=${pagination.page}&limit=${pagination.limit}${orderType}${filterType}` : ''}`).then((response) => {
                return resolve(response.data);
            }).catch((error) => {
                return reject(error);
            })

        });

    }

    getUrl(): string {
        return "";
    }

    getWithID(id: number): Promise<any> {
        return Promise.resolve(undefined);
    }

    post(object: any): Promise<any> {
        return new Promise((resolve, reject) => {
            return axios.post('/learningplan', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    //@ts-ignore
    update(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/learningplan/' + id, object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }

    updateStep(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/learningplan/' + id + '/step', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }


    updateGeneralInfo(object: any, id: number): Promise<any> {
        return new Promise((resolve, reject) => {

            return axios.patch('/learningplan/' + id + '/general', object).then((response) => {
                return resolve(response.data);
            }).catch((err) => {
                return reject(err);
            })

        });
    }



}
