import WizardBox from "../WizardBox";
import {useCallback, useRef, useState} from "react";
import {useLanguageState} from "../../States/LanguageState";
import {I18N} from "../../i18n/i18n";
import CompanyStep from "./Components/CompanyStep";
import DepartmentStep from "./Components/DepartmentStep";
import UsersStep from "./Components/UsersStep";
import FinishFachStep from "./Components/FinishFachStep";
import CategoriesStep from "../TechWizard/Components/CategoriesStep";
import TrophiesStep from "../TechWizard/Components/TrophiesStep";
import LearningContentStep from "../TechWizard/Components/LearningContentStep";
import LearningPlanStep from "../TechWizard/Components/LearningPlantStep";

export default function FachWizardMain(props: {isOpen: boolean, setIsOpen: (status: boolean) => void;}) {

    const language = useLanguageState(state => state.language);
    const [activeStep, setActiveStep] = useState(0);
    const categoriesRef = useRef(null);
    const learningContentRef = useRef(null);
    const trophiesRef = useRef(null);
    const learningPlanRef = useRef(null);



    const onClearData = useCallback(() => {

        switch (activeStep){
            case 1: {
                if(categoriesRef.current !== null){
                    categoriesRef.current.click();
                }
                return;
            }
            case 2: {
                if(trophiesRef.current !== null){
                    trophiesRef.current.click();
                }
                return;
            }
            case 3: {
                if(learningContentRef.current !== null){
                    learningContentRef.current.click();
                }
                return;
            }
            case 4: {
                if(learningPlanRef.current !== null){
                    learningPlanRef.current.click();
                }
                return;
            }
            default: {
                return '';
            }
        }



    }, [activeStep]);

    const steps = [
        {welcomeStep: true, stepDescription: I18N("welcomeFachDescription", language)},
        {title: I18N('categoriesStep', language), stepDescription: I18N('categoriesStepDescription', language), stepInnerComponent: <CategoriesStep ref={categoriesRef} title={I18N('categoriesStep', language)} />},
        {title: I18N('trophiesStep', language), stepDescription: I18N('trophiesStepDescription', language), stepInnerComponent: <TrophiesStep ref={trophiesRef} title={I18N('trophiesStep', language)} />},
        {title: I18N('learningContentStep', language), stepDescription: I18N('learningContentStepDescription', language), stepInnerComponent: <LearningContentStep ref={learningContentRef} title={I18N('learningContentStep', language)} />},
        {title: I18N('learningPlanStep', language), stepDescription: I18N('learningPlanStepDescription', language), stepInnerComponent: <LearningPlanStep ref={learningPlanRef} title={I18N('learningPlanStep', language)} />},
        {finishStep: true, stepDescription: I18N('finishFachDescription', language), stepInnerComponent: <FinishFachStep />}
    ]

    return (
        <WizardBox sendActiveStep={setActiveStep} onClearData={onClearData} steps={steps} open={props.isOpen} setOpen={props.setIsOpen} />
    )

}