import ViewBoxTab from "../../ViewComponents/ViewBoxTab";
import {I18N} from "../../i18n/i18n";
import TableMui from "../../ViewComponents/TableMui";
import React, {useCallback, useEffect, useState} from "react";
import {useLanguageState} from "../../States/LanguageState";
import {GridFilterModel, GridSortModel} from "@mui/x-data-grid";
import FormButton from "../../ViewComponents/FormButton";
import {PhishingRepository} from "../../Repositories/PhishingRepository";
import TrashIcon from '../../assets/trash.svg';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import AnalyticsInner from "./AnalyticsInner";
import {useLoginState} from "../../States/UserState";
import {getToken} from "../../Repositories/Cookies";
import {useBreadCrumb} from "../../States/BreadCrumbState";
import {useHistory} from "react-router-dom";
const sweetAlert = withReactContent(Swal)
const phishingRepository = new PhishingRepository();

type Props = {
    backHome: () => void, showMode: any, clearShowMode: () => void;
}

export default function  AnalyticsHome(props: Props){
    const language = useLanguageState(state => state.language);
    const [loading, setLoading] = useState(false);
    const [limit, setLimit] = useState<number>(10);
    const [showMode, setShowMode] = useState(null);
    const [campaigns, setCampaigns] = useState<any>({});
    const [campaignsArchived, setCampaignsArchived] = useState<any>({});
    const [campaignsAll, setCampaignsAll] = useState<any>({});
    const [page, setPage] = useState<number>(1);
    const [filter, setFilter] = useState(null);
    const [orderType, setOrderType] = useState(null);
    const [calculatedData, setCalculatedData] = useState<any>(null);
    const loginState = useLoginState();
    const clientName = getToken();
    const [requestedUserId, setRequestedUserId] = useState(null);
    const companyList = useLoginState(state => state.company)

    const breadCrumb = useBreadCrumb();
    const history = useHistory();

    const loadBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('phishing', language),
                onClick: () => history.push('/contentAnalytics/phishing')
            }
        ]);

        breadCrumb.setTitle(I18N('phishing', language));
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, [loading]);
    useEffect(() => {
        loadBreadCrumb();
    }, []);


    useEffect(() => {

        if(!loginState.token.resource_access[clientName].roles.includes('realm-admin')){
            setRequestedUserId(true);
        }else{
            setRequestedUserId(false);
        }

    }, [clientName, loginState.token.resource_access]);

    useEffect(() => {

        if(props.showMode !== null){
            setShowMode(props.showMode);
            props.clearShowMode();
        }

    }, [props, props.showMode])

    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'red';
        }else if(percent >= 50) {
            return 'orange'
        }else if(percent >= 25){
            return 'lightOrange';
        }else if(percent >= 10){
            return 'lightGreen';
        }else if(isNaN(percent)){
            return ''
        } else {
            return 'green'
        }


    }, []);


    const loadCampaigns = useCallback(async (active?: boolean) => {
        if(requestedUserId === null){
            return [];
        }
        setLoading(true);
        const camp = await phishingRepository.getAllCampaigns({page: page, filter: requestedUserId ? {column: 'responsible_user_id', operator: 'equals', value: loginState.token.sub} : filter, limit: limit, orderType: orderType, active: active});
        if(active === true){
            setCampaigns(camp)
        }else if(active === false){
            setCampaignsArchived(camp);
        }else{
            setCampaignsAll(camp);
        }
        setLoading(false);
    }, [filter, limit, loginState.token.sub, orderType, page, requestedUserId]);

    useEffect(() => {
        loadCampaigns(true).then(undefined);
        loadCampaigns(false).then(undefined);
        loadCampaigns(undefined).then(undefined)
    }, [loadCampaigns]);

    const colorBar = useCallback((width: number) => {

        return (
            <div style={{width: '100%', backgroundColor: 'rgb(242, 242, 242)', borderRadius: 5}}>
                <div className={`rankingBar ${getColor(width)}`} style={{width: width + '%'}} />
            </div>

            )

    }, [getColor]);


    const askForArchive = useCallback(async (row) => {

        await sweetAlert.fire({
            showCloseButton: true,
            reverseButtons: true,
            confirmButtonColor: "#F86D70",
            cancelButtonColor: "#F2F2F2",
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: I18N('archive', language),
            cancelButtonText: <p style={{color: "#000", padding: 0, margin: 0}}>{I18N('no', language)}</p>,
            title: I18N('archiveQuestion', language),
        }).then(async (response) => {
            if(response.isConfirmed){
                await archiveCampaign(row);
            }
        })


    }, [language]);

    const archiveCampaign = useCallback(async (row) => {
        setLoading(true);
        await phishingRepository.archiveCampaign(row.id);
        setLoading(false);
        await loadCampaigns(true);
        await loadCampaigns(false);
        await loadCampaigns(undefined);

    }, [loadCampaigns]);

    const campaignColumns = useCallback(() => {

        return [
            {
                field: 'campaign',
                name: I18N("campaign", language),
                sortable: true,
                filterable: true,
                disableColumnMenu: false,
                valueGetter: (params) => {
                    return params.row.designation
                },
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.designation}</span>;
                }
            },
            {
                field: 'numberOfContacts',
                sortable: true,
                filterable: true,
                disableColumnMenu: false,
                valueGetter: (params) => {
                    return params.row.numberOfContacts
                },
                name: I18N("numberOfContacts", language),
                renderCell: (params: any) => {
                    return (
                        params.row.numberOfContacts
                    )
                }
            },
            {
                field: 'execution',
                name: I18N('executionP', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    return params.row.execution_date
                },
                renderCell: (params: any) => {
                    return (
                        params.row.execution_date
                    )
                }
            },
            {
                field: 'openRate',
                name: I18N('openRate', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                valueGetter: (params) => {
                    const clickCalculation = (params.row.phishing_clicked.length / params.row.numberOfContacts * 100);
                    return isNaN(clickCalculation) ? 0 : clickCalculation
                },
                renderCell: (params: any) => {
                    const clickCalculation = (params.row.phishing_clicked.length / params.row.numberOfContacts * 100);
                    return (
                        colorBar(isNaN(clickCalculation) ? 0 : clickCalculation)
                    )
                }
            },
            {
                field: 'clickRate',
                name: I18N('clickRate', language),
                sortable: true,
                filterable: false,
                disableColumnMenu: false,
                valueGetter: (params) => {
                    const clickCalculation = (params.row.phishing_clicked.filter((i) => i.clicked_at !== '').length / params.row.numberOfContacts * 100);
                    return (
                       isNaN(clickCalculation) ? 0 : clickCalculation
                    )
                },
                width: 250,
                renderCell: (params: any) => {
                    const clickCalculation = (params.row.phishing_clicked.filter((i) => i.clicked_at !== '').length / params.row.numberOfContacts * 100);
                    return (
                        colorBar(isNaN(clickCalculation) ? 0 : clickCalculation)
                    )
                }
            },
            {
                field: 'actions',
                name: I18N('actionsP', language),
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                width: 75,
                renderCell: (params: any) => {
                    return (
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center"}}>
                            <img onClick={() => askForArchive(params.row)} src={TrashIcon} width={25} height={25} />
                        </div>
                    )
                }
            }
        ];

    }, [language]);

    const onSortModelChange = useCallback((mode: GridSortModel) => {

        if(mode.length > 0){
            setOrderType({
                column: mode[0].field,
                type: mode[0].sort
            })
        }else{
            setOrderType(null);
        }

    }, []);

    const onFilterModeChange = useCallback((mode: GridFilterModel) => {

        if(mode.items.length > 0){
            setFilter({
                column: mode.items[0].columnField,
                value: mode.items[0].value,
                operator: mode.items[0].operatorValue
            })
        }else{
            setFilter(null);
        }

    }, []);


    const exportData = useCallback(async () => {


        setLoading(true);
        await phishingRepository.getPDF({...calculatedData}, showMode.designation, !(companyList.length > 1));
        setLoading(false);

    }, [calculatedData, showMode, companyList]);

    return (
        <div>
            {showMode === null ? (
                <div style={{height: '100%', width: '100%', display: 'flex',  flexDirection: 'column'}}>
                    <ViewBoxTab tabs={[{
                        name: I18N('active', language),
                        content: (<div style={{height: 700}}><TableMui
                            sortingMode={"client"}
                            filterMode={"client"}
                            isLoading={loading}
                            pageSize={limit}
                            rowCount={campaigns?.total}
                            onPageSizeChange={(size) => setLimit(size)}
                            onPageChange={(page) => setPage(page + 1)}
                            data={campaigns?.data?.filter((item) => item.status === 1) ?? []} key="tabsCampaignFirst" onClick={(data) => {
                            setShowMode(data.row);
                        }} columns={campaignColumns()} /></div>)
                    },{
                        name: I18N('archived', language),
                        content: (<div style={{height: 700}}><TableMui
                            sortingMode={"client"}
                            filterMode={"client"}
                            isLoading={loading}
                            pageSize={limit}
                            rowCount={campaignsArchived?.total}
                            onPageSizeChange={(size) => setLimit(size)}
                            onPageChange={(page) => setPage(page + 1)}
                            data={campaignsArchived?.data ?? []}
                            columns={campaignColumns()} key={'tableCampaignFinished'} onClick={(data) => {
                            setShowMode(data.row);
                        }} /></div>)
                    },
                        {
                            name: I18N('all', language),
                            content: (<div style={{height: 700}}><TableMui
                                isLoading={loading}
                                sortingMode={"client"}
                                filterMode={"client"}
                                pageSize={limit}
                                rowCount={campaignsAll?.total}
                                onPageSizeChange={(size) => setLimit(size)}
                                onPageChange={(page) => setPage(page + 1)}
                                data={campaignsAll?.data ?? []}
                                key="tableLandingAlle"  columns={campaignColumns()} onClick={(data) => {
                                setShowMode(data.row);
                            }} /></div>)
                        }]} onTabChange={() => {}} />
                </div>
            ): (
                <AnalyticsInner exportData={exportData} onBack={() => {
                    setCalculatedData(null);
                    setShowMode(null);
                    loadBreadCrumb();
                }} setCalculations={setCalculatedData} item={showMode} />
            )}


        </div>

    )
}
