import {BACKEND_URL} from "../assets/AppConstants";
import React from "react";

export default function EGRCItemHolder(props: {openVideo: any, content: any}){

    return (
        <div onClick={() => props.openVideo(props.content)} key={props.content.id} className="contentInner">
            <p className="content-text">{props.content.name}</p>
            <img  className="content-picture" src={BACKEND_URL + props.content.image} alt={props.content.title} />
        </div>
    )

}