import { I18N } from "../i18n/i18n";
import React, {useCallback, useEffect, useState} from "react";
import { useLanguageState } from "../States/LanguageState";
import FormButton from "../ViewComponents/FormButton";
import moment from "moment/moment";
import TableMui from "../ViewComponents/TableMui";
import {QuizRepository} from "../Repositories/QuizRepository";
import QuizSingleAnalytics from './QuizSingleAnalytics';
import {useBreadCrumb} from "../States/BreadCrumbState";
import {useHistory} from "react-router-dom";

const quizRepository = new QuizRepository();

export default function QuizAnalytics(props: {
    backHome: () => void;
}) {

    const language = useLanguageState(state => state.language);
    const [activePage, setActivePage] = useState<'content' | 'quizInner'>('content');
    const [activeContent, setActiveContent] = useState(null);
    const [loading, setLoading] = useState(false);
    const [quizContent, setQuizContent] = useState<any>([]);

    const breadCrumb = useBreadCrumb();
    const history = useHistory();

    const loadBreadCrumb = useCallback(() => {

        breadCrumb.setCrumb([
            {
                title: I18N('analytics', language),
                onClick: undefined
            },
            {
                title: I18N('quiz', language),
                onClick: () => history.push('/contentAnalytics/quiz')
            }
        ]);

        breadCrumb.setTitle(I18N('quiz', language));
        breadCrumb.setBackButton(null);
        breadCrumb.setChildren([{
            title: '',
            isLoading: loading,
            isFullLoading: true,
            color: "transparent",
            onClick: undefined
        }]);

    }, [loading]);

    useEffect(() => {

        loadBreadCrumb();

    }, [loadBreadCrumb]);


    const loadData = useCallback(async () => {
        setLoading(true);
        const quiz = await quizRepository.getAll();
        setQuizContent(quiz);
        setLoading(false);
    }, []);

    useEffect(() => {
        loadData().then(undefined);
    }, []);



    const calculateData = useCallback(() => {

        let calculatedData = [];

        if(quizContent.length < 1){
            return [];
        }


        quizContent.forEach((content) => {
            const hundred = content.seen_videos.length === 0 ? 100 : content.seen_videos.length;
            const oldWatches = content.seen_videos.filter((seenVideo) => {
                if(moment(seenVideo.seen_at).isBefore(moment().subtract(1, "year"))){
                    return true;
                }
            });
            const percentage = content.seen_videos.length / hundred * 100;
            let type = I18N('notAssigned', language);
            if(content.seen_videos.length > 0){
                type = content.seen_videos[0].videos.type === 4 ? I18N('standalone', language) : I18N('withLearnContent', language);
            }



            calculatedData.push({ ...content, type: type, percentage: percentage, requiredFor: quizContent.length, totalWatch: content.seen_videos.length, oldWatches: oldWatches.length });

        });



        return calculatedData;

    }, [quizContent, language]);

    useEffect(() => {
        window.scrollTo({
            behavior: "smooth",
            top: 0
        });
    }, [activePage]);

    const getColor = useCallback((percent: number) => {

        if(percent >= 75){
            return 'green';
        }else if(percent >= 50) {
            return 'lightGreen'
        }else if(percent >= 25){
            return 'orange';
        }else if(percent >= 10){
            return 'lightOrange';
        }else if(isNaN(percent)){
            return ''
        }else {
            return 'red'
        }


    }, []);


    const contentColumns = useCallback(() => {

        return [
            {
                field: 'name',
                name: I18N("name", language),
                sortable: true,
                filterable: true,
                disableColumnMenu: false,
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.name}</span>;
                }
            },
            {
                field: 'type',
                name: I18N("type", language),
                sortable: true,
                filterable: true,
                disableColumnMenu: false,
                valueGetter: (params) => {
                    return params.row.type
                },
                renderCell: (params: any) => {
                    return <span style={{fontWeight: 'bold'}}>{params.row.type}</span>;
                }
            },
            {
                field: 'Gesamt gesehen',
                sortable: true,
                filterable: false,
                disableColumnMenu: true,
                valueGetter: (params) => {
                    return params.row.totalWatch
                },
                name: I18N("totalSeen", language),
                renderCell: (params: any) => {
                    return (
                        <div className="endFlex">
                            <div className="rankingText">{params.row.totalWatch} / {params.row.requiredFor} ({(params.row.totalWatch / params.row.requiredFor * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.totalWatch / params.row.requiredFor * 100))}`} style={{width: (params.row.totalWatch / params.row.requiredFor * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
            {
                field: 'Gesehen letzte 12 Monate',
                name: I18N('totalSeenLast12', language),
                sortable: true,
                valueGetter: (params) => {
                    return params.row.oldWatches
                },
                filterable: false,
                disableColumnMenu: true,
                width: 250,
                renderCell: (params: any) => {
                    return (
                        <div className={"endFlex"}>
                            <div className="rankingText">{params.row.oldWatches} / {params.row.totalWatch} ({isNaN((params.row.oldWatches / params.row.totalWatch * 100)) ? '0' : (params.row.oldWatches / params.row.totalWatch * 100).toFixed(0)}%)</div>
                            <div className={`rankingBar ${getColor((params.row.oldWatches / params.row.totalWatch * 100))}`} style={{width: (params.row.oldWatches / params.row.totalWatch * 100) + '%'}}>
                            </div>
                        </div>
                    )
                }
            },
        ];

    }, [getColor, language]);

    const views = useCallback(() => {

        switch (activePage) {
            case "content": {
                return (
                    <React.Fragment>
                        <div style={{height: 670}}>
                            <TableMui  sortingMode={"client"} filterMode={"client"} onClick={(data) => {
                                setActiveContent(data.row);
                                setActivePage('quizInner');
                            }} data={calculateData()} columns={contentColumns()} />
                        </div>
                    </React.Fragment>
                )
            }
            case "quizInner" : {
                return (
                    <QuizSingleAnalytics onClick={() => {
                        setActivePage('content');
                        setActiveContent(null);
                        loadBreadCrumb();
                    }} quiz={activeContent} />
                )
            }
        }

    }, [activePage, language, activeContent, calculateData]);

    return (
        <div style={{marginTop: activePage !== 'content' ? 0 : 30}}>
            {views()}
        </div>
    )
}
