import create from 'zustand';

type CurrentlyUploadingProps = {
    uploading: any[] ,
    setUploading: (uploading: FileList) => void;
    updateProgress: (file: File, progress: number, loaded: number, finished?: boolean) => void;
    setInUploadingPage: (inUploadingPage: boolean) => void;
    inUploadingPage: boolean;
    removeFromList: (index: number) => void;
};

export const useUploadingState = create<CurrentlyUploadingProps>(set => ({
    uploading: null,
    inUploadingPage: false,
    setInUploadingPage: (inUploadingPage) => set({inUploadingPage}),
    removeFromList: (index: number) => {
        set((prev) => {
            const state = prev.uploading;
            state.splice(index, 1);
           return ({uploading: [...state]})
        });
    },
    updateProgress: (file: File, progress: number, loaded: number, finished) => {
       set((prev) => {
           const state = prev.uploading;
           if(prev.uploading !== null){
               const findFile = state.findIndex((current) => current.name === file.name);
               if(findFile !== -1){
                   state[findFile].progress = progress;
                   if(finished !== undefined){
                      state[findFile].finished = true;
                   }else{
                       state[findFile].loaded = loaded;
                   }

               }
               return ({uploading: [...state]});
           }
       })
    },
    setUploading: (uploading: FileList) => {
        set((prev) => {
            const state = prev.uploading;
            let itemList = [];
            if(state !== null){
                itemList = state;
                Object.keys(uploading).forEach((fileKey) => {
                    itemList.push(uploading.item(Number(fileKey)));
                });
            }else{
                Object.keys(uploading).forEach((fileKey) => {
                    itemList.push(uploading.item(Number(fileKey)));
                });
            }
           return ({uploading: itemList});
        });


    }
}));
