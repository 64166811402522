export function calculateContentProgress(contentData) {
    let progress = 0;
        const data = JSON.parse(JSON.parse(contentData.data));
        if(data.points !== undefined){
            if(data.points.score !== null && data.points.max !== null){
                progress = data.points.score * 100 / data.points.max;
            }
        }else if(data.currentPage !== undefined){
            progress = data.currentPage * 100 / data.totalPage;
        }else if(data.playedSeconds !== undefined){
            progress = data.played * 100;
        }else {
            progress = 0;
        }

        console.log(progress);
        return Math.ceil(progress);
}
