import {I18N} from "../../i18n/i18n";
import ViewBox from "../ViewBox";
import React, {useEffect, useState} from "react";
import {useLanguageState} from "../../States/LanguageState";
import TrophyCircle from "./TrophyCircle";
import RightButton from '../../assets/rightFilled.svg'
import RightDarkMode from '../../assets/rightDarkMode.svg'

import {TrophyCalculator} from "../../Helpers/TrophyCalculator";
import {useTheme} from "@mui/material";

export default function TrophyCircles(props: {
    contentData: any;
    from?: string
}){

    const language = useLanguageState(state => state.language);
    const [trophies, setTrophies] = useState([]);
    const theme = useTheme();
    useEffect(() => {

        if(props.contentData.length < 1){
            return;
        }
        const trophyList = TrophyCalculator(props);
        setTrophies(trophyList.sort((a,b) => b.awarded ? -1 : 1));

    }, [props.contentData]);

    return (
        <ViewBox width={70} customClass={props.from !== undefined ? "viewBox fromMy" : undefined} title={I18N('trophies', language)} rightComponent={props.from === undefined && (
            <a style={{textDecoration: "none", color: theme.palette.mode === 'dark' ? '#fff' : "#000", fontWeight: "bold", position: "relative"}} href="/Profile#trophies"><p style={{margin: 0, padding: 0, marginRight: 25}}>{I18N("showAll", language)}</p> <img style={{position: "absolute", right: 0, top: 5}} src={theme.palette.mode === 'dark' ? RightDarkMode : RightButton} /></a>
        )}>

            <div className={`trophyListHolder ${props.from !== undefined && 'fromMyHolder'}`}>
                {trophies.slice(0,3).map((trophy) => <TrophyCircle trophy={trophy} />)}
            </div>
            <div className="clearfix" />
        </ViewBox>
    )

}
