import {I18N} from "../i18n/i18n";

export function ClassificationDecider(status: number, language){

    switch (status){
        case 1: {
            return I18N('confidential', language)
        }
        case 2: {
            return I18N('public', language)
        }
        case 3: {
            return I18N('internal', language);
        }
        case 4: {
            return I18N('strictlyConfidential', language)
        }


    }

}


export function StatusDecider(status: number, language){

    switch (status){
        case 1: {
            return I18N('published', language)
        }
        case 2: {
            return I18N("completed", language)
        }
        case 3: {
            return I18N("archived", language)
        }
        case 4: {
            return I18N("inWork", language)
        }
    }


}


export function StatusDeciderColor(status: number){

    switch (status){
        case 1: {
            return "#27AE60"
        }
        case 2: {
            return "#7CB4FF"
        }
        case 3: {
            return "#F86D70"
        }
    }

}
