import React, {useCallback, useEffect, useRef, useState } from 'react';
import { ProfileRepository } from "../../../Repositories/ProfileRepository";
import { useLoginState } from "../../../States/UserState";
import { I18N } from "../../../i18n/i18n";
import { useLanguageState } from "../../../States/LanguageState";
import ViewBox from "../../../ViewComponents/ViewBox";
import {UsersRepository} from "../../../Repositories/UsersRepository";
import {getToken} from "../../../Repositories/Cookies";
import moment from "moment/moment";
import passwordSuggest from '../../../assets/passwordSuggest.svg';
import {Link} from "react-router-dom";
import PersonalInfoBar from "../Bars/PersonalInfoBar";
import EditIcon from "../../../ViewComponents/EditIcon";
import ChangePasswordBar from "../Bars/ChangePasswordBar";
import axios from "axios";
import ChangeAutoLogout from "../Bars/ChangeAutoLogout";
type Props = {
}
const userRepository = new UsersRepository();

type State = {
    username: string,
    newPassword: string,
    newPasswordRepeat: string,
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    showPassword: boolean,
    showPasswordOld: boolean;
    image: string,
    autoLogout: 1 | 0;
    autoLogoutTimer: number;

    allowEmails: boolean;
}

export function ProfileTab(props: Props) {

    const language = useLanguageState();
    const clientName = getToken();
    const loginState = useLoginState();
    const [userData, setUserData] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [isPersonalOpen, setIsPersonalOpen] = useState(false);
    const [isPasswordOpen, setIsPasswordOpen] = useState(false);
    const [isAutoLogoutOpen, setIsAutoLogoutOpen] = useState(false);


    console.log(loginState.data?.innerData);




    const getUserDefaults = useCallback((key: string): string => {

        if (userData === null) {
            return key;
        } else {
            return userData[key]
        }

    }, [userData]);

    const [userState, setUserState] = useState<State>({
        username: getUserDefaults('preferred_username'),
        newPassword: '',
        newPasswordRepeat: '',
        firstName: getUserDefaults('given_name'),
        lastName: getUserDefaults('family_name'),
        phone: loginState.phone,
        email: getUserDefaults('email'),
        showPassword: false,
        showPasswordOld: false,
        image: loginState.photo,
        autoLogoutTimer: loginState.data.innerData.autoLogoutTimer,
        autoLogout: loginState.data.innerData.autoLogout,
        allowEmails: loginState.data.innerData.allowEmails
    });


    const getUserData = useCallback(async () => {

        await userRepository.getUserInfo().then((response) => {
            setUserData(response);
        });


    }, [userState]);

    const getDataConfirm = useCallback(async () => {

        await axios.get('/users/me').then((re) => {
            console.log();
            setUserState({
                ...userState,
                phone: re.data.innerData.phone,
                image: re.data.innerData.photo,
                autoLogoutTimer: re.data.innerData.autoLogoutTimer,
                autoLogout: re.data.innerData.autoLogout,
                allowEmails: re.data.innerData.allowEmails
            });
            loginState.setPhone(re.data.innerData.phone);
            loginState.setPhoto(re.data.innerData.photo)
        }).catch((e) => console.log(e));

    }, [userState]);

    useEffect(() => {

        getUserData().then(undefined)


    }, []);



    useEffect(() => {

        setIsEditable((loginState.token !== null && loginState.token !== undefined && loginState.token.resource_access[clientName].roles.includes('EditOwnProfile')))

    }, [loginState, clientName]);

    useEffect(() => {

        setUserState({
            username: getUserDefaults('preferred_username').split('_')[0],
            newPassword: '',
            newPasswordRepeat: '',
            firstName: getUserDefaults('given_name'),
            lastName: getUserDefaults('family_name'),
            phone: loginState.phone,
            email: getUserDefaults('email'),
            showPassword: false,
            showPasswordOld: false,
            image: loginState.photo,
            autoLogout: loginState.data.innerData.autoLogout,
            autoLogoutTimer: loginState.data.innerData.autoLogoutTimer,
            allowEmails: loginState.data.innerData.allowEmails
        })

    }, [getUserDefaults, loginState.phone, loginState.photo]);


    const updateUser = useCallback(async () => {

        console.log('refresh');
        await getUserData().then(undefined);
        await getDataConfirm().then(undefined);


    }, [getUserData, getDataConfirm]);

    return (

        <React.Fragment>
            <PersonalInfoBar isOpen={isPersonalOpen} setIsOpen={setIsPersonalOpen} userInfo={userState} onUpdate={updateUser} />
            <ChangePasswordBar isOpen={isPasswordOpen} setIsOpen={setIsPasswordOpen} userInfo={userState} onUpdate={updateUser} />
            <ChangeAutoLogout isOpen={isAutoLogoutOpen} setIsOpen={setIsAutoLogoutOpen} userInfo={userState} onUpdate={updateUser} />
            <div className="flex-row">
                <ViewBox rightComponent={(<EditIcon onClick={() => setIsPersonalOpen(true)} />)} title={I18N("personalInformation", language.language)}>
                    <table>
                        <th>
                            <td style={{width: 150}}>

                            </td>
                        </th>
                        <tr >
                            <td style={{fontSize: 12, color: '#3E4649'}}>
                                {I18N('username', language.language)}
                            </td>
                            <td style={{fontSize: 14, fontWeight: "bold", color: "#3E4649"}}>
                                {userState.username}
                            </td>
                        </tr>
                        <tr >
                            <td style={{fontSize: 12, color: '#3E4649'}}>
                                {I18N('firstName', language.language)}
                            </td>
                            <td style={{fontSize: 14, fontWeight: "bold", color: "#3E4649"}}>
                                {userState.firstName}
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, color: '#3E4649'}}>
                                {I18N('lastName', language.language)}
                            </td>
                            <td style={{fontSize: 14, fontWeight: "bold", color: "#3E4649"}}>
                                {userState.lastName}
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, color: '#3E4649'}}>
                                {I18N('email', language.language)}
                            </td>
                            <td style={{fontSize: 14, fontWeight: "bold", color: "#3E4649"}}>
                                {userState.email}
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, color: '#3E4649'}}>
                                {I18N('phone', language.language)}
                            </td>
                            <td style={{fontSize: 14, fontWeight: "bold", color: "#3E4649"}}>
                                {userState.phone}
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, color: '#3E4649'}}>
                                {I18N('allowEmails', language.language)}
                            </td>
                            <td style={{fontSize: 14, fontWeight: "bold", color: "#3E4649"}}>
                                {userState.allowEmails ? I18N("active", language.language) : I18N("inActive", language.language)}
                            </td>
                        </tr>
                    </table>
                </ViewBox>
                <ViewBox title={I18N('assignments', language.language)}>
                    <table>
                        <th>
                            <td style={{width: 150}}></td>
                            <td></td>
                        </th>
                        <tr >
                            <td style={{paddingBottom: 30, fontSize: 12, color: "#3E4649"}}>{I18N('companies', language.language)}</td>
                            <td style={{display: "flex", paddingBottom: 30, justifyContent: "space-between"}}>
                                {loginState.data?.innerData?.users_companies?.map((i) => {
                                    return (
                                        <div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 10, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{i.company.name}</div>
                                    )
                                })}
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, color: "#3E4649"}}>{I18N('departments', language.language)}</td>
                            <td style={{display: "flex", justifyContent: "space-between"}}><div style={{background: '#F2F2F2', paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 10, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{loginState.data?.innerData?.department_departmentTousers?.name}</div>
                            </td>
                        </tr>
                    </table>
                </ViewBox>
            </div>

            <ViewBox>
                <div style={{display: "flex", justifyContent: "space-between", width: '100%'}}>
                    <table>
                        <th>
                            <td style={{width: 150}}></td>
                            <td></td>
                        </th>
                        <tr >
                            <td style={{paddingBottom: 30, fontSize: 12, color: "#3E4649"}}>{I18N('lastLogin', language.language)}</td>
                            <td style={{paddingBottom: 30, fontSize: 14, color: "#3E4649", fontWeight: "bold"}}>
                                {moment(loginState.data.innerData.lastLogin).format("DD/MM/YYYY HH:mm")}
                            </td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, paddingBottom: 30, color: "#3E4649"}}>{I18N('password', language.language)}</td>
                            <td style={{fontSize: 14,paddingBottom: 30, color: '#FFE16A', fontWeight: "bold", cursor: "pointer"}} onClick={() => setIsPasswordOpen(true)}>{I18N('changePassword', language.language)}</td>
                        </tr>
                        <tr>
                            <td style={{fontSize: 12, color: "#3E4649"}}>{I18N('autoLogout', language.language)}</td>
                            <td style={{fontSize: 14, color: '#FFE16A', fontWeight: "bold", cursor: "pointer", display: "flex", flexDirection: "row"}} onClick={() => setIsAutoLogoutOpen(true)}>
                                <div style={{background: userState.autoLogout ? '#36B37E' : "#F86D70", color:"#fff", paddingRight: 10, paddingLeft: 10, paddingBottom: 5, paddingTop: 5, fontSize: 10, textAlign: "center", width: "auto", borderRadius: 10, marginRight: 10}}>{I18N(userState.autoLogout ? 'active' : 'inActive', language.language)} ({userState.autoLogoutTimer} {I18N('mins',language.language)})</div>
                                <EditIcon onClick={() => setIsAutoLogoutOpen(true)} />
                            </td>
                        </tr>
                    </table>
                    <div style={{width: '40%', display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center", fontSize: 14, }}>
                        <img src={passwordSuggest} style={{marginRight: 10}} />
                        <p>{I18N('passwordChangeText', language.language)} <Link to={"/Passwortpruefung"}><strong style={{textDecoration: "underline", color: "#ffe16a"}}>{I18N("passwordChangeLinkText", language.language)}</strong></Link> </p>
                    </div>
                </div>

            </ViewBox>
        </React.Fragment>


    )

}