import React, { useCallback, useEffect, useState } from 'react';
import { DepartmentOperation } from './DepartmentOperation';
import DepartmentList from './DepartmentList';
import { I18N } from '../i18n/i18n';
import { useLanguageState } from '../States/LanguageState';
import { DepartmentsRepository } from '../Repositories/DepartmentsRepository';
import { useBreadCrumb } from '../States/BreadCrumbState';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useHasPermissions } from '../Helpers/MultiPermissionCheck';

const departmentRepository = new DepartmentsRepository();

type Props = {
  style: any;
  isMobile: () => boolean;
  title: string;
};

export function DepartmentTab(props: Props) {
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [editDepartment, setEditDepartment] = useState<null | any>(null);
  const language = useLanguageState((state) => state.language);
  const [formLoading, setFormLoading] = useState(false);
  const [formState, setFormState] = useState('');
  const [companyState, setCompanyState] = useState(undefined);
  const [responsibleState, setResponsibleState] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const breadCrumb = useBreadCrumb();
  const location = useLocation();
  const [mountKey, setMountKey] = useState(undefined);
  const canEdit = useHasPermissions(['EditDepartment']);

  useEffect(() => {
    if (location.key !== undefined) {
      if (location.key !== mountKey) {
        onReturnBackToView();
      }
    }
    setMountKey(location.key);
  }, [location, mountKey]);

  const scrollTop = useCallback(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, []);

  const backToDepartmentsTable = () => {
    setCreateOpen(false);
    setEditDepartment(null);
    scrollTop();
  };

  const onReturnBackToView = useCallback(() => {
    breadCrumb.setTitle(props.title);
    breadCrumb.setCrumb([
      {
        title: I18N('configuration', language),
        onClick: undefined
      },
      {
        title: props.title,
        onClick: onReturnBackToView
      }
    ]);
    breadCrumb.setBackButton(null);
    breadCrumb.setChildren([
      {
        icon: 'plus',
        title: I18N('new', language),
        onClick: () => {
          setCreateOpen(!createOpen);
          scrollTop();
        },
        color: 'dark'
      }
    ]);
    backToDepartmentsTable();
  }, [props.title]);

  const saveDepartment = useCallback(async () => {
    if (formState === '') {
      enqueueSnackbar(
        I18N('pleaseFill', language, [
          { key: 'name', value: I18N('department', language) }
        ]),
        { variant: 'error' }
      );

      return;
    }

    setFormLoading(true);

    const compData =
      companyState === undefined || companyState == null
        ? []
        : companyState.map((i) => i.value);

    if (editDepartment === null) {
      await departmentRepository
        .post({ name: formState, companies: compData })
        .then(async () => {
          setFormLoading(false);
          enqueueSnackbar(
            I18N('successfullyCreatedReturning', language, [
              { key: 'name', value: I18N('department', language) }
            ]),
            { variant: 'success' }
          );
          backToDepartmentsTable();
        })
        .catch(async (err) => {
          if (err.response.data.status !== undefined) {
            setFormLoading(false);
            enqueueSnackbar(
              I18N(err.response.data.status, language, [
                { key: 'name', value: I18N('department', language) }
              ]),
              { variant: 'error' }
            );
          } else {
            setFormLoading(false);
            enqueueSnackbar(I18N('unexpectedError', language), {
              variant: 'error'
            });
          }
        });
    } else {
      await departmentRepository
        .update(
          {
            name: formState,
            companies: compData,
            responsible: responsibleState
          },
          editDepartment.ID
        )
        .then(async (r) => {
          setFormLoading(false);
          enqueueSnackbar(
            I18N('successfullyUpdatedReturning', language, [
              { key: 'name', value: I18N('department', language) }
            ]),
            { variant: 'success' }
          );
          backToDepartmentsTable();
        })
        .catch(async (e) => {
          setFormLoading(false);
          enqueueSnackbar(I18N('unexpectedError', language), {
            variant: 'error'
          });
        });
    }

    setFormLoading(false);
    onReturnBackToView();
  }, [formState, editDepartment, language, companyState, responsibleState]);

  useEffect(() => {
    onReturnBackToView();
  }, [onReturnBackToView]);

  return (
    <div style={props.style}>
      {!createOpen ? (
        <DepartmentList
          editMode={(item) => {
            setEditDepartment(item);
            setCreateOpen(true);
            scrollTop();
          }}
        />
      ) : (
        <DepartmentOperation
          onReturnBackToView={onReturnBackToView}
          onResponsibleChange={setResponsibleState}
          onFinish={saveDepartment}
          onCompaniesChange={setCompanyState}
          onNameChange={setFormState}
          editMode={editDepartment}
          canEdit={canEdit}
        />
      )}
    </div>
  );
}
